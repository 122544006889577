import React from 'react';
import * as Yup from 'yup';

import { useFormik } from 'formik';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {
  PasswordInput,
  InputControl,
  BusyButton,
} from 'components';

import { extractApiErrors } from 'utils/api';
import { changePassword } from 'api/authentication';
import { passwordRegEx } from 'consts';
import toaster from 'services/toaster';

const useStyles = makeStyles()({
  actionsItem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const makeId = postfix => `changing-password--${postfix}`;

export const Password = () => {
  const { classes } = useStyles();
  const t = useTranslator();

  const initialValues = {
    passwordConfirm: '',
    newPassword: '',
    password: '',
  };

  const validationSchema = Yup
    .object()
    .shape({
      password: Yup
        .string()
        .trim()
        .required(t.translate('Password is required.')),
      newPassword: Yup
        .string()
        .trim()
        .matches(passwordRegEx, t.translate('Password must be at least {length} characters and must contain at least one upper case letter, one lower case letter and one digit', { length: 8 }))
        .required(t.translate('New password is required.')),
      passwordConfirm: Yup
        .mixed()
        .test('match', t.translate('Passwords do not match.'), function () {
          return this.parent.passwordConfirm === this.parent.newPassword;
        }),
    });

  const onSubmit = async (values, { setErrors, resetForm }) => {
    try {
      await changePassword(values.password, values.newPassword);
      toaster.success(t.translate('Password has been changed successfully.'));
      resetForm();
    } catch (err) {
      setErrors(extractApiErrors(err));
    }
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    validateOnMount: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  return (
    <form action="#">
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Typography variant="h6">{t.translate('Change your password')}</Typography>
        </Grid>
        <Grid item>
          <InputControl
            id={makeId('password')}
            name="password"
            error={errors.password}
            label={t.translate('Current password')}
            value={values.password}
            InputComponent={PasswordInput}
            onChange={handleChange}
          />
        </Grid>
        <Grid item>
          <InputControl
            id={makeId('new-password')}
            name="newPassword"
            error={errors.newPassword}
            label={t.translate('New password')}
            value={values.newPassword}
            InputComponent={PasswordInput}
            onChange={handleChange}
          />
        </Grid>
        <Grid item>
          <InputControl
            id={makeId('password-confirm')}
            name="passwordConfirm"
            error={errors.passwordConfirm}
            label={t.translate('Confirm password')}
            value={values.passwordConfirm}
            InputComponent={PasswordInput}
            onChange={handleChange}
          />
        </Grid>
        <Grid item className={classes.actionsItem}>
          <BusyButton
            color="secondary"
            submit
            variant="contained"
            onClick={handleSubmit}
          >
            {t.translate('button: Change password')}
          </BusyButton>
        </Grid>
      </Grid>
    </form>
  );
};
