import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Radio from '@mui/material/Radio';
import MuiRadioGroup from '@mui/material/RadioGroup';

export const RadioGroup = ({
  options,
  value,
  label,
  disabled = false,
  ...props
}) => (
  <FormControl fullWidth>
    <InputLabel shrink>{label}</InputLabel>
    <Box mt={2.5}>
      <MuiRadioGroup {...props} value={value}>
        <Grid container alignItems="center" spacing={1}>
          {Array.from(options).map(([value, text]) => (
            <Grid item key={value}>
              <FormControlLabel value={value} control={<Radio />} label={text} disabled={disabled}/>
            </Grid>
          ))}
        </Grid>
      </MuiRadioGroup>
    </Box>
  </FormControl>
);

RadioGroup.propTypes = {
  options: PropTypes.instanceOf(Map).isRequired,
  value: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
