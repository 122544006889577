import { makeStyles } from 'tss-react/mui';

const buttonSizeSpacing = 4.5;

export const useStyles = makeStyles()(theme => ({
  root: {
    flexWrap: 'nowrap',
  },
  labelItem: {
    '& .MuiTypography-root': {
      whiteSpace: 'nowrap',
      '& span': {
        whiteSpace: 'normal',
      },
    },
  },
  controlsItem: {
    display: 'flex',
  },
  input: {
    margin: theme.spacing(0, 1),
    width: 40,
    '& input': {
      textAlign: 'center',
    },
  },
  button: {
    height: theme.spacing(buttonSizeSpacing),
    minHeight: theme.spacing(buttonSizeSpacing),
    minWidth: theme.spacing(buttonSizeSpacing),
    width: theme.spacing(buttonSizeSpacing),
    '& .MuiSvgIcon-root': {
      height: 12,
      width: 12,
    },
  },
}));
