import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslator } from 'i18n';
import {
  useCancelSource,
  useScreen,
  useDialog,
} from 'hooks';

import { WarehouseDialog } from 'dialogs';
import {
  DesktopView,
  MobileView,
  Header,
} from 'views/warehouses/details';

import { setDetailsWarehouseShippingPartnerAvailability } from 'store/actions/warehouses';
import { selectActionPermission } from 'store/selectors/account';
import { fetchWarehouse } from 'services/warehouses';
import {
  disableWarehouseShippingPartner,
  enableWarehouseShippingPartner,
} from 'api/warehouses';

export const WarehouseDetails = () => {
  const cancelSource = useCancelSource();
  const dispatch = useDispatch();
  const screen = useScreen();
  const params = useParams();
  const t = useTranslator();

  const canEditWarehouse = useSelector(selectActionPermission('warehouses', 'edit'));
  const warehouse = useSelector(({ warehouses }) => warehouses.details.warehouse);

  const modifyingDialog = useDialog();

  const fetchData = useCallback(() => dispatch(fetchWarehouse(params.warehouseId)), [dispatch, params.warehouseId]);

  const changeShippingPartnerAvailability = shippingPartnerId => async ({ target: { checked } }) => {
    const cancelToken = cancelSource.issueToken(shippingPartnerId);
    try {
      dispatch(setDetailsWarehouseShippingPartnerAvailability(shippingPartnerId, checked));
      if (checked) {
        await enableWarehouseShippingPartner({ shippingPartnerId, warehouseId: warehouse.id }, { cancelToken });
      } else {
        await disableWarehouseShippingPartner({ shippingPartnerId, warehouseId: warehouse.id }, { cancelToken });
      }
      cancelSource.clear(shippingPartnerId)();
    } catch (err) {
      cancelSource.clear(shippingPartnerId)(err);
    }
  };

  useEffect(() => void fetchData(), [fetchData]);

  return (
    <>
      <Helmet>
        <title>{warehouse ? `${warehouse.name} | ` : ''}{t.translate('Warehouses')} | {t.translate('app:title')}</title>
      </Helmet>

      {canEditWarehouse && warehouse && (
        <WarehouseDialog
          {...modifyingDialog.props}
          warehouse={warehouse}
          onSave={fetchData}
        />
      )}

      <Header
        warehouse={warehouse}
        onWarehouseEdit={() => modifyingDialog.open()}
        canEditWarehouse={canEditWarehouse}
      />

      {warehouse && (
        screen.isMobile ? (
          <MobileView
            onShippingPartnerAvailabilityChange={changeShippingPartnerAvailability}
            canEditWarehouse={canEditWarehouse}
            warehouse={warehouse}
          />
        ) : (
          <DesktopView
            onShippingPartnerAvailabilityChange={changeShippingPartnerAvailability}
            canEditWarehouse={canEditWarehouse}
            warehouse={warehouse}
          />
        )
      )}
    </>
  );
};
