import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';
import {
  AppHeaderPrimaryToolbar,
  AppHeaderMenuButton,
  AppHeaderRightBox,
  QuerySearchInput,
  AppHeaderTitle,
  AppHeader,
} from 'components';

export const Header = ({ isMobile }) => {
  const t = useTranslator();

  return (
    <AppHeader>
      <AppHeaderPrimaryToolbar>
        {isMobile && <AppHeaderMenuButton edge="start" />}
        <AppHeaderTitle>{t.translate('Suppliers')}</AppHeaderTitle>
        <AppHeaderRightBox>
          <QuerySearchInput placeholder={t.translate('Search by supplier number or name')} hideInput={isMobile}/>
        </AppHeaderRightBox>
      </AppHeaderPrimaryToolbar>
    </AppHeader>
  );
};

Header.propTypes = {
  isMobile: PropTypes.bool,
};
