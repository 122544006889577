import React from 'react';
import PropTypes from 'prop-types';

import { useLoading } from 'hooks/loading';
import { useTranslator } from 'i18n';

import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import CircularProgress from '@mui/material/CircularProgress';
import { Fab } from 'components';

export const LabellingButton = ({ onClick }) => {
  const t = useTranslator();
  const loading = useLoading();

  return (
    <Fab
      variant="extended"
      onClick={onClick}
      aria-labelledby={t.translate('Receive a document of shipping labels for the pickup sheet')}
    >
      {loading.progress
        ? <CircularProgress color="inherit" size={24} />
        : <ConfirmationNumberIcon />}
      {t.translate('Shipping labels')}
    </Fab>
  );
};

LabellingButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
