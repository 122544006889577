import config from 'config';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { useHistory } from 'react-router-dom';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';

import { CollapsedCancelledRow } from './CollapsedCancelledRow/CollapsedCancelledRow';
import { CollapseTableRow, DotInfo } from 'components';
import { CollapsedRow } from './CollapsedRow/CollapsedRow';
import { styleTableRow } from 'theme/mixins/table';
import { selectPositionStatus } from '../../utils';
import { SupplierHandlingTypes } from 'consts';
import { getRetailer } from '../../../../../utils/retailers';

const useStyles = makeStyles()(theme => styleTableRow(theme, {
  defaultFontSize: {
    fontSize: '0.875rem',
  },
  button: {
    color: theme.palette.primary.main,
  },
  cancelIcon: {
    color: theme.palette.red.coral,
  },
  doneIcon: {
    color: theme.palette.green.main,
  },
  hint: {
    maxWidth: 320,
  },
  orderedIcon: {
    color: theme.palette.text.secondary,
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  retunButton: {
    color: theme.palette.primary.l900,
  },
  link: {
    fontSize: '0.875rem',
  },
}));

export const ProductsTable = ({
  positions,
  order,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState({ statusPosition: null });
  const [isReturnedCell] = useState(!!order.hasReturns);

  const openProduct = id => history.push(`/products/${id}`);

  const handlePopoverOpen = (name, event) => {
    setAnchorEl(prevState => ({
      ...prevState,
      [name]: event.currentTarget,
    }));
  };

  const handlePopoverClose = (name) => {
    setAnchorEl(prevState => ({
      ...prevState,
      [name]: null,
    }));
  };

  const hasVehicleAttribute = positions.find(position => position.attributes?.find(attribute => attribute.generic_key === 'fahrzeug' || attribute.generic_key === 'vehicleinformation'));

  const optionalCellCount = () => {
    return (isReturnedCell ? 1 : 0);
  };

  const getReturnedQuantity = (position) => {
    return position.orderPositionReturns.reduce((acc, pos) => acc + pos.returnedQuantity, 0);
  };

  const getDotInfo = ({ orderStorageProducts }) => {
    const dotInfo = [];

    orderStorageProducts.forEach(product => {
      if (product.manufacturingDate) {
        dotInfo.push(`${product.quantity} - ${t.formatManufacturingDate(product.manufacturingDate)}`);
      }
    });

    return <DotInfo dotInfo={dotInfo}/>;
  };

  const retailer = getRetailer(order.supplier.retailers, order.country);

  return (
    <Paper>
      <TableContainer>
        <Table stickyHeader className={classes.table}>
            {positions.some((position) => !!position.cancelledAmount) ? (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell name="productId" className={classes.defaultFontSize}>Product-ID</TableCell>
                    <TableCell name="productType" className={classes.defaultFontSize}>Type</TableCell>
                    <TableCell name="alzuraId" className={classes.defaultFontSize}>Alzura-ID</TableCell>
                    <TableCell name="productName" className={classes.defaultFontSize}>{t.translate('Product name')}</TableCell>
                    <TableCell name="productEan" className={classes.defaultFontSize}>EAN</TableCell>
                    <TableCell name="quantity" className={classes.defaultFontSize} align="center">{t.translate('Ordered')}</TableCell>
                    {isReturnedCell && <TableCell name="returned" className={classes.defaultFontSize} align="center">{t.translate('Returned')}</TableCell>}
                    <TableCell name="purchasePrice" className={classes.defaultFontSize} align="center">{t.translate('Purchase price')}</TableCell>
                    <TableCell name="netPrice" className={classes.defaultFontSize} align="center">{t.translate('Net single price')}</TableCell>
                    <TableCell name="totalNetPrice" className={classes.defaultFontSize} align="center">{t.translate('Net total price')}</TableCell>
                    <TableCell name="margin" className={classes.defaultFontSize} align="center">{t.translate('Total margin')}</TableCell>
                    <TableCell name="manufacturingDate" className={classes.defaultFontSize} align="center">{t.translate('DOT')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {positions.map((position, index) => (
                    <CollapseTableRow
                      key={index}
                      colSpan={13 + optionalCellCount()}
                      isOpenCollapsedRow={true}
                      collapsedRow={<CollapsedCancelledRow position={position}/>}>
                      <TableCell className={classes.defaultFontSize}>
                        <Link className={classes.link} onClick={() => openProduct(position.product.id)}>{position.product.id}</Link>
                      </TableCell>
                      <TableCell className={classes.defaultFontSize}>{position.product.type ? `${position.product.type.code} - ${t.translate(`productType:${position.product.type.code}`)}` : '—'}</TableCell>
                      <TableCell className={classes.defaultFontSize}>{position.product.alzuraId || '—'}</TableCell>
                      <TableCell className={classes.defaultFontSize} style={{ maxWidth: 300 }}>{position.product.details[0]?.name || '—'}</TableCell>
                      <TableCell className={classes.defaultFontSize}>{position.product.ean || '—'}</TableCell>
                      <TableCell className={classes.defaultFontSize} align="center">{Number(position.quantity)}</TableCell>
                      {isReturnedCell && <TableCell className={classes.defaultFontSize} align="center">{getReturnedQuantity(position) ?? '-'}</TableCell>}
                      <TableCell className={classes.defaultFontSize} align="center">{numeral(Number(position.purchasePrice)).format('0,0.00')} &euro;</TableCell>
                      <TableCell className={classes.defaultFontSize} align="center">{numeral(Number(position.netPrice)).format('0,0.00')} &euro;</TableCell>
                      <TableCell className={classes.defaultFontSize} align="center">{numeral(Number(position.netTotalPrice)).format('0,0.00')} &euro;</TableCell>
                      <TableCell className={classes.defaultFontSize} align="center">{numeral(position.margin).format('0,0.00')} &euro;</TableCell>
                      <TableCell className={classes.defaultFontSize} align="center">{getDotInfo(position)}</TableCell>
                    </CollapseTableRow>
                  ))}
                </TableBody>
              </>
            ) : (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell name="productId" className={classes.defaultFontSize}>Product-ID</TableCell>
                    <TableCell name="productType" className={classes.defaultFontSize}>Type</TableCell>
                    <TableCell name="alzuraId" className={classes.defaultFontSize}>Alzura ID</TableCell>
                    <TableCell name="productName" className={classes.defaultFontSize}>{t.translate('Product name')}</TableCell>
                    <TableCell name="productEan" className={classes.defaultFontSize}>{t.translate('Product EAN code')}</TableCell>
                    <TableCell name="quantity" className={classes.defaultFontSize} align="center">{t.translate('Quantity')}</TableCell>
                    {isReturnedCell && <TableCell name="returned" className={classes.defaultFontSize} align="center">{t.translate('Returned')}</TableCell>}
                    <TableCell name="purchasePrice" className={classes.defaultFontSize} align="center">{t.translate('Purchase price')}</TableCell>
                    <TableCell name="netPrice" className={classes.defaultFontSize} align="center">{t.translate('Net single price')}</TableCell>
                    <TableCell name="totalNetPrice" className={classes.defaultFontSize} align="center">{t.translate('Net total price')}</TableCell>
                    <TableCell name="margin" className={classes.defaultFontSize} align="center">{t.translate('Total margin')}</TableCell>
                    <TableCell name="externalOrderId" className={classes.defaultFontSize}>{t.translate('Order-Reference')}</TableCell>
                    <TableCell name="manufacturingDate" className={classes.defaultFontSize} align="center">{t.translate('DOT')}</TableCell>
                    <TableCell name="externalOrderStatus" className={classes.defaultFontSize}>{t.translate('Status')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {((order.supplier?.handlingType === SupplierHandlingTypes.DEFAULT || order.supplier?.handlingType === SupplierHandlingTypes.DROPSHIPPING_DEFAULT) && !hasVehicleAttribute) ? (
                  positions.map((position, index) => (
                    <TableRow key={index}>
                      <TableCell />
                      <TableCell className={classes.defaultFontSize}>
                        <Link className={classes.link} onClick={() => openProduct(position.product.id)}>{position.product.id}</Link>
                      </TableCell>
                      <TableCell className={classes.defaultFontSize}>{position.product.type ? `${position.product.type.code} - ${t.translate(`productType:${position.product.type.code}`)}` : '—'}</TableCell>
                      <TableCell className={classes.defaultFontSize}>{position.product.alzuraId || '—'}</TableCell>
                      <TableCell className={classes.defaultFontSize} style={{ maxWidth: 300 }}>{position.product.details[0]?.name || '—'}</TableCell>
                      <TableCell className={classes.defaultFontSize}>{position.product.ean || '—'}</TableCell>
                      <TableCell className={classes.defaultFontSize} align="center">{position.quantity}</TableCell>
                      {isReturnedCell && <TableCell className={classes.defaultFontSize} align="center">{getReturnedQuantity(position) ?? '-'}</TableCell>}
                      <TableCell className={classes.defaultFontSize} align="center">{numeral(Number(position.purchasePrice)).format('0,0.00')} &euro;</TableCell>
                      <TableCell className={classes.defaultFontSize} align="center">{numeral(Number(position.netPrice)).format('0,0.00')} &euro;</TableCell>
                      <TableCell className={classes.defaultFontSize} align="center">{numeral(Number(position.netTotalPrice)).format('0,0.00')} &euro;</TableCell>
                      <TableCell className={classes.defaultFontSize} align="center">{numeral(position.margin).format('0,0.00')} &euro;</TableCell>
                      <TableCell className={classes.defaultFontSize}>
                        {!!position.externalOrderNumber ?
                          <Link
                            href={`${config.tyre24BackOfficeUrl}/orderhistory/details/order/${position.externalOrderNumber}/country/${retailer.country}`}
                            underline="hover"
                            target="_blank"
                            rel="noopener"
                          >
                            {position.externalOrderNumber}
                          </Link> : '—'
                        }
                      </TableCell>
                      <TableCell className={classes.defaultFontSize} align="center">{getDotInfo(position)}</TableCell>
                      <TableCell className={classes.defaultFontSize}>
                        <IconButton
                          aria-label="hint"
                          aria-owns={Boolean(anchorEl.statusPosition) ? 'mouse-over-popover' : undefined}
                          aria-haspopup="true"
                          onMouseEnter={e => handlePopoverOpen('statusPosition', e)}
                          onMouseLeave={() => handlePopoverClose('statusPosition')}
                          onClick={e => handlePopoverOpen('statusPosition', e)}
                          size="large">
                          {selectPositionStatus(order.statusCode, order.supplier?.handlingType, order.inReview, classes).icon}
                        </IconButton>
                        <Popover
                          id="mouse-over-popover"
                          className={classes.popover}
                          classes={{ paper: classes.paper }}
                          open={Boolean(anchorEl.statusPosition)}
                          anchorEl={anchorEl.statusPosition}
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                          onClose={() => handlePopoverClose('statusPosition')}
                          disableRestoreFocus
                        >
                          <Typography variant="body2" className={classes.hint}>
                            {t.translate(selectPositionStatus(order.statusCode, order.supplier?.handlingType, order.inReview, classes).text)}
                          </Typography>
                        </Popover>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  positions.map((position, index) => (
                    <CollapseTableRow
                      key={index}
                      colSpan={15 + optionalCellCount()}
                      isOpenCollapsedRow={true}
                      collapsedRow={<CollapsedRow position={position} order={order} />}>
                      <TableCell className={classes.defaultFontSize}>
                        <Link className={classes.link} onClick={() => openProduct(position.product.id)}>{position.product.id}</Link>
                      </TableCell>
                      <TableCell className={classes.defaultFontSize}>{position.product.type ? `${position.product.type.code} - ${t.translate(`productType:${position.product.type.code}`)}` : '—'}</TableCell>
                      <TableCell className={classes.defaultFontSize}>{position.product.alzuraId || '—'}</TableCell>
                      <TableCell className={classes.defaultFontSize} style={{ maxWidth: 300 }}>{position.product.details[0]?.name || '—'}</TableCell>
                      <TableCell className={classes.defaultFontSize}>{position.product.ean || '—'}</TableCell>
                      <TableCell className={classes.defaultFontSize} align="center">{position.quantity}</TableCell>
                      {isReturnedCell && <TableCell className={classes.defaultFontSize} align="center">{getReturnedQuantity(position) ?? '-'}</TableCell>}
                      <TableCell className={classes.defaultFontSize} align="center">{numeral(Number(position.purchasePrice)).format('0,0.00')} &euro;</TableCell>
                      <TableCell className={classes.defaultFontSize} align="center">{numeral(Number(position.netPrice)).format('0,0.00')} &euro;</TableCell>
                      <TableCell className={classes.defaultFontSize} align="center">{numeral(Number(position.netTotalPrice)).format('0,0.00')} &euro;</TableCell>
                      <TableCell className={classes.defaultFontSize} align="center">{numeral(position.margin).format('0,0.00')} &euro;</TableCell>
                      <TableCell className={classes.defaultFontSize}>
                        {!!position.externalOrderNumber ?
                          <Link
                            href={`${config.tyre24BackOfficeUrl}/orderhistory/details/order/${position.externalOrderNumber}/country/${retailer.country}`}
                            underline="hover"
                            target="_blank"
                            rel="noopener"
                          >
                            {position.externalOrderNumber}
                          </Link> : '—'
                        }
                      </TableCell>
                      <TableCell className={classes.defaultFontSize} align="center">{getDotInfo(position)}</TableCell>
                      <TableCell className={classes.defaultFontSize}>
                        <IconButton
                          aria-label="hint"
                          aria-owns={Boolean(anchorEl.statusPosition) ? 'mouse-over-popover' : undefined}
                          aria-haspopup="true"
                          onMouseEnter={e => handlePopoverOpen('statusPosition', e)}
                          onMouseLeave={() => handlePopoverClose('statusPosition')}
                          onClick={e => handlePopoverOpen('statusPosition', e)}
                          size="large">
                          {selectPositionStatus(position.externalOrderStatus, order.supplier?.handlingType, order.inReview, classes).icon}
                        </IconButton>
                        <Popover
                          id="mouse-over-popover"
                          className={classes.popover}
                          classes={{ paper: classes.paper }}
                          open={Boolean(anchorEl.statusPosition)}
                          anchorEl={anchorEl.statusPosition}
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                          onClose={() => handlePopoverClose('statusPosition')}
                          disableRestoreFocus
                        >
                          <Typography variant="body2" className={classes.hint}>
                            {t.translate(selectPositionStatus(position.externalOrderStatus, order.supplier?.handlingType, order.inReview, classes).text)}
                          </Typography>
                        </Popover>
                      </TableCell>
                    </CollapseTableRow>
                  ))
                )}
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
};

ProductsTable.propTypes = {
  positions: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.number.isRequired,
      externalOrderNumber: PropTypes.string,
      externalOrderStatus: PropTypes.number,
      netPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      netTotalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      product: PropTypes.shape({
        description: PropTypes.string,
        name: PropTypes.string,
        ean: PropTypes.string,
        alzuraId: PropTypes.string,
      }).isRequired,
    }).isRequired
  ).isRequired,
  onProductDescriptionOpen: PropTypes.func.isRequired,
  onStockHistoryShow: PropTypes.func.isRequired,
  order: PropTypes.shape({
    statusCode: PropTypes.number.isRequired,
    inReview: PropTypes.bool.isRequired,
    supplier: PropTypes.object.isRequired,
  }).isRequired,
};
