import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslator } from 'i18n';
import { makeStyles } from 'tss-react/mui';
import EditIcon from '@mui/icons-material/Edit';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  ActionButton,
  CardFeature,
} from 'components';
import { styleMobileTableItem } from 'theme/mixins/table';
import { humanizeDefaultView } from 'services/account';
import { humanizeLocale } from 'services/i18n/locale';
import { grantSupervisor } from '../../../../api/users';
import { selectActionPermission } from '../../../../store/selectors/account';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  headline: {
    marginBottom: theme.spacing(-.5),
  },
  actionButtons: {
    whiteSpace: 'nowrap',
  },
}));

export const UserCard = ({
  onUserEdit,
  isMobileView,
  onSave,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const user = useSelector(({ users }) => users.details.user);

  const canEditUser = useSelector(selectActionPermission('users', 'edit'));
  const canGrantSupervisor = useSelector(selectActionPermission('purchases', 'grantSupervisor'));

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item container className={classes.headline}>
        <Grid item xs>
          <Typography variant="h6">{user.name}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{t.formatDate(user.date)} {t.formatTime(user.date)}</Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs container direction="column">
          <CardFeature
            label="ID"
            value={user.id}
          />
          <CardFeature
            label={t.translate('Email')}
            value={user.email}
          />
          <CardFeature
            label={t.translate('Default view')}
            value={t.translate(humanizeDefaultView(user.defaultView))}
          />
          <CardFeature
            label={t.translate('Language')}
            value={humanizeLocale(user.locale, { t })}
          />
        </Grid>
        <Grid item>
          <Grid container direction={isMobileView ? 'column' : 'row'}>
            <Grid item className={classes.actionButtons}>
              {canGrantSupervisor && (
                <ActionButton
                  tooltipPlacement={isMobileView ? 'left' : 'bottom'}
                  aria-label={t.translate('Grant supervisor')}
                  tooltip={t.translate('Toggle supervisor permission')}
                  icon={<SupervisorAccountIcon />}
                  onClick={async () => {
                    await grantSupervisor(user.id, { isSupervisor: !user.permissions.purchases.supervisor });
                    onSave && onSave();
                  }}
                />
              )}
              {canEditUser && (
                <ActionButton
                  tooltipPlacement={isMobileView ? 'left' : 'bottom'}
                  aria-label={t.translate('Edit user')}
                  tooltip={t.translate('Edit')}
                  icon={<EditIcon />}
                  onClick={() => onUserEdit(user)}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

UserCard.propTypes = {
  onUserEdit: PropTypes.func.isRequired,
  isMobileView: PropTypes.bool,
};
