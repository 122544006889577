import { OUTDATED_PRODUCTS } from 'store/types';
import { outdatedProducts as is } from 'store/initialState';

const setOutdatedProducts = (state, data) => ({
  ...state,
  overview: {
    ...state.overview,
    outdatedProducts: {
      data,
    },
  },
});

export default (state = is, { type, payload }) => {
  switch (type) {
    case OUTDATED_PRODUCTS.OVERVIEW.SET_OUTDATED_PRODUCTS:
      return setOutdatedProducts(state, payload);
    default:
      return state;
  }
};
