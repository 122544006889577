import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import marketplacesApi from 'api/marketplaces';

import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslator } from 'i18n';
import {
  useScreen,
  useDialog,
  useList,
  didMount,
} from 'hooks';

import { AlzuraSpriteIcon, Fab } from 'components';
import {
  ProductTicket,
  DesktopView,
  MobileView,
  Header,
} from 'views/products/details';
import {
  CorrectionStockDialog,
  StorageReassignDialog,
  BookingStockDialog,
  QrCodePrintDialog,
  ProductDialog,
  ImageDialog,
} from 'dialogs';

import { fetchProduct, fetchProductLocations } from 'services/products';
import { selectActionPermission, selectAuthenticatedUser } from 'store/selectors/account';
import { clearStorages } from 'store/actions/products';
import { setDefaultImage } from 'api/products';
import toaster from 'services/toaster';
import tables from 'config/tables';
import socket, { SOCKET_EVENTS } from '../../../bootstrap/socket';

export const ProductDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const screen = useScreen();
  const params = useParams();
  const list = useList(tables.products.storages);
  const t = useTranslator();

  const [activeMarketplaces, setActiveMarketplaces] = useState(null);

  const correctionStockDialog = useDialog({ storageProduct: null });
  const modifyingProductDialog = useDialog();
  const storageReassignDialog = useDialog();
  const printingDialog = useDialog();
  const bookingDialog = useDialog();
  const imageDialog = useDialog({ images: [], additionalImages: [] });

  const canReassignStock = useSelector(selectActionPermission('products', 'reassignStock'));
  const canBookStock = useSelector(selectActionPermission('products', 'book'));
  const canCorrectStock = useSelector(selectActionPermission('products', 'correct'));
  const canCreateProduct = useSelector(selectActionPermission('products', 'create'));
  const canEditProductPermission = useSelector(selectActionPermission('products', 'edit'));
  const product = useSelector(({ products }) => products.storages.product);
  const canUpdateFromMarketplace = useSelector(selectActionPermission('products', 'updateFromMarketplace'));
  const authenticatedUser = useSelector(selectAuthenticatedUser);

  const fetchData = () => {
    dispatch(fetchProductLocations(params.productId, list.compile()));
    dispatch(fetchProduct(params.productId));

    return () => dispatch(clearStorages());
  };

  const onSetDefaultImage = async (image) => {
    try{
      await setDefaultImage(params.productId, image);
      toaster.success(t.translate('Image was set as default successfully.'));
      imageDialog.close();
      fetchData();
    } catch {}
  };

  const isEditedByAnotherUser = product?.editedByUserId ? product.editedByUserId !== authenticatedUser?.id : false;

  const canEditProduct = canEditProductPermission
    && (!product?.editedByUserId || product.editedByUserId === authenticatedUser.id);

  didMount(() => {
    socket.on(SOCKET_EVENTS.PRODUCTS.EDITED_BY, async ({ entityId }) => {
      if (entityId === Number(params.productId)) dispatch(fetchProduct(entityId));
    });
  });

  useEffect(() => {
    return () => {
      socket.emit(SOCKET_EVENTS.PRODUCTS.EDITED_BY, { entityId: Number(params.productId), isEdited: false });
      socket.off(SOCKET_EVENTS.PRODUCTS.EDITED_BY);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchData, [params.productId, location.search]);

  useEffect(() => {
    (async () => {
      const { data: marketplaces } = await marketplacesApi.fetchMarketplaces();
      setActiveMarketplaces(marketplaces.filter(marketplace => marketplace.isActive));
    })();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t.translate('Products')} | {t.translate('app:title')}</title>
      </Helmet>

      {product && (
        <>
          {canCreateProduct && (
            <Fab onClick={() => bookingDialog.open()} aria-label="Add stock" variant="extended">
              <AlzuraSpriteIcon id='assignmentAdd'/>
              {t.translate('Add stock')}
            </Fab>
          )}

          <QrCodePrintDialog
            {...printingDialog.props}
            value={product.id.toString()}
            ean={product.ean}
            ticket={<ProductTicket product={product}/>}
            ticketWithBarcode={<ProductTicket product={product} isBarcode={true}/>}
            isProductPrint={true}
          />

          {canEditProduct && (
            <ProductDialog
              {...modifyingProductDialog.props}
              product={product}
              isMobileView={screen.isMobile}
              onSave={() => dispatch(fetchProduct(params.productId))}
              canUpdateFromMarketplace={canUpdateFromMarketplace}
            />
          )}

          {canBookStock && bookingDialog.visible && (
            <BookingStockDialog
              {...bookingDialog.props}
              product={product}
              isMobileView={screen.isMobile}
              onSave={fetchData}
            />
          )}

          {canCorrectStock && correctionStockDialog.data.storageProduct && (
            <CorrectionStockDialog
              {...correctionStockDialog.props}
              onSave={fetchData}
            />
          )}

          {canReassignStock && storageReassignDialog.visible && (
            <StorageReassignDialog
              {...storageReassignDialog.props}
              isMobile={screen.isMobile}
              onComplete={fetchData}
              canChangeStockAmount={true}
            />
          )}
        </>
      )}

      {imageDialog.data.images && (
        <ImageDialog
          {...imageDialog.props}
          isMobile={screen.isMobile}
          onSetDefaultImage={onSetDefaultImage}
        />
      )}

      <Header
        isEditedByAnotherUser={isEditedByAnotherUser}
        canEditProduct={canEditProduct}
        onProductEdit={() => modifyingProductDialog.open()}
        onProductPrint={() => printingDialog.open({ isBarcode: false })}
        onProductPrintWithBarcode={() => printingDialog.open( { isBarcode: true } )}
        isMobile={screen.isMobile}
      />

      {screen.isMobile ? (
        <MobileView
          onStorageReassignStock={storageProduct => storageReassignDialog.open({ storageProduct })}
          onStorageProductEdit={storageProduct => correctionStockDialog.open({ storageProduct })}
          onImageFullView={(images, additionalImages) => imageDialog.open({ images, additionalImages })}
          isMobile={screen.isMobile}
        />
      ) : (
        <DesktopView
          onStorageReassignStock={storageProduct => storageReassignDialog.open({ storageProduct })}
          onStorageProductEdit={storageProduct => correctionStockDialog.open({ storageProduct })}
          canEditProduct={canEditProduct}
          marketplaces={activeMarketplaces}
          onImageFullView={(images, additionalImages) => imageDialog.open({ images, additionalImages })}
          isMobile={screen.isMobile}
        />
      )}
    </>
  );
};
