import React from 'react';
import { Grid } from '@mui/material';
import { useStyles } from '../styles';
import {
  CheckboxControl,
  InputControl,
  PasswordInput,
} from 'components';
import { useTranslator } from 'i18n';
import { SupplierHandlingTypes } from 'consts';
import { PageWrapper } from '../PageWrapper/PageWrapper';

const makeId = postfix => `supplier-settings-ftp--${postfix}`;

export const FTPSettings = ({ isEditMode, errors, values, handleChange, canEditFTP }) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const { DEFAULT } = SupplierHandlingTypes;
  return (
    <PageWrapper>
      {canEditFTP && values.handlingType === DEFAULT
        ? <Grid container item lg={4} md={6} sm={12} xs={12} direction="row" className={classes.containerWrap}>
          <CheckboxControl
            disabled={!isEditMode}
            id={makeId('productExport')}
            name="productExport"
            error={errors.productExport}
            label={t.translate('Export product stock to marketplace FTP')}
            checked={values.productExport}
            onChange={handleChange}
          />
          <InputControl
            disabled={!isEditMode}
            id={makeId('host')}
            className={classes.marginBottom}
            name="ftp.host"
            error={errors['ftp.host']}
            label={t.translate('Host')}
            value={values.ftp.host}
            onChange={handleChange}
          />
          <InputControl
            disabled={!isEditMode}
            id={makeId('username')}
            className={classes.marginBottom}
            name="ftp.username"
            error={errors['ftp.username']}
            label={t.translate('Username')}
            value={values.ftp.username}
            onChange={handleChange}
            autoComplete="one-time-code"
          />
          <InputControl
            disabled={!isEditMode}
            id={makeId('password')}
            className={classes.marginBottom}
            name="ftp.password"
            error={errors['ftp.password']}
            label={t.translate('Password')}
            value={values.ftp.password}
            InputComponent={PasswordInput}
            onChange={handleChange}
            autoComplete="one-time-code"
          />
          <Grid container spacing={4}>
            <Grid item md={4} className={classes.marginBottom}>
              <InputControl
                disabled={!isEditMode}
                id={makeId('port')}
                name="ftp.port"
                error={errors['ftp.port']}
                label={t.translate('Port')}
                value={values.ftp.port}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={8} className={classes.marginBottom}>
              <InputControl
                disabled={!isEditMode}
                id={makeId('remote-path')}
                name="ftp.remotePath"
                error={errors['ftp.remotePath']}
                label={t.translate('Remote path')}
                value={values.ftp.remotePath}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
        : t.translate('You cannot edit FTP settings because you don\'t have specific permission or supplier type is not "Ship from Warehouse".')}
    </PageWrapper>
  );
};
