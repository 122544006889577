import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

import {
  AppHeaderPrimaryToolbar,
  AppHeaderBackButton,
  AppHeaderRightBox,
  AppHeaderTitle,
  AppHeader,
} from 'components';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import GetAppIcon from '@mui/icons-material/GetApp';
import { PurchaseStatuses } from '../../../../consts';

const useStyles = makeStyles()((theme) => ({
  button: {
    color: 'inherit',
    fontWeight: 'normal',
    height: 'inherit',
    textTransform: 'none',
  },
  editedByInfo: {
    color: 'inherit',
    fontSize: '0.875rem',
    fontWeight: 'normal',

    '&.MuiChip-root': {
      border: 'none',
    },
  },
  tooltip: {
    padding: theme.spacing(2),
  },
}));

export const Header = ({
  canDeletePurchase,
  canEditPurchase,
  canApproveAndDeclinePurchase,
  canCorrectionPurchase,
  isEditedByAnotherUser,
  onPurchaseDelete,
  onPurchaseEdit,
  onPurchaseApprove,
  onPurchaseDecline,
  onPurchaseCorrection,
  onPurchaseExport,
  purchase,
  isMobile,
}) => {
  const history = useHistory();
  const { classes } = useStyles();
  const t = useTranslator();

  return (
    <AppHeader>
      <AppHeaderPrimaryToolbar>
        <AppHeaderBackButton edge="start" onClick={() => history.goBack()} />
        <AppHeaderTitle>{t.translate('Purchase')}: {purchase?.number}</AppHeaderTitle>
        <AppHeaderRightBox>
          {isEditedByAnotherUser && (
            isMobile
              ? <Tooltip
                  className={classes.tooltip}
                  title={`${t.translate('This purchase is currently being edited by')} ${purchase.editedByUser.name}`}
                >
                <InfoIcon />
              </Tooltip>
              : <Chip
                className={classes.editedByInfo}
                variant="outlined"
                label={`${t.translate('This purchase is currently being edited by')} ${purchase.editedByUser.name}`}
              />
          )}
          {canApproveAndDeclinePurchase && (
            <Button
              className={classes.button}
              startIcon={!isMobile && <CheckIcon />}
              onClick={() => onPurchaseApprove(purchase)}
            >
              {!isMobile ? t.translate('Approve') : <CheckIcon />}
            </Button>
          )}
          {canEditPurchase && (
            <Button
              className={classes.button}
              startIcon={!isMobile && <EditIcon />}
              onClick={() => onPurchaseEdit(purchase)}
            >
              {!isMobile ? t.translate('Edit') : <EditIcon />}
            </Button>
          )}
          {canCorrectionPurchase && (
            <Button
              className={classes.button}
              startIcon={!isMobile && <EditIcon />}
              onClick={() => onPurchaseCorrection(purchase)}
            >
              {!isMobile ? t.translate('Correction') : <EditIcon />}
            </Button>
          )}
          {canApproveAndDeclinePurchase && (
            <Button
              className={classes.button}
              startIcon={!isMobile && <CancelIcon />}
              onClick={() => onPurchaseDecline(purchase)}
            >
              {!isMobile ? t.translate('Decline') : <CancelIcon />}
            </Button>
          )}
          {canDeletePurchase && (
            <Button
              className={classes.button}
              startIcon={!isMobile && <DeleteIcon />}
              onClick={() => onPurchaseDelete(purchase)}
            >
              {!isMobile ? t.translate('Delete') : <DeleteIcon />}
            </Button>
          )}
          {(purchase?.status === PurchaseStatuses.received) && (
            <Button
              className={classes.button}
              startIcon={!isMobile && <GetAppIcon />}
              onClick={onPurchaseExport}
            >
              {!isMobile ? t.translate('Export') : <GetAppIcon />}
            </Button>
          )}
        </AppHeaderRightBox>
      </AppHeaderPrimaryToolbar>
    </AppHeader>
  );
};

Header.propTypes = {
  purchase: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    warehouse: PropTypes.shape({
      name: PropTypes.string,
    }),
    supplier: PropTypes.shape({
      name: PropTypes.string,
    }),
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
    editedByUser: PropTypes.shape({
      name: PropTypes.string,
    }),
    reviewedByUser: PropTypes.shape({
      name: PropTypes.string,
    }),
    status: PropTypes.string,
  }),
  canDeletePurchase: PropTypes.bool.isRequired,
  canEditPurchase: PropTypes.bool.isRequired,
  canApproveAndDeclinePurchase: PropTypes.bool.isRequired,
  isEditedByAnotherUser: PropTypes.bool.isRequired,
  onPurchaseDelete: PropTypes.func.isRequired,
  onPurchaseEdit: PropTypes.func.isRequired,
  onPurchaseApprove: PropTypes.func.isRequired,
  onPurchaseDecline: PropTypes.func.isRequired,
};
