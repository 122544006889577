import React from 'react';
import PropTypes from 'prop-types';

const W = 1179;
const H = 184;

export const Logo = ({
  height,
  width,
  ...rest
}) => {
  const [computedHeight, computedWidth] = height
    ? [height, Math.ceil(W / H * height)]
    : width
      ? [Math.ceil(H / W * width), width]
      : [H, W];

  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width={computedWidth}
      height={computedHeight}
      viewBox={`0 0 ${W} ${H}`}
    >
      <g fill="#0A1E43" fillRule="nonzero">
        <path d="M851.37 54.1h-32.236v86.473h-6.675V54.1h-32.425v-5.635h71.336zM928.698 140.573h-5.689a4.384 4.384 0 0 1-1.858-.343 3.799 3.799 0 0 1-1.403-1.332l-29.43-37.392a8.405 8.405 0 0 0-1.061-1.218 5.535 5.535 0 0 0-1.252-.838 8.052 8.052 0 0 0-1.593-.419 19.944 19.944 0 0 0-2.313 0h-11.984v41.542h-6.713V48.465h23.475c10.594 0 18.495 2.043 23.703 6.13a21.514 21.514 0 0 1 7.888 18.087 24.76 24.76 0 0 1-1.82 9.633 22.85 22.85 0 0 1-5.196 7.616 28.242 28.242 0 0 1-8.267 5.369 38.413 38.413 0 0 1-10.619 3.008 10.712 10.712 0 0 1 2.806 2.627l31.326 39.638zm-40.58-46.606a38.613 38.613 0 0 0 10.961-1.447 23.669 23.669 0 0 0 8.154-4.227 17.696 17.696 0 0 0 5.082-6.549 20.747 20.747 0 0 0 1.744-8.681 17.012 17.012 0 0 0-6.371-14.584 30.964 30.964 0 0 0-18.659-4.76h-16.763v40.248h15.853zM1018.958 140.573h-5.12a2.458 2.458 0 0 1-1.516-.457 3.123 3.123 0 0 1-.873-1.294l-10.77-26.654h-45.092l-10.771 26.654a2.475 2.475 0 0 1-.948 1.37 2.383 2.383 0 0 1-1.555.495h-5.044l37.545-92.108h6.561l37.583 91.994zm-20.29-33.47l-18.696-46.53a48.071 48.071 0 0 1-1.858-5.483 55.21 55.21 0 0 1-.872 2.97c-.304.952-.607 1.79-.949 2.552l-18.696 46.491h41.072zM1110.357 94.5a54.418 54.418 0 0 1-3.186 19.038 40.692 40.692 0 0 1-8.95 14.508 38.634 38.634 0 0 1-13.843 9.214 48.635 48.635 0 0 1-17.786 3.16h-33.526V48.466h33.526a47.88 47.88 0 0 1 17.786 3.198 37.913 37.913 0 0 1 13.843 9.177 41.38 41.38 0 0 1 8.95 14.622 54.38 54.38 0 0 1 3.186 19.038zm-6.865 0a52.396 52.396 0 0 0-2.654-17.21 35.783 35.783 0 0 0-7.585-12.757 32.082 32.082 0 0 0-11.643-8.11 40.548 40.548 0 0 0-15.17-2.703h-26.85v81.446h26.85c5.182.077 10.33-.84 15.17-2.703a32.079 32.079 0 0 0 11.68-7.958 35.783 35.783 0 0 0 7.585-12.756 52.434 52.434 0 0 0 2.617-17.249zM1179 135.052v5.521h-54.99V48.465H1179v5.521h-48.62v37.24h40.2v5.406h-40.2v38.42z" />
        <path d="M754 49h4v92h-4z" opacity=".5" />
        <path d="M621.774 108.98a19.5 19.5 0 0 0-3.057-3.808 18.867 18.867 0 0 0-3.774-2.665 28.99 28.99 0 0 0 7.548-4.34 26.675 26.675 0 0 0 8.641-13.403 32.14 32.14 0 0 0-.755-19.304 21.399 21.399 0 0 0-6.037-8.68 28.897 28.897 0 0 0-10.68-5.712 53.212 53.212 0 0 0-15.81-2.056H570V141h19.85v-34.039h5.056a5.727 5.727 0 0 1 5.736 3.275l14.226 26.652a7.534 7.534 0 0 0 7.17 4.074H640l-18.226-31.983zm-9.812-25.358a11.446 11.446 0 0 1-2.641 4.569 12.102 12.102 0 0 1-4.68 3.046c-2.2.8-4.53 1.175-6.867 1.104h-8V64.965h8a16.201 16.201 0 0 1 11.32 3.427 12.164 12.164 0 0 1 3.774 9.442c.01 1.966-.297 3.92-.906 5.788zM534.104 49v54.514a30.532 30.532 0 0 1-1.177 8.68 18.024 18.024 0 0 1-3.34 6.47 14.545 14.545 0 0 1-5.354 4.074 17.726 17.726 0 0 1-7.175 1.409 17.991 17.991 0 0 1-7.176-1.409 14.886 14.886 0 0 1-5.429-4.111 19.51 19.51 0 0 1-3.34-6.51 30.532 30.532 0 0 1-1.14-8.68V49h-19.97v54.514a45.336 45.336 0 0 0 2.544 15.531 35.498 35.498 0 0 0 7.327 12.182 32.684 32.684 0 0 0 11.656 7.918 43.247 43.247 0 0 0 30.903 0 32.874 32.874 0 0 0 11.618-7.918 35.574 35.574 0 0 0 7.365-12.182 45.336 45.336 0 0 0 2.582-15.531V49h-19.894zM400.354 49v16.444H439.5l-40.709 61.853a10.538 10.538 0 0 0-1.295 2.626 9.162 9.162 0 0 0-.496 2.855V141h66.704v-16.444h-41.166l40.556-61.663a11.83 11.83 0 0 0 1.41-2.969c.335-1.032.502-2.112.496-3.197v-7.613L400.354 49zM352.009 123.947V49H332v92h52v-17.053zM109 59H75v46h15.43V74.308H109zM117 64h15v15h-15z" />
        <path d="M120.692 104.363H90V120l45.924-.038H136V86h-15.308z" />
        <path d="M195.796 39.136l-82.6-35.837L105.632 0l-7.564 3.3-82.713 35.836L0 45.81l4.5 16.155 18.91 68.716 2.119 7.584 6.883 3.793 63.652 36.481 9.493 5.461 9.53-5.46 63.5-36.293 6.884-3.792 2.118-7.584 18.91-68.716L211 46l-15.204-6.864zm-26.701 86.387l-63.538 36.33-63.5-36.33-19.062-68.64 82.562-35.912 82.6 35.913-19.062 68.64zM237 140.994h13.247a6.63 6.63 0 0 0 4.213-1.294 8.524 8.524 0 0 0 2.43-3.311l4.972-16.062H293.1l5.049 16.062a7.915 7.915 0 0 0 2.353 3.16 6.252 6.252 0 0 0 4.175 1.445H318L292.683 49h-30.366L237 140.994zm29.53-35.168l11.122-39.508 10.78 39.508H266.53zM648 140.994h13.253a6.635 6.635 0 0 0 4.215-1.294 8.525 8.525 0 0 0 2.43-3.311l5.051-16.062h31.254l4.974 16.062a7.611 7.611 0 0 0 2.317 3.16 6.257 6.257 0 0 0 4.177 1.445H729L703.709 49h-30.38L648 140.994zm29.544-35.168l11.127-39.508 10.899 39.508h-22.026z" />
      </g>
    </svg>
  );
};

Logo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};
