import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './style';
import { useTranslator } from 'i18n';

import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import {
  BooleanIcon,
  Color,
} from 'components';

import { AlzuraSpriteIcon } from 'components';

export const StorageItem = ({
  onStorageProductDetail,
  onStorageReassignStock,
  onStorageProductEdit,
  storageProduct,
  canReassignStock,
  canCorrectStock,
}) => {
  const { classes } = useStyles();
  const t = useTranslator();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Paper className={classes.root}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant="body1">{t.translate('Storage')}:&nbsp;</Typography>
          <Typography variant="body1">{storageProduct.storage.id}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="column">
            <Grid container justifyContent="space-between">
              <Typography variant="body1">{t.translate('Building')}</Typography>
              <Typography variant="body1">{storageProduct.storage.building || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body1">{t.translate('Floor')}</Typography>
              <Typography variant="body1">{storageProduct.storage.floor || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body1">{t.translate('Room')}</Typography>
              <Typography variant="body1">{storageProduct.storage.room || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body1">{t.translate('Row')}</Typography>
              <Typography variant="body1">{storageProduct.storage.row || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body1">{t.translate('Shelf')}</Typography>
              <Typography variant="body1">{storageProduct.storage.shelf || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body1">{t.translate('Level')}</Typography>
              <Typography variant="body1">{storageProduct.storage.level || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body1">{t.translate('Box number')}</Typography>
              <Typography variant="body1">{storageProduct.storage.boxNumber || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body1">{t.translate('Box color')}</Typography>
              <Color box color={storageProduct.storage.boxColor || 'no color'} />
            </Grid>
            <Grid container justifyContent="space-between">
              <Button
                style={{ width: '100%', margin: '16px 0' }}
                className={classes.button}
                startIcon={<AlzuraSpriteIcon id="cupboard" />}
                onClick={() => onStorageProductDetail(storageProduct)}
              >
                {t.translate('Open storage')}
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
      </Accordion>
      <Grid container direction="column">
        <Grid container item justifyContent="space-between">
          <Typography className={classes.heading}>{t.translate('Exclude from available stock')}</Typography>
          <BooleanIcon truthy={storageProduct.storage.excludeStock}/>
        </Grid>
        <Grid container item justifyContent="space-between">
          <Typography className={classes.heading}>{t.translate('Stock')}</Typography>
          <Typography variant="body1">{storageProduct.totalStock}</Typography>
        </Grid>
      </Grid>
      <Grid container direction="row-reverse" style={{  marginTop: '8px' }} >
        {canCorrectStock && (
          <Grid item >
            <Button
              style={{ marginLeft: '8px' }}
              className={classes.button}
              startIcon={<EditIcon />}
              onClick={() => onStorageProductEdit(storageProduct)}
            >
              {t.translate('Edit')}
            </Button>
          </Grid>
        )}
        {canReassignStock && !!Number(storageProduct.totalStock) && (
          <Grid item >
            <Button
              className={classes.button}
              startIcon={<AlzuraSpriteIcon id="assignmentReturn" />}
              onClick={() => onStorageReassignStock(storageProduct)}
            >
              {t.translate('Reassign')}
            </Button>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

StorageItem.propTypes = {
  onStorageProductDetail: PropTypes.func.isRequired,
  onStorageReassignStock: PropTypes.func.isRequired,
  onStorageProductEdit: PropTypes.func.isRequired,
  canCorrectStock: PropTypes.bool.isRequired,
  canReassignStock: PropTypes.bool.isRequired,
  storageProduct: PropTypes.shape({
    totalStock: PropTypes.number.isRequired,
    storage: PropTypes.shape({
      boxNumber: PropTypes.string,
      boxColor: PropTypes.string,
      building: PropTypes.string.isRequired,
      floor: PropTypes.string,
      shelf: PropTypes.string,
      level: PropTypes.string,
      room: PropTypes.string,
      row: PropTypes.string,
      id: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};
