import { makeStyles } from 'tss-react/mui';
import { styleMobileTableItem } from 'theme/mixins/table';

export const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  accordion: {
    background: theme.palette.backgrounds.main['50'],
    boxShadow: 'none',
    '& .MuiCollapse-root': {
      background: theme.palette.common.white,
    },
  },
  bold: {
    fontWeight: 500,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.l600,
    },
  },
  item: {
    background: theme.palette.common.white,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(12),
  },
  headerLabel: {
    fontWeight: 500,
    marginRight: theme.spacing(1.5),
  },
  packageProductLabel: {
    marginLeft: theme.spacing(0.75),
  },
  photo: {
    flexShrink: 0,
    height: '35px',
    margin: theme.spacing(2, 0, 1.5),
    marginRight: theme.spacing(1),
    width: '35px',
  },
  photoHeader: {
    flexShrink: 0,
    height: '35px',
    margin: theme.spacing(0, 0.5),
    width: '35px',
  },
  removeButton: {
    background: theme.palette.primary.main,
    border: `solid 1px ${theme.palette.grey['150']}`,
    borderRadius: '12px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.12)',
    color: theme.palette.common.white,
    justifyContent: 'space-around',
    height: '24px',
    minWidth: '55px',
    padding: '0 !important',
    '& .MuiButton-label': {
      marginLeft: theme.spacing(1),
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.l600,
    },
  },
}));
