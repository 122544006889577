import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';

const useStyles = makeStyles()(theme => ({
  root: {
    height: theme.spacing(1.5),
    width: theme.spacing(1.5),
  },
}));

export const ColorBox = ({ color, ...props }) => (
  <Box
    component="i"
    display="inline-block"
    {...props}
    className={useStyles().classes.root}
    style={color !== '#212121' ? { background: color } : { background: 'none', border: '1px dashed #cdcdcd' }}
  />
);

ColorBox.propTypes = {
  color: PropTypes.string.isRequired,
};
