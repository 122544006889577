import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Radio from '@mui/material/Radio';

import { Color, CircularLoader } from 'components';

export const StoragesTable = ({
  onStorageSelect,
  selectedStorageId,
  isLoading,
  storages,
}) => {
  const t = useTranslator();

  if (isLoading) {
    return <CircularLoader />;
  } else {
    return (
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{t.translate('Select')}</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>{t.translate('Building')}</TableCell>
              <TableCell>{t.translate('Floor')}</TableCell>
              <TableCell>{t.translate('Room')}</TableCell>
              <TableCell>{t.translate('Shelf')}</TableCell>
              <TableCell>{t.translate('Level')}</TableCell>
              <TableCell>{t.translate('Box number')}</TableCell>
              <TableCell>{t.translate('Box color')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {storages.map((storage, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Radio
                    checked={selectedStorageId === storage.id}
                    onChange={() => onStorageSelect(storage)}
                    value={storage.id}
                    name="radio-buttons"
                    inputProps={{ 'aria-label': storage.id }}
                  />
                </TableCell>
                <TableCell>{storage.id}</TableCell>
                <TableCell>{storage.building}</TableCell>
                <TableCell>{storage.floor}</TableCell>
                <TableCell>{storage.room}</TableCell>
                <TableCell>{storage.shelf}</TableCell>
                <TableCell>{storage.level}</TableCell>
                <TableCell>{storage.boxNumber}</TableCell>
                <TableCell>{storage.boxColor ? <Color box color={storage.boxColor} /> : '—'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
};

StoragesTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onStorageSelect: PropTypes.func.isRequired,
  selectedStorageId: PropTypes.number,
  storages: PropTypes.arrayOf(
    PropTypes.shape({
      boxNumber: PropTypes.string,
      boxColor: PropTypes.string,
      building: PropTypes.string.isRequired,
      floor: PropTypes.string,
      shelf: PropTypes.string,
      level: PropTypes.string,
      room: PropTypes.string,
      row: PropTypes.string,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
};
