import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslator } from 'i18n';
import {
  useScreen,
  useDialog,
  useList, didMount,
} from 'hooks';

import AddIcon from '@mui/icons-material/Add';

import { StorageTicket, Fab } from 'components';
import {
  PrintingStoragesDialog,
  QrCodePrintDialog,
  ConfirmingDialog,
  StorageDialog,
  ImportStoragesDialog,
  StorageReassignDialog,
} from 'dialogs';
import {
  DesktopView,
  MobileView,
} from 'views/storages/overview';

import { fetchWarehouses } from 'services/warehouses';
import { fetchStorages } from 'services/storages';
import { download } from 'services/files';
import { deleteStorage, exportStorages, uploadStorages } from 'api/storages';
import {
  selectOverviewStoragesMetadata,
  selectOverviewStorages,
} from 'store/selectors/storages';
import {
  selectAuthenticatedUserWarehouse,
  selectActionPermission,
} from 'store/selectors/account';

import toaster from 'services/toaster';
import tables from 'config/tables';
import { StorageTypes } from '../../../consts';

export const StoragesOverview = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const screen = useScreen();
  const list = useList(tables.storages.storages);
  const t = useTranslator();

  const canViewWarehouses = useSelector(selectActionPermission('warehouse', 'view'));
  const canDeleteStorage = useSelector(selectActionPermission('storages', 'delete'));
  const canCreateStorage = useSelector(selectActionPermission('storages', 'create'));
  const canEditStorage = useSelector(selectActionPermission('storages', 'edit'));
  const canExportStorage = useSelector(selectActionPermission('storages', 'export'));
  const canReassignStock = useSelector(selectActionPermission('products', 'reassignStock'));
  const canEditTransitOption = useSelector(selectActionPermission('storages', 'transit'));
  const canEditExcludeStockOption = useSelector(selectActionPermission('storages', 'excludeStock'));
  const storagesMetadata = useSelector(selectOverviewStoragesMetadata);
  const storages = useSelector(selectOverviewStorages);
  const warehouse = useSelector(selectAuthenticatedUserWarehouse);

  const printingBulkDialog = useDialog();
  const confirmingDialog = useDialog({ storage: null });
  const printingDialog = useDialog({ storage: null });
  const storageDialog = useDialog({ storage: null, canEditTransitOption: false, canEditExcludeStockOption: false });
  const importDialog = useDialog();
  const storageReassignDialog = useDialog({ storage: null });

  didMount(() => changeTab(StorageTypes.DEFAULT));

  const fetchData = useCallback(() => {
    const payload = list.compile();
    if (payload.type) {
      dispatch(fetchStorages(list.compile()));
      if (canViewWarehouses) {
        dispatch(fetchWarehouses(list.compile()));
      }
    }
  }, [dispatch, canViewWarehouses, list]);

  useEffect(() => void fetchData(), [fetchData, location.search, warehouse]);

  const removeStorage = async () => {
    try {
      await deleteStorage(confirmingDialog.data.storage.id);
      toaster.success(t.translate('Storage has been deleted successfully.'));
      fetchData();
    } catch (err) {}
  };

  const exportAllStorages = async () => {
    try {
      const file = await exportStorages();
      if (file) {
        toaster.success(t.translate('Storages has been successfully exported.'));
        download(file);
      }
    } catch (err) {}
  };

  const handleStoragesImport = async (data, onError, mode) => {
    try {
      data.append('mode', mode);
      await uploadStorages(data);
      toaster.success(t.translate('The storage locations have been loaded successfully.'));
      importDialog.close();
    } catch (err) {
      if (err.filePath) {
        download(err);
      }
      onError(err);
    }
  };

  const changeTab = tab => list.changeFilter('type', tab);

  return (
    <>
      <Helmet>
        <title>{t.translate('Storages')} | {t.translate('app:title')}</title>
      </Helmet>

      {canReassignStock && storageReassignDialog.visible && (
        <StorageReassignDialog {...storageReassignDialog.props} isMobile={screen.isMobile}/>
      )}

      {canExportStorage && importDialog.visible && (
        <ImportStoragesDialog
          {...importDialog.props}
          onStoragesImport={handleStoragesImport}
        />
      )}

      {canDeleteStorage && (
        <ConfirmingDialog
          {...confirmingDialog.props}
          title={t.translate('Delete storage')}
          acceptButtonText={t.translate(`Yes, I'm sure`)}
          declineButtonText={t.translate('No, cancel')}
          onAccept={removeStorage}
        >
          {t.translate('Are you sure you want to delete the storage?')}
        </ConfirmingDialog>
      )}

      {((storageDialog.data.storage && canEditStorage) ||
      (!storageDialog.data.storage && canCreateStorage)) && (
        <>
          <Fab onClick={() => storageDialog.open({ storage: null, canEditTransitOption, canEditExcludeStockOption })} aria-label="Create storage" variant="extended">
            <AddIcon />
            {t.translate('Create storage')}
          </Fab>

          {storageDialog.visible && (
            <StorageDialog
              {...storageDialog.props}
              isMobile={screen.isMobile}
              onSave={fetchData}
            />
          )}
        </>
      )}

      <QrCodePrintDialog
        {...printingDialog.props}
        {...(printingDialog.data.storage ? {
          ticket: <StorageTicket storage={printingDialog.data.storage} />,
          value: printingDialog.data.storage.id.toString(),
        } : {
          ticket: '',
          value: '',
        })}
      />

      <PrintingStoragesDialog {...printingBulkDialog.props} />

      {screen.isMobile ? (
        <MobileView
          storagesMetadata={storagesMetadata}
          storages={storages}
          canDeleteStorage={canDeleteStorage}
          canEditStorage={canEditStorage}
          onStorageDelete={storage => confirmingDialog.open({ storage })}
          onStoragePrint={storage => printingDialog.open({ storage })}
          onStorageEdit={storage => storageDialog.open({ storage, canEditTransitOption, canEditExcludeStockOption })}
          canExportStorage={canExportStorage}
          onStoragesExport={exportAllStorages}
          onStoragesImport={() => importDialog.open()}
          canReassignStock={canReassignStock}
          onStorageReassign={() => storageReassignDialog.open()}
          onStoragesPrint={() => printingBulkDialog.open()}
          onTabChange={changeTab}
        />
      ) : (
        <DesktopView
          storagesMetadata={storagesMetadata}
          storages={storages}
          canExportStorage={canExportStorage}
          canDeleteStorage={canDeleteStorage}
          canEditStorage={canEditStorage}
          onStorageDelete={storage => confirmingDialog.open({ storage })}
          onStoragePrint={storage => printingDialog.open({ storage })}
          onStorageEdit={storage => storageDialog.open({ storage, canEditTransitOption, canEditExcludeStockOption })}
          onStoragesExport={exportAllStorages}
          onStoragesImport={() => importDialog.open()}
          canReassignStock={canReassignStock}
          onStorageReassign={() => storageReassignDialog.open()}
          onStoragesPrint={() => printingBulkDialog.open()}
          onTabChange={changeTab}
        />
      )}
    </>
  );
};
