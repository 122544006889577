import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControlError } from 'components';

export const CheckboxControl = ({
  label,
  error,
  fullWidth = true,
  ...props
}) => (
  <FormControl fullWidth={fullWidth} error={!!error}>
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          {...props}
        />
      }
      label={label}
    />
    {!!error && <FormControlError error={error} />}
  </FormControl>
);

CheckboxControl.propTypes = {
  label: PropTypes.string,
  error: PropTypes.any,
  fullWidth: PropTypes.bool,
};
