import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import { useTranslator } from 'i18n';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import InfoIcon from '@mui/icons-material/Info';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import {
  CheckboxControl,
  InputControl,
  BusyButton,
} from 'components';

import { modifyCustomer } from 'api/customers';
import { extractApiErrors } from 'utils/api';
import toaster from 'services/toaster';

import { useStyles } from './style.js';

const makeId = postfix => `customer-dialog--${postfix}`;

export const CustomerDialog = ({
  onClose,
  onSave,
  customer,
  isMobileView,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const title = customer
    ? 'Edit customer'
    : 'Create customer';

  const [initialValues, setInitialValues] = useState({
    name: '',
    number: '',
    company: '',
    email: '',
    invoiceWithoutVat: false,
    createInvoices: true,
  });

  const onSubmit = async (values, { setErrors }) => {
    try {
      const payload = {
        createInvoices: values.createInvoices,
        invoiceWithoutVat: values.createInvoices && values.invoiceWithoutVat,
      };

      if (customer) {
        await modifyCustomer(customer.id, payload);
        toaster.success(t.translate('Customer has been saved successfully.'));
      }

      onSave();
      onClose();
    } catch (err) {
      setErrors(extractApiErrors(err));
    }
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    enableReinitialize: true,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues,
    onSubmit,
  });

  const [anchorEl, setAnchorEl] = useState({ invoiceWithoutVat: null });

  const handlePopoverOpen = (name, event) => {
    setAnchorEl(prevState => ({
      ...prevState,
      [name]: event.currentTarget,
    }));
  };

  const handlePopoverClose = (name) => {
    setAnchorEl(prevState => ({
      ...prevState,
      [name]: null,
    }));
  };

  useEffect(() => {
    if (customer) {
      setInitialValues(initialValues => ({
        ...initialValues,
        name: customer.name,
        number: customer.number,
        company: customer.company || '',
        email: customer.email || '',
        invoiceWithoutVat: customer.invoiceWithoutVat || false,
        createInvoices: customer.createInvoices,
      }));
    }
  }, [customer]);

  return (
    <Dialog
      {...props}
      scroll="body"
      fullWidth
      fullScreen={isMobileView}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">{t.translate(title)}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" className={classes.blockLabel}>{t.translate('Customer data')}</Typography>
        <Grid container spacing={4} direction="column" >
          <Grid item xs={12}>
            <InputControl
              id={makeId('number')}
              name="number"
              error={errors.number}
              label={t.translate('Number')}
              value={values.number}
              onChange={handleChange}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <InputControl
              id={makeId('name')}
              name="name"
              error={errors.name}
              label={t.translate('Name')}
              value={values.name}
              onChange={handleChange}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <InputControl
              id={makeId('company')}
              name="company"
              error={errors.company}
              label={t.translate('Company')}
              value={values.company}
              onChange={handleChange}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <InputControl
              id={makeId('email')}
              name="email"
              error={errors.email}
              label={t.translate('Email')}
              value={values.email}
              onChange={handleChange}
              disabled
            />
          </Grid>
          <Grid item xs={12} container>
            <CheckboxControl
              name="createInvoices"
              error={errors.createInvoices}
              label={t.translate('Create invoices')}
              checked={!!values.createInvoices}
              onChange={handleChange}
            />
          </Grid>
          {values.createInvoices && (
            <Grid item xs={12} container className={classes.containerItem}>
              <CheckboxControl
                name="invoiceWithoutVat"
                error={errors.invoiceWithoutVat}
                label={t.translate('Invoice without VAT')}
                checked={!!values.invoiceWithoutVat}
                onChange={handleChange}
              />
              <IconButton
                aria-label="hint"
                aria-owns={Boolean(anchorEl.invoiceWithoutVat) ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={e => handlePopoverOpen('invoiceWithoutVat', e)}
                onMouseLeave={() => handlePopoverClose('invoiceWithoutVat')}
                onClick={e => handlePopoverOpen('invoiceWithoutVat', e)}
                size="large">
                <InfoIcon />
              </IconButton>
              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{ paper: classes.paper }}
                open={Boolean(anchorEl.invoiceWithoutVat)}
                anchorEl={anchorEl.invoiceWithoutVat}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => handlePopoverClose('invoiceWithoutVat')}
                disableRestoreFocus
              >
                <Typography variant="body2" className={classes.hint}>
                  {t.translate('customers:invoiceWithoutVatHint')}
                </Typography>
              </Popover>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
        <BusyButton color="primary" submit onClick={handleSubmit}>{t.translate('Save')}</BusyButton>
      </DialogActions>
    </Dialog>
  );
};

CustomerDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  customer: PropTypes.shape({
    number: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    company: PropTypes.string,
    email: PropTypes.string,
    invoiceWithoutVat: PropTypes.bool.isRequired,
  }),
};
