import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import toaster from 'services/toaster';
import { didUpdate, useForm } from 'hooks';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';

import {
  StorageAutocomplete,
  useStorageAutocomplete,
  useSupplierAutocomplete,
} from 'autocompletes';

import {
  StockFormControl,
  FormControlError,
  InputControl,
  BusyButton,
} from 'components';

import { fetchStoredSpecialCaseStorageProductStock } from 'api/special-cases';
import { fetchStorage } from 'api/storages';
import { storeSpecialCase } from 'api/special-cases';
import {
  SpecialCaseStatuses,
} from 'consts';

const useStyles = makeStyles()(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: theme.spacing(84),
    },
  },
  title: {
    paddingBottom: 0,
  },
  content: {
    padding: theme.spacing(3),
  },
  stockFormContainer: {
    padding: theme.spacing(3),
    border: `solid 1px ${theme.palette.grey['255']}`,
  },
  buttonContainer: {
    padding: theme.spacing(3),
  },
}));

const makeId = postfix => `special-case-store-dialog--${postfix}`;

export const SpecialCaseStoreDialog = ({
  specialCase,
  isMobile,
  onClose,
  onSave,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const form = useForm({
    storageNumber: specialCase.storageId.toString(),
    supplierNumber: '',
    comment: '',
    stock: specialCase.stock,
    color: null,
  });
  const {
    catchHttpError,
    isModelValid,
    setFormValue,
    resetForm,
    setValue,
    errors,
    model,
  } = form;

  const [storageProductStock, setStorageProductStock] = useState(0);
  const fetchStorageProductStock = async storageId => {
    try {
      const { storageProductStock } = await fetchStoredSpecialCaseStorageProductStock(specialCase.id, storageId);
      setStorageProductStock(storageProductStock);
    } catch (err) {
      setStorageProductStock(0);
      catchHttpError(err);
    }
  };

  const supplierAutocomplete = useSupplierAutocomplete({ form });
  const storageAutocomplete = useStorageAutocomplete({ form });

  const changeStorageNumber = value => {
    storageAutocomplete.props.onChange(value);
    if (value.trim()) {
      fetchStorageProductStock(value.trim());
    } else {
      setStorageProductStock(0);
    }
  };

  didUpdate(() => {
    if (props.open) {
      storageAutocomplete.setSelectedOption({ id: specialCase.storageId });
      supplierAutocomplete.setSelectedOption({ id: specialCase.storageId });
      fetchStorageProductStock(specialCase.storageId);
      resetForm();
    }
  }, [props.open]);

  const validateModel = errors => {
    if (!storageAutocomplete.selectedOption) {
      errors.storageNumber = t.translate('Please specify an existing storage.');
    }
    if (!supplierAutocomplete.selectedOption) {
      errors.supplierNumber = t.translate('Please specify an existing supplier.');
    }
  };

  const submit = async () => {
    try {
      if (!isModelValid(validateModel)) {
        return;
      }

      await storeSpecialCase(specialCase.id, {
        ...(({ storageNumber, ...model }) => model)(model),
        supplierId: supplierAutocomplete.selectedOption.id,
        storageId: storageAutocomplete.selectedOption.id,
      });
      toaster.success(t.translate('Special case has been stored successfully.'));

      onSave();
      onClose();
    } catch (err) {
      catchHttpError(err);
    }
  };

  useEffect(() => {
    if (storageAutocomplete.selectedOption?.warehouse && !storageAutocomplete.selectedOption?.boxNumber) {
      setValue('color')('black');
    } else {
      setValue('color')(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageAutocomplete.selectedOption]);

  useEffect(() => {
    const setInitialColor = async() => {
      const storage = await fetchStorage(specialCase.id);
      if (!storage.boxNumber) {
        setValue('color')('black');
      }
    };
    setInitialColor();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialCase.id]);

  return (
    <Dialog {...props} onClose={onClose} className={classes.root} fullScreen={isMobile}>
      <DialogTitle className={classes.title}>
        {t.translate('Store')}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container direction="column" spacing={3}>
          <Grid item container spacing={3} direction={isMobile ? 'column' : 'row'}>
            <Grid item md={6}>
              <InputControl
                id={makeId('status')}
                label="Status"
                value={t.translate(SpecialCaseStatuses[specialCase.status].text)}
                disabled
              />
            </Grid>
            <Grid item md={6}>
              <StorageAutocomplete
                {...storageAutocomplete.props}
                label={t.translate('Storage')}
                onChange={changeStorageNumber}
              />
            </Grid>
          </Grid>

          <Grid item container spacing={3} direction="column">
            <Grid item md={12}>
              <InputLabel shrink htmlFor={props.id}>{t.translate('Stock')}</InputLabel>
              <Grid className={classes.stockFormContainer}>
                <FormControl fullWidth error={!!errors.stock}>
                  <StockFormControl
                    actions={['add', ...(model.stock > 0 ? ['subtract'] : [])]}
                    stock={storageProductStock}
                    value={model.stock}
                    onChange={setValue('stock')}
                  />
                  {!!errors.stock && <FormControlError error={errors.stock} />}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <InputControl
                id={makeId('comment')}
                error={errors.comment}
                label={t.translate('Comment')}
                value={model.comment}
                placeholder={t.translate('Annotations')}
                onChange={setFormValue('comment')}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.buttonContainer}>
        <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
        <BusyButton color="primary" submit onClick={submit}>{t.translate('Save')}</BusyButton>
      </DialogActions>
    </Dialog>
  );
};

SpecialCaseStoreDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  specialCase: PropTypes.shape({
    productName: PropTypes.string,
    product: PropTypes.shape({
      alzuraId: PropTypes.string,
    }),
    status: PropTypes.oneOf(['done']).isRequired,
    stock: PropTypes.number.isRequired,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    storageId: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
};
