import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { omit } from 'lodash';

import { useTranslator } from 'i18n';
import { didUpdate } from 'hooks';

import { makeStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import {
  CheckboxControl,
  PasswordInput,
  InputControl,
  BusyButton,
} from 'components';

import { styleDefaultFormDialog } from 'theme/mixins/dialog';
import { createPermissionsListData } from 'services/account';
import { extractApiErrors } from 'utils/api';
import { passwordRegEx } from 'consts';
import { menuItems } from 'config/navigation';
import {
  fetchDefaultPermissions,
  modifyUser,
  createUser,
} from 'api/users';

import toaster from 'services/toaster';
import config from 'config';
import { useSelector } from 'react-redux';
import { isAuthenticatedUserAdministrator } from '../../store/selectors/account';

const useStyles = makeStyles()(theme => styleDefaultFormDialog(theme, {
  sectionGrid: {
    border: `1px solid ${theme.palette.grey['215']}`,
    borderRadius: '2px',
    flexFlow: 'column wrap',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      flexFlow: 'row wrap',
    },
  },
  permissions: {
    marginTop: theme.spacing(4),
  },
  viewAction: {
    '&.MuiGrid-item': {
      padding: theme.spacing(2, 2, 1),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2, 2),
      },
    },
  },
  bold: {
    fontWeight: 500,
  },
  permissionAction: {
    backgroundColor: theme.palette.backgrounds.grey['205'],
    borderLeft: 'none',
    display: 'flex',
    flexFlow: 'column wrap',
    paddingLeft: theme.spacing(1.8),
    maxHeight: 'none',
    [theme.breakpoints.up('md')]: {
      borderLeft: `1px solid ${theme.palette.backgrounds.grey['170']}`,
      paddingLeft:theme.spacing(3),
      maxHeight: '140px',
    },
  },
}));

const makeId = postfix => `user-dialog--${postfix}`;

export const UserDialog = ({
  onClose,
  onSave,
  user,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const isAdministrator = useSelector(isAuthenticatedUserAdministrator);

  const [defaultPermissions, setDefaultPermissions] = useState(null);

  const [initialValues, setInitialValues] = useState({
    passwordConfirmation: '',
    dailyStatusMail: false,
    permissions: defaultPermissions || {},
    defaultView: '/products',
    isDisponent: false,
    password: '',
    isAdmin: false,
    isActive: true,
    isSendFailedOrdersMail: false,
    locale: '',
    email: '',
    name: '',
  });

  const validationSchema = Yup
    .object()
    .shape({
      name: Yup
        .string()
        .trim()
        .required(t.translate('Name is required.')),
      email: Yup
        .string()
        .trim()
        .email(t.translate('Email is incorrect.'))
        .required(t.translate('Email is required.')),
      ...(!user && {
        password: Yup
          .string()
          .trim()
          .matches(passwordRegEx, t.translate('Password must be at least {length} characters and must contain at least one upper case letter, one lower case letter, one special character and one digit', { length: 8 }))
          .required(t.translate('Password is required.')),
        passwordConfirmation: Yup
          .mixed()
          .test('match', t.translate('Passwords do not match.'), function () {
            return this.parent.passwordConfirmation === this.parent.password;
          }),
      }),
    });

  const onSubmit = async (values, { setErrors }) => {
    try {
      const payload = {
        ...omit(values, 'passwordConfirmation'),
      };

      if (user) {
        await modifyUser(user.id, payload);
        toaster.success(t.translate('The user has been saved successfully.'));
      } else {
        await createUser(payload);
        toaster.success(t.translate('New user has been created successfully.'));
      }

      onSave();
      onClose();
    } catch (err) {
      setErrors(extractApiErrors(err));
    }
  };

  const {
    handleSubmit,
    handleChange,
    resetForm,
    values,
    errors,
  } = useFormik({
    enableReinitialize: true,
    validateOnMount: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    if (props.open && !user && !defaultPermissions) {
      const getDefaultPermissions = async () => {
        try {
          const defaultPermissions = await fetchDefaultPermissions();
          setDefaultPermissions(defaultPermissions);
          setInitialValues(initialValues => ({ ...initialValues, permissions: defaultPermissions }));
        } catch (err) { }
      };
      getDefaultPermissions();
    }
  }, [
    defaultPermissions,
    props.open,
    user,
  ]);

  useEffect(() => {
    if (user) {
      setInitialValues(initialValues => ({
        ...initialValues,
        dailyStatusMail: user.dailyStatusMail,
        isDisponent: user.isDisponent,
        permissions: user.permissions,
        defaultView: user.defaultView,
        isAdmin: user.isAdmin,
        isActive: user.isActive,
        isSendFailedOrdersMail: user.isSendFailedOrdersMail,
        locale: user.locale || '',
        email: user.email,
        name: user.name,
      }));
    }
  }, [user]);

  didUpdate(() => void (props.open && resetForm()), [props.open]);

  const permissions = createPermissionsListData(values.permissions);
  const defaultViews = menuItems.filter(
    ({ path, name }) => name === 'account'
      || path === values.defaultView
      || values.isAdmin
      || (user && values.permissions[name]?.view)
      || (!user && values.permissions[name])
  );

  return !!Object.keys(values.permissions).length && (
    <Dialog {...props} scroll="body" maxWidth={false} classes={{ paperScrollBody: classes.root }} onClose={onClose}>
      <DialogTitle className={classes.title}>{t.translate(user ? 'Edit user' : 'Create user')}</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container direction="column">
          <Grid container spacing={3} style={{ flexWrap: 'wrap' }}>
            <Grid item xs={12} md={6}>
              <InputControl
                id={makeId('name')}
                name="name"
                error={errors.name}
                label={t.translate('Name')}
                value={values.name}
                required
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputControl
                id={makeId('email')}
                name="email"
                error={errors.email}
                label={t.translate('Email')}
                value={values.email}
                required
                onChange={handleChange}
              />
            </Grid>
            {!user && (
              <>
                <Grid item xs={12} md={6}>
                  <InputControl
                    id={makeId('password')}
                    name="password"
                    error={errors.password}
                    label={t.translate('Password')}
                    value={values.password}
                    required
                    InputComponent={PasswordInput}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputControl
                    id={makeId('password-confirmation')}
                    name="passwordConfirmation"
                    error={errors.passwordConfirmation}
                    label={t.translate('Confirm password')}
                    value={values.passwordConfirmation}
                    required
                    InputComponent={PasswordInput}
                    onChange={handleChange}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel shrink htmlFor={makeId('default-view')}>{t.translate('Default view')}</InputLabel>
                <Select
                  id={makeId('default-view')}
                  name="defaultView"
                  value={values.defaultView}
                  displayEmpty
                  onChange={handleChange}
                >
                  {defaultViews.map(item => (
                    <MenuItem key={item.name} value={item.path}>{t.translate(item.text)}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel shrink htmlFor={makeId('locale')}>{t.translate('Language')}</InputLabel>
                <Select
                  id={makeId('locale')}
                  name="locale"
                  value={values.locale}
                  displayEmpty
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>{t.translate('Detect automatically')}</em>
                  </MenuItem>
                  {Object.entries(config.i18n.locales).map(([key, value]) => (
                    <MenuItem key={key} value={key}>{value}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel shrink htmlFor={makeId('is-active')}>{t.translate('Account status')}</InputLabel>
                  <Select
                    id={makeId('is-active')}
                    name="isActive"
                    value={values.isActive}
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>{t.translate('Active')}</MenuItem>
                    <MenuItem value={false}>{t.translate('Inactive')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <CheckboxControl
                id={makeId('admin')}
                name="isAdmin"
                error={errors.isAdmin}
                label={t.translate('Administrator')}
                checked={!!values.isAdmin}
                onChange={handleChange}
              />
              <CheckboxControl
                id={makeId('disponent')}
                name="isDisponent"
                error={errors.isDisponent}
                label={t.translate('Disponent')}
                checked={!!values.isDisponent}
                onChange={handleChange}
              />
              <CheckboxControl
                name="dailyStatusMail"
                error={errors.dailyStatusMail}
                label={t.translate('Send daily status mail')}
                checked={!!values.dailyStatusMail}
                disabled={!values.isAdmin && !values.permissions.dashboard.view}
                onChange={handleChange}
              />
              <CheckboxControl
                name="isSendFailedOrdersMail"
                error={errors.isSendFailedOrdersMail}
                label={t.translate('Send mail about failed orders')}
                checked={!!values.isSendFailedOrdersMail}
                disabled={!isAdministrator}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          {!values.isAdmin && (
            <Grid item container direction="column" className={classes.permissions}>
              <Grid item>
                <Typography className={classes.bold}>{t.translate('Page permissions')}</Typography>
              </Grid>
              {permissions.map(section => (
                <Grid item container key={section.key} className={classes.sectionGrid}>
                  <Grid item className={classes.viewAction} xs={12} md={3}>
                    <Grid item>
                      <Typography variant="body2" className={classes.bold}>{t.translate(section.name)}</Typography>
                    </Grid>
                    <CheckboxControl
                      name={`permissions.${section.key}.view`}
                      label={t.translate(`permission:view`)}
                      checked={section.actions[0].value}
                      onChange={handleChange}
                      fullWidth={false}
                    />
                  </Grid>
                  {section.actions.length > 1 && (
                    <Grid item className={classes.permissionAction} xs={12} md={9}>
                      {section.actions.map(action => action.key !== 'view' && (
                        <CheckboxControl
                          fullWidth={false}
                          key={action.key}
                          name={`permissions.${section.key}.${action.key}`}
                          label={t.translate(`permission:${action.key}`)}
                          checked={action.value}
                          disabled={!values.permissions[section.key].view}
                          onChange={handleChange}
                        />
                      ))}
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
        <BusyButton submit onClick={handleSubmit}>{t.translate('Save')}</BusyButton>
      </DialogActions>
    </Dialog>
  );
};

UserDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  user: PropTypes.shape({
    dailyStatusMail: PropTypes.bool.isRequired,
    isSendFailedOrdersMail: PropTypes.bool.isRequired,
    permissions: PropTypes.object.isRequired,
    defaultView: PropTypes.string,
    isAdmin: PropTypes.bool.isRequired,
    locale: PropTypes.string,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }),
};
