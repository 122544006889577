import React from 'react';

import { useSelector } from 'react-redux';
import { useTranslator } from 'i18n';
import {
  useTableSortableHeadCell,
  useList,
} from 'hooks';

import TableContainer from '@mui/material/TableContainer';
import { makeStyles } from 'tss-react/mui';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import MuiTable from '@mui/material/Table';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';

import { FiltersRow } from './FiltersRow/FiltersRow';
import {
  CollapseTableRow,
  Pagination,
  TableCell,
  TableRow,
  Table,
} from 'components';

import tables from 'config/tables';
import { CollapsedRow } from './CollapsedRow/CollapsedRow';
import Link from '@mui/material/Link';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

const useStyles = makeStyles()(theme => ({
  table: {
    '& th:first-of-type, & td:first-of-type': {
      paddingLeft: theme.spacing(3),
    },
    '& th:last-child, & td:last-child': {
      paddingRight: theme.spacing(3),
    },
  },
  text: {
    wordBreak: 'break-all',
  },
  button: {
    color: theme.palette.primary.l900,
  },
  chip: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(.5),
    borderRadius: theme.spacing(0.5),
    height: '26px',
    '& .MuiChip-label': {
      color: theme.palette.common.white,
      paddingLeft: theme.spacing(.75),
    },
    backgroundColor: '#03a9f4',
  },
}));

export const ChangesHistoryTable = ({ isDetailsTable, parentList }) => {
  const { classes } = useStyles();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const list = parentList || useList(tables.changesHistory.changesHistory);
  const t = useTranslator();
  const history = useHistory();

  const totalEntities = useSelector(({ changesHistory }) => changesHistory.changesHistory.meta.totalEntities);
  const changesHistory = useSelector(({ changesHistory }) => changesHistory.changesHistory.data);

  const SortableCell = useTableSortableHeadCell({
    changeSorting: list.changeSorting,
    sorting: list.sorting,
  });

  const getLink = (reference) => {
    const parseRef = reference.split(':');
    switch (parseRef[0]) {
      case 'product': return () => history.push(`/products/${parseRef[1]}`);
      case 'supplier': return () => history.push(`/edit-supplier/${parseRef[1]}`);
      default: return;
    }
  };

  return (
    <Paper>
      <TableContainer>
        {!isDetailsTable ?
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell width={50} />
                <SortableCell name="createdAt">{t.translate('Date')}</SortableCell>
                <SortableCell name="userName">{t.translate('User')}</SortableCell>
                <SortableCell name="reference">{t.translate('Reference')}</SortableCell>
                <SortableCell name="modelName">{t.translate('Entity')}</SortableCell>
                <SortableCell name="primaryId">{t.translate('Entity ID')}</SortableCell>
                <SortableCell name="transactionId">{t.translate('Transaction ID')}</SortableCell>
                <TableCell align="right"/>
              </TableRow>
              <FiltersRow
                visible={true}
                onFiltersEnable={() => true}
              />
            </TableHead>
            <TableBody>
              {changesHistory.map((history, index) => (
                <CollapseTableRow
                  key={index}
                  colSpan={10}
                  collapsedRow={<CollapsedRow changes={history.changes}/>}
                >
                  <TableCell>{t.formatDate(history.createdAt)} {t.formatTime(history.createdAt)}</TableCell>
                  <TableCell>{history.user?.name || t.translate('Auto')}</TableCell>
                  <TableCell>{history.reference ?
                    <Link onClick={getLink(history.reference)}>{history.reference}</Link> : '—'}</TableCell>
                  <TableCell>{history.modelName}</TableCell>
                  <TableCell>{history.primaryId}</TableCell>
                  <TableCell>{history.transactionId || '—'}</TableCell>
                </CollapseTableRow>
              ))}
            </TableBody>
          </Table>
          :
          <MuiTable className={classes.table}>
            <TableBody>
              {changesHistory.map((history, index) => {
                return history.changes.length === 1 ?
                  <MuiTableRow>
                    <MuiTableCell/>
                    <MuiTableCell>
                      <div style={{ display: 'flex', alignItems: 'center', height: 30 }}>
                        <span style={{ fontWeight: 'bold' }}>{t.translate(_.capitalize(_.toLower(_.startCase(history.changes[0].field))))}:</span>&nbsp;{`${history.changes[0].previousValue}`} -> {`${history.changes[0].value}`}
                      </div>
                    </MuiTableCell>
                    <MuiTableCell align="right">
                      {history.user?.name || t.translate('Auto')}&nbsp;-&nbsp;{t.formatDate(history.createdAt)} {t.formatTime(history.createdAt)}
                    </MuiTableCell>
                  </MuiTableRow>:
                  <CollapseTableRow
                    key={index}
                    colSpan={3}
                    collapsedRow={<CollapsedRow changes={history.changes}/>}
                  >
                    <TableCell>
                      <Chip className={`${classes.chip}`} label={`${history.changes.length} ${t.translate('changes')}`}/>
                    </TableCell>
                    <TableCell align="right">
                      {history.user?.name || t.translate('Auto')}&nbsp;-&nbsp;{t.formatDate(history.createdAt)} {t.formatTime(history.createdAt)}
                    </TableCell>
                  </CollapseTableRow>;
              })}
            </TableBody>
          </MuiTable>
        }
      </TableContainer>
      <Pagination {...list.paginationProps} count={totalEntities} />
    </Paper>
  );
};
