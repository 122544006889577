import { i18n } from 'config';

const LOCALE_KEY = 'locale';

export const loadLocale = () => localStorage.getItem(LOCALE_KEY) || i18n.defaultLocale;

export const saveLocale = locale => localStorage.setItem(LOCALE_KEY, locale);

export const detectLocale = () => {
  const language = navigator && navigator.language;

  if (language) {
    const locale = language.split('-')[0];

    return i18n.locales[locale] ? locale : i18n.defaultLocale;
  } else {
    return i18n.defaultLocale;
  }
};

export const humanizeLocale = (locale, { t }) => locale ? i18n.locales[locale] : t.translate('Automatically');
