import React from 'react';

export const DotInfo = ({ dotInfo }) => {
  return dotInfo?.length ?
    <pre
      style={{ width: 75 }}
    >
      {dotInfo.join('\n')}
    </pre>
    : '—';
};
