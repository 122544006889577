import React from 'react';

import { Column } from 'layouts';
import { PurchaseTable } from './PurchaseTable/PurchaseTable';

export const DesktopView = ({
  authenticatedUser,
  canEditPurchasePermission,
  hasSupervisorPermission,
  confirmApproveDialog,
  confirmDeclineDialog,
  confirmDeleteDialog,
}) => (
  <>
    <Column p={3}>
      <PurchaseTable
        confirmApproveDialog={confirmApproveDialog}
        confirmDeclineDialog={confirmDeclineDialog}
        confirmDeleteDialog={confirmDeleteDialog}
        authenticatedUser={authenticatedUser}
        canEditPurchasePermission={canEditPurchasePermission}
        hasSupervisorPermission={hasSupervisorPermission}
      />
    </Column>
  </>
);

DesktopView.propTypes = {
};
