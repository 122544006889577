import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import { styleMobileTableItem } from 'theme/mixins/table';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  featureLabel: {
    fontWeight: 500,
  },
}));

export const WarehouseCard = ({ warehouse }) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <Paper className={classes.root}>
      <Grid container direction="column" spacing={4}>
        <Grid item container>
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.featureLabel}>{t.translate('Address')}</Typography>
          </Grid>
          <Grid item xs >
            <Typography variant="body2" align="right">{warehouse.address.name || '—'}</Typography>
            <Typography variant="body2" align="right">{warehouse.address.street || '—'}&nbsp;{warehouse.address.house || ''}</Typography>
            <Typography variant="body2" align="right">{warehouse.address.zip || '—'}&nbsp;{warehouse.address.city || '—'}</Typography>
            <Typography variant="body2" align="right">{warehouse.address.country || '—'}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

WarehouseCard.propTypes = {
  warehouse: PropTypes.shape({
    address: PropTypes.shape({
      country: PropTypes.string.isRequired,
      street: PropTypes.string.isRequired,
      house: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
      name: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
