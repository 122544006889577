import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';

export const TextFilterFormControl = ({
  onToggle,
  onChange,
  enabled,
  value,
  label,
  ...props
}) => (
  <FormControl fullWidth>
    <InputLabel shrink htmlFor={props.id}>{label}</InputLabel>
    <Input
      {...props}
      value={value}
      startAdornment={
        <Checkbox
          color="primary"
          checked={enabled}
          onChange={({ target: { checked } }) => onToggle(checked)}
        />
      }
      onChange={({ target: { value } }) => onChange(value)}
    />
  </FormControl>
);

TextFilterFormControl.propTypes = {
  onToggle: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
};
