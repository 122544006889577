import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';
import {
  useTableSortableHeadCell,
  useList,
} from 'hooks';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Pagination } from 'components';

import { StorageProductOrSpecialCaseActionReasons } from 'consts';
import tables from 'config/tables';

export const HistoryTable = ({
  operations: {
    meta: operationsMetdata,
    data: operations,
  },
}) => {
  const list = useList('history', tables.returns.history);
  const t = useTranslator();

  const SortableCell = useTableSortableHeadCell({
    changeSorting: list.changeSorting,
    sorting: list.sorting,
  });

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <SortableCell name="createdAt">{t.translate('Date')}</SortableCell>
              <SortableCell name="userName">{t.translate('User')}</SortableCell>
              <SortableCell name="storageNumber">{t.translate('Storage')}</SortableCell>
              <SortableCell name="addedStock">{t.translate('Stock')}</SortableCell>
              <TableCell>{t.translate('table-column: Reason')}</TableCell>
              <SortableCell name="orderCode">{t.translate('Order number')}</SortableCell>
              <TableCell>{t.translate('table-column: Annotation')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {operations.map((operation, index) => (
              <TableRow key={index}>
                <TableCell>{t.formatDate(operation.createdAt)}<br />{t.formatTime(operation.createdAt)}</TableCell>
                <TableCell>{operation.user?.name || '—'}</TableCell>
                <TableCell>{operation.storageId}</TableCell>
                <TableCell>{operation.addedStock > 0 ? '+' : ''}{operation.addedStock}</TableCell>
                <TableCell>{t.translate(StorageProductOrSpecialCaseActionReasons[operation.reason].text)}</TableCell>
                <TableCell>{operation.orderCode || (operation.manual ? t.translate('Manually created') : '—')}</TableCell>
                <TableCell>{operation.comment || '—'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination {...list.paginationProps} count={operationsMetdata.totalEntities} />
    </>
  );
};

HistoryTable.propTypes = {
  operations: PropTypes.shape({
    meta: PropTypes.shape({
      totalEntities: PropTypes.number.isRequired,
    }).isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        addedStock: PropTypes.number.isRequired,
        orderCode: PropTypes.string,
        createdAt: PropTypes.string.isRequired,
        comment: PropTypes.string,
        reason: PropTypes.oneOf(Object.keys(StorageProductOrSpecialCaseActionReasons)).isRequired,
        manual: PropTypes.bool,
        user: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
        storageId: PropTypes.number,
      })
    ).isRequired,
  }).isRequired,
};

HistoryTable.defaultProps = {
  operations: {
    meta: {
      totalEntities: 0,
    },
    data: [],
  },
};
