import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';
import {
  AppHeaderPrimaryToolbar,
  AppHeaderMenuButton,
  AppHeaderTitle,
  AppHeader,
} from 'components';

export const Header = ({
  isMobileView,
  routeTitle,
}) => {
  const t = useTranslator();

  return (
    <AppHeader>
      <AppHeaderPrimaryToolbar>
        {isMobileView && (<AppHeaderMenuButton edge="start" />)}
        <AppHeaderTitle>
          {t.translate(routeTitle || 'Reports')}
        </AppHeaderTitle>
      </AppHeaderPrimaryToolbar>
    </AppHeader>
  );
};

Header.propTypes = {
  isMobileView: PropTypes.bool,
  routeTitle: PropTypes.string,
};
