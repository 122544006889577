import { RETAILERS } from 'store/types';
import { retailers as initialState } from 'store/initialState';

const setOverviewRetailers = (state, { data, meta }) => ({
  ...state,
  overview: {
    data,
    meta: {
      totalEntities: meta.totalEntities,
    },
  },
});

const setDetailsRetailer = (state, retailer) => ({
  ...state,
  details: {
    ...state.details,
    retailer,
  },
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case RETAILERS.OVERVIEW.SET_RETAILERS:
      return setOverviewRetailers(state, payload);
    case RETAILERS.DETAILS.SET_RETAILER:
      return setDetailsRetailer(state, payload);
    default:
      return state;
  }
};
