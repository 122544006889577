import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';

import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AlzuraSpriteIcon } from 'components';
import { useStyles } from '../Tables';

import {
  TableCell,
  TableRow,
  Table,
} from 'components';
import numeral from 'numeral';

export const StorageUsageTable = ({ storageUsage, warehouses }) => {
  const { classes } = useStyles();
  const t = useTranslator();

  return (
    <TableContainer>
      <Table stickyHeader className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell fixed width={130}>
              <Grid className={classes.titleFixedColumn}>
                <AlzuraSpriteIcon id='assessment'/>
                <Typography>{t.translate('Storage Usage')}</Typography>
              </Grid>
            </TableCell>
            <TableCell width={50} className={classes.headerCell} name="volume" align="right">
              {t.translate('Volume')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {warehouses.map(warehouse => {
            const storageUsageWarehouse = storageUsage.find(elem => elem.warehouseId === warehouse.id);
            return (
              <TableRow key={warehouse.id} className={classes.tableRow}>
                <TableCell className={classes.fixedCell}>
                  <Typography>
                    {`${warehouse.id} - ${warehouse.name}`}
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell} align="right" >
                  {storageUsageWarehouse?.usedStorage ?
                    <>{numeral(storageUsageWarehouse.usedStorage).format('0,0.00')}&nbsp;m<sup>3</sup></>
                    : <>0.00&nbsp;m<sup>3</sup></>}
                </TableCell>
              </TableRow>
          );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

StorageUsageTable.propTypes = {
  storageUsage: PropTypes.arrayOf(PropTypes.shape({
    warehouseId: PropTypes.number,
    warehouseName: PropTypes.string,
    usedStorage: PropTypes.number,
  })),
  warehouses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};
