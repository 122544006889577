import React from 'react';
import { UsersTable } from './UsersTable/UsersTable';
import { Column } from 'layouts';

export const DesktopView = () => (
  <Column filled>
    <UsersTable/>
  </Column>
);

DesktopView.propTypes = {};
