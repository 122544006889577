import React from 'react';
import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

const useStyles = makeStyles()(theme => ({
  disabled: {
    backgroundColor: theme.palette.action.hover,
    cursor: 'initial',
  },
  pointer: {
    cursor: 'pointer',
  },
}));

export const CustomTableRow = ({ disabled, onClick, children, ...props }) => {
  const { classes } = useStyles();
  const onClickHandler = (e) => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    onClick(e);
  };

  return <TableRow {...props} className={clsx(classes.pointer, disabled && classes.disabled)} onClick={e => onClickHandler(e)}>{children}</TableRow>;
};

CustomTableRow.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
