import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useTableLocallySortableHeadCell } from 'hooks';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import {
  ActionButton,
  DotInfo,
  OrderLink,
  Picture,
} from 'components';
import { findRelatedProductOrders } from 'utils/querying';
import { styleTableRow } from 'theme/mixins/table';
import Link from '@mui/material/Link';

const useStyles = makeStyles()(theme => styleTableRow(theme, {
  button: {
    color: theme.palette.primary.main,
  },
  defaultFontSize: {
    fontSize: '0.875rem',
  },
  doneIcon: {
    color: theme.palette.green.main,
  },
}));

const defaultAdditionalImage = (additionalImages = []) => (
  additionalImages?.find(image => image.isDefault)
);

export const ProductsTable = ({
  canEditPickupSheet,
  onPickupSheetProductEdit,
  onProductLocationShow,
  pickupSheet,
}) => {
  const { classes } = useStyles();
  const t = useTranslator();
  const history = useHistory();

  const openProduct = id => history.push(`/products/${id}`);
  const openSupplier = id => history.push(`/suppliers/${id}`);

  const SortableCell = useTableLocallySortableHeadCell({
    changeSorting: () => {},
    defaultSorting: {
      orderBy: 'id',
      order: 'asc',
    },
  });

  const getDotInfo = (id, orders) => {
    const dotInfo = [];

    if (orders && id) {
      orders.forEach(({ positions }) => {
        const relatedPosition = positions?.find(({ productId }) => productId === id);

        if (relatedPosition?.orderStorageProducts?.length) {
          relatedPosition.orderStorageProducts.forEach(({ manufacturingDate, quantity }) => {
            if (manufacturingDate) dotInfo.push(`${quantity} - ${t.formatManufacturingDate(manufacturingDate)}`);
          });
        }
      });
    }

    return <DotInfo dotInfo={dotInfo}/>;
  };

  return (
    <Paper>
      <TableContainer>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell name="manufacturingDate">{t.translate('DOT')}</TableCell>
              <SortableCell name="orders" className={classes.defaultFontSize}>{t.translate('Orders')}</SortableCell>
              <SortableCell name="productId" className={classes.defaultFontSize} align="right">{t.translate('Supplier ID')}</SortableCell>
              <SortableCell name="alzuraId" className={classes.defaultFontSize} align="right">Alzura ID</SortableCell>
              <SortableCell name="productId" className={classes.defaultFontSize} align="right">{t.translate('Product ID')}</SortableCell>
              <TableCell className={classes.defaultFontSize}>{t.translate('Product photo')}</TableCell>
              <SortableCell name="productName" className={classes.defaultFontSize}>{t.translate('Product name')}</SortableCell>
              <TableCell>{t.translate('Fahrzeug')}</TableCell>
              <SortableCell name="productEan" className={classes.defaultFontSize} align="right">{t.translate('Product EAN code')}</SortableCell>
              <SortableCell name="ordered" className={classes.defaultFontSize} align="right">{t.translate('Ordered')}</SortableCell>
              <SortableCell name="packedQuantity" className={classes.defaultFontSize} align="right">{t.translate('Packed')}</SortableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {pickupSheet.pickupSheetProducts?.map((pickupSheetProduct, index) => {
              const relatedOrders = findRelatedProductOrders(pickupSheetProduct, pickupSheet.orders);

              return (
                <TableRow key={index} hover>
                  <TableCell>
                    {getDotInfo(pickupSheetProduct.product.id, pickupSheet.orders)}
                  </TableCell>
                  <TableCell>
                    {pickupSheet.orders.length ? (
                      <Grid container direction="column">
                        <OrderLink pickupSheetProduct={pickupSheetProduct} orders={pickupSheet.orders} showList/>
                      </Grid>
                    ) : '—'}
                  </TableCell>
                  <TableCell className={classes.defaultFontSize} align="right">
                    <Link className={classes.link}
                          onClick={() => openSupplier(pickupSheetProduct.supplierId)}>{pickupSheetProduct.supplierId}</Link>
                  </TableCell>
                  <TableCell className={classes.defaultFontSize}
                             align="right">{pickupSheetProduct.product.alzuraId || '—'}</TableCell>
                  <TableCell className={classes.defaultFontSize} align="right">
                    <Link className={classes.link}
                          onClick={() => openProduct(pickupSheetProduct.product.id)}>{pickupSheetProduct.product.id}</Link>
                  </TableCell>
                  <TableCell>
                    <Picture
                      height={54}
                      width={54}
                      image={defaultAdditionalImage(pickupSheetProduct.product.additionalImages) || pickupSheetProduct.product.photo || pickupSheetProduct.product.imageUrl}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.defaultFontSize}>{pickupSheetProduct.product.details[0]?.name || '—'}</TableCell>
                  <TableCell className={classes.defaultFontSize}>
                    {(
                      relatedOrders[0]
                        ?.positions?.find(position => position.productId === pickupSheetProduct.product.id)
                        ?.attributes?.find(attribute => attribute.generic_key === 'fahrzeug')?.value
                      || relatedOrders[0]
                        ?.positions?.find(position => position.productId === pickupSheetProduct.product.id)
                        ?.attributes?.find(attribute => attribute.generic_key === 'vehicleinformation')?.value
                    ) || '—'}
                  </TableCell>
                  <TableCell className={classes.defaultFontSize}
                             align="right">{pickupSheetProduct.product.ean || '—'}</TableCell>
                  <TableCell className={classes.defaultFontSize} align="right">{pickupSheetProduct.quantity}</TableCell>
                  <TableCell className={classes.defaultFontSize} align="right">
                    {pickupSheetProduct.packedQuantity === pickupSheetProduct.quantity
                      ? <CheckCircleOutlineIcon className={classes.doneIcon}/>
                      : `${pickupSheetProduct.packedQuantity}/${pickupSheetProduct.quantity}`}
                  </TableCell>
                  <TableCell align="right">
                    <ActionButton
                      className={classes.button}
                      tooltipPlacement="bottom"
                      aria-label={t.translate('Storage location details')}
                      tooltip={t.translate('Storage location details')}
                      icon={<InfoIcon/>}
                      onClick={() => onProductLocationShow(
                        {
                          productId: pickupSheetProduct.product.id,
                          orderId: relatedOrders[0]?.id,
                        }
                      )}
                    />
                    {canEditPickupSheet && !pickupSheet.packingLabelsDocument && !pickupSheet.status === 'packed' && (
                      <ActionButton
                        className={classes.button}
                        tooltipPlacement="left"
                        aria-label={t.translate('Edit the pickup sheet product.')}
                        tooltip={t.translate('Change quantity')}
                        icon={<EditIcon/>}
                        onClick={() => onPickupSheetProductEdit(pickupSheetProduct)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

ProductsTable.propTypes = {
  canEditPickupSheet: PropTypes.bool.isRequired,
  onPickupSheetProductEdit: PropTypes.func.isRequired,
  onProductLocationShow: PropTypes.func.isRequired,
  pickupSheet: PropTypes.object.isRequired,
};
