import { table } from '.';

export default {
  pickupSheets: {
    pickupSheets: table,
  },
  warehouses: {
    warehouses: table,
  },
  statistics: {
    reports: table,
  },
  suppliers: {
    suppliers: table,
  },
  storages: {
    storages: {
      ...table,
      orderBy: 'id',
      order: 'desc',
    },
  },
  products: {
    storages: table,
    products: table,
    history: table,
    prices: {
      ...table,
      orderBy: 'isProductActiveInMarketplace desc, stock desc, purchasePrice',
      order: 'desc',
    },
  },
  purchases: {
    purchases: table,
    positions: table,
  },
  replenishmentSuggestions: {
    replenishmentSuggestions: table,
  },
  returns: {
    positions: {
      ...table,
      orderBy: 'alzuraId',
      order: 'asc',
    },
    returns: {
      ...table,
      orderBy: 'date',
      order: 'desc',
    },
    history: table,
  },
  dialogs: {
    selectingProduct: table,
  },
  orders: {
    positions: {
      ...table,
      orderBy: 'alzuraId',
      order: 'asc',
    },
    orders: {
      ...table,
      orderBy: 'date',
      order: 'desc',
    },
  },
  rawOrders: {
    rawOrders: table,
  },
  users: {
    users: table,
  },
  companies: {
    companies: table,
  },
  retailers: {
    retailers: table,
  },
  customers: {
    customers: table,
    orders: {
      ...table,
      orderBy: 'date',
      order: 'desc',
    },
  },
  storageUsage: {
    storageUsage: table,
  },
  warehouseFees: {
    warehouseFees: table,
  },
  changesHistory: {
    changesHistory: table,
  },
};
