import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './EanCodeScanDialog.jsx';
import { useTranslator } from 'i18n';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { EanCodeScanner } from 'components';

export const EanCodeScanDialog = ({
  isMobile,
  onDecode,
  onClose,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <Dialog
      {...props}
      scroll="body"
      className={classes.root}
      onClose={onClose}
    >
      <DialogTitle className={classes.title}>{t.translate('Scan EAN code')}</DialogTitle>
      <DialogContent className={classes.content}>
        <EanCodeScanner
          onClose={onClose}
          onDecode={onDecode}
          isMobile={isMobile}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

EanCodeScanDialog.propTypes = {
  onDecode: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};
