import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { useTranslator } from 'i18n';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import {
  CheckboxControl,
  PasswordInput,
  InputControl,
  BusyButton,
} from 'components';

import { createRetailer, modifyRetailer } from 'api/retailers';
import { extractApiErrors } from 'utils/api';
import toaster from 'services/toaster';

import { useStyles } from './RetailerDialog.jsx';

const makeId = postfix => `retailer-dialog--${postfix}`;

export const RetailerDialog = ({
  onClose,
  onSave,
  retailer,
  isMobileView,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const title = retailer
    ? 'Edit retailer'
    : 'Create retailer';

  const [initialValues, setInitialValues] = useState({
    addressCountry: '',
    street: '',
    house: '',
    city: '',
    zip: '',
    addressName: '',
    addressAdditionalName: '',
    password: '',
    name: '',
    accountNumber: '',
    active: true,
    country: 'de',
    b2bToken: '',
    autoOrderToken: '',
  });

  const validationSchema = Yup
    .object()
    .shape({
      accountNumber: Yup
        .string()
        .trim()
        .required(t.translate('Please specify a retailer account number.')),
      name: Yup
        .string()
        .trim()
        .required(t.translate('Please specify a retailer name.')),
      country: Yup
        .string()
        .trim()
        .required(t.translate('Please specify a country.')),
      addressCountry: Yup
        .string()
        .trim()
        .required(t.translate('Please specify a country.')),
      city: Yup
        .string()
        .trim()
        .required(t.translate('Please specify a city.')),
      street: Yup
        .string()
        .trim()
        .required(t.translate('Please specify a street.')),
      house: Yup
        .string()
        .trim()
        .required(t.translate('Please specify a house number.')),
      zip: Yup
        .string()
        .trim()
        .required(t.translate('Please specify a zip code.')),
    });

  const onSubmit = async (values, { setErrors }) => {
    try {
      if (retailer) {
        await modifyRetailer(retailer.id, values);
        toaster.success(t.translate('Retailer has been saved successfully.'));
      } else {
        await createRetailer(values);
        toaster.success(t.translate('New retailer has been created successfully.'));
      }

      onSave();
      onClose();
    } catch (err) {
      setErrors(extractApiErrors(err));
    }
  };

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
  } = useFormik({
    enableReinitialize: true,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    if (retailer) {
      setInitialValues(initialValues => ({
        ...initialValues,
        addressCountry: retailer.address.country,
        street: retailer.address.street,
        house: retailer.address.house,
        city: retailer.address.city,
        zip: retailer.address.zip,
        active: retailer.active,
        b2bToken: retailer.b2bToken || '',
        autoOrderToken: retailer.autoOrderToken || '',
        name: retailer.name,
        accountNumber: retailer.accountNumber,
        password: retailer.password || '',
        country: retailer.country || 'de',
        addressName: retailer.address.name || '',
        addressAdditionalName: retailer.address.additionalName || '',
      }));
    }
  }, [retailer]);

  return (
    <Dialog
      {...props}
      scroll="body"
      fullWidth
      fullScreen={isMobileView}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">{t.translate(title)}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" className={classes.blockLabel}>{t.translate('Retailer status & data')}</Typography>
        <Grid container >
          <CheckboxControl
            name="active"
            error={errors.active}
            label={t.translate('Active')}
            checked={!!values.active}
            onChange={handleChange}
          />
        </Grid>
        <Grid container spacing={4} direction={isMobileView ? 'column' : 'row'} >
          <Grid item md={4}>
            <InputControl
              id={makeId('accountNumber')}
              name="accountNumber"
              error={errors.accountNumber}
              label={t.translate('Account number')}
              value={values.accountNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={8}>
            <InputControl
              id={makeId('name')}
              name="name"
              error={errors.name}
              label={t.translate('Retailer name')}
              value={values.name}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <InputControl
            id={makeId('country')}
            name="country"
            error={errors.country}
            label={t.translate('Country code')}
            value={values.country}
            onChange={e => setFieldValue('country', e.target.value.toLowerCase())}
          />
        </Grid>
        <Typography variant="body1" className={classes.containerLabel}>{t.translate('Address')}</Typography>
        <Grid container spacing={4}>
          <Grid item md={9} xs={9}>
            <InputControl
              id={makeId('street')}
              name="street"
              error={errors.street}
              label={t.translate('Street')}
              value={values.street}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3} xs={3}>
            <InputControl
              id={makeId('house')}
              name="house"
              error={errors.house}
              label={t.translate('House number')}
              value={values.house}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item md={2}>
            <InputControl
              id={makeId('zip')}
              name="zip"
              error={errors.zip}
              label={t.translate('Zip code')}
              value={values.zip}
              onChange={handleChange}
            />
          </Grid>

          <Grid item md={5}>
            <InputControl
              id={makeId('city')}
              name="city"
              error={errors.city}
              label={t.translate('City')}
              value={values.city}
              onChange={handleChange}
            />
          </Grid>
          {!isMobileView && (
            <Grid item md={5}>
              <InputControl
                id={makeId('addressCountry')}
                name="addressCountry"
                error={errors.addressCountry}
                label={t.translate('Country')}
                value={values.addressCountry}
                onChange={handleChange}
              />
            </Grid>
          )}
        </Grid>

        <Grid container spacing={4}>
          <Grid item md={6} xs={6}>
            <InputControl
              id={makeId('address-name')}
              name="addressName"
              error={errors.addressName}
              label={t.translate('Name')}
              value={values.addressName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <InputControl
              id={makeId('address-additionalName')}
              name="addressAdditionalName"
              error={errors.addressAdditionalName}
              label={t.translate('Additional name')}
              value={values.addressAdditionalName}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Typography variant="body1" className={classes.blockLabel}>{t.translate('Additional retailer details')}</Typography>
        <Grid container direction="column">
          <Grid item className={classes.containerItem}>
            <InputControl
              id={makeId('b2bToken')}
              name="b2bToken"
              error={errors.b2bToken}
              multiline
              label={t.translate('API B2B Token')}
              value={values.b2bToken}
              onChange={handleChange}
            />
          </Grid>
          <Grid item className={classes.containerItem}>
            <InputControl
              id={makeId('autoOrderToken')}
              name="autoOrderToken"
              error={errors.autoOrderToken}
              multiline
              label={t.translate('AutoOrder Token')}
              value={values.autoOrderToken}
              onChange={handleChange}
            />
          </Grid>
          <Grid item className={classes.containerItem}>
            <InputControl
              id={makeId('password')}
              name="password"
              error={errors.password}
              label={t.translate('Password')}
              value={values.password}
              InputComponent={PasswordInput}
              onChange={handleChange}
              autoComplete="one-time-code"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
        <BusyButton color="primary" submit onClick={handleSubmit}>{t.translate('Save')}</BusyButton>
      </DialogActions>
    </Dialog>
  );
};

RetailerDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  retailer: PropTypes.shape({
    address: PropTypes.shape({
      country: PropTypes.string.isRequired,
      street: PropTypes.string.isRequired,
      house: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
      name: PropTypes.string,
      additionalName: PropTypes.string,
    }).isRequired,
    accountNumber: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    b2bToken: PropTypes.string,
    autoOrderToken: PropTypes.string,
    active: PropTypes.bool.isRequired,
    password: PropTypes.string,
    country: PropTypes.string.isRequired,
  }),
};
