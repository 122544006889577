import warehousesApi from 'api/warehouses';
import usersApi from 'api/users';
import {
  setDetailsWarehouses,
  setOverviewUsers,
  setDetailsUser,
} from 'store/actions/users';
import {
  startLoading,
  stopLoading,
} from 'store/actions/app';

export const fetchUsers = params => async dispatch => {
  try {
    dispatch(startLoading('users.overview.users'));
    const users = await usersApi.fetchMany(params);
    dispatch(setOverviewUsers(users));
    dispatch(stopLoading('users.overview.users'));
  } catch (err) {
    dispatch(stopLoading('users.overview.users'));
  }
};

export const fetchWarehouses = () => async dispatch => {
  try {
    const warehouses = await warehousesApi.fetchMany({ meta: ['list-set'] });
    dispatch(setDetailsWarehouses(warehouses.data));
  } catch (err) {}
};

export const fetchUser = userId => async dispatch => {
  try {
    dispatch(startLoading('users.details.user'));
    const order = await usersApi.fetchOne(userId);
    dispatch(setDetailsUser(order));
    dispatch(stopLoading('users.details.user'));
  } catch (err) {
    dispatch(stopLoading('users.details.user'));
  }
};
