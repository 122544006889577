import React from 'react';
import PropTypes from 'prop-types';
import { useLoading } from 'hooks/loading';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { preventAction } from 'utils/html';

export const BusyButton = ({
  onClick,
  submit,
  children,
  ...props
}) => {
  const loading = useLoading();

  const click = async () => {
    const cb = await loading.lock(onClick)();
    if (typeof cb === 'function') {
      cb();
    }
  };

  return (
    <Button
      color="primary"
      {...props}
      {...(submit && { type: 'submit' })}
      onClick={submit ? preventAction(click) : click}
    >
      {loading.progress
        ? (<>
            <CircularProgress
              style={{ position: 'absolute' }}
              color="inherit"
              size={20}
            />
            <span style={{ visibility: 'hidden' }}>{children}</span>
          </>)
        : children}
    </Button>
  );
};

BusyButton.propTypes = {
  onClick: PropTypes.func,
  submit: PropTypes.bool,
};
