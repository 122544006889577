import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslator } from 'i18n';
import { makeStyles } from 'tss-react/mui';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles()(theme => ({
  headline: {
    marginBottom: theme.spacing(1),
  },
}));

export const Warehouses = ({ onAvailabilityChange }) => {
  const { classes } = useStyles();
  const t = useTranslator();

  const warehouses = useSelector(({ users }) => users.details.warehouses);
  const user = useSelector(({ users }) => users.details.user);

  return (
    <Grid container direction="column">
      <Grid item className={classes.headline}>
        <Typography variant="h6">{t.translate('Warehouses')}</Typography>
      </Grid>
      <Grid item container direction="column" spacing={1}>
        {warehouses.map(warehouse => (
          <Grid item key={warehouse.id}>
            <FormControlLabel
              control={
                <Switch
                  checked={user.allowedWarehouseIds.includes(warehouse.id)}
                  onChange={onAvailabilityChange(warehouse.id)}
                />
              }
              label={warehouse.name}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

Warehouses.propTypes = {
  onAvailabilityChange: PropTypes.func.isRequired,
};
