import React, { useEffect, useState } from 'react';

import { useTranslator } from 'i18n';

import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useStyles } from './IdentifyProducts.jsx';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { IdentifyProductItem } from 'components';

export const IdentifyProducts = ({
  updateProducts,
  onSelect,
  onClose,
  products,
  b2bProducts,
  isMobileView,
  ...props
}) => {
  const { classes } = useStyles();
  const t = useTranslator();

  const [isB2bProduct, setIsB2bProduct] = useState(false);
  const [isDetails, setIsDetails] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (products) {
      setItems(products.data);
    } else if (b2bProducts) {
      setIsB2bProduct(true);
      setItems(b2bProducts);
    } else {
      setItems([]);
    }
  }, [products, b2bProducts]);

  return <>
    <Grid container justifyContent="space-between" alignItems={isMobileView ? 'stretch' : 'center'} direction={isMobileView ? 'column' : 'row'}>
      <Grid item container direction="row" alignItems="center">
        <IconButton
          color="inherit"
          aria-label="go back"
          className={classes.backButton}
          onClick={() => onClose()}
          size="large">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="body1" className={classes.bold}>{t.translate('Identify product')}</Typography>
      </Grid>

      {isMobileView && (
        <Typography variant="body2" className={classes.textSecondary}>{t.translate('Please choose which product should be assigned')}.</Typography>
      )}

      <Grid item>
        <Button
          className={classes.moreButton}
          variant="outlined"
          onClick={() => setIsDetails(!isDetails)}
        >
          {isDetails
            ? t.translate('Show less details')
            : t.translate('Show more details')
          }
        </Button>
      </Grid>
    </Grid>

    {!isMobileView && (
      <Typography variant="body1" className={classes.textSecondary}>{t.translate('Please choose which product should be assigned')}.</Typography>
    )}

    <Grid container spacing={2} style={{ flexWrap: 'wrap' }}>
      {items.map((product, index) => (
        <Grid item md={6} xs={12} key={index}>
          <IdentifyProductItem
            isDetails={isDetails}
            isB2bProduct={isB2bProduct}
            product={product}
            onSelect={onSelect}
            onClose={onClose}
            isMobileView={isMobileView}
          />
        </Grid>
      ))}
    </Grid>

  </>;
};
