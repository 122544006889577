import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  emailItem: {
    paddingTop: `${theme.spacing(3.25)} !important`,
    [theme.breakpoints.down('sm')]: {
      paddingTop: `${theme.spacing(4.25)} !important`,
    },
  },
  passwordItem: {
    paddingTop: `${theme.spacing(3.5)} !important`,
  },
  forgotPasswordLink: {
    color: theme.palette.primary.l500,
    cursor: 'pointer',
  },
  submitItem: {
    paddingTop: `${theme.spacing(3.5)} !important`,
  },
}));
