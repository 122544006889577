import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslator } from 'i18n';
import { useScreen } from 'hooks';
import withAuthentication from 'hocs/authentication';

import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import { AppNavigation } from 'components';

import { selectNavigationVisibility } from 'store/selectors/app';
import { closeNavigation } from 'store/actions/app';
import {
  isAuthenticatedUserAdministrator,
  selectPermissions,
} from 'store/selectors/account';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      '& .MuiTablePagination-selectRoot': {
        marginRight: theme.spacing(1),
      },
      '& .MuiTablePagination-actions': {
        marginLeft: theme.spacing(1),
      },
    },
  },
}));

const MainComponent = ({ children }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const screen = useScreen();
  const t = useTranslator();

  const isNavigationVisible = useSelector(selectNavigationVisibility);
  const isAdministrator = useSelector(isAuthenticatedUserAdministrator);
  const permissions = useSelector(selectPermissions);

  const shouldShowMenuItem = name => {
    if (name === 'changesHistory' && !isAdministrator) return false;
    return isAdministrator || permissions[name] === undefined || permissions[name].view;
  };

  return (
    <>
      <Helmet>
        <title>{t.translate('app:title')}</title>
      </Helmet>
      <AppNavigation
        shouldShowMenuItem={shouldShowMenuItem}
        isMobileView={screen.isMobile}
        visible={isNavigationVisible}
        onClose={() => dispatch(closeNavigation())}
      />
      <Box className={classes.root}>
        {children}
      </Box>
    </>
  );
};

export const Main = withAuthentication(MainComponent);
