import { PICKUP_SHEETS } from 'store/types';
import { pickupSheets as is } from 'store/initialState';

const updateActivePickupSheetProduct = (state, pickupSheetProduct) => ({
  ...state,
  activePickupSheet: {
    ...state.activePickupSheet,
    pickupSheetProducts: state.activePickupSheet.pickupSheetProducts.map(
      _pickupSheetProduct => ({
        ..._pickupSheetProduct,
        ...(pickupSheetProduct.id === _pickupSheetProduct.id
          ? pickupSheetProduct
          : {}),
      })
    ),
  },
});

const updateActivePickupSheet = (state, pickupSheet) => ({
  ...state,
  activePickupSheet: {
    ...state.activePickupSheet,
    ...pickupSheet,
  },
});

const setActivePickupSheet = (state, pickupSheet) => ({
  ...state,
  activePickupSheet: pickupSheet,
});

const setPickupSheets = (state, { data, meta }) => ({
  ...state,
  pickupSheets: {
    data,
    meta: {
      totalEntities: meta.totalEntities,
    },
  },
});

export default (state = is, { type, payload }) => {
  switch (type) {
    case PICKUP_SHEETS.UPDATE_ACTIVE_PICKUP_SHEET_PRODUCT:
      return updateActivePickupSheetProduct(state, payload);
    case PICKUP_SHEETS.UPDATE_ACTIVE_PICKUP_SHEET:
      return updateActivePickupSheet(state, payload);
    case PICKUP_SHEETS.SET_ACTIVE_PICKUP_SHEET:
      return setActivePickupSheet(state, payload);
    case PICKUP_SHEETS.SET_PICKUP_SHEETS:
      return setPickupSheets(state, payload);
    default:
      return state;
  }
};
