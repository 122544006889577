import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useList } from 'hooks';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { Column } from 'layouts';
import { Pagination } from 'components';
import { WarehouseItem } from './WarehouseItem/WarehouseItem';

import tables from 'config/tables';

export const MobileView = ({
  onWarehouseDelete,
  canDeleteWarehouse,
  totalEntities,
  warehouses,
}) => {
  const history = useHistory();
  const list = useList(tables.warehouses.warehouses);

  const openWarehouse = warehouse => history.push(`/warehouses/${warehouse.id}`);

  return (
    <Column p={1} pb={8.5} pt={2}>
      <Grid container direction="column">
        {warehouses.map(warehouse => (
          <WarehouseItem
            key={warehouse.id}
            warehouse={warehouse}
            canDeleteWarehouse={canDeleteWarehouse}
            onWarehouseDelete={onWarehouseDelete}
            onWarehouseOpen={() => openWarehouse(warehouse)}
          />
        ))}
        <Paper>
          <Pagination
            {...list.paginationProps}
            count={totalEntities}
            direction="row"
          />
        </Paper>
      </Grid>
    </Column>
  );
};

MobileView.propTypes = {
  totalEntities: PropTypes.number,
  warehouses: PropTypes.array.isRequired,
};
