import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { styleMobileTableItem } from 'theme/mixins/table';
import { useHistory } from 'react-router-dom';
import Link from '@mui/material/Link';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  paper: {
    padding: 0,
    height: '100%',
  },
  featureLabel: {
    fontWeight: 500,
  },
  shippingNeutral: {
    color: theme.palette.grey['150'],
  },
  shippingPriority: {
    color: theme.palette.red.coral,
  },
}));

export const AddressesDetails = ({ invoiceAddress, shippingAddress, order }) => {
  const { classes } = useStyles();
  const t = useTranslator();
  const history = useHistory();

  const openPickupSheet = id => history.push(`/pickup-sheets/${id}`);

  return (
    <Paper className={classes.paper}>
      <Grid container direction="column">
        <Grid direction="column" container p={2}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="body2" className={classes.featureLabel}>{t.translate('Invoice')}</Typography>
            </Grid>
            <Grid item align="right">
              <Typography variant="body2" align="right">{invoiceAddress.name || '—'}{invoiceAddress.additionalName && ` ${invoiceAddress.additionalName}`}</Typography>
              <Typography variant="body2" align="right">{invoiceAddress.street || '—'}&nbsp;{invoiceAddress.house || ''}</Typography>
              <Typography variant="body2" align="right">{invoiceAddress.zip || '—'}&nbsp;{invoiceAddress.city || '—'}</Typography>
              <Typography variant="body2" align="right">{invoiceAddress.country || '—'}</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="body2" className={classes.featureLabel}>{t.translate('Tax number')}</Typography>
            </Grid>
            <Grid item align="right">
              <Typography variant="body2" align="right">{order.taxNumber || '—'}</Typography>
            </Grid>
          </Grid>
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography variant="body2" className={classes.featureLabel}>{t.translate('VAT ID')}</Typography>
            </Grid>
            <Grid item align="right">
              <Typography variant="body2" align="right">{order.vatId || '—'}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider/>
        <Grid container p={2}>
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.featureLabel}>{t.translate('Shipping')}</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2" align="right">{shippingAddress.name || '—'}{shippingAddress.additionalName && ` ${shippingAddress.additionalName}`}</Typography>
            <Typography variant="body2" align="right">{shippingAddress.street || '—'}{shippingAddress.house && '\u00A0'}{shippingAddress.house || ''}</Typography>
            <Typography variant="body2" align="right">{shippingAddress.zip || '—'}&nbsp;{shippingAddress.city || '—'}</Typography>
            <Typography variant="body2" align="right">{shippingAddress.country || '—'}</Typography>
            {order.shippingNeutral && <Typography variant="body2" align="right" className={classes.shippingNeutral}>{t.translate('Neutral shipping')}</Typography>}
            {order.shippingPriority && <Typography variant="body2" align="right" className={classes.shippingPriority}>{t.translate('Priority shipping')}</Typography>}
          </Grid>
        </Grid>
        {!!order.additionalShipments?.length && order.additionalShipments.map((o, index) => (
          <React.Fragment key={`additional-shipment-${index}`}>
            <Divider/>
            <Grid item container p={2}>
              <Grid item xs={4}>
                <Typography variant="body2" className={classes.featureLabel}>
                  {t.translate('Additional shipment')}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="body2" align="right">{t.translate('Reason')}: <b>{t.translate(`shipAgainReason:${o.reason}`)}</b></Typography>
                <Typography variant="body2" align="right">{o.pickupSheet.deliveryAddress.name || '—'}{o.pickupSheet.deliveryAddress.additionalName && ` ${o.pickupSheet.deliveryAddress.additionalName}`}</Typography>
                <Typography variant="body2" align="right">{o.pickupSheet.deliveryAddress.street || '—'}{o.pickupSheet.deliveryAddress.house && '\u00A0'}{o.pickupSheet.deliveryAddress.house || ''}</Typography>
                <Typography variant="body2" align="right">{o.pickupSheet.deliveryAddress.zip || '—'}&nbsp;{o.pickupSheet.deliveryAddress.city || '—'}</Typography>
                <Typography variant="body2" align="right">{o.pickupSheet.deliveryAddress.country || '—'}</Typography>
                <Typography variant="body2" align="right">
                  {t.translate('Pickup sheet')}: <Link className={classes.link} onClick={() => openPickupSheet(o.pickupSheet.id)}>#{o.pickupSheet.id}</Link>
                </Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Paper>
  );
};

AddressesDetails.propTypes = {
  invoiceAddress: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    house: PropTypes.string,
    name: PropTypes.string,
    additionalName: PropTypes.string,
    street: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
  shippingAddress: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    house: PropTypes.string,
    name: PropTypes.string,
    additionalName: PropTypes.string,
    street: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
  order: PropTypes.shape({
    shippingPriority: PropTypes.bool,
    shippingNeutral: PropTypes.bool,
    taxNumber: PropTypes.string,
    vatId: PropTypes.string,
    additionalShipments: PropTypes.array,
  }).isRequired,
};
