import React from 'react';

import { useSelector } from 'react-redux';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { BooleanChip } from 'components';

import { createPermissionsListData } from 'services/account';
import { selectPermissions } from 'store/selectors/account';

const useStyles = makeStyles()(theme => ({
  tableItem: {
    '&.MuiGrid-item': {
      margin: theme.spacing(0, -2),
      padding: 0,
      '& .MuiTableCell-root': {
        '&:first-of-type': {
          paddingLeft: theme.spacing(3),
        },
        '&:last-child': {
          borderRightWidth: 0,
          paddingRight: theme.spacing(3),
        },
      },
    },
  },
  actionsGrid: {
    flexWrap: 'wrap',
  },
}));

export const DesktopView = () => {
  const { classes } = useStyles();
  const t = useTranslator();

  const authenticatedUserPermissions = useSelector(selectPermissions);
  const permissions = createPermissionsListData(authenticatedUserPermissions);

  return (
    <Grid item className={classes.tableItem}>
      <Table>
        <TableBody>
          {permissions.map(section => (
            <TableRow key={section.key}>
              <TableCell>
                <Typography>{t.translate(section.name)}</Typography>
              </TableCell>
              <TableCell>
                <Grid container spacing={1} className={classes.actionsGrid}>
                  {section.actions.map(action => (
                    <Grid item key={action.key}>
                      <BooleanChip
                        label={t.translate(`permission:${action.key}`)}
                        value={action.value}
                        disabled={action.key !== 'view' && !authenticatedUserPermissions[section.key].view}
                      />
                    </Grid>
                  ))}
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
};
