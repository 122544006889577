import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useSelector } from 'react-redux';
import { useStyles } from './Info.jsx';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { NavigationHeaderIcon } from 'icons';
import { Logo } from 'components';
import { selectAuthenticatedUser } from 'store/selectors/account';

export const Info = ({ isThumbnail }) => {
  const { classes } = useStyles();

  const authenticatedUser = useSelector(selectAuthenticatedUser);

  return (
    <Box className={clsx(classes.root, isThumbnail && classes.thumbnailRoot)}>
      <Box className={clsx(classes.logoBox, isThumbnail && classes.thumbnailLogoBox)}>
        <Logo className={clsx(classes.logo, isThumbnail && classes.thumbnailLogo)} />
        {!isThumbnail && <NavigationHeaderIcon className={classes.logoBackground} />}
      </Box>
      {!isThumbnail && (
        <Box className={classes.nameBox}>
          <Typography variant="body2">{authenticatedUser.name}</Typography>
        </Box>
      )}
    </Box>
  );
};

Info.propTypes = {
  isThumbnail: PropTypes.bool.isRequired,
};
