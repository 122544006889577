import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    marginTop: theme.spacing(2.5),
  },
  sampleItem: {
    display: 'flex',
  },
  example: {
    borderRadius: theme.shape.borderRadius,
    flexGrow: 1,
    height: theme.spacing(3.5),
    marginRight: theme.spacing(1.75),
  },
  circle: {
    width: 'auto !important',
    '& > span > div': {
      borderColor: theme.palette.grey[200],
      borderRadius: '50%',
      borderStyle: 'solid',
      borderWidth: 1,
      marginBottom: `${theme.spacing(1.125)} !important`,
      marginRight: `${theme.spacing(1.125)} !important`,
    },
  },
  hueItem: {
    alignItems: 'center',
    flexGrow: 1,
    display: 'flex',
  },
  hue: {
    width: '100% !important',
    '& > div, & > div > div': {
      borderRadius: `${theme.shape.borderRadius}px !important`,
    },
  },
}));
