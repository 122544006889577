import React from 'react';
import { makeStyles } from 'tss-react/mui';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles()((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
  loader: {
    color: theme.palette.primary.main,
  },
}));

export const CircularLoader = ({ size, ...props }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress size={size || 60} className={classes.loader} {...props} />
    </div>
  );
};
