import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import {
  useFilters,
  useRoute,
} from 'hooks';

import { makeStyles } from 'tss-react/mui';
import {
  AlzuraSpriteIcon,
  TextFilterCell,
  ActionButton,
  TableCell,
  TableRow,
} from 'components';

const useStyles = makeStyles()(theme => ({
  alignRight: {
    textAlign: 'right',
  },
  row: {
    backgroundColor: theme.palette.backgrounds.secondary,
    '& input': {
      paddingLeft: theme.spacing(1),
      backgroundColor: theme.palette.backgrounds.grey[250],
    },
  },
  button: {
    color: theme.palette.primary.l900,
  },
  cell: {
    backgroundColor: 'inherit',
  },
}));

export const FiltersRow = ({
  onFiltersEnable,
  visible,
  ...props
}) => {

  const route = useRoute();
  const t = useTranslator();
  const { classes } = useStyles();

  const [isActiveFilter, setIsActiveFilter] = useState(false);

  const filters = useFilters({
    manufacturerNumber: v => v || '',
    manufacturerName: v => v || '',
    name: v => v || '',
    ean: v => v || '',
    id: v => v || '',
  }, {
    flags: ['activeOnMarketplaceDe'],
  });

  useEffect(() => {
    if (filters.isAvailable()) {
      onFiltersEnable();
    }
  }, [filters, onFiltersEnable]);

  useEffect(() => {
    setIsActiveFilter(Object.keys(route.search).find(key => Object.keys(filters.props).includes(key)));
  }, [route.search, filters.props]);

  return visible && (
    <TableRow {...props} className={classes.row}>
      <TextFilterCell cellClassName={ classes.cell } {...filters.props.id}/>
      <TextFilterCell cellClassName={ classes.cell } {...filters.props.name} />
      <TextFilterCell align="right" cellClassName={ classes.cell } {...filters.props.manufacturerNumber} />
      <TextFilterCell cellClassName={ classes.cell } {...filters.props.manufacturerName} />
      <TextFilterCell align="right" cellClassName={ classes.cell } {...filters.props.ean} />
      <TableCell className={classes.cell}/>
      <TableCell className={classes.cell}/>
      <TableCell className={classes.cell}/>
      <TableCell className={classes.cell}/>
      <TableCell className={classes.cell} align="right">
        <ActionButton
          tooltip={t.translate('Reset filters')}
          icon={<AlzuraSpriteIcon id="reset" />}
          onClick={filters.reset}
          className={classes.button}
          disabled={!isActiveFilter}
        />
      </TableCell>
    </TableRow>
  );
};

FiltersRow.propTypes = {
  onFiltersEnable: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};
