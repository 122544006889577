import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './style';
import { useTranslator } from 'i18n';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const SummaryLabel = ({ text, isXs }) => {
  if (isXs) {
    const words = text.split(' ');
    return <>{words[0]}: <br /></>;
  } else {
    return <>{text}: </>;
  }
};

export const StockFormControl = ({
  onChange,
  disabled,
  actions,
  value,
  stock,
  hideLabels,
  topBound,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [freezeAdd, setFreezeAdd] = useState(false);

  useEffect(() => {
    setFreezeAdd(value >= topBound);
  }, [value, topBound]);

  const changeStock = value => {
    let conditionalStock = parseInt(value, 10);

    if (isNaN(conditionalStock)) {
      conditionalStock = 0;
    }

    onChange(
      stock + conditionalStock >= 0
        ? conditionalStock
        : -stock
    );

    if (value >= topBound) {
      onChange(topBound);
      setFreezeAdd(true);
    } else {
      setFreezeAdd(false);
    }
  };

  const canNotSubtract = disabled || (
    actions.includes('subtract')
      ? 0 === value + stock
      : 0 === value
  );
  const canNotAdd = disabled || (
    !actions.includes('add') && value === 0
  );

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={1}
    >
      {!hideLabels && (
        <Grid item className={classes.stockSummary}>
          <Typography color="textSecondary" variant="body2" align="center">
            <SummaryLabel text={t.translate('Old')} isXs={isXs} />
            {stock}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Button
          className={classes.stockButton}
          disabled={canNotSubtract}
          variant="contained"
          color="secondary"
          onClick={() => changeStock(value - 1)}
          aria-label="subtract stock"
        >
          <RemoveIcon />
        </Button>
        <Input
          className={classes.stockInput}
          disabled={disabled}
          value={(value > 0 ? '+' : '') + value}
          onChange={e => changeStock(e.target.value)}
          aria-label="stock"
        />
        <Button
          className={classes.stockButton}
          disabled={canNotAdd || freezeAdd}
          variant="contained"
          color="secondary"
          onClick={() => changeStock(value + 1)}
          aria-label="add stock"
        >
          <AddIcon />
        </Button>
      </Grid>
      {!hideLabels && (
        <Grid item className={classes.stockSummary}>
          <Typography color="textSecondary" variant="body2" align="center">
            <SummaryLabel text={t.translate('New')} isXs={isXs} />
            {stock + value}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

StockFormControl.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.oneOf(['add', 'subtract'])
  ),
  value: PropTypes.number.isRequired,
  stock: PropTypes.number.isRequired,
  hideLabels: PropTypes.bool,
};

StockFormControl.defaultProps = {
  disabled: false,
};
