import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import { styleFormDialog } from 'theme/mixins/dialog';

const useStyles = makeStyles()((theme) =>
  styleFormDialog(theme, {
    content: {
      '&.MuiDialogContent-root': {
        width: 'auto',
        overflowY: 'initial',
        paddingBottom: theme.spacing(0.5),
        wordBreak: 'break-word',
      },
      '&.MuiDialogContent-root .MuiGrid-item': {
        paddingBottom: theme.spacing(1),
      },
      '&.MuiDialogContent-root .MuiGrid-item > p:first-of-type': {
        fontWeight: 500,
      },
    },
    button: {
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        right: theme.spacing(2),
        bottom: theme.spacing(2),
      },
    },
  }));

export const ProductDescriptionDialog = ({
  description,
  onClose,
  isMobile,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <>
      <Dialog fullWidth fullScreen={isMobile} scroll="body" {...props} onClose={onClose}>
        <DialogTitle>{t.translate('Product description')}</DialogTitle>
        <Grid container direction="column" justifyContent="space-between">
          <DialogContent className={classes.content}>
            <Typography variant="body2">{description}</Typography>
          </DialogContent>
          <DialogActions className={classes.button}>
            <Button color="primary" onClick={onClose}>
              {t.translate('Close')}
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </>
  );
};

ProductDescriptionDialog.propTypes = {
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
