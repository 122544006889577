import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import { makeSourcePublicLink } from 'services/files';
import { useStyles } from './ImportStoragesDialog.jsx';
import { useTranslator } from 'i18n';
import { FileUpload } from 'components';

export const ImportStoragesDialog = ({
  onStoragesImport,
  onClose,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <>
      <Dialog scroll="body" {...props} className={classes.root} onClose={onClose} id='storages-import-dialog'>
        <DialogTitle className={classes.title}>{t.translate('Select import mode')}</DialogTitle>
        <DialogContent className={clsx(classes.content, classes.actions)}>
          <Link href={makeSourcePublicLink('/demo/storages_import_demo.csv')} className={classes.demoFile}>
            {t.translate('Example file')}
          </Link>
          <FileUpload
            onUploaded={(e, err) => onStoragesImport(e, err, 'create')}
            noIcon
            color="primary"
            variant="contained"
            inputProps={{ id: 'create-storage-locations' }}
          >
            {t.translate('Create storage locations')}
          </FileUpload>
          <FileUpload
            onUploaded={(e, err) => onStoragesImport(e, err, 'update')}
            noIcon
            color="primary"
            variant="contained"
            inputProps={{ id: 'update-storage-locations' }}
          >
            {t.translate('Update storage locations')}
          </FileUpload>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ImportStoragesDialog.propTypes = {
  onStoragesImport: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
