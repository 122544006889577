import React, { useState } from 'react';
import { Grid, Typography, Select, MenuItem, Checkbox, ListItemText, Button } from '@mui/material';
import { useTranslator } from 'i18n';
import { CheckboxControl, InputControl } from 'components';
import { ExternalPaymentMethods, SupplierHandlingTypes, SupplierPaymentsMethods } from 'consts';
import { Hint } from '../Hint/Hint';
import { PageWrapper } from '../PageWrapper/PageWrapper';
import { useStyles } from '../styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

const { DEFAULT, DROPSHIPPING_DEFAULT, DROPSHIPPING_AUTO_ORDER, TRANSIT } = SupplierHandlingTypes;

const makeId = postfix => `supplier-settings-account--${postfix}`;

export const AccountSettings = ({
  canEditToken,
  canChangeCreateInvoices,
  isEditMode,
  setFieldValue,
  values,
  handleChange,
  errors,
  handlingTypes,
  retailers,
  paymentsMethods,
  setPaymentsMethods,
  mondoEmails,
  supplier,
  suppliers,
  companies,
  activeMarketplaces,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const [showAll, setShowAll] = useState(values.companies.length === activeMarketplaces.length);

  const handleToggleShowAll = () => {
    setShowAll(true);
  };
  return (
    <PageWrapper>
      <Grid
        container
        item
        xl={6}
        lg={8}
        xs={12}
        direction="row"
        className={classes.containerWrap}
        style={{ justifyContent: 'space-between' }}
        spacing={4}
      >
        <Grid item md={6} xs={12}>
          <Grid item container className={classes.containerItem}>
            <InputControl
              id={makeId('billingOfServices')}
              error={errors.partnerBillingType}
              label={t.translate('Billing of services')}
              InputComponent={() => (<Select
                disabled={!isEditMode}
                value={values.partnerBillingType}
                name="partnerBillingType"
                onChange={handleChange}
              >
                {[
                  { value: true, text: 'ALZURA creates credit note to partner' },
                  { value: false, text: 'Partner creates invoice to ALZURA' },
                ].map(type => (
                  <MenuItem key={type.value} value={type.value}>{t.translate(type.text)}</MenuItem>
                ))}
              </Select>)}
            />
          </Grid>
          {!values.partnerBillingType &&
            <Grid item container className={classes.containerItem}>
              <CheckboxControl
                id={makeId('is-partner-billing-type-commission')}
                name="isPartnerBillingTypeFee"
                error={errors.isPartnerBillingTypeFee}
                label={t.translate('BillFeesForNotUsingCreditNotePayment')}
                checked={!!values.isPartnerBillingTypeFee}
                onChange={handleChange}
                disabled={!isEditMode}
              />
              <Hint text={'suppliers:billingOfServices-hint'}/>
            </Grid>
          }
          {values.partnerBillingType && (
            <Grid item container className={classes.containerItem}>
              <InputControl
                id={makeId('affiliate-id')}
                name="affiliateId"
                error={errors.affiliateId}
                label={t.translate('Affiliate account number')}
                value={values.affiliateId}
                onChange={handleChange}
                disabled={!isEditMode || !values.partnerBillingType}
                required={values.partnerBillingType}
              />
            </Grid>
          )}
          <Grid item container className={classes.containerItem}>
            <InputControl
              id={makeId('paymentMethod')}
              name="paymentMethod"
              error={errors.paymentMethod}
              label={t.translate('Payment method')}
              InputComponent={() => (<Select
                disabled={!isEditMode}
                value={values.paymentMethod}
                name="paymentMethod"
                onChange={handleChange}
              >
                {ExternalPaymentMethods.map(method => (
                  <MenuItem key={method} value={method}>{t.translate(`external_payment_methods:${method}`)}</MenuItem>
                ))}
              </Select>)}
            />
          </Grid>
          <Grid item container className={classes.containerItem}>
            <InputControl
              id={makeId('handlingType')}
              name="handlingType"
              error={errors.handlingType}
              label={t.translate('Handling type')}
              InputComponent={() => (<Select
                disabled={!isEditMode}
                value={values.handlingType}
                name="handlingType"
                onChange={handleChange}
              >
                {Object.values(handlingTypes)?.map(type => (
                  <MenuItem key={type} value={type}>{t.translate(`supplierHandlingType:${type}`)}</MenuItem>
                ))}
              </Select>)}
            />
            <Hint text={`suppliers:handlingType-hint:${values.handlingType}`}/>
          </Grid>
          {[DROPSHIPPING_AUTO_ORDER, TRANSIT].includes(values.handlingType) && (<>
            <Grid item container className={classes.containerItem}>
              <InputControl
                id={makeId('externalSupplier')}
                name="externalSupplier"
                error={errors.externalSupplier}
                label={t.translate('Forward orders to this supplier number')}
                value={values.externalSupplier}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Grid>
            <Grid item container className={classes.containerItem}>
              <FormControl fullWidth error={!!errors.retailerIds}>
                <InputLabel shrink id="product-groups-label">{t.translate('Retailer account for outbound orders')}</InputLabel>
                <Select
                  className={classes.selectEmpty}
                  labelId="retailer-ids-label"
                  id="retailer-ids"
                  name="retailerIds"
                  multiple
                  disabled={!isEditMode}
                  value={values.retailerIds}
                  onChange={(event) => {
                    setFieldValue('retailerIds', event.target.value);
                  }}
                  input={<Input />}
                  renderValue={(selected) =>
                    <Grid style={{ overflow: 'hidden' }}>
                      {retailers?.filter(retailer => selected.includes(retailer.id)).map(({ accountNumber, name }) => `${accountNumber} — ${name}`).join(', ')}
                    </Grid>
                  }
                >
                  {retailers?.filter(retailer => retailer.active).map(({ id, accountNumber, name }) => (
                    <MenuItem key={`menu-item-${id}`} value={id}>
                      <Checkbox checked={values.retailerIds.indexOf(id) > -1} />
                      <ListItemText primary={`${accountNumber} — ${name}`} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Typography variant="body2" className={classes.label}>
              {t.translate('Outbound order payment types')}
            </Typography>
            <Grid item className={classes.containerItem}>
              {SupplierPaymentsMethods.map((paymentsMethod, index) => (
                <CheckboxControl
                  disabled={!isEditMode}
                  key={index}
                  id={makeId(`payments-method--${paymentsMethod.id}`)}
                  label={t.translate(paymentsMethod.text)}
                  value={paymentsMethod.id}
                  checked={values.paymentsMethods.includes(paymentsMethod.id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue('paymentsMethods', [...paymentsMethods, paymentsMethod.id]);
                      setPaymentsMethods([...paymentsMethods, paymentsMethod.id]);
                    }
                    else {
                      const idx = paymentsMethods.indexOf(paymentsMethod.id);
                      const tempPaymentsMethodsArray = paymentsMethods;
                      tempPaymentsMethodsArray.splice(idx, 1);
                      setFieldValue('paymentsMethods', tempPaymentsMethodsArray);
                      setPaymentsMethods(tempPaymentsMethodsArray);
                    }
                  }}
                />
              ))}
            </Grid>
          </>)}
          {[DROPSHIPPING_AUTO_ORDER, DROPSHIPPING_DEFAULT].includes(values.handlingType) && (
            <Grid item container className={classes.containerItem}>
              <InputControl
                id={makeId('sendTracking')}
                error={errors.sendTracking}
                label={t.translate('Forward tracking numbers for customer')}
                InputComponent={() => (
                  <Select
                    disabled={!isEditMode}
                    value={values.sendTracking}
                    name="sendTracking"
                    onChange={handleChange}
                  >
                    {[{ text: 'Yes', value: true }, { text: 'No', value: false }].map((el, index) => (
                      <MenuItem key={index} value={el.value}>{t.translate(el.text)}</MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
          )}
          <Grid item container className={classes.containerItem}>
            <InputControl
              id={makeId('invoicing-email')}
              error={errors.mondoEmail}
              label={t.translate('Invoicing E-Mail Account')}
              InputComponent={() => (<Select
                disabled={!isEditMode}
                value={mondoEmails.length ? values.mondoEmail.name : ''}
                name="mondoEmail.name"
                onChange={handleChange}
              >
                {mondoEmails.map(item => (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>)}
            />
            <Hint text={'supplierInvoicingEmail:info'}/>
          </Grid>
          {canEditToken && (
            <Grid item container className={classes.containerItem}>
              <InputControl
                disabled={!isEditMode}
                id={makeId('token')}
                name="token"
                error={errors.token}
                multiline
                label={t.translate('ALZURA Marketplace API Token')}
                value={values.token}
                onChange={handleChange}
              />
            </Grid>
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid className={classes.containerItem}>
            <Typography variant="body2" className={classes.label}>
              {t.translate('Optional settings')}
            </Typography>
            <CheckboxControl
              disabled={!isEditMode}
              id={makeId('is-self-owned')}
              name="isSelfOwned"
              error={errors.isSelfOwned}
              label={t.translate('Self-owned')}
              checked={!!values.isSelfOwned}
              onChange={handleChange}
            />
            <Grid item container>
              <CheckboxControl
                id={makeId('create-invoices')}
                name="createInvoices"
                error={errors.createInvoices}
                label={t.translate('Create invoices')}
                checked={!!values.createInvoices}
                onChange={handleChange}
                disabled={!canChangeCreateInvoices || !isEditMode}
              />
              <Hint text={'suppliers:createInvoices-hint'}/>
            </Grid>
            <CheckboxControl
              disabled={!isEditMode}
              id={makeId('show-logo-on-pickup-sheets')}
              name="showLogoOnPickupSheets"
              error={errors.generateBarcode}
              label={t.translate('Print ALZURA logo in pickup-sheets')}
              checked={!!values.showLogoOnPickupSheets}
              onChange={handleChange}
            />
            <CheckboxControl
              disabled={!isEditMode}
              id={makeId('generate-barcode')}
              name="generateBarcode"
              error={errors.generateBarcode}
              label={t.translate('Print EAN on pickup-sheets')}
              checked={!!values.generateBarcode}
              onChange={handleChange}
            />
          </Grid>
          <Grid className={classes.containerItem}>
            <Typography variant="body2" className={classes.label}>
              {t.translate('Return handling')}
            </Typography>
            <CheckboxControl
              disabled={!isEditMode}
              id={makeId('fixed-fees')}
              name="isFixedFees"
              error={errors.isFixedFees}
              label={t.translate('Bill fixed fees to customer')}
              checked={!!values.isFixedFees}
              onChange={handleChange}
            />
            <CheckboxControl
              disabled={!isEditMode}
              id={makeId('variable-fees')}
              name="isVariableFees"
              error={errors.isVariableFees}
              label={t.translate('Bill variable fees to customer')}
              checked={!!values.isVariableFees}
              onChange={handleChange}
            />
          </Grid>
          {values.handlingType === SupplierHandlingTypes.DEFAULT && (
            <Grid className={classes.containerItem}>
              <Typography variant="body2" className={classes.label}>
                {t.translate('Replenishment suggestions')}
              </Typography>
              <CheckboxControl
                disabled={!isEditMode}
                id={makeId('is-replenishment-suggestions-enabled')}
                name="isReplenishmentSuggestionsEnabled"
                error={errors.isReplenishmentSuggestionsEnabled }
                label={t.translate('Automatic warehouse replenishment')}
                checked={!!values.isReplenishmentSuggestionsEnabled}
                onChange={handleChange}
              />
              <Grid className={classes.marginBottom}>
                <InputControl
                  disabled={!isEditMode}
                  id={makeId('invoiceEmail')}
                  name="replenishmentSuggestionsEmail"
                  error={errors.replenishmentSuggestionsEmail}
                  label={t.translate('Replenishment suggestions email')}
                  value={values.replenishmentSuggestionsEmail}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          )}
          <Grid item container className={classes.containerItem}>
            <InputControl
              id={makeId('resellingAccountId')}
              error={errors.resellingAccountId}
              label={t.translate('Account for reselling')}
              InputComponent={() => (
                <Select
                  disabled={!isEditMode}
                  value={values.resellingAccountId}
                  name="resellingAccountId"
                  onChange={handleChange}
                >
                  {!supplier ? <MenuItem value={0}>{t.translate('Itself')}</MenuItem> : null}
                  {suppliers
                    .filter(({ id, handlingType }) => handlingType === DEFAULT || supplier?.id === id)
                    .map(s => (<MenuItem key={s.id} value={s.id}>{`${s.number} - ${s.name}`}</MenuItem>))}
                </Select>
              )}
            />
          </Grid>
        </Grid>
        <Grid item md={8} xs={12}>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell name="marketplaces">{t.translate('Marketplaces')}</TableCell>
                  <TableCell name="company">
                    <span>{t.translate('Representative company')}</span>
                    <Hint text={'supplierRepresentativeCompany:info'}/>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activeMarketplaces
                  .filter(marketplace =>
                    (supplier && (showAll || values.companies.map(item => item.marketplaceCountryId).includes(marketplace.id))) ||
                    (!supplier && (showAll || marketplace.country.countryCode === 'de'))
                  ).map((marketplace, index) => {
                    const currentCompany = values.companies.find(company => company.marketplaceCountryId === marketplace.id) || {};
                    return (
                      <TableRow key={index}>
                        <TableCell>{t.translate(`country:${marketplace.country.countryCode}`)}</TableCell>
                        <TableCell>
                          <Select
                            fullWidth
                            disabled={!isEditMode || !companies.length}
                            value={currentCompany.companyId || ''}
                            name={`companies[${index}].companyId`}
                            onChange={(event) => {
                              const selectedCompanyId = event.target.value;
                              const updatedCompanies = values.companies.map(company =>
                                company.marketplaceCountryId === marketplace.id
                                  ? { ...company, companyId: selectedCompanyId }
                                  : company
                              );

                              if (!values.companies.some(company => company.marketplaceCountryId === marketplace.id)) {
                                updatedCompanies.push({ marketplaceCountryId: marketplace.id, companyId: selectedCompanyId });
                              }

                              setFieldValue('companies', updatedCompanies);
                            }}
                            id={makeId(`representative-company`)}
                            error={Boolean(errors.companies)}
                          >
                            {companies.map(company => (
                              <MenuItem key={company.id} value={company.id}>
                                {company.details.length ? company.details[0].name : 'n/a'}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {(!showAll && values.companies.length !== activeMarketplaces.length) && (
              <Button onClick={handleToggleShowAll} disabled={!isEditMode}>
                {t.translate('More')}
              </Button>
            )}
          </TableContainer>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};
