import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { styleMobileTableItem } from 'theme/mixins/table';
import { SupplierPaymentsMethods } from '../../../../../consts';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  root: {
    padding: 0,
  },
  featureLabel: {
    fontWeight: 500,
  },
  divider: {
    paddingLeft: 16,
  },
}));

export const OrderDetails = ({ order }) => {
  const { classes } = useStyles();
  const t = useTranslator();

  const getPaymentMethodName = (id) => {
    const method = SupplierPaymentsMethods.find(method => method.id === id);
    return method ? ` (${t.translate(method.text)})` : '';
  };

  return (
    <Paper className={classes.root}>
      <Grid container direction="column">
        <Grid container p={2}>
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.featureLabel}>{t.translate('Order Value (net)')}</Typography>
            <Typography variant="body2" className={classes.featureLabel}>{t.translate('Order Value (gross)')}</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2" align="right">{numeral(Number(order.netSum)).format('0,0.00')} &euro;</Typography>
            <Typography variant="body2" align="right">{numeral(Number(order.grossSum)).format('0,0.00')} &euro;</Typography>
          </Grid>
        </Grid>
        <Divider/>
        <Grid item container sx={{ padding: 2 }}>
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.featureLabel}>{t.translate('Handling Fee')}</Typography>
            <Typography variant="body2" className={classes.featureLabel}>{t.translate('Shipping costs')}</Typography>
            <Typography variant="body2" className={classes.featureLabel}>{t.translate('Alternative shipping address price')}</Typography>
            <Typography variant="body2" className={classes.featureLabel}>{t.translate('Regional surcharge')}</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2" align="right">{numeral(Number(order.netHandlingFee)).format('0,0.00')} &euro;</Typography>
            <Typography variant="body2" align="right">{numeral(Number(order.netShippingPrice)).format('0,0.00')} &euro;</Typography>
            <Typography variant="body2" align="right">{numeral(Number(order.netAlternativeShippingAddressPrice)).format('0,0.00')} &euro;</Typography>
            <Typography variant="body2" align="right">{numeral(Number(order.regionalShippingSurcharge)).format('0,0.00')} &euro;</Typography>
          </Grid>
        </Grid>
        <Divider/>
        <Grid container p={2}>
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.featureLabel}>{`${t.translate('Payment costs')}${getPaymentMethodName(order.paymentMethodId)}`}</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2" align="right">{numeral(Number(order.netPaymentPrice)).format('0,0.00')} &euro;</Typography>
          </Grid>
        </Grid>
        <Divider/>
        <Grid item container sx={{ padding: 2 }}>
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.featureLabel}>{t.translate('Order Total (net)')}</Typography>
            <Typography variant="body2" className={classes.featureLabel}>{t.translate('Order Total (gross)')}</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2" align="right">{numeral(Number(order.netTotalPrice)).format('0,0.00')} &euro;</Typography>
            <Typography variant="body2" align="right">{numeral(Number(order.grossTotalPrice)).format('0,0.00')} &euro;</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

OrderDetails.propTypes = {
  order: PropTypes.shape({
    netSum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    grossSum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    netHandlingFee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    netShippingPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    netAlternativeShippingAddressPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    netPaymentPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    netTotalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    grossTotalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    regionalShippingSurcharge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};
