import React from 'react';
import PropTypes from 'prop-types';

import { Column } from 'layouts';
import { WarehousesTable } from './WarehousesTable/WarehousesTable';

export const DesktopView = ({ onWarehouseDelete, ...props }) => (
  <Column p={3}>
    <WarehousesTable onWarehouseDelete={onWarehouseDelete} {...props}/>
  </Column>
);

DesktopView.propTypes = {
  onWarehouseDelete: PropTypes.func.isRequired,
};
