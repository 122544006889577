import React from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslator } from 'i18n';
import {
  useTableSortableHeadCell,
  useList,
} from 'hooks';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';

import {
  ActionButton,
  Pagination,
  CustomTableRow,
} from 'components';

import { wrapTableRowClick } from 'utils/html';
import tables from 'config/tables';
import toaster from 'services/toaster';
import { fetchCompanies } from 'services/companies';
import companiesApi from 'api/companies';

export const useStyles = makeStyles()(theme => ({
  button: {
    backgroundColor: theme.palette.primary.l10,
    color: theme.palette.primary.dark,
    fontSize: '0.75rem',
    fontWeight: 'normal',
    height: 'inherit',
    textTransform: 'none',
  },
}));

export const CompaniesTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const list = useList(tables.companies.companies);
  const t = useTranslator();

  const { data: companies, meta } = useSelector(({ companies }) => companies.overview);

  const SortableCell = useTableSortableHeadCell({
    changeSorting: list.changeSorting,
    sorting: list.sorting,
  });

  const openCompany = company => history.push(`/settings/companies/edit-company/${company.id}`);

  const onCompanyDelete = async (companyId) => {
    try {
      await companiesApi.deleteOne(companyId);
      toaster.success(t.translate('Company has been deleted successfully.'));
      await dispatch(fetchCompanies(list.compile()));
    } catch (err) {}
  };

  return (
    <>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <SortableCell name="id">ID</SortableCell>
              <SortableCell name="name">{t.translate('Company name')}</SortableCell>
              <SortableCell name="alternativeName">{t.translate('Company additional name')}</SortableCell>
              <SortableCell name="country">{t.translate('Country')}</SortableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((company, index) => {
              const details = company.details[0];

              return (
                <CustomTableRow key={index} hover onClick={wrapTableRowClick(() => openCompany(company))}>
                  <TableCell>{company.id}</TableCell>
                  <TableCell>{details.name}</TableCell>
                  <TableCell>{details.alternativeName !== 'null' ? details.alternativeName : '-'}</TableCell>
                  <TableCell>{t.translate(`country:${details.address.addressCountry.countryCode}`)}</TableCell>
                  <TableCell align="right">
                    <Grid item>
                      {company.id !== 1 && (<ActionButton
                        aria-label={t.translate('Delete company')}
                        tooltip={t.translate('Delete company')}
                        className={classes.button}
                        icon={<DeleteIcon/>}
                        onClick={() => onCompanyDelete(company.id)}
                      />)}
                      <ActionButton
                        aria-label={t.translate('Open company')}
                        tooltip={t.translate('verb:Open')}
                        icon={<ChevronRightIcon/>}
                        onClick={() => openCompany(company)}
                      />
                    </Grid>
                  </TableCell>
                </CustomTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination {...list.paginationProps} count={meta.totalEntities} />
    </>
  );
};

CompaniesTable.propTypes = {};
