import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useTranslator } from '../../i18n';
import { useList, useScreen } from '../../hooks';

import { Header, DesktopView } from 'views/changes-history';
import tables from '../../config/tables';
import { fetchChangesHistory } from '../../services/changes-history';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';


export const ChangesHistory = () => {
  const location = useLocation();
  const t = useTranslator();
  const screen = useScreen();
  const dispatch = useDispatch();
  const list = useList(tables.changesHistory.changesHistory);

  const fetchData = useCallback(() => dispatch(fetchChangesHistory(list.compile())), [dispatch, list]);

  useEffect(() => void fetchData(), [fetchData, location.search]);

  return (
    <>
      <Helmet>
        <title>{t.translate('Changes history')} | {t.translate('app:title')}</title>
      </Helmet>

      <Header isMobile={screen.isMobile} />
      <DesktopView/>
    </>
  );
};
