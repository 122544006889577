import rawOrdersApi from 'api/raw-orders';
import {
  setRawOrders,
  setIsLoading,
} from 'store/actions/raw-orders';

export const fetchRawOrders = params => async dispatch => {
  try {
    dispatch(setIsLoading(true));

    const orders = await rawOrdersApi.fetchRawOrders(params);

    dispatch(setRawOrders(orders));
    dispatch(setIsLoading(false));
  } catch (err) {
    dispatch(setIsLoading(false));
  }
};
