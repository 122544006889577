import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useTranslator } from 'i18n';

import {
  AppHeaderPrimaryToolbar,
  AppHeaderBackButton,
  AppHeaderTitle,
  AppHeader,
  ViewSwitch,
  AppHeaderRightBox,
} from 'components';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from 'tss-react/mui';
import GetAppIcon from '@mui/icons-material/GetApp';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import Chip from '@mui/material/Chip';

const useStyles = makeStyles()((theme) => ({
  button: {
    color: 'inherit',
    height: 'inherit',
    textTransform: 'none',
    minWidth: theme.spacing(4),
    marginRight: theme.spacing(2),
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  editedByInfo: {
    color: 'inherit',
    fontSize: '0.875rem',
    fontWeight: 'normal',

    '&.MuiChip-root': {
      border: 'none',
    },
  },
  tooltip: {
    padding: theme.spacing(2),
  },
}));

export const Header = ({
  supplier,
  tabs,
  isMobileView,
  activeTab,
  formState,
  setFormState,
  onSupplierExport,
  canEditSupplier,
  isEditedByAnotherUser,
}) => {
  const history = useHistory();
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <AppHeader>
      <AppHeaderPrimaryToolbar>
        <AppHeaderBackButton edge="start" onClick={() => history.goBack()}/>
        <AppHeaderTitle>{supplier ? `${t.translate('Supplier')}: ${supplier.number}` : t.translate('Create supplier')}</AppHeaderTitle>
        <AppHeaderRightBox alignItems="center">
          {isEditedByAnotherUser && (
            isMobileView
              ? <Tooltip
                className={classes.tooltip}
                title={`${t.translate('This supplier is currently being edited by')} ${supplier.editedByUser.name}`}
              >
                <InfoIcon />
              </Tooltip>
              : <Chip
                className={classes.editedByInfo}
                variant="outlined"
                label={`${t.translate('This supplier is currently being edited by')} ${supplier.editedByUser.name}`}
              />
          )}
          {canEditSupplier && (
            <>
              <Button
                className={classes.button}
                startIcon={<GetAppIcon/>}
                onClick={() => onSupplierExport(supplier)}
              >
                {t.translate('Export')}
              </Button>
              <Button
                className={classes.button}
                startIcon={formState.isEditMode ? <SaveIcon/> : <EditIcon/>}
                onClick={() => {
                  if (!formState.isEditMode) {
                    setFormState({ ...formState, isEditMode: true });
                  }
                  else {
                    formState.onSave();
                  }
                }}
              >
                {t.translate(formState.isEditMode ? 'Save': 'Edit')}
              </Button>
            </>
          )}
        </AppHeaderRightBox>
      </AppHeaderPrimaryToolbar>
      <ViewSwitch options={tabs} isMobile={isMobileView} activeOption={activeTab}/>
    </AppHeader>
  );
};

Header.propTypes = {
  supplier: PropTypes.object,
};
