import { PICKUP_SHEETS } from 'store/types';
import { pack } from 'store/utils';

export const setPickupSheets = payload => dispatch => dispatch(pack(PICKUP_SHEETS.SET_PICKUP_SHEETS, payload));

export const setActivePickupSheet = payload => dispatch => dispatch(pack(PICKUP_SHEETS.SET_ACTIVE_PICKUP_SHEET, payload));

export const updateActivePickupSheet = payload => dispatch => dispatch(pack(PICKUP_SHEETS.UPDATE_ACTIVE_PICKUP_SHEET, payload));

export const updateActivePickupSheetProduct = payload => dispatch => dispatch(pack(PICKUP_SHEETS.UPDATE_ACTIVE_PICKUP_SHEET_PRODUCT, payload));
