import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';

import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import PublishIcon from '@mui/icons-material/Publish';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';
import DoneIcon from '@mui/icons-material/Done';

const useStyles = makeStyles()(() => ({
  button: {
    overflow: 'hidden',
  },
  input: {
    bottom: 0,
    cursor: 'pointer',
    opacity: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
}));

export const FileUploadActionButton = ({
  onUploaded = () => {},
  onUpLoading = () => {},
  onError = () => {},
  disabled,
  children,
  Icon,
  noIcon,
  inputProps,
  ...props
}) => {
  const t = useTranslator();

  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleUpload = e => {
    try {
      setLoading(true);
      onUpLoading();
      const data = new FormData();
      data.set('file', e.target.files[0]);
      onUploaded(data, () => setIsError(true));
      e.target.value = null;
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setIsError(true);
      onError(err);
    }
  };

  const { classes, cx } = useStyles();

  return (
    <Tooltip title={t.translate('Upload image')} placement={'bottom'}>
      <IconButton
        {...props}
        className={cx(props.className, classes.button)}
        disabled={disabled || loading}
        onClick={() => setIsError(false)}
        size="large">
        {!loading && !isError
          ? <PublishIcon />
          : loading
            ? <CircularProgress size={24} />
            : isError
              ? <ClearIcon />
              : <DoneIcon />}

        {!loading && (
          <input
            { ...inputProps }
            className={classes.input}
            type="file"
            name="file"
            onChange={handleUpload}
          />
        )}
      </IconButton>
    </Tooltip>
  );
};

FileUploadActionButton.propTypes = {
  onUploaded: PropTypes.func,
  onUpLoading: PropTypes.func,
  onError: PropTypes.func,
  disabled: PropTypes.bool,
  noIcon: PropTypes.bool,
  inputProps: PropTypes.object,
};
