import React from 'react';
import PropTypes from 'prop-types';

import { Column } from 'layouts';
import { ProductsTable } from './ProductsTable/ProductsTable';

export const DesktopView = ({
  productTypes,
}) => (
  <>
    <Column p={3}>
      <ProductsTable productTypes={productTypes}/>
    </Column>
  </>
);

DesktopView.propTypes = {
  productTypes: PropTypes.array,
};
