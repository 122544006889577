import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth:'672px',
      },
    },
    '& .MuiFormControl-root': {
      marginTop: theme.spacing(2),
    },
  },
  container: {
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      '& .MuiGrid-item:first-of-type': {
        marginRight: theme.spacing(1.5),
      },
      '& .MuiGrid-item:last-child': {
        marginLeft: theme.spacing(1.5),
      },
      '& input': {
        padding: '12px 0',
      },
    },
    '& button': {
      padding: theme.spacing(1.5),
    },
  },
  containerAlzuraId: {
    width: '100%',
  },
  containerPhoto: {
    paddingBottom: theme.spacing(4),
    '& .MuiFormControl-root': {
      display: 'flex',
    },
    [theme.breakpoints.up('md')]: {
      '& > div:last-child': {
        marginLeft: theme.spacing(3),
      },
    },
  },
  containerCondition: {
    '& .MuiFormGroup-root': {
      borderBottomColor: theme.palette.grey[300],
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
    '& .MuiBox-root': {
      '& .MuiGrid-item:first-of-type': {
        width: '50%',
      },
      [theme.breakpoints.up('md')]: {
        marginRight: '12px',
      },
    },
    '& > :last-child': {
      marginLeft: '12px',
    },
  },
  containerControl: {
    width: '100%',
  },
  containerDetails: {
    paddingBottom: theme.spacing(4),
    '& > div': {
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        '& .MuiGrid-grid-md-4:not(:last-child) ': {
          marginRight: theme.spacing(1.5),
        },
        '& .MuiGrid-grid-md-4:not(:first-of-type) ': {
          marginLeft: theme.spacing(1.5),
        },
        '& .MuiGrid-grid-md-8': {
          marginLeft: theme.spacing(1.5),
        },
      },
    },
  },
  stockFormControl: {
    padding: theme.spacing(3),
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
    borderWidth: 1,
  },
  containerDimensions: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      '& .MuiGrid-item:not(:last-child)': {
        marginRight: theme.spacing(1.5),
      },
      '& .MuiGrid-item:not(:first-of-type)': {
        marginLeft: theme.spacing(1.5),
      },
    },
  },
  containerAdditionalInfo: {
    paddingBottom: theme.spacing(4),
  },
  qrButton: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      '& .MuiButton-endIcon': {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  checkboxes: {
    marginTop: theme.spacing(2),
  },
  button: {
    border: `2px solid ${theme.palette.grey['205']}`,
    borderRadius: '4px',
    color: theme.palette.grey['205'],
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    width: '30%',
    '&:nth-of-type(n+4)': {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
      width: '48%',
    },
    '& svg': {
      position: 'absolute',
      left: theme.spacing(2.25),
    },
    '&:hover': {
      background: 'none',
    },
    '&.MuiToggleButton-root.Mui-selected': {
      background: 'none',
    },
    '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
      borderWidth: '2px',
    },
  },
  toggleButton: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    '& button, & .Mui-selected': {
      backgroundColor: `${theme.palette.common.white} !important`,
    },
    '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
      marginLeft: 0,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
}));
