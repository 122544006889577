import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  table: {
    '& th:first-of-type, & td:first-of-type': {
      paddingLeft: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        '&.TableCell-fixedLeftLast': {
          borderRight: 'none',
        },
      },
    },
    '& th:last-child, & td:last-child': {
      paddingRight: theme.spacing(3),
    },
  },
  title: {
    alignItems: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    '& > p': {
      fontSize: '1.25rem',
      fontWeight: 700,
      marginLeft: theme.spacing(2),
    },
  },
  tableHeader: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  headerCell: {
    color: theme.palette.text.secondary,
    fontSize: '1rem',
  },
  tableRow: {
    '&:hover .MuiTableCell-body.TableCell-fixed:before': {
      backgroundColor: 'unset',
    },
  },
  titleFixedColumn: {
    alignItems: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    '& > p': {
      fontSize: '1.25rem',
      fontWeight: 700,
      overflow: 'hidden',
      marginLeft: theme.spacing(2),
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  cell: {
    borderBottom: 0,
    color: theme.palette.text,
    fontWeight: 700,
  },
  fixedCell: {
    borderBottom: 0,
  },
  label: {
    borderBottom: 0,
  },
}));
