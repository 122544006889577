import config from 'config';
import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { styleMobileTableItem } from 'theme/mixins/table';
import { selectPositionStatus } from '../../utils';
import { getRetailer } from '../../../../../utils/retailers';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  accordion: {
    background: theme.palette.backgrounds.main['50'],
    boxShadow: 'none',
    '& .MuiCollapse-root': {
      background: theme.palette.common.white,
      paddingTop: theme.spacing(1),
    },
  },
  bold: {
    fontWeight: 500,
  },
  button: {
    backgroundColor: theme.palette.primary.l10,
    color: theme.palette.primary.main,
    fontSize: '0.75rem',
    textTransform: 'none',
  },
  cancelIcon: {
    color: theme.palette.red.coral,
    fontSize: '1rem',
  },
  contentItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  statusItem: {
    alignItems: 'center',
    display: 'flex',
  },
  doneIcon: {
    color: theme.palette.green.main,
    fontSize: '1rem',
  },
  divider: {
    margin: theme.spacing(0, 2),
  },
  item: {
    background: theme.palette.common.white,
    marginBottom: theme.spacing(2),
  },
  orderedIcon: {
    color: theme.palette.text.secondary,
    fontSize: '1rem',
  },
  productDescription: {
    marginTop: theme.spacing(2),
  },
}));

export const ProductItem = ({ position, onProductDescriptionOpen, order, onStockHistoryShow }) => {
  const { classes } = useStyles();
  const t = useTranslator();

  const fahrzeugAttirbute = position.attributes?.find(attribute => attribute.generic_key === 'fahrzeug');
  const vehicleAttirbute = position.attributes?.find(attribute => attribute.generic_key === 'vehicleinformation');

  const { text: statusText, icon: statusIcon } = selectPositionStatus(position.externalOrderStatus || order.statusCode, order.supplier?.handlingType, order.inReview, classes);
  const retailer = getRetailer(order.supplier.retailers, order.country);

  return (
    <Grid item className={classes.item}>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item className={classes.contentItem}>
            <Typography variant="body1" className={classes.bold}>{position.product.details[0]?.name || '—'}</Typography>
            <Box>
              <Typography variant="body2" component="span" className={classes.bold}>{t.translate('Product EAN code')}:&nbsp;</Typography>
              <Typography variant="body2" component="span">{position.product.ean || '—'}</Typography>
            </Box>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item className={classes.contentItem}>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('ALZURA-ID')}</Typography>
              <Typography variant="body2">{position.product.alzuraId || '—'}</Typography>
            </Grid>
            {(fahrzeugAttirbute || vehicleAttirbute) && (
              <Grid container justifyContent="space-between">
                <Typography variant="body2" className={classes.bold}>{t.translate(fahrzeugAttirbute?.key || vehicleAttirbute?.key)}</Typography>
                <Typography variant="body2">{(fahrzeugAttirbute?.value || vehicleAttirbute?.value) || '—'}</Typography>
              </Grid>
            )}
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Net single price')}</Typography>
              <Typography variant="body2">{numeral(Number(position.netPrice)).format('0,0.00')} &euro;</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Net total price')}</Typography>
              <Typography variant="body2">{numeral(Number(position.netTotalPrice)).format('0,0.00')} &euro;</Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider className={classes.divider}/>
      </Accordion>
      <Paper className={classes.paper}>
        <Grid container direction="column">
          <Grid item className={classes.contentItem}>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Quantity')}</Typography>
              <Typography variant="body2">{position.quantity}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Order-Reference')}</Typography>
              {!!position.externalOrderNumber ?
                <Link
                  href={`${config.tyre24BackOfficeUrl}/orderhistory/details/order/${position.externalOrderNumber}/country/${retailer.country}`}
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  {position.externalOrderNumber || '—'}
                </Link> : '—'
              }
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Status')}</Typography>
              <Typography variant="body2" className={classes.statusItem}>{statusText}&nbsp;{statusIcon}</Typography>
            </Grid>
          </Grid>
          <Grid item className={classes.contentItem}>
            <Grid item align="right" className={classes.productDescription}>
              <Button
                className={classes.button}
                startIcon={<InfoIcon />}
                onClick={() => onProductDescriptionOpen(position)}
              >
                {t.translate('Product description')}
              </Button>
              <Button
                className={classes.button}
                startIcon={<InfoIcon />}
                onClick={() => onStockHistoryShow(position.productId)}
              >
                {t.translate('History')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

ProductItem.propTypes = {
  position: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    externalOrderNumber: PropTypes.string,
    externalOrderStatus: PropTypes.number,
    netPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    netTotalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    attributes: PropTypes.array.isRequired,
    product: PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string,
      ean: PropTypes.string,
      alzuraId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  onProductDescriptionOpen: PropTypes.func.isRequired,
  onStockHistoryShow: PropTypes.func.isRequired,
  order: PropTypes.shape({
    statusCode: PropTypes.number.isRequired,
    inReview: PropTypes.bool.isRequired,
    supplier: PropTypes.object.isRequired,
  }).isRequired,
};
