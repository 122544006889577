import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useList } from 'hooks';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { Column } from 'layouts';
import {
  CircularLoader,
  Pagination,
} from 'components';

import { RetailerItem } from './RetailerItem/RetailerItem';

import tables from 'config/tables';

export const MobileView = ({
  isLoading,
  retailersMetadata,
  retailers,
}) => {
  const history = useHistory();
  const list = useList(tables.retailers.retailers);

  const openRetailer = retailer => history.push(`retailers/${retailer.id}`);

  return (
    <Column p={1} pb={8.5} pt={2}>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <Grid container direction="column">
          {retailers.map(retailer => (
            <RetailerItem
              key={retailer.id}
              retailer={retailer}
              onRetailerOpen={openRetailer}
            />
          ))}
          <Paper>
            <Pagination
              {...list.paginationProps}
              count={retailersMetadata.totalEntities}
              direction="row"
            />
          </Paper>
        </Grid>
      )}
    </Column>
  );
};

MobileView.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  retailersMetadata: PropTypes.shape({
    totalEntities: PropTypes.number.isRequired,
  }).isRequired,
  retailers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
};
