import api from '.';
import { extractListParams } from 'utils/querying';

export const fetchWarehouses = params => api.get('/warehouses', { params: extractListParams(params) });

export const fetchWarehousesForFilter = () => api.get('/warehouses;filter');

export const fetchWarehouse = warehouseId => api.get(`/warehouses/${warehouseId}`);

export const createWarehouse = payload => api.post('/warehouses', payload);

export const modifyWarehouse = (warehouseId, payload) => api.put(`/warehouses/${warehouseId}`, payload);

export const deleteWarehouse = warehouseId => api.delete(`/warehouses/${warehouseId}`);

export const disableWarehouseShippingPartner = ({ shippingPartnerId, warehouseId }, options) =>
  api.patch(`/warehouses/${warehouseId}/shipping-partners/${shippingPartnerId};disabled`, null, options);
export const enableWarehouseShippingPartner = ({ shippingPartnerId, warehouseId }, options) =>
  api.patch(`/warehouses/${warehouseId}/shipping-partners/${shippingPartnerId};enabled`, null, options);

export default {
  disableWarehouseShippingPartner,
  enableWarehouseShippingPartner,
  modifyOne: modifyWarehouse,
  fetchMany: fetchWarehouses,
  fetchManyForFilter: fetchWarehousesForFilter,
  deleteOne: deleteWarehouse,
  createOne: createWarehouse,
  fetchOne: fetchWarehouse,
};
