import { I18N } from 'store/types';
import { i18n as is } from 'store/initialState';

export const setMessages = (state, { messages, locale }) => ({
  ...state,
  messages: {
    ...state.messages,
    [locale]: messages,
  },
});

export const setLocale = (state, locale) => ({ ...state, locale });

export default (state = is, { type, payload }) => {
  switch (type) {
    case I18N.SET_MESSAGES:
      return setMessages(state, payload);
    case I18N.SET_LOCALE:
      return setLocale(state, payload);
    default:
      return state;
  }
};
