import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useTranslator } from 'i18n';
import {
  useScreen,
} from 'hooks';

import {
  DesktopView,
  MobileView,
} from 'views/products/overview';

export const Products = ({ productTypes }) => {
  const screen = useScreen();
  const t = useTranslator();

  return (
    <>
      <Helmet>
        <title>{t.translate('Products')} | {t.translate('app:title')}</title>
      </Helmet>

      {screen.isMobile ? (
        <MobileView
          productTypes={productTypes}
        />
      ) : (
        <DesktopView
          productTypes={productTypes}
        />
      )}
    </>
  );
};
