import React from 'react';
import { CompaniesTable } from './CompaniesTable/CompaniesTable';
import { Column } from 'layouts';

export const DesktopView = () => (
  <Column filled>
    <CompaniesTable/>
  </Column>
);

DesktopView.propTypes = {};
