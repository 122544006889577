import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import { CircularLoader } from 'components';
import { RetailerCard } from '../common';
import { Column } from 'layouts';

export const MobileView = ({
  isLoading,
  retailer,
  ...props
}) => {
  if (isLoading || !retailer) {
    return <CircularLoader />;
  } else {
    return (
      <Column p={1}>
        <Grid container direction="column">
          <RetailerCard retailer={retailer} {...props}/>
        </Grid>
      </Column>
    );
  }
};

MobileView.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};
