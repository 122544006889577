import warehousesApi from 'api/warehouses';
import storagesApi from 'api/storages';
import storageProductsApi from 'api/storage-products';
import {
  setOverviewWarehouses,
  setOverviewStorages,
  setStorageProducts,
  setStorage,
} from 'store/actions/storages';

export const fetchStorages = params => async dispatch => {
  try {
    const storages = await storagesApi.fetchMany(params);
    dispatch(setOverviewStorages(storages));
  } catch (err) {}
};

export const fetchWarehouses = () => async dispatch => {
  try {
    const warehouoses = await warehousesApi.fetchMany({ meta: ['storages-filter-set'] });
    dispatch(setOverviewWarehouses(warehouoses.data));
  } catch (err) {}
};

export const fetchStorage = storageId => async dispatch => {
  try {
    const storage = await storagesApi.fetchOne(storageId);
    dispatch(setStorage(storage));
  } catch (err) {}
};

export const fetchStorageProducts = (storageId, params) => async dispatch => {
  try {
    const storageProducts = await storageProductsApi.fetchMany({ storageId, ...params });
    dispatch(setStorageProducts(storageProducts));
  } catch (err) {}
};
