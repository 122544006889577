import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import debounce from 'lodash/debounce';

import { useRoute } from 'hooks/route';
import { useStyles } from './style';
import { useTranslator } from 'i18n';

import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import ClickAwayListener from '@mui/material/ClickAwayListener';

export const QuerySearchInput = ({
  fullWidth,
  className,
  name,
  placeholder,
  hideInput = false,
  ...props
}) => {
  const [open, setOpen] = useState(!hideInput);
  const route = useRoute();
  const { classes } = useStyles({ open });
  const t = useTranslator();

  const searchQuery = name
    ? route.search[name]?.q
    : route.search.q;

  const [value, setValue] = useState(searchQuery || '');

  const setQuery = q => route.parse().replace({
    search: {
      ...route.search,
      ...(name ? {
        [name]: {
          ...(route.search[name] || {}),
          q: q || undefined,
        },
      } : {
        q: q || undefined,
      }),
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetQuery = useCallback(debounce((value) => setQuery(value), 500), [route.history.location.search]);

  useEffect(() => debouncedSetQuery.cancel, [debouncedSetQuery.cancel]);

  useEffect(() => {
    debouncedSetQuery(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);


  return (
    <ClickAwayListener onClickAway={hideInput ? () => setOpen(false) : () => { }}>
      <div className={clsx(className, classes.root, !fullWidth && classes.rootDynamicWidth)} onClick={() => setOpen(true)}>
        <SearchIcon className={classes.searchIcon} />
        {open && (
          <InputBase
            value={value || ''}
            placeholder={placeholder || t.translate('Search') + '…'}
            classes={{
              root: clsx(classes.inputRoot, !fullWidth && classes.inputRootDynamicWidth),
              input: classes.inputInput,
              ...(!fullWidth && { focused: classes.inputFocusedDynamicWidth }),
            }}
            onChange={e => setValue(e.target.value)}
            {...props}
            {...(value && {
              endAdornment: (
                <IconButton className={classes.clearButton} onClick={() => setValue('')} size="large">
                  <CancelIcon />
                </IconButton>
              ),
            })}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

QuerySearchInput.propTypes = {
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  hideInput: PropTypes.bool,
};
