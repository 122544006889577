import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    borderRadius: theme.spacing(.5),
    '& td': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.grey['250']}`,
      '&:first-child': {
        borderLeft: `1px solid ${theme.palette.grey['250']}`,
        borderTopLeftRadius: theme.spacing(.5),
        borderBottomLeftRadius: theme.spacing(.5),
      },
      '&:last-child': {
        borderRight: `1px solid ${theme.palette.grey['250']}`,
        borderTopRightRadius: theme.spacing(.5),
        borderBottomRightRadius: theme.spacing(.5),
      },
    },
  },
  nameCell: {
    width: theme.spacing(21),
  },
  stockFormControl: {
    '& > div': {
      justifyContent: 'center',
    },
  },
  expandedRow: {
    backgroundColor: theme.palette.backgrounds.grey['240'],
    '& td': {
      '&:first-child': {
        borderBottomLeftRadius: 0,
      },
      '&:last-child': {
        borderBottomRightRadius: 0,
      },
    },
  },
  expansionRow: {
    '& td': {
      borderLeft: `1px solid ${theme.palette.grey['250']}`,
      borderRight: `1px solid ${theme.palette.grey['250']}`,
      borderBottomLeftRadius: theme.spacing(.5),
      borderBottomRightRadius: theme.spacing(.5),
      padding: theme.spacing(2),
    },
  },
  reasonItem: {
    marginBottom: theme.spacing(3),
  },
}));
