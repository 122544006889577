import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { makeStyles } from 'tss-react/mui';
import InfoIcon from '@mui/icons-material/Info';
import {
  Grid,
  Input,
  Button,
  TableHead,
  TableBody,
  Typography,
  FormControl,
  InputAdornment,
  TableContainer,
  IconButton,
  Popover,
} from '@mui/material';

import { FormControlError, Table, TableCell, TableRow } from '../../../components';
import { useTranslator } from '../../../i18n';
import toaster from '../../../services/toaster';
import { fetchStorageFees, modifyStorageFees } from 'api/storage-fees';

const useStyles = makeStyles()(theme => ({
  actionsItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  hint: {
    maxWidth: 320,
  },
  cell: {
    verticalAlign: 'top',
    padding: theme.spacing(2, 1),
  },
  measure: {
    color: theme.palette.grey['150'],
  },
  power: {
    marginBottom: '10px',
  },
}));

export const StorageFeesConfiguration = () => {
  const [initialValues, setInitialValues] = useState({
    storageFees: [],
  });

  const t = useTranslator();
  const { classes } = useStyles();

  useEffect(() => {
    async function fetchData() {
      const storageFees = await fetchStorageFees();

      setInitialValues({
        storageFees: storageFees.map(item => ({
          ...item,
          storageFeeBaseValue: t.formatPrice(item.storageFeeBaseValue),
          storageActionFeeBaseValue: t.formatPrice(item.storageActionFeeBaseValue),
          packageFeeLight: t.formatPrice(item.packageFeeLight),
          packageFeeHeavy: t.formatPrice(item.packageFeeHeavy),
        })),
      });
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = Yup
    .object()
    .shape({
      storageFees: Yup.array().of(
        Yup.object().shape({
          storageFeeBaseValue: Yup.string()
            .required().label(t.translate('Storage fee base value')),
          storageActionFeeBaseValue: Yup.string()
            .required().label(t.translate('Storage action fee base value')),
          packageFeeLight: Yup.string()
            .required().label(t.translate('Package fee')),
          packageFeeHeavy: Yup.string()
            .required().label(t.translate('Package fee')),
        })
      ),
    })
  ;

  const onSubmit = async () => {
    try {
      await modifyStorageFees({
        storageFees: values.storageFees.map(item => ({
          ...item,
          storageFeeBaseValue: t.parsePrice(item.storageFeeBaseValue),
          storageActionFeeBaseValue: t.parsePrice(item.storageActionFeeBaseValue),
          packageFeeLight: t.parsePrice(item.packageFeeLight),
          packageFeeHeavy: t.parsePrice(item.packageFeeHeavy),
        })),
      });

      toaster.success(t.translate('Return fees have been updated successfully'));
    } catch (err) {
      toaster.error(Object.values(err.collection)[0][0]);
    }
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    enableReinitialize: true,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  const [anchorEl, setAnchorEl] = useState({ infoFixed: null, infoVariable: null });

  const handlePopoverOpen = (name, event) => {
    event.persist();
    setAnchorEl(prevState => ({
      ...prevState,
      [name]: event.currentTarget,
    }));
  };

  const handlePopoverClose = (name) => {
    setAnchorEl(prevState => ({
      ...prevState,
      [name]: null,
    }));
  };

  return <>
    <Grid item>
      <Typography variant="h6">
        {t.translate('Storage fees')}
      </Typography>
    </Grid>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell name="country" className={classes.countryCell}>
              {t.translate('Country')}
            </TableCell>
            <TableCell name="storageFeeBaseValue">
              <Grid item>
                {t.translate('Storage fee')}
                <IconButton
                  aria-label="hint"
                  aria-owns={Boolean(anchorEl.infoStorageFeeBaseValue) ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={e => handlePopoverOpen('infoStorageFeeBaseValue', e)}
                  onMouseLeave={() => handlePopoverClose('infoStorageFeeBaseValue')}
                  onClick={e => handlePopoverOpen('infoStorageFeeBaseValue', e)}
                  size="large">
                  <InfoIcon />
                </IconButton>
                <Popover
                  id="mouse-over-popover"
                  className={classes.popover}
                  classes={{ paper: classes.paper }}
                  open={Boolean(anchorEl.infoStorageFeeBaseValue)}
                  anchorEl={anchorEl.infoStorageFeeBaseValue}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  onClose={() => handlePopoverClose('infoStorageFeeBaseValue')}
                  disableRestoreFocus
                >
                  <Typography variant="body2" className={classes.hint}>
                    {t.translate('storageFee:infoStorageFeeBaseValue')}
                  </Typography>
                </Popover>
              </Grid>
            </TableCell>
            <TableCell name="storageActionFeeBaseValue">
              <Grid item>
                {t.translate('Storage action fee')}
                <IconButton
                  aria-label="hint"
                  aria-owns={Boolean(anchorEl.infoStorageActionFeeBaseValue) ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={e => handlePopoverOpen('infoStorageActionFeeBaseValue', e)}
                  onMouseLeave={() => handlePopoverClose('infoStorageActionFeeBaseValue')}
                  onClick={e => handlePopoverOpen('infoStorageActionFeeBaseValue', e)}
                  size="large">
                  <InfoIcon />
                </IconButton>
                <Popover
                  id="mouse-over-popover"
                  className={classes.popover}
                  classes={{ paper: classes.paper }}
                  open={Boolean(anchorEl.infoStorageActionFeeBaseValue)}
                  anchorEl={anchorEl.infoStorageActionFeeBaseValue}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  onClose={() => handlePopoverClose('infoStorageActionFeeBaseValue')}
                  disableRestoreFocus
                >
                  <Typography variant="body2" className={classes.hint}>
                    {t.translate('storageFee:infoStorageActionFeeBaseValue')}
                  </Typography>
                </Popover>
              </Grid>
            </TableCell>
            <TableCell name="packageFeeLight">
              <Grid item>
                {t.translate('Package fee')}
                <IconButton
                  aria-label="hint"
                  aria-owns={Boolean(anchorEl.infoPackageFeeLight) ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={e => handlePopoverOpen('infoPackageFeeLight', e)}
                  onMouseLeave={() => handlePopoverClose('infoPackageFeeLight')}
                  onClick={e => handlePopoverOpen('infoPackageFeeLight', e)}
                  size="large">
                  <InfoIcon />
                </IconButton>
                <Popover
                  id="mouse-over-popover"
                  className={classes.popover}
                  classes={{ paper: classes.paper }}
                  open={Boolean(anchorEl.infoPackageFeeLight)}
                  anchorEl={anchorEl.infoPackageFeeLight}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  onClose={() => handlePopoverClose('infoPackageFeeLight')}
                  disableRestoreFocus
                >
                  <Typography variant="body2" className={classes.hint}>
                    {t.translate('storageFee:infoPackageFeeLight')}
                  </Typography>
                </Popover>
              </Grid>
            </TableCell>
            <TableCell name="packageFeeHeavy">
              <Grid item>
                {t.translate('Package fee')}
                <IconButton
                  aria-label="hint"
                  aria-owns={Boolean(anchorEl.infoPackageFeeHeavy) ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={e => handlePopoverOpen('infoPackageFeeHeavy', e)}
                  onMouseLeave={() => handlePopoverClose('infoPackageFeeHeavy')}
                  onClick={e => handlePopoverOpen('infoPackageFeeHeavy', e)}
                  size="large">
                  <InfoIcon />
                </IconButton>
                <Popover
                  id="mouse-over-popover"
                  className={classes.popover}
                  classes={{ paper: classes.paper }}
                  open={Boolean(anchorEl.infoPackageFeeHeavy)}
                  anchorEl={anchorEl.infoPackageFeeHeavy}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  onClose={() => handlePopoverClose('infoPackageFeeHeavy')}
                  disableRestoreFocus
                >
                  <Typography variant="body2" className={classes.hint}>
                    {t.translate('storageFee:infoPackageFeeHeavy')}
                  </Typography>
                </Popover>
              </Grid>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.storageFees.map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                <Typography>
                  {t.translate(`country:${item.marketplaceCountry.country.countryCode}`)}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <FormControl
                  error={!!errors?.storageFees && !!errors.storageFees[index]?.storageFeeBaseValue}
                >
                  <Input
                    id={'storage-fee-base-value-input'}
                    name={`storageFees[${index}].storageFeeBaseValue`}
                    value={item.storageFeeBaseValue}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment className={classes.measure} position="end">
                        € / m<sup className={classes.power}>3</sup>
                      </InputAdornment>
                    }
                  />
                  {
                    !!errors?.storageFees
                    && !!errors.storageFees[index]?.storageFeeBaseValue
                    && <FormControlError error={errors.storageFees[index].storageFeeBaseValue}/>
                  }
                </FormControl>
              </TableCell>
              <TableCell className={classes.cell}>
                <FormControl error={!!errors?.storageFees && !!errors.storageFees[index]?.storageActionFeeBaseValue}>
                  <Input
                    id={'storage-action-fee-base-value-input'}
                    name={`storageFees[${index}].storageActionFeeBaseValue`}
                    value={item.storageActionFeeBaseValue}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment className={classes.measure} position="end">
                        € / action
                      </InputAdornment>
                    }
                  />
                  {
                    !!errors?.storageFees
                    && !!errors.storageFees[index]?.storageActionFeeBaseValue
                    && <FormControlError error={errors.storageFees[index].storageActionFeeBaseValue}/>
                  }
                </FormControl>
              </TableCell>
              <TableCell className={classes.cell}>
                <FormControl error={!!errors?.storageFees && !!errors.storageFees[index]?.packageFeeLight}>
                  <Input
                    id={'package-fee-light-input'}
                    name={`storageFees[${index}].packageFeeLight`}
                    value={item.packageFeeLight}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment className={classes.measure} position="end">
                        € / pkg
                      </InputAdornment>
                    }
                  />
                  {
                    !!errors?.storageFees
                    && !!errors.storageFees[index]?.packageFeeLight
                    && <FormControlError id={'package-fee-light-error'} error={errors.storageFees[index].packageFeeLight}/>
                  }
                </FormControl>
              </TableCell>
              <TableCell className={classes.cell}>
                <FormControl error={!!errors?.storageFees && !!errors.storageFees[index]?.packageFeeHeavy}>
                  <Input
                    id={'package-fee-heavy-input'}
                    name={`storageFees[${index}].packageFeeHeavy`}
                    value={item.packageFeeHeavy}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment className={classes.measure} position="end">
                        € / pkg
                      </InputAdornment>
                    }
                  />
                  {
                    !!errors?.storageFees
                    && !!errors.storageFees[index]?.packageFeeHeavy
                    && <FormControlError error={errors.storageFees[index].packageFeeHeavy}/>
                  }
                </FormControl>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid item className={classes.actionsItem}>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          onClick={handleSubmit}
        >
          {t.translate('Save')}
        </Button>
      </Grid>
    </TableContainer>
  </>;
};
