import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import { TextField } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CreateIcon from '@mui/icons-material/Create';
import CancelIcon from '@mui/icons-material/Cancel';

const useStyles = makeStyles()(theme => ({
  tag: {
    color: 'rgba(0, 0, 0, 0.8);',
    fontSize: 16,
    display: 'flex',
    padding: '5px 7px',
    backgroundColor: 'rgb(218,218,218)',
    alignItems: 'center',
    marginBottom: theme.spacing(.5),
    marginRight: theme.spacing(.5),
    borderRadius: theme.spacing(0.5),
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    color: 'rgb(166,166,166)',
    cursor: 'pointer',
    '& :hover': {
      color: 'rgb(130,130,130)',
    },
  },
  textField: {
    '& .MuiInputBase-inputMarginDense': {
      padding: 0,
      fontSize: 16,
    },
    width: 300,
    marginLeft: 5,
    paddingTop: 0,
  },
  doneIcon: {
    marginLeft: 5,
    fontSize: 16,
  },
  createIcon: {
    marginLeft: 5,
    fontSize: 16,
  },
  cancelIcon: {
    fontSize: 16,
  },
}));

export const ProductGroupBadge = ({ id, groupName, onRename, onDelete }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [inputValue, setInputValue] = useState(groupName);
  const { classes } = useStyles();

  const handleRename = async () => {
    await onRename(id, inputValue);
    setIsEditMode(false);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div className={classes.tag} data-testid={`product-group-${id}-testid`}>
      {
        isEditMode
          ? <TextField
            size="small"
            className={classes.textField}
            defaultValue={groupName}
            onChange={handleInputChange}
          />
          : <span>{groupName}</span>
      }
      {
        (onRename || onDelete) && (
          <div className={classes.icon}>
            {
              isEditMode
                ? <DoneIcon onClick={handleRename} className={classes.doneIcon}/>
                : <>
                  <CreateIcon onClick={() => setIsEditMode(true)} className={classes.createIcon}/>
                  <CancelIcon className={classes.cancelIcon} onClick={() => onDelete(id)}/>
                </>
            }
          </div>
        )
      }
    </div>
  );
};

ProductGroupBadge.propTypes = {
  id: PropTypes.number,
  groupName: PropTypes.string.isRequired,
  onRename: PropTypes.func,
  onDelete: PropTypes.func,
};
