import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardLabel: {
    color: theme.palette.text.itemHeadline,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  cardContainer: {
    flexGrow: 1,
    '& .MuiGrid-item:not(:first-child, :last-child)': {
      borderLeft: `1px solid ${theme.palette.grey[215]}`,
      borderRight: `1px solid ${theme.palette.grey[215]}`,
    },
  },
  cardItem: {
    padding: theme.spacing(2),
    '& > div > p:first-child': {
      fontWeight: 500,
    },
  },
}));
