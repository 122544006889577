import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import remove from 'lodash/remove';

import Carousel from 'react-material-ui-carousel';

import { useStyles } from './style';
import { useTranslator } from 'i18n';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import {
  Picture,
} from 'components';
import { ProductGroupBadge } from 'components/ProductGroupBadge/ProductGroupBadge';

export const ProductDetailsCard = ({
  onImage = () => {},
  product,
  isMobile,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const additionalImages = [...product.additionalImages];

  const images = additionalImages
  ? [...remove(additionalImages, (image) => image.isDefault), product.photo || product.imageUrl, ...additionalImages]
  : [product.photo || product.imageUrl];

  return (
      <>
        <Grid item className={classes.root} xs={12} md={8}>
          <Typography className={classes.cardLabel} variant="body2">{t.translate('Product details')}</Typography>
          <Paper className={classes.cardContainer}>
            <Grid container>
              {isMobile ? (
                <>
                  <Grid item className={classes.cardItem}>
                    <Grid container>
                      <Carousel
                        autoPlay={false}
                        indicators
                        navButtonsAlwaysInvisible
                        sx={classes.carousel}
                      >
                        {
                          images.map((image, i) => (
                            <Picture
                              key={i}
                              image={image}
                              className={classes.photo}
                              onClick={() => onImage(images, product.additionalImages)}
                            />
                          ))
                        }
                      </Carousel>
                      <Grid item>
                        <Typography variant="body2" style={{ fontWeight: 500, wordBreak: 'break-all' }}>{product.details[0]?.name || '—'}</Typography>
                        <Typography variant="body2">{product.manufacturerName}&nbsp;—&nbsp;{product.type ? `${product.type.code} — ${t.translate(`productType:${product.type.code}`)}` : ''}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <Typography variant="body2">{t.translate('Product-ID')}</Typography>
                      <Typography variant="body2">{product.id}</Typography>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <Typography variant="body2">EAN</Typography>
                      <Typography variant="body2">{product.ean || '—'}</Typography>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <Typography variant="body2">ALZURA ID</Typography>
                      <Typography variant="body2">{product.alzuraId || '—'}</Typography>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <Typography variant="body2">{t.translate('Manufacturer number (HSN)')}</Typography>
                      <Typography variant="body2">{product.manufacturerNumber || '—'}</Typography>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Carousel
                    autoPlay={false}
                    indicators
                    navButtonsAlwaysInvisible
                    className={classes.carousel}
                  >
                    {
                      images.map((image, i) => (
                        <Picture
                          key={i}
                          image={image}
                          className={classes.photo}
                          onClick={() => onImage(images, product.additionalImages)}
                        />
                      ))
                    }
                  </Carousel>
                  <Grid item className={classes.cardItem}>
                    <Typography variant="body2" style={{ fontWeight: 500, wordBreak: 'break-all' }}>{product.details[0]?.name || '—'}</Typography>
                    <Typography variant="body2">{product.manufacturerName}&nbsp;—&nbsp;{product.type ? `${product.type.code} — ${t.translate(`productType:${product.type.code}`)}` : ''}</Typography>
                    <Grid container justifyContent="space-between">
                      <Typography variant="body2">{t.translate('Product-ID')}</Typography>
                      <Typography variant="body2">{product.id || '—'}</Typography>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <Typography variant="body2">EAN</Typography>
                      <Typography variant="body2">{product.ean || '—'}</Typography>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <Typography variant="body2">ALZURA ID</Typography>
                      <Typography variant="body2">{product.alzuraId || '—'}</Typography>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <Typography variant="body2">{t.translate('Manufacturer number (HSN)')}</Typography>
                      <Typography variant="body2">{product.manufacturerNumber || '—'}</Typography>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <Typography variant="body2">{t.translate('Manufacturer ID')}</Typography>
                      <Typography variant="body2">{product.manufacturerId || '—'}</Typography>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <Typography variant="body2">{t.translate('Product groups')}</Typography>
                      <Grid className={classes.badgeContainer}>
                        {product.groups.length ? product.groups.map(g => <ProductGroupBadge key={`badge-${g.id}`} groupName={g.name} id={g.id}/>) : '—'}
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
        </Grid>
        <Grid item className={classes.root} xs={12} md={4}>
          <Typography className={classes.cardLabel} variant="body2">{t.translate('Product dimensions')}</Typography>
          <Paper className={classes.cardContainer}>
            <Grid container>
              <Grid item className={classes.cardItem}>
                <Grid container justifyContent="space-between">
                  <Typography variant="body2">{t.translate('Width')}&nbsp;</Typography>
                  <Typography variant="body2">
                    {product.dimensions.length ? `${numeral(product.dimensions?.[0].width).format('0.00')} cm` : '—'}
                  </Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Typography variant="body2">{t.translate('Height')}&nbsp;</Typography>
                  <Typography variant="body2">
                    {product.dimensions.length ? `${numeral(product.dimensions?.[0].height).format('0.00')} cm` : '—'}
                  </Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Typography variant="body2">{t.translate('Length')}&nbsp;</Typography>
                  <Typography variant="body2">
                    {product.dimensions.length ? `${numeral(product.dimensions?.[0].length).format('0.00')} cm` : '—'}
                  </Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Typography variant="body2">{t.translate('Volume')}&nbsp;</Typography>
                  <Typography variant="body2">
                    {product.dimensions.length ? <>{numeral(product.dimensions?.[0].volume / 1e6).format('0.000000')} m<sup>3</sup></> : '—'}
                  </Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Typography variant="body2">{t.translate('Weight')}&nbsp;</Typography>
                  <Typography variant="body2">
                    {product.dimensions.length ? `${numeral(product.dimensions?.[0].weight).format('0.00')} kg` : '—'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {product.details[0]?.description && (
          <Grid item className={classes.root} xs={12} md={12}>
            <Typography className={classes.cardLabel} variant="body2">{t.translate('Product description')}</Typography>
            <Paper className={classes.cardContainer}>
              <Grid container className={classes.cardItem} justifyContent="space-between" alignItems="center">
                <Typography variant="body2" className={classes.descriptionContainer} >{product.details[0].description}</Typography>
              </Grid>
            </Paper>
          </Grid>
        )}
      </>
  );
};

ProductDetailsCard.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  onImage: PropTypes.func.isRequired,
  product: PropTypes.shape({
    manufacturerNumber: PropTypes.string,
    manufacturerName: PropTypes.string,
    manufacturerId: PropTypes.string,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    stock: PropTypes.number.isRequired,
    photo: PropTypes.object,
    type: PropTypes.shape({
      code: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
    name: PropTypes.string,
    ean: PropTypes.string,
    volume: PropTypes.number,
    length: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    alzuraId: PropTypes.string,
    additionalImages: PropTypes.array,
    id: PropTypes.number.isRequired,
  }).isRequired,
};
