import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Toolbar from '@mui/material/Toolbar';

const useStyles = makeStyles()(theme => ({
  root: {
    height: theme.spacing(8.75),
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(7),
    },
  },
}));

export const AppHeaderPrimaryToolbar = ({ children }) => {
  const { classes } = useStyles();

  return (
    <Toolbar className={classes.root}>
      {children}
    </Toolbar>
  );
};
