import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';

import { Column } from 'layouts';
import { ProductDetailsCard } from '../../common';

import { MarketplaceSuppliersTable } from './MarketplaceSuppliersTable/MarketplaceSuppliersTable';
import { StoragesTable } from './StoragesTable/StoragesTable';
import { ProductChangesHistoryTable } from './ProductChangesHistoryTable/ProductChangesHistoryTable';

export const DesktopView = ({
  onStorageReassignStock,
  onStorageProductEdit,
  onImageFullView,
  onProductPricesEdit,
  canEditProduct,
  marketplaces,
  isMobile,
}) => {
  const product = useSelector(({ products }) => products.storages.product);
  const prices = useSelector(({ products }) => products.prices);

  if (product) {
    return (
      <>
        <Grid container style={{ margin: '0 24px 0 8px', flexWrap: 'wrap', width: 'auto' }} spacing={2}>
          <ProductDetailsCard
            onImage={onImageFullView}
            product={product}
            isMobile={isMobile}
          />
          <MarketplaceSuppliersTable
            onStorageReassignStock={onStorageReassignStock}
            onStorageProductEdit={onStorageProductEdit}
            onProductPricesEdit={onProductPricesEdit}
            canEditProduct={canEditProduct}
            marketplaces={marketplaces}
            product={product}
            prices={prices}
          />
          <StoragesTable
            onStorageReassignStock={onStorageReassignStock}
            onStorageProductEdit={onStorageProductEdit}
            product={product}
          />
          <ProductChangesHistoryTable product={product}/>
        </Grid>
      </>
    );
  } else {
    return <Column p={3} pb={8} />;
  }
};

DesktopView.propTypes = {
  onStorageReassignStock: PropTypes.func.isRequired,
  onStorageProductEdit: PropTypes.func.isRequired,
  onImageFullView: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
