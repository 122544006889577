import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Button, FormControl, Grid } from '@mui/material';
import { CircularLoader, FormControlError } from '../../../components';
import { useTranslator } from '../../../i18n';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Input from '@mui/material/Input';
import toaster from 'services/toaster';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { fetchProductsOptions, modifyProductsOptions } from 'api/options';

const useStyles = makeStyles()(theme => ({
  actionsItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  hint: {
    maxWidth: 320,
  },
}));

export const LowStockUpdate = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    lowStockValue: 0,
  });

  const t = useTranslator();
  const { classes } = useStyles();

  useEffect(() => {
    fetchProductsOptions()
      .then(({ lowStockUpdateValue }) => {
        setInitialValues({
          lowStockValue: lowStockUpdateValue,
        });
        setIsLoading(false);
    });
  }, []);

  const validationSchema = Yup
    .object()
    .shape({
      lowStockValue: Yup.number().positive().integer().label(t.translate('Low stock')).typeError(t.translate('Low stock must be a number')),
    })
  ;

  const onSubmit = async () => {
    try {
      await modifyProductsOptions({ lowStockUpdateValue: values.lowStockValue });
      toaster.success(t.translate('Low stock has been updated successfully'));
    }catch (err) {
      toaster.error(Object.values(err.collection)[0][0]);
    }
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    enableReinitialize: true,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  const [anchorEl, setAnchorEl] = useState({ info: null });

  const handlePopoverOpen = (name, event) => {
    event.persist();
    setAnchorEl(prevState => ({
      ...prevState,
      [name]: event.currentTarget,
    }));
  };

  const handlePopoverClose = (name) => {
    setAnchorEl(prevState => ({
      ...prevState,
      [name]: null,
    }));
  };

  if (isLoading) return <CircularLoader/>;

  return (
    <FormControl error={!!errors.lowStockValue}>
      <>
        <InputLabel shrink htmlFor={'low-stock-input'}>{t.translate('Low stock')}</InputLabel>
        <Input
          id={'low-stock-input'}
          name={'lowStockValue'}
          value={values.lowStockValue}
          onChange={handleChange}
          endAdornment={
            <Grid item>
              <IconButton
                aria-label="hint"
                aria-owns={Boolean(anchorEl.info) ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={e => handlePopoverOpen('info', e)}
                onMouseLeave={() => handlePopoverClose('info')}
                onClick={e => handlePopoverOpen('info', e)}
                size="large">
                <InfoIcon />
              </IconButton>
              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{ paper: classes.paper }}
                open={Boolean(anchorEl.info)}
                anchorEl={anchorEl.info}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => handlePopoverClose('info')}
                disableRestoreFocus
              >
                <Typography variant="body2" className={classes.hint}>
                  {t.translate('lowStock:info')}
                </Typography>
              </Popover>
            </Grid>
          }
        />
        {!!errors.lowStockValue && <FormControlError error={errors.lowStockValue} />}
      </>
      <Grid item className={classes.actionsItem}>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          onClick={handleSubmit}
        >
          {t.translate('Save')}
        </Button>
      </Grid>
    </FormControl>
  );
};
