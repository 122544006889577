import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import { Alert, AlertTitle } from '@mui/material';

import { Column } from 'layouts';
import { CircularLoader, ItemHeadline } from 'components';
import { CustomerCard } from './CustomerCard/CustomerCard';
import { ProductItem } from './ProductItem/ProductItem';
import { PackageItem } from './PackageItem/PackageItem';
import { NextPackageItem } from './NextPackageItem/NextPackageItem';

const normalise = (value, max) => ((value - 0) * 100) / (max - 0);

export const MobileView = ({
  onPickupSheetProductEdit,
  onProductLocationShow,
  onProductDecreaseScanned,
  onPackPickupSheet,
  canPackPickupSheet,
  canEditPickupSheet,
  canUnpackPickupSheet,
  onUnpackPackage,
  pickupSheet,
  isLoading,
}) => {
  const t = useTranslator();

  return (
    <Column p={1} pt={2}>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <Grid container direction="column">
          {pickupSheet && (
            <>
              <Grid container style={{ flexWrap: 'wrap' }}>
                <Grid item xs={12} container direction="column">
                  <ItemHeadline text={t.translate('PickupSheet details')} />
                  <CustomerCard pickupSheet={pickupSheet} />
                </Grid>
                {pickupSheet?.orders?.[0].shippingNeutral && (
                  <Grid item xs={12} container direction="column">
                    <Alert variant="filled" severity="warning">
                      <AlertTitle>{t.translate('Neutral Shipping')}</AlertTitle>
                      {t.translate('This pickup sheet will be sent with neutral packaging')}
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12} container direction="column">
                  <ItemHeadline text={t.translate('PickupSheet products')} badgeCounter={pickupSheet.pickupSheetProducts.length}  style={{ marginBottom: 0 }}/>
                  {pickupSheet.pickupSheetProducts?.map(pickupSheetProduct => (
                    <ProductItem
                      key={pickupSheetProduct.id}
                      pickupSheet={pickupSheet}
                      pickupSheetProduct={pickupSheetProduct}
                      canEditPickupSheet={canEditPickupSheet}
                      onPickupSheetProductEdit={onPickupSheetProductEdit}
                      onProductLocationShow={onProductLocationShow}
                      onProductDecreaseScanned={onProductDecreaseScanned}
                    />
                  ))}
                  </Grid>
                </Grid>
                {pickupSheet.status === 'open' && (
                  <Grid item xs={12} container direction="column">
                    <NextPackageItem
                      onPackPickupSheet={onPackPickupSheet}
                      onProductDecreaseScanned={onProductDecreaseScanned}
                      canEditPickupSheet={canEditPickupSheet}
                      canPackPickupSheet={canPackPickupSheet}
                      pickupSheet={pickupSheet}
                    />
                  </Grid>
                )}
                {pickupSheet.pickupSheetPackages.reverse().map((pickupSheetPackage, index) => (
                  <Grid item xs={12} container direction="column" key={index}>
                    <ItemHeadline
                      text=''
                      secondaryText={`${t.translate('Weight')} ${pickupSheetPackage.weight.toFixed(2) || 0} kg`}
                      secondaryComponent={
                        <LinearProgress
                          variant="determinate"
                          value={normalise(Number(pickupSheetPackage.weight) || 0, pickupSheet.maximumPackageWeight)}
                          style={{ width: '20%' }}
                        />
                      }
                    />
                    <PackageItem
                      packageNumber={`${t.translate('Package')} ${pickupSheet.pickupSheetPackages.length - index}`}
                      pickupSheetProducts={pickupSheet.pickupSheetProducts}
                      pickupSheetPackage={pickupSheetPackage}
                      lastItemPacked={index === pickupSheet.pickupSheetPackages.length - 1 && pickupSheet.status !== 'open'}
                      canUnpackPickupSheet={canUnpackPickupSheet}
                      onUnpackPackage={onUnpackPackage}
                    />
                  </Grid>
                ))}
              </>
            )}
          </Grid>
      )}
    </Column>
  );
};

MobileView.propTypes = {
  canUnpackPickupSheet: PropTypes.bool.isRequired,
  onUnpackPackage: PropTypes.func.isRequired,
  onPickupSheetProductEdit: PropTypes.func.isRequired,
  onProductLocationShow: PropTypes.func.isRequired,
  onProductDecreaseScanned: PropTypes.func.isRequired,
  canEditPickupSheet: PropTypes.bool.isRequired,
  pickupSheet: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
};
