import printJs from 'print-js';
import pickupSheetsApi from 'api/pickup-sheets';
import {
  setActivePickupSheet,
  setPickupSheets,
} from 'store/actions/pickup-sheets';
import {
  startLoading,
  stopLoading,
} from 'store/actions/app';
import { makeSourceLink } from 'services/files';

export const printPickupSheetDocument = async pickupSheet => {
  try {
    const { filePath } = await pickupSheetsApi.printOne(pickupSheet.id);

    await printJs(makeSourceLink(filePath));
  } catch (err) {}
};

export const fetchPickupSheets = params => async dispatch => {
  try {
    const pickupSheets = await pickupSheetsApi.fetchMany(params);
    dispatch(setPickupSheets(pickupSheets));
  } catch (err) {}
};

export const fetchPickupSheet = pickupSheetId => async dispatch => {
  try {
    dispatch(startLoading('pickupSheets.details.pickupSheet'));
    const pickupSheet = await pickupSheetsApi.fetchOne(pickupSheetId);
    dispatch(setActivePickupSheet(pickupSheet));
    dispatch(stopLoading('pickupSheets.details.pickupSheet'));
  } catch (err) {
    dispatch(stopLoading('pickupSheets.details.pickupSheet'));
  }
};
