import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PublishIcon from '@mui/icons-material/Publish';
import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';

import { useTranslator } from 'i18n';
import { useRoute } from 'hooks/route';
import { useList } from 'hooks';

import { QrCodeScanner } from '../../QrCodeScanner/QrCodeScanner';
import { selectActionPermission } from '../../../../../store/selectors/account';
import {
  AppHeaderPrimaryToolbar,
  AppHeaderRightBox,
  SuppliersFilter,
  AppHeaderTitle,
  AppHeader,
  AlzuraSpriteIcon,
  ViewSwitch,
} from 'components';
import { EanCodeScanDialog } from 'dialogs';

import Grid from '@mui/material/Grid';

import tables from 'config/tables';
import { OUTDATED_PRODUCTS_LABEL } from 'pages/products/Overview/config';

export const Header = ({
  productTypes,
  productGroups,
  onImportProductsClick,
  onExportProductsClick,
  onCreateProduct,
  canExportProducts,
  allowedOptions,
  routeTitle,
  activeOption,
  screen,
}) => {
  const canImportProducts = useSelector(selectActionPermission('products', 'import'));
  const canCreateProduct = useSelector(selectActionPermission('products', 'create'));

  const t = useTranslator();
  const route = useRoute();
  const list = useList(tables.products.products);

  const [scannerVisible, setScannerVisible] = useState(false);

  const applyEanCode = eanCode => route.push({ search: { ...route.search, q: eanCode } });

  return (
    <>
      <EanCodeScanDialog
        open={scannerVisible}
        onClose={() => setScannerVisible(false)}
        onDecode={applyEanCode}
      />

      <AppHeader>
        <AppHeaderPrimaryToolbar>
          <AppHeaderTitle>{t.translate('Products')}</AppHeaderTitle>
          <AppHeaderRightBox>
            {routeTitle !== OUTDATED_PRODUCTS_LABEL ? (
              <Grid container spacing={2} alignItems="center">
                {canCreateProduct && (
                  <Grid item>
                    <Button
                      startIcon={<AlzuraSpriteIcon id="assignmentAdd"/>}
                      onClick={onCreateProduct}
                      color="inherit"
                    >
                      {t.translate('Create product')}
                    </Button>
                  </Grid>
                )}
                {canImportProducts && (
                  <Grid item>
                    <Button
                      startIcon={<PublishIcon />}
                      onClick={onImportProductsClick}
                      color="inherit"
                    >
                      {t.translate('Import')}
                    </Button>
                  </Grid>
                )}
                {canExportProducts && (
                  <Grid item>
                    <Button
                      startIcon={<GetAppIcon />}
                      onClick={onExportProductsClick}
                      color="inherit"
                    >
                      {t.translate('Export')}
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <QrCodeScanner />
                </Grid>
                <Grid item>
                  <SuppliersFilter
                    tables={list}
                    type="supplierIds"
                    productTypes={productTypes}
                    productGroups={productGroups}
                    isFilterByDot={true}
                  />
                </Grid>
              </Grid>
            ) : null}
          </AppHeaderRightBox>
        </AppHeaderPrimaryToolbar>
        {allowedOptions.length > 1 ? <ViewSwitch options={allowedOptions} isMobile={screen.isMobile} activeOption={activeOption}/> : null}
      </AppHeader>
    </>
  );
};

Header.propTypes = {
  productTypes: PropTypes.array,
  onImportProductsClick: PropTypes.func.isRequired,
  onExportProductsClick: PropTypes.func.isRequired,
  canExportProducts: PropTypes.bool,
  onCreateProduct: PropTypes.func.isRequired,
};
