import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';

import { HistoryTable } from './HistoryTable/HistoryTable';
import { Column } from 'layouts';
import {
  FullScreenDialogHeader,
  FullScreenDialog,
} from 'components';

export const DesktopStockHistoryDialog = ({
  onClose,
  operations,
  ...props
}) => {
  const t = useTranslator();

  return (
    <FullScreenDialog {...props} onClose={onClose}>
      <FullScreenDialogHeader title={t.translate('Stock history')} onClose={onClose} />
      <Column filled>
        <HistoryTable operations={operations} />
      </Column>
    </FullScreenDialog>
  );
};

DesktopStockHistoryDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  operations: PropTypes.any,
};
