import React from 'react';

import PropTypes from 'prop-types';
import { useStyles } from './ProductItem.jsx';
import { useTranslator } from 'i18n';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import {
  CardFeature,
  Color,
} from 'components';

export const ProductItem = ({
  storageProduct,
}) => {
  const { classes } = useStyles();
  const t = useTranslator();

  return (
    <Grid item>
      <Paper className={classes.paper}>
        <Grid container direction="column" spacing={1}>
          <Grid item container className={classes.headline}>
            <Grid item xs>
              <Typography variant="h6">#{storageProduct.product.id}</Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs>
              <Grid container direction="column" className={classes.summaryGrid}>
                <CardFeature
                  label={'Alzura ID'}
                  value={storageProduct.product.alzuraId || '—'}
                />
                <CardFeature
                  label={'EAN'}
                  value={storageProduct.product.ean || '—'}
                />
                <CardFeature
                  label={t.translate('Name')}
                  value={storageProduct.product.details[0]?.name || '—'}
                />
                <CardFeature
                  label={t.translate('Stock')}
                  value={storageProduct.stock || '—'}
                />
                <CardFeature
                  label={t.translate('Color')}
                  value={<Color color={storageProduct.color || 'black'}  />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

ProductItem.propTypes = {
  storageProduct: PropTypes.shape({
    totalStock: PropTypes.number.isRequired,
    storage: PropTypes.shape({
      boxNumber: PropTypes.string,
      boxColor: PropTypes.string,
      building: PropTypes.string.isRequired,
      shelf: PropTypes.string,
      level: PropTypes.string,
      room: PropTypes.string,
      row: PropTypes.string,
      id: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};
