import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import isObject from 'lodash/isObject';
import debounce from 'lodash/debounce';

import { useCancelSource, useLoading } from 'hooks';
import { QrCodeControl } from 'components';
import { fetchStorage } from 'api/storages';

export const StorageQrCodeControl = ({
  onChange,
  onLoad,
  criteria,
  ...props
}) => {
  const cancelSource = useCancelSource();
  const loading = useLoading();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadStorage = useCallback(debounce(async (value) => {
    const cancelToken = cancelSource.issueToken();
    try {
      const storage = await fetchStorage(value, { cancelToken, params: criteria });
      onLoad(storage);
      cancelSource.clear()();
    } catch (err) {
      cancelSource.clear()(err);
      if (!axios.isCancel(err)) {
        onLoad(null);
      }
    }
  }, 150), [criteria]);

  const handleValue = (e) => {
    const value = isObject(e) ? e.target?.value : e.replace(/(\r\n|\n|\r)/gm, '');
    onChange(value);
    if (!value.trim()) {
      cancelSource.cancel()('Empty query.');
      onLoad(null);
      return;
    }

    loadStorage(value);
  };

  return (
    <QrCodeControl
      {...props}
      loading={loading.progress}
      onChange={handleValue}
      onScan={handleValue}
    />
  );
};

StorageQrCodeControl.propTypes = {
  onChange: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  criteria: PropTypes.object,
};
