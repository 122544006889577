import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslator } from 'i18n';
import {
  useTableSortableHeadCell,
  useList,
} from 'hooks';

import TableContainer from '@mui/material/TableContainer';
import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import InfoIcon from '@mui/icons-material/Info';
import Table from '@mui/material/Table';
import Link from '@mui/material/Link';

import {
  StockOperationReason,
  StockOperationOrder,
  Pagination,
  Color,
  DotChip,
} from 'components';

import groupBy from 'lodash/groupBy';
import tables from 'config/tables';

const useStyles = makeStyles()(theme => ({
  cellPadding: {
    '& .MuiTableRow-root .MuiTableCell-root:first-of-type': {
      paddingLeft: theme.spacing(3),
    },
    '& .MuiTableCell-alignRight': {
      paddingRight: theme.spacing(3),
    },
  },
  dateRow: {
    backgroundColor: theme.palette.backgrounds.secondary,
  },
}));

export const OperationsTable = ({ onOperationDetail }) => {
  const list = useList(tables.products.history);
  const { classes } = useStyles();
  const t = useTranslator();
  const history = useHistory();

  const openSupplier = id => history.push(`/edit-supplier/${id}`);

  const totalEntities = useSelector(({ products }) => products.history.meta.totalEntities);
  const operations = useSelector(({ products }) => products.history.data);

  const SortableCell = useTableSortableHeadCell({
    changeSorting: list.changeSorting,
    sorting: list.sorting,
  });

  const operationGroups = groupBy(operations, ({ createdAt }) => t.formatDate(createdAt));

  return <>
    <TableContainer>
      <Table className={classes.cellPadding}>
        <TableHead>
          <TableRow>
            <SortableCell name="createdAt">{t.translate('Time')}</SortableCell>
            <SortableCell name="userName">{t.translate('User')}</SortableCell>
            <SortableCell name="supplierName">{t.translate('Supplier')}</SortableCell>
            <SortableCell name="addedStock">{t.translate('Stock')}</SortableCell>
            <TableCell>{t.translate('Purchase price')}</TableCell>
            <TableCell>{t.translate('table-column: Reason')}</TableCell>
            <TableCell name="color">{t.translate('Color')}</TableCell>
            <TableCell>{t.translate('Reference')}</TableCell>
            <TableCell>{t.translate('Return reason')}</TableCell>
            <TableCell>{t.translate('DOT')}</TableCell>
            <TableCell>{t.translate('Comment')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(operationGroups).map(([date, group]) => (
            <React.Fragment key={date}>
              <TableRow className={classes.dateRow}>
                <TableCell colSpan={11} align="center">
                  <b>{date}</b>
                </TableCell>
              </TableRow>
              {group.map((operation, index) => (
                <TableRow key={index} hover>
                  <TableCell>{t.formatTime(operation.createdAt)}</TableCell>
                  <TableCell>{operation.user?.name}</TableCell>
                  <TableCell>
                    <Link onClick={() => openSupplier(operation.supplier.id)}>{operation.supplier?.number}</Link>
                  </TableCell>
                  <TableCell>{operation.addedStock > 0 ? '+' : ''}{operation.addedStock}</TableCell>
                  <TableCell>{t.formatPrice(operation.purchasePrice)}&nbsp;&euro;</TableCell>
                  <TableCell>
                    <StockOperationReason operation={operation} />
                  </TableCell>
                  <TableCell>
                    <Color box color={operation.color || 'black'} />
                  </TableCell>
                  <TableCell>
                    <StockOperationOrder operation={operation} />
                  </TableCell>
                  <TableCell align="center">{operation.returnReason ?? '-'}</TableCell>
                  <TableCell>
                    <DotChip manufacturingDate={operation.manufacturingDate}/>
                  </TableCell>
                  <TableCell align="center">
                    {operation.comment && (
                      <IconButton
                        color="secondary"
                        variant="contained"
                        onClick={() => onOperationDetail(operation)}
                        aria-label="detail product storage operation"
                        size="large">
                        <InfoIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Pagination {...list.paginationProps} count={totalEntities} />
  </>;
};

OperationsTable.propTypes = {
  onOperationDetail: PropTypes.func.isRequired,
};
