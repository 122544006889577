import api from '.';

export const fetchProductGroup = (id) => api.get(`/product-groups/${id}`);
export const fetchProductGroups = () => api.get('/product-groups');
export const createProductGroup = (name) => api.post('/product-groups', { name });
export const deleteProductGroup = (id) => api.delete(`/product-groups/${id}`);
export const modifyProductGroup = (id, name) => api.put(`/product-groups/${id}`, { name });

export default {
  fetchProductGroup,
  fetchProductGroups,
  createProductGroup,
  deleteProductGroup,
  modifyProductGroup,
};

