import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.grey['100'],
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    display: 'flex',
    height: theme.spacing(6),
    zIndex: 1,
    '& .MuiSvgIcon-root': {
      flexShrink: 0,
      margin: theme.spacing(1.5, 2),
      '&:first-of-type': {
        marginLeft: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          marginLeft: theme.spacing(2),
        },
      },
      '&:last-child': {
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          marginRight: theme.spacing(2),
        },
      },
    },
  },
  menu: {
    '& .MuiPopover-paper': {
      marginTop: theme.spacing(7),
      right: theme.spacing(2),
    },
    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(3),
    },
  },
}));
