import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useList } from '../../../../hooks';
import tables from '../../../../config/tables';
import { fetchChangesHistory } from '../../../../services/changes-history';
import { Grid } from '@mui/material';
import { ChangesHistoryTable } from '../../../changes-history/DesktopView/ChangesHistoryTable/ChangesHistoryTable';
import { makeStyles } from 'tss-react/mui';
import { styleTableRow } from '../../../../theme/mixins/table';

const useStyles = makeStyles()(theme => styleTableRow(theme, {
  containerWrap: {
    flexWrap: 'wrap',
    flexBasis: 'auto',
    alignItems: 'flex-start',
  },
}));

export const ChangesHistorySettings = ({ companyId }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const list = useList(tables.changesHistory.changesHistory);

  const fetchData = useCallback(() => dispatch(fetchChangesHistory({
    ...list.compile(),
    reference: `company:${companyId}`,
    isStrictSearch: true,
  })), [dispatch, list, companyId]);

  useEffect(() => void fetchData(), [fetchData]);

  return (
    <Grid container item spacing={2} style={{ padding: 20 }} className={classes.containerWrap}>
      <Grid item xs={12}>
        <ChangesHistoryTable isDetailsTable={true}/>
      </Grid>
    </Grid>
  );
};
