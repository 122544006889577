import api from '.';

export const fetchAllStatistics = () => api.get('/statistics');

export const fetchProductFunnelStatistics = () => api.get('/statistics/product-funnel');

export default {
  fetchAll: fetchAllStatistics,
  fetchProductFunnel: fetchProductFunnelStatistics,
};
