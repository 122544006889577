import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';


import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';

import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles()(theme => ({
  accordion: {
    '&.MuiAccordion-root.Mui-expanded': {
      marginTop: 0,
    },
    '& .MuiAccordionSummary-root': {
      minHeight: `${theme.spacing(6)} !important`,
      padding: theme.spacing(0, 2),
    },
    '& .MuiAccordionSummary-content': {
      marginBottom: `${theme.spacing(1.5)} !important`,
      marginTop: `${theme.spacing(1.5)} !important`,
    },
  },
  accordionDetails: {
    padding: theme.spacing(0, 2, 1),
  },
}));

export const Warehouses = ({ onAvailabilityChange }) => {
  const { classes } = useStyles();
  const t = useTranslator();

  const warehouses = useSelector(({ users }) => users.details.warehouses);
  const user = useSelector(({ users }) => users.details.user);

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{t.translate('Warehouses')}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Grid container direction="column" spacing={1}>
          {warehouses.map(warehouse => (
            <Grid item key={warehouse.id}>
              <FormControlLabel
                control={
                  <Switch
                    checked={user.allowedWarehouseIds.includes(warehouse.id)}
                    onChange={onAvailabilityChange(warehouse.id)}
                  />
                }
                label={warehouse.name}
              />
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

Warehouses.propTypes = {
  onAvailabilityChange: PropTypes.func.isRequired,
};
