import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import numeral from 'numeral';

import { useTranslator } from 'i18n';
import {
  useTableSortableHeadCell,
  useList,
} from 'hooks';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TableContainer from '@mui/material/TableContainer';
import { makeStyles } from 'tss-react/mui';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import CancelIcon from '@mui/icons-material/Cancel';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import { ApprovingButton } from '../../../common';
import { CollapsedRow } from './CollapsedRow/CollapsedRow';

import {
  CircularLoader,
  Pagination,
  ActionButton,
  CollapseTableRow,
  OrderStatusBadge,
} from 'components';

import { selectActionPermission } from 'store/selectors/account';
import { OrderStatuses } from 'consts';

import {
  selectOrdersLoading,
  selectOrdersData,
} from 'store/selectors/orders';
import tables from 'config/tables';

const useStyles = makeStyles()(theme => ({
  actionButtons: {
    whiteSpace: 'nowrap',
  },
  button: {
    color: theme.palette.primary.l900,
  },
  countryCell: {
    textTransform: 'uppercase',
  },
  cellPadding: {
    '& .MuiTableRow-root .MuiTableCell-root:first-of-type': {
      paddingLeft: theme.spacing(3),
    },
    '& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:last-child': {
      paddingRight: theme.spacing(2),
    },
  },
}));

export const OrdersTable = ({
  onStockEvaluation,
  onMarkReturnWithoutStock,
  onApprove,
  onCancel,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const history = useHistory();
  const list = useList(tables.orders.orders);

  const canApprove = useSelector(selectActionPermission('orders', 'approve'));
  const canPerformOrderActions = useSelector(selectActionPermission('orders', 'actions'));

  const openOrder = order => history.push(`/orders/${order.id}`);
  const openCustomer = id => history.push(`/customers/${id}`);

  const isLoading = useSelector(selectOrdersLoading);
  const totalEntities = useSelector(({ orders }) => orders.meta.totalEntities);
  const orders = useSelector(selectOrdersData);

  const SortableCell = useTableSortableHeadCell({
    changeSorting: list.changeSorting,
    sorting: list.sorting,
  });

  if (isLoading) {
    return <CircularLoader />;
  } else {
    return (
      <Paper>
        <TableContainer>
          <Table className={classes.cellPadding}>
            <TableHead>
              <TableRow>
                <TableCell />
                <SortableCell name="date">{t.translate('Date')}</SortableCell>
                <SortableCell name="number">{t.translate('Order no')}</SortableCell>
                <SortableCell name="country">{t.translate('Country')}</SortableCell>
                <SortableCell name="customerNumber">{t.translate('Customer no')}</SortableCell>
                <SortableCell name="customerCompany">{t.translate('Customer company')}</SortableCell>
                <TableCell>{t.translate('Status')}</TableCell>
                <TableCell>{t.translate('Project')}</TableCell>
                <SortableCell align="right" name="netTotalPrice">{t.translate('Order Total (net)')}</SortableCell>
                <TableCell align="right">{t.translate('Actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order, index) => (
                <CollapseTableRow
                  key={index}
                  hover
                  onRowClick={() => openOrder(order)}
                  colSpan={10}
                  isOpenCollapsedRow={order.positions.some(position => position?.orderPositionReturns.length &&
                    position.orderPositionReturns.some(positionReturn => positionReturn.evaluatedQuantity !== positionReturn.returnedQuantity)
                  )}
                  collapsedRow={<CollapsedRow
                    order={order}
                    onStockEvaluation={onStockEvaluation}
                    onMarkReturnWithoutStock={onMarkReturnWithoutStock}
                  />}
                >
                  <TableCell>{t.formatDate(order.date)} {t.formatTime(order.date)}</TableCell>
                  <TableCell>{order.number}</TableCell>
                  <TableCell className={classes.countryCell}>{order.country}</TableCell>
                  <TableCell>
                    <Link onClick={() => openCustomer(order.customer.id)}>{order.customer.number}</Link>
                  </TableCell>
                  <TableCell>{order.customer.company}</TableCell>
                  <TableCell><OrderStatusBadge order={order}/></TableCell>
                  <TableCell>{order.supplier.mondoEmail.name}</TableCell>
                  <TableCell align="right">{numeral(Number(order.netTotalPrice)).format('0,0.00')} &euro;</TableCell>
                  <TableCell align="right" className={classes.actionButtons}>
                    {canApprove && order.inReview && (
                      <ApprovingButton
                        order={order}
                        onApprove={onApprove}
                        className={classes.button}
                      />
                    )}
                    {((order.statusCode === OrderStatuses.QUEUE || order.statusCode === OrderStatuses.CLOSED) && canPerformOrderActions) && !order.orderedAtSupplier && (
                      <ActionButton
                        aria-label={t.translate('Cancel the order')}
                        tooltip={t.translate('Cancel')}
                        icon={<CancelIcon />}
                        onClick={() => onCancel(order)}
                        className={classes.button}
                      />
                    )}
                    <ActionButton
                      aria-label={t.translate('Open the order')}
                      tooltip={t.translate('verb:Open')}
                      icon={<ChevronRightIcon />}
                      onClick={() => openOrder(order)}
                    />
                  </TableCell>
                </CollapseTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination {...list.paginationProps} count={totalEntities}/>
      </Paper>
    );
  };
};

OrdersTable.propTypes = {
  onApprove: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
