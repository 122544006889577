import React, { useState } from 'react';
import { FixedSizeList } from 'react-window';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslator } from 'i18n';
import {
  TableContainer,
  TableBody,
  TableHead,
  Paper,
  Grid,
  TableRow,
  Table,
  TableCell,
  Button,
  Link,
} from '@mui/material';
import {
  BadgeFilter,
  EanCodeControl,
  InputControl,
} from 'components';
import groupBy from 'lodash/groupBy';
import { useRoute } from 'hooks';
import { ReplenishmentSuggestionsStatuses, SupplierHandlingTypes } from 'consts';
import { ReplenishmentSuggestionTableRow } from './ReplenishmentSuggestionTableRow/ReplenishmentSuggestionTableRow';
import { useStyles } from './styles';
import { selectActionPermission } from 'store/selectors/account';

const makeId = postfix => `replenishment-suggestions-table--${postfix}`;

export const ReplenishmentSuggestionsTable = ({
  selectedSuggestions,
  setSelectedSuggestions,
  onOpenStockDialog,
  onPausedSuggestionsClick,
  scrollWidth,
  list,
}) => {
  const { classes } = useStyles();
  const t = useTranslator();
  const history = useHistory();
  const route = useRoute();

  const canManageReplenishmentSuggestions = useSelector(selectActionPermission('purchases', 'manageSuggestions'));

  const { data: replenishmentSuggestions } = useSelector(({ replenishmentSuggestions }) => replenishmentSuggestions.overview.replenishmentSuggestions);

  const [manufacturerNumber, setManufacturerNumber] = useState(route.search.suggestions?.manufacturerNumber);
  const [productEan, setProductEan] = useState(route.search.suggestions?.ean);
  const openProduct = id => history.push(`/products/${id}`);
  const openPurchase = id => history.push(`/purchases/${id}`);

  const cellProps = {
    component: 'div',
    className: classes.cell,
  };

  const cellHeaderProps = {
    component: 'div',
    className: classes.cellHeader,
  };

  const headerCenter = {
    justifyContent: 'center',
  };

  const groupedSuggestions = groupBy(replenishmentSuggestions, 'supplierId');

  const resetFilters = async () => {
    setManufacturerNumber('');
    setProductEan('');
    history.push('/replenishment-suggestions?status[]=OPEN');
  };

  const onToggle = (suggestion) => {
    const suggestionsList = selectedSuggestions.find(s => s.id === suggestion.id);

    if (suggestionsList) {
      setSelectedSuggestions(selectedSuggestions.filter(s => s.id !== suggestion.id));
    }
    else {
      selectedSuggestions.push({
        ...suggestion,
        newQuantity: suggestion.quantity,
        newPurchasePrice: t.formatPrice(suggestion.product.purchasePrices[0].purchasePrice),
      });
      setSelectedSuggestions([...selectedSuggestions]);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Grid className={classes.root}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          className={classes.container}
          spacing={1}
          style={{ flexWrap: 'wrap' }}
        >
          <Grid item xs={5}>
            <EanCodeControl
              id={makeId('product-ean')}
              name="productEan"
              label={t.translate('Product EAN code')}
              value={productEan}
              onChange={productEan => {
                setProductEan(productEan);
                list.changeFilter('ean', productEan);
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <InputControl
              id={makeId('manufacturer-number')}
              label={t.translate('Manufacturer number')}
              value={manufacturerNumber}
              onChange={({ target: { value } }) => {
                setManufacturerNumber(value);
                list.changeFilter('manufacturerNumber', value);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.resetFiltersButton}
              onClick={resetFilters}
            >
              {t.translate('Reset filters')}
            </Button>
          </Grid>
        </Grid>

        <Grid container className={classes.filtersContainer} alignItems="center" justifyContent="space-between">
          <Grid item xs={10}>
            <Grid container alignItems="center">
              <BadgeFilter
                filteredBy={'status'}
                list={list}
                filteredByValues={ReplenishmentSuggestionsStatuses}
                title={t.translate('Status')}
                prefixForTranslator={'replenishment-suggestion-status:'}
                defaultFilter={ReplenishmentSuggestionsStatuses.OPEN}
                isTransparentBg={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'right' }}>
            <Link onClick={onPausedSuggestionsClick}>
              {t.translate('Show paused suggestions')}
            </Link>
          </Grid>
        </Grid>

        <Grid container className={classes.handlingTypeFiltersContainer} alignItems="center" justifyContent="space-between">
          <Grid item xs={10}>
            <Grid container alignItems="center">
              <BadgeFilter
                filteredBy={'handlingType'}
                list={list}
                filteredByValues={SupplierHandlingTypes}
                title={'Filter by handling type'}
                prefixForTranslator={'supplierHandlingType:'}
                isTransparentBg={true}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="column">
          <TableContainer>
            <Table className={classes.table} component="div">
              <TableHead component="div">
                <TableRow component="div" className={classes.row} style={{ paddingRight: `${scrollWidth}px` }}>
                  <TableCell {...cellHeaderProps} />
                  <TableCell {...cellHeaderProps}>{t.translate('Product ID')}</TableCell>
                  <TableCell {...cellHeaderProps}>{t.translate('Alzura ID')}</TableCell>
                  <TableCell {...cellHeaderProps}>{t.translate('Manufacturer no.')}</TableCell>
                  <TableCell {...cellHeaderProps}>{t.translate('Manufacturer name')}</TableCell>
                  <TableCell {...cellHeaderProps}>EAN</TableCell>
                  <TableCell {...cellHeaderProps}>{t.translate('Product name')}</TableCell>
                  <TableCell {...cellHeaderProps} style={headerCenter}>{t.translate('Purchase price')}</TableCell>
                  <TableCell {...cellHeaderProps} style={headerCenter}>{t.translate('Quantity')}</TableCell>
                  <TableCell {...cellHeaderProps} style={headerCenter}>{t.translate('Status')}</TableCell>
                  <TableCell {...cellHeaderProps}>{t.translate('Forecast')}</TableCell>
                  <TableCell {...cellHeaderProps} />
                </TableRow>
              </TableHead>
              {!Boolean(Object.keys(groupedSuggestions).length) && (
                <TableBody component="div">
                  <TableRow component="div">
                    <TableCell component="div" colSpan={12} align="center">
                      {t.translate('No results')}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {Object.keys(groupedSuggestions).map((supplierId, index) => {
                const supplierSuggestions = groupedSuggestions[supplierId];

                return (
                  <TableBody component="div" key={index}>
                    <TableRow component="div" className={classes.numberRow}>
                      <TableCell component="div" colSpan={12} align="center" className={classes.supplierHeader}>
                        <div>{supplierSuggestions[0].supplier.number} - {supplierSuggestions[0].supplier.name}</div>
                      </TableCell>
                    </TableRow>
                    <FixedSizeList
                      style={{ scrollbarGutter: 'stable' }}
                      height={Math.min(59 * supplierSuggestions.length, 600)} // 59 - height of one row
                      itemSize={59}
                      itemCount={supplierSuggestions.length}
                      overscanCount={5}
                      itemData={{
                        supplierSuggestions,
                        selectedSuggestions,
                        classes,
                        cellProps,
                        openPurchase,
                        openProduct,
                        onToggle,
                        onOpenStockDialog,
                        canManageReplenishmentSuggestions,
                        t,
                      }}
                    >
                      {ReplenishmentSuggestionTableRow}
                    </FixedSizeList>
                  </TableBody>
                );
              })}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  );
};

ReplenishmentSuggestionsTable.propTypes = {};
