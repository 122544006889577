import React from 'react';
import { SvgIcon } from '@mui/material';

export const QrCodeNextStep = props => (
  <SvgIcon {...props} titleAccess="Next step" viewBox="0 0 232 232">
    <rect x="0" y="0" width="232" height="232" fill="#ffffff"/>
    <defs>
      <rect id="p" width="8" height="8"/>
    </defs>
    <g fill="#000000">
      <use xlinkHref="#p" x="32" y="32"/>
      <use xlinkHref="#p" x="32" y="40"/>
      <use xlinkHref="#p" x="32" y="48"/>
      <use xlinkHref="#p" x="32" y="56"/>
      <use xlinkHref="#p" x="32" y="64"/>
      <use xlinkHref="#p" x="32" y="72"/>
      <use xlinkHref="#p" x="32" y="80"/>
      <use xlinkHref="#p" x="32" y="96"/>
      <use xlinkHref="#p" x="32" y="104"/>
      <use xlinkHref="#p" x="32" y="120"/>
      <use xlinkHref="#p" x="32" y="144"/>
      <use xlinkHref="#p" x="32" y="152"/>
      <use xlinkHref="#p" x="32" y="160"/>
      <use xlinkHref="#p" x="32" y="168"/>
      <use xlinkHref="#p" x="32" y="176"/>
      <use xlinkHref="#p" x="32" y="184"/>
      <use xlinkHref="#p" x="32" y="192"/>
      <use xlinkHref="#p" x="40" y="32"/>
      <use xlinkHref="#p" x="40" y="80"/>
      <use xlinkHref="#p" x="40" y="96"/>
      <use xlinkHref="#p" x="40" y="104"/>
      <use xlinkHref="#p" x="40" y="112"/>
      <use xlinkHref="#p" x="40" y="120"/>
      <use xlinkHref="#p" x="40" y="128"/>
      <use xlinkHref="#p" x="40" y="144"/>
      <use xlinkHref="#p" x="40" y="192"/>
      <use xlinkHref="#p" x="48" y="32"/>
      <use xlinkHref="#p" x="48" y="48"/>
      <use xlinkHref="#p" x="48" y="56"/>
      <use xlinkHref="#p" x="48" y="64"/>
      <use xlinkHref="#p" x="48" y="80"/>
      <use xlinkHref="#p" x="48" y="104"/>
      <use xlinkHref="#p" x="48" y="128"/>
      <use xlinkHref="#p" x="48" y="144"/>
      <use xlinkHref="#p" x="48" y="160"/>
      <use xlinkHref="#p" x="48" y="168"/>
      <use xlinkHref="#p" x="48" y="176"/>
      <use xlinkHref="#p" x="48" y="192"/>
      <use xlinkHref="#p" x="56" y="32"/>
      <use xlinkHref="#p" x="56" y="48"/>
      <use xlinkHref="#p" x="56" y="56"/>
      <use xlinkHref="#p" x="56" y="64"/>
      <use xlinkHref="#p" x="56" y="80"/>
      <use xlinkHref="#p" x="56" y="104"/>
      <use xlinkHref="#p" x="56" y="112"/>
      <use xlinkHref="#p" x="56" y="144"/>
      <use xlinkHref="#p" x="56" y="160"/>
      <use xlinkHref="#p" x="56" y="168"/>
      <use xlinkHref="#p" x="56" y="176"/>
      <use xlinkHref="#p" x="56" y="192"/>
      <use xlinkHref="#p" x="64" y="32"/>
      <use xlinkHref="#p" x="64" y="48"/>
      <use xlinkHref="#p" x="64" y="56"/>
      <use xlinkHref="#p" x="64" y="64"/>
      <use xlinkHref="#p" x="64" y="80"/>
      <use xlinkHref="#p" x="64" y="96"/>
      <use xlinkHref="#p" x="64" y="112"/>
      <use xlinkHref="#p" x="64" y="120"/>
      <use xlinkHref="#p" x="64" y="144"/>
      <use xlinkHref="#p" x="64" y="160"/>
      <use xlinkHref="#p" x="64" y="168"/>
      <use xlinkHref="#p" x="64" y="176"/>
      <use xlinkHref="#p" x="64" y="192"/>
      <use xlinkHref="#p" x="72" y="32"/>
      <use xlinkHref="#p" x="72" y="80"/>
      <use xlinkHref="#p" x="72" y="96"/>
      <use xlinkHref="#p" x="72" y="104"/>
      <use xlinkHref="#p" x="72" y="112"/>
      <use xlinkHref="#p" x="72" y="144"/>
      <use xlinkHref="#p" x="72" y="192"/>
      <use xlinkHref="#p" x="80" y="32"/>
      <use xlinkHref="#p" x="80" y="40"/>
      <use xlinkHref="#p" x="80" y="48"/>
      <use xlinkHref="#p" x="80" y="56"/>
      <use xlinkHref="#p" x="80" y="64"/>
      <use xlinkHref="#p" x="80" y="72"/>
      <use xlinkHref="#p" x="80" y="80"/>
      <use xlinkHref="#p" x="80" y="96"/>
      <use xlinkHref="#p" x="80" y="112"/>
      <use xlinkHref="#p" x="80" y="128"/>
      <use xlinkHref="#p" x="80" y="144"/>
      <use xlinkHref="#p" x="80" y="152"/>
      <use xlinkHref="#p" x="80" y="160"/>
      <use xlinkHref="#p" x="80" y="168"/>
      <use xlinkHref="#p" x="80" y="176"/>
      <use xlinkHref="#p" x="80" y="184"/>
      <use xlinkHref="#p" x="80" y="192"/>
      <use xlinkHref="#p" x="96" y="32"/>
      <use xlinkHref="#p" x="96" y="40"/>
      <use xlinkHref="#p" x="96" y="48"/>
      <use xlinkHref="#p" x="96" y="56"/>
      <use xlinkHref="#p" x="96" y="72"/>
      <use xlinkHref="#p" x="96" y="80"/>
      <use xlinkHref="#p" x="96" y="104"/>
      <use xlinkHref="#p" x="96" y="128"/>
      <use xlinkHref="#p" x="96" y="136"/>
      <use xlinkHref="#p" x="96" y="152"/>
      <use xlinkHref="#p" x="96" y="160"/>
      <use xlinkHref="#p" x="96" y="184"/>
      <use xlinkHref="#p" x="96" y="192"/>
      <use xlinkHref="#p" x="104" y="32"/>
      <use xlinkHref="#p" x="104" y="56"/>
      <use xlinkHref="#p" x="104" y="72"/>
      <use xlinkHref="#p" x="104" y="88"/>
      <use xlinkHref="#p" x="104" y="96"/>
      <use xlinkHref="#p" x="104" y="112"/>
      <use xlinkHref="#p" x="104" y="128"/>
      <use xlinkHref="#p" x="104" y="152"/>
      <use xlinkHref="#p" x="104" y="160"/>
      <use xlinkHref="#p" x="104" y="168"/>
      <use xlinkHref="#p" x="104" y="184"/>
      <use xlinkHref="#p" x="104" y="192"/>
      <use xlinkHref="#p" x="112" y="32"/>
      <use xlinkHref="#p" x="112" y="40"/>
      <use xlinkHref="#p" x="112" y="56"/>
      <use xlinkHref="#p" x="112" y="80"/>
      <use xlinkHref="#p" x="112" y="88"/>
      <use xlinkHref="#p" x="112" y="112"/>
      <use xlinkHref="#p" x="112" y="136"/>
      <use xlinkHref="#p" x="112" y="168"/>
      <use xlinkHref="#p" x="112" y="184"/>
      <use xlinkHref="#p" x="120" y="32"/>
      <use xlinkHref="#p" x="120" y="56"/>
      <use xlinkHref="#p" x="120" y="72"/>
      <use xlinkHref="#p" x="120" y="88"/>
      <use xlinkHref="#p" x="120" y="96"/>
      <use xlinkHref="#p" x="120" y="104"/>
      <use xlinkHref="#p" x="120" y="112"/>
      <use xlinkHref="#p" x="120" y="152"/>
      <use xlinkHref="#p" x="120" y="160"/>
      <use xlinkHref="#p" x="120" y="168"/>
      <use xlinkHref="#p" x="120" y="176"/>
      <use xlinkHref="#p" x="128" y="32"/>
      <use xlinkHref="#p" x="128" y="40"/>
      <use xlinkHref="#p" x="128" y="64"/>
      <use xlinkHref="#p" x="128" y="80"/>
      <use xlinkHref="#p" x="128" y="88"/>
      <use xlinkHref="#p" x="128" y="104"/>
      <use xlinkHref="#p" x="128" y="128"/>
      <use xlinkHref="#p" x="128" y="136"/>
      <use xlinkHref="#p" x="128" y="144"/>
      <use xlinkHref="#p" x="128" y="152"/>
      <use xlinkHref="#p" x="128" y="168"/>
      <use xlinkHref="#p" x="128" y="192"/>
      <use xlinkHref="#p" x="136" y="112"/>
      <use xlinkHref="#p" x="136" y="120"/>
      <use xlinkHref="#p" x="136" y="136"/>
      <use xlinkHref="#p" x="136" y="160"/>
      <use xlinkHref="#p" x="136" y="184"/>
      <use xlinkHref="#p" x="136" y="192"/>
      <use xlinkHref="#p" x="144" y="32"/>
      <use xlinkHref="#p" x="144" y="40"/>
      <use xlinkHref="#p" x="144" y="48"/>
      <use xlinkHref="#p" x="144" y="56"/>
      <use xlinkHref="#p" x="144" y="64"/>
      <use xlinkHref="#p" x="144" y="72"/>
      <use xlinkHref="#p" x="144" y="80"/>
      <use xlinkHref="#p" x="144" y="104"/>
      <use xlinkHref="#p" x="144" y="136"/>
      <use xlinkHref="#p" x="144" y="160"/>
      <use xlinkHref="#p" x="144" y="176"/>
      <use xlinkHref="#p" x="144" y="184"/>
      <use xlinkHref="#p" x="144" y="192"/>
      <use xlinkHref="#p" x="152" y="32"/>
      <use xlinkHref="#p" x="152" y="80"/>
      <use xlinkHref="#p" x="152" y="96"/>
      <use xlinkHref="#p" x="152" y="120"/>
      <use xlinkHref="#p" x="152" y="128"/>
      <use xlinkHref="#p" x="152" y="136"/>
      <use xlinkHref="#p" x="152" y="168"/>
      <use xlinkHref="#p" x="152" y="184"/>
      <use xlinkHref="#p" x="160" y="32"/>
      <use xlinkHref="#p" x="160" y="48"/>
      <use xlinkHref="#p" x="160" y="56"/>
      <use xlinkHref="#p" x="160" y="64"/>
      <use xlinkHref="#p" x="160" y="80"/>
      <use xlinkHref="#p" x="160" y="104"/>
      <use xlinkHref="#p" x="160" y="112"/>
      <use xlinkHref="#p" x="160" y="120"/>
      <use xlinkHref="#p" x="160" y="136"/>
      <use xlinkHref="#p" x="160" y="144"/>
      <use xlinkHref="#p" x="160" y="168"/>
      <use xlinkHref="#p" x="160" y="176"/>
      <use xlinkHref="#p" x="160" y="184"/>
      <use xlinkHref="#p" x="168" y="32"/>
      <use xlinkHref="#p" x="168" y="48"/>
      <use xlinkHref="#p" x="168" y="56"/>
      <use xlinkHref="#p" x="168" y="64"/>
      <use xlinkHref="#p" x="168" y="80"/>
      <use xlinkHref="#p" x="168" y="96"/>
      <use xlinkHref="#p" x="168" y="104"/>
      <use xlinkHref="#p" x="168" y="128"/>
      <use xlinkHref="#p" x="168" y="144"/>
      <use xlinkHref="#p" x="168" y="160"/>
      <use xlinkHref="#p" x="168" y="168"/>
      <use xlinkHref="#p" x="168" y="176"/>
      <use xlinkHref="#p" x="168" y="184"/>
      <use xlinkHref="#p" x="176" y="32"/>
      <use xlinkHref="#p" x="176" y="48"/>
      <use xlinkHref="#p" x="176" y="56"/>
      <use xlinkHref="#p" x="176" y="64"/>
      <use xlinkHref="#p" x="176" y="80"/>
      <use xlinkHref="#p" x="176" y="96"/>
      <use xlinkHref="#p" x="176" y="104"/>
      <use xlinkHref="#p" x="176" y="168"/>
      <use xlinkHref="#p" x="184" y="32"/>
      <use xlinkHref="#p" x="184" y="80"/>
      <use xlinkHref="#p" x="184" y="96"/>
      <use xlinkHref="#p" x="184" y="104"/>
      <use xlinkHref="#p" x="184" y="112"/>
      <use xlinkHref="#p" x="184" y="136"/>
      <use xlinkHref="#p" x="184" y="144"/>
      <use xlinkHref="#p" x="184" y="152"/>
      <use xlinkHref="#p" x="184" y="160"/>
      <use xlinkHref="#p" x="184" y="168"/>
      <use xlinkHref="#p" x="192" y="32"/>
      <use xlinkHref="#p" x="192" y="40"/>
      <use xlinkHref="#p" x="192" y="48"/>
      <use xlinkHref="#p" x="192" y="56"/>
      <use xlinkHref="#p" x="192" y="64"/>
      <use xlinkHref="#p" x="192" y="72"/>
      <use xlinkHref="#p" x="192" y="80"/>
      <use xlinkHref="#p" x="192" y="96"/>
      <use xlinkHref="#p" x="192" y="120"/>
      <use xlinkHref="#p" x="192" y="168"/>
      <use xlinkHref="#p" x="192" y="192"/>
    </g>
  </SvgIcon>
);
