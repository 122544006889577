import * as Yup from 'yup';

export const buildValidationSchema = (countries, t) => {
  return Yup
    .object()
    .shape({
      name: Yup
        .string()
        .trim()
        .required(t.translate('Please specify a company name.')),
      address: Yup.object().shape({
        street: Yup.string().required(t.translate('Please specify a street.')),
        city: Yup.string().required(t.translate('Please specify a city.')),
        zip: Yup.string().required(t.translate('Please specify a zip code.')),
        country: Yup
          .string()
          .trim()
          .oneOf(countries.map(({ countryCode }) => countryCode))
          .required(t.translate('Please specify a country.')),
      }),
      uploadedFile: Yup.mixed().required(t.translate('Please specify a logo.')),
      email: Yup.string().email().nullable(),
      websiteUrl: Yup.string().url().nullable(),
      DATEVNumberLength: Yup
        .number()
        .integer()
        .min(7)
        .label(t.translate('DATEV number length')),
      VATSettings: Yup.array().of(
        Yup.object().shape({
          VAT: Yup
            .number()
            .min(0.01)
            .max(1),
        })
      ).test('at-least-one-valid-VAT', 'At least one valid VAT must be entered', (value) => {
        return value.some(item => item.VAT >= 0.01 && item.VAT <= 1);
      }),
      documentSettings: Yup.array().of(
        Yup.object().shape({
          numberLength: Yup
            .number()
            .integer()
            .required(t.translate('Please specify a number length.')),
          separator: Yup
            .string()
            .max(8)
            .nullable(),
        })
      ),
    });
};
