import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';

import { useTheme } from '@mui/material/styles';
import Image from 'mui-image';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { makeSourceLink } from 'services/files';

export const ZoomPicture = ({ image, style, imageStyle, ...props }) => {
  const theme = useTheme();
  const box = useRef();

  const link = image
    ? image.filePath
      ? makeSourceLink(image.filePath)
      : image
    : '#';

    const [transform, setTransform] = useState(null);
    const [position, setPosition] = useState({ mouseX: 0, mouseY: 0 });
    const [zoom, setZoom] = useState(1);

  const handleMouseMovement = (e) => {
    const {
      left: offsetLeft,
      top: offsetTop,
    } = box.current.getBoundingClientRect();

    const {
      current: {
        offsetHeight,
        offsetWidth,
      },
    } = box;

    const x = ((e.pageX - offsetLeft) / parseInt(offsetWidth, 10)) * 100;
    const y = ((e.pageY - offsetTop) / parseInt(offsetHeight, 10)) * 100;

    setPosition({
      mouseX: x,
      mouseY: y,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledMouseMovement = useCallback(throttle((value) => handleMouseMovement(value), 30), []);

  const handleMouseEnter = () => {
    setZoom(2);
  };

  const handleMouseOut = () => {
    setPosition({
      mouseX: 0,
      mouseY: 0,
    });
    setZoom(1);
  };

  const handleOnWheel = (e) => {
    e.deltaY < 0
      ? setZoom(prev => prev + .2)
      : setZoom(prev => prev - .2);
  };

  useEffect(() => {
    setTransform({ transformOrigin: `${position.mouseX}% ${position.mouseY}%` });
  }, [position]);

  return (
    <Box
      ref={box}
      onMouseMove={e => {
        e.persist(); throttledMouseMovement(e);
      }}
      onMouseOut={handleMouseOut}
      onWheel = {handleOnWheel}
      onMouseEnter={handleMouseEnter}
      style={{ display: 'flex' }}
      {...props}
    >
      <Image
        showLoading={!image && <CircularProgress size={16}/>}
        src={link}
        bgColor={theme.palette.common.white}
        wrapperStyle={{
          ...transform,
          borderRadius: theme.shape.borderRadius,
          overflow: 'hidden',
          transform: `scale(${zoom})`,
          ...style,
        }}
        style={{
          objectFit: 'contain',
          ...imageStyle,
        }}
      />
    </Box>
  );
};

ZoomPicture.propTypes = {
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      filePath: PropTypes.string.isRequired,
    }),
  ]),
};
