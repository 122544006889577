import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';

import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AlzuraSpriteIcon } from 'components';
import { useStyles } from '../Tables';

import {
  TableCell,
  TableRow,
  Table,
} from 'components';

const createRowCreator = t => (label, values, key) => ({ values, label: t.translate(label), key });

const getTimeFormat = time => {
  const searchIndex = time.search(/[1-9]/);

  if (searchIndex === -1) {
    return '—';
  } else {
    const label = ['s', 'm', 'h'];
    return time.slice(searchIndex)
                .split(':').reverse()
                .map((value, index) => value + label[index])
                .reverse().join(' ');
  }
};

export const ShippingTable = ({ pickupSheets }) => {
  const { classes } = useStyles();
  const t = useTranslator();

  const createRow = createRowCreator(t);
  const rows = [
    createRow('Today', pickupSheets, 'daily'),
    createRow('Week', pickupSheets, 'weekly'),
    createRow('Month', pickupSheets, 'monthly'),
    createRow('Year', pickupSheets, 'yearly'),
  ];

  return (
    <TableContainer>
      <Table stickyHeader className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell fixed width={130} name="orders">
              <Grid className={classes.titleFixedColumn}>
                <AlzuraSpriteIcon id='assessment'/>
                <Typography>{t.translate('Shipping')}</Typography>
              </Grid>
            </TableCell>
            <TableCell width={110} className={classes.headerCell} name="avgTimeToPick" align="right">
              {t.translate('Pickup')}
            </TableCell>
            <TableCell width={110}  className={classes.headerCell} name="avgTimeToPack" align="right">
              {t.translate('Packing')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((item, index) => (
            <TableRow key={index} className={classes.tableRow}>
              <TableCell className={classes.fixedCell}>
                <Typography>
                  {item.label}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell} align="right" >{
                item.values[item.key]?.avgTimeToPick ?
                getTimeFormat(item.values[item.key]?.avgTimeToPick)
                : '—'}</TableCell>
              <TableCell className={classes.cell} align="right" >{
                item.values[item.key]?.avgTimeToPack ?
                getTimeFormat(item.values[item.key]?.avgTimeToPack)
                : '—'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ShippingTable.propTypes = {
  yearly: PropTypes.shape({
    label: PropTypes.string.isRequired,
    avgTimeToPick: PropTypes.string,
    avgTimeToPack: PropTypes.string,
  }),
  monthly: PropTypes.shape({
    label: PropTypes.string.isRequired,
    avgTimeToPick: PropTypes.string,
    avgTimeToPack: PropTypes.string,
  }),
  weekly: PropTypes.shape({
    label: PropTypes.string.isRequired,
    avgTimeToPick: PropTypes.string,
    avgTimeToPack: PropTypes.string,
  }),
  daily: PropTypes.shape({
    label: PropTypes.string.isRequired,
    avgTimeToPick: PropTypes.string,
    avgTimeToPack: PropTypes.string,
  }),
};
