import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './SelectingProductDialog.jsx';
import { useTranslator } from 'i18n';

import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ViewListIcon from '@mui/icons-material/ViewList';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';

import { IdentifyProductItem } from 'components';

const SelectingProductDialog = ({
  updateProducts,
  onSelect,
  onClose,
  products,
  b2bProducts,
  isMobileView,
  ...props
}) => {
  const { classes } = useStyles();
  const t = useTranslator();

  const [isDetails, setIsDetails] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (products) {
      setItems(products.data);
    } else if (b2bProducts) {
      setItems(b2bProducts);
    } else {
      setItems([]);
    }
  }, [products, b2bProducts]);

  return <>
    <Dialog
      {...props}
      scroll="body"
      fullWidth
      maxWidth="sm"
      fullScreen={isMobileView}
      onClose={onClose}
      aria-label="max-width-dialog"
      className={classes.root}
    >
      <DialogTitle id="max-width-dialog-title" className={classes.dialogTitle}>
        <Grid container justifyContent="space-between" alignItems={isMobileView ? 'stretch' : 'center'} direction={isMobileView ? 'column' : 'row'}>
          <Grid item container
            alignItems="center"
            xs={12}
          >
            <IconButton
              color="inherit"
              aria-label="go back"
              className={classes.backButton}
              onClick={onClose}
              size="large">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="body1" className={classes.bold}>{t.translate('Identify product')}</Typography>
          </Grid>
          {isMobileView && (
            <Typography variant="body2" className={classes.textSecondary}>{t.translate('Please choose which product should be assigned')}.</Typography>
          )}
          <Grid item>
            <Button
              className={classes.moreButton}
              variant="outlined"
              onClick={() => setIsDetails(!isDetails)}
            >
              {isDetails
                ? t.translate('Show less details')
                : t.translate('Show more details')
              }
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent >
        {!isMobileView && (
          <Typography variant="body1" className={classes.textSecondary}>{t.translate('Please choose which product should be assigned')}.</Typography>
        )}

        <Grid container spacing={2} style={{ flexWrap: 'wrap' }}>
          {items.map((product, index) => (
            <Grid item md={6} xs={12} key={index}>
              <IdentifyProductItem
                isDetails={isDetails}
                product={product}
                onSelect={onSelect}
                onClose={onClose}
                isMobileView={isMobileView}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  </>;
};

SelectingProductDialog.propTypes = {
  updateProducts: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  products: PropTypes.object,
  b2bProducts: PropTypes.array,
  isMobileView: PropTypes.bool.isRequired,
};

export default SelectingProductDialog;

export const ListingButton = props => (
  <IconButton {...props} aria-label="list products" size="large">
    <ViewListIcon />
  </IconButton>
);
