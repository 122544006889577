/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps */
import { useRef, useEffect } from 'react';
import isEqual from 'lodash/isEqual';

export const didMount = fn => useEffect(fn, []);

export const didUpdate = (fn, deps) => {
  const ref = useRef(false);

  useEffect(() => {
    if (ref.current) {
      return fn();
    } else {
      ref.current = true;
    }
  }, deps);
};

export const useDeepCompareMemoize = (value, initialValue) => {
  const ref = useRef(initialValue);

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};
