import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: theme.zIndex.cameraStream,
    '& .react-html5-camera-photo': {
      height: '100%',
      width: '100%',
      '& video': {
        height: '100%',
        objectFit: 'cover',
        width: '100%',
      },
    },
  },
  backButton: {
    color: theme.palette.common.white,
    height: theme.spacing(6),
    left: theme.spacing(1.5),
    position: 'absolute',
    top: theme.spacing(1.375),
    width: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      left: theme.spacing(.5),
      top: theme.spacing(.5),
    },
  },
  actions: {
    bottom: 0,
    left: 0,
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    position: 'absolute',
    right: 0,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
    },
  },
}));
