import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { didUpdate, useForm } from 'hooks';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { SupplierAutocomplete, useSupplierAutocomplete } from 'autocompletes';
import {
  IntegerFormControl,
  FormControlError,
  InputControl,
  BusyButton,
  RadioGroup,
  DotChip,
} from 'components';

import { correctStock, fetchStorageProducts } from 'api/storage-products';
import { styleDefaultFormDialog } from 'theme/mixins/dialog';
import toaster from 'services/toaster';
import { correctionReason } from 'consts';

const useStyles = makeStyles()(theme => styleDefaultFormDialog(theme, {
  autocompleteContainer: {
    flexWrap: 'wrap',
  },
  productDetails: {
    backgroundColor: theme.palette.backgrounds.grey['240'],
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  supplierAutocomplete: {
    paddingLeft: theme.spacing(3.5),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(0),
    },
  },
  stockDescription: {
    fontSize: '0.75rem',
    marginBottom: theme.spacing(1),
  },
  stockFormControl: {
    display: 'block',
    padding: theme.spacing(3),
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
    borderWidth: 1,
  },
}));

const makeId = postfix => `correction-storage-product-dialog--${postfix}`;

export const CorrectionStockDialog = ({
  onClose,
  onSave,
  storageProduct,
  isMobileView,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const form = useForm({
    supplierId: null,
    comment: '',
    reason: 'correction',
    stock: 0,
    supplierNumber: storageProduct.supplier
      ? storageProduct.supplier.number
      : '',
    initialStock: 0,
    correctionReason: correctionReason.inventoryCheck,
  });
  const {
    catchHttpError,
    isModelValid,
    setFormValue,
    resetForm,
    setValue,
    errors,
    model,
  } = form;

  const correctionReasonOptions = useMemo(
    () => new Map(['inventoryCheck', 'damagedGoods'].map(
      key => [key, t.translate(correctionReason[key])])
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t.intl.locale]
  );

  const supplierAutocomplete = useSupplierAutocomplete({ form });

  didUpdate(() => {
    if (props.open) {
      resetForm();
    }
  }, [props.open]);

  const validateModel = errors => {
    if (!model.stock || model.stock === '-') {
      errors.stock = t.translate('Please specify a new stock.');
    }
    if (!supplierAutocomplete.selectedOption) {
      errors.supplierId = t.translate('Please specify an existing supplier.');
    }
  };

  useEffect(() => {
    if (supplierAutocomplete.selectedOption) {
      const fetchStorageProduct = async () => {
        const { storage, product, manufacturingDate } = storageProduct;
        const { data } = await fetchStorageProducts({
          storageId: storage?.id,
          supplierId: supplierAutocomplete.selectedOption?.id,
          productId: product?.id,
          manufacturingDate,
        });

        const totalStock = data.reduce((acc, storageProduct) => acc + storageProduct.stock, 0);

        if (Array.isArray(data) && data.length) {
          setValue('supplierId')(supplierAutocomplete.selectedOption?.id);
          setValue('initialStock')(totalStock);
        } else {
          setValue('supplierId')(null);
          setValue('initialStock')(0);
        }
      };

      fetchStorageProduct();
    } else if (!!model.supplierId && !supplierAutocomplete.selectedOption) {
      setValue('supplierId')(null);
      setValue('initialStock')(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierAutocomplete.selectedOption, storageProduct.id]);

  const submit = async () => {
    try {
      if (!isModelValid(validateModel)) {
        return;
      }
      model.comment = `${model.correctionReason} - ${model.comment}`;

      const payload = {
        ...(({ initialStock, supplierNumber, ...model }) => model)(model),
        storageId: storageProduct.storage.id,
        productId: storageProduct.product.id,
      };

      await correctStock(payload);
      toaster.success(t.translate('Storage has been saved successfully.'));

      onSave && onSave();
      onClose();
    } catch (err) {
      catchHttpError(err);
    }
  };

  return (
    <Dialog {...props} scroll="body" maxWidth={false} classes={{ paperScrollBody: classes.root }} onClose={onClose}>
      <DialogTitle id="max-width-dialog-title">{t.translate('Correction')}</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container direction="column" spacing={3} >
          <Grid container item className={classes.autocompleteContainer}>
            <Grid item xs={12} md={6} className={classes.productDetails}>
              <Typography color="textSecondary" variant="body2">{t.translate('Product ID')}: {storageProduct.product.id || '—'}</Typography>
              <Typography color="textSecondary" variant="body2">{t.translate('Storage')}: {storageProduct.storage.id}</Typography>
              {storageProduct.manufacturingDate && (
                <Typography color="textSecondary" variant="body2">
                  {t.translate('DOT')}: <DotChip manufacturingDate={storageProduct.manufacturingDate}/>
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6} className={classes.supplierAutocomplete}>
              <SupplierAutocomplete
                {...supplierAutocomplete.props}
                label={t.translate('Supplier')}
                error={errors.supplierId}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography color="textSecondary" variant="body2" className={classes.stockDescription}>{t.translate('Stock')}</Typography>
            <FormControl error={!!errors.stock} className={classes.stockFormControl}>
              <IntegerFormControl
                initialValue={model.initialStock}
                rightLabel={t.translate('New stock')}
                leftLabel={t.translate('Old stock')}
                value={model.stock}
                min={0}
                onChange={setValue('stock')}
                disabled={!model.supplierId}
              />
              {!!errors.stock && <FormControlError error={errors.stock} />}
            </FormControl>
          </Grid>
          <Grid item>
            <RadioGroup
              defaultValue={correctionReason.inventoryCheck}
              label={t.translate('Correction booking reason')}
              value={model.correctionReason}
              options={correctionReasonOptions}
              onChange={setFormValue('correctionReason')}
            />
          </Grid>
          <Grid item>
            <InputControl
              id={makeId('comment')}
              error={errors.comment}
              label={t.translate('Comment')}
              value={model.comment}
              placeholder={t.translate('Annotations')}
              onChange={setFormValue('comment')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
        <BusyButton color="primary" submit onClick={submit}>{t.translate('Save')}</BusyButton>
      </DialogActions>
    </Dialog>
  );
};

CorrectionStockDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  storageProduct: PropTypes.shape({
    manufacturingDate: PropTypes.string,
    storage: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    product: PropTypes.shape({
      alzuraId: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
