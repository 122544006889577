import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    borderCollapse: 'separate',
    '& .MuiDialog-paper': {
      maxWidth: theme.spacing(86),
    },
    '& th': {
      borderBottom: 'none',
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiDialogContent-root': {
        padding: theme.spacing(1),
      },
    },
  },
  title: {
    padding: theme.spacing(3),
    paddingBottom: 0,
  },
  emptyRow: {
    height: theme.spacing(1),
  },
  commentItem: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  mobileButton: {
    '& button': {
      width: '100%',
    },
    marginBottom: theme.spacing(2),
  },
  expandedContainerItem: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  reasonItem: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}));
