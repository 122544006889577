import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { Column } from 'layouts';
import { UserCard } from '../common';
import { Warehouses } from './Warehouses/Warehouses';
import { Permissions } from './Permissions/Permissions';

const useStyles = makeStyles()(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
}));

export const MobileView = ({
  onWarehouseAvailabilityChange,
  onUserEdit,
  onSave,
}) => {
  const { classes } = useStyles();

  const user = useSelector(({ users }) => users.details.user);

  if (user) {
    return (
      <Column p={1}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Paper className={classes.paper}>
              <UserCard isMobileView onUserEdit={onUserEdit} onSave={onSave} />
            </Paper>
          </Grid>
          <Grid item>
            <Permissions />
          </Grid>
          <Grid item>
            <Warehouses onAvailabilityChange={onWarehouseAvailabilityChange} />
          </Grid>
        </Grid>
      </Column>
    );
  } else {
    return <Column />;
  }
};

MobileView.propTypes = {
  onWarehouseAvailabilityChange: PropTypes.func.isRequired,
  onUserEdit: PropTypes.func.isRequired,
};
