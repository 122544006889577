import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';
import { useStyles } from './style';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  InputControl,
  BusyButton,
} from 'components';

import { requestPasswordRecovery } from 'api/authentication';
import toaster from 'services/toaster';

export const ResettingPasswordDialog = ({
  onSent,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');

  const submit = async () => {
    try {
      await requestPasswordRecovery(email);
      toaster.success(t.translate('Link to reset password has been sent to your email.'));
      onSent();
    } catch (err) {
      if (err.isHttp && err.collection) {
        setError(err.collection.email);
      }
    }
  };

  useEffect(() => {
    if (!props.open) {
      setError(null);
      setEmail('');
    }
  }, [props.open]);

  return (
    <Dialog scroll="body" {...props}>
      <form action="#">
        <DialogTitle className={classes.title}>{t.translate('Forgot password')}</DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container direction="column">
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                {t.translate('Enter your email to reset your password.')}
              </Typography>
            </Grid>
            <Grid item className={classes.emailItem}>
              <InputControl
                id="resetting-password--email"
                error={error}
                label={t.translate('Email')}
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <BusyButton
            fullWidth
            submit
            color="secondary"
            variant="contained"
            onClick={submit}
          >
            {t.translate('Reset password')}
          </BusyButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

ResettingPasswordDialog.propTypes = {
  onSent: PropTypes.func.isRequired,
};
