import { CHANGES_HISTORY } from 'store/types';
import { changesHistory as is } from 'store/initialState';

const setChangesHistory = (state, { data, meta }) => ({
  ...state,
  changesHistory: {
    ...state.changesHistory,
    data,
    meta: {
      totalEntities: meta.totalEntities,
    },
  },
});

export default (state = is, { type, payload }) => {
  switch (type) {
    case CHANGES_HISTORY.SET_CHANGES_HISTORY:
      return setChangesHistory(state, payload);
    default:
      return state;
  }
};
