import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import { useTranslator } from 'i18n';

import { Column } from 'layouts';
import { CircularLoader, ItemHeadline } from 'components';
import { ProductsTable } from './ProductsTable/ProductsTable';
import { OrderCard } from './OrderCard/OrderCard';
import { OrderDetails } from './OrderDetails/OrderDetails';
import { AddressesDetails } from '../../common/AddressesDetails/AddressesDetails';
import { useDialog } from 'hooks';
import { StatusHistory } from './StatusHistory/StatusHistory';
import { ReturnFees } from './ReturnFees/ReturnFees';

export const DesktopView = ({
  onProductDescriptionOpen,
  order,
  isLoading,
  onStockHistoryShow,
}) => {
  const t = useTranslator();
  const creatingDialog = useDialog();

  const bookReturnedStock = productId => {
    const orderPosition = order.positions.find(position => position.productId === productId);
    creatingDialog.open({ orderPosition });
  };

  return (
    <Column p={3} pt={2}>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <Grid container direction="column">
          {order && (
            <>
              <Grid container spacing={3} style={{ flexWrap: 'wrap' }}>
                <Grid item xs={12} container direction="column">
                  <ItemHeadline text={t.translate('Order details')} />
                  <OrderCard order={order}/>
                </Grid>
                <Grid item xs={12} container direction="column">
                  <ItemHeadline text={t.translate('Products')} badgeCounter={order.positions?.length}/>
                  <ProductsTable
                    onProductDescriptionOpen={onProductDescriptionOpen}
                    order={order}
                    positions={order.positions}
                    onReturnedStockBook={bookReturnedStock}
                    onStockHistoryShow={onStockHistoryShow}
                  />
                </Grid>
                <Grid item container spacing={3}>
                  <Grid item xs={6} container direction="column">
                    <ItemHeadline text={t.translate('Invoice and shipping address')}/>
                    <AddressesDetails invoiceAddress={order.invoiceAddress} shippingAddress={order.deliveryAddress} order={order} />
                  </Grid>
                  <Grid item xs={6} container direction="column">
                    <ItemHeadline text={t.translate('Total')}/>
                    <OrderDetails order={order} />
                  </Grid>
                </Grid>
                {!!order.statusHistory.length &&
                  <Grid item xs={6} container direction="column">
                    <ItemHeadline text={t.translate('Status history')}/>
                    <StatusHistory statusHistory={order.statusHistory}/>
                  </Grid>
                }
                {!!order.returns.length &&
                  <Grid item xs={6} container direction="column">
                    <ItemHeadline text={t.translate('Returns')}/>
                    <ReturnFees returns={order.returns}/>
                  </Grid>
                }
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Column>
  );
};

DesktopView.propTypes = {
  order: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  onProductDescriptionOpen: PropTypes.func.isRequired,
  onStockHistoryShow: PropTypes.func.isRequired,
};
