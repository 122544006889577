import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { useStyles } from './ConfirmingCancelComponent.jsx';

import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import TableContainer from '@mui/material/TableContainer';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';

import { ConfirmingPositionItem } from './ConfirmingPositionItem/ConfirmingPositionItem';
import { OrderStatuses } from '../../../consts';

export const ConfirmingCancelComponent = ({
  isMobileView,
  onAccept,
  onClose,
  values,
  order,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const isOrderClosed = order.statusCode === OrderStatuses.CLOSED;

  const accept = () => {
    onAccept();
    onClose();
  };

  return <>
    <DialogTitle className={classes.title}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <IconButton
            color="inherit"
            aria-label="go back"
            className={classes.backButton}
            onClick={onClose}
            size="large">
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item>{t.translate('Confirm cancellation')}</Grid>
      </Grid>
      <Grid>
        <Typography
          variant={isMobileView ? 'body2' : 'body1'}
          className={classes.subTitle}
        >
          {
            isOrderClosed
            ? t.translate('Are you sure you want to cancel this order?')
            : t.translate('Are you sure you want to cancel these products?')
          }
        </Typography>
      </Grid>
    </DialogTitle>
    {!isOrderClosed && (
      <DialogContent >
        {isMobileView ? (
          <>
            {order.positions.map((position, index) => (
              <ConfirmingPositionItem
                key={index}
                position={position}
                cancelledAmount={values[position.id]?.cancelledAmount}
                setCancelAllQuantity={values.parentForm.allOrderCancel}
              />
            ))
            }
          </>
        ) : (
          <TableContainer>
            <Table className={classes.root}>
              <TableHead>
                <TableRow>
                  <TableCell name="productId" className={classes.defaultFontSize} >{t.translate('Product-ID')}</TableCell>
                  <TableCell name="productName" className={classes.defaultFontSize}>{t.translate('Product name')}</TableCell>
                  <TableCell name="ean" className={classes.defaultFontSize}>EAN</TableCell>
                  <TableCell name="productEan" className={classes.defaultFontSize} align="center">{t.translate('Ordered')}</TableCell>
                  <TableCell name="quantity" className={classes.defaultFontSize} align="center">{t.translate('Cancel')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.positions.map((position, index) => (
                  <React.Fragment key={index}>
                    <TableRow className={classes.confirmingTable}>
                      <TableCell >{position.product.id}</TableCell>
                      <TableCell className={classes.nameCell}>{position.product.details[0]?.name || '—'}</TableCell>
                      <TableCell >{position.product.ean || '—'}</TableCell>
                      <TableCell align="center">{position.quantity || '—'}</TableCell>
                      <TableCell align="center">{values[position.id]?.cancelledAmount || '—'}</TableCell>
                    </TableRow>
                    <TableRow className={classes.emptyRow} />
                  </React.Fragment>
                ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
    )}
    <DialogActions className={classes.actions}>
      <Button color="primary" onClick={onClose}>{t.translate('Go Back')}</Button>
      <Button color="primary" onClick={accept}>{t.translate('Confirm')}</Button>
    </DialogActions>
  </>;
};

ConfirmingCancelComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  order: PropTypes.shape({
    number: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  values: PropTypes.shape({
    cancelledAmount: PropTypes.number,
  }).isRequired,
};
