import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './style';
import { useTranslator } from 'i18n';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import SortIcon from '@mui/icons-material/Sort';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const SortBar = ({
  currentText,
  order,
  children,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <Box
        {...props}
        className={classes.root}
        aria-controls="sort-bar"
        aria-haspopup="true"
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        <SortIcon />
        <Box flexGrow={1}>
          {t.translate(order === 'desc' ? 'Descending by' : 'Ascending by')} {}
          {currentText}
        </Box>
        <ArrowDropDownIcon />
      </Box>
      <Menu
        id="sort-bar"
        open={!!anchorEl}
        anchorEl={anchorEl}
        className={classes.menu}
        keepMounted
        onClose={() => setAnchorEl(null)}
      >
        {children}
      </Menu>
    </>
  );
};

SortBar.propTypes = {
  currentText: PropTypes.string.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
};
