import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';

import { CircularLoader, BadgeFilter } from 'components';
import { Column } from 'layouts';

import { SuppliersTable } from './SuppliersTable/SuppliersTable';
import { SupplierHandlingTypes } from 'consts';
import { Paper } from '@mui/material';

const useStyles = makeStyles()(theme => ({
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    boxShadow: 'none',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
  },
}));

export const DesktopView = ({
  isSuppliersLoading,
  list,
  ...props
}) => {
  const { classes } = useStyles();

  return (
    <Column p={3}>
      {isSuppliersLoading
        ? <CircularLoader />
        : (<>
          <Paper className={classes.paper}>
            <BadgeFilter
              filteredBy={'handlingType'}
              list={list}
              filteredByValues={SupplierHandlingTypes}
              title={'Filter by handling type'}
              prefixForTranslator={'supplierHandlingType:'}
            />
          </Paper>
          <SuppliersTable {...props} />
        </>)
      }
    </Column>
  );
};

DesktopView.propTypes = {
  isSuppliersLoading: PropTypes.bool.isRequired,
};
