import React from 'react';
import clsx from 'clsx';
import { Grid, MenuItem, TableContainer, TableHead, TableBody, Select, Typography } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useStyles } from '../styles';
import { ActionButton, InputControl, Table, TableCell, TableRow } from 'components';
import { useTranslator } from 'i18n';
import { fetchAlzuraSupplier } from 'api/suppliers';
import toaster from 'services/toaster';
import { PageWrapper } from '../PageWrapper/PageWrapper';

const makeId = postfix => `supplier-settings-general--${postfix}`;

export const GeneralSettings = ({ isEditMode, setFieldValue, values, handleChange, errors, activeMarketplaces }) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const handleAlzuraSupplierFetch = async () => {
    const getContactInfoIdByCountryCode = (countryCode) => values.contactInfos.find((item) => item.countryCode === countryCode)?.id;
    const alzuraSupplier = await fetchAlzuraSupplier(values.number);
    if (alzuraSupplier) {
      setFieldValue('address.name', alzuraSupplier.name);
      setFieldValue('address.additionalName', alzuraSupplier.number);
      setFieldValue('address.street', alzuraSupplier.address?.street);
      setFieldValue('address.zip', alzuraSupplier.address?.zip);
      setFieldValue('address.city', alzuraSupplier.address?.city);
      setFieldValue('address.country', alzuraSupplier.address?.country);

      if (activeMarketplaces) {
        const contactInfos = [];
        activeMarketplaces.forEach(marketplace => {
          const countryData = alzuraSupplier?.countriesMap[marketplace.country.countryCode.toUpperCase()];
          let contactInfo = values.contactInfos.find(({ countryCode }) => countryCode === marketplace.country.countryCode);

          const {
            email = '',
            invoiceEmail = '',
            contactPerson = '',
            phone = '',
            fax = '',
            ustId = '',
            taxId = '',
            taxArea = '',
            bankName = '',
            bankHolder = '',
            bankIBAN = '',
            bankBIC = '',
          } = countryData || {};

          if (countryData || !contactInfo) {
            contactInfo = {
              email,
              invoiceEmail,
              contactPerson,
              phone,
              fax,
              ustId,
              taxId,
              taxArea,
              bankName,
              bankHolder,
              bankIBAN,
              bankBIC,
            };
          }

          contactInfos.push({
            ...contactInfo,
            id: getContactInfoIdByCountryCode(marketplace.country.countryCode),
            marketplaceCountryId: marketplace.id,
            countryCode: marketplace.country.countryCode,
          });
        });
        setFieldValue('contactInfos', contactInfos);
      }
    }
    else {
      toaster.warning(t.translate('No supplier information for this account number'));
    }
  };

  return (
    <PageWrapper>
      <Grid item xl={6} lg={8} xs={12} className={classes.containerWrap}>
        <Grid container spacing={4}>
          <Grid container item xs={12} md={6} className={classes.containerWrap} spacing={1}>
            <Grid item xs={12} className={classes.marginBottom}>
              <InputControl
                id={makeId('name')}
                name="name"
                error={errors.name}
                label={t.translate('Internal name')}
                value={values.name}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Grid>
            <Grid container item xs={4} className={classes.marginBottom}>
              <InputControl
                id={makeId('status')}
                error={errors.active}
                label={t.translate('Account status')}
                InputComponent={() => (
                  <Select
                    value={values.active}
                    name="active"
                    onChange={handleChange}
                    disabled={!isEditMode}
                  >
                    {
                      [
                        { text: 'Active', value: true },
                        { text: 'Inactive', value: false },
                      ]
                        .map((el, index) => (
                          <MenuItem key={index} value={el.value}>{t.translate(el.text)}</MenuItem>
                        ))}
                  </Select>
                )}
              />
            </Grid>
            <Grid item xs={8} className={classes.marginBottom}>
              <InputControl
                id={makeId('number')}
                name="number"
                error={errors.number}
                label={t.translate('Supplier account number')}
                value={values.number}
                onChange={handleChange}
                disabled={!isEditMode}
                endAdornment={(
                  <ActionButton
                    disabled={!isEditMode}
                    className={classes.floatingActionButton}
                    tooltipPlacement="bottom"
                    aria-label={t.translate('Update from Alzura API')}
                    tooltip={t.translate('Update from Alzura API')}
                    icon={<CloudDownloadIcon/>}
                    onClick={handleAlzuraSupplierFetch}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Typography variant="body2" className={classes.addressLabel}>
                {t.translate('Supplier address')}
              </Typography>
              <Typography variant="body2">
                {values.address.name}
              </Typography>
              <Typography variant="body2">
                {values.address.additionalName}
              </Typography>
              <Typography variant="body2">
                {values.address.street}
              </Typography>
              <Typography variant="body2">
                {values.address.zip}&nbsp;{values.address.city}
              </Typography>
              <Typography variant="body2">{values.address.country}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputControl
              disabled={!isEditMode}
              id={makeId('note')}
              name="note"
              error={errors.note}
              label={t.translate('Note')}
              multiline
              rows={11}
              value={values.note}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.addressLabel}>{t.translate('Country')}</TableCell>
                <TableCell className={clsx(classes.addressLabel, errors.contactInfos?.length ? classes.errorLabel : '')}>{t.translate('Email')}</TableCell>
                <TableCell className={classes.addressLabel}>{t.translate('Invoice email')}</TableCell>
                <TableCell className={classes.addressLabel}>{t.translate('Contact person')}</TableCell>
                <TableCell className={classes.addressLabel}>{t.translate('Phone')}</TableCell>
                <TableCell className={classes.addressLabel}>{t.translate('Fax')}</TableCell>
                <TableCell className={classes.addressLabel}>{t.translate('UST Id')}</TableCell>
                <TableCell className={classes.addressLabel}>{t.translate('Tax Id')}</TableCell>
                <TableCell className={classes.addressLabel}>{t.translate('Tax area')}</TableCell>
                <TableCell className={classes.addressLabel}>{t.translate('Bank name')}</TableCell>
                <TableCell className={classes.addressLabel}>{t.translate('Bank holder')}</TableCell>
                <TableCell className={classes.addressLabel}>{t.translate('Bank IBAN')}</TableCell>
                <TableCell className={classes.addressLabel}>{t.translate('Bank BIC')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.contactInfos.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{t.translate(`country:${item.countryCode}`)}</TableCell>
                  <TableCell>{item.email || '—'}</TableCell>
                  <TableCell>{item.invoiceEmail || '—'}</TableCell>
                  <TableCell>{item.contactPerson || '—'}</TableCell>
                  <TableCell>{item.phone || '—'}</TableCell>
                  <TableCell>{item.fax || '—'}</TableCell>
                  <TableCell>{item.ustId || '—'}</TableCell>
                  <TableCell>{item.taxId || '—'}</TableCell>
                  <TableCell>{item.taxArea || '—'}</TableCell>
                  <TableCell>{item.bankName || '—'}</TableCell>
                  <TableCell>{item.bankHolder || '—'}</TableCell>
                  <TableCell>{item.bankIBAN || '—'}</TableCell>
                  <TableCell>{item.bankBIC || '—'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </PageWrapper>
  );
};
