import React from 'react';
import { createRoot } from 'react-dom/client';
import { Toast } from 'components';

const severities = ['success', 'warning', 'error', 'info'];
const toaster = {};

toaster.serve = () => {
  const plate = document.createElement('div');
  plate.id = 'plate';
  document.body.appendChild(plate);
};

/**
 * Mounts a new toast instance
 * into the root application point
 * passing to it method to unmount it.
 *
 * @param {any} arguments[0].content
 */
toaster.bake = ({
  content,
  ...props
}) => {
  const node = document.createElement('div');
  const root = createRoot(node);

  document.getElementById('plate').appendChild(node);

  const unmount = () => node.remove() && root.unmount();

  root.render(
    <Toast {...props} unmount={unmount}>{content}</Toast>
  );
};

/**
 * Creates severity named methods:
 * - toaster.success(props);
 * - toaster.warning(props);
 * - toaster.error(props);
 * - toaster.info(props);
 *
 * @param {string | object} props
 *        If a string, it's the `content`. See the method `bake`.
 *        If an object, it's the `argument[0]` like the method `bake` takes.
 */
severities.forEach(severity =>
  toaster[severity] = props => toaster.bake(
    typeof props === 'string' || React.isValidElement(props)
      ? {
          content: props,
          severity,
        }
      : {
          ...props,
          severity,
        }
  )
);

export default toaster;
