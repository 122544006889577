import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslator } from 'i18n';
import { useDialog, useList, useScreen } from 'hooks';

import { Box } from '@mui/material';

import { Header } from 'views/products/overview/DesktopView/Header/Header';
import { Header as MobileHeader } from 'views/products/overview/MobileView/Header/Header';
import { routes, tabsOptions } from './config';
import {
  isAuthenticatedUserAdministrator,
  selectActionPermission, selectAuthenticatedUserWarehouse,
  selectPermissions,
} from 'store/selectors/account';
import toaster from 'services/toaster';
import { ExportProductsDialog, ImportProductsDialog, ProductDialog } from 'dialogs';
import { fetchProductTypes, importProducts, importProductsPurchasePrices } from 'api/products';
import { fetchProductGroups } from 'api/product-groups';
import { fetchSuppliersForFilter } from 'services/suppliers';
import { ProductImportTypes } from 'consts';
import { exportSupplierProducts } from 'api/suppliers';
import { fetchProducts } from 'services/products';
import tables from 'config/tables';

export const ProductsOverview = () => {
  const list = useList(tables.products.products);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const screen = useScreen();
  const t = useTranslator();
  const isAdmin = useSelector(isAuthenticatedUserAdministrator);
  const permissions = useSelector(selectPermissions);

  const canCreateProduct = useSelector(selectActionPermission('products', 'create'));
  const warehouse = useSelector(selectAuthenticatedUserWarehouse);
  const canImportProducts = useSelector(selectActionPermission('products', 'import'));
  const canUpdateFromMarketplace = useSelector(selectActionPermission('products', 'updateFromMarketplace'));
  const canExportProducts = useSelector(selectActionPermission('suppliers', 'export-products'));

  const creatingDialog = useDialog();
  const importProductsDialog = useDialog();
  const exportProductsDialog = useDialog();

  const [productTypes, setTypes] = useState([]);
  const [productGroups, setProductGroups] = useState([]);

  const matchWithOutdatedProducts = useRouteMatch(routes[routes.length - 1].homePath);

  const shouldShowMenuItem = option => {
    const name = option.view;
    const subView = option.subView;
    return isAdmin || permissions[name] === undefined || (subView ? (permissions[name].view && permissions[name][subView]) : permissions[name].view);
  };
  const allowedRoutes = routes.filter(({ name, subView }) => isAdmin || permissions[name] === undefined || (subView ? (permissions[name].view && permissions[name][subView]) : permissions[name].view));

  let activeRoute = !screen.isMobile ? (
    allowedRoutes.find(({ homePath }) => homePath === history.location.pathname || homePath === matchWithOutdatedProducts?.path)
    || allowedRoutes[0]
  ) : allowedRoutes.find(({ homePath }) => homePath === history.location.pathname || homePath === matchWithOutdatedProducts?.path);

  useEffect(() => {
    if (!activeRoute?.homePath === matchWithOutdatedProducts?.path && !activeRoute?.subPaths.includes(history.location.pathname)) {
      history.replace(activeRoute.paths[0]);
    }
  }, [activeRoute, history, screen.isMobile, matchWithOutdatedProducts]);

  const allowedOptions = tabsOptions.filter(option => shouldShowMenuItem(option));
  const activeOption = allowedOptions.find(({ name }) => history.location.pathname.includes(name))?.name;

  useEffect(() => {
    const getProductTypes = async () => {
      try {
        const types = await fetchProductTypes();
        const groups = await fetchProductGroups();
        if (types) {
          setTypes(types.data);
        }
        if (groups) {
          setProductGroups(groups.data);
        }
      } catch (err) {}
    };
    dispatch(fetchSuppliersForFilter());
    getProductTypes();
  }, [dispatch]);

  const onProductsImport = async ({ importType, file, supplierId, marketplaceCountryId }) => {
    try {
      const formData = new FormData();

      formData.set('file', file);

      if (importType === ProductImportTypes.products) {
        if (marketplaceCountryId) {
          formData.set('marketplaceCountryId', marketplaceCountryId);
        }

        await importProducts(formData);

        toaster.success(t.translate('Products have been added to an import queue.'));
      } else {
        formData.set('supplierId', supplierId);

        await importProductsPurchasePrices(formData);

        toaster.success(t.translate('Products prices have been added to an import queue.'));
      }

      importProductsDialog.close();
    } catch (error) {}
  };

  const onProductsExport = async supplierId => {
    try {
      await exportSupplierProducts(supplierId);

      toaster.success(t.translate('Products export have been added to a queue.'));

      exportProductsDialog.close();
    } catch (error) {}
  };

  const onImportProductsClick = () => importProductsDialog.open();

  const onExportProductsClick = () => exportProductsDialog.open();

  const fetchData = useCallback(() => dispatch(fetchProducts(list.compile())), [dispatch, list]);

  useEffect(() => void fetchData(), [fetchData, location.search, warehouse]);

  return (
    <>
      <Helmet>
        <title>{t.translate('app:title')}</title>
      </Helmet>

      {screen.isMobile ? (
        <MobileHeader
          onCreateProduct={() => creatingDialog.open()}
          productTypes={productTypes}
          productGroups={productGroups}
          onImportProductsClick={onImportProductsClick}
          onExportProductsClick={onExportProductsClick}
          canExportProducts={canExportProducts}
        />
      ) : (
        <Header
          onCreateProduct={() => creatingDialog.open()}
          productTypes={productTypes}
          productGroups={productGroups}
          onImportProductsClick={onImportProductsClick}
          onExportProductsClick={onExportProductsClick}
          canExportProducts={canExportProducts}
          routeTitle={activeRoute?.label}
          screen={screen}
          allowedOptions={allowedOptions}
          activeOption={activeOption}
        />
      )}

      {canCreateProduct && creatingDialog.visible && (
        <ProductDialog
          {...creatingDialog.props}
          isMobileView={screen.isMobile}
          onSave={fetchData}
          canUpdateFromMarketplace={canUpdateFromMarketplace}
          productTypes={productTypes}
        />
      )}

      {canImportProducts && importProductsDialog.visible && (
        <ImportProductsDialog
          {...importProductsDialog.props}
          onProductsImport={onProductsImport}
        />
      )}

      {canExportProducts && exportProductsDialog.visible && (
        <ExportProductsDialog
          {...exportProductsDialog.props}
          onProductsExport={onProductsExport}
        />
      )}

      <Box display="flex" flexGrow={1} width={1}>
        <Switch>
          {Object.entries(allowedRoutes).map(([key, { name, homePath, subPaths, Page }]) => (
            shouldShowMenuItem(name) && (
              <Route key={key} exact path={[homePath, ...subPaths]}>
                <Page productTypes={productTypes}/>
              </Route>
            )
          ))}
        </Switch>
      </Box>
    </>
  );
};
