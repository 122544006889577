import React from 'react';
import { Grid, Input, Typography, FormControl, InputLabel } from '@mui/material';
import { useStyles } from '../styles';
import { FormControlError, InputControl, Picture } from 'components';
import { useTranslator } from 'i18n';
import { PageWrapper } from '../../../suppliers/settings/PageWrapper/PageWrapper';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const makeId = postfix => `companies-settings-general--${postfix}`;

export const GeneralSettings = ({ isEditMode, values, handleChange, errors, setFieldValue, countries }) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFieldValue('uploadedFile', selectedFile);
    }
  };

  return (
    <PageWrapper>
      <Grid item xl={8} lg={10} xs={12} className={classes.containerWrap}>
        <Grid container spacing={4}>
          <Grid container item xs={12} md={6} className={classes.containerWrap} spacing={1}>
            <Grid container item xs={12} md={9}>
              <Typography variant="h6" className={classes.marginBottom}>{t.translate('Company data')}</Typography>
            </Grid>
            <Grid item xs={6} className={classes.marginBottom}>
              <InputControl
                id={makeId('name')}
                name="name"
                error={errors.name}
                label={t.translate('Company name')}
                value={values.name}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Grid>
            <Grid item xs={6} className={classes.marginBottom}>
              <InputControl
                id={makeId('alternativeName')}
                name="alternativeName"
                error={errors.alternativeName}
                label={t.translate('Company additional name')}
                value={values.alternativeName}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Grid>
            <Grid item xs={6} className={classes.marginBottom}>
              <InputControl
                id={makeId('phoneLandline')}
                name="phoneLandline"
                error={errors.phoneLandline}
                label={t.translate('Landline phone')}
                value={values.phoneLandline}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Grid>
            <Grid item xs={6} className={classes.marginBottom}>
              <InputControl
                id={makeId('phoneMobile')}
                name="phoneMobile"
                error={errors.phoneMobile}
                label={t.translate('Mobile phone')}
                value={values.phoneMobile}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Grid>
            <Grid item xs={6} className={classes.marginBottom}>
              <InputControl
                id={makeId('email')}
                name="email"
                error={errors.email}
                label={t.translate('Mail')}
                value={values.email}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Grid>
            <Grid item xs={6} className={classes.marginBottom}>
              <InputControl
                id={makeId('street')}
                name="address.street"
                error={errors.address?.street}
                label={t.translate('Street')}
                value={values.address.street}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Grid>
            <Grid item xs={6} className={classes.marginBottom}>
              <InputControl
                id={makeId('websiteUrl')}
                name="websiteUrl"
                error={errors.websiteUrl}
                label={t.translate('Website')}
                value={values.websiteUrl}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Grid>
            <Grid item xs={2} className={classes.marginBottom}>
              <InputControl
                id={makeId('zip')}
                name="address.zip"
                error={errors.address?.zip}
                label={t.translate('Zip code')}
                value={values.address.zip}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Grid>
            <Grid item xs={4} className={classes.marginBottom}>
              <InputControl
                id={makeId('city')}
                name="address.city"
                error={errors.address?.city}
                label={t.translate('City')}
                value={values.address.city}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Grid>
            <Grid container item xs={12} md={9} className={classes.containerWrap} spacing={1}>
              <Grid item xs={8} className={classes.marginBottom}>
                  <FormControl fullWidth>
                    <InputLabel shrink htmlFor="priceType">{t.translate('Country')}</InputLabel>
                    <Select
                      id={makeId('country')}
                      name="address.country"
                      value={values.address?.country}
                      error={errors.address?.country}
                      onChange={e => setFieldValue('address.country', e.target.value)}
                      disabled={!isEditMode}
                    >
                      {countries.map(({ countryCode }) => (
                        <MenuItem key={countryCode} value={countryCode}>{t.translate(`country:${countryCode}`)}</MenuItem>
                      ))}
                    </Select>
                    {!!errors.address?.country && <FormControlError error={errors.address?.country} />}
                  </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className={classes.containerWrap}>
            <Grid container item xs={6}>
              <Typography variant="h6" className={classes.marginBottom}>{t.translate('Logo')}</Typography>
            </Grid>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Picture image={values.uploadedFile || values.logo} className={classes.photo}/>
              </Grid>
              <Grid item>
                <label htmlFor="company--logo">
                  <Input
                    id="company--logo"
                    onChange={handleFileChange}
                    type="file"
                    accept="image/*"
                    required
                    style={{ display: 'none' }}
                  />
                  {isEditMode && (
                    <Button color="secondary" variant="contained" component="span" size="small">
                      {t.translate('Select logo')}
                    </Button>
                  )}
                  {!!errors.uploadedFile && <FormControlError error={errors.uploadedFile} />}
                </label>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid container item xs={12} md={6} className={classes.containerWrap} spacing={1}>
            <Grid container item xs={12} md={9}>
              <Typography variant="h6" className={classes.marginBottom}>{t.translate('Additional general details')}</Typography>
            </Grid>
            <Grid item xs={6} className={classes.marginBottom}>
              <InputControl
                id={makeId('documentOwner')}
                name="documentOwner"
                error={errors.documentOwner}
                label={t.translate('Owner / Board')}
                value={values.documentOwner}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Grid>
            <Grid item xs={6} className={classes.marginBottom}>
              <InputControl
                id={makeId('documentSeatAndRegister')}
                name="documentSeatAndRegister"
                error={errors.documentSeatAndRegister}
                label={t.translate('Seat and register')}
                value={values.documentSeatAndRegister}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Grid>
            <Grid item xs={6} className={classes.marginBottom}>
              <InputControl
                id={makeId('documentAdditionalDetails')}
                name="documentAdditionalDetails"
                error={errors.documentAdditionalDetails}
                label={t.translate('Additional details')}
                value={values.documentAdditionalDetails}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};
