import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './AdjustPositionRow.jsx';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';

import {
  StockFormControl,
} from 'components';

const makeName = (id, name) => `${id}.${name}`;

export const AdjustPositionRow = ({
  setCancelAllQuantity,
  handleChange,
  position,
  setFieldValue,
  values,
  errors,
  ...props
}) => {
  const { classes } = useStyles();

  return (
    <>
      <TableRow className={classes.root} >
        <TableCell >{position.product.id}</TableCell>
        <TableCell className={classes.nameCell}>{position.product.details[0]?.name || '—'}</TableCell>
        <TableCell >{position.product.ean || '—'}</TableCell>
        <TableCell align="center">{position.quantity || '—'}</TableCell>
        <TableCell >
          <Grid className={classes.stockFormControl}>
            <StockFormControl
              hideLabels
              name={makeName(position.id, 'addedAmount')}
              actions={['add']}
              stock={0}
              value={values.positions[position.id].addedAmount}
              onChange={(value) => setFieldValue(`positions.${position.id}.addedAmount`, value)}
            />
          </Grid>
        </TableCell>
      </TableRow>
    </>
  );
};

AdjustPositionRow.propTypes = {
  handleChange: PropTypes.func.isRequired,
  position: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    product: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
