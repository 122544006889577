import React from 'react';
import { PickupSheetsTable } from './PickupSheetsTable/PickupSheetsTable';
import { Header } from './Header/Header';
import { Column } from 'layouts';

export const DesktopView = props => (
  <>
    <Header />
    <Column p={3}>
      <PickupSheetsTable {...props} />
    </Column>
  </>
);
