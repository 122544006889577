import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslator } from '../../i18n';
import { CheckboxControl, InputControl } from '../../components';
import { createRawOrder } from 'api/raw-orders';
import toaster from '../../services/toaster';
import { Hint } from '../../views/suppliers/settings/Hint/Hint';

export const RawOrderDialog = ({
  onClose,
  id,
  ...props
}) => {
  const t = useTranslator();
  const [isCancelledOrder, setIsCancelledOrder] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const [error, setError] = useState(null);

  const onSubmit = async () => {
    if (!isCancelledOrder && !orderNumber) {
      setError(t.translate('Order number is required.'));
    } else {
      try {
        await createRawOrder(id, {
          ...(!isCancelledOrder && { manualOutboundOrderNumber: orderNumber }),
        });
        toaster.success(t.translate('Order has been created successfully.'));
        onClose();
      } catch (err) {
        toaster.error(err.message ?? Object.values(err.collection)[0][0]);
      }
    }
  };

  return (
    <Dialog
      scroll="body"
      {...props}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{t.translate('Process stucked order')}</DialogTitle>
      <DialogContent>
        <Grid container>
          <CheckboxControl
            id={'isCancelledOrder'}
            label={t.translate('Cancel order')}
            checked={isCancelledOrder}
            onChange={({ target: { checked } }) => {
              if (error) {
                setError(null);
              }
              setIsCancelledOrder(checked);
            }}
          />
          <Hint text={t.translate('orders:processStuckOrder-hint')}/>
        </Grid>
        <Grid mt={2} >
          <InputControl
            disabled={isCancelledOrder}
            id={'order-number'}
            error={error}
            label={t.translate('Order number')}
            value={orderNumber}
            placeholder={t.translate('Reference number of the order')}
            onChange={({ target: { value } }) => {
              if (error) {
                setError(null);
              }
              setOrderNumber(value);
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
        <Button color="primary" onClick={onSubmit}>{t.translate('Create order')}</Button>
      </DialogActions>
    </Dialog>
  );
};
