import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { styleTableRow } from 'theme/mixins/table';

const useStyles = makeStyles()(theme => styleTableRow(theme, {
  button: {
    color: theme.palette.primary.l900,
  },
}));

export const StorageDetails = ({ storage, stock }) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <Paper>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell name="id">{t.translate('Building')}</TableCell>
              <TableCell name="name">{t.translate('Floor')}</TableCell>
              <TableCell name="street">{t.translate('Room')}</TableCell>
              <TableCell name="house">{t.translate('Row')}</TableCell>
              <TableCell name="zip">{t.translate('Shelf')}</TableCell>
              <TableCell name="city">{t.translate('Level')}</TableCell>
              <TableCell name="country">{t.translate('Box no.')}</TableCell>
              <TableCell name="country">{t.translate('Box color')}</TableCell>
              <TableCell>{t.translate('Total stock')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{storage.building}</TableCell>
              <TableCell>{storage.floor}</TableCell>
              <TableCell>{storage.room}</TableCell>
              <TableCell>{storage.row}</TableCell>
              <TableCell>{storage.shelf}</TableCell>
              <TableCell>{storage.level}</TableCell>
              <TableCell>{storage.boxNumber}</TableCell>
              <TableCell>{storage.boxColor}</TableCell>
              <TableCell>{stock}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

StorageDetails.propTypes = {
  storage: PropTypes.shape({
    building: PropTypes.string,
    floor: PropTypes.string,
    room: PropTypes.string,
    row: PropTypes.string,
    shelf: PropTypes.string,
    level: PropTypes.string,
    boxNumber: PropTypes.string,
    boxColor: PropTypes.string,
  }),
  stock: PropTypes.number,
};
