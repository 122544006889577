import { table } from 'config';

export const machinizePagination = ({
  rowsPerPage = table.rowsPerPage,
  page = 0,
  offset,
  limit,
}) => ({
  offset: offset ?? rowsPerPage * page,
  limit: limit ?? rowsPerPage,
});

export const extractSorting = ({
  orderBy = table.orderBy,
  order = table.order,
}) => ({
  orderBy,
  order,
});

export const extractListParams = ({
  searchQuery,
  meta,
  handlingType,
  ...params
}) => ({
  ...machinizePagination(params),
  ...extractSorting(params),
  ...(searchQuery && { searchQuery }),
  ...(meta && { meta }),
  ...(handlingType && { handlingType }),
});

export const findRelatedProductOrders = (pickupSheetProduct, orders = []) => {
  if (orders && pickupSheetProduct?.product?.id) {
    return orders.reduce((productOrders, order) => {
      const relatedOrder = order?.positions?.find(({ productId }) => {
        return productId === pickupSheetProduct.product.id && order.supplierId === pickupSheetProduct.supplierId;
      });

      if (relatedOrder) {
        productOrders.push(order);
      }

      return productOrders;
    }, []);
  }
};
