import api from '.';
import { extractListParams } from 'utils/querying';

export const fetchOrders = ({
  status,
  supplierNumber,
  type,
  pickupSheet,
  orderedAtSupplier,
  omitCancelledPositions,
  isOverdue,
  ...params
}) => api.get('/orders', {
  params: {
    ...extractListParams(params),
    ...(status && { status }),
    ...(supplierNumber && { supplierNumber }),
    ...(type && { type }),
    ...(pickupSheet && { pickupSheet }),
    ...(orderedAtSupplier && { orderedAtSupplier }),
    ...(omitCancelledPositions && { omitCancelledPositions }),
    ...(isOverdue && { isOverdue }),
  },
});

export const fetchOrder = orderId => api.get(`/orders/${orderId}`);

export const fetchInboundStockPositions = () => api.get('/orders/inbound-stock');

export const modifyOrder = (orderId, payload) => api.put(`/orders/${orderId}`, payload);

export const cancelOrder = (orderId, payload) => api.put(`/orders/${orderId};cancel`, payload);

export const cancelOrderPosition = (orderId, payload) => api.put(`/orders/${orderId};cancelledOrderPosition`, payload);

export const approveOrder = orderId => api.put(`/orders/${orderId};approved`);

export const fetchB2BOrder = orderCode => api.get(`/orders/b2b/${orderCode}`);

export const fetchB2BOrderSupplier = orderCode => api.get(`/orders/b2b/${orderCode}/supplier`);

export const fetchOrderStatuses = ({ searchQuery } = {}) => api.get('/orders/statuses', searchQuery && { params: { searchQuery } });

export const fetchLatestOrders = () => api.patch(`/orders/latest`, null);

export const updateOutboundStatus = orderId => api.post(`/orders/${orderId};update-outbound-status`);

export const returnOrder = (orderId, payload) => api.put(`/orders/${orderId};return`, payload);

export const markAsReturnWithoutStock = orderReturnId => api.put(`/orders/returns/${orderReturnId}`);

export default {
  fetchB2BOneSupplier: fetchB2BOrderSupplier,
  fetchManyStatuses: fetchOrderStatuses,
  cancelOrderPosition,
  fetchB2BOne: fetchB2BOrder,
  approveOne: approveOrder,
  fetchMany: fetchOrders,
  modifyOne: modifyOrder,
  cancelOne: cancelOrder,
  fetchOne: fetchOrder,
  fetchLatestOrders,
  fetchInboundStockPositions,
  returnOrder,
  updateOutboundStatus,
  markAsReturnWithoutStock,
};
