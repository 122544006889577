import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';
import { useLoading } from 'hooks';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import { ActionButton } from 'components';
import { approveOrder } from 'api/orders';
import toaster from 'services/toaster';

export const ApprovingButton = ({
  onApprove,
  order,
  ...props
}) => {
  const t = useTranslator();
  const loading = useLoading();

  const approve = loading.lock(async () => {
    try {
      await approveOrder(order.id);
      toaster.success('The order has been approved successfully.');
      onApprove();
    } catch (err) {}
  });

  const Icon = loading.progress
    ? <CircularProgress color="inherit" size={24} />
    : <CheckCircleIcon />;

  return (
    <ActionButton
      aria-label={t.translate('Approve the order')}
      tooltip={t.translate('Approve')}
      {...props}
      icon={Icon}
      onClick={approve}
    />
  );
};

ApprovingButton.propTypes = {
  onApprove: PropTypes.func.isRequired,
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
