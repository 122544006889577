import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useList } from 'hooks';

import Grid from '@mui/material/Grid';
import { StorageItem } from './StorageItem/StorageItem';
import { Pagination } from 'components';
import { SortBar } from '../common';
import { Header } from './Header/Header';
import { Column } from 'layouts';
import tables from 'config/tables';

export const MobileView = ({
  storagesMetadata,
  storages,
  onStorageReassign,
  onStoragesExport,
  onStoragesImport,
  canExportStorage,
  canReassignStock,
  onStoragesPrint,
  onTabChange,
  ...props
}) => {
  const list = useList(tables.storages.storages);

  const [isSortBarVisible, setIsSortBarVisible] = useState(false);

  return (
    <>
      <Header
        onStorageReassign={onStorageReassign}
        onStoragesPrint={onStoragesPrint}
        onStoragesExport={onStoragesExport}
        onStoragesImport={onStoragesImport}
        canExportStorage={canExportStorage}
        canReassignStock={canReassignStock}
        onTabChange={onTabChange}
        onSortButtonClick={() => setIsSortBarVisible(!isSortBarVisible)} />
      {isSortBarVisible && <SortBar />}
      <Column p={1} pt={isSortBarVisible ? 7 : 1}>
        <Grid container direction="column" spacing={1}>
          {storages.map(storage => (
            <StorageItem
              key={storage.id}
              storage={storage}
              {...props}
            />
          ))}
          <Grid item>
            <Pagination {...list.paginationProps} count={storagesMetadata.totalEntities} />
          </Grid>
        </Grid>
      </Column>
    </>
  );
};

MobileView.propTypes = {
  storagesMetadata: PropTypes.shape({
    totalEntities: PropTypes.number.isRequired,
  }).isRequired,
  storages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  onStorageReassign: PropTypes.func.isRequired,
  onStoragesExport: PropTypes.func.isRequired,
  onStoragesImport: PropTypes.func.isRequired,
  canExportStorage: PropTypes.bool.isRequired,
  canReassignStock: PropTypes.bool.isRequired,
  onStoragesPrint: PropTypes.func.isRequired,
};
