import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslator } from 'i18n';
import { useScreen } from 'hooks';

import { makeStyles } from 'tss-react/mui';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import {
  Header,
  ShippingTable,
  OrdersTable,
  TotalTable,
  ProductFunnelTable,
  StorageUsageTable,
  WarehouseFeesTable,
} from 'views/dashboard';

import {
  AmountTable,
} from 'views/dashboard/common';

import {
  AlzuraSpriteIcon,
  CircularLoader,
} from 'components';

import {
  selectStatistics,
  selectProductFunnel,
} from 'store/selectors/dashboard';
import { isLoading } from 'store/selectors/app';
import { fetchStatistics } from 'services/dashboard';
import { fetchWarehouses } from 'api/warehouses';

const useStyles = makeStyles()(theme => ({
  root: {
    margin: 0,
    width: '100%',
    paddingRight: theme.spacing(2),
    flexWrap: 'wrap',
  },
  cardContainer: {
    height: '100%',
  },
}));

export const Dashboard = () => {
  const dispatch = useDispatch();
  const [warehouses, setWarehouses] = useState([]);
  const { classes } = useStyles();
  const t = useTranslator();
  const screen = useScreen();

  const {
    pickupSheets,
    packages,
    orders,
    customers,
    products,
    stock,
    storageUsage,
    warehouseFees,
  } = useSelector(selectStatistics);
  const productFunnel = useSelector(selectProductFunnel);
  const isStatisticsLoading = useSelector(isLoading('dashboard.statistics'));

  useEffect(() => {
    void dispatch(fetchStatistics());
  }, [dispatch]);

  useEffect(() => {
    fetchWarehouses({ orderBy: 'id', order: 'asc' })
      .then((data) => {
        setWarehouses(data.data.map(warehouse => ({
          id: warehouse.id,
          name: warehouse.name,
        })));
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>{t.translate('Dashboard')} | {t.translate('app:title')}</title>
      </Helmet>

      <Header isMobile={screen.isMobile} />

      {isStatisticsLoading ? (
        <CircularLoader />
      ) : (
        <Grid container className={classes.root} spacing={2}>
          <Grid xs={12} md={4} lg={4} item>
            <Card className={classes.cardContainer}>
              <AmountTable
                icon={<AssessmentIcon />}
                title="Customers"
                data={customers}
              />
            </Card>
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <Card className={classes.cardContainer}>
              <AmountTable
                icon={<AlzuraSpriteIcon id='assessment'/>}
                title="Packages"
                data={packages}
              />
            </Card>
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <Card className={classes.cardContainer}>
              <ShippingTable pickupSheets={pickupSheets} />
            </Card>
          </Grid>
          <Grid xs={12} lg={6} item>
            <Card className={classes.cardContainer}>
              <OrdersTable orders={orders} />
            </Card>
          </Grid>
          <Grid xs={12} lg={6} item>
            <Card className={classes.cardContainer}>
              <TotalTable orders={orders} />
            </Card>
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <Card className={classes.cardContainer}>
              <AmountTable
                icon={<AssessmentIcon />}
                title="Products created"
                data={products}
              />
            </Card>
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <Card className={classes.cardContainer}>
              <AmountTable
                icon={<AssessmentIcon />}
                title="Stock added"
                data={stock}
              />
            </Card>
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <Card className={classes.cardContainer}>
              <ProductFunnelTable
                productFunnel={productFunnel}
              />
            </Card>
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <Card className={classes.cardContainer}>
              <StorageUsageTable
                storageUsage={storageUsage}
                warehouses={warehouses}
              />
            </Card>
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <Card className={classes.cardContainer}>
              <WarehouseFeesTable
                warehouseFees={warehouseFees}
                warehouses={warehouses}
              />
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};
