import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';
import { useLoading } from 'hooks';

import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';

import { approveOrder } from 'api/orders';
import toaster from 'services/toaster';

import { OrderStatuses } from 'consts';

const useStyles = makeStyles()({
  iconButton: {
    padding: 0,
  },
});

export const MobileControls = ({
  canApproveOrder,
  onOrderApprove,
  canEditOrder,
  isOrderHasReturns,
  onOrderAdjust,
  canPerformOrderActions,
  onOrderCancel,
  order,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const loading = useLoading();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (action) => {
    setAnchorEl(null);
    if (action) {
      action();
    }
  };

  const approve = loading.lock(async () => {
    try {
      await approveOrder(order.id);
      toaster.success('The order has been approved successfully.');
      onOrderApprove();
    } catch (err) { }
  });

  return <>
    <IconButton
      color="inherit"
      aria-label="more"
      aria-controls="long-menu"
      aria-haspopup="true"
      onClick={handleClick}
      className={classes.iconButton}
      size="large">
      <MoreVertIcon />
    </IconButton>
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => handleClose()}
      PaperProps={{
        style: {
          width: '20ch',
        },
      }}
    >
      {order.inReview && canApproveOrder && (
        <MenuItem onClick={() => handleClose(approve)}>
          <ListItemIcon>
            {loading.progress
              ? <CircularProgress color="inherit" size={24} />
              : <CheckCircleIcon />
            }
          </ListItemIcon>
          <Typography variant="inherit">{t.translate('Approve')}</Typography>
        </MenuItem>
      )}
      {order.statusCode === OrderStatuses.QUEUE && canEditOrder && !order.orderedAtSupplier && (
        <MenuItem onClick={() => handleClose(onOrderAdjust)}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t.translate('Adjust')}</Typography>
        </MenuItem>
      )}
      {((order.statusCode === OrderStatuses.QUEUE || order.statusCode === OrderStatuses.CLOSED) && canPerformOrderActions)
      && !order.orderedAtSupplier
      && !isOrderHasReturns
      && (
        <MenuItem onClick={() => handleClose(onOrderCancel)}>
          <ListItemIcon>
            <CancelIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t.translate('Cancel')}</Typography>
        </MenuItem>
      )}
    </Menu>
  </>;
};

MobileControls.propTypes = {
  canApproveOrder: PropTypes.bool.isRequired,
  onOrderApprove: PropTypes.func.isRequired,
  canEditOrder: PropTypes.bool.isRequired,
  isOrderHasReturns: PropTypes.bool,
  onOrderAdjust: PropTypes.func.isRequired,
  canPerformOrderActions: PropTypes.bool.isRequired,
  onOrderCancel: PropTypes.func.isRequired,
  order: PropTypes.object,
};
