import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useList } from 'hooks/list';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { Column } from 'layouts';
import { CircularLoader, Pagination } from 'components';

import { CustomerItem } from './CustomerItem/CustomerItem';

import tables from 'config/tables';

export const MobileView = ({ loading, customers, meta }) => {
  const history = useHistory();
  const list = useList(tables.customers.customers);

  const openCustomer = customer => history.push(`customers/${customer.id}`);

  return (
    <Column p={1} pt={2}>
      {loading ? (
        <CircularLoader />
      ) : (
        <Grid container direction="column">
          {customers.map(customer => (
            <CustomerItem
              key={customer.id}
              customer={customer}
              onOpen={openCustomer}
            />
          ))}
          <Paper>
            <Pagination
              {...list.paginationProps}
              count={meta.totalEntities}
              direction="row"
            />
          </Paper>
        </Grid>
      )}
    </Column>
  );
};

MobileView.propTypes = {
  loading: PropTypes.bool.isRequired,
  customers: PropTypes.array,
  meta: PropTypes.shape({
    totalEntities: PropTypes.number.isRequired,
  }).isRequired,
};
