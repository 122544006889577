import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Tabs as MaterialTabs } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';

import { useTranslator } from 'i18n';

const useStyles = makeStyles()(theme => ({
  indicator: {
    backgroundColor: theme.palette.common.white,
  },
}));

export const Tabs = ({
  onChange,
  initialValue,
  items,
  appBar,
  tabs,
  tab,
  hideLabel,
  currentValue,
}) => {
  const { classes } = useStyles();
  const t = useTranslator();

  const [value, setValue] = useState(initialValue);

  const change = (e, value) => {
    setValue(value);
    onChange(value);
  };

  return (
    <AppBar
      position="static"
      {...appBar}
    >
      <MaterialTabs
        aria-label={t.translate('Scrollable tab bar')}
        indicatorColor="primary"
        textColor="inherit"
        variant="scrollable"
        scrollButtons="auto"
        value={currentValue || value}
        onChange={change}
        classes={{ indicator: classes.indicator }}
        TabIndicatorProps={{ children: <span /> }}
        {...tabs}
      >
        {items.map(item => (
          <Tab
            key={item.name}
            value={item.name}
            label={hideLabel || (item.label || null)}
            id={`scrollable-auto-tab-${item.name}`}
            aria-controls={`scrollable-auto-tabpanel-${item.name}`}
            iconPosition="start"
            icon={item.Icon || null}
            {...tab}
          />
        ))}
      </MaterialTabs>
    </AppBar>
  );
};

Tabs.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      label: PropTypes.any.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  appBar: PropTypes.object,
  tabs: PropTypes.object,
  tab: PropTypes.object,
  hideLabel: PropTypes.bool,
  currentValue: PropTypes.string,
};
