import React, { memo, useEffect, useState } from 'react';
import {
  Dialog,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  areEqual,
  FixedSizeList,
} from 'react-window';
import groupBy from 'lodash/groupBy';
import { makeStyles } from 'tss-react/mui';
import { useTranslator } from '../../i18n';
import { DialogActions, DialogTitle, DialogContent, Button } from '@mui/material';
import { fetchPausedReplenishmentSuggestions } from 'api/replenishment-suggestions';
import { CircularLoader } from 'components';

const useStyles = makeStyles()(theme => ({
  table: {
    height: '100%',
    width: '100%',
  },
  cell: {
    display: 'flex',
    flexBasis: '10%',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    border: 'none',
    flexShrink: 0,
    minWidth: 0,
    overflow: 'hidden',
    '&::nth-of-type(1)': {
      flexBasis: '5%',
    },
    '&::nth-of-type(2)': {
      flexBasis: '15%',
    },
    '&::nth-of-type(6)': {
      flexBasis: '20%',
    },
    '&::nth-of-type(7)': {
      flexBasis: '15%',
    },
  },
  numberRow: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    backgroundColor: theme.palette.backgrounds.grey[240],
  },
  orderHeader: {
    width: '100%', fontWeight: 500,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    boxSizing: 'border-box',
    minWidth: '100%',
    width: '100%',
    justifyContent: 'space-between',
    boxShadow: '0 -1px 0 rgb(224, 224, 224)',
  },
}));

const Row = memo(({ index, style, data }) => {
  const {
    t,
    supplierSuggestions,
    classes,
    cellProps,
  } = data;
  const suggestion = supplierSuggestions[index];
  return (
    <TableRow
      style={style}
      key={suggestion.id}
      component="div"
      className={classes.row}
    >
      <TableCell {...cellProps}>{suggestion.product.id}</TableCell>
      <TableCell {...cellProps}>{suggestion.product.alzuraId}</TableCell>
      <TableCell {...cellProps}>{suggestion.product.manufacturerNumber}</TableCell>
      <TableCell {...cellProps}>{suggestion.product.manufacturerName}</TableCell>
      <TableCell {...cellProps}>{suggestion.product.ean}</TableCell>
      <TableCell {...cellProps}>{suggestion.product.details[0]?.name}</TableCell>
      <TableCell {...cellProps}>{t.formatDate(suggestion.creationPausedUntil)}</TableCell>
    </TableRow>);
}, areEqual);

export const PausedReplenishmentSuggestionsDialog = ({
  isMobile,
  onClose,
  scrollWidth,
  ...props
}) => {
  const { classes } = useStyles();
  const t = useTranslator();
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const cellProps = {
    component: 'div',
    className: classes.cell,
  };

  useEffect(() => {
    fetchPausedReplenishmentSuggestions()
      .then(res => {
        setSuggestions(res);
        setIsLoading(false);
      });
  }, []);

  const groupedSuggestions = groupBy(suggestions, 'supplierId');

  return (
    <Dialog
      open={props.open}
      fullWidth
      fullScreen={isMobile}
      scroll="body"
      maxWidth="lg"
      onClose={onClose}
    >
      <DialogTitle>
        {t.translate('Paused replenishment suggestions')}
      </DialogTitle>
      <DialogContent>
        {isLoading
          ? <CircularLoader style={{ margin: 50 }} />
          : (<Grid container direction="column" className={classes.container}>
              <TableContainer>
                <Table className={classes.table} component="div">
                  <TableHead component="div">
                    <TableRow component="div" className={classes.row} style={{ paddingRight: `${scrollWidth}px` }}>
                      <TableCell {...cellProps}>{t.translate('Product ID')}</TableCell>
                      <TableCell {...cellProps}>{t.translate('Alzura ID')}</TableCell>
                      <TableCell {...cellProps}>{t.translate('Manufacturer no.')}</TableCell>
                      <TableCell {...cellProps}>{t.translate('Manufacturer name')}</TableCell>
                      <TableCell {...cellProps}>EAN</TableCell>
                      <TableCell {...cellProps}>{t.translate('Product name')}</TableCell>
                      <TableCell {...cellProps}>{t.translate('Paused until')}</TableCell>
                    </TableRow>
                  </TableHead>
                  {Object.keys(groupedSuggestions).map((supplierId, index) => {
                    const supplierSuggestions = groupedSuggestions[supplierId];

                    return (
                      <TableBody component="div" key={index}>
                        <TableRow component="div" className={classes.numberRow}>
                          <TableCell component="div" colSpan={7} align="center" className={classes.orderHeader}>
                            <div>{supplierSuggestions[0].supplier.number} - {supplierSuggestions[0].supplier.name}</div>
                          </TableCell>
                        </TableRow>
                        <FixedSizeList
                          style={{ scrollbarGutter: 'stable' }}
                          height={Math.min(59 * supplierSuggestions.length, 600)} // 59 - height of one row
                          itemSize={59}
                          itemCount={supplierSuggestions.length}
                          overscanCount={5}
                          itemData={{
                            supplierSuggestions,
                            classes,
                            cellProps,
                            t,
                          }}
                        >
                          {Row}
                        </FixedSizeList>
                      </TableBody>
                    );
                  })}
                </Table>
              </TableContainer>
            </Grid>)}
      </DialogContent>
      <DialogActions className={classes.button}>
        <Button color="primary" onClick={onClose}>
          {t.translate('dialog-action:Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
