import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useList } from 'hooks';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import tables from 'config/tables';
import { styleTableRow } from 'theme/mixins/table';
import { ChangesHistoryTable } from '../../../../changes-history/DesktopView/ChangesHistoryTable/ChangesHistoryTable';
import { fetchChangesHistory } from '../../../../../services/changes-history';
import { useTranslator } from '../../../../../i18n';

const useStyles = makeStyles()(theme => styleTableRow(theme, {
  root: {
    marginBottom: theme.spacing(10),
  },
  cardLabel: {
    color: theme.palette.text.itemHeadline,
    padding: theme.spacing(1),
  },
}));

export const ProductChangesHistoryTable = ({
  product,
}) => {
  const { classes } = useStyles();
  const t = useTranslator();
  const dispatch = useDispatch();
  const list = useList('changesHistory', tables.changesHistory.changesHistory);

  const fetchData = useCallback(() => dispatch(fetchChangesHistory({
    ...list.compile(),
    reference: `product:${product?.id}`,
  })), [dispatch, list, product]);

  useEffect(() => void fetchData(), [fetchData]);

  return (
    <Grid item className={classes.root} xs={12} md={6}>
      <Grid container justifyContent="space-between">
        <Typography className={classes.cardLabel} variant="body2">{t.translate('Changes history')}</Typography>
      </Grid>
      <ChangesHistoryTable isDetailsTable={true} parentList={list}/>
    </Grid>
  );
};
