import { DASHBOARD } from 'store/types';
import { dashboard as is } from 'store/initialState';

const setStatistics = (state, statistics) => ({ ...state, statistics });

const setProductFunnel = (state, productFunnel) => ({ ...state, productFunnel });

export default (state = is, { type, payload }) => {
  switch (type) {
    case DASHBOARD.SET_STATISTICS:
      return setStatistics(state, payload);
    case DASHBOARD.SET_PRODUCT_FUNNEL:
      return setProductFunnel(state, payload);
    default:
      return state;
  }
};
