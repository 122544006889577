import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslator } from 'i18n';
import {
  useTableSortableHeadCell,
} from 'hooks';
import CheckIcon from '@mui/icons-material/Check';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import TableContainer from '@mui/material/TableContainer';
import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import { FiltersRow } from './FiltersRow/FiltersRow';
import {
  Pagination,
  TableCell,
  TableRow,
  Table,
} from 'components';
import { fetchProducts } from 'services/products';
import toaster from 'services/toaster';

const useStyles = makeStyles()(theme => ({
  table: {
    '& th:first-of-type, & td:first-of-type': {
      paddingLeft: theme.spacing(3),
    },
    '& th:last-child, & td:last-child': {
      paddingRight: theme.spacing(3),
    },
  },
  text: {
    wordBreak: 'break-all',
  },
  button: {
    color: theme.palette.primary.l900,
  },
}));

export const ProductsTable = ({
  selectedProductIds,
  onSelectProduct,
  onDeselectProduct,
  deliveredProductIds,
  supplierId,
}) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const t = useTranslator();

  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 10,
    orderBy: 'createdAt',
    order: 'desc',
  });

  const [selected, setSelected] = useState(selectedProductIds);

  const [filters, setFilters] = useState({
    id: '',
    manufacturerNumber: '',
    manufacturerName: '',
    name: '',
    ean: '',
  });

  const fetchData = useCallback(() => dispatch(fetchProducts({
    ...pagination,
    supplierId,
    ...filters,
  })), [dispatch, filters, supplierId, pagination]);
  useEffect(() => void fetchData(), [fetchData]);

  const totalEntities = useSelector(({ products }) => products.products.meta.totalEntities);
  const products = useSelector(({ products }) => products.products.data);

  const paginationHandlers = {
    page: (event, value) => setPagination({ ...pagination, page: value }),
    rowsPerPage: (value) => setPagination({ ...pagination, rowsPerPage: value }),
    changeSorting: ({ orderBy, order }) => setPagination({ ...pagination, order, orderBy }),
  };

  const SortableCell = useTableSortableHeadCell({
    changeSorting: paginationHandlers.changeSorting,
    sorting: {
      orderBy: pagination.orderBy,
      order: pagination.order,
    },
  });

  const onClickAdd = (product) => {
    setSelected((selected) => selected.concat(product.id));
    onSelectProduct && onSelectProduct(product);
    toaster.info(t.translate('New position has been added.'));
  };

  const onClickRemove = (product) => {
    setSelected((selected) => {
      selected.splice(selected.indexOf(product.id), 1);
      return selected;
    });
    onDeselectProduct && onDeselectProduct(product);
    toaster.info(t.translate('Position has been removed.'));
  };

  const getButtonForProduct = (product) => {
    if (selected.includes(product.id)) {
      return (
        <IconButton
          variant="contained"
          onClick={() => onClickRemove(product)}
          aria-label="Deselect product"
          disabled={!!deliveredProductIds.find((id) => id === product.id)}
          size="large">
          <RemoveCircleOutlineIcon/>
        </IconButton>
      );
    }
    return (
      <IconButton
        variant="contained"
        onClick={() => onClickAdd(product)}
        aria-label="Select product"
        size="large">
        <CheckIcon/>
      </IconButton>
    );
  };

  return (
    <Paper>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell/>
              <SortableCell name="id">{t.translate('Product ID')}</SortableCell>
              <SortableCell name="manufacturerNumber">{t.translate('Manufacturer no.')}</SortableCell>
              <SortableCell name="manufacturerName">{t.translate('Manufacturer')}</SortableCell>
              <SortableCell name="name">{t.translate('Product name')}</SortableCell>
              <SortableCell name="ean">EAN</SortableCell>
              <TableCell width={140} align="right">
                {t.translate('Actions')}
              </TableCell>
            </TableRow>
            <FiltersRow filters={filters} setFilters={setFilters}/>
          </TableHead>
          <TableBody>
            {products.map((product, index) => (
              <TableRow key={index}>
                <TableCell/>
                <TableCell>{product.id}</TableCell>
                <TableCell>{product.manufacturerNumber || '—'}</TableCell>
                <TableCell>{product.manufacturerName || '—'}</TableCell>
                <TableCell>{product.details[0]?.name || '—'}</TableCell>
                <TableCell>{product.ean || '—'}</TableCell>
                <TableCell align="right">
                  {getButtonForProduct(product)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        onRowsPerPageChange={paginationHandlers.rowsPerPage}
        onPageChange={paginationHandlers.page}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.page}
        count={totalEntities}
      />
    </Paper>
  );
};

ProductsTable.propTypes = {
  selectedProductIds: PropTypes.array,
  onSelectProduct: PropTypes.func,
  onDeselectProduct: PropTypes.func,
};
