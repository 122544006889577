import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import { Column } from 'layouts';
import { UserCard } from '../common';
import { Warehouses } from './Warehouses/Warehouses';
import { Permissions } from './Permissions/Permissions';

const useStyles = makeStyles()(theme => ({
  item: {
    padding: theme.spacing(0, 3, 3, 3),
    '&:first-of-type': {
      paddingTop: theme.spacing(3),
    },
  },
}));

export const DesktopView = ({
  onWarehouseAvailabilityChange,
  onUserEdit,
  onSave,
}) => {
  const { classes } = useStyles();

  const user = useSelector(({ users }) => users.details.user);

  if (user) {
    return (
      <Column filled>
        <Grid container direction="column">
          <Grid item className={classes.item}>
            <UserCard onUserEdit={onUserEdit} onSave={onSave} />
          </Grid>
          <Grid item className={classes.item}>
            <Permissions />
          </Grid>
          <Grid item className={classes.item}>
            <Warehouses onAvailabilityChange={onWarehouseAvailabilityChange} />
          </Grid>
        </Grid>
      </Column>
    );
  } else {
    return <Column filled />;
  }
};

DesktopView.propTypes = {
  onUserEdit: PropTypes.func.isRequired,
};
