import numeral from 'numeral';
import dayjs from 'dayjs';
import {
  createIntlCache,
  createIntl,
  useIntl,
} from 'react-intl';

const DEFAULT_LOCALE = 'en';

class Translator {
  constructor () {
    if (this.constructor === Translator) {
      throw new TypeError('Abstract class "Translator" cannot be instantiated directly.');
    }
  }

  translate (id, values) {
    return this.intl.formatMessage({ id }, values);
  }

  formatDate (date, options = {}) {
    const representation = this.intl.locale === 'en' ? 'numeric' : '2-digit';
    return this.intl.formatDate(date, {
      month: representation,
      year: 'numeric',
      day: representation,
      ...options,
    });
  }

  formatManufacturingDate (date) {
    return dayjs(date).format('WW/YY');
  }

  formatTime (time, options = {}) {
    const formattedTime = this.intl.formatTime(time, {
      minute: '2-digit',
      hour: 'numeric',
      ...options,
    });
    return formattedTime + (this.intl.locale === 'de' ? ' Uhr' : '');
  }

  formatPrice (price) {
    const value = typeof price === 'string' && !isNaN(price) ? Number(price) : price;
    return numeral(value).format('0,0.00');
  }

  parseFloatMultiply (price, multiplier = 1, delimiter = 2) {
    return Number((Number(price) * multiplier).toFixed(delimiter));
  }

  formatDecimalToPercentage (decimal, precision = 2) {
    return this.formatPrice(this.parseFloatMultiply(decimal, 100, precision));
  }

  parsePercentage (percentage, precision = 2) {
    return this.parseFloatMultiply(numeral(percentage).value(), 1 / 100, precision);
  }

  parsePrice (price) {
    return numeral(price).value();
  }

  get locale () {
    return this.intl.locale;
  }

  isValidPrice (price) {
    const parsedPrice = this.parsePrice(price);
    return parsedPrice !== null && !isNaN(parsedPrice);
  }
}

export class VanillaTranslator extends Translator {
  constructor (options) {
    options = options || { locale: DEFAULT_LOCALE };
    super();
    this.intl = createIntl(options, createIntlCache());
  }
}

export class ReactTranslator extends Translator {
  constructor () {
    super();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    this.intl = useIntl();
  }
}

export default Translator;
