import React from 'react';
import { Helmet } from 'react-helmet-async';

import { makeStyles } from 'tss-react/mui';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useScreen } from 'hooks';
import { useTranslator } from 'i18n';
import { Header } from 'views/forbidden';

const useStyles = makeStyles()(theme => ({
  box: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: theme.spacing(3, 5),
  },
  icon: {
    color: theme.palette.text.disabled,
    fontSize: '8rem',
  },
  text: {
    fontSize: '2rem',
    textAlign: 'center',
  },
}));

export const Forbidden = () => {
  const t = useTranslator();
  const { classes } = useStyles();
  const screen = useScreen();

  return (
    <>
      <Helmet>
        <title>{t.translate('Forbidden')} | {t.translate('app:title')}</title>
      </Helmet>

      <Header isMobile={screen.isMobile}/>

      <Box className={classes.box}>
        <Box display="flex" justifyContent="center">
          <ErrorOutlineIcon className={classes.icon} />
        </Box>
        <Box display="flex" justifyContent="center" mt={3} color="text.disabled">
          <Typography variant="h6" className={classes.text}>{t.translate('You do not have permissions to view this page.')}</Typography>
        </Box>
      </Box>
    </>
  );
};
