import api from '.';
import { extractListParams, extractSorting } from 'utils/querying';

export const fetchProducts = (
  {
    activeOnMarketplaceDe,
    manufacturerNumber,
    manufacturerName,
    manufacturerId,
    superdealPrice,
    generalPrice,
    productTypes,
    medianPrice,
    supplierIds,
    supplierId,
    salesPrice,
    alzuraId,
    groupIds,
    reserved,
    typeId,
    isDot,
    stock,
    name,
    ean,
    id,
    ...params
  },
  options = {}
) => api.get('/products', {
  ...options,
  params: {
    ...extractListParams(params),
    ...(activeOnMarketplaceDe !== undefined && { activeOnMarketplaceDe }),
    ...(manufacturerNumber && { manufacturerNumber }),
    ...(manufacturerName && { manufacturerName }),
    ...(superdealPrice && { superdealPrice }),
    ...(generalPrice && { generalPrice }),
    ...(medianPrice && { medianPrice }),
    ...(salesPrice && { salesPrice }),
    ...(reserved && { reserved }),
    ...(stock && { stock }),
    ...(name && { name }),
    ...(ean && { ean }),
    ...(supplierIds && { supplierIds }),
    ...(alzuraId && { alzuraId }),
    ...(typeId && { typeId }),
    ...(id && { id }),
    ...(productTypes && { productTypes }),
    ...(supplierId && { supplierId }),
    ...(manufacturerId && { manufacturerId }),
    ...(groupIds && { groupIds }),
    ...(isDot && { isDot }),
  },
});

export const fetchProduct = (productId, options) => api.get(`/products/${productId}`, options);

export const fetchProductPrices = (productId, options) => api.get(`/products/${productId}/prices`, {
  params: {
    ...extractSorting(options),
  },
});

export const modifyProductPrices = (productId, payload) => api.put(`/products/${productId}/prices`, payload);

export const createProduct = payload => api.post('/products', payload);

export const modifyProduct = (productId, payload) => api.put(`/products/${productId}`, payload);

export const fetchProductTypes = () => api.get('/products/types');

export const fetchB2BProducts = ({
  manufacturerNumber,
  manufacturerId,
  ean,
  countryCode,
}, options) => api.get('/products/b2b', {
  ...options,
  params: {
    ...(manufacturerNumber && { manufacturerNumber }),
    ...(manufacturerId && { manufacturerId }),
    ...(ean && { ean }),
    ...(countryCode && { countryCode }),
  },
});

export const setDefaultImage = (productId, payload) => api.put(`/products/${productId};defaultPhoto`, payload);

export const calculateSalesPrice = (payload) => api.post(`/products/calculateSalesPrice`, payload);

export const importProducts = (data) => api.post('/products;import', data, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export const importProductsPurchasePrices = (data) => api.post('/products;import-purchase-prices', data, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export const fetchProductDimensions = ean => api.get(`/products/dimensions`, {
  params: {
    ean,
  },
});

export const reassignOutdatedProducts = (payload) => api.post('/products/reassign-outdated', payload);

export const fetchProductReplenishmentSuggestionsOptions = productId => api.get(`/products/${productId}/replenishment-suggestions`);

export const modifyProductReplenishmentSuggestionsOptions = (productId, payload) => api.put(`/products/${productId}/replenishment-suggestions`, payload);

export default {
  modifyProductPrices: modifyProductPrices,
  fetchProductPrices: fetchProductPrices,
  fetchManyB2BOnes: fetchB2BProducts,
  fetchManyTypes: fetchProductTypes,
  fetchMany: fetchProducts,
  modifyOne: modifyProduct,
  createOne: createProduct,
  fetchOne: fetchProduct,
  calculateSalesPrice,
  setDefaultImage,
  importProducts,
  importProductsPurchasePrices,
  fetchProductDimensions,
  fetchProductReplenishmentSuggestionsOptions,
  modifyProductReplenishmentSuggestionsOptions,
};
