import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslator } from 'i18n';
import {
  useScreen,
  useDialog,
  useList,
} from 'hooks';

import AddIcon from '@mui/icons-material/Add';

import { CreatingStatisticsReportDialog } from 'dialogs';
import { Column } from 'layouts';

import { StatisticsReportsTable } from 'views/statistics-reports/StatisticsReportsTable/StatisticsReportsTable';
import { MobileView } from 'views/statistics-reports/MobileView/MobileView';

import { fetchStatisticsReports } from 'services/reports';

import {
  selectOverviewStatisticsReportsMetadata,
  selectOverviewStatisticsReports,
} from 'store/selectors/reports';

import tables from 'config/tables';
import { Fab } from 'components';

export const StatisticsReportsOverview = () => {
  const list = useList(tables.statistics.reports);
  const location = useLocation();
  const dispatch = useDispatch();
  const screen = useScreen();
  const t = useTranslator();

  const creatingDialog = useDialog();

  const statisticsReportsMetadata = useSelector(selectOverviewStatisticsReportsMetadata);
  const statisticsReports = useSelector(selectOverviewStatisticsReports);

  const fetchData = useCallback(() => dispatch(fetchStatisticsReports(list.compile())), [dispatch, list]);

  useEffect(() => void fetchData(), [fetchData, location.search]);

  return (
    <>
      <Helmet>
        <title>{t.translate('Statistics reports')} | {t.translate('app:title')}</title>
      </Helmet>

      <CreatingStatisticsReportDialog
        {...creatingDialog.props}
        onSave={fetchData}
      />

      <Fab onClick={() => creatingDialog.open()} aria-label="Create statistics report" variant="extended">
        <AddIcon id='assignmentAdd'/>
        {t.translate('Create statistics report')}
      </Fab>

      {screen.isMobile ? (
        <MobileView
          statisticsReportsMetadata={statisticsReportsMetadata}
          statisticsReports={statisticsReports}
        />
      ) : (
        <Column p={3}>
          <StatisticsReportsTable
            statisticsReportsMetadata={statisticsReportsMetadata}
            statisticsReports={statisticsReports}
          />
        </Column>
      )}
    </>
  );
};
