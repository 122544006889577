import React from 'react';
import { makeStyles } from 'tss-react/mui';
import MuiFab from '@mui/material/Fab';

const useStyles = makeStyles()(theme => ({
  root: {
    bottom: theme.spacing(5),
    position: 'fixed',
    right: theme.spacing(5),
    zIndex: theme.zIndex.modal - 1,
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
}));

export const Fab = React.forwardRef(({ children, ...props }, ref) => {
  const { classes, cx } = useStyles();

  return <MuiFab color="secondary" {...props} className={cx(props.className, classes.root)} ref={ref}>{children}</MuiFab>;
});
