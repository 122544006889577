import { useState } from 'react';

export const useDialog = (initialData = {}, initialOptions = {}) => {
  const [visibility, setVisibility] = useState(false);
  const [options, setOptions] = useState(initialOptions);
  const [data, setData] = useState(initialData);

  const patchOptions = newOptions => setOptions({ ...options, ...newOptions });
  const patchData = newData => setData({ ...data, ...newData });
  const patch = (newData, newOptions) => {
    patchOptions(newOptions);
    patchData(newData);
  };

  const reset = () => {
    setOptions(initialOptions);
    setData(initialData);
  };

  const close = () => setVisibility(false);
  const open = (newData, newOptions) => {
    if (newData) {
      patchData(newData);
    }
    if (newOptions) {
      patchOptions(newOptions);
    }
    setVisibility(true);
  };

  return {
    patchOptions,
    setOptions,
    patchData,
    setData,
    patch,
    reset,
    close,
    open,
    visible: visibility,
    options,
    data,
    props: {
      ...data,
      open: visibility,
      onClose: close,
    },
  };
};
