import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 150,
    justifyContent: 'space-between',
    width: 202,
  },
  item: {
    backgroundColor: theme.palette.primary.l50,
    borderRadius: theme.shape.borderRadius,
    height: 36,
  },
}));

export const BurgerImage = props => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(props.className, classes.container)}>
      <div className={classes.item}></div>
      <div className={classes.item}></div>
      <div className={classes.item}></div>
    </div>
  );
};
