import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { JsonView } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { useTranslator } from 'i18n';
import toaster from 'services/toaster';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';

export const JsonViewDialog = ({
  onClose,
  data,
  title,
  ...props
}) => {
  const t = useTranslator();

  const handleCopy = () => {
    navigator.clipboard.writeText(JSON.stringify(data)).then(() => {
      toaster.success(t.translate('Data has been copied.'));
    });
  };

  return (
    <Dialog {...props} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ height: 600 }}>
        <Grid sx={{ overflow: 'auto', height: '100%' }}>
          <JsonView data={data}/>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>{t.translate('Close')}</Button>
        <Button color="primary" onClick={handleCopy}>{t.translate('Copy')}</Button>
      </DialogActions>
    </Dialog>
  );
};
