import { makeStyles } from 'tss-react/mui';
import { styleMobileTableItem } from 'theme/mixins/table';

export const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  root: {
    margin: theme.spacing(1),
    '& .Mui-expanded': {
      margin: 0,
    },
    '& .MuiAccordionSummary-root': {
      borderBottomColor: theme.palette.grey[255],
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
    '& .MuiAccordionDetails-root': {
      padding: theme.spacing(2),
    },
  },
  accordion: {
    background: theme.palette.primary.l10,
    boxShadow: 'none',
    '& .Mui-expanded': {
    },
    '& .MuiCollapse-root': {
      background: theme.palette.common.white,
    },
  },
  bold: {
    fontWeight: 500,
    marginBottom: theme.spacing(.5),
  },
  country: {
    textTransform: 'uppercase',
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  button: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.l10,
    fontSize: '0.75rem',
    textTransform: 'none',
  },
  contentItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  actionButton: {
    color: theme.palette.primary.l900,
  },
  divider: {
    margin: theme.spacing(0, 2),
  },
}));
