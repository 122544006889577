import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useRoute } from '.';
import debounce from 'lodash/debounce';

const createAvailabilityInitialStateGetter = (keys, { route }) => () => keys.reduce(
  (availability, key) => Object.assign(availability, {
    [key]: route.search[key] !== undefined,
  }),
  {}
);

const createValuesInitialStateGetter = (keys, getters, { route }) => () => keys.reduce(
  (values, key) => Object.assign(values, {
    [key]: getters[key](route.search[key]),
  }),
  {}
);

export const useFilters = (getters, { manual, flags = [] }) => {
  const keys = Object.keys(getters);

  const route = useRoute();

  const [availability, setAvailability] = useState(createAvailabilityInitialStateGetter(keys, { route }));
  const [values, setValues] = useState(createValuesInitialStateGetter(keys, getters, { route }));

  const replace = (key, value) => route.replace({ search: { ...route.search, [key]: value } });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deferReplacing = useCallback(debounce(replace, 500), [route.history.location.search]);

  useEffect(() => deferReplacing.cancel, [deferReplacing.cancel]);

  const changeAvailability = (key, enabled) => setAvailability({ ...availability, [key]: enabled });
  const changeValue = (key, value) => setValues({ ...values, [key]: value });

  const isAvailable = () => Object.values(availability).includes(true);

  const toggle = key => enabled => {
    changeAvailability(key, enabled);
    if (!manual) {
      if (enabled) {
        if (flags.includes(key)) {
          replace(key, Number(values[key]));
        } else {
          replace(key, values[key]);
        }
      } else {
        replace(key, undefined);
      }
    }
  };

  const change = key => value => {
    changeAvailability(key, true);
    changeValue(key, value);
    if (!manual) {
      if (flags.includes(key)) {
        replace(key, Number(value));
      } else {
        deferReplacing(key, value);
      }
    }
    if (!value) {
      replace(key, undefined);
      changeAvailability(key, false);
    }
  };

  const reset = () => {
    if (!manual) {
      route.replace({
        search: {
          ...route.search,
          ...keys.reduce((params, key) => Object.assign(params, { [key]: undefined }), {}),
        },
      });
    }
    setAvailability(keys.reduce((availability, key) => Object.assign(availability, { [key]: false }), {}));
    setValues(keys.reduce((values, key) => Object.assign(values, { [key]: getters[key]() }), {}));
  };

  const apply = () => route.replace({
    search: {
      ...route.search,
      ...keys.reduce(
        (result, key) => Object.assign(result, {
          [key]: availability[key]
            ? flags.includes(key)
              ? Number(values[key])
              : values[key]
            : undefined,
        }),
        {}
      ),
    },
  });

  const props = keys.reduce((props, key) => Object.assign(props, {
    [key]: {
      enabled: availability[key],
      value: values[key],
      onToggle: toggle(key),
      onChange: change(key),
    },
  }), {});

  return {
    isAvailable,
    toggle,
    change,
    reset,
    apply,
    props,
  };
};
