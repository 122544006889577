import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

import { BusyButton, RadioGroup } from 'components';

import { useStyles } from './style.js';
import Grid from '@mui/material/Grid';
import { useForm } from '../../hooks';
import { fetchB2BProducts, reassignOutdatedProducts } from 'api/products';
import toaster from 'services/toaster';

const makeId = postfix => `products-mapping-dialog--${postfix}`;

export const ProductsMappingDialog = ({
  onClose,
  onSave,
  selectedStorageProducts,
  isMobileView,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const product = selectedStorageProducts[0].product;
  const [childArticles, setChildArticles] = useState([]);

  const {
    setFormValue,
    model,
  } = useForm({
    alzuraId: null,
  });

  useEffect(() => {
    (async () => {
      const b2bProducts = await fetchB2BProducts({
        ...product,
        countryCode: product.details[0].marketplaceCountry.country.countryCode,
      });

      let allChildArticles = [];
      for (const b2bProduct of b2bProducts) {
        allChildArticles = allChildArticles.concat(b2bProduct.childArticles);
      }
      setChildArticles(allChildArticles);
    })();
  }, [product]);

  const handleSubmit = async (values) => {
    try {
      const payload = {
        storageProductsIds: selectedStorageProducts.map(sp => sp.id),
        alzuraId: model.alzuraId,
      };
      await reassignOutdatedProducts(payload);
      toaster.success(t.translate('Product has been created successfully.'));

      onSave();
      onClose();
    }
    catch (err) {
      toaster.error(Object.values(err.collection)[0][0]);
    }
  };

  return (
    <Dialog
      {...props}
      id={makeId(product.id)}
      scroll="body"
      fullWidth
      fullScreen={isMobileView}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">{product.details[0].name}</DialogTitle>
      <DialogContent>
        {childArticles.length
          ? <Grid item xs={12} container>
            <RadioGroup
              className={classes.radioGroup}
              defaultValue={childArticles[0].id}
              label={t.translate('Select child product')}
              value={model.alzuraId}
              options={new Map(childArticles.map((c) => [c.id, Object.values(c).join(' | ')]))}
              onChange={setFormValue('alzuraId')}
            />
          </Grid>
          : <Typography variant="body1">{t.translate('No child articles available for this product')}</Typography>}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
        <BusyButton color="primary" submit onClick={handleSubmit}>{t.translate('Create')}</BusyButton>
      </DialogActions>
    </Dialog>
  );
};

ProductsMappingDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
