import React, { useEffect, useState } from 'react';
import { useTranslator } from 'i18n';
import { makeStyles, withStyles } from 'tss-react/mui';
import {
  CircularLoader, FormControlError,
} from 'components';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Button, FormControl, Input, InputLabel, Tooltip } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { fetchReplenishmentSuggestionsOptions, modifyReplenishmentSuggestionsOptions } from 'api/options';
import toaster from 'services/toaster';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles()({
  actionsItem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const ReplenishmentTooltip = withStyles(Tooltip, theme => ({
  tooltip: {
    backgroundColor: 'white',
    padding: theme.spacing(1),
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    color: 'rgba(0, 0, 0, 0.87)',
    borderRadius: '4px',
    pointerEvents: 'none',
  },
}));

export const ReplenishmentSuggestionsConfiguration = () => {
  const [initialValues, setInitialValues] = useState({
    defaultReplenishmentTime: 45,
    additionalReplenishmentTime: 10,
  });
  const [isLoading, setIsLoading] = useState(true);

  const t = useTranslator();
  const { classes } = useStyles();

  const validationSchema = Yup
    .object()
    .shape({
      defaultReplenishmentTime: Yup
        .number()
        .integer()
        .min(0)
        .label(t.translate('Default replenishment time'))
        .required(),
      additionalReplenishmentTime: Yup
        .number()
        .integer()
        .min(0)
        .label(t.translate('Additional replenishment time'))
        .required(),
    })
  ;

  const onSubmit = async () => {
    try {
      const { defaultReplenishmentTime, additionalReplenishmentTime } = values;

      await modifyReplenishmentSuggestionsOptions({ defaultReplenishmentTime, additionalReplenishmentTime });
      toaster.success(t.translate('Replenishment suggestions configuration has been updated successfully'));
    } catch (err) {
      toaster.error(Object.values(err.collection)[0][0]);
    }
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    enableReinitialize: true,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    fetchReplenishmentSuggestionsOptions()
      .then(({
        defaultReplenishmentTime,
        additionalReplenishmentTime,
      }) => {
        setInitialValues({ defaultReplenishmentTime, additionalReplenishmentTime });
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {isLoading ? <CircularLoader />
        : (
          <form action="#" onSubmit={onSubmit}>
            <Grid container direction="column" spacing={4}>
              <Grid container item>
                <Typography variant="h6">
                  {t.translate('Replenishment suggestions configuration')}
                </Typography>
              </Grid>

              <Grid item>
                <FormControl fullWidth error={!!errors.defaultReplenishmentTime}>
                  <InputLabel
                    shrink
                    htmlFor="defaultReplenishmentTime"
                  >
                    {t.translate('Default replenishment time')}
                  </InputLabel>
                  <Input
                    id="defaultReplenishmentTime"
                    value={values.defaultReplenishmentTime}
                    onChange={handleChange}
                    type="text"
                    required
                    endAdornment={
                      <ReplenishmentTooltip
                        title={
                          <React.Fragment>
                            <Typography className={classes.tooltip}>{t.translate('defaultReplenishmentTime:info')}</Typography>
                          </React.Fragment>
                        }
                      >
                        <InfoIcon />
                      </ReplenishmentTooltip>
                    }
                  />
                  {errors.defaultReplenishmentTime && (
                    <FormControlError error={errors.defaultReplenishmentTime} />
                  )}
                </FormControl>
              </Grid>

              <Grid item>
                <FormControl fullWidth error={!!errors.additionalReplenishmentTime}>
                  <InputLabel
                    shrink
                    htmlFor="additionalReplenishmentTime"
                  >
                    {t.translate('Additional replenishment time')}
                  </InputLabel>
                  <Input
                    id="additionalReplenishmentTime"
                    value={values.additionalReplenishmentTime}
                    onChange={handleChange}
                    required
                    endAdornment={
                      <ReplenishmentTooltip
                        title={
                          <React.Fragment>
                            <Typography className={classes.tooltip}>{t.translate('additionalReplenishmentTime:info')}</Typography>
                          </React.Fragment>
                        }
                      >
                        <InfoIcon />
                      </ReplenishmentTooltip>
                    }
                  />
                  {errors.additionalReplenishmentTime && (
                    <FormControlError error={errors.additionalReplenishmentTime} />
                  )}
                </FormControl>
              </Grid>

              <Grid item className={classes.actionsItem}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  {t.translate('Save')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
    </>
  );
};
