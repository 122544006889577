import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  cellPadding: {
    '& .MuiTableRow-root .MuiTableCell-root:first-of-type': {
      paddingLeft: theme.spacing(3),
    },
    '& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:last-child': {
      paddingRight: theme.spacing(2),
    },
  },
  dialogActions: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
}));
