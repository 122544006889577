import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';
import { makeStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import {
  BooleanIcon,
  BusyButton,
} from 'components';
import { styleFormDialog } from 'theme/mixins/dialog';
import { modifyAuthenticatedUser } from 'api/users';

const useStyles = makeStyles()(theme => styleFormDialog(theme, {
  content: {
    '&.MuiDialogContent-root': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  listItem: {
    height: theme.spacing(5.5),
    '& .MuiGrid-item': {
      alignItems: 'center',
      display: 'flex',
    },
  },
  idItem: {
    width: theme.spacing(7),
  },
}));

export const SelectingWarehouseDialog = ({
  onSelect,
  onClose,
  warehouses,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  const confirm = async () => {
    try {
      await modifyAuthenticatedUser({ warehouseId: selectedWarehouse.id });

      return () => {
        onSelect(selectedWarehouse);
        onClose();
      };
    } catch (err) {}
  };

  return (
    <Dialog
      {...props}
      id="select-warehouse"
      onClose={(e, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') onClose();
      }}
    >
      <DialogTitle className={classes.title}>{t.translate('Select warehouse')}</DialogTitle>
      <DialogContent className={classes.content}>
        <List>
          {warehouses.map(warehouse => (
            <ListItem
              className={classes.listItem}
              key={warehouse.id}
              button
              onClick={() => setSelectedWarehouse(warehouse.id === selectedWarehouse?.id ? null : warehouse)}
            >
              <Grid container spacing={2}>
                <Grid item className={classes.idItem}>
                  <Typography>{warehouse.id}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography>{warehouse.name}</Typography>
                </Grid>
                {warehouse.id === selectedWarehouse?.id && (
                  <Grid item>
                    <BooleanIcon truthy />
                  </Grid>
                )}
              </Grid>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <BusyButton disabled={!selectedWarehouse} onClick={confirm}>{t.translate('Confirm')}</BusyButton>
      </DialogActions>
    </Dialog>
  );
};

SelectingWarehouseDialog.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  warehouses: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
};
