import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Box, Toolbar } from '@mui/material';

const useStyles = makeStyles()({
  toolbar: {
    width: '100%',
  },
});

export const AppHeaderToolbarCell = ({
  shrink,
  children,
}) => {
  const { classes } = useStyles();

  return (
    <Box
      display="flex"
      flexShrink={shrink ? 0 : 1}
      flexGrow={shrink ? 0 : 1}
      width={shrink ? 360 : 'auto'}
    >
      <Toolbar className={classes.toolbar}>
        {children}
      </Toolbar>
    </Box>
  );
};

AppHeaderToolbarCell.propTypes = {
  shrink: PropTypes.bool,
};
