import React from 'react';
import Box from '@mui/material/Box';

export const AppHeaderRightBox = ({ children, ...props }) => (
  <Box
    display="flex"
    ml={2}
    {...props}
  >
    {children}
  </Box>
);
