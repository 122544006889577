import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';

import { Tabs } from 'components';

const useStyles = makeStyles()(theme => ({
  appBar: {
    height: theme.spacing(7),
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(6),
      maxWidth: `calc(100vw - 16px)`,
    },
    width: '100%',
    boxShadow: `inset 0 -2px 0 0 ${theme.palette.primary.l50}`,

    '& .MuiTabs-scrollButtons': {
      opacity: 1,

      '&.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.3)',
      },

      '&:first-of-type': {
        borderRight: `2px solid ${theme.palette.primary.l50}`,
      },
    },
  },
  tab: {
    height: theme.spacing(7),
    paddingLeft: theme.spacing(4.125),
    paddingRight: theme.spacing(4.125),
    paddingTop: '8px',
    borderRight: `2px solid ${theme.palette.primary.l50}`,

    '&:hover': {
      backgroundColor: theme.palette.primary.l25,
    },

    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(6),
    },
  },
  indicator: {
    display: 'flex',
    backgroundColor: 'transparent',

    '& > span': {
      display: 'inline-block',
      backgroundColor: theme.palette.common.white,
      width: 'calc(100% - 2px)',
    },
  },
}));

export const MarketplaceCountriesTabs = ({ marketplaces, initialValue, ...props }) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (marketplaces) {
      const currentTabs = marketplaces.reduce((acc, marketplace) => ([
        ...acc,
        {
          ...marketplace,
          name: marketplace.country.countryCode,
          label: t.translate(`country:${marketplace.country.countryCode}`),
        },
      ]), [])
      .filter(tab => tab.isActive);
      setTabs(currentTabs);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplaces]);

  return (
    <Tabs
      initialValue={initialValue}
      items={tabs}
      appBar={{ className: classes.appBar, color: 'default' }}
      tabs={{
        textColor: 'inherit',
        classes: {
          scroller: classes.scroller,
          indicator: classes.indicator,
        },
      }}
      tab={{ className: classes.tab }}
      {...props}
    />
  );
};

MarketplaceCountriesTabs.propTypes = {
  onChange: PropTypes.func.isRequired,
};
