import { PRODUCTS } from 'store/types';
import { pack } from 'store/utils';

export const setStorageProduct = payload => dispatch => dispatch(pack(PRODUCTS.SET_STORAGE_PRODUCT, payload));

export const setStorages = payload => dispatch => dispatch(pack(PRODUCTS.SET_STORAGES, payload));

export const setProducts = payload => dispatch => dispatch(pack(PRODUCTS.SET_PRODUCTS, payload));

export const setHistory = payload => dispatch => dispatch(pack(PRODUCTS.SET_HISTORY, payload));

export const setProduct = payload => dispatch => dispatch(pack(PRODUCTS.SET_PRODUCT, payload));

export const clearStorages = () => dispatch => dispatch(pack(PRODUCTS.CLEAR_STORAGES));

export const clearHistory = () => dispatch => dispatch(pack(PRODUCTS.CLEAR_HISTORY));

export const setSuppliers = payload => dispatch => dispatch(pack(PRODUCTS.SET_SUPPLIERS, payload));

export const setProductPrices = payload => dispatch => dispatch(pack(PRODUCTS.SET_PRODUCT_PRICES, payload));

export const clearPrices = () => dispatch => dispatch(pack(PRODUCTS.CLEAR_PRICES));
