import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';
import { useStyles } from './RetailerItem.jsx';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';

import { BooleanIcon } from 'components';

export const RetailerItem = ({
  onRetailerOpen,
  retailer,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <Grid item className={classes.item}>
      <Paper>
        <Grid item container direction="column" className={classes.headline}>
          <Typography variant="body2" className={classes.bold}>{retailer.accountNumber || '—'}</Typography>
          <Box>
            <Typography variant="body2" component="span" className={classes.bold}>{t.translate('Name')}:&nbsp;</Typography>
            <Typography variant="body2" component="span">{retailer.name || '—'}</Typography>
          </Box>
        </Grid>
        <Divider />
        <Grid item container direction="column" className={classes.paper}>
          <Grid justifyContent="space-between" item container>
            <Typography variant="body2" className={classes.bold}>{t.translate('Active')}</Typography>
            <BooleanIcon className={classes.active} truthy={retailer.active} isDefault />
          </Grid>
          <Grid justifyContent="space-between" item container>
            <Typography variant="body2" className={classes.bold}>{t.translate('Created')}</Typography>
            <Typography variant="body2">{t.formatDate(retailer.createdAt)} {t.formatTime(retailer.createdAt)}</Typography>
          </Grid>
          <Grid item className={classes.buttons}>
            <Button
              aria-label={t.translate('Open retailer')}
              className={classes.button}
              startIcon={<InfoIcon />}
              onClick={() => onRetailerOpen(retailer)}
            >
              {t.translate('Details')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

RetailerItem.propTypes = {
  onRetailerOpen: PropTypes.func.isRequired,
  retailer: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
};
