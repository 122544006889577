import { ORDERS } from 'store/types';
import { pack } from 'store/utils';

export const setActiveOrder = payload => dispatch => dispatch(pack(ORDERS.SET_ACTIVE_ORDER, payload));

export const setStatuses = payload => dispatch => dispatch(pack(ORDERS.SET_STATUSES, payload));

export const setOrders = payload => dispatch => dispatch(pack(ORDERS.SET_ORDERS, payload));

export const setLoadingOrders = payload => dispatch => dispatch(pack(ORDERS.SET_LOADING_ORDERS, payload));
