import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Typography } from '@mui/material';

import { useTranslator } from '../../i18n';

const useStyles = makeStyles()(theme => ({
  chip: {
    padding: theme.spacing(0.75),
    display: 'flex',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: theme.spacing(1.75),
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.grey['215']}`,
    cursor: 'pointer',
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: theme.palette.primary.l65,
      color: 'black',
      borderColor: theme.palette.primary.l65,
    },
  },
  activeChip: {
    backgroundColor: theme.palette.primary.l900,
    color: 'white',
    borderColor: 'white',
  },
  typography: {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));


export const BadgeFilter = ({
  filteredBy,
  list,
  filteredByValues,
  title,
  prefixForTranslator,
  defaultFilter,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const [activeValues, setActiveValues] = useState(defaultFilter ? [defaultFilter] : []);
  const filters = list.filters[filteredBy];

  const changeFilter = (value) => () => {
    let values;
    if (activeValues.includes(value)) {
      values = activeValues.filter(type => type !== value);
    }
    else {
      values = [...activeValues, value];
    }
    setActiveValues(values);
    list.changeFilter(
      filteredBy,
      [...values]
    );
  };

  useEffect(() => {
    if (filters) {
      setActiveValues(filters);
    }
  }, [filters]);

  const isActiveChip = (value) => {
    return activeValues.includes(value) ? classes.activeChip : '';
  };

  return (
    <>
      <Typography variant="body1" className={classes.typography}>{t.translate(title)}:</Typography>
      {Object.keys(filteredByValues).map((value, index) => (
        <div
          key={`${value}-${index}`}
          className={`${classes.chip} ${isActiveChip(filteredByValues[value])}`}
          onClick={changeFilter(filteredByValues[value])}
        >
          <span>{t.translate(`${prefixForTranslator || ''}${filteredByValues[value]}`)}</span>
        </div>
      ))}
    </>
  );
};
