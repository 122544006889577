import React from 'react';
import { useDispatch } from 'react-redux';
import { useScreen } from 'hooks';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { toggleNavigation } from 'services/app';
import { openNavigation } from 'store/actions/app';

export const AppHeaderMenuButton = props => {
  const dispatch = useDispatch();
  const screen = useScreen();

  const handleClick = () => {
    if (screen.isMobile) {
      dispatch(openNavigation());
    } else {
      dispatch(toggleNavigation());
    }
  };

  return (
    <IconButton
      color="inherit"
      {...props}
      onClick={handleClick}
      aria-label="open app navigation"
      size="large">
      <MenuIcon />
    </IconButton>
  );
};
