import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import { OrderStatuses } from 'consts';

const useStyles = makeStyles()(theme => ({
  fontBold: {
    fontWeight: 500,
    marginBottom: theme.spacing(.5),
  },
  cardItem: {
    padding: theme.spacing(2),
  },
}));

const createCancelReason = (value, text) => ({ [value]: { text } });
const CANCEL_REASONS = {
  ...createCancelReason(OrderStatuses.DENIED, 'The order was rejected.'),
  ...createCancelReason(OrderStatuses.PARTIALLY_CANCELLED, 'Partially cancelled.'),
  ...createCancelReason(OrderStatuses.SOLD_OUT, 'Unfortunately the item is out of stock.'),
  ...createCancelReason(OrderStatuses.CANCELLED_BY_SELLER, 'Customer cancellation by supplier.'),
  ...createCancelReason(OrderStatuses.DAMAGED_OR_LOST, 'Damaged or lost.'),
};

export const CollapsedCancelledRow = ({ position }) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <>
      <Grid container direction="column" item className={classes.cardItem} md={4}>
        <Grid container justifyContent="space-between" >
          <Grid item>
            <Typography className={classes.fontBold} variant="body2">{t.translate('Originally ordered')}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{Number(position.quantity + position.cancelledAmount) || '—'}</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography className={classes.fontBold} variant="body2">{t.translate('Cancelled')}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{position.cancelledAmount || '—'}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid container item direction="column" className={classes.cardItem} md={8}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography className={classes.fontBold} variant="body2">{t.translate('Reason')}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{CANCEL_REASONS[position.reason]?.text || '—'}</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography className={classes.fontBold} variant="body2">{t.translate('Comment')}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{position.comment}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

CollapsedCancelledRow.propTypes = {
  position: PropTypes.shape({
    quantity: PropTypes.number,
    cancelledAmount: PropTypes.number,
    reason: PropTypes.string,
    comment: PropTypes.string,
  }).isRequired,
};
