import React from 'react';
import { InputControl, Table, TableCell, TableRow } from 'components';
import { useTranslator } from 'i18n';
import { PageWrapper } from '../../../suppliers/settings/PageWrapper/PageWrapper';
import { Hint } from '../../../suppliers/settings/Hint/Hint';
import { Grid, TableBody, TableContainer, TableHead, Typography } from '@mui/material';
import { useStyles } from '../styles';

const makeId = postfix => `companies-vat-settings--${postfix}`;

export const VatSettings = ({ isEditMode, values, handleChange, errors, setFieldValue }) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
      <PageWrapper>
        <Grid item xl={8} lg={10} xs={12} className={classes.containerWrap}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} className={classes.containerWrap}>
              <Grid container alignItems="center" className={classes.marginBottom}>
                <Typography variant="h6">{t.translate('VAT & TAX numbers')}</Typography>
                <Hint
                  className={classes.helpTextIcon}
                  text={
                    t.translate(
                      'The following Tax & Vat ID will be used for sales / invoices on the specified country. The country will be determined by order - country or invoice settings. These vat values will be used for he document creation to partner / suppliers in the specified country. The values should be based on the country of this company {country}.',
                      { country: values.address.country ? `(${t.translate(`country:${values.address.country}`)})` : '' }
                    )
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell name="country">{t.translate('Country')}</TableCell>
                        <TableCell name="VATId">{t.translate('Vat ID')}</TableCell>
                        <TableCell name="taxNumber">{t.translate('Tax number')}</TableCell>
                        <TableCell name="VAT">{t.translate('VAT')} (%)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.VATSettings.map((vatSetting, index) => {
                        const error = errors.VATSettings && errors.VATSettings[index];
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              {t.translate(`country:${vatSetting.country.countryCode}`)}
                            </TableCell>
                            <TableCell>
                              <InputControl
                                id={makeId('VATSettings.VATId')}
                                name={`VATSettings[${index}].VATId`}
                                error={error?.VATId}
                                value={values.VATSettings[index].VATId}
                                onChange={handleChange}
                                disabled={!isEditMode}
                                className={classes.cellInput}
                              />
                            </TableCell>
                            <TableCell>
                              <InputControl
                                id={makeId('VATSettings.taxNumber')}
                                name={`VATSettings[${index}].taxNumber`}
                                error={error?.taxNumber}
                                value={values.VATSettings[index].taxNumber}
                                onChange={handleChange}
                                disabled={!isEditMode}
                                className={classes.cellInput}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputControl
                                type="number"
                                id={makeId('VATSettings.VAT')}
                                name={`VATSettings[${index}].VAT`}
                                error={error?.VAT}
                                value={values.VATSettings[index].VAT * 100}
                                onChange={(e) => setFieldValue(`VATSettings[${index}].VAT`, e.target.value / 100)}
                                disabled={!isEditMode}
                                className={classes.cellInput}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PageWrapper>
  );
};
