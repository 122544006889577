import React, { useMemo, useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useTranslator } from 'i18n';
import { useList } from 'hooks/list';

import { selectOverviewSuppliers } from 'store/selectors/suppliers';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import Grid from '@mui/material/Grid';

import {
  CheckableMenuItem,
  TuneBox,
} from 'components';

import { PurchaseStatuses } from 'consts';
import tables from 'config/tables';

const useStyles = makeStyles()(theme => ({
  title: {
    padding: theme.spacing(1.25, 2),
    backgroundColor: theme.palette.backgrounds.secondary,
    borderBottom: `1px solid ${theme.palette.grey[255]}`,
    '&:last-of-type': {
      borderTop: `1px solid ${theme.palette.grey[255]}`,
    },
  },
  label: {
    fontWeight: 500,
  },
  popover: {
    '& .MuiPopover-paper': {
      marginLeft: theme.spacing(.5),
    },
  },
}));

export const PurchasesFilter = props => {
  const t = useTranslator();
  const list = useList(tables.purchases.purchases);
  const { classes } = useStyles();
  const statuses = useMemo(() => Object.values(PurchaseStatuses).map((status) => [status, status]), []);
  const suppliers = useSelector(selectOverviewSuppliers);

  const [filterUsed, setFilterUsed] = useState(null);

  useEffect(() => {
    if (list.filters.status || list.filters.supplierNumber) {
      setFilterUsed(true);
    } else if (!list.filters.status && !list.filters.supplierNumber) {
      setFilterUsed(false);
    }
  }, [
    list.filters.status,
    list.filters.supplierNumber,
  ]);

  const supplierList = [['all', 'All'], ...Object.values(suppliers)?.map(({ number, name }) => [number, name])];
  const purchaseStatuses = [['all', 'All'], ...statuses];

  const changeFilter = (property, value) => e => list.changeFilter(
    property,
    value === 'all' ? [] : property === 'supplierNumber' ? value : [value]
  );

  return (
    <TuneBox
      width={280}
      {...props}
      PopoverProps={{ className: classes.popover }}
      filterUsed={filterUsed}
    >
      <Grid container direction="column">
        <Grid container className={classes.title}>
          <Typography variant="body2" className={classes.label}>{t.translate('Filter by status')}</Typography>
        </Grid>
        {purchaseStatuses.map(([key, text]) => (
          <CheckableMenuItem
            key={key}
            label={t.translate(`status:${text}`)}
            checked={list.filters.status ? list.filters.status[0] === key : 'all' === key}
            onChange={changeFilter('status', key)}
            control={<Radio color="secondary" />}
          />
        ))}
        <Grid container className={classes.title}>
          <Typography variant="body2" className={classes.label}>{t.translate('Filter by supplier')}</Typography>
        </Grid>
        {supplierList.map(([key, text]) => (
          <CheckableMenuItem
            key={key}
            label={text}
            checked={list.filters.supplierNumber ? list.filters.supplierNumber === key : 'all' === key}
            onChange={changeFilter('supplierNumber', key)}
            control={<Radio color="secondary" />}
          />
        ))}
      </Grid>
    </TuneBox>
  );
};
