import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';
import toaster from 'services/toaster';
import {
  useProductCodesFormControls,
  didUpdate,
  didMount,
  useForm,
} from 'hooks';

import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { useStyles } from './style';

import { ListingButton } from 'dialogs/SelectingProductDialog/SelectingProductDialog';
import {
  ProductTypeControl,
  IdentifyProducts,
  EanCodeControl,
  PhotoControl,
  InputControl,
  BusyButton,
  ActionButton,
  CheckboxControl,
} from 'components';

import {
  fetchProductTypes,
  fetchB2BProducts,
  fetchProductDimensions,
  modifyProduct,
  createProduct,
} from 'api/products';
import { useSelector } from 'react-redux';
import { selectActionPermission } from '../../store/selectors/account';
import { fetchProductsOptions } from '../../api/options';
import { ProductGroupBadge } from '../../components/ProductGroupBadge/ProductGroupBadge';
import { fetchProductGroups } from '../../api/product-groups';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import { Checkbox, ListItemText } from '@mui/material';
import socket, { SOCKET_EVENTS } from '../../bootstrap/socket';

const makeId = postfix => `product-dialog--${postfix}`;

const getProductDimensions = ean => {
  if (!ean) return null;

  return fetchProductDimensions(ean);
};

export const ProductDialog = ({
  onClose,
  onSave,
  product,
  isMobileView,
  canUpdateFromMarketplace,
  isCreateFromPurchase = false,
  productTypes,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const [types, setTypes] = useState(productTypes || []);
  const [photo, setPhoto] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const canUpdateLowStock = useSelector(selectActionPermission('products', 'lowStockUpdate'));
  const [defaultLowStockValue, setDefaultLowStockValue] = useState(0);
  const [productGroups, setProductGroups] = useState([]);
  const {
    width = '',
    height = '',
    length = '',
    weight = '',
  } = product?.dimensions && product?.dimensions[0] ? product.dimensions[0] : {};

  useEffect(() => {
    fetchProductsOptions()
      .then(({ lowStockUpdateValue }) => {
        setDefaultLowStockValue(lowStockUpdateValue);
      });
    fetchProductGroups().then((groups) => setProductGroups(groups.data));
  }, []);

  const form = useForm(product ? {
    manufacturerNumber: product.manufacturerNumber || '',
    manufacturerName: product.manufacturerName || '',
    lastArticleIdentify: product.lastArticleIdentify || '',
    description: product.details[0]?.description || '',
    imageUrl: product.imageUrl,
    name: product.details[0]?.name || '',
    ean: product.ean || '',
    length,
    height,
    width,
    weight,
    manufacturerId: product.manufacturerId || '',
    alzuraId: product.alzuraId || '',
    typeId: product.type?.id || null,
    isUpdateOnLowStock: product.isUpdateOnLowStock,
    lowStockUpdateValue: product.lowStockUpdateValue || 0,
    groupIds: product.groups.map( g => g.id),
  } : {
    manufacturerNumber: '',
    manufacturerName: '',
    lastArticleIdentify: '',
    description: '',
    imageUrl: '',
    name: '',
    ean: '',
    length: '',
    height: '',
    width: '',
    weight: '',
    manufacturerId: '',
    alzuraId: '',
    typeId: null,
    isUpdateOnLowStock: false,
    lowStockUpdateValue: 0,
    groupIds: [],
  });

  const {
    catchHttpError,
    isModelValid,
    setFormValue,
    resetForm,
    setValue,
    toggleFormValue,
    errors,
    model,
  } = form;

  const {
    changeProductCode,
    updateProducts,
    selectProduct,
    selectingDialog,
    foundB2BProduct,
    foundProduct,
  } = useProductCodesFormControls({
    setManufacturerNumber: setValue('manufacturerNumber'),
    setManufacturerId: setValue('manufacturerId'),
    setProductEan: setValue('ean'),
    setProductAlzuraId: setValue('alzuraId'),
    setRestProductData: async ({ identified, b2bProduct, product }) => {
      const productDimensions = await getProductDimensions(product?.ean ?? b2bProduct?.ean);

      if (product) {
        setValue('ean')(product.ean);
        setPhoto(product.photo || null);
        setValue('manufacturerName')(product.manufacturerName || '');
        setValue('lastArticleIdentify')(product.lastArticleIdentify || '');
        setValue('description')(product.details[0]?.description || '');
        setValue('imageUrl')(product.imageUrl || '');
        setValue('name')(product.details[0]?.name || '');
        setValue('manufacturerId')(product.manufacturerId || '');
        setValue('typeId')(product.type?.id || null);
        setValue('length')(productDimensions?.length || '');
        setValue('height')(productDimensions?.height || '');
        setValue('width')(productDimensions?.width || '');
        setValue('weight')(productDimensions?.weight || '');
      } else {
        if (!identified) {
          setValue('description')('');
          setValue('length')('');
          setValue('height')('');
          setValue('width')('');
          setValue('weight')('');
        }
        if (b2bProduct) {
          setValue('manufacturerName')(b2bProduct.manufacturerName || '');
          setValue('lastArticleIdentify')(b2bProduct.lastArticleIdentify || '');
          setValue('imageUrl')(b2bProduct.imageUrls[0] || '');
          setValue('name')(b2bProduct.name || '');
          setValue('manufacturerId')(b2bProduct.manufacturerId || '');
          setValue('typeId')(b2bProduct.type?.id || null);
          setValue('length')(productDimensions?.length || '');
          setValue('height')(productDimensions?.height || '');
          setValue('width')(productDimensions?.width || '');
          setValue('weight')(productDimensions?.weight || '');
        } else {
          setPhoto(null);
          setValue('manufacturerName')('');
          setValue('imageUrl')('');
          setValue('name')('');
          setValue('length')('');
          setValue('height')('');
          setValue('width')('');
          setValue('weight')('');
          setValue('manufacturerId')('');
          setValue('typeId')(null);
        }
      }
    },
  });

  didMount(() => {
    let mounted = true;

    const getProductTypes = async () => {
      try {
        const productTypes = await fetchProductTypes();
        if (mounted) {
          setTypes(productTypes.data);
        }
      } catch (err) {}
    };

    if (!types.length) {
      getProductTypes();
    }

    return () => mounted = false;
  }, []);

  didUpdate(() => {
    if (props.open) {
      setPhoto(product?.photo || null);
      resetForm();
      selectingDialog.reset();

      if (product?.id) {
        socket.emit(SOCKET_EVENTS.PRODUCTS.EDITED_BY, { entityId: product.id, isEdited: true });
      }
    }
  }, [props.open]);

  const validateModel = errors => {
    if (!t.isValidPrice(model.generalPrice)) {
      errors.generalPrice = t.translate('Please specify a valid purchase price.');
    }
    if (!t.isValidPrice(model.salesPrice)) {
      errors.generalPrice = t.translate('Please specify a valid sales price.');
    }

    if ((model.length || model.height || model.width || model.weight)
      && !model.ean) {
      errors.ean = t.translate('Please specify an ean.');
    }

    if (model.isUpdateOnLowStock && !Number(model.lowStockUpdateValue)) {
      errors.lowStockUpdateValue = t.translate('Low stock must be a number greater than 0');
    }
  };

  const submit = async () => {
    try {
      if (!model.isUpdateOnLowStock) setValue('lowStockUpdateValue')(Number(defaultLowStockValue));
      if (!isModelValid(validateModel)) return;

      const payload = {
        ...model,
        ...(photo ? {
          photoId: photo.id,
        } : {}),
      };

      if (product || foundProduct) {
        await modifyProduct(product?.id || foundProduct.id, { ...payload, fromApi: !!foundB2BProduct || product?.fromApi });
        toaster.success(t.translate('Product has been saved successfully.'));
      } else {
        await createProduct({
          ...payload,
          isCreateFromPurchase,
          fromApi: !!foundB2BProduct,
        });
        toaster.success(t.translate('Product has been created successfully.'));
      }

      onSave();
      onCloseDialog();
    } catch (err) {
      catchHttpError(err);
    }
  };

  const identify = async ({ ean, manufacturerNumber, manufacturerId }) => {
    if (ean || manufacturerNumber || manufacturerId) {
      setIsLoading(true);
      try {
        const b2bProducts = await fetchB2BProducts({ ean, manufacturerNumber, manufacturerId });
        if (b2bProducts.length > 1) {
          selectingDialog.open({ b2bProducts, products: null }, { properties: { ean } });
        } else if (b2bProducts.length) {
          selectProduct({ ean })({
            identified: true,
            b2bProduct: b2bProducts[0],
            product: null,
          });
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onCloseDialog = async () => {
    if (product?.id) {
      socket.emit(SOCKET_EVENTS.PRODUCTS.EDITED_BY, { entityId: product.id, isEdited: false });
    }

    onClose();
  };

  return (
    <>
      <Dialog
        {...props}
        scroll="body"
        fullWidth
        maxWidth="sm"
        fullScreen={isMobileView}
        onClose={onCloseDialog}
        aria-labelledby="max-width-dialog-title"
        className={classes.root}
      >
        {!selectingDialog.visible && (
          <DialogTitle id="max-width-dialog-title">
            {t.translate(product ? 'Edit product' : 'Create product')}
            {isLoading && (
              <div className={classes.loader}>
                <CircularProgress size={16}/>
              </div>
            )}
          </DialogTitle>
        )}
        <DialogContent className={classes.root}>

          {selectingDialog.visible && (
            selectingDialog.data.products ||
            selectingDialog.data.b2bProducts
          ) ? (
            <IdentifyProducts
              {...selectingDialog.props}
              isMobileView={isMobileView}
              updateProducts={updateProducts}
              onSelect={selectProduct(selectingDialog.options.properties)}
            />
          ) : (
            <>
              <Grid
                container
                spacing={1}
                justifyContent="space-between"
                alignItems="flex-end"
                direction={isMobileView ? 'column' : 'row'}
                className={classes.container}
              >
                <Grid container item direction="column">
                  <Grid item>
                    <EanCodeControl
                      id={makeId('ean')}
                      disabled={isLoading}
                      error={errors.ean}
                      label={t.translate('Product EAN code')}
                      value={model.ean}
                      onChange={value => changeProductCode({ ean: value })}
                      endAdornment={(
                        <>
                          {(selectingDialog.options.fetching.id || selectingDialog.options.fetching.ean) && <CircularProgress color="inherit" size={24} />}
                          {selectingDialog.options.properties.ean && <ListingButton onClick={() => selectingDialog.open()} />}
                          {
                            canUpdateFromMarketplace && !isLoading && (
                              <ActionButton
                                tooltipPlacement="bottom"
                                aria-label={t.translate('Update from marketplace')}
                                tooltip={t.translate('Update from marketplace')}
                                icon={<CloudDownloadIcon/>}
                                onClick={async () => identify({ ean: model.ean })}
                              />
                            )
                          }
                        </>
                      )}
                    />
                  </Grid>
                  <Grid container direction="row" className={classes.manufacturerContainer} spacing={1}>
                    <Grid item xs={7}>
                      <InputControl
                        id={makeId('manufacturer-number')}
                        disabled={isLoading}
                        error={errors.manufacturerNumber}
                        label={t.translate('Manufacturer number')}
                        value={model.manufacturerNumber}
                        onChange={({ target: { value } }) => changeProductCode({ manufacturerNumber: value })}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <InputControl
                        id={makeId('manufacturer-id')}
                        disabled={isLoading}
                        error={errors.manufacturerId}
                        label={t.translate('Brand ID')}
                        value={model.manufacturerId}
                        onChange={({ target: { value } }) => changeProductCode({ manufacturerId: value })}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      {selectingDialog.options.fetching.manufacturerId && <CircularProgress color="inherit" size={24} />}
                      {selectingDialog.options.properties.manufacturerId && <ListingButton onClick={() => selectingDialog.open()} />}
                      {
                        canUpdateFromMarketplace && !isLoading && (
                          <ActionButton
                            className={classes.floatingActionButton}
                            tooltipPlacement="bottom"
                            aria-label={t.translate('Update from marketplace')}
                            tooltip={t.translate('Update from marketplace')}
                            icon={<CloudDownloadIcon/>}
                            onClick={async () => identify({ manufacturerId: model.manufacturerId, manufacturerNumber: model.manufacturerNumber })}
                          />
                        )
                      }
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>{`${t.translate('Parent ID')}: ${product?.parentId || '—'}`}</Grid>
                </Grid>
                <Grid container
                      item
                      justifyContent="flex-end"
                      className={classes.containerPhoto}
                >
                  <PhotoControl
                    label={t.translate('Product photo')}
                    photo={photo || model.imageUrl}
                    onChange={setPhoto}
                    onDelete={() => photo ? setPhoto(null) : setValue('imageUrl')('')}
                  />
                </Grid>
              </Grid>

              <Grid container
                direction="column"
                className={classes.containerDetails}
              >
                <Typography variant="body1">{t.translate('Details')}</Typography>
                <Grid container
                  direction={isMobileView ? 'column' : 'row'}
                  spacing={1}
                  className={classes.container}
                >
                  <Grid item md={6}>
                    <ProductTypeControl
                      id={makeId('type')}
                      disabled={isLoading}
                      error={errors.typeId}
                      label={t.translate('Product type')}
                      types={types}
                      value={model.typeId || 0}
                      product={foundB2BProduct || foundProduct || product}
                      onChange={setValue('typeId')}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <InputControl
                      id={makeId('alzura-id')}
                      disabled={isLoading}
                      error={errors.alzuraId}
                      label="Alzura ID"
                      value={model.alzuraId}
                      onChange={({ target: { value } }) => changeProductCode({ alzuraId: value })}
                      endAdornment={(<>
                        {selectingDialog.options.fetching.alzuraId && <CircularProgress color="inherit" size={24} />}
                        {selectingDialog.options.properties.alzuraId && <ListingButton onClick={() => selectingDialog.open()} />}
                      </>)}
                    />
                  </Grid>
                </Grid>

                <Grid container >
                  <Grid item md={12} xs={12}>
                    <InputControl
                      id={makeId('name')}
                      disabled={isLoading}
                      error={errors.name}
                      label={t.translate('Product name')}
                      value={model.name}
                      onChange={setFormValue('name')}
                    />
                  </Grid>
                </Grid>
                <Grid container >
                  <Grid item  md={12} xs={12}>
                    <InputControl
                      id={makeId('description')}
                      disabled={isLoading}
                      error={errors.description}
                      label={t.translate('Product description')}
                      value={model.description}
                      multiline
                      onChange={setFormValue('description')}
                    />
                  </Grid>
                </Grid>

                <Grid container direction={isMobileView ? 'column' : 'row'} spacing={1} >
                  <Grid item md={4}>
                    <InputControl
                      id={makeId('manufacturer-name')}
                      disabled={isLoading}
                      error={errors.manufacturerName}
                      label={t.translate('Manufacturer name')}
                      value={model.manufacturerName}
                      onChange={setFormValue('manufacturerName')}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container direction="column" className={classes.containerDimensions}>
                <Typography>{t.translate('Dimensions')}</Typography>
                <Grid container spacing={1}>
                  <Grid item>
                    <InputControl
                      id={makeId('width')}
                      disabled={isLoading}
                      error={errors.width}
                      label={`${t.translate('Width')}, cm`}
                      value={model.width}
                      onChange={setFormValue('width')}
                      type="number"
                    />
                  </Grid>
                  <Grid item>
                    <InputControl
                      id={makeId('height')}
                      disabled={isLoading}
                      error={errors.height}
                      label={`${t.translate('Height')}, cm`}
                      value={model.height}
                      onChange={setFormValue('height')}
                      type="number"
                    />
                  </Grid>
                  <Grid item>
                    <InputControl
                      id={makeId('length')}
                      disabled={isLoading}
                      error={errors.length}
                      label={`${t.translate('Length')}, cm`}
                      value={model.length}
                      onChange={setFormValue('length')}
                      type="number"
                    />
                  </Grid>
                  <Grid item>
                    <InputControl
                      id={makeId('weight')}
                      disabled={isLoading}
                      error={errors.weight}
                      label={`${t.translate('Weight')}, kg`}
                      value={model.weight}
                      onChange={setFormValue('weight')}
                      type="number"
                    />
                  </Grid>
                </Grid>
              </Grid>
              {
                canUpdateLowStock &&
                <Grid container direction="column" className={classes.lowStockContainer}>
                  <Typography>{t.translate('Low stock value')}</Typography>
                  <Grid container direction="column">
                    <Grid item>
                      <CheckboxControl
                        id={makeId('stock-enable')}
                        label={t.translate('Overwrite default value')}
                        checked={model.isUpdateOnLowStock}
                        onChange={toggleFormValue('isUpdateOnLowStock')}
                        onBlur={() => isModelValid(validateModel)}
                      />
                    </Grid>
                    {model.isUpdateOnLowStock &&
                      <Grid item>
                        <InputControl
                          error={errors.lowStockUpdateValue}
                          id={makeId('stock-value')}
                          label={t.translate('Low stock value')}
                          value={!model.isUpdateOnLowStock ? defaultLowStockValue : model.lowStockUpdateValue}
                          onChange={setFormValue('lowStockUpdateValue')}
                          disabled={!model.isUpdateOnLowStock}
                          onBlur={() => isModelValid(validateModel)}
                        />
                      </Grid>
                    }
                  </Grid>
                </Grid>
              }
              <Grid container direction="column">
                <Typography>{t.translate('Product groups')}</Typography>
                <FormControl fullWidth>
                  <InputLabel shrink id="product-groups-label">{t.translate('Product groups')}</InputLabel>
                  <Select
                    className={classes.selectEmpty}
                    labelId="default-product-groups-label"
                    id="default-product-groups"
                    name="defaultProductGroups"
                    multiple
                    value={model.groupIds}
                    onChange={(event) => {
                      setValue('groupIds')((event.target.value || []).filter(item => !!item));
                    }}
                    input={<Input />}
                    renderValue={(selected) =>
                      <Grid className={classes.badgeContainer}>
                        {productGroups.filter((g) => selected.includes(g.id)).map(g => <ProductGroupBadge groupName={g.name} id={g.id} />)}
                      </Grid>
                    }
                  >
                    {productGroups.map((group) => (
                      <MenuItem key={`menu-item-${group.id}`} value={group.id}>
                        <Checkbox checked={model.groupIds.indexOf(group.id) > -1} />
                        <ListItemText primary={group.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <DialogActions>
                <Button color="primary" onClick={onCloseDialog} disabled={isLoading}>{t.translate('dialog-action:Cancel')}</Button>
                <BusyButton color="primary" submit onClick={submit} disabled={isLoading}>{t.translate('Save')}</BusyButton>
              </DialogActions>
            </>
          )}
        </DialogContent>
      </Dialog>

    </>
  );
};

ProductDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  product: PropTypes.shape({
    manufacturerNumber: PropTypes.string,
    manufacturerName: PropTypes.string,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    fromApi: PropTypes.bool.isRequired,
    photo: PropTypes.object,
    type: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    name: PropTypes.string,
    ean: PropTypes.string,
    alzuraId: PropTypes.string,
    id: PropTypes.number.isRequired,
    dimensions: PropTypes.array,
  }),
  isMobileView: PropTypes.bool.isRequired,
  productTypes: PropTypes.array,
};
