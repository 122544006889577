import {
  BankAccountSettings,
  DatevSettings,
  GeneralSettings,
  VatSettings,
  DocumentNumbersSettings,
  ChangesHistorySettings,
} from 'views/companies/settings';

export const createRoutes = [{
    homePath: `/settings/companies/new-company/details`,
    Page: GeneralSettings,
    name: 'company',
  }, {
    homePath: `/settings/companies/new-company/bank-account`,
    Page: BankAccountSettings,
    name: 'company',
  }, {
    homePath: `/settings/companies/new-company/vat`,
    Page: VatSettings,
    name: 'company',
  }, {
    homePath: `/settings/companies/new-company/datev`,
    Page: DatevSettings,
    name: 'company',
  }, {
    homePath: `/settings/companies/new-company/document-numbers`,
    Page: DocumentNumbersSettings,
    name: 'company',
  },
];

export const createTabsOptions = [{
    label: 'Details',
    name: `/settings/companies/new-company/details`,
    view: 'company',
  }, {
    label: 'Bank account',
    name: `/settings/companies/new-company/bank-account`,
    view: 'company',
  }, {
    label: 'VAT Settings',
    name: `/settings/companies/new-company/vat`,
    view: 'company',
  }, {
    label: 'DATEV Settings',
    name: `/settings/companies/new-company/datev`,
    view: 'company',
  }, {
    label: 'Document numbers',
    name: `/settings/companies/new-company/document-numbers`,
    view: 'company',
  },
];

export const editRoutes = (id) => [{
    homePath: `/settings/companies/edit-company/${id}/details`,
    Page: GeneralSettings,
    name: 'company',
  }, {
    homePath: `/settings/companies/edit-company/${id}/bank-account`,
    Page: BankAccountSettings,
    name: 'company',
  }, {
    homePath: `/settings/companies/edit-company/${id}/vat`,
    Page: VatSettings,
    name: 'company',
  }, {
    homePath: `/settings/companies/edit-company/${id}/datev`,
    Page: DatevSettings,
    name: 'company',
  }, {
    homePath: `/settings/companies/edit-company/${id}/document-numbers`,
    Page: DocumentNumbersSettings,
    name: 'company',
  }, {
    homePath: `/settings/companies/edit-company/${id}/changes-history`,
    Page: ChangesHistorySettings,
    name: 'company',
  },
];

export const editTabsOptions = (id) => [{
    label: 'Details',
    name: `/settings/companies/edit-company/${id}/details`,
    view: 'company',
  }, {
    label: 'Bank account',
    name: `/settings/companies/edit-company/${id}/bank-account`,
    view: 'company',
  }, {
    label: 'VAT Settings',
    name: `/settings/companies/edit-company/${id}/vat`,
    view: 'company',
  }, {
    label: 'DATEV Settings',
    name: `/settings/companies/edit-company/${id}/datev`,
    view: 'company',
  }, {
    label: 'Document numbers',
    name: `/settings/companies/edit-company/${id}/document-numbers`,
    view: 'company',
  }, {
    label: 'Changes history',
    name: `/settings/companies/edit-company/${id}/changes-history`,
    view: 'company',
  },
];
