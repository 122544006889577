import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useTranslator } from 'i18n';

import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import { useStyles } from './style';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';

import {
  FormControlError,
  StockFormControl,
  InputControl,
} from 'components';

import { cancelReason } from 'consts';


const makeId = postfix => `cancelling-order-dialog--${postfix}`;
const makeName = (id, name) => `${id}.${name}`;

export const CancellingPositionItem = ({
  setCancelAllQuantity,
  handleChange,
  position,
  setFieldValue,
  values,
  errors,
  isShipAgain,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  useEffect(() => {
    setFieldValue(`${position.id}.allPositionCanceled`, values[position.id].cancelledAmount >= position.quantity);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[position.id].cancelledAmount]);

  useEffect(() => {
    if (setCancelAllQuantity) {
      setFieldValue(`${position.id}.cancelledAmount`, position.quantity);
    }
    setFieldValue(`${position.id}.allOrderCancel`, values.parentForm.allOrderCancel);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCancelAllQuantity, values.parentForm.allOrderCancel]);

  return (
    <>
      <Grid container className={classes.root} direction="column">
        <Grid container className={clsx(classes.containerItem, !!values[position.id].cancelledAmount && classes.expanded)} direction="column">
          <Typography variant="body2" className={classes.bold}>{position.product.details[0]?.name || '—'}</Typography>
          <Grid container>
            <Typography variant="body2" className={classes.bold}>EAN:&nbsp;</Typography>
            <Typography variant="body2">{position.product.ean || '—'}</Typography>
          </Grid>

          <Grid container >
            <Grid container direction="column">
              <Grid container>
                <Typography variant="body2" className={classes.bold}>{t.translate('Product-ID')}:&nbsp;</Typography>
                <Typography variant="body2">{position.product.id}</Typography>
              </Grid>
              <Grid container>
                <Typography variant="body2" className={classes.bold}>{t.translate('Ordered')}:&nbsp;</Typography>
                <Typography variant="body2">{position.quantity}</Typography>
              </Grid>
            </Grid>

            <Grid container direction="column-reverse">
              <Grid item className={classes.stockFormControl}>
                <StockFormControl
                  hideLabels
                  name={makeName(position.id, 'cancelledAmount')}
                  actions={['add']}
                  stock={0}
                  value={values[position.id].cancelledAmount}
                  onChange={(value) => setFieldValue(`${position.id}.cancelledAmount`, value)}
                  topBound={position.quantity}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!!values[position.id].cancelledAmount && !setCancelAllQuantity && !isShipAgain && (
          <Grid container className={classes.expandedContainerItem} direction="column">

            <FormControl fullWidth error={!!errors[position.id]?.reason} className={classes.reasonItem}>
              <InputLabel shrink htmlFor={makeId('reason')}>{t.translate('Reason')}</InputLabel>
              <Select
                id={makeId('reason')}
                name={makeName(position.id, 'reason')}
                value={values[position.id].reason}
                onChange={(e) => setFieldValue(`${position.id}.reason`, e.target.value)}
              >
                <MenuItem value={0}>{t.translate('Select')}</MenuItem>
                {cancelReason.map(({ value, text }, index) => (
                  <MenuItem key={index} value={value}>{t.translate(text)}</MenuItem>
                ))}
              </Select>
              {!!errors[position.id]?.reason && <FormControlError error={errors[position.id]?.reason} />}
            </FormControl>
            <InputControl
              id={makeId('comment')}
              name={makeName(position.id, 'comment')}
              error={errors[position.id]?.comment}
              label={t.translate('Comment')}
              value={values[position.id].comment}
              placeholder={t.translate('Annotations')}
              onChange={handleChange}
            />

          </Grid>
        )}
      </Grid>
    </>
  );
};

CancellingPositionItem.propTypes = {
  setCancelAllQuantity: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  position: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    product: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isShipAgain: PropTypes.bool,
};
