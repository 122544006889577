import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Color } from 'components';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > p': {
      marginTop: theme.spacing(.5),
    },
  },
}));

export const StorageSummary = ({ storage, hideEmpty }) => {
  const { classes } = useStyles();
  const t = useTranslator();

  if (hideEmpty) {
    return (
      <Grid item className={classes.root}>
        <Typography color="textSecondary" variant="body2">{t.translate('Warehouse')}: {storage.warehouse.name}</Typography>
        <Typography color="textSecondary" variant="body2">{t.translate('Type')}: {t.translate(`storageType:${storage.type}`)}</Typography>
        <Typography color="textSecondary" variant="body2">{t.translate('Building')}: {storage.building}</Typography>
        {storage.floor && <Typography color="textSecondary" variant="body2">{t.translate('Floor')}: {storage.floor}</Typography>}
        {storage.room && <Typography color="textSecondary" variant="body2">{t.translate('Room')}: {storage.room}</Typography>}
        {storage.row && <Typography color="textSecondary" variant="body2">{t.translate('Row')}: {storage.row}</Typography>}
        {storage.shelf && <Typography color="textSecondary" variant="body2">{t.translate('Shelf')}: {storage.shelf}</Typography>}
        {storage.level && <Typography color="textSecondary" variant="body2">{t.translate('Level')}: {storage.level}</Typography>}
        {storage.boxNumber && <Typography color="textSecondary" variant="body2">{t.translate('Box number')}: {storage.boxNumber}</Typography>}
        {storage.boxColor && (
          <Typography color="textSecondary" variant="body2">
            {t.translate('Box color')}
            <Color color={storage.boxColor} />
          </Typography>
        )}
      </Grid>
    );
  }

  return (
    <Grid item className={classes.root}>
      <Typography color="textSecondary" variant="body2">{t.translate('Warehouse')}: {storage.warehouse.name}</Typography>
      <Typography color="textSecondary" variant="body2">{t.translate('Type')}: {t.translate(`storageType:${storage.type}`)}</Typography>
      <Typography color="textSecondary" variant="body2">{t.translate('Building')}: {storage.building}</Typography>
      <Typography color="textSecondary" variant="body2">{t.translate('Floor')}: {storage.floor || '—'}</Typography>
      <Typography color="textSecondary" variant="body2">{t.translate('Room')}: {storage.room || '—'}</Typography>
      <Typography color="textSecondary" variant="body2">{t.translate('Row')}: {storage.row || '—'}</Typography>
      <Typography color="textSecondary" variant="body2">{t.translate('Shelf')}: {storage.shelf || '—'}</Typography>
      <Typography color="textSecondary" variant="body2">{t.translate('Level')}: {storage.level || '—'}</Typography>
      <Typography color="textSecondary" variant="body2">{t.translate('Box number')}: {storage.boxNumber || '—'}</Typography>
      <Typography color="textSecondary" variant="body2">
        {t.translate('Box color')}
        : {storage.boxColor ? <Color color={storage.boxColor} /> : '—'}
      </Typography>
    </Grid>
  );
};

StorageSummary.propTypes = {
  storage: PropTypes.shape({
    warehouse: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    type: PropTypes.string.isRequired,
    boxNumber: PropTypes.string,
    boxColor: PropTypes.string,
    building: PropTypes.string.isRequired,
    floor: PropTypes.string,
    shelf: PropTypes.string,
    level: PropTypes.string,
    room: PropTypes.string,
    row: PropTypes.string,
  }).isRequired,
  hideEmpty: PropTypes.bool,
};
