import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    height: theme.spacing(6),
  },
}));

export const AppHeaderSecondaryToolbar  = ({ children, ...props }) => (
  <Box {...props} className={useStyles().classes.root}>
    {children}
  </Box>
);
