import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@mui/material';
import { Snackbar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

const useStyles = makeStyles()({
  snackbar: {
      bottom: 100,
  },
  alert: {
    '&.MuiAlert-filledInfo': {
      backgroundColor: '#7592d6',
    },
    '&.MuiAlert-filledError': {
      backgroundColor: '#b00020',
    },
    '&.MuiAlert-filledSuccess': {
      backgroundColor: '#22b000',
    },
  },
});

export const Toast = ({
  severity,
  children,
  variant,
  unmount,
  onClose,
  open,
  className,
  ...props
}) => {
  const { classes } = useStyles();

  // The `opened` constant is used to control
  // on visibility of component created dynamically.
  const [opened, setOpened] = useState(true);

  const close = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (unmount) {
      // Instead of simple call of the `unmount`,
      // the state and delay is used to save
      // toast disappearance behavior.
      setOpened(false);

      if (onClose) onClose();

      return setTimeout(unmount, 1000);
    }

    if (onClose) onClose();
  };

  return (
    <Snackbar
      {...props}
      open={unmount ? opened : open}
      onClose={close}
      className={clsx(classes.snackbar, className && className )}
    >
      <div>
        <Alert
          severity={severity}
          variant={variant}
          onClose={close}
          className={classes.alert}
        >
          {children}
        </Alert>
      </div>
    </Snackbar>
  );
};

/* XXX:
 * The component can be rendered natively or dynamically.
 * In the first case it is necessary to use `open` and `onClose` properties.
 * In the opposite case it is necessary to provide the `unmount` function.
 */
Toast.propTypes = {
  severity: PropTypes.string,
  variant: PropTypes.string,
  unmount: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

Toast.defaultProps = {
  autoHideDuration: 5000,
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'bottom',
  },
  severity: 'success',
  variant: 'filled',
};
