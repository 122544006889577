import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  title: {
    paddingBottom: `${theme.spacing(.25)} !important`,
  },
  content: {
    paddingBottom: '0 !important',
    width: 400,
  },
  absoluteSummary: {
    right: theme.spacing(3),
    position: 'absolute',
    textAlign: 'right',
    top: theme.spacing(2.625),
    width: '50%',
  },
  summary: {
    display: 'flex',
    flexDirection: 'column',
    '& p:not(:first-of-type)': {
      marginTop: theme.spacing(.5),
    },
  },
  label: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(.25),
  },
  actions: {
    padding: theme.spacing(1, 2, 2),
  },
}));
