import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    height: '100%',
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
  },
  photo: {
    flexShrink: 0,
    height: theme.spacing(15),
    width: theme.spacing(15),
    margin: `${theme.spacing(3)} auto`,
    [theme.breakpoints.down('lg')]: {
      height: theme.spacing(10),
      width: theme.spacing(10),
    },
  },
  bold: {
    fontWeight: 500,
    marginBottom: theme.spacing(.5),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.l900,
    textTransform: 'none',
    marginTop: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.primary.l900,
    },
  },
}));
