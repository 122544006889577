import React from 'react';
import MuiTable from '@mui/material/Table';
import {
  extractFirstGrandchild,
  reeveChildren,
} from 'utils/jsx';

export const Table = ({ children, ...props }) => {
  const cellsProps = extractFirstGrandchild(children).props.children.map(({ props: { width, fixed } }) => ({ width, fixed }));

  const clonedChildren = reeveChildren(children, (container, containerProps) => React.cloneElement(
    container,
    containerProps,
    reeveChildren(container.props.children, (row, rowProps) => React.cloneElement(row, { ...rowProps, cellsProps }))
  ));

  return <MuiTable {...props}>{clonedChildren}</MuiTable>;
};
