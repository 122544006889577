import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import config from 'config';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import { styleMobileTableItem } from 'theme/mixins/table';
import { OrderStatusBadge, TrackingLink } from 'components';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  details: {
    padding: theme.spacing(2, 2),
  },
  detailsBorder: {
    borderLeft: `1px solid ${theme.palette.grey['215']}`,
    padding: theme.spacing(2, 2),
  },
  paper: {
    padding: 0,
  },
  featureLabel: {
    fontWeight: 500,
  },
  link: {
    fontSize: '0.875rem',
  },
  trackings: {
    '& > div': {
      margin: theme.spacing(0.5, 1),
      marginBottom: theme.spacing(1),
    },
    '& > div:first-of-type': {
      marginLeft: 0,
    },
    '& > div:last-child': {
      marginBottom: 0,
    },
  },
  button: {
    marginTop: theme.spacing(3),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.l10,
    textTransform: 'none',
  },
  chip: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(.5),
    borderRadius: theme.spacing(0.5),
    height: '26px',
    '& .MuiChip-label': {
      paddingLeft: theme.spacing(.75),
    },
  },
}));

export const OrderCard = ({ order }) => {
  const { classes } = useStyles();
  const history = useHistory();
  const t = useTranslator();

  const openCustomer = id => history.push(`/customers/${id}`);
  const openSupplier = id => history.push(`/edit-supplier/${id}`);
  const openPickupSheet = id => history.push(`/pickup-sheets/${id}`);

  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid className={classes.details} justifyContent="space-between" direction="column" item container xs={4}>
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography variant="body2" className={classes.featureLabel}>{t.translate('Number')}</Typography>
            </Grid>
            <Grid item align="right">
              <Link
                className={classes.link}
                href={`${config.tyre24BackOfficeUrl}/orderhistory/details/order/${order.number}/country/${order.country}`}
              >
                {order.number}
              </Link>
            </Grid>
          </Grid>
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography variant="body2" className={classes.featureLabel}>{t.translate('Customer')}</Typography>
            </Grid>
            <Grid item align="right">
              <Link className={classes.link} onClick={() => openCustomer(order.customer.id)}>{order.customer.number}</Link>
              <Typography variant="body2">{t.formatDate(order.date)} {t.formatTime(order.date)}</Typography>
              <Typography variant="body2">{order.customer.company}</Typography>
              <Typography variant="body2">{order.customer.name}</Typography>
            </Grid>
          </Grid>
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography variant="body2" className={classes.featureLabel}>{t.translate('Account Type')}</Typography>
            </Grid>
            <Grid item align="right">
              <Typography variant="body2">{order.accountType}</Typography>
              <Button
                className={classes.button}
                startIcon={<MailIcon />}
                href={order.contactEmail || ''}
                target="_blank"
              >
                {t.translate('Send a message via Tyre24')}
              </Button>
            </Grid>
          </Grid>

        </Grid>
        <Grid className={classes.detailsBorder} direction="column" item container xs={4}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="body2" className={classes.featureLabel}>{t.translate('Supplier')}</Typography>
            </Grid>
            <Grid item align="right">
              <Link className={classes.link} onClick={() => openSupplier(order.supplier.id)}>{order.supplier.number}</Link>
              <Typography variant="body2">{order.supplier.name}</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="body2" className={classes.featureLabel}>{t.translate('Order type')}</Typography>
            </Grid>
            <Grid item align="right">
              <Typography variant="body2">{t.translate(`supplierHandlingType:${order.supplier.handlingType}`)}</Typography>
            </Grid>
          </Grid>
          {order.pickupSheetId && (
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="body2" className={classes.featureLabel}>{t.translate('Pickup sheet')}</Typography>
              </Grid>
              <Grid item align="right">
                <Link className={classes.link} onClick={() => openPickupSheet(order.pickupSheetId)}>#{order.pickupSheetId}</Link>
              </Grid>
            </Grid>
          )}
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="body2" className={classes.featureLabel}>{t.translate('Status')}</Typography>
            </Grid>
            <Grid item align="right">
              <OrderStatusBadge order={order}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.detailsBorder} item container xs={4} direction="column">
          <Grid item>
            <Typography variant="body2" className={classes.featureLabel}>{t.translate('Tracking')}</Typography>
          </Grid>
          <Grid className={classes.trackings} item container style={{ flexWrap: 'wrap' }}>
            {Boolean(order?.tracking?.length) && (
              <TrackingLink
                tracking={order.tracking}
                LinkWrapper={({ children }) => <Typography variant="body2">{children}</Typography>}
                button
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

OrderCard.propTypes = {
  order: PropTypes.shape({
    customer: PropTypes.shape({
      company: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    supplier: PropTypes.shape({
      id: PropTypes.number.isRequired,
      number: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      handlingType: PropTypes.string.isRequired,
    }).isRequired,
    date: PropTypes.string.isRequired,
    pickupSheetId: PropTypes.number,
    contactEmail: PropTypes.string,
  }).isRequired,
};
