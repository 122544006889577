import * as React from 'react';
import PropTypes from 'prop-types';
import { chainPropTypes } from '@mui/utils';
import clsx from 'clsx';
import { withStyles } from 'tss-react/mui';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TableCell from '@mui/material/TableCell';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { TablePaginationActions } from './TablePaginationActions';
import { useId } from './useId';
import { defaultRowsPerPage } from 'consts';

export const styles = (theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    overflow: 'auto',
    '&:last-child': {
      padding: 0,
    },
  },
  toolbar: {
    minHeight: 52,
    paddingRight: 2,
  },
  spacer: {
    flex: '1 1 100%',
  },
  caption: {
    flexShrink: 0,
  },
  selectRoot: {
    marginRight: 32,
    marginLeft: 8,
  },
  select: {
    paddingLeft: 8,
    paddingRight: 24,
    textAlign: 'right',
    textAlignLast: 'right',
  },
  selectIcon: {},
  input: {
    color: 'inherit',
    fontSize: 'inherit',
    flexShrink: 0,
  },
  menuItem: {},
  actions: {
    flexShrink: 0,
    marginLeft: 20,
  },
});

function defaultLabelDisplayedRows({ from, to, count }) {
  return `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

function defaultGetAriaLabel(type) {
  return `Go to ${type} page`;
}

const TablePagination = React.forwardRef(function TablePagination(props, ref) {
  const {
    ActionsComponent = TablePaginationActions,
    backIconButtonProps,
    classes,
    className,
    colSpan: colSpanProp,
    component: Component = TableCell,
    count,
    getItemAriaLabel = defaultGetAriaLabel,
    labelDisplayedRows = defaultLabelDisplayedRows,
    labelRowsPerPage = 'Rows per page:',
    nextIconButtonProps,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    rowsPerPageOptions = defaultRowsPerPage,
    SelectProps = {},
    showFirstButton = true,
    showLastButton = true,
    ...other
  } = props;
  let colSpan;

  if (Component === TableCell || Component === 'td') {
    colSpan = colSpanProp || 1000; // col-span over everything
  }

  const selectId = useId(SelectProps.id);
  const labelId = useId(SelectProps.labelId);
  const MenuItemComponent = SelectProps.native ? 'option' : MenuItem;

  return (
    <Component className={clsx(classes.root, className)} colSpan={colSpan} ref={ref} {...other}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.spacer} />
        {rowsPerPageOptions.length > 1 && (
          <Typography color="inherit" variant="body2" className={classes.caption} id={labelId}>
            {labelRowsPerPage}
          </Typography>
        )}

        {rowsPerPageOptions.length > 1 && (
          <Select
            classes={{
              select: classes.select,
              icon: classes.selectIcon,
            }}
            input={<InputBase className={clsx(classes.input, classes.selectRoot)} />}
            value={rowsPerPage}
            onChange={onRowsPerPageChange}
            id={selectId}
            labelId={labelId}
            {...SelectProps}
          >
            {rowsPerPageOptions.map((rowsPerPageOption) => (
              <MenuItemComponent
                className={classes.menuItem}
                key={rowsPerPageOption.value ? rowsPerPageOption.value : rowsPerPageOption}
                value={rowsPerPageOption.value ? rowsPerPageOption.value : rowsPerPageOption}
              >
                {rowsPerPageOption.label ? rowsPerPageOption.label : rowsPerPageOption}
              </MenuItemComponent>
            ))}
          </Select>
        )}

        <ActionsComponent
          className={classes.actions}
          backIconButtonProps={backIconButtonProps}
          count={count}
          nextIconButtonProps={nextIconButtonProps}
          onPageChange={onPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          showFirstButton={showFirstButton}
          showLastButton={showLastButton}
          getItemAriaLabel={getItemAriaLabel}
        >
          <Typography color="inherit" variant="body2" className={classes.caption}>
            {labelDisplayedRows({
              from: count === 0 ? 0 : page * rowsPerPage + 1,
              to: count !== -1 ? Math.min(count, (page + 1) * rowsPerPage) : (page + 1) * rowsPerPage,
              count: count === -1 ? -1 : count,
              page,
            })}
          </Typography>
        </ActionsComponent>
      </Toolbar>
    </Component>
  );
});

TablePagination.propTypes = {
  ActionsComponent: PropTypes.elementType,
  backIconButtonProps: PropTypes.object,
  classes: PropTypes.object,
  className: PropTypes.string,
  colSpan: PropTypes.number,
  component: PropTypes.elementType,
  count: PropTypes.number.isRequired,
  getItemAriaLabel: PropTypes.func,
  labelDisplayedRows: PropTypes.func,
  labelRowsPerPage: PropTypes.node,
  nextIconButtonProps: PropTypes.object,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func,
  page: chainPropTypes(PropTypes.number.isRequired, (props) => {
    const { count, page, rowsPerPage } = props;

    if (count === -1) {
      return null;
    }

    const newLastPage = Math.max(0, Math.ceil(count / rowsPerPage) - 1);
    if (page < 0 || page > newLastPage) {
      return new Error(
        'Material-UI: The page prop of a TablePagination is out of range ' +
          `(0 to ${newLastPage}, but page is ${page}).`
      );
    }
    return null;
  }),
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }),
    ]).isRequired
  ),
  SelectProps: PropTypes.object,
  showFirstButton: PropTypes.bool,
  showLastButton: PropTypes.bool,
};

export default withStyles(TablePagination, styles, { name: 'MuiTablePagination' });
