import { makeStyles } from 'tss-react/mui';
import { styleMobileTableItem } from 'theme/mixins/table';

export const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  paper: {
    marginBottom: theme.spacing(0),
  },
  headline: {
    marginBottom: theme.spacing(-.5),
  },
  summaryGrid: {
    minHeight: '100%',
  },
  buttonsItem: {
    marginBottom: theme.spacing(-1.5),
    marginRight: theme.spacing(-1.5),
  },
}));
