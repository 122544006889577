import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';
import { omit } from 'lodash';
import { makeStyles } from 'tss-react/mui';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import { styleFormDialog } from 'theme/mixins/dialog';
import { createPurchase, modifyPurchase, correctPurchase } from 'api/purchases';
import { FormControlError, InputControl, LocalPagination, PriceControl } from 'components';
import { useDialog } from 'hooks';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import RemoveIcon from '@mui/icons-material/Remove';
import Input from '@mui/material/Input';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { selectActionPermission } from '../../store/selectors/account';
import { fetchSuppliersForFilter } from '../../services/suppliers';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { fetchWarehousesForFilter } from '../../services/warehouses';
import { selectOverviewSuppliers } from '../../store/selectors/suppliers';
import { extractApiErrors } from '../../utils/api';
import { Menu } from '@mui/material';
import { PurchaseStatuses } from '../../consts';
import { PurchaseProductsSelect } from '../../components';

import toaster from 'services/toaster';
import socket, { SOCKET_EVENTS } from 'bootstrap/socket';

const makeId = (postfix, index) => `purchase-dialog--${postfix}-${index}`;

const stockButtonSizeSpacing = 4.5;

const useStyles = makeStyles()((theme) =>
  styleFormDialog(theme, {
    content: {
      '&.MuiDialogContent-root': {
        width: 'auto',
        overflowY: 'initial',
        paddingBottom: theme.spacing(0.5),
        wordBreak: 'break-word',
      },
      '&.MuiDialogContent-root .MuiGrid-item': {
        paddingBottom: theme.spacing(1),
      },
      '&.MuiDialogContent-root .MuiGrid-item > p:first-of-type': {
        fontWeight: 500,
      },
    },
    button: {
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        right: theme.spacing(2),
        bottom: theme.spacing(2),
      },
    },
    stockSummary: {
      flexGrow: 1,
    },
    stockButton: {
      height: theme.spacing(stockButtonSizeSpacing),
      minHeight: theme.spacing(stockButtonSizeSpacing),
      minWidth: theme.spacing(stockButtonSizeSpacing),
      width: theme.spacing(stockButtonSizeSpacing),
      '& .MuiSvgIcon-root': {
        height: 12,
        width: 12,
      },
    },
    stockInput: {
      margin: theme.spacing(0, 1),
      width: 40,
      '& input': {
        textAlign: 'center',
      },
    },
    commentContainer: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    pagination: {
      display: 'flex',
      justifyContent: 'center',
    },
    minWidthColumn: {
      minWidth: 200,
    },
  }));

export const PurchaseDialog = ({
   onSuccess,
   onClose,
   isMobile,
   purchaseData,
   isCorrection,
   ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [purchase, setPurchase] = useState({
    id: '',
    warehouseId: '',
    supplierId: '',
    positions: [],
    comment: '',
  });
  const purchaseProductsSelectDialog = useDialog();
  const warehouses = useSelector(({ warehouses }) => warehouses.overview.data );
  const suppliers = useSelector(selectOverviewSuppliers);
  const warehouseId = useSelector(({ account }) => account.warehouse.id);
  const canSkipApprovalPurchasePermission = useSelector(selectActionPermission('purchases', 'skipApproval'));
  const [pagination, setPagination] = useState({ page: 0, rowsPerPage: 10 });

  useEffect(() => {
    if (purchaseData) {
      socket.emit(SOCKET_EVENTS.PURCHASES.EDITED_BY, { entityId: purchaseData.id, isEdited: true });

      setPurchase({
        id: purchaseData.id,
        warehouseId: purchaseData.warehouse.id,
        supplierId: purchaseData.supplier.id,
        positions: JSON.parse(JSON.stringify(purchaseData.positions)),
      });
    }
  }, [purchaseData]);

  useEffect(() => {
    dispatch(fetchWarehousesForFilter());
    dispatch(fetchSuppliersForFilter());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectProduct = () => {
    purchaseProductsSelectDialog.open();
  };

  const onAddPurchasePosition = (product) => {
    const positionsList = [...purchase.positions];
    const existedPosition = positionsList.find((position) => position.productId === product.id);
    if (!existedPosition) {
      positionsList.push({
        productId: product.id,
        productName: product.details[0]?.name,
        ean: product.ean || '',
        quantity: 1,
        purchasePrice: !isNaN(product.purchasePrice) ? Number(product.purchasePrice) : 0,
      });
      setPurchase({
        ...purchase,
        positions: positionsList,
      });
    }
  };

  const onRemovePurchasePosition = (product, productId = null) => {
    const positionsList = [...purchase.positions];
    const index = positionsList.findIndex((position) => position.productId === (productId || product.id));
    if (index !== -1) {
      positionsList.splice(index, 1);
      setPurchase({
        ...purchase,
        positions: positionsList,
      });
    }
  };

  const onChangeQuantity = (targetPosition, value, action) => {
    const positionsList = [...purchase.positions];
    switch (action) {
      case '+':
        targetPosition.quantity++;
        break;
      case '-':
        targetPosition.quantity--;
        break;
      default:
        targetPosition.quantity = value;
        break;
    }

    if (positionsList.length && (targetPosition.quantity < targetPosition.deliveredQuantity)) {
      targetPosition.quantity = targetPosition.deliveredQuantity;
    }

    setPurchase({
      ...purchase,
      positions: positionsList,
    });
  };

  const onSubmit = async (status) => {
    try {
      purchase.positions.forEach(position => {
        if (position.purchasePrice <= 0) {
          toaster.error({ content: t.translate('Purchase price must be positive.'), anchorOrigin: { vertical: 'bottom', horizontal: 'center' } });
          throw new Error('Purchase price must be positive.');
        }
        if (position.quantity <= 0) {
          toaster.error({ content: t.translate('Purchase quantity must be positive.'), anchorOrigin: { vertical: 'bottom', horizontal: 'center' } });
          throw new Error('Purchase quantity must be positive.');
        }
        position.purchasePrice = t.parsePrice(position.purchasePrice);
      });

      const payload = {
        warehouseId: values.warehouseId,
        supplierId: values.supplierId,
        status,
        positions: purchase.positions.map(position => omit(position, ['product', 'id'])),
        comment: values.comment,
      };

      if (purchase?.id && !isCorrection) {
        await modifyPurchase(purchase.id, payload);
      } else if (purchase?.id && isCorrection) {
        await correctPurchase(purchase.id, payload);
      } else {
        await createPurchase(payload);
      }
      onSuccess && onSuccess();

      await onCloseDialog();
    } catch (err) {
      setErrors(extractApiErrors(err));
    }
  };

  const {
    handleChange,
    setErrors,
    values,
    errors,
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: true,
    validationSchema: Yup
      .object()
      .shape({
        warehouseId: Yup
          .number()
          .required(t.translate('Please specify a warehouse.')),
        supplierId: Yup
          .number()
          .required(t.translate('Please specify a supplier.')),
        comment: Yup
          .string(),
      }),
    initialValues: {
      warehouseId: purchaseData?.warehouse?.id || warehouseId,
      supplierId: purchaseData?.supplier?.id || '',
      comment: purchaseData?.comment || '',
    },
    onSubmit,
  });

  const onCloseDialog = async () => {
    if (purchase?.id) {
      socket.emit(SOCKET_EVENTS.PURCHASES.EDITED_BY, { entityId: purchase.id, isEdited: false });
    }
    onClose();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const canSaveAsDraft = [PurchaseStatuses.draft, undefined].includes(purchase?.status);
  const canSaveAsApprovalNeeded = [PurchaseStatuses.draft, PurchaseStatuses.approvalNeeded, PurchaseStatuses.declined, undefined].includes(purchase?.status);
  const canSaveAsOpen = canSkipApprovalPurchasePermission && [PurchaseStatuses.draft, undefined].includes(purchase?.status);

  const getTitle = (purchaseData, isCorrection) => {
    return isCorrection ? 'Correction' :
      purchaseData ? 'Edit' : 'New';
  };

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      scroll="body"
      maxWidth="xl"
      open={props.open}
      onClose={onCloseDialog}
    >
      {
        !purchaseProductsSelectDialog.visible &&
        <DialogTitle>{t.translate(`${getTitle(purchaseData, isCorrection)} purchase`)}</DialogTitle>
      }
      <Grid container direction="column" justifyContent="space-between">
        <DialogContent className={classes.content}>
          {purchaseProductsSelectDialog.visible && (
            <PurchaseProductsSelect
              {...purchaseProductsSelectDialog.props}
              selectedProductIds={purchase.positions.map((position) => position.productId)}
              deliveredProductIds={purchase.positions.map((position) => position.deliveredQuantity && position.productId)}
              onAddPurchasePosition={onAddPurchasePosition}
              onRemovePurchasePosition={onRemovePurchasePosition}
              supplierId={values.supplierId}
            />
          )}
          {!purchaseProductsSelectDialog.visible && (
            <>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth error={!!errors.warehouseId}>
                    <InputLabel shrink htmlFor={props.id}>{t.translate('Warehouse')}</InputLabel>
                      <Select
                        disabled={isCorrection}
                        id={makeId('warehouse-id')}
                        name='warehouseId'
                        value={warehouses.length ? values.warehouseId : ''}
                        onChange={handleChange}
                      >
                        {warehouses.map(warehouse => (
                          <MenuItem key={warehouse.id} value={warehouse.id}>{warehouse.id} — {warehouse.name}</MenuItem>
                        ))}
                      </Select>
                      {errors.warehouseId && <FormControlError error={errors.warehouseId}/>}
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth error={!!errors.supplierId}>
                    <InputLabel shrink htmlFor={props.id}>{t.translate('Supplier')}</InputLabel>
                    <Select
                      disabled={isCorrection}
                      id={makeId('supplier-id')}
                      name="supplierId"
                      value={suppliers.length ? values.supplierId : ''}
                      onChange={handleChange}
                    >
                      {suppliers.map(supplier => (
                        <MenuItem key={supplier.id} value={supplier.id}>{supplier.number} — {supplier.name}</MenuItem>
                      ))}
                    </Select>
                    {errors.supplierId && <FormControlError error={errors.supplierId}/>}
                  </FormControl>
                </Grid>
              </Grid>
              {values.supplierId && values.warehouseId && (
                <Grid container spacing={2} justifyContent={'flex-end'}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => onSelectProduct()}
                    >
                      {t.translate('Add position')}
                    </Button>
                  </Grid>
                </Grid>
              )}
              {!!purchase.positions.length && (
                <>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell width={100}>{t.translate('Product ID')}</TableCell>
                          <TableCell className={classes.minWidthColumn}>{t.translate('Name')}</TableCell>
                          <TableCell className={classes.minWidthColumn}>{t.translate('Manufacturer no.')}</TableCell>
                          <TableCell className={classes.minWidthColumn}>{t.translate('Manufacturer')}</TableCell>
                          <TableCell width={200} align="center">{t.translate('EAN')}</TableCell>
                          <TableCell width={150} align="center">{t.translate('Purchase price')}</TableCell>
                          <TableCell width={200} align="center">{t.translate('Quantity')}</TableCell>
                          <TableCell width={120} align="center">{t.translate('Total net')}</TableCell>
                          <TableCell width={75} align="right">{t.translate('Actions')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {purchase.positions
                          .slice(pagination.page * pagination.rowsPerPage, (pagination.page + 1) * pagination.rowsPerPage)
                          .map((position) => {
                            return (
                              <TableRow key={`position-${position.id}`}>
                                <TableCell>{position.productId}</TableCell>
                                <TableCell>{position.productName || position.product?.details[0]?.name || '—'}</TableCell>
                                <TableCell>{position.product?.manufacturerNumber || '—'}</TableCell>
                                <TableCell>{position.product?.manufacturerName || '—'}</TableCell>
                                <TableCell align="center">{position.ean || '—'}</TableCell>
                                <TableCell align="center">
                                  <PriceControl
                                    id={makeId('purchase-price', position.id)}
                                    value={position.purchasePrice}
                                    onChange={({ target: { value } }) => {
                                      const positionsList = [...purchase.positions];
                                      position.purchasePrice = value;
                                      setPurchase({
                                        ...purchase,
                                        positions: positionsList,
                                      });
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    className={classes.stockButton}
                                    disabled={position.quantity <= 0 || (position.quantity === position.deliveredQuantity)}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => onChangeQuantity(position, null, '-')}
                                    aria-label="subtract stock"
                                  >
                                    <RemoveIcon/>
                                  </Button>
                                  <Input
                                    className={classes.stockInput}
                                    value={position.quantity}
                                    onChange={(e) => onChangeQuantity(position, isNaN(Number(e.target.value)) ? 0 : e.target.value)}
                                  />
                                  <Button
                                    className={classes.stockButton}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => onChangeQuantity(position, null, '+')}
                                    aria-label="add stock"
                                  >
                                    <AddIcon/>
                                  </Button>
                                </TableCell>
                                <TableCell align="right">
                                  {t.formatPrice(t.parsePrice(position.purchasePrice) * position.quantity)}&nbsp;&euro;
                                </TableCell>
                                <TableCell align="right">
                                  {position.quantity === 0 ?
                                    <IconButton
                                      variant="contained"
                                      onClick={() => onRemovePurchasePosition(position.product, position.productId)}
                                      aria-label="Deselect product"
                                      size="large">
                                      <RemoveCircleOutlineIcon/>
                                    </IconButton> : null
                                  }
                                </TableCell>
                              </TableRow>
                            );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <LocalPagination
                    defaultRowsPerPage={pagination.rowsPerPage}
                    count={purchase.positions.length}
                    className={classes.pagination}
                    onChange={setPagination}
                  />

                  <Grid item className={classes.commentContainer}>
                    <InputControl
                      id={makeId('comment')}
                      name="comment"
                      error={errors.comment}
                      label={t.translate('Comment')}
                      multiline
                      rows={3}
                      value={values.comment}
                      onChange={handleChange}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.button}>
          {!purchaseProductsSelectDialog.visible && (
            <Button color="primary" onClick={onCloseDialog}>
              {t.translate('dialog-action:Cancel')}
            </Button>
          )}

          {purchaseProductsSelectDialog.visible && (
            <Button color="primary" onClick={() => purchaseProductsSelectDialog.close()}>
              {t.translate('Next')}
            </Button>
          )}

          {!!purchase.positions.length && !purchaseProductsSelectDialog.visible && (
            <>
              <Button
                color="primary"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={isCorrection ? () => onSubmit('open') : handleClick}
              >
                {t.translate(isCorrection ? 'Save' : 'dialog-action:SaveAs')}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {canSaveAsDraft && (
                  <MenuItem onClick={() => onSubmit('draft')}>
                    {t.translate('status:draft')}
                  </MenuItem>
                )}
                {canSaveAsApprovalNeeded && (
                  <MenuItem onClick={() => onSubmit('approval-needed')}>
                    {t.translate('status:approval-needed')}
                  </MenuItem>
                )}
                {canSaveAsOpen && (
                  <MenuItem onClick={() => onSubmit('open')}>
                    {t.translate('status:open')}
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

PurchaseDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
