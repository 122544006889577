import { USERS } from 'store/types';
import { pack } from 'store/utils';

export const setOverviewUsers = payload => dispatch => dispatch(pack(USERS.OVERVIEW.SET_USERS, payload));

export const setDetailsUserWarehouseAvailability = (warehouseId, isEnabled) => dispatch =>
  dispatch(pack(USERS.DETAILS.SET_USER_WAREHOUSE_AVAILABILITY, { isEnabled, warehouseId }));

export const setDetailsWarehouses = payload => dispatch => dispatch(pack(USERS.DETAILS.SET_WAREHOUSES, payload));

export const setDetailsUser = payload => dispatch => dispatch(pack(USERS.DETAILS.SET_USER, payload));
