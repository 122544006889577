import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  drawer: {
    display: 'flex',
    flexDirection: 'column',
  },
  drawerPaper: {
    overflowX: 'hidden',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  thumbnail: {
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
    width: theme.spacing(9),
  },
  full: {
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
    width: theme.spacing(38),
  },
  divider: {
    backgroundColor: theme.palette.text.primary,
    opacity: .12,
  },
  menu: {
    padding: theme.spacing(1, 0),
    position: 'relative',
  },
  menuItem: {
    margin: theme.spacing(1, 0),
    overflow: 'hidden',
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      '&, & .MuiSvgIcon-root, & .MuiTypography-root': {
        color: theme.palette.primary.main,
      },
    },
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  menuItemIcon: {
    minWidth: 48,
  },
  menuItemText: {
    whiteSpace: 'nowrap',
  },
  navigationFooter: {
    alignItems: 'center',
    display: 'flex',
  },
  tooltipRight: {
    margin: theme.spacing(0, 1),
  },
}));
