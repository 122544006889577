import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useTranslator } from 'i18n';
import {
  useTableSortableHeadCell,
  useList,
} from 'hooks';

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  ActionButton,
  Pagination,
} from 'components';

import { wrapTableRowClick } from 'utils/html';
import tables from 'config/tables';

const useStyles = makeStyles()(theme => ({
  actionButtons: {
    whiteSpace: 'nowrap',
  },
  button: {
    color: theme.palette.primary.l900,
  },
  cellPadding: {
    '& .MuiTableRow-root .MuiTableCell-root:first-of-type': {
      paddingLeft: theme.spacing(3),
    },
    '& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:last-child': {
      paddingRight: theme.spacing(2),
    },
  },
}));

export const WarehousesTable = ({
  onWarehouseDelete,
  canDeleteWarehouse,
  totalEntities,
  warehouses,
 }) => {
  const history = useHistory();
  const list = useList(tables.warehouses.warehouses);
  const t = useTranslator();
  const { classes } = useStyles();

  const SortableCell = useTableSortableHeadCell({
    changeSorting: list.changeSorting,
    sorting: list.sorting,
  });

  const openWarehouse = warehouse => history.push(`/warehouses/${warehouse.id}`);

  return (
    <Paper>
      <TableContainer>
        <Table className={classes.cellPadding}>
          <TableHead>
            <TableRow>
              <SortableCell name="id">ID</SortableCell>
              <SortableCell name="name">{t.translate('Warehouse name')}</SortableCell>
              <SortableCell name="street">{t.translate('Street')}</SortableCell>
              <SortableCell name="house">No.</SortableCell>
              <TableCell name="name">{t.translate('Name')}</TableCell>
              <SortableCell name="zip">{t.translate('Zip code')}</SortableCell>
              <SortableCell name="city">{t.translate('City')}</SortableCell>
              <SortableCell name="country">{t.translate('Country')}</SortableCell>
              <TableCell align="right">{t.translate('Actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {warehouses.map((warehouse, index) => (
              <TableRow key={index} hover onClick={wrapTableRowClick(() => openWarehouse(warehouse))}>
                <TableCell>{warehouse.id}</TableCell>
                <TableCell>{warehouse.name}</TableCell>
                <TableCell>{warehouse.address.street}</TableCell>
                <TableCell>{warehouse.address.house}</TableCell>
                <TableCell>{warehouse.address.name}</TableCell>
                <TableCell>{warehouse.address.zip}</TableCell>
                <TableCell>{warehouse.address.city}</TableCell>
                <TableCell>{warehouse.address.country}</TableCell>
                <TableCell align="right" className={classes.actionButtons}>
                  {canDeleteWarehouse && (
                    <ActionButton
                      aria-label={t.translate('Delete the warehouse')}
                      tooltip={t.translate('Delete')}
                      icon={<DeleteIcon />}
                      onClick={() => onWarehouseDelete(warehouse)}
                      className={classes.button}
                    />
                  )}
                  <ActionButton
                    aria-label={t.translate('Open the warehouse')}
                    tooltip={t.translate('verb:Open')}
                    icon={<ChevronRightIcon />}
                    onClick={() => openWarehouse(warehouse)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination {...list.paginationProps} count={totalEntities} />
    </Paper>
  );
};

WarehousesTable.propTypes = {
  onWarehouseDelete: PropTypes.func.isRequired,
  canDeleteWarehouse: PropTypes.bool.isRequired,
  totalEntities: PropTypes.number,
  warehouses: PropTypes.array.isRequired,
};
