import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Color, BooleanIcon } from 'components';
import { styleMobileTableItem } from 'theme/mixins/table';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  root: {
    backgroundColor: theme.palette.backgrounds.grey,
  },
  storageItem: {
    borderBottom: `1px solid ${theme.palette.grey[250]}`,
    '&.MuiGrid-item': {
      padding: theme.spacing(1, 2),
    },
  },
}));

export const StorageLocationItemMobile = ({
  storage,
  storageProduct,
}) => {
  const { classes } = useStyles();
  const t = useTranslator();

  return (
    <Grid container direction="column" spacing={2} className={classes.root}>
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">ID</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{storage.id}</Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate('Type')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate(`storageType:${storage.type}`)}</Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate('Exclude from available stock')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <BooleanIcon truthy={storage.excludeStock} />
        </Grid>
      </Grid>
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate('Building')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{storage.building}</Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate('Floor')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{storage.floor || '—'}</Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate('Room')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{storage.room}</Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate('Row')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{storage.row}</Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate('Shelf')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{storage.shelf}</Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate('Level')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{storage.level || '—'}</Typography>
        </Grid>
      </Grid>
      {storage.boxNumber && (
        <Grid item container direction="row" className={classes.storageItem}>
          <Grid item xs={6}>
            <Typography variant="body2">{t.translate('Box number')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">{storage.boxNumber}</Typography>
          </Grid>
        </Grid>
      )}
      {storage.boxColor && (
        <Grid item container direction="row" className={classes.storageItem}>
          <Grid item xs={6}>
            <Typography variant="body2">{t.translate('Box color')}:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Color color={storage.boxColor} />
          </Grid>
        </Grid>
      )}
      {storageProduct.color && (
        <Grid item container direction="row" className={classes.storageItem}>
          <Grid item xs={6}>
            <Typography variant="body2">{t.translate('Color')}:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Color color={storageProduct.color} />
          </Grid>
        </Grid>
      )}
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate('Width')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{storage.width !== null ? `${numeral(storage.width).format('0.00')} cm` : '—'}</Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate('Height')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{storage.height !== null ? `${numeral(storage.height).format('0.00')} cm` : '—'}</Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate('Length')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{storage['length'] !== null ? `${numeral(storage['length']).format('0.00')} cm` : '—'}</Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate('Volume')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{storage.volume !== null ? `${numeral(storage.volume).format('0.000000')} m` : '—'}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

StorageLocationItemMobile.propTypes = {
  storage: PropTypes.shape({
    boxNumber: PropTypes.string,
    boxColor: PropTypes.string,
    building: PropTypes.string.isRequired,
    floor: PropTypes.string,
    shelf: PropTypes.string,
    level: PropTypes.string,
    room: PropTypes.string,
    row: PropTypes.string,
    volume: PropTypes.number,
    length: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    excludeStock: PropTypes.bool,
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  storageProduct: PropTypes.shape({
    id: PropTypes.number.isRequired,
    color: PropTypes.string,
  }).isRequired,
};
