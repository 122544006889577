import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  paper: {
    padding: theme.spacing(1.5, 2),
  },
  accordion: {
    '&.MuiAccordion-root.Mui-expanded': {
      marginTop: 0,
    },
    '& .MuiAccordionSummary-root': {
      minHeight: `${theme.spacing(6)} !important`,
      padding: theme.spacing(0, 2),
    },
    '& .MuiAccordionSummary-content': {
      marginBottom: `${theme.spacing(1.5)} !important`,
      marginTop: `${theme.spacing(1.5)} !important`,
    },
  },
  accordionDetails: {
    padding: theme.spacing(.5, 0, 1),
  },
  sectionGrid: {
    borderTopColor: theme.palette.grey['250'],
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    '&.MuiGrid-item': {
      padding: theme.spacing(1, 2.5, 1.5),
    },
  },
  actionsGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(.5),
  },
}));
