import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslator } from 'i18n';
import {
  useScreen,
  useDialog,
  useList,
  didMount, useRoute,
} from 'hooks';
import {
  DesktopView,
} from 'views/purchases/overview';
import {
  ConfirmingDialog,
  PurchaseDialog,
} from 'dialogs';
import { Fab } from 'components';
import { fetchSuppliersForFilter } from 'services/suppliers';
import { fetchPurchases } from 'services/purchases';
import {
  selectActionPermission,
  selectAuthenticatedUser,
  selectAuthenticatedUserWarehouse,
} from 'store/selectors/account';
import tables from 'config/tables';
import AddIcon from '@mui/icons-material/Add';
import {
  approvePurchase,
  declinePurchase,
  deletePurchase,
} from 'api/purchases';
import toaster from 'services/toaster';
import socket, { SOCKET_EVENTS } from 'bootstrap/socket';

export const Purchases = () => {
  const location = useLocation();
  const route = useRoute();
  const history = useHistory();
  const dispatch = useDispatch();
  const screen = useScreen();
  const list = useList(tables.purchases.purchases);
  const t = useTranslator();

  const confirmDeleteDialog = useDialog({ purchase: null });
  const confirmApproveDialog = useDialog({ purchase: null });
  const confirmDeclineDialog = useDialog({ purchase: null });

  const [isFirstRequest, setIsFirstRequest] = useState(true);

  const onDeletePurchase = async () => {
    try {
      await deletePurchase(confirmDeleteDialog.data.purchase.id);
      toaster.success(t.translate('Purchase has been deleted successfully.'));
      await fetchData();
    } catch (err) {}
  };

  const onApprovePurchase = async () => {
    try {
      await approvePurchase(confirmApproveDialog.data.purchase.id);
      toaster.success(t.translate('Purchase has been approved successfully.'));
      await fetchData();
    } catch (err) {}
  };

  const onDeclinePurchase = async () => {
    try {
      await declinePurchase(confirmDeclineDialog.data.purchase.id);
      toaster.success(t.translate('Purchase has been declined successfully.'));
      await fetchData();
    } catch (err) {}
  };

  const purchaseDialog = useDialog(null);

  const authenticatedUser = useSelector(selectAuthenticatedUser);

  const canEditPurchasePermission = useSelector(selectActionPermission('purchases', 'edit'));
  const hasSupervisorPermission = useSelector(selectActionPermission('purchases', 'supervisor'));

  const canCreatePurchase = useSelector(selectActionPermission('purchases', 'create'));
  const warehouse = useSelector(selectAuthenticatedUserWarehouse);

  didMount(() => {
    if (!route.search.status) {
      const encodedUrl = '/purchases?status%5B1%5D=open&status%5B2%5D=approval-needed&status%5B3%5D=draft';
      history.push(encodedUrl);
    }
    setIsFirstRequest(false);

    socket.on(SOCKET_EVENTS.PURCHASES.UPDATE, async () => {
      await fetchData();
    });
  });

  useEffect(() => {
    dispatch(fetchSuppliersForFilter());
  }, [dispatch]);

  const fetchData = useCallback(() => {
    dispatch(fetchPurchases(list.compile()));
  }, [dispatch, list]);

  useEffect(() => {
    if (!isFirstRequest) {
      fetchData();
    }
  }, [fetchData, isFirstRequest, list.filters, location.search, warehouse]);

  return (
    <>
      <Helmet>
        <title>{t.translate('Purchasing')} | {t.translate('app:title')}</title>
      </Helmet>

      {purchaseDialog.visible && (
        <PurchaseDialog
          {...purchaseDialog.props}
          isMobile={screen.isMobile}
          onSuccess={fetchData}
        />
      )}

      <ConfirmingDialog
        {...confirmDeleteDialog.props}
        title={t.translate('Delete purchase')}
        acceptButtonText={t.translate(`Yes, I'm sure`)}
        declineButtonText={t.translate('No, cancel')}
        onAccept={onDeletePurchase}
      >
        {t.translate('Are you sure you want to delete the purchase?')}
      </ConfirmingDialog>

      <ConfirmingDialog
        {...confirmApproveDialog.props}
        title={t.translate('Approve purchase')}
        acceptButtonText={t.translate(`Yes, I'm sure`)}
        declineButtonText={t.translate('No, cancel')}
        onAccept={onApprovePurchase}
      >
        {t.translate('Are you sure you want to approve the purchase?')}
      </ConfirmingDialog>

      <ConfirmingDialog
        {...confirmDeclineDialog.props}
        title={t.translate('Decline purchase')}
        acceptButtonText={t.translate(`Yes, I'm sure`)}
        declineButtonText={t.translate('No, cancel')}
        onAccept={onDeclinePurchase}
      >
        {t.translate('Are you sure you want to decline the purchase?')}
      </ConfirmingDialog>

      <Fab
        disabled={!canCreatePurchase}
        onClick={() => purchaseDialog.open()}
        aria-label="New purchase"
        variant="extended"
      >
        <AddIcon/>
        {t.translate('New purchase')}
      </Fab>

      <DesktopView
        isMobile={screen.isMobile}
        confirmApproveDialog={confirmApproveDialog}
        confirmDeclineDialog={confirmDeclineDialog}
        confirmDeleteDialog={confirmDeleteDialog}
        authenticatedUser={authenticatedUser}
        canEditPurchasePermission={canEditPurchasePermission}
        hasSupervisorPermission={hasSupervisorPermission}
      />
    </>
  );
};
