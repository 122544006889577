import { CUSTOMERS } from '../types';
import { customers as is } from '../initialState';

const setActiveCustomerLoading = (state, payload) => ({
  ...state,
  activeCustomerLoading: payload,
});

const setActiveCustomer = (state, customer) => ({
  ...state,
  activeCustomer: customer,
});

const setCustomersLoading = (state, payload) => {
  const customersData = state.customers;
  return { ...state,
    customers: {
      ...customersData,
      customersLoading: payload,
    },
  };
};

const setCustomers = (state, { data, meta }) => {
  const customersData = state.customers;
  return { ...state,
    customers: {
      ...customersData,
      data,
      meta,
    },
  };
};

export default (state = is, { type, payload }) => {
  switch (type) {
    case CUSTOMERS.SET_ACTIVE_CUSTOMER:
      return setActiveCustomer(state, payload);
    case CUSTOMERS.SET_CUSTOMERS:
      return setCustomers(state, payload);
    case CUSTOMERS.SET_ACTIVE_CUSTOMER_LOADING:
      return setActiveCustomerLoading(state, payload);
    case CUSTOMERS.SET_CUSTOMERS_LOADING:
      return setCustomersLoading(state, payload);
    default:
      return state;
  }
};
