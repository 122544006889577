import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';
import {
  useTableSortableHeadCell,
  useList,
} from 'hooks';

import TableContainer from '@mui/material/TableContainer';
import { makeStyles } from 'tss-react/mui';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';

import { StorageRow } from '../StorageRow/StorageRow';

import {
  Pagination,
} from 'components';
import tables from 'config/tables';

const useStyles = makeStyles()(theme => ({
  lastTableCell: {
    paddingRight: theme.spacing(3),
  },
}));

export const StoragesTable = ({
  onStorageDelete,
  onStoragePrint,
  onStorageEdit,
  canDeleteStorage,
  canEditStorage,
  storagesMetadata,
  storages,
}) => {
  const t = useTranslator();
  const list = useList(tables.storages.storages);
  const { classes } = useStyles();

  const SortableCell = useTableSortableHeadCell({
    changeSorting: list.changeSorting,
    sorting: list.sorting,
  });

  return (
    <Paper>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <SortableCell name="id">ID</SortableCell>
              <SortableCell name="warehouseName">{t.translate('Warehouse')}</SortableCell>
              <SortableCell name="building">{t.translate('Building')}</SortableCell>
              <SortableCell name="floor">{t.translate('Floor')}</SortableCell>
              <SortableCell name="room">{t.translate('Room')}</SortableCell>
              <TableCell name="volume">{t.translate('Volume')}, m<sup>3</sup></TableCell>
              <TableCell align="right" className={classes.lastTableCell}>
                {t.translate('Actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {storages.map((storage, index) => (
              <StorageRow
                key={index}
                storage={storage}
                canEditStorage={canEditStorage}
                canDeleteStorage={canDeleteStorage}
                onStorageDelete={onStorageDelete}
                onStoragePrint={onStoragePrint}
                onStorageEdit={onStorageEdit}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination {...list.paginationProps} count={storagesMetadata.totalEntities} />
    </Paper>
  );
};

StoragesTable.propTypes = {
  onStorageDelete: PropTypes.func.isRequired,
  onStoragePrint: PropTypes.func.isRequired,
  onStorageEdit: PropTypes.func.isRequired,
  canDeleteStorage: PropTypes.bool.isRequired,
  canEditStorage: PropTypes.bool.isRequired,
  storagesMetadata: PropTypes.shape({
    totalEntities: PropTypes.number.isRequired,
  }).isRequired,
  storages: PropTypes.arrayOf(
    PropTypes.shape({
      warehouse: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      boxNumber: PropTypes.string,
      boxColor: PropTypes.string,
      building: PropTypes.string.isRequired,
      floor: PropTypes.string,
      shelf: PropTypes.string,
      level: PropTypes.string,
      room: PropTypes.string,
      row: PropTypes.string,
      volume: PropTypes.number,
      length: PropTypes.number,
      height: PropTypes.number,
      width: PropTypes.number,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
};
