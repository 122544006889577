import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import Typography from '@mui/material/Typography';
import { useStyles } from './AdjustPositionItem.jsx';
import Grid from '@mui/material/Grid';

import {
  StockFormControl,
} from 'components';

const makeName = (id, name) => `${id}.${name}`;

export const AdjustPositionItem = ({
  setCancelAllQuantity,
  handleChange,
  position,
  setFieldValue,
  values,
  errors,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <>
      <Grid container className={classes.root} direction="column">
        <Grid container className={classes.containerItem} direction="column">
          <Typography variant="body2" className={classes.bold}>{position.product.details[0]?.name || '—'}</Typography>
          <Grid container>
            <Typography variant="body2" className={classes.bold}>EAN:&nbsp;</Typography>
            <Typography variant="body2">{position.product.ean || '—'}</Typography>
          </Grid>

          <Grid container >
            <Grid container direction="column">
              <Grid container>
                <Typography variant="body2" className={classes.bold}>{t.translate('Product-ID')}:&nbsp;</Typography>
                <Typography variant="body2">{position.product.id}</Typography>
              </Grid>
              <Grid container>
                <Typography variant="body2" className={classes.bold}>{t.translate('Ordered')}:&nbsp;</Typography>
                <Typography variant="body2">{position.quantity}</Typography>
              </Grid>
            </Grid>

            <Grid container direction="column-reverse">
              <Grid item className={classes.stockFormControl}>
                <StockFormControl
                  hideLabels
                  name={makeName(position.id, 'addedAmount')}
                  actions={['add']}
                  stock={0}
                  value={values.positions[position.id].addedAmount}
                  onChange={(value) => setFieldValue(`positions.${position.id}.addedAmount`, value)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

AdjustPositionItem.propTypes = {
  handleChange: PropTypes.func.isRequired,
  position: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    product: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
