import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';
import { useForm } from 'hooks/form';
import toaster from 'services/toaster';
import { didUpdate } from 'hooks/lifecycle';
import { useProductCodesFormControls } from 'hooks/products';

import { useStyles } from './style';
import CircularProgress from '@mui/material/CircularProgress';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ToggleButton from '@mui/material/ToggleButton';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';

import SelectingProductDialog, { ListingButton } from 'dialogs/SelectingProductDialog/SelectingProductDialog';

import { storageBoxColors } from 'config/colors';

import {
  QrCodeScanDialog,
} from 'dialogs';

import { SupplierAutocomplete, useSupplierAutocomplete } from 'autocompletes';
import {
  StorageQrCodeControl,
  ProductTypeControl,
  StockFormControl,
  EanCodeControl,
  PhotoControl,
  InputControl,
  BusyButton,
} from 'components';

import { StorageItem } from './StorageItem/StorageItem';

import { createStorageProduct, fetchStorageProducts } from 'api/storage-products';
import { fetchProductTypes } from 'api/products';
import { StockOperationReasons } from 'consts';

const makeId = postfix => `booking-stock-dialog--${postfix}`;

export const BookingStockDialog = ({
  onClose,
  onSave,
  storageCriteria,
  storageProduct,
  product,
  isMobileView,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const [scannersVisibility, setScannersVisibility] = useState({
    ean: false,
    qr: false,
  });

  const [sourceStorageLocation, setSourceStorageLocation] = useState(null);

  const closeScanner = type => () => setScannersVisibility({ ...scannersVisibility, [type]: false });

  const [productPhoto, setProductPhoto] = useState(null);
  const [storage, setStorage] = useState(null);
  const [types, setTypes] = useState([]);

  const form = useForm({
    manual: true,
    manufacturerNumber: '',
    manufacturerName: '',
    supplierNumber: '',
    productName: '',
    ...(storageProduct ? {
      productImageUrl: storageProduct.product.imageUrl || '',
      storageNumber: storageProduct.storage.id.toString(),
      productEan: storageProduct.product.ean || '',
      length: storageProduct.product.length || '',
      height: storageProduct.product.height || '',
      width: storageProduct.product.width || '',
      color: storageProduct.color || 'black',
      productAlzuraId: storageProduct.product.alzuraId || '',
      productTypeId: storageProduct.product.type?.id || null,
      productId: storageProduct.product.id,
    } : {
      storageNumber: '',
      color: null,
      ...(product ? {
        productImageUrl: product.imageUrl || '',
        productEan: product.ean || '',
        length: product.length || '',
        height: product.height || '',
        width: product.width || '',
        productAlzuraId: product.alzuraId || '',
        productTypeId: product.type?.id || null,
        productId: product.id,
      } : {
        productImageUrl: '',
        productEan: '',
        length: '',
        height: '',
        width: '',
        productAlzuraId: '',
        productTypeId: null,
        productId: null,
      }),
    }),
    packingUnit: '',
    comment: '',
    reason: StockOperationReasons.CORRECTION,
    weight: '',
    stock: 0,
    manufacturerId: '',
  });
  const {
    catchHttpError,
    isModelValid,
    setFormValue,
    resetForm,
    setValue,
    errors,
    model,
  } = form;

  const {
    changeProductCode,
    updateProducts,
    selectProduct,
    selectingDialog,
    foundB2BProduct,
    foundProduct,
  } = useProductCodesFormControls({
    setManufacturerNumber: setValue('manufacturerNumber'),
    setProductEan: setValue('productEan'),
    setProductAlzuraId: setValue('productAlzuraId'),
    setRestProductData: ({ b2bProduct, product }) => {
      if (product) {
        setProductPhoto(product.photo || null);
        setValue('manufacturerName')('');
        setValue('productImageUrl')(product.imageUrl || '');
        setValue('productName')('');
        setValue('length')(product.length || '');
        setValue('height')(product.height || '');
        setValue('width')(product.width || '');
        setValue('manufacturerId')('');
        setValue('productEan')(product.ean);
        setValue('productTypeId')(product.type?.id || null);
        setValue('productId')(product.id);
      } else {
        setProductPhoto(null);
        setValue('productId')(null);
        if (b2bProduct) {
          setValue('manufacturerName')(b2bProduct.manufacturerName || '');
          setValue('productImageUrl')(b2bProduct.imageUrls[0] || '');
          setValue('productName')(b2bProduct.name || '');
          setValue('length')(b2bProduct.length || '');
          setValue('height')(b2bProduct.height || '');
          setValue('width')(b2bProduct.width || '');
          setValue('manufacturerId')(b2bProduct.manufacturerId || '');
          setValue('productEan')(b2bProduct.ean || '');
          setValue('productTypeId')(b2bProduct.type?.id || null);
        } else {
          setValue('manufacturerName')('');
          setValue('productImageUrl')('');
          setValue('productName')('');
          setValue('length')('');
          setValue('height')('');
          setValue('width')('');
          setValue('manufacturerId')('');
          setValue('productTypeId')(null);
        }
      }
    },
  });

  const supplierAutocomplete = useSupplierAutocomplete({ form });

  useEffect(() => {
    let mounted = true;
    const getProductTypes = async () => {
      try {
        const productTypes = await fetchProductTypes();
        if (mounted) {
          setTypes(productTypes.data);
        }
      } catch (err) {}
    };
    getProductTypes();
    return () => mounted = false;
  }, []);

  useEffect(() => {
    const getStorageProductColor = async (params) => {
      const storageProduct = await fetchStorageProducts(params);
      if (storageProduct.data[0]) {
        setValue('color')(storageProduct.data[0]?.color);
      } else {
        setValue('color')('black');
      }
    };

    if ((storage && !storage.boxColor) && supplierAutocomplete.selectedOption?.id && model.productId) {
      getStorageProductColor({ productId: model.productId, storageId: storage.id, supplierId: supplierAutocomplete.selectedOption?.id });
    } else {
      setValue('color')('black');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storage?.id, supplierAutocomplete.selectedOption?.id, model.productId]);

  didUpdate(() => {
    if (props.open) {
      setProductPhoto(storageProduct?.product?.photo || product?.photo || null);
      setStorage(storageProduct?.storage || null);
      resetForm({ supplierNumber: model.supplierNumber });
      selectingDialog.reset();
    }
  }, [props.open]);

  const validateModel = errors => {
    if (!storage) {
      errors.storageNumber = t.translate('Please specify an existing storage.');
    }
    if (!model.productEan && !model.productAlzuraId && !model.productId) {
      errors.productEan = t.translate('Please specify a product.');
    }
    if (!model.productId && !supplierAutocomplete.selectedOption && model.supplierNumber.trim()) {
      errors.supplierNumber = t.translate('Please specify an existing supplier.');
    }
    if (!supplierAutocomplete.selectedOption) {
      errors.supplierNumber = t.translate('Please specify an existing supplier.');
    }
    if (!model.stock) {
      toaster.error({ content: t.translate('Stock can not equal 0.'), anchorOrigin: { vertical: 'bottom', horizontal: 'center' } });
      errors.stock = t.translate('Stock can not equal 0.');
    }
  };

  const submit = async () => {
    try {
      if (!isModelValid(validateModel)) {
        return;
      }

      const payload = [{
        ...(({
          supplierNumber,
          storageNumber,
          productId,
          lastSubmittedPrice,
          cheapestMarketPrice,
          cheapestMarketPrice2,
          cheapestMarketPrice3,
          ...model
        }) => ({
          ...model,
          ...(productId && { productId }),
        }))(model),
        ...(productPhoto && { productPhotoId: productPhoto.id }),
        ...(supplierAutocomplete.selectedOption && { supplierId: supplierAutocomplete.selectedOption.id }),
        productFromApi: !!foundB2BProduct,
        storageId: storage.id,
      }];

      await createStorageProduct(payload);
      toaster.success(t.translate('New storage has been created successfully.'));

      onSave();
      onClose();
    } catch (err) {
      catchHttpError(err);
    }
  };

  return (
    <>
      {props.open && (
        selectingDialog.data.products ||
        selectingDialog.data.b2bProducts
      ) && (
        <SelectingProductDialog
          {...selectingDialog.props}
          isMobileView={isMobileView}
          updateProducts={updateProducts}
          onSelect={selectProduct(selectingDialog.options.properties)}
        />
      )}

      <QrCodeScanDialog
        open={scannersVisibility.qr}
        onClose={closeScanner('qr')}
        onDecode={id => changeProductCode({ id })}
      />

      <Dialog
        {...props}
        scroll="body"
        fullWidth
        maxWidth="sm"
        fullScreen={isMobileView}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        className={classes.root}
      >
        <DialogTitle id="max-width-dialog-title">{t.translate('Add stock')}</DialogTitle>
        <DialogContent className={classes.root}>
          {isMobileView ? (
            <>
              <Grid
                container
                spacing={1}
                justifyContent="space-between"
                alignItems="flex-end"
                className={classes.container}
              >
                <Grid item md={6} xs={12}>
                  <SupplierAutocomplete
                    {...supplierAutocomplete.props}
                    label={t.translate('Supplier')}
                    error={errors.supplierNumber}
                    getOptionsOnLoad={true}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                justifyContent="space-between"
                alignItems="flex-end"
                className={classes.container}
              >
                <EanCodeControl
                  disabled
                  id={makeId('product-ean')}
                  error={errors.productEan}
                  label={t.translate('Product EAN code')}
                  value={model.productEan}
                  onChange={productEan => changeProductCode({ ean: productEan })}
                  endAdornment={(<>
                    {(selectingDialog.options.fetching.id || selectingDialog.options.fetching.ean) && <CircularProgress color="inherit" size={24} />}
                    {selectingDialog.options.properties.ean && <ListingButton onClick={() => selectingDialog.open()} />}
                  </>)}
                />
              </Grid>
            </>
          ) : (
            <Grid
              container
              spacing={1}
              justifyContent="space-between"
              alignItems="flex-end"
              className={classes.container}
            >
              <Grid item md={6} xs={12}>
                <SupplierAutocomplete
                  {...supplierAutocomplete.props}
                  label={t.translate('Supplier')}
                  error={errors.supplierNumber}
                  getOptionsOnLoad={true}
                />
              </Grid>
              <Grid item md={6}>
                <EanCodeControl
                  disabled
                  id={makeId('product-ean')}
                  error={errors.productEan}
                  label={t.translate('Product EAN code')}
                  value={model.productEan}
                  onChange={productEan => changeProductCode({ ean: productEan })}
                  endAdornment={(<>
                    {(selectingDialog.options.fetching.id || selectingDialog.options.fetching.ean) && <CircularProgress color="inherit" size={24} />}
                    {selectingDialog.options.properties.ean && <ListingButton onClick={() => selectingDialog.open()} />}
                  </>)}
                />
              </Grid>
            </Grid>
          )}
          <Grid
            container
            spacing={1}
            direction={isMobileView ? 'column' : 'row'}
            justifyContent="space-between" alignItems="flex-end"
            className={classes.container}
          >
            <Grid item md={6} xs={12} className={classes.containerAlzuraId}>
              <InputControl
                disabled
                id={makeId('product-alzura-id')}
                error={errors.productAlzuraId}
                label="Alzura ID"
                value={model.productAlzuraId}
                onChange={({ target: { value } }) => changeProductCode({ alzuraId: value })}
                endAdornment={(<>
                  {selectingDialog.options.fetching.alzuraId && <CircularProgress color="inherit" size={24} />}
                  {selectingDialog.options.properties.alzuraId && <ListingButton onClick={() => selectingDialog.open()} />}
                </>)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProductTypeControl
                disabled
                id={makeId('type')}
                error={errors.productTypeId}
                label={t.translate('Product type')}
                types={types}
                value={model.productTypeId || 0}
                product={foundB2BProduct || foundProduct || product}
                onChange={setValue('productTypeId')}
              />
            </Grid>
          </Grid>
          <Grid container
            justifyContent="space-between"
            direction={isMobileView ? 'column' : 'row'}
            className={classes.containerPhoto}
          >
            <Grid item md={6}>
              <PhotoControl
                disabledControl
                label={t.translate('Product photo')}
                photo={productPhoto || model.productImageUrl}
                onChange={setProductPhoto}
                onDelete={() => productPhoto ? setProductPhoto(null) : setValue('productImageUrl')('')}
              />
            </Grid>
            <Grid item md={6}/>
          </Grid>
          <Grid container
            direction="column"
            className={classes.containerDetails}
          >
            <Typography variant="body1">{t.translate('Storage')}</Typography>
            <Grid container
              direction="column"
              spacing={1}
            >
              <StorageQrCodeControl
                id={makeId('source-storage-number')}
                error={errors.storageNumber}
                label={t.translate('Source storage location')}
                value={model.storageNumber}
                name="storageNumber"
                onChange={setValue('storageNumber')}
                onLoad={async storageNumber => {
                  setStorage(storageNumber);
                  setSourceStorageLocation(storageNumber);
                }}
              />
              {sourceStorageLocation && model.storageNumber && <StorageItem storage={sourceStorageLocation} />}
            </Grid>
          </Grid>
          {sourceStorageLocation && !sourceStorageLocation?.boxColor && model.storageNumber && (
            <>
              <Typography variant="body2">{t.translate('Color')}</Typography>

              <Grid item container
                className={classes.storageItem}
              >
                <ToggleButtonGroup
                  value={model.color}
                  onChange={(event, color) => setValue('color')(color)}
                  exclusive
                  className={classes.toggleButton}
                >
                  {Object.entries(storageBoxColors).map(([color, hex]) => (
                    <ToggleButton
                      key={color}
                      variant="outlined"
                      style={color !== 'black' ? { color: hex, borderColor: hex } : { borderStyle: 'dashed' }}
                      className={classes.button}
                      value={color}
                    >
                      {model.color === color && <CheckCircleIcon />}
                      {color !== 'black' ? t.translate(color): t.translate('no color')}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
            </>
          )}
          <Typography variant="body1">{t.translate('Stock')}</Typography>
          <Grid container spacing={1} justifyContent="center" className={classes.stockFormControl}>
            <StockFormControl
              actions={['add']}
              stock={0}
              value={model.stock}
              onChange={setValue('stock')}
            />
          </Grid>
          <Grid
            container
            direction="column"
            className={classes.containerAdditionalInfo}
          >
            <Typography>{t.translate('Addition info')}</Typography>
            <Grid item>
              <InputControl
                id={makeId('comment')}
                error={errors.comment}
                label={t.translate('Comment')}
                value={model.comment}
                onChange={setFormValue('comment')}
              />
            </Grid>
          </Grid>
          <DialogActions className={classes.dialogActions}>
            <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
            <BusyButton color="primary" submit onClick={submit}>{t.translate('Save')}</BusyButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

const ProductPropType = PropTypes.shape({
  generalPrice: PropTypes.number,
  medianPrice: PropTypes.number,
  imageUrl: PropTypes.string,
  photo: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  ean: PropTypes.string,
  alzuraId: PropTypes.string,
  id: PropTypes.number.isRequired,
  type: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
});

BookingStockDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  storageCriteria: PropTypes.object,
  storageProduct: PropTypes.shape({
    storage: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    product: ProductPropType.isRequired,
  }),
  product: ProductPropType,
  isMobileView: PropTypes.bool.isRequired,
};
