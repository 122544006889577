import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const FullScreenDialog = ({
  form,
  children,
  ...props
}) => {

  const boxProps = {
    ...(form && {
      component: 'form',
      active: '#',
    }),
  };

  return (
    <Dialog
      fullScreen
      scroll="body"
      {...props}
      TransitionComponent={Transition}
    >
      <Box {...boxProps}>{children}</Box>
    </Dialog>
  );
};

FullScreenDialog.propTypes = {
  form: PropTypes.bool,
};
