import { useState } from 'react';
import isPlainObject from 'lodash/isPlainObject';
import get from 'lodash/get';

const initialize = (initialModel, path, plainPaths) => Object
  .entries(initialModel)
  .reduce(
    ([model, modelSetters, errors, errorSetters], [key, initialValue]) => {
      if (isPlainObject(initialValue) && !plainPaths.includes([...path, key].join('.'))) {
        const [modelN, modelSettersN, errorsN, errorSettersN] = initialize(initialValue);
        return [
          Object.assign(model, { [key]: modelN }),
          Object.assign(modelSetters, { [key]: modelSettersN }),
          Object.assign(errors, { [key]: errorsN }),
          Object.assign(errorSetters, { [key]: errorSettersN }),
        ];
      } else {
        const [value, modelSetter] = useState(initialValue);
        const [error, errorSetter] = useState(null);
        return [
          Object.assign(model, { [key]: value }),
          Object.assign(modelSetters, { [key]: modelSetter }),
          Object.assign(errors, { [key]: error }),
          Object.assign(errorSetters, { [key]: errorSetter }),
        ];
      }
    },
    [{}, {}, {}, {}]
  );

export const useForm = (initialModel, { plainPaths = [] } = {}) => {
  const [model, modelSetters, errors, errorSetters] = initialize(initialModel, [], plainPaths);

  const resetModel = (setters, path, modelToBeKept = {}) => Object
    .entries(setters)
    .forEach(
      ([key, setter]) => isPlainObject(setter) && !plainPaths.includes([...path, key].join('.'))
        ? resetModel(setter, [...path, key], modelToBeKept?.[key])
        : setter(
            typeof modelToBeKept?.[key] !== 'undefined'
              ? modelToBeKept[key]
              : path.length
                ? get(initialModel, path.join('.'))[key]
                : initialModel[key]
          )
    );

  const resetErrors = (setters, path) => Object
    .entries(setters)
    .forEach(
      ([key, setter]) => isPlainObject(setter) && !plainPaths.includes([...path, key].join('.'))
        ? resetErrors(setter, [...path, key])
        : setter(null)
    );

  const resetForm = modelToBeKept => {
    resetModel(modelSetters, [], modelToBeKept);
    resetErrors(errorSetters, []);
  };

  const setValue = path => value => {
    get(modelSetters, path)(value);
    get(errorSetters, path)(null);
  };

  const toggleFormValue = path => e => setValue(path)(e.target.checked);
  const setFormValue = path => e => setValue(path)(e.target.value);

  const setErrors = errors => Object
    .entries(errors)
    .forEach(([path, error]) => get(errorSetters, path)(error));

  const isModelValid = validateModel => {
    const errors = {};
    validateModel(errors);
    const isValid = !Object.keys(errors).length;
    if (!isValid) {
      setErrors(errors);
    }
    return isValid;
  };

  const catchHttpError = err => err.isHttp && err.collection && setErrors(err.collection);

  return {
    toggleFormValue,
    catchHttpError,
    isModelValid,
    initialModel,
    setFormValue,
    resetForm,
    setErrors,
    setValue,
    errors,
    model,
  };
};
