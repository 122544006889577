import { createCachedSelector } from 're-reselect';

export const selectAuthenticatedUser = ({ account }) => account;

export const isAuthenticatedUserAdministrator = ({ account }) => account.isAdmin;

export const isAuthenticatedUserIdentified = ({ account }) => !!account.email;

export const selectAuthenticatedUserDefaultView = ({ account }) => account.defaultView;

export const selectAuthenticatedUserLocale = ({ account }) => account.locale;

export const selectAuthenticatedUserWarehouse = ({ account }) => account.warehouse;

export const selectPermissions = ({ account }) => account.permissions;

export const selectSectionPermissions = section => ({ account }) => account.permissions[section];

export const selectDefaultProductGroups = ({ account }) => account.defaultProductGroups;

export const selectActionPermission = (section, action) => createCachedSelector(
  ({ account }) => account,
  ({ permissions, isAdmin }) => isAdmin || (
    Boolean(permissions[section]?.view) &&
    Boolean(permissions[section]?.[action]
  ))
)(
  () => `${section}.${action}`
);
