export const table = {
	rowsPerPage: localStorage.getItem('pagination.rows_per_page') || 10,
	orderBy: 'createdAt',
	order: 'desc',
};

export const auth = {
	paths: {
		notAuthenticated: [
			'/password-recovery',
			'/',
		],
		authenticated: '/products',
		login: '/',
	},
	tokens: {
		refresh: 'session.refresh_token',
		access: 'session.access_token',
	},
};

export const i18n = {
	defaultLocale: 'en',
	locales: {
		en: 'English',
		de: 'Deutsch',
	},
};

export const app = {
	navigationType: 'app.navigation_type',
};

export default {
	appVersion: process.env.REACT_APP_VERSION,
	staticUrl: process.env.REACT_APP_API_URL + '/static/private',
	staticPublicUrl: process.env.REACT_APP_API_URL + '/static/public',
	apiUrl: process.env.REACT_APP_API_URL,
	tyre24BackOfficeUrl: process.env.REACT_APP_TYRE24_BACKOFFICE_URL,
	table,
	auth,
	i18n,
	app,
};
