import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslator } from 'i18n';
import {
  useTableSortableHeadCell,
  useList,
} from 'hooks';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TableContainer from '@mui/material/TableContainer';
import { makeStyles } from 'tss-react/mui';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import DeleteIcon from '@mui/icons-material/Delete';
import TableRow from '@mui/material/TableRow';
import PrintIcon from '@mui/icons-material/Print';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';

import {
  ActionButton,
  Pagination,
} from 'components';

import { selectActionPermission } from 'store/selectors/account';
import { wrapTableRowClick } from 'utils/html';

import { PickupSheetStatuses } from 'consts';
import tables from 'config/tables';

const useStyles = makeStyles()(theme => ({
  tableRow: {
    '& th, & td': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  button: {
    color: theme.palette.primary.l900,
  },
}));

export const PickupSheetsTable = ({
  onPickupSheetDelete,
  onPickupSheetPrint,
}) => {
  const t = useTranslator();
  const list = useList(tables.pickupSheets.pickupSheets);
  const history = useHistory();
  const { classes } = useStyles();

  const canDeletePickupSheet = useSelector(selectActionPermission('pickupSheets', 'delete'));
  const canPrintPickupSheet = useSelector(selectActionPermission('pickupSheets', 'print'));
  const totalEntities = useSelector(({ pickupSheets }) => pickupSheets.pickupSheets.meta.totalEntities);
  const pickupSheets = useSelector(({ pickupSheets }) => pickupSheets.pickupSheets.data);

  const SortableCell = useTableSortableHeadCell({
    changeSorting: list.changeSorting,
    sorting: list.sorting,
  });

  const openPickupSheet = pickupSheet => history.push(`/pickup-sheets/${pickupSheet.id}`);

  return (
    <Paper>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <SortableCell name="id">ID</SortableCell>
              <SortableCell name="customerNumber">{t.translate('Customer no')}</SortableCell>
              <SortableCell name="customerCompany">{t.translate('Customer company')}</SortableCell>
              <SortableCell name="customerName">{t.translate('Customer name')}</SortableCell>
              <SortableCell name="status">Status</SortableCell>
              <SortableCell name="createdAt">{t.translate('Created')}</SortableCell>
              <TableCell align="right">{t.translate('Actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pickupSheets.map((pickupSheet, index) => (
              <TableRow key={index} hover onClick={wrapTableRowClick(() => openPickupSheet(pickupSheet))} className={classes.tableRow}>
                <TableCell>{pickupSheet.id}</TableCell>
                <TableCell>{pickupSheet.customer.number}</TableCell>
                <TableCell>{pickupSheet.customer.company}</TableCell>
                <TableCell>{pickupSheet.customer.name}</TableCell>
                <TableCell>{t.translate(PickupSheetStatuses[pickupSheet.status])}</TableCell>
                <TableCell>{t.formatDate(pickupSheet.createdAt)} {t.formatTime(pickupSheet.createdAt)}</TableCell>
                <TableCell align="right">
                  {canDeletePickupSheet && pickupSheet.status === 'open' && (
                    <ActionButton
                      className={classes.button}
                      aria-label={t.translate('Delete the pickup sheet')}
                      tooltip={t.translate('Delete')}
                      icon={<DeleteIcon />}
                      onClick={() => onPickupSheetDelete(pickupSheet)}
                    />
                  )}
                  {canPrintPickupSheet && (
                    <ActionButton
                      className={classes.button}
                      aria-label={t.translate('Print the pickup sheet')}
                      tooltip={t.translate('Print')}
                      icon={<PrintIcon />}
                      onClick={() => onPickupSheetPrint(pickupSheet)}
                    />
                  )}
                  <ActionButton
                    aria-label={t.translate('Open the pickup sheet')}
                    tooltip={t.translate('verb:Open')}
                    icon={<ChevronRightIcon />}
                    onClick={() => openPickupSheet(pickupSheet)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination {...list.paginationProps} count={totalEntities} />
    </Paper>
  );
};

PickupSheetsTable.propTypes = {
  onPickupSheetDelete: PropTypes.func.isRequired,
  onPickupSheetPrint: PropTypes.func.isRequired,
};
