import {
  AccountSettings,
  GeneralSettings,
  AddressSettings,
  FeesSettings,
  PricesSettings,
  FTPSettings,
  SupplierStatistics,
  ChangesHistory,
} from 'views/suppliers/settings';

export const createRoutes = [{
  homePath: `/new-supplier/general`,
  Page: GeneralSettings,
  name: 'supplier',
}, {
  homePath: `/new-supplier/account`,
  Page: AccountSettings,
  name: 'supplier',
}, {
  homePath: `/new-supplier/address`,
  Page: AddressSettings,
  name: 'supplier',
}, {
  homePath: `/new-supplier/fees`,
  Page: FeesSettings,
  name: 'supplier',
}, {
  homePath: `/new-supplier/prices`,
  Page: PricesSettings,
  name: 'supplier',
}, {
  homePath: `/new-supplier/ftp`,
  Page: FTPSettings,
  name: 'supplier',
}];

export const createTabsOptions = [{
  label: 'General',
  name: `/new-supplier/general`,
  view: 'supplier',
}, {
  label: 'Account',
  name: `/new-supplier/account`,
  view: 'suppler',
}, {
  label: 'Address',
  name: `/new-supplier/address`,
  view: 'suppler',
}, {
  label: 'Fees',
  name: `/new-supplier/fees`,
  view: 'suppler',
}, {
  label: 'Prices',
  name: `/new-supplier/prices`,
  view: 'suppler',
}, {
  label: 'FTP',
  name: `/new-supplier/ftp`,
  view: 'suppler',
},
];

export const editRoutes = (id) => [{
  homePath: `/edit-supplier/${id}/general`,
  Page: GeneralSettings,
  name: 'supplier',
}, {
  homePath: `/edit-supplier/${id}/account`,
  Page: AccountSettings,
  name: 'supplier',
}, {
  homePath: `/edit-supplier/${id}/address`,
  Page: AddressSettings,
  name: 'supplier',
}, {
  homePath: `/edit-supplier/${id}/fees`,
  Page: FeesSettings,
  name: 'supplier',
}, {
  homePath: `/edit-supplier/${id}/prices`,
  Page: PricesSettings,
  name: 'supplier',
}, {
  homePath: `/edit-supplier/${id}/ftp`,
  Page: FTPSettings,
  name: 'supplier',
}, {
  homePath: `/edit-supplier/${id}/statistics`,
  Page: SupplierStatistics,
  name: 'supplier',
}, {
  homePath: `/edit-supplier/${id}/changes-history`,
  Page: ChangesHistory,
  name: 'supplier',
}];

export const editTabsOptions = (id) => [{
    label: 'General',
    name: `/edit-supplier/${id}/general`,
    view: 'supplier',
  }, {
    label: 'Account',
    name: `/edit-supplier/${id}/account`,
    view: 'supplier',
  }, {
    label: 'Address',
    name: `/edit-supplier/${id}/address`,
    view: 'supplier',
  }, {
    label: 'Fees',
    name: `/edit-supplier/${id}/fees`,
    view: 'supplier',
  }, {
    label: 'Prices',
    name: `/edit-supplier/${id}/prices`,
    view: 'supplier',
  }, {
    label: 'FTP',
    name: `/edit-supplier/${id}/ftp`,
    view: 'supplier',
  }, {
    label: 'Statistics',
    name: `/edit-supplier/${id}/statistics`,
    view: 'supplier',
  }, {
    label: 'Changes history',
    name: `/edit-supplier/${id}/changes-history`,
    view: 'supplier',
  },
];
