import React, { useEffect } from 'react';

import { useLoading } from 'hooks/loading';
import { useTranslator } from 'i18n';
import { useForm } from 'hooks/form';

import { makeStyles } from 'tss-react/mui';
import {
  FormControl,
  Grid,
  Typography,
  InputLabel,
} from '@mui/material';

import {
  CircularLoader,
  BusyButton,
} from 'components';

import {
  modifyProductsOptions,
  fetchProductsOptions,
} from 'api/options';
import toaster from 'services/toaster';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { StockReservationBehaviorOptions } from '../../../consts';

const useStyles = makeStyles()({
  actionsItem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export const StockReservationConfiguration = () => {
  const t = useTranslator();
  const { classes } = useStyles();

  const loading = useLoading();

  const {
    catchHttpError,
    setValue,
    model,
  } = useForm({
    stockReservationBehavior: true,
  });

  const fetchOptions = loading.lock(async () => {
    try {
      const options = await fetchProductsOptions();

      setValue('stockReservationBehavior')(options.stockReservationBehavior);
    } catch (err) {}
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => void fetchOptions(), []);

  const submit = async () => {
    try {
      await modifyProductsOptions({
        ...model,
      });
      toaster.success(t.translate('Stock reservation behavior have been changed successfully.'));
    } catch (err) {
      catchHttpError(err);
    }
  };

  if (loading.progress) {
    return <CircularLoader />;
  } else {
    return (
      <form action="#">
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Typography variant="h6">
              {t.translate('Change stock reservation behavior')}
            </Typography>
          </Grid>

          <Grid item>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor="stockReservationBehavior">{t.translate('Stock reservation behavior')}</InputLabel>
              <Select
                id="stockReservationBehavior"
                name="stockReservationBehavior"
                value={model.stockReservationBehavior}
                onChange={e => setValue('stockReservationBehavior')(e.target.value)}
              >
                {StockReservationBehaviorOptions.map(({ value, text }) => (
                  <MenuItem key={value} value={value}>{t.translate(text)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item className={classes.actionsItem}>
            <BusyButton
              submit
              color="secondary"
              variant="contained"
              onClick={submit}
            >
              {t.translate('Change data')}
            </BusyButton>
          </Grid>
        </Grid>
      </form>
    );
  }
};
