import React, { useEffect } from 'react';

import { useLoading } from 'hooks/loading';
import { useTranslator } from 'i18n';
import { useForm } from 'hooks/form';

import { makeStyles } from 'tss-react/mui';
import {
  FormControl,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from '@mui/material';

import {
  FormControlError,
  CircularLoader,
  BusyButton,
} from 'components';

import {
  modifyProductsOptions,
  fetchProductsOptions,
} from 'api/options';
import toaster from 'services/toaster';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { B2BPriceTypes } from '../../../consts';

const useStyles = makeStyles()({
  actionsItem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export const SalesPriceCalculation = () => {
  const t = useTranslator();
  const { classes } = useStyles();

  const loading = useLoading();

  const {
    toggleFormValue,
    catchHttpError,
    setValue,
    errors,
    model,
  } = useForm({
    salesPriceCalculationWhenExportingSupplierStock: true,
    salesPriceAutoCalculation: true,
    priceType: 1,
  });

  const fetchOptions = loading.lock(async () => {
    try {
      const options = await fetchProductsOptions();

      setValue('salesPriceCalculationWhenExportingSupplierStock')(options.salesPriceCalculationWhenExportingSupplierStock);
      setValue('salesPriceAutoCalculation')(options.salesPriceAutoCalculation);
      setValue('priceType')(options.priceType);
    } catch (err) {}
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => void fetchOptions(), []);

  const submit = async () => {
    try {
      await modifyProductsOptions({
        ...model,
      });
      toaster.success(t.translate('Sales prices have been changed successfully.'));
    } catch (err) {
      catchHttpError(err);
    }
  };

  if (loading.progress) {
    return <CircularLoader />;
  } else {
    return (
      <form action="#">
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Typography variant="h6">
              {t.translate('Change sales price calculation')}
            </Typography>
          </Grid>

          <Grid item>
            <FormControl error={!!errors.salesPriceAutoCalculation}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="product-rate--autoCalculation"
                    color="primary"
                    checked={!!model.salesPriceAutoCalculation}
                    onChange={toggleFormValue('salesPriceAutoCalculation')}
                  />
                }
                label={t.translate('Automatic sales price calculation')}
              />
              {!!errors.salesPriceAutoCalculation && (
                <FormControlError error={errors.salesPriceAutoCalculation} />
              )}
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor="priceType">{t.translate('B2B Price type')}</InputLabel>
              <Select
                id="priceType"
                name="priceType"
                value={model.priceType}
                onChange={e => setValue('priceType')(e.target.value)}
              >
                {B2BPriceTypes.map(({ value, text }) => (
                  <MenuItem key={value} value={value}>{t.translate(text)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item className={classes.actionsItem}>
            <BusyButton
              submit
              color="secondary"
              variant="contained"
              onClick={submit}
            >
              {t.translate('Change data')}
            </BusyButton>
          </Grid>
        </Grid>
      </form>
    );
  }
};
