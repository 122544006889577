import React from 'react';
import PropTypes from 'prop-types';
import MuiTableRow from '@mui/material/TableRow';
import clsx from 'clsx';

export const TableRow = ({ cellsProps, children, ...props }) => {
  let right = 0;
  let left = 0;

  const leftChildren = [];
  for (let i = 0; i < children.length; i++) {
    if (cellsProps[i].fixed === 'right') {
      break;
    }

    if (cellsProps[i].fixed) {
      leftChildren.push(React.cloneElement(children[i], {
        ...cellsProps[i],
        left,
        key: i,
        ...(i < children.length - 1 && !cellsProps[i + 1].fixed && {
          className: clsx(children[i].props.className, 'TableCell-fixedLeftLast'),
        }),
      }));
      left += cellsProps[i].width;
    } else {
      leftChildren.push(React.cloneElement(children[i], {
        ...cellsProps[i],
        key: i,
      }));
    }
  }

  const rightChildren = [];
  for (let i = children.length - 1; i >= 0; i--) {
    if (cellsProps[i].fixed !== 'right') {
      break;
    }
    rightChildren.push(React.cloneElement(children[i], {
      ...cellsProps[i],
      right,
      key: i,
      ...(i && !cellsProps[i - 1].fixed && {
        className: 'TableCell-fixedRightFirst',
      }),
    }));
    right += cellsProps[i].width;
  }

  return <MuiTableRow {...props}>{[...leftChildren, ...rightChildren.reverse()]}</MuiTableRow>;
};

TableRow.propTypes = {
  widths: PropTypes.arrayOf(PropTypes.number),
};
