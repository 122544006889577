import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './PhotoCamera.jsx';
import ReactDOM from 'react-dom';
import Box from '@mui/material/Box';
import { AppHeaderBackButton } from 'components';
import toaster from 'services/toaster';

import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

export const PhotoCamera = ({
  onCapture,
  onClose,
}) => {
  const { classes } = useStyles();

  const capturePhoto = dataUri => {
    onCapture(dataUri);
    onClose();
  };

  const handleError = () => {
    toaster.info('No media device.');
    setTimeout(onClose, 1000);
  };

  return ReactDOM.createPortal(
    <Box className={classes.root}>
      <Camera
        isDisplayStartCameraError={false}
        idealFacingMode="environment"
        isImageMirror={false}
        isSilentMode
        isFullscreen
        imageType="jpg"
        onCameraError={handleError}
        onTakePhoto={capturePhoto}
      />
      <AppHeaderBackButton className={classes.backButton} onClick={onClose} />
    </Box>,
    document.body
  );
};

PhotoCamera.propTypes = {
  onCapture: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
