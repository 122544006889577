import React, { useEffect } from 'react';

import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslator } from 'i18n';
import {
  useDialog,
  useRoute,
  didMount,
} from 'hooks';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { ConfirmingDialog } from 'dialogs';
import {
  CheckboxControl,
  InputControl,
  BusyButton,
} from 'components';

import { modifyAuthenticatedUser } from 'api/users';
import { extractApiErrors } from 'utils/api';
import { updateAccount } from 'store/actions/account';
import { logOut } from 'services/authentication';
import {
  selectAuthenticatedUser,
  selectActionPermission, isAuthenticatedUserAdministrator,
} from 'store/selectors/account';
import {
  checkEmailChangeToken,
  requestEmailChange,
  changeEmail,
  identify,
} from 'api/authentication';
import toaster from 'services/toaster';

const useStyles = makeStyles()({
  actionsItem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const makeId = postfix => `user-data--${postfix}`;

export const Data = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const route = useRoute();
  const t = useTranslator();

  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const canViewDashboard = useSelector(selectActionPermission);
  const isAdmin = useSelector(isAuthenticatedUserAdministrator);

  const confirmingDialog = useDialog();

  const initialValues = {
    dailyStatusMail: authenticatedUser.dailyStatusMail,
    email: authenticatedUser.email,
    name: authenticatedUser.name,
    isSendFailedOrdersMail: authenticatedUser.isSendFailedOrdersMail,
  };

  const onSubmit = async (values, { setValues, setErrors }) => {
    const { email, ...data } = values;
    const isEmailChanged = email !== initialValues.email;
    const isDataChanged = Object.entries(data).some(([key, value]) => value !== initialValues[key]);

    if (!isEmailChanged && !isDataChanged) {
      return;
    }

    try {
      const notifications = [];

      if (isDataChanged) {
        await modifyAuthenticatedUser(data);
        notifications.push(t.translate('User data have been changed successfully.'));

        const account = await identify();
        dispatch(updateAccount(account));
      }

      if (isEmailChanged) {
        await requestEmailChange(email);
        notifications.push(t.translate('Confirmation link has been sent to the specified email.'));

        setValues({ ...values, email: initialValues.email });
      }

      toaster.success(
        <>
          {notifications.map((notification, index) => (
            <React.Fragment key={index}>
              {notification}
              {index < notifications.length - 1 && <br />}
            </React.Fragment>
          ))}
        </>
      );
    } catch (err) {
      setErrors(extractApiErrors(err));
    }
  };

  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
  } = useFormik({
    initialValues,
    onSubmit,
  });

  const confirmEmailChange = async () => {
    const { token, email } = route.search;
    try {
      await changeEmail(token, email);

      toaster.success(
        <>
          {t.translate('Email has been changed successfully.')}<br />
          {t.translate('You will be redirected to the login page.')}
        </>
      );

      confirmingDialog.close();
      setTimeout(logOut, 5000);
    } catch (err) {}
  };

  didMount(() => {
    const { token, email } = route.search;
    if (token && email) {
      const fetchEmailChangeToken = async () => {
        try {
          await checkEmailChangeToken(token, email);
          confirmingDialog.open();
        } catch (err) {}
      };
      fetchEmailChangeToken();
    }
  });

  useEffect(() => {
    setValues({
      dailyStatusMail: authenticatedUser.dailyStatusMail,
      email: authenticatedUser.email,
      name: authenticatedUser.name,
      isSendFailedOrdersMail: authenticatedUser.isSendFailedOrdersMail,
    });
  }, [
    setValues,
    authenticatedUser.dailyStatusMail,
    authenticatedUser.email,
    authenticatedUser.name,
    authenticatedUser.isSendFailedOrdersMail,
  ]);

  return (
    <>
      {route.search.token && route.search.email && (
        <ConfirmingDialog
          {...confirmingDialog.props}
          title={t.translate('Confirm email change')}
          acceptButtonText={t.translate('Confirm')}
          declineButtonText={t.translate('dialog-action:Cancel')}
          onAccept={confirmEmailChange}
        >
          {t.translate('Are you sure you want to change your email {from} to {to}?', {
            from: <b>{authenticatedUser.email}</b>,
            to: <b>{route.search.email}</b>,
          })}
        </ConfirmingDialog>
      )}

      <form action="#">
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Typography variant="h6">{t.translate('Change your user data')}</Typography>
          </Grid>
          <Grid item>
            <InputControl
              id={makeId('email')}
              name="email"
              error={errors.email}
              label={t.translate('Email')}
              value={values.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <InputControl
              id={makeId('name')}
              name="name"
              error={errors.name}
              label={t.translate('Name')}
              value={values.name}
              onChange={handleChange}
            />
          </Grid>
          {canViewDashboard && (
            <Grid item>
              <CheckboxControl
                id={makeId('statusMail')}
                name="dailyStatusMail"
                error={errors.dailyStatusMail}
                label={t.translate('Receive daily status mail')}
                checked={!!values.dailyStatusMail}
                onChange={handleChange}
              />
              {isAdmin && (<CheckboxControl
                id={makeId('isSendFailedOrdersMail')}
                name="isSendFailedOrdersMail"
                error={errors.isSendFailedOrdersMail}
                label={t.translate('Receive mails about failed orders')}
                checked={!!values.isSendFailedOrdersMail}
                onChange={handleChange}
              />)}
            </Grid>
          )}
          <Grid item className={classes.actionsItem}>
            <BusyButton
              color="secondary"
              submit
              variant="contained"
              onClick={handleSubmit}
            >
              {t.translate('Change data')}
            </BusyButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
