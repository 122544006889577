import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, Switch, Route, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslator } from 'i18n';
import { useScreen } from 'hooks';

import { Box } from '@mui/material';

import { Header } from 'views/reports';
import { ViewSwitch } from 'components';
import { routes, tabsOptions } from './config';
import {
  isAuthenticatedUserAdministrator,
  selectPermissions,
} from 'store/selectors/account';

export const Reports = () => {
  const history = useHistory();
  const screen = useScreen();
  const t = useTranslator();
  const isAdmin = useSelector(isAuthenticatedUserAdministrator);
  const permissions = useSelector(selectPermissions);

  const matchWithWarehouseInventory = useRouteMatch(routes[routes.length - 1].homePath);

  const shouldShowMenuItem = name => isAdmin || permissions[name] === undefined || permissions[name].view;
  const allowedRoutes = Object.values(routes).filter(({ name }) => isAdmin || permissions[name] === undefined || permissions[name].view);

  let activeRoute = !screen.isMobile ? (
    allowedRoutes.find(({ homePath }) => homePath === history.location.pathname || homePath === matchWithWarehouseInventory?.path)
    || allowedRoutes[0]
  ) : allowedRoutes.find(({ homePath }) => homePath === history.location.pathname || homePath === matchWithWarehouseInventory?.path);

  useEffect(() => {
    if (!activeRoute?.homePath === matchWithWarehouseInventory?.path && !activeRoute?.subPaths.includes(history.location.pathname)) {
      history.replace(activeRoute.paths[0]);
    }
  }, [activeRoute, history, screen.isMobile, matchWithWarehouseInventory]);

  const allowedOptions = tabsOptions.filter(option => shouldShowMenuItem(option.view));
  const activeOption = allowedOptions.find(({ name }) => history.location.pathname.includes(name))?.name;

  return (
    <>
      <Helmet>
        <title>{t.translate('app:title')}</title>
      </Helmet>

      <Header
        isMobileView={screen.isMobile}
        routeTitle={activeRoute?.label}
      />

      <ViewSwitch options={allowedOptions} isMobile={screen.isMobile} activeOption={activeOption} />

      <Box display="flex" flexGrow={1} width={1}>
        <Switch>
          {Object.entries(allowedRoutes).map(([key, { name, homePath, subPaths, Page }]) => (
            shouldShowMenuItem(name) && (
              <Route key={key} exact path={[homePath, ...subPaths]}>
                <Page />
              </Route>
            )
          ))}
        </Switch>
      </Box>
    </>
  );
};
