import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    bottom: theme.spacing(5),
    position: 'fixed',
    right: theme.spacing(5),
    zIndex: theme.zIndex.modal - 1,
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(5),
      right: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      left: theme.spacing(5),
      top: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        left: theme.spacing(2),
        top: theme.spacing(2),
      },
    },
    '& .MuiSpeedDial-fab': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));
