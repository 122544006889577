import React from 'react';
import numeral from 'numeral';
import { Paper, TableBody, TableContainer, TableHead, Grid } from '@mui/material';
import { useStyles } from '../styles';
import {
  CollapseTableRow,
  ItemHeadline,
  Table,
  TableCell,
  TableRow,
} from 'components';
import { CollapsedRow } from '../CollapsedRow/CollapsedRow';
import { useTranslator } from 'i18n';

export const SupplierStatistics = ({ supplier }) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <Grid container item spacing={2} style={{ margin: 10 }} className={classes.containerWrap}>
      <Grid item md={6} xs={12}>
        <Paper style={{ padding: 20 }}>
          <ItemHeadline text={t.translate('Storage usage')}/>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t.translate('Warehouse')}</TableCell>
                  <TableCell>{t.translate('Used Storage')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {supplier?.storageUsage?.map(({ warehouseId, warehouseName, averageUsedStorage }) => (
                  <TableRow key={warehouseId}>
                    <TableCell>{`${warehouseId} - ${warehouseName}`}</TableCell>
                    <TableCell>{numeral(averageUsedStorage).format('0.0[0000]')} m<sup>3</sup></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid item md={6} xs={12}>
        <Paper style={{ padding: 20 }}>
          <ItemHeadline text={t.translate('Vouchers history')}/>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell/>
                  <TableCell>{t.translate('Voucher ID')}</TableCell>
                  <TableCell>{t.translate('Date')}</TableCell>
                  <TableCell>{t.translate('Total Price Net')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {supplier?.vouchers?.map((voucher) => (
                  <CollapseTableRow
                    key={`voucher-${voucher.id}`}
                    colSpan={4}
                    collapsedRow={<CollapsedRow positions={voucher.positions}/>}
                    hover
                    isVoucherHistory={true}
                  >
                    <TableCell>{voucher.id}</TableCell>
                    <TableCell>{t.formatDate(voucher.createdAt)} {t.formatTime(voucher.createdAt)}</TableCell>
                    <TableCell>{numeral(Number(voucher.totalPriceNet)).format('0,0.00')} &euro;</TableCell>
                  </CollapseTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};
