import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { IntlProvider } from 'react-intl';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  RouteProvider,
  RouteLayout,
  useDialog,
  didUpdate,
  didMount,
} from './hooks';
import { withScreen } from './hocs/screen';
import { withStore } from './hocs/store';

import { SelectingWarehouseDialog } from './dialogs';
import { Forbidden } from './pages';

import { isAuthenticated, identify } from './services/authentication';
import { fetchWarehouses } from './api/warehouses';
import { modifyAuthenticatedUser } from './api/users';
import { fetchApiVersion } from './services/app';
import { setWarehouse } from './store/actions/account';
import { applyLocale } from './services/i18n';
import {
  selectAuthenticatedUserWarehouse,
  isAuthenticatedUserAdministrator,
  isAuthenticatedUserIdentified,
  selectPermissions,
} from './store/selectors/account';

import toaster from './services/toaster';
import routes from './routes';
import theme from './theme';

toaster.serve();

const App = ({ isMobileView }) => {
  const dispatch = useDispatch();

  const isAdministrator = useSelector(isAuthenticatedUserAdministrator);
  const isIdentified = useSelector(isAuthenticatedUserIdentified);
  const permissions = useSelector(selectPermissions);
  const warehouse = useSelector(selectAuthenticatedUserWarehouse);
  const messages = useSelector(({ i18n }) => i18n.messages[i18n.locale]);
  const locale = useSelector(({ i18n }) => i18n.locale);

  const selectingWarehouseDialog = useDialog({ warehouses: [] });

  didMount(() => {
    dispatch(fetchApiVersion());
    if (isAuthenticated()) {
      dispatch(identify());
    } else {
      dispatch(applyLocale(locale));
    }
  });

  didUpdate(() => {
    if (!warehouse) {
      const getWarehouses = async () => {
        try {
          const { data: warehouses } = await fetchWarehouses({ meta: ['list-set'] });
          if (warehouses?.length > 1) {
            selectingWarehouseDialog.open({ warehouses });
          } else {
            await modifyAuthenticatedUser({ warehouseId: warehouses[0].id });
            dispatch(setWarehouse(warehouses[0]));
          }
        } catch (err) {}
      };
      getWarehouses();
    }
  }, [isIdentified]);

  const shouldRenderPage = roles => isAdministrator || roles.some(role => permissions[role] === undefined || permissions[role].view);

  return messages ? (
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <IntlProvider locale={locale} messages={messages}>
            <BrowserRouter>
              {isIdentified && !warehouse && (
                <SelectingWarehouseDialog
                  {...selectingWarehouseDialog.props}
                  onSelect={warehouse => dispatch(setWarehouse(warehouse))}
                />
              )}
              <RouteProvider>
                <Switch>
                  {Object.entries(routes).map(([path, { Layout, Page, roles }]) => (
                    <Route key={path} path={path}>
                      <RouteLayout>
                        <Layout>
                          {isAuthenticated() ? (
                            isIdentified && (
                              shouldRenderPage(roles)
                                ? <Page />
                                : <Forbidden />
                            )
                          ) : <Page />}
                        </Layout>
                      </RouteLayout>
                    </Route>
                  ))}
                </Switch>
              </RouteProvider>
            </BrowserRouter>
          </IntlProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  ) : <></>;
};

export default withScreen(withStore(App));
