import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import { Column } from 'layouts';
import { CircularLoader } from 'components';
import { StockBooking } from '../common';

export const MobileView = ({ isLoading, ...props }) => (
  <Column p={1}>
    {isLoading ? (
      <CircularLoader />
    ) : (
      <Grid container direction="column">
        <Grid item xs={12} container direction="column" alignItems="center">
          <StockBooking {...props} />
        </Grid>
      </Grid>
    )}
  </Column>
);

MobileView.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};
