import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';

import Grid from '@mui/material/Grid';
import { OperationItem } from './OperationItem/OperationItem';
import { Column } from 'layouts';
import {
  FullScreenDialogHeader,
  FullScreenDialog,
} from 'components';

export const MobileStockHistoryDialog = ({
  onClose,
  operations: {
    data: operations,
  },
  ...props
}) => {
  const t = useTranslator();

  return (
    <FullScreenDialog {...props} onClose={onClose}>
      <FullScreenDialogHeader title={t.translate('Stock history')} onClose={onClose} />
      <Column p={1}>
        <Grid container direction="column" spacing={1}>
        {operations.map(operation => (
          <OperationItem
            key={operation.id}
            operation={operation}
          />
        ))}
        </Grid>
      </Column>
    </FullScreenDialog>
  );
};

MobileStockHistoryDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  operations: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

MobileStockHistoryDialog.defaultProps = {
  operations: {
    data: [],
  },
};
