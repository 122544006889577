import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';

import { styleFormDialog } from 'theme/mixins/dialog';
import { textOrderStatus } from 'services/orders';

const useStyles = makeStyles()(theme => styleFormDialog(theme, {
  root: {
    maxWidth: 'none',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      maxHeight: 'none',
      maxWidth: 'none',
      height: '100%',
      width: '100%',
    },
    [theme.breakpoints.between('sm', 'xl')]: {
      width: '70vw',
    },
    width: '50vw',
  },
  content: {
    '&.MuiDialogContent-root': {
      overflowY: 'initial',
      paddingBottom: theme.spacing(.5),
      width: 'initial',
    },
    '&.MuiDialogContent-root .MuiGrid-item': {
      paddingBottom: theme.spacing(1),
    },
    '&.MuiDialogContent-root .MuiGrid-item > p:first-of-type': {
      fontWeight: 700,
    },
  },
}));

export const CustomerOrdersDialog = ({
  order,
  onClose,
  ...props
}) => {
  const t = useTranslator();
  const history = useHistory();
  const { classes } = useStyles();

  const openOrder = order => history.push(`/orders/${order.id}`);

  return (
    <>
      <Dialog classes={{ paperWidthSm: classes.root }} {...props} onClose={onClose}>
        <DialogTitle>{t.translate('Order details')}</DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container justifyContent="space-between" spacing={1}>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="body2">{t.translate('Order number')}</Typography>
                <Typography variant="body2">{order.number}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{t.translate('Delivery address')}</Typography>
                <Typography variant="body2">{order.deliveryAddress.street}&nbsp;{order.deliveryAddress.house}</Typography>
                <Typography variant="body2">{order.deliveryAddress.zip}&nbsp;{order.deliveryAddress.city}</Typography>
                <Typography variant="body2">{order.deliveryAddress.country}</Typography>
              </Grid>
            </Grid>
            <Grid container direction="column" align="right">
              <Grid item>
                <Typography variant="body2">{t.translate('Status')}</Typography>
                <Typography variant="body2">{textOrderStatus(order, { t })}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{t.translate('Net sum')}</Typography>
                <Typography variant="body2">{t.formatPrice(Number(order.netSum))} &euro;</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{t.translate('Gross sum')}</Typography>
                <Typography variant="body2">{t.formatPrice(Number(order.grossSum))} &euro;</Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => openOrder(order)}>{t.translate('View Order')}</Button>
          <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

CustomerOrdersDialog.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    number: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    netSum: PropTypes.string,
    grossSum: PropTypes.string,
    tracking: PropTypes.array,
    deliveryAddress: PropTypes.shape({
      name: PropTypes.string,
      city: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      street: PropTypes.string.isRequired,
      house: PropTypes.string,
      zip: PropTypes.string.isRequired,
    }),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};
