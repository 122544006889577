import React, { useEffect, useState } from 'react';
import { useTranslator } from 'i18n';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import TableContainer from '@mui/material/TableContainer';
import { makeStyles } from 'tss-react/mui';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import EditIcon from '@mui/icons-material/Edit';

import { ItemHeadline, Pagination, TableCell, TableRow, Table, ActionButton } from 'components';
import { FiltersRow } from './FiltersRow/FiltersRow';
import { useList } from 'hooks';
import tables from 'config/tables';

const useStyles = makeStyles()(theme => ({
  cardLabel: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  tableContainer: {
    '& thead tr:first-of-type th': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    '& th:first-of-type, & td:first-of-type': {
      paddingLeft: theme.spacing(2),
    },
    '& th:last-child, & td:last-child': {
      paddingRight: theme.spacing(2),
    },
  },
}));


export const PositionsTable = ({
  purchase,
  positions,
  canCorrectionPosition,
  onPositionCorrection,
}) => {
  const history = useHistory();
  const { classes } = useStyles();
  const t = useTranslator();
  const list = useList(tables.purchases.positions);

  const { data, meta } = positions;

  const openProduct = productId => history.push(`/products/${productId}`);

  const [totalNetSum, setTotalNetSum] = useState(0);

  useEffect(() => {
    const totalNet = purchase.positions.reduce((sum, { purchasePrice, quantity }) => {
      const sumPosition = purchasePrice * quantity;
      return Number(sum) + Number(sumPosition);
    }, 0);
    setTotalNetSum(totalNet);
  }, [purchase]);

  return (
    <>
      <ItemHeadline
        className={classes.cardLabel}
        text={t.translate('Purchase positions')}
        secondaryText={`${t.translate('Total net')} ${t.formatPrice(totalNetSum) || 0} €`}
      />

      <Paper className={classes.tableContainer}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell name="productId">{t.translate('Product ID')}</TableCell>
                <TableCell name="productName">{t.translate('Name')}</TableCell>
                <TableCell name="manufacturerNumber" align="right">{t.translate('Manufacturer no.')}</TableCell>
                <TableCell name="manufacturerName">{t.translate('Manufacturer')}</TableCell>
                <TableCell name="ean" align="right">{t.translate('EAN')}</TableCell>
                <TableCell name="purchasePrice" align="right">{t.translate('Purchase price')}</TableCell>
                <TableCell name="quantity" align="right">{t.translate('Awaited quantity')}</TableCell>
                <TableCell name="quantity" align="right">{t.translate('Received')}</TableCell>
                <TableCell name="totalNet" align="right">{t.translate('Total net')}</TableCell>
                <TableCell align="right">{t.translate('Actions')}</TableCell>
              </TableRow>
              <FiltersRow
                visible={true}
                onFiltersEnable={() => true}
              />
            </TableHead>
            <TableBody>
              {data.map((position, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Link onClick={() => openProduct(position.product.id)}>{position.product.id}</Link>
                  </TableCell>
                  <TableCell>{position.product?.details[0]?.name || '—'}</TableCell>
                  <TableCell align="right">{position.product?.manufacturerNumber || '—'}</TableCell>
                  <TableCell>{position.product?.manufacturerName || '—'}</TableCell>
                  <TableCell align="right">{position.ean || '—'}</TableCell>
                  <TableCell align="right">{t.formatPrice(position.purchasePrice)}&nbsp;&euro;</TableCell>
                  <TableCell align="right">{position.quantity}</TableCell>
                  <TableCell align="right">{position.deliveredQuantity}</TableCell>
                  <TableCell align="right">{t.formatPrice(position.purchasePrice * position.quantity) || '—'}&nbsp;&euro;</TableCell>
                  <TableCell align="right">
                    {canCorrectionPosition && <ActionButton
                      tooltipPlacement="bottom"
                      aria-label={t.translate('Edit purchase')}
                      tooltip={t.translate('Edit purchase')}
                      icon={<EditIcon/>}
                      onClick={() => onPositionCorrection(purchase.positions.find(item => item.id === position.id), purchase.id)}
                    />}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination {...list.paginationProps} count={meta.totalEntities} />
      </Paper>
    </>
  );
};

PositionsTable.propTypes = {
  purchase: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string,
    positions: PropTypes.arrayOf(
      PropTypes.shape({
        ean: PropTypes.string,
        purchasePrice: PropTypes.number.isRequired,
        quantity: PropTypes.number,
        deliveredQuantity: PropTypes.number,
        product: PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string,
        }),
      })
    ).isRequired,
  }),
  canCorrectionPosition: PropTypes.bool.isRequired,
  onPositionCorrection: PropTypes.func.isRequired,
};
