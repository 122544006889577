import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    '& .MuiExpansionPanelSummary-root': {
      flexDirection: 'row-reverse',
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(1.5),
      minHeight: theme.spacing(6),
      padding: theme.spacing(0, 2),
    },
    '& .MuiExpansionPanelSummary-expandIcon': {
      marginLeft: theme.spacing(-2),
      marginRight: 0,
    },
    '& .MuiExpansionPanelSummary-content': {
      ...theme.typography.body1,
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(1.5),
      '& .MuiGrid-item': {
        alignItems: 'center',
        display: 'flex',
      },
      '& .MuiTypography-root': {
        margin: '-.75rem 0',
      },
    },
  },
  button: {
    margin: theme.spacing(-.5, 0),
    '&:last-child': {
      marginRight: theme.spacing(-1),
    },
  },
  detailsPanel: {
    display: 'flex',
    padding: theme.spacing(0, 0, 1),
  },
  orderDetails: {
    background: theme.palette.grey['100'],
    flexGrow: 1,
    padding: theme.spacing(1, 1, 1, 4),
  },
}));
