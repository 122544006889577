import { menuItems } from 'config/navigation';
import capitalize from 'lodash/capitalize';
import lowerCase from 'lodash/lowerCase';
import keyBy from 'lodash/keyBy';

export const humanizeDefaultView = (
  menuItems =>
    defaultView => defaultView && menuItems[defaultView]
      ? menuItems[defaultView].text
      : 'Automatically'
)(
  keyBy(menuItems, 'path')
);

export const createPermissionsListData = permissions => [
  'dashboard',
  'returns',
  'orders',
  'pickupSheets',
  'products',
  'purchases',
  'inboundStocks',
  'specialCases',
  'statisticsReports',
  'warehouseInventoryReports',
  'warehouses',
  'suppliers',
  'customers',
  'storages',
  'users',
  'retailers',
  'companies',
  'settings',
]
  .reduce((sections, section) => permissions[section]
    ? [...sections, {
        key: section,
        name: capitalize(lowerCase(section)),
        actions: Object
          .keys(permissions[section])
          .map(action => ({
            key: action,
            value: permissions[section][action],
          })),
      }]
    : sections
    , []);
