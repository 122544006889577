import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';

import { StorageItem } from '../StorageReassignDialog/StorageItem/StorageItem';

const useStyles = makeStyles()(theme => ({
  root: {
    maxWidth: 'none',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      margin: 0,
      maxHeight: 'none',
      maxWidth: 'none',
      height: '100%',
      width: '100%',
    },
    width: '50vw',
  },
  content: {
    '&.MuiDialogContent-root': {
      overflowY: 'initial',
      paddingBottom: theme.spacing(.5),
    },
  },
  item: {
    '& > div': {
      padding: theme.spacing(2),
      '& > div:last-child': {
        borderBottom: 'none',
      },
    },
  },
  dialogTitle: {
    padding: theme.spacing(3),
    paddingBottom: 0,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
}));

export const StorageDetailsDialog = ({
  isMobileView,
  storage,
  onClose,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <Dialog {...props} classes={{ paperWidthSm: classes.root }} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>{t.translate('Storage location details')}</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid
          item
          container
          direction="column"
          spacing={1}
          className={classes.item}
        >
          <StorageItem storage={storage} />
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="primary" onClick={onClose}>{t.translate('Cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

StorageDetailsDialog.propTypes = {
  storageProduct: PropTypes.shape({
    storage: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    product: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    supplierId: PropTypes.number,
  }),
  onComplete: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  isMobileView: PropTypes.bool.isRequired,
};
