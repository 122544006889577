import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslator } from 'i18n';
import { didMount, useList } from 'hooks';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import Grid from '@mui/material/Grid';

import { selectOverviewSuppliers } from 'store/selectors/suppliers';

import {
  CheckableMenuItem,
  TuneBox,
} from 'components';
import { Checkbox } from '@mui/material';
import { selectDefaultProductGroups } from '../../store/selectors/account';
import { setDefaultProductGroups } from '../../store/actions/account';
import { getUserDefaultProductGroups, setUserDefaultProductGroups } from '../../services/local-storage';

const useStyles = makeStyles()(theme => ({
  title: {
    padding: theme.spacing(1.25, 2),
    backgroundColor: theme.palette.backgrounds.secondary,
    borderBottomColor: theme.palette.grey[255],
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  label: {
    fontWeight: 500,
  },
  popover: {
    '& .MuiPopover-paper': {
      marginLeft: theme.spacing(.5),
    },
  },
}));

export const SuppliersFilter = ({
  tables,
  type,
  isFilterByOverdue = false,
  isFilterByDot = false,
  productTypes = [],
  productGroups = [],
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const list = useList(tables);
  const dispatch = useDispatch();
  const suppliers = useSelector(selectOverviewSuppliers);
  const defaultProductGroups = useSelector(selectDefaultProductGroups);

  const overdueFilters = list.filters['isOverdue'];
  const dotFilters = list.filters['isDot'];
  const [filterUsed, setFilterUsed] = useState(null);
  const [activeProductGroups, setActiveProductGroups] = useState([]);
  const [isOverdue, setIsOverdue] = useState(!!overdueFilters ? overdueFilters === 'true' : false);
  const [isDot, setIsDot] = useState(!!dotFilters ? dotFilters === 'true' : false);

  const supplierFilters = list.filters[type];
  const productFilters = list.filters['productTypes'];


  didMount(() => {
    const productGroups = getUserDefaultProductGroups() || defaultProductGroups;
    setActiveProductGroups(productGroups);
    list.changeFilter('groupIds', [...productGroups]);
  });

  useEffect(() => {
    setFilterUsed(!!supplierFilters || !!productFilters);
  }, [supplierFilters, productFilters]);

  const supplierList = [['all', 'All'], ...Object.values(suppliers)?.map(({ id, number, name }) => {
    const key = type === 'supplierNumber' ? number : id;
    return [key, `${number} — ${name}`];
  })];

  const productTypesList = [['all', 'All', 'All'], ...productTypes?.map(({ id, code }) => {
    return [id, `${code} — ${t.translate(`productType:${code}`)}`];
  })];

  const productGroupsList = [...productGroups?.map(({ id, name }) => {
    return [id, `${id} — ${name}`];
  })];

  const changeFilter = (property, value) => e => {
    if (property === 'groupIds') {
      let values;
      if (activeProductGroups.includes(value)) {
        values = activeProductGroups.filter( group => group !== value);
      } else {
        values = [...activeProductGroups, value];
      }
      setActiveProductGroups(values);
      dispatch(setDefaultProductGroups(values));
      setUserDefaultProductGroups(values);
      list.changeFilter(
        property,
        [...values]
      );
    } else {
      list.changeFilter(
        property,
        value === 'all' ? [] : [value]
      );
    }
  };

  return (
    <TuneBox
      width={280}
      {...props}
      PopoverProps={{ className: classes.popover }}
      filterUsed={filterUsed}
    >
      <Grid container direction="column">
        {isFilterByOverdue && (
          <>
            <Grid container className={classes.title}>
              <Typography variant="body2" className={classes.label}>{t.translate('Filter by overdue')}</Typography>
            </Grid>
            <CheckableMenuItem
              label={t.translate('status:overdue')}
              checked={isOverdue}
              onChange={() => {
                setIsOverdue(prevState => {
                  list.changeFilter(
                    'isOverdue',
                    !prevState
                  );
                  return !prevState;
                });
              }}
              control={<Checkbox color="secondary" />}
            />
          </>
        )}
        <Grid container className={classes.title}>
          <Typography variant="body2" className={classes.label}>{t.translate('Filter by supplier')}</Typography>
        </Grid>
        {supplierList.map(([key, text]) => (
          <CheckableMenuItem
            key={key}
            label={text}
            checked={supplierFilters ? supplierFilters[0] === String(key) : 'all' === key}
            onChange={changeFilter(type, key)}
            control={<Radio color="secondary" />}
          />
        ))}
        {productTypes?.length !== 0 && (
          <>
          <Grid container className={classes.title}>
            <Typography variant="body2" className={classes.label}>{t.translate('Filter by type')}</Typography>
          </Grid>
          {productTypesList.map(([key, text]) => (
            <CheckableMenuItem
              key={key}
              label={text}
              checked={productFilters ? productFilters[0] === String(key) : 'all' === key}
              onChange={changeFilter('productTypes', key)}
              control={<Radio color="secondary" />}
            />
          ))}
          </>
        )}
        {productGroups?.length !== 0 && (
          <>
            <Grid container className={classes.title}>
              <Typography variant="body2" className={classes.label}>{t.translate('Filter by group')}</Typography>
            </Grid>
            {productGroupsList.map(([key, text]) => (
              <CheckableMenuItem
                key={key}
                label={text}
                checked={activeProductGroups.includes(key)}
                onChange={changeFilter('groupIds', key)}
                control={<Checkbox color="secondary" />}
              />
            ))}
          </>
        )}
        {isFilterByDot ? (
          <>
            <Grid container className={classes.title}>
              <Typography variant="body2" className={classes.label}>{t.translate('Filter by stock DOT')}</Typography>
            </Grid>
            <CheckableMenuItem
              label={t.translate('status:dot')}
              checked={isDot}
              onChange={() => {
                setIsDot(prevState => {
                  list.changeFilter(
                    'isDot',
                    !prevState
                  );
                  return !prevState;
                });
              }}
              control={<Checkbox color="secondary"/>}
            />
          </>
        ) : null}
      </Grid>
    </TuneBox>
  );
};

SuppliersFilter.propTypes = {
  tables: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  productTypes: PropTypes.array,
  isFilterByOverdue: PropTypes.bool,
};
