import { makeStyles } from 'tss-react/mui';
import { styleFormDialog } from 'theme/mixins/dialog';

export const useStyles = makeStyles()(theme => styleFormDialog(theme, {
  inputContainer: {
    display: 'flex',
    alignContent: 'center',

    '& label': {
      flex: 0.25,

      [theme.breakpoints.down('sm')]: {
        flex: 0.4,
      },
    },
  },
  actions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dropdownLabel: {
    marginRight: 10,
  },
  dropdown: {
    maxWidth: '300px',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '200px',
    },
  },
}));
