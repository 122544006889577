import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import JsBarcode from 'jsbarcode';
import ReactToPrint from 'react-to-print';
import { QRCodeSVG } from 'qrcode.react';
import { useTranslator } from 'i18n';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styleFormDialog } from 'theme/mixins/dialog';

import toaster from 'services/toaster';

const useStyles = makeStyles()(theme => styleFormDialog(theme, {
  content: {
    '&.MuiDialogContent-root': {
      width: 312,
    },
  },
  actions: {
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(.5),
  },
}));

export const QrCodePrintDialog = ({
  isProductPrint = false,
  ticketWithBarcode,
  isBarcode,
  onClose,
  ticket,
  value,
  ean,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const ticketRef = useRef(null);
  const ticketWithBarcodeRef = useRef(null);
  const barcodeRef = useRef(null);

  const [isRefSet, setIsRefSet] = useState(false);

  const setRef = ref => {
    const isRefSet = barcodeRef.current === null && ref;

    barcodeRef.current = ref;

    if (isRefSet) {
      setIsRefSet(true);
    }
  };

  useEffect(() => {
    if (isRefSet && isBarcode) {
      try {
        JsBarcode(barcodeRef.current, ean, {
          format: 'EAN13',
          margin: 0,
          width: 3,
        });
      } catch (err) {
        toaster.error(t.translate('Given EAN format is not supported.'));
        onClose();
      }
    }

    return () => setIsRefSet(false);
  }, [ean, isRefSet, isBarcode, t, onClose]);

  return (
    <Dialog scroll="body" {...props} className={classes.root} onClose={onClose}>
      <DialogTitle className={classes.title}>{t.translate(!isBarcode ? 'Print QR code' : 'Print barcode')}</DialogTitle>
      <DialogContent className={classes.content}>
        {!isProductPrint || (isProductPrint && !isBarcode)
          ? <QRCodeSVG
            bgColor="#FFFFFF"
            fgColor="#000000"
            level="Q"
            size={312}
            value={value}
          />
          : <svg ref={setRef}/>
        }
        <div
          style={{
            height: 0,
            overflow: 'hidden',
            position: 'relative',
            width: '2cm',
          }}
        >
          <div ref={ticketRef}>{ticket}</div>
          {isProductPrint ? <div ref={ticketWithBarcodeRef}>{ticketWithBarcode}</div> : null}
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
        <ReactToPrint
          trigger={() => <Button color="primary">{t.translate('Print')}</Button>}
          content={() => isBarcode ? ticketWithBarcodeRef.current : ticketRef.current}
          onAfterPrint={onClose}
        />
      </DialogActions>
    </Dialog>
  );
};

QrCodePrintDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  ticket: PropTypes.any.isRequired,
  value: PropTypes.string.isRequired,
};
