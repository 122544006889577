import { createSelector } from 'reselect';

export const retailersData = state => state.retailers.overview.data;

export const retailersMeta = state => state.retailers.overview.meta;

export const selectDetailsRetailer = ({ retailers }) => retailers.details.retailer;

export const retailersDataWithMeta = createSelector([
  retailersData,
  retailersMeta,
], (data, meta) => ({
  data,
  meta,
}));
