import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  useList,
} from 'hooks';

import { CircularLoader, Fab } from 'components';
import {
  DesktopView,
} from 'views/companies/overview';

import { isLoading } from 'store/selectors/app';
import { fetchCompanies } from 'services/companies';

import tables from 'config/tables';
import AddIcon from '@mui/icons-material/Add';
import { isAuthenticatedUserAdministrator } from 'store/selectors/account';

export const CompaniesOverview = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const list = useList(tables.companies.companies);

  const isAdministrator = useSelector(isAuthenticatedUserAdministrator);
  const isLoadingCompanies = useSelector(isLoading('companies.overview.companies'));

  const fetchData = useCallback(() => dispatch(fetchCompanies(list.compile())), [dispatch, list]);

  useEffect(() => void fetchData(), [fetchData, location.search]);

  const openCreatingPage = () => history.push(`/settings/companies/new-company`);

  if (isLoadingCompanies) {
    return <CircularLoader />;
  }

  return (
    <>
      {isAdministrator && (
        <Fab onClick={openCreatingPage} aria-label="Create company">
          <AddIcon />
        </Fab>
      )}
      <DesktopView/>
    </>
  );
};
