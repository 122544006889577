import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import clsx from 'clsx';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { useStyles } from './MoreBox.jsx';
import Box from '@mui/material/Box';

export const MoreBox = ({
  onOpen,
  PopoverProps,
  children,
  isMobile,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const close = () => setAnchorEl(null);
  const open = e => {
    setAnchorEl(e.currentTarget);
    onOpen();
  };

  return <>
    {isMobile ? (
      <IconButton
        variant="contained"
        color="inherit"
        onClick={open}
        aria-label="toggle table tune box"
        size="large">
        <MoreVertIcon />
      </IconButton>
    ) : (
      <Button
        className={classes.button}
        startIcon={<MoreVertIcon />}
        onClick={open}
      >
        {t.translate('More')}
      </Button>

    )}
    <Popover
      keepMounted
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      {...PopoverProps}
      className={clsx(PopoverProps.className, classes.popover)}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={close}
    >
      <ArrowDropUpIcon className={classes.arrow} />
      <Box {...props} className={clsx(props.className, classes.box)}>{children}</Box>
    </Popover>
  </>;
};

MoreBox.propTypes = {
  PopoverProps: PropTypes.object,
  onOpen: PropTypes.func,
};

MoreBox.defaultProps = {
  PopoverProps: {},
  onOpen: () => {},
};
