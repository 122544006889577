import { useMemo } from 'react';
import { useRoute } from './route';

/**
 * Lets control pagination, sorting and filters using URL search params.
 *
 * @argument {string|object} name
 *        If the `name` is string, it'll be used as key of search params, e.g.
 *        If the `name` is 'stock', the 'page' param will be 'stock[page]'.
 *        If the `name` is an object, it will be used as the `options`.
 * @argument {object?}       options
 *        Default pagination and sorting options.
 * @property {number?}       options.rowsPerPage
 * @property {string?}       options.orderBy
 * @property {string?}       options.order
 *        Sorting direction. Should be one of ['desc', 'asc'].
 * @returns  {object}
 */
export const useList = (name, options = {}) => {
  if (typeof name !== 'string') {
    options = name || {};
    name = undefined;
  }

  const route = useRoute();

  return useMemo(
    () => {
      const go = search => route.replace({
        search: {
          ...route.search,
          ...(name ? {
            [name]: route.search[name] ? {
              ...route.search[name],
              ...search,
            } : search,
          } : search),
        },
      });

      const changeRowsPerPage = rowsPerPage => go({ rowsPerPage });

      const changePage = (e, page) => go({ page: page + 1 });

      const changeSorting = ({ orderBy, order }) => go({ orderBy, order });

      const changeFilter = (key, value) => go({ [key]: value });

      const changePageAndFilter = (key, value, page) => go({ [key]: value, page: page + 1 });

      const {
        rowsPerPage = options.rowsPerPage || 10,
        orderBy = options.orderBy,
        order = options.order || 'desc',
        page = 1,
        ...params
      } = name
        ? route.search[name] || {}
        : route.search;

      const pagination = {
        rowsPerPage: parseInt(rowsPerPage, 10),
        page: parseInt(page - 1, 10),
      };
      const sorting = {
        orderBy,
        order,
      };
      const filters = (({ q: searchQuery, ...params }) => ({ ...params, searchQuery }))(params);

      const paginationProps = {
        onRowsPerPageChange: changeRowsPerPage,
        onPageChange: changePage,
        ...pagination,
      };

      const compile = () => ({
        ...pagination,
        ...sorting,
        ...filters,
      });

      return {
        changeSorting,
        changeFilter,
        changePageAndFilter,
        go,
        paginationProps,
        pagination,
        sorting,
        filters,
        compile,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [route.history.location.search, name]
  );
};
