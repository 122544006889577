import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';

import { BarcodeIcon } from 'icons';

import { EanCodeScanDialog } from 'dialogs';
import { FormControlError } from 'components';

export const EanCodeControl = ({
  onChange,
  disabled,
  error,
  label,
  ...props
}) => {
  const disableAutoSubmission = e => {
    if (e.which === 13) {
      e.preventDefault();
    }
  };

  const [scannerVisible, setScannerVisible] = useState(false);

  return <>
    <EanCodeScanDialog
      open={scannerVisible}
      onClose={() => setScannerVisible(false)}
      onDecode={onChange}
    />

    <FormControl fullWidth error={!!error}>
      <InputLabel shrink htmlFor={props.id}>{label}</InputLabel>
      <Input
        {...props}
        disabled={disabled}
        onKeyDown={disableAutoSubmission}
        endAdornment={
          !disabled && (
            <>
              <IconButton
                onClick={() => setScannerVisible(true)}
                aria-label="scan ean code"
                size="large">
                <BarcodeIcon />
              </IconButton>
              {props.endAdornment}
            </>
            )}
        onChange={({ target: { value } }) => onChange(value.trim())}
      />
      {error && <FormControlError error={error} />}
    </FormControl>
  </>;
};

EanCodeControl.propTypes = {
  onChange: PropTypes.func.isRequired,
  error: PropTypes.any,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
