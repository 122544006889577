import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import {
  useDialog,
} from 'hooks';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { useStyles } from './IdentifyProductItem.jsx';
import Grid from '@mui/material/Grid';

import { makeSourceLink } from '../../services/files';
import {
  AlzuraSpriteIcon,
  Picture,
} from 'components';
import {
  ImageDialog,
} from 'dialogs';

export const IdentifyProductItem = ({
  isDetails,
  onSelect,
  onClose,
  isB2bProduct,
  product,
  isMobileView,
  onAssignProduct,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const imageDialog = useDialog({ images: [], additionalImages: [] });

  const assignProduct = () => {
    if (isB2bProduct) {
      onSelect({ b2bProduct: product });
    } else {
      onSelect({ product });
    }
    onClose();
  };

  return (
    <>
      {imageDialog.data.images && (
        <ImageDialog
          {...imageDialog.props}
          isMobile={isMobileView}
        />
      )}

      <Grid container className={classes.root} direction="column" justifyContent="space-between">
        <Grid item>
          <Typography variant="body1" className={classes.bold}>{product?.details?.[0]?.name}</Typography>
        </Grid>
        <Grid item container direction="column">
          <Grid item>
            <Picture
              image={product.imageUrls ? product.imageUrls[0] : (product.photo?.filePath ? makeSourceLink(product.photo.filePath) : '')}
              className={classes.photo}
              onClick={() => {
                imageDialog.open({ images: [(product.imageUrls ? product.imageUrls[0] : (product.photo?.filePath ? product.photo.filePath : ''))], additionalImages: [] });
              }}
            />
          </Grid>
          <Grid item>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>EAN</Typography>
              <Typography variant="body2" >{product.ean || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>ALZURA ID</Typography>
              <Typography variant="body2">{product.alzuraId || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Manufacturer number')}</Typography>
              <Typography variant="body2">{product.manufacturerNumber || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Manufacturer ID')}</Typography>
              <Typography variant="body2">{product.manufacturerId || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Manufacturer name')}</Typography>
              <Typography variant="body2">{product.manufacturerName || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Product type')}</Typography>
              <Typography variant="body2">{product.type?.code || '—'}</Typography>
            </Grid>
            {isDetails && (
              <>
                <Divider className={classes.divider}/>
                <Grid container justifyContent="space-between">
                  <Typography variant="body2" className={classes.bold}>{t.translate('Marketplace stock')}</Typography>
                  <Typography variant="body2">{product.stock || '—'}</Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Typography variant="body2" className={classes.bold}>{t.translate('Cheapest marketprice')}</Typography>
                  <Typography variant="body2">{t.formatPrice(product.cheapestMarketPrice)}&nbsp;&euro;</Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Typography variant="body2" className={classes.bold}>{t.translate('2nd Cheapest marketprice')}</Typography>
                  <Typography variant="body2" className={classes.bold}>{t.formatPrice(product.cheapestMarketPrice2)}&nbsp;&euro;</Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Typography variant="body2" className={classes.bold}>{t.translate('3rd Cheapest marketprice')}</Typography>
                  <Typography variant="body2" className={classes.bold}>{t.formatPrice(product.cheapestMarketPrice3)}&nbsp;&euro;</Typography>
                </Grid>
              </>
            )}
            <Grid container direction="row-reverse">
              <Button
                color="inherit"
                className={classes.button}
                startIcon={<AlzuraSpriteIcon id='assignmentAdd'/>}
                onClick={() => assignProduct()}
              >
                {t.translate('Assign product')}
              </Button>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

IdentifyProductItem.propTypes = {
  onClose: PropTypes.func.isRequired,
  isB2bProduct: PropTypes.bool.isRequired,
  product: PropTypes.shape({
    manufacturerNumber: PropTypes.string,
    manufacturerName: PropTypes.string,
    cheapestMarketPrice: PropTypes.number,
    cheapestMarketPrice2: PropTypes.number,
    cheapestMarketPrice3: PropTypes.number,
    type: PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
    }),
    name: PropTypes.string,
    ean: PropTypes.string,
    alzuraId: PropTypes.string,
    id: PropTypes.number,
  }),
  isMobileView: PropTypes.bool.isRequired,
  canEditAutoSalesPrice: PropTypes.bool,
};
