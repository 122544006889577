import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Quagga from 'quagga';
import { useTranslator } from 'i18n';
import { useStyles } from './EanCodeScanner.jsx';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import toaster from 'services/toaster';

export const EanCodeScanner = ({
  onDecode,
  isMobile,
  onClose,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const fileInput = useRef(null);
  const [legacyMode, setLegacyMode] = useState(!navigator.mediaDevices || typeof navigator.mediaDevices.getUserMedia !== 'function');
  const [showActions, setShowActions] = useState(false);

  const sendUp = ({ codeResult: { code } }) => {
    if (code) {
      onDecode(code);
      onClose();
    }
  };

  useEffect(() => {
    if (!legacyMode) {
      Quagga.init({
        inputStream: {
          type: 'LiveStream',
          target: document.querySelector('#stream-viewport'),
          constraints: {
            width: window.innerHeight,
            height: window.innerWidth,
            facingMode: 'environment',
          },
        },
        locator: {
          patchSize: 'medium',
          halfSample: true,
        },
        numOfWorkers: navigator.hardwareConcurrency || 2,
        decoder: {
          readers: ['ean_reader'],
        },
        locate: true,
      }, () => {
        try {
          Quagga.start();
          setShowActions(true);
        } catch (err) {
          setLegacyMode(true);
        }
      });

      Quagga.onDetected(e => {
        setShowActions(false);
        sendUp(e);
      });

      return () => {
        setShowActions(false);
        Quagga.stop();
        Quagga.offDetected(sendUp);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (legacyMode) {
      fileInput.current.click();
    }
  }, [legacyMode]);

  const decodeFromImage = ({ target: { files } }) => {
    if (files && files.length) {
      Quagga.decodeSingle({
        inputStream: {
          size: 800,
          singleChannel: false,
        },
        decoder: {
          readers: ['ean_reader'],
        },
        locate: true,
        src: URL.createObjectURL(files[0]),
        locator: {
          patchSize: 'large',
        },
      }, ({ codeResult }) => {
        if (codeResult) {
          sendUp({ codeResult });
        } else {
          toaster.error({
            content: 'Ean was not recognized, please try again.',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            ...(isMobile && { style: { bottom: 20 } }),
          });
          onClose();
        }
      });
    }
  };

  return legacyMode
    ? (
        <input
          className={classes.input}
          hidden
          type="file"
          ref={fileInput}
          onChange={decodeFromImage}
        />
      )
    : (
        <div className={classes.root}>
          <div id="stream-viewport" className={classes.viewport} />
          {showActions && (
            <DialogActions className={classes.actions}>
              <Button className={classes.button} fullWidth onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
            </DialogActions>
          )}
        </div>
      );
};

EanCodeScanner.propTypes = {
  onDecode: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
