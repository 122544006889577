import { createTheme } from '@mui/material/styles';

const theme = createTheme();

const palette = {
  primary: {
    main: '#7592d6',
    l900: '#00328e',
    l700: '#134eaf',
    l600: '#2159bb',
    l500: '#2961c5',
    l200: '#9eb1e2',
    l100: '#c4cfee',
    l65: '#a4bbf1',
    l50: '#e8ecf8',
    l25: '#eaf1ff',
    l10: '#f1f5ff',
  },
  secondary: {
    main: '#002a91',
    '20': '#001652',
    l700: '#2347b1',
    hover: {
      main: 'rgba(0, 42, 145, 0.04)',
    },
  },
  text: {
    secondary: 'rgba(0, 0, 0, 0.6)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    itemHeadline: '#a4afce',
  },
  grey: {
    '150': '#979797',
    '205': '#cdcdcd',
    '215': '#d8d8d8',
    '250': '#e4e4e4',
    '255': '#e3e3e3',
  },
  red: {
    coral: '#d62538',
  },
  green: {
    apple: '#45d15b',
    main: '#22b000',
    l700: '#1da012',
    l100: '#e5f6e0',
  },
  orange: {
    '255': '#ffd300',
    '250': '#fecd0b',
  },
  cyan: {
    main: '#38bff3',
  },
  brown: {
    main: '#351c15',
  },
  purple: {
    main: '#612a9e',
  },
  navigation: {
    main: '#d2def6',
  },
  backgrounds: {
    main: {
      '50': 'rgba(0, 50, 142, 0.02)',
    },
    secondary: '#f5f5f5',
    grey: {
      '170': 'rgba(173, 173, 173, 0.25)',
      '205': 'rgba(203, 203, 203, 0.25)',
      '225': 'rgba(224, 224, 224, 1)',
      '240': '#f2f2f2',
      '250': '#f1efef',
      '255': 'rgba(255, 255, 255, 0.2)',
    },
    red: 'rgba(255, 0, 0, 0.04)',
  },
  alert: {
    error: '#b00020',
  },
};

const zIndex = {
  cameraStream: 2000,
};

const props = {
  shadows: [
    '0px 1px 3px 0px rgba(0,0,0,0.2),0px 2px 1px -1px rgba(0,0,0,0.12),0px 1px 1px 0px rgba(0,0,0,0.14)',
  ],
};

export default createTheme({
  palette,
  zIndex,
  props,
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        marginTop: 0,
      },
    },
    MuiDateField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: `0 !important`,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        secondary: {
          '&:hover': {
            backgroundColor: palette.secondary.main,
          },
          '&:before': {
            background: 'rgba(255, 255, 255, 0)',
            borderRadius: 'inherit',
            bottom: 0,
            content: `''`,
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
            transition: `background ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut} 0ms`,
          },
          '&:hover:before': {
            background: 'rgba(255, 255, 255, .08)',
          },
        },
        extended: {
          '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(1),
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
        },
        text: {
          paddingLeft: `${theme.spacing(2)} !important`,
          paddingRight: `${theme.spacing(2)} !important`,
        },
        outlinedPrimary: {
          borderColor: `${theme.palette.divider} !important`,
          color: palette.primary.l500,
        },
        containedSecondary: {
          '&:hover': {
            backgroundColor: palette.secondary.main,
          },
          '&:before': {
            background: 'rgba(255, 255, 255, 0)',
            borderRadius: 'inherit',
            bottom: 0,
            content: `''`,
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
            transition: `background ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut} 0ms`,
          },
          '&:hover:before': {
            background: 'rgba(255, 255, 255, .08)',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: `${theme.spacing(1)} 0 calc(${theme.spacing(1)} + 1px)`,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: `${theme.spacing(6)} !important`,
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '& .MuiTouchRipple-child': {
            backgroundColor: palette.primary.main,
          },
        },
        icon: {
          color: `${theme.palette.text.primary} !important`,
          margin: '0 !important',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: `${palette.primary.l200} !important`,
            '& .MuiTableCell-root': {
              color: theme.palette.common.white,
            },
          },
          '&:hover .MuiTableCell-body.TableCell-fixed:before': {
            backgroundColor: theme.palette.action.hover,
            bottom: 0,
            content: `''`,
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
          },
        },
        hover: {
          cursor: 'pointer',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          fontSize: '0.875rem',
          padding: '.5rem',
          '&.TableCell-fixed': {
            backgroundColor: theme.palette.common.white,
            position: 'sticky',
            '&.MuiTableCell-head': {
              zIndex: 4,
            },
            '&.MuiTableCell-body': {
              zIndex: 3,
            },
          },
          '&.TableCell-fixedLeftLast': {
            borderRightColor: theme.palette.grey[300],
            borderRightStyle: 'solid',
            borderRightWidth: 1,
          },
          '&.TableCell-fixedRightFirst': {
            borderLeftColor: theme.palette.grey[300],
            borderLeftStyle: 'solid',
            borderLeftWidth: 1,
          },
        },
        head: {
          fontSize: '0.875rem',
          fontWeight: 500,
          height: theme.spacing(7),
          '& .MuiInput-input': {
            fontWeight: 'normal',
          },
        },
        stickyHeader: {
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          flexShrink: 0,
        },
        toolbar: {
          minHeight: `${theme.spacing(6.5)} !important`,
          paddingRight: theme.spacing(3),
        },
        selectIcon: {
          marginTop: theme.spacing(-1.5),
          top: '50% !important',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: `${palette.secondary.l700} !important`,
          },
          '&.Mui-disabled': {
            color: `${palette.grey[150]} !important`,
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        'grid-xs-true': {
          flexBasis: 'auto',
        },
        container: {
          flexWrap: 'nowrap',
        },
      },
    },
  },
});
