import { defineMessages } from 'react-intl';
import { i18n } from 'config';
import {
  setMessages,
  setLocale,
} from 'store/actions/i18n';
import {
  detectLocale,
  saveLocale,
} from './locale';
import numeral from 'numeral';
import appApi from 'api/app';
import store from 'store';
import api from 'api';

export const applyLocale = locale => async dispatch => {
  try {
    const { messages } = store.getState().i18n;
    const finalLocale = locale && i18n.locales[locale] ? locale : detectLocale();

    api.setLocale(finalLocale);
    if (!messages[finalLocale]) {
      const messages = await appApi.fetchTranslations();
      dispatch(setMessages(finalLocale, messages));
      defineMessages(messages);
    }

    dispatch(setLocale(finalLocale));
    numeral.locale(finalLocale);
    saveLocale(finalLocale);
  } catch (err) {}
};

