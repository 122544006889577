import {
  fetchRetailers as fetchRetailersApi,
  fetchRetailer as fetchRetailerApi,
} from 'api/retailers';
import { setOverviewRetailers, setDetailsRetailer } from 'store/actions/retailers';
import {
  startLoading,
  stopLoading,
} from 'store/actions/app';

export const fetchRetailers = params => async dispatch => {
  try {
    dispatch(startLoading('retailers.overview.retailers'));
    const retailers = await fetchRetailersApi(params);
    dispatch(setOverviewRetailers(retailers));
    dispatch(stopLoading('retailers.overview.retailers'));
  } catch (err) {
    dispatch(stopLoading('retailers.overview.retailers'));
  }
};

export const fetchRetailer = retailerId => async dispatch => {
  try {
    dispatch(startLoading('retailers.details.retailer'));
    const retailer = await fetchRetailerApi(retailerId);
    dispatch(setDetailsRetailer(retailer));
    dispatch(stopLoading('retailers.details.retailer'));
  } catch (err) {
    dispatch(stopLoading('retailers.details.retailer'));
  }
};
