import { EvaluationTypes } from 'consts';

export const customizeEvaluations = (reSellStorage, repatriationStorage, values) => {
  const evaluations = [];
  if (((values.reSellQuantity > 0) && !reSellStorage) || ((values.repatriationQuantity > 0) && !repatriationStorage))
    return evaluations;

  if ((values.reSellQuantity > 0) && reSellStorage) {
    evaluations.push({
      type: EvaluationTypes.RESELL,
      quantity: values.reSellQuantity,
      storageId: reSellStorage.id,
    });
  }

  if ((values.repatriationQuantity > 0) && repatriationStorage) {
    evaluations.push({
      type: EvaluationTypes.REPATRIATE,
      quantity: values.repatriationQuantity,
      storageId: repatriationStorage.id,
    });
  }

  if (values.trashQuantity > 0) {
    evaluations.push({
      type: EvaluationTypes.TRASH,
      quantity: values.trashQuantity,
    });
  }

  return evaluations;
};
