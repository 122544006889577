import React, { useEffect, useState } from 'react';

import { useTranslator } from 'i18n';

import TableContainer from '@mui/material/TableContainer';
import { makeStyles } from 'tss-react/mui';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import { CollapsedRow } from './CollapsedRow/CollapsedRow';

import { CollapseTableRow } from 'components';

import { groupBy } from 'lodash';

import { useSelector } from 'react-redux';

const useStyles = makeStyles()(theme => ({
  actionButtons: {
    whiteSpace: 'nowrap',
  },
  button: {
    color: theme.palette.primary.l900,
  },
  countryCell: {
    textTransform: 'uppercase',
  },
  cellPadding: {
    '& .MuiTableRow-root .MuiTableCell-root:first-of-type': {
      paddingLeft: theme.spacing(3),
    },
    '& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:last-child': {
      paddingRight: theme.spacing(2),
    },
  },
}));

export const OutdatedProductsTable = ({
  selectedStorageProducts,
  setSelectedStorageProducts,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const [groupedStorageProducts, setGroupedStorageProducts] = useState({});
  const [filterValue, setFilterValue] = useState(null);

  const { data: outdatedStorageProducts } = useSelector(({ outdatedProducts }) => outdatedProducts.overview.outdatedProducts);

  useEffect(() => {
    const filteredProducts = filterValue
      ? outdatedStorageProducts.filter(p => p.productId === filterValue)
      : outdatedStorageProducts;

    setGroupedStorageProducts(groupBy(filteredProducts, 'productId'));
  }, [outdatedStorageProducts, filterValue]);

  useEffect(() => {
    setFilterValue(null);
  }, [outdatedStorageProducts]);

  const handleSelectPosition = (storageProduct) => {
    const { id, productId } = storageProduct;
    const filterProduct = selectedStorageProducts.find(p => p.id === id);

    if (filterProduct) {
      const filteredProducts = selectedStorageProducts.filter(p => p.id !== id);
      setSelectedStorageProducts(filteredProducts);

      if (filteredProducts.length === 0) setFilterValue(null);
    }
    else {
      setSelectedStorageProducts([...selectedStorageProducts, storageProduct]);
    }

    if (filterValue === null) setFilterValue(productId);
  };

  return (
    <Paper>
      <TableContainer>
        <Table className={classes.cellPadding}>
          <TableHead>
            <TableRow>
              <TableCell/>
              <TableCell>{t.translate('Product ID')}</TableCell>
              <TableCell>{t.translate('Alzura ID')}</TableCell>
              <TableCell>{t.translate('Manufacturer no.')}</TableCell>
              <TableCell>{t.translate('Manufacturer')}</TableCell>
              <TableCell>{t.translate('Product name')}</TableCell>
              <TableCell>{t.translate('EAN')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(groupedStorageProducts).map((key, index) => {
              const { product } = groupedStorageProducts[key][0];

              return (
                <CollapseTableRow
                  key={index}
                  hover
                  colSpan={7}
                  isOpenCollapsedRow={selectedStorageProducts.find(sp => sp.productId === product.id)}
                  collapsedRow={
                    <CollapsedRow
                      products={groupedStorageProducts[key]}
                      onClick={handleSelectPosition}
                      selectedStorageProducts={selectedStorageProducts}
                    />
                  }
                >
                  <TableCell>{key}</TableCell>
                  <TableCell>{product.alzuraId}</TableCell>
                  <TableCell>{product.manufacturerNumber}</TableCell>
                  <TableCell>{product.manufacturerName}</TableCell>
                  <TableCell>{product.details[0].name}</TableCell>
                  <TableCell>{product.ean}</TableCell>
                </CollapseTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

OutdatedProductsTable.propTypes = {};
