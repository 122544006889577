import React from 'react';
import { SvgIcon } from '@mui/material';

export const NavigationHeaderIcon = (props) => (
  <SvgIcon {...props} titleAccess="NavigationHeader" viewBox="0 0 304 113">
    <defs>
      <filter id="a0imsttxtb">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.458824 0 0 0 0 0.572549 0 0 0 0 0.839216 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(0 -24) translate(0 -1)">
          <g filter="url(#a0imsttxtb)" opacity=".448">
            <g mask="url(#qljct34pxc)">
              <g>
                <g>
                  <path
                    fill="#002A91"
                    fillRule="nonzero"
                    d="M22.714 59.664v4.061c-1.1-.35-2.177-.758-3.224-1.22V58.25c1.04.534 2.116 1.007 3.224 1.414zm22.733-1.251v4.232c-.945.404-1.913.765-2.903 1.08v-4.061c.995-.365 1.964-.783 2.903-1.251zM32.63 0c18.02 0 32.629 14.609 32.629 32.63 0 4.272-.821 8.353-2.314 12.092-.888-.303-1.884-.527-2.987-.667l-.234-.028v-1.648c1.096-3.045 1.693-6.327 1.693-9.75 0-15.898-12.889-28.787-28.787-28.787-15.9 0-28.788 12.889-28.788 28.788 0 5.484 1.534 10.61 4.195 14.972H3.63C1.31 43.118 0 38.027 0 32.63 0 14.609 14.609 0 32.63 0z"
                    transform="translate(220 45)"
                  />
                </g>
                <g>
                  <g transform="translate(220 45) translate(.678 42.322) translate(.428 8.16)">
                    <rect
                      width="15.503"
                      height="15.503"
                      stroke="#002A91"
                      strokeWidth="2.401"
                      rx=".861"
                    />
                    <path
                      fill="#002A91"
                      d="M6.029 0.861L9.474 0.861 9.474 7.751 7.751 6.379 6.029 7.751z"
                    />
                    <g fill="#002A91">
                      <path
                        d="M0.861 2.584H2.584V5.168H0.861z"
                        transform="translate(9.474 7.751)"
                      />
                      <path
                        d="M1.723 0L3.445 3.445 0 3.445z"
                        transform="translate(9.474 7.751)"
                      />
                    </g>
                  </g>
                  <g>
                    <g transform="translate(220 45) translate(.678 42.322) translate(47.651 .894) translate(0 9.474)">
                      <rect
                        width="13.953"
                        height="13.953"
                        stroke="#002A91"
                        strokeWidth="2.401"
                        rx=".775"
                      />
                      <path
                        fill="#002A91"
                        d="M5.426 0.775L8.527 0.775 8.527 6.976 6.976 5.741 5.426 6.976z"
                      />
                      <g fill="#002A91">
                        <path
                          d="M0.775 2.325H2.325V4.65H0.775z"
                          transform="translate(8.527 6.976)"
                        />
                        <path
                          d="M1.55 0L3.101 3.101 0 3.101z"
                          transform="translate(8.527 6.976)"
                        />
                      </g>
                    </g>
                    <path
                      fill="#002A91"
                      d="M9.474 0v2.597c8.42.269 10.649 4.57 6.685 12.906 1.73-6.593-.497-10.031-6.684-10.316L9.474 7.75 5.168 3.876 9.474 0z"
                      transform="translate(220 45) translate(.678 42.322) translate(47.651 .894)"
                    />
                  </g>
                  <g>
                    <path
                      stroke="#002A91"
                      strokeWidth="2.401"
                      d="M.861 0h9.69l3.516 3.876V14.64c0 .476-.385.862-.86.862H.86c-.475 0-.861-.386-.861-.861V.862C0 .385.386 0 .861 0z"
                      transform="translate(220 45) translate(.678 42.322) translate(24.748 13.185)"
                    />
                    <path
                      fill="#002A91"
                      d="M2.871 2.871H9.331V4.306H2.871zM2.871 5.742H11.484V7.177H2.871zM2.871 8.613H11.484V10.048H2.871zM2.871 11.484H7.895V12.919H2.871z"
                      transform="translate(220 45) translate(.678 42.322) translate(24.748 13.185)"
                    />
                  </g>
                </g>
                <path
                  fill="#002A91"
                  d="M49.831 34.393v3.925H22.185l3.565 3.565c.767.766.767 2.009 0 2.775-.766.767-2.009.767-2.775 0l-6.94-6.939c-.34-.352-.55-.833-.55-1.364 0-1.084.878-1.962 1.962-1.962h32.384zm-7.49-14.682l6.94 6.94c.34.352.55.833.55 1.363 0 1.084-.878 1.963-1.962 1.963H15.485V26.05H43.13l-3.565-3.564c-.767-.767-.767-2.01 0-2.776.766-.767 2.009-.767 2.775 0z"
                  transform="translate(220 45)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
