import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';

import { styleMobileTableItem } from 'theme/mixins/table';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));

export const ShippingPartnersCard = ({
  onAvailabilityChange,
  shippingPartners,
  canEditWarehouse,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <Paper className={classes.root}>
      <Grid {...props} container direction="column" spacing={2}>
        <Grid item container direction="column" spacing={1} className={classes.controlsItem}>
          {shippingPartners.map(shippingPartner => (
            <Grid item key={shippingPartner.id}>
              <FormControlLabel
                control={
                  <Switch
                    checked={shippingPartner.isEnabled}
                    disabled={!canEditWarehouse}
                    onChange={onAvailabilityChange(shippingPartner.id)}
                  />
                }
                label={t.translate(`shippingPartner:${shippingPartner.name}`)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};

ShippingPartnersCard.propTypes = {
  onAvailabilityChange: PropTypes.func.isRequired,
  shippingPartners: PropTypes.arrayOf(
    PropTypes.shape({
      isEnabled: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  canEditWarehouse: PropTypes.bool.isRequired,
};
