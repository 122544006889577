import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    page: {
        padding: theme.spacing(6, 0),
        width: 400 + theme.spacing(6 + 3 * 2),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 0),
            width: 312 + theme.spacing(2 + 2 * 2),
        },
    },
    logoItem: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: `${theme.spacing(3)} !important`,
            paddingTop: `${theme.spacing(3)} !important`,
            width: 312 + theme.spacing(2 + 2 * 2),
        },
    },
    paper: {
        padding: theme.spacing(3),
        width: 400,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1.5, 2, 3),
            width: 312,
        },
    },
    localeSwitcher: {
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(-2),
        },
    },
}));
