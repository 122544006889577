import React from 'react';

import { useTranslator } from 'i18n';
import { useRoute } from 'hooks/route';
import { SearchInput } from '../../common';
import {
  AppHeaderPrimaryToolbar,
  AppHeaderRightBox,
  QrCodeScanButton,
  HandheldListener,
  AppHeaderTitle,
  AppHeader,
} from 'components';
import { PickupSheetsFilter } from '../../common';
import Grid from '@mui/material/Grid';

export const Header = () => {
  const t = useTranslator();
  const route = useRoute();

  const openPickupSheet = pickupSheetId => route.push({
    pathname: `/pickup-sheets/${pickupSheetId}`,
    search: { list: route.search },
  });

  return (
    <AppHeader>
      <AppHeaderPrimaryToolbar>
        <AppHeaderTitle>{t.translate('Pickup sheets')}</AppHeaderTitle>
        <AppHeaderRightBox>
          <Grid container spacing={1}>
            <Grid item>
              <QrCodeScanButton onComplete={openPickupSheet} />
              <HandheldListener onScan={openPickupSheet} />
            </Grid>
            <Grid item>
              <PickupSheetsFilter />
            </Grid>
            <Grid item>
              <SearchInput />
            </Grid>
          </Grid>
        </AppHeaderRightBox>
      </AppHeaderPrimaryToolbar>
    </AppHeader>
  );
};
