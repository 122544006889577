import numeral from 'numeral';

numeral.register('locale', 'de', {
  ...numeral.locales.en,
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  currency: {
    symbol: '€',
  },
});
