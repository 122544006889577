import React from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import { Column } from 'layouts';
import { Header } from './Header/Header';
import { PickupSheetItem } from './PickupSheetItem/PickupSheetItem';
import { selectActionPermission } from 'store/selectors/account';

export const MobileView = props => {
  const history = useHistory();

  const canDeletePickupSheet = useSelector(selectActionPermission('pickupSheets', 'delete'));
  const canPrintPickupSheet = useSelector(selectActionPermission('pickupSheets', 'print'));
  const pickupSheets = useSelector(({ pickupSheets }) => pickupSheets.pickupSheets.data);

  const openPickupSheet = pickupSheet => history.push(`/pickup-sheets/${pickupSheet.id}`);

  return (
    <>
      <Header />
      <Column p={1} pb={8.5} pt={2}>
        <Grid container direction="column" >
          {pickupSheets.map(pickupSheet => (
            <PickupSheetItem
              {...props}
              key={pickupSheet.id}
              pickupSheet={pickupSheet}
              canPrintPickupSheet={canPrintPickupSheet}
              canDeletePickupSheet={canDeletePickupSheet}
              onPickupSheetOpen={openPickupSheet}
            />
          ))}
        </Grid>
      </Column>
    </>
  );
};
