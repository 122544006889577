import { makeStyles } from 'tss-react/mui';
import { styleMobileTableItem } from 'theme/mixins/table';

export const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  accordion: {
    background: theme.palette.backgrounds.main['50'],
    boxShadow: 'none',
    '& .MuiCollapse-root': {
      background: theme.palette.common.white,
    },
  },
  bold: {
    fontWeight: 500,
  },
  item: {
    background: theme.palette.common.white,
    marginTop: theme.spacing(1),
    marginBottom: props => props.lastItemPacked ? theme.spacing(12) : theme.spacing(3),
  },
  packageProductLabel: {
    marginLeft: theme.spacing(0.75),
  },
  photo: {
    flexShrink: 0,
    height: '35px',
    margin: theme.spacing(2, 0, 1.5),
    marginRight: theme.spacing(1),
    width: '35px',
  },
  photoHeader: {
    flexShrink: 0,
    height: '35px',
    margin: theme.spacing(0.5, 1.5),
    width: '35px',
  },
  headerLabel: {
    fontWeight: 500,
    marginRight: theme.spacing(1.5),
  },
  button: {
    margin: theme.spacing(1.5),
    color: theme.palette.red.coral,
    borderColor: theme.palette.red.coral,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.backgrounds.red,
    },
  },
}));
