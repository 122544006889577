import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import socket, { SOCKET_EVENTS } from '../../../bootstrap/socket';

import { useTranslator } from 'i18n';
import {
  useScreen,
  useDialog,
  useRoute,
  didMount,
  useList,
} from 'hooks';

import { CancellingOrderDialog, ConfirmingDialog } from 'dialogs';
import {
  DesktopView,
  CreatingFab,
  MobileView,
  Header,
} from 'views/orders/overview';

import { selectActionPermission } from 'store/selectors/account';
import { fetchOrders } from 'services/orders';
import { fetchLatestOrders, markAsReturnWithoutStock } from 'api/orders';
import { fetchSuppliersForFilter } from 'services/suppliers';
import tables from 'config/tables';
import toaster from 'services/toaster';
import { StockEvaluationDialog } from '../../../dialogs/StockEvaluationDialog/StockEvaluationDialog';
import { OrderStatusFilter, STUCK_ORDERS } from 'consts';
import { RawOrdersView } from '../../../views/orders/overview/RawOrdersView/RawOrdersView';

export const OrdersOverview = () => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const route = useRoute();
  const history = useHistory();
  const list = useList(tables.orders.orders);
  const t = useTranslator();

  const canCreatePickupSheet = useSelector(selectActionPermission('pickupSheets', 'create'));
  const canApproveOrder = useSelector(selectActionPermission('orders', 'approve'));
  const canPerformOrderActions = useSelector(selectActionPermission('orders', 'actions'));
  const canFetchOrders = useSelector(selectActionPermission('orders', 'fetchLatest'));

  const cancellingDialog = useDialog({ order: null });
  const stockEvaluationDialog = useDialog({ position: null });
  const confirmingDialog = useDialog({ orderReturnId: null });

  const fetchData = useCallback(() => {
    dispatch(fetchOrders(list.compile()));
  }, [
    dispatch,
    list,
  ]);

  const fetchLatestOrdersData = async () => {
    try {
      await fetchLatestOrders();
      toaster.success(t.translate('Latest orders have been fetched successfully.'));
    } catch (err) {}
  };

  const markReturnWithoutStock = async () => {
    await markAsReturnWithoutStock(confirmingDialog.data.orderReturnId);

    toaster.success(t.translate('Order return was updated successfully.'));
  };

  didMount(() => {
    dispatch(fetchSuppliersForFilter());

    if (!route.search.status) {
      history.push('/orders?status=open&order=asc');
    }
  });

  useEffect(() => {
    return () => {
      socket.off(SOCKET_EVENTS.ORDERS.UPDATE);
    };
  }, []);

  useEffect(() => {
    if (route.search.status) {
      socket.off(SOCKET_EVENTS.ORDERS.UPDATE);
      socket.on(SOCKET_EVENTS.ORDERS.UPDATE, async () => {
        await fetchData();
      });
      fetchData();
    }
  }, [
    fetchData,
    route.search.status,
  ]);

  const changeTab = tab => list.go({ 'status': tab, page: 1, order: tab === OrderStatusFilter.OPEN ? 'asc' : 'desc' });

  return (
    <>
      <Helmet>
        <title>{t.translate('Orders')} | {t.translate('app:title')}</title>
      </Helmet>

      {cancellingDialog.data.order && canPerformOrderActions && (
        <CancellingOrderDialog
          {...cancellingDialog.props}
          isMobileView={screen.isMobile}
          onSave={fetchData}
        />
      )}

      {stockEvaluationDialog.data.position && (
        <StockEvaluationDialog
          {...stockEvaluationDialog.props}
          isMobileView={screen.isMobile}
          onSave={fetchData}
        />
      )}

      {canPerformOrderActions && (
        <ConfirmingDialog
          {...confirmingDialog.props}
          title={t.translate('Mark the return as a return without stock')}
          acceptButtonText={t.translate(`Yes`)}
          declineButtonText={t.translate('No')}
          onAccept={markReturnWithoutStock}
        >
          {t.translate('Do you want to mark the selected return as a return without stock?')}
        </ConfirmingDialog>
      )}

      {canCreatePickupSheet && (
        <>
          <CreatingFab onCreate={fetchData} label={'Create next pickup sheet'} style={{ right: '310px' }}/>
          <CreatingFab onCreate={fetchData} label={'Create all pickup sheets'} isCreateMany={true}/>
        </>
      )}

      <Header
        isMobile={screen.isMobile}
        onTabChange={changeTab}
        canFetchOrders={canFetchOrders}
        fetchLatestOrders={fetchLatestOrdersData}
      />

      {route.search.status !== STUCK_ORDERS ? (
        screen.isMobile ? (
          <MobileView
            onOrderApprove={fetchData}
            onOrderCancel={order => cancellingDialog.open({ order })}
            canPerformOrderActions={canPerformOrderActions}
            canApprove={canApproveOrder}
          />
        ) : (
          <DesktopView
            onOrderCancel={order => cancellingDialog.open({ order })}
            onApprove={fetchData}
            onStockEvaluation={position => stockEvaluationDialog.open({ position })}
            onMarkReturnWithoutStock={orderReturnId => confirmingDialog.open(orderReturnId)}
            canPerformOrderActions={canPerformOrderActions}
            canApprove={canApproveOrder}
          />
        )
      ) : (
        <RawOrdersView/>
      )}
    </>
  );
};
