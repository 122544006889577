import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Link from '@mui/material/Link';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  Button,
  TextField,
  Grid,
  Select,
  MenuItem,
} from '@mui/material';

import { useTranslator } from 'i18n';
import { useStyles } from './style';
import { makeSourcePublicLink } from '../../services/files';
import { fetchSuppliers } from '../../api/suppliers';
import { useList } from '../../hooks';
import tables from '../../config/tables';
import { fetchWarehouses } from '../../api/warehouses';
import { SupplierHandlingTypes } from 'consts';

export const ImportPurchasesDialog = ({
  onPurchasesImport,
  onClose,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const [file, setFile] = useState({ name: '' });
  const [supplier, setSupplier] = useState('');
  const [supplierList, setSupplierList] = useState([]);
  const [warehouse, setWarehouse] = useState('');
  const [warehouseList, setWarehouseList] = useState([]);
  const list = useList(tables.suppliers.suppliers);
  const wList = useList(tables.warehouses.warehouses);

  const onImportClick = () => {
    onPurchasesImport({
      file,
      supplierId: supplier || null,
      warehouseId: warehouse || null,
    });
  };

  useEffect( () => {
    fetchWarehouses({
      meta: ['list-set'],
      ...wList.compile(),
    }).then(({ data }) => {
      setWarehouseList(data);
    });
    fetchSuppliers({
      ...list.compile(),
      limit: -1,
      handlingType: [SupplierHandlingTypes.DEFAULT],
    }).then(({ data }) => {
      setSupplierList(data);
    });
  }, [list, wList]);

  useEffect(() => {
    warehouseList.length && setWarehouse(warehouseList[0].id);
    supplierList.length && setSupplier(supplierList[0].id);
  }, [supplierList, warehouseList]);

  return (
    <>
      <Dialog scroll="body" {...props} className={classes.root} id='import-products-dialog'>
        <DialogTitle className={classes.title}>
          <Grid container alignItems={'center'}>
            <Grid item className={classes.dropdownLabel}>{t.translate('Import')}</Grid>
          </Grid>
        </DialogTitle>
          <DialogContent className={classes.content}>
            <DialogContentText>
              {t.translate('You can use the purchases import to create purchses based on a csv file.')}
            </DialogContentText>
            <DialogContentText>
              {t.translate('The file should have the following structure')}:
              <Link underline="hover" className={classes.demoLink} href={makeSourcePublicLink('/demo/purchase_import_demo.csv')}>
                demo
              </Link>
            </DialogContentText>
            <DialogContentText>
              {t.translate('You will receive an import status mail after the import is completed.')}
            </DialogContentText>
            <Grid container alignItems={'center'}>
              <Grid item className={classes.dropdownLabel}>{t.translate('Supplier')}</Grid>
              <Grid item>
                <Select
                  id="supplier-dropdown"
                  value={supplier}
                  onChange={(e) => {
                    setSupplier(e.target.value);
                  }}
                >
                  {supplierList.map((supplier, index) => (
                    <MenuItem key={`${supplier.number}-${index}`} value={supplier.id}>
                      {`${supplier.number}-${supplier.name}`}
                    </MenuItem>))
                  }
                </Select>
              </Grid>
            </Grid>
            <Grid container alignItems={'center'}>
              <Grid item className={classes.dropdownLabel}>{t.translate('Warehouse')}</Grid>
              <Grid item>
                <Select
                  id="warehouse-dropdown"
                  value={warehouse}
                  onChange={(e) => {
                    setWarehouse(e.target.value);
                  }}
                >
                  {warehouseList.map(warehouse => (
                    <MenuItem key={warehouse.id} value={warehouse.id}>{warehouse.id} — {warehouse.name}</MenuItem>))
                  }
                </Select>
              </Grid>
            </Grid>
            <Box className={classes.inputContainer} style={{ marginTop: 10 }}>
              <TextField className={classes.fileName} variant="outlined" value={file?.name} disabled={true} size="small" />
              <label htmlFor="select-file-input">
                <input id="select-file-input" type="file" accept=".csv" className={classes.selectFileInput} onChange={(event) => setFile(event.target.files[0])} />
                <Button color="primary" className={classes.selectFileButton} variant="contained" component="span" size="small">{t.translate('Select file')}</Button>
              </label>
            </Box>
          </DialogContent>
        <DialogActions className={classes.actions}>
          <Button color="primary" onClick={onImportClick}>{t.translate('Import')}</Button>
          <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ImportPurchasesDialog.propTypes = {
  onPurchasesImport: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
