import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import JsBarcode from 'jsbarcode';
import { useTranslator } from 'i18n';

const useStyles = makeStyles()({
  root: {
    marginLeft: '0',
    marginTop: '0',
    maxWidth: '5cm',
    maxHeight: '2.5cm',
  },
  summaryItem: {
    marginLeft: '0.25cm',
    lineHeight: '6px',
    '& .MuiTypography-root': {
      fontSize: '8px',
    },
  },
});

export const ProductTicket = ({ product, isBarcode= false }) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const barcodeRef = useRef(null);

  useEffect(() => {
    if (isBarcode) {
      try {
        JsBarcode(barcodeRef.current, product.ean, {
          format: 'EAN13',
          width: 1,
          height: 40,
          margin: 0,
          flat: true,
          fontSize: '10px',
        });
      } catch (err) {}
    }
  }, [product, isBarcode, t]);

  return (
    <Grid container className={classes.root}>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            {!isBarcode ?
              <QRCodeSVG
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="Q"
                size={56}
                value={product.id.toString()}
              /> : <svg ref={barcodeRef}/>
            }
          </Grid>
          <Grid item align="center">
            <Typography variant="caption" style={{ fontSize: '8px' }}>ID: {product.id}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.summaryItem}>
        <Grid container direction="column">
          {product.details[0]?.name && <Grid item><Typography variant="caption">{product.details[0].name}</Typography></Grid>}
          {product.alzuraId && <Grid item><Typography variant="caption">Alzura ID: {product.alzuraId}</Typography></Grid>}
          {product.ean && <Grid item><Typography variant="caption">EAN: {product.ean}</Typography></Grid>}
        </Grid>
      </Grid>
    </Grid>
  );
};

ProductTicket.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    ean: PropTypes.string,
    alzuraId: PropTypes.string,
    id: PropTypes.number.isRequired,
  }).isRequired,
};
