import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { FormControlError } from 'components';

export const InputControl = ({
  InputComponent,
  label,
  error,
  ...props
}) => (
  <FormControl fullWidth error={!!error}>
    <InputLabel variant="standard" shrink htmlFor={props.id}>{label}</InputLabel>
    {InputComponent ? <InputComponent {...props} /> : <Input {...props} />}
    {!!error && <FormControlError error={error} />}
  </FormControl>
);

InputControl.propTypes = {
  InputComponent: PropTypes.any,
  label: PropTypes.string,
  error: PropTypes.any,
  id: PropTypes.string.isRequired,
};
