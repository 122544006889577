import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslator } from 'i18n';
import { makeStyles } from 'tss-react/mui';

import { Column } from 'layouts';
import { ActionButton, CircularLoader, Pagination } from 'components';
import { useDialog, useList, useTableSortableHeadCell } from 'hooks';
import { selectRawOrdersData, selectRawOrdersLoading, selectRawOrdersMeta } from 'store/selectors/raw-orders';
import { RawOrderDialog, JsonViewDialog } from 'dialogs';
import { fetchRawOrders } from 'services/raw-orders';
import tables from 'config/tables';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FeedIcon from '@mui/icons-material/Feed';
import FeedbackIcon from '@mui/icons-material/Feedback';

import {
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Table,
  Paper,
  Link,
} from '@mui/material';

const useStyles = makeStyles()(theme => ({
  button: {
    color: theme.palette.primary.l900,
  },
  cellPadding: {
    '& .MuiTableRow-root .MuiTableCell-root:first-of-type': {
      paddingLeft: theme.spacing(3),
    },
    '& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:last-child': {
      paddingRight: theme.spacing(2),
    },
  },
}));

export const RawOrdersView = () => {
  const dispatch = useDispatch();
  const t = useTranslator();
  const history = useHistory();
  const { classes } = useStyles();

  const list = useList(tables.rawOrders.rawOrders);

  useEffect(() => {
    dispatch(fetchRawOrders(list.compile()));
  }, [
    dispatch,
    list,
  ]);

  const isLoading = useSelector(selectRawOrdersLoading);
  const rawOrders = useSelector(selectRawOrdersData);
  const { totalEntities } = useSelector(selectRawOrdersMeta);

  const rawOrderDialog = useDialog();
  const jsonViewDialog = useDialog();

  const SortableCell = useTableSortableHeadCell({
    changeSorting: list.changeSorting,
    sorting: list.sorting,
  });

  const openSupplier = id => history.push(`/edit-supplier/${id}`);

  if (isLoading) {
    return <CircularLoader />;
  } else {
    return (
      <>
        {rawOrderDialog.visible &&
          <RawOrderDialog
            {...rawOrderDialog.props}
          />
        }
        {jsonViewDialog.visible &&
          <JsonViewDialog
            {...jsonViewDialog.props}
          />
        }
        <Column p={3} fixed sx={{ top: '130px' }}>
          <Paper>
            <TableContainer>
              <Table className={classes.cellPadding}>
                <TableHead>
                  <TableRow>
                    <SortableCell name="date">{t.translate('Date')}</SortableCell>
                    <SortableCell name="number">{t.translate('Order no')}</SortableCell>
                    <SortableCell name="supplierNumber">{t.translate('Supplier number')}</SortableCell>
                    <TableCell align="right">{t.translate('Actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rawOrders.map((rawOrder, index) => (
                    <TableRow key={`rawOrder-${index}`}>
                      <TableCell>{t.formatDate(rawOrder.createdAt)} {t.formatTime(rawOrder.createdAt)}</TableCell>
                      <TableCell>{rawOrder.number}</TableCell>
                      <TableCell>
                        <Link onClick={() => openSupplier(rawOrder.supplier.id)}>{rawOrder.supplier.number}</Link>
                      </TableCell>
                      <TableCell align="right">
                        <ActionButton
                          aria-label={t.translate('Create order')}
                          tooltip={t.translate('Create order')}
                          icon={<CheckCircleIcon/>}
                          onClick={() => rawOrderDialog.open({ id: rawOrder.id })}
                          className={classes.button}
                        />
                        <ActionButton
                          aria-label={t.translate('Show order data')}
                          tooltip={t.translate('Show order data')}
                          icon={<FeedIcon/>}
                          onClick={() => jsonViewDialog.open({ data: rawOrder.data, title: t.translate('Order data') })}
                        />
                        <ActionButton
                          aria-label={t.translate('Show error data')}
                          tooltip={t.translate('Show error data')}
                          icon={<FeedbackIcon/>}
                          onClick={() => jsonViewDialog.open({ data: rawOrder.error, title: t.translate('Error data') })}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination {...list.paginationProps} count={totalEntities}/>
          </Paper>
        </Column>
      </>
    );
  }
};

RawOrdersView.propTypes = {

};
