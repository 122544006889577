import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import { useTranslator } from 'i18n';
import { Column } from 'layouts';
import { ItemHeadline } from 'components';

import { WarehouseCard } from '../WarehouseCard/WarehouseCard';
import { ShippingPartnersCard } from '../common';

export const DesktopView = ({
  onShippingPartnerAvailabilityChange,
  canEditWarehouse,
  warehouse,
}) => {
  const t = useTranslator();

  return (
    <Column p={3}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <ItemHeadline text={t.translate('Warehouse')} />
          <WarehouseCard
            warehouse={warehouse}
          />
        </Grid>
        <Grid item xs={6}>
          <ItemHeadline text={t.translate('Shipping partners')} />
            <ShippingPartnersCard
              shippingPartners={warehouse.shippingPartners}
              canEditWarehouse={canEditWarehouse}
              onAvailabilityChange={onShippingPartnerAvailabilityChange}
            />
        </Grid>
      </Grid>
    </Column>
  );
};

DesktopView.propTypes = {
  onShippingPartnerAvailabilityChange: PropTypes.func.isRequired,
  canEditWarehouse: PropTypes.bool,
  warehouse: PropTypes.object.isRequired,
};
