import React from 'react';

import { useSelector } from 'react-redux';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { selectOrdersMeta } from 'store/selectors/orders';

const useStyles = makeStyles()(theme => ({
  root: {
    alignItems: 'center',
    background: theme.palette.common.white,
    display: 'flex',
    height: theme.spacing(3),
    justifyContent: 'center',
    padding: theme.spacing(0, 2, 0, 6.5),
    '& .MuiTypography-root': {
      margin: theme.spacing(-1, 0, 1),
    },
  },
}));

export const Summary = ({
  className,
  ...props
}) => {
  const t = useTranslator();
  const { classes, cx } = useStyles();
  const meta = useSelector(selectOrdersMeta);

  return (
    <Box {...props} className={cx(className, classes.root)}>
      <Typography variant="body2">
        {t.translate('Orders retrieved at')}:
        {} {t.formatDate(meta.lastRetrieved)}
        {} {t.formatTime(meta.lastRetrieved, { second: '2-digit' })}
      </Typography>
    </Box>
  );
};
