import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import TableContainer from '@mui/material/TableContainer';
import { makeStyles } from 'tss-react/mui';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';

import { styleTableRow } from 'theme/mixins/table';
import { Picture } from 'components';

import numeral from 'numeral';

const useStyles = makeStyles()(theme => styleTableRow(theme, {
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.l600,
    },
  },
  defaultFontSize: {
    fontSize: '0.875rem',
  },
  emptyScannedLabel: {
    fontSize: '0.875rem',
    textAlign: 'center',
  },
  removeButton: {
    background: theme.palette.primary.main,
    border: `solid 1px ${theme.palette.grey['150']}`,
    borderRadius: '12px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.12)',
    color: theme.palette.common.white,
    justifyContent: 'space-around',
    height: '24px',
    minWidth: '55px',
    padding: '0 !important',
    '& .MuiButton-label': {
      marginLeft: theme.spacing(1),
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.l600,
    },
  },
}));

const defaultAdditionalImage = (additionalImages = []) => (
  additionalImages?.find(image => image.isDefault)
);

export const NextPackageTable = ({
  canEditPickupSheet,
  canPackPickupSheet,
  onPackPickupSheet,
  onProductDecreaseScanned,
  pickupSheet,
}) => {
  const { classes } = useStyles();
  const t = useTranslator();
  const filteredPickupSheetProducts = pickupSheet.pickupSheetProducts?.filter(pickupSheetProduct => pickupSheetProduct.markedQuantity !== pickupSheetProduct.packedQuantity);

  return (
    <Paper>
      <TableContainer>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.defaultFontSize}>{t.translate('Product photo')}</TableCell>
              <TableCell className={classes.defaultFontSize}>{t.translate('Supplier ID')}</TableCell>
              <TableCell className={classes.defaultFontSize}>{t.translate('Product name')}</TableCell>
              <TableCell className={classes.defaultFontSize}>{t.translate('Product EAN code')}</TableCell>
              <TableCell className={classes.defaultFontSize}>{t.translate('Quantity')}</TableCell>
              <TableCell className={classes.defaultFontSize}>{t.translate('Weight')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPickupSheetProducts.map((pickupSheetProduct, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Picture
                    height={54}
                    width={54}
                    image={defaultAdditionalImage(pickupSheetProduct.product.additionalImages) || pickupSheetProduct.product.photo || pickupSheetProduct.product.imageUrl}
                  />
                </TableCell>
                <TableCell className={classes.defaultFontSize}>{pickupSheetProduct.supplierId || '—'}</TableCell>
                <TableCell className={classes.defaultFontSize}>{pickupSheetProduct.product.details[0]?.name || '—'}</TableCell>
                <TableCell className={classes.defaultFontSize}>{pickupSheetProduct.product.ean || '—'}</TableCell>
                <TableCell className={classes.defaultFontSize}>
                  {canEditPickupSheet
                    && (pickupSheetProduct.markedQuantity - pickupSheetProduct.packedQuantity) > 0
                    ? (
                      <Tooltip title={t.translate('Remove scanned product from package.')}>
                        <Button
                          className={classes.removeButton}
                          endIcon={<RemoveCircleIcon />}
                          onClick={() => onProductDecreaseScanned(pickupSheetProduct.id)}
                        >
                          {pickupSheetProduct.markedQuantity - pickupSheetProduct.packedQuantity}
                        </Button>
                      </Tooltip>
                    ) : (
                      pickupSheetProduct.markedQuantity
                      && (pickupSheetProduct.packedQuantity || pickupSheetProduct.packedQuantity === 0)
                      && pickupSheetProduct.markedQuantity - pickupSheetProduct.packedQuantity
                    )}
                </TableCell>
                <TableCell className={classes.defaultFontSize}>
                  {`${numeral(pickupSheetProduct.weight * (pickupSheetProduct.markedQuantity - pickupSheetProduct.packedQuantity)).format('0.00') || 0} kg`}
                </TableCell>
                <TableCell />
              </TableRow>
            ))}
            {canPackPickupSheet && (
              <TableRow>
                <TableCell />
                <TableCell />
                {!filteredPickupSheetProducts.length
                  ? (
                    <TableCell className={classes.emptyScannedLabel}>{t.translate('Scan a product to add it to the package')}</TableCell>
                  ) : (
                    <TableCell />
                  )}
                <TableCell />
                <TableCell size="small" width="220px">
                  {!!filteredPickupSheetProducts.length && (
                    <Button
                      aria-label={t.translate(pickupSheet.pickupSheetPackages?.length ? 'Finish package' : 'Next package')}
                      className={classes.button}
                      onClick={onPackPickupSheet}
                    >
                      {t.translate(pickupSheet.pickupSheetPackages?.length ? 'Finish package' : 'Next package')}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

NextPackageTable.propTypes = {
  canEditPickupSheet: PropTypes.bool.isRequired,
  canPackPickupSheet: PropTypes.bool.isRequired,
  onPackPickupSheet: PropTypes.func.isRequired,
  onProductDecreaseScanned: PropTypes.func.isRequired,
  pickupSheet: PropTypes.object.isRequired,
};
