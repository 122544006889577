import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';
import { useStyles } from './SupplierItem.jsx';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';

import { BooleanIcon } from 'components';

export const SupplierItem = ({
  onSupplierOpen,
  canDeleteSupplier,
  onSupplierDelete,
  supplier,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <Grid item className={classes.item}>
      <Paper>
        <Grid item container direction="column" className={classes.headline}>
          <Typography variant="body2" className={classes.bold}>{supplier.name || '—'}</Typography>
          <Box>
            <Typography variant="body2" component="span" className={classes.bold}>{t.translate('Number')}:&nbsp;</Typography>
            <Typography variant="body2" component="span">{supplier.number || '—'}</Typography>
          </Box>
        </Grid>
        <Divider />
        <Grid item container direction="column" className={classes.paper}>
          <Grid justifyContent="space-between" item container>
            <Typography variant="body2" className={classes.bold}>{t.translate('Active')}</Typography>
            <BooleanIcon className={classes.active} truthy={supplier.active} isDefault />
          </Grid>
          <Grid justifyContent="space-between" item container>
            <Typography variant="body2" className={classes.bold}>{t.translate('Handling type')}</Typography>
            <Typography variant="body2">{t.translate(`supplierHandlingType:${supplier.handlingType}`)}</Typography>
          </Grid>
          <Grid justifyContent="space-between" item container>
            <Typography variant="body2" className={classes.bold}>{t.translate('Note')}</Typography>
            <Typography variant="body2" className={classes.noteBox}>{supplier.note || '—'}</Typography>
          </Grid>
          <Grid justifyContent="space-between" item container>
            <Typography variant="body2" className={classes.bold}>{t.translate('Created')}</Typography>
            <Typography variant="body2">{t.formatDate(supplier.createdAt)} {t.formatTime(supplier.createdAt)}</Typography>
          </Grid>
          <Grid item className={classes.buttons}>
            <Button
              aria-label={t.translate('Open supplier')}
              className={classes.button}
              startIcon={<InfoIcon />}
              onClick={() => onSupplierOpen(supplier)}
            >
              {t.translate('Details')}
            </Button>
            {canDeleteSupplier && (
              <Button
                aria-label={t.translate('Delete supplier')}
                className={classes.button}
                startIcon={<DeleteIcon />}
                onClick={() => onSupplierDelete(supplier)}
              >
                {t.translate('Delete')}
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

SupplierItem.propTypes = {
  onSupplierOpen: PropTypes.func.isRequired,
  onSupplierDelete: PropTypes.func.isRequired,
  canDeleteSupplier: PropTypes.bool.isRequired,
  supplier: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    note: PropTypes.string,
  }).isRequired,
};
