import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => {
  const cell = {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'start',
    border: 'none',
    flexShrink: 0,
    minWidth: 0,
    overflow: 'hidden',
    '&:first-of-type': {
      justifyContent: 'space-between',
      flexBasis: '5%',
    },
    '&:nth-of-type(2)': {
      flexBasis: '5%',
    },
    '&:nth-of-type(3)': {
      flexBasis: '15%',
    },
    '&:nth-of-type(4)': {
      flexBasis: '10%',
    },
    '&:nth-of-type(5)': {
      flexBasis: '10%',
    },
    '&:nth-of-type(6)': {
      flexBasis: '10%',
    },
    '&:nth-of-type(7)': {
      flexBasis: '15%',
    },
    '&:nth-of-type(8)': {
      flexBasis: '5%',
    },
    '&:nth-of-type(9)': {
      flexBasis: '5%',
    },
    '&:nth-of-type(10)': {
      flexBasis: '10%',
    },
    '&:nth-of-type(11)': {
      flexBasis: '5%',
    },
    '&:nth-of-type(12)': {
      flexBasis: '5%',
    },
  };

  return {
    root: {
      display: 'block',
      flex: 1,
      padding: theme.spacing(3),
    },
    table: {
      height: '100%',
      width: '100%',
    },
    cell: {
      ...cell,
    },
    cellHeader: {
      ...cell,
    },
    numberRow: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      backgroundColor: theme.palette.backgrounds.grey[240],
    },
    supplierHeader: {
      width: '100%',
      fontWeight: 500,
      padding: '20px 0',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      boxSizing: 'border-box',
      minWidth: '100%',
      width: '100%',
      boxShadow: '0 -1px 0 rgb(224, 224, 224)',
    },
    qrButton: {
      marginLeft: theme.spacing(1),
      '& .MuiButton-endIcon': {
        marginLeft: 0,
        marginRight: 0,
      },
      width: '100%',
    },
    container: {
      marginBottom: theme.spacing(3),
    },
    paper: {
      [theme.breakpoints.down('sm')]: {
        borderRadius: 0,
        margin: 0,
        maxHeight: 'none',
        maxWidth: 'none',
        height: '100%',
        width: '100%',
      },
    },
    filtersContainer: {
      margin: theme.spacing(1, 0, 1),
    },
    handlingTypeFiltersContainer: {
      margin: theme.spacing(1, 0, 3),
    },
    resetFiltersButton: {
      width: '100%',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  };
});
