import React from 'react';

import { Purchases, ReplenishmentSuggestions } from './subPages';

export const REPLENISHMENT_SUGGESTIONS_LABEL = 'Replenishment Suggestions';

export const routes = [{
  homePath: '/purchases',
  subPaths: [],
  Page: () => <Purchases/>,
  name: 'purchases',
  label: 'Purchases',
}, {
  homePath: '/replenishment-suggestions',
  subPaths: [],
  Page: () => <ReplenishmentSuggestions/>,
  name: 'purchases',
  subView: 'viewSuggestions',
  label: REPLENISHMENT_SUGGESTIONS_LABEL,
}];

export const tabsOptions = [
  {
    label: REPLENISHMENT_SUGGESTIONS_LABEL,
    name: '/replenishment-suggestions',
    view: 'purchases',
    subView: 'viewSuggestions',
  },
  {
    label: 'Purchases',
    name: '/purchases',
    view: 'purchases',
  },
];
