import { makeStyles } from 'tss-react/mui';
import { styleDefaultFormDialog } from 'theme/mixins/dialog';

export const useStyles = makeStyles()(theme => styleDefaultFormDialog(theme, {
  root: {
    maxWidth: 'none',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      margin: 0,
      maxHeight: 'none',
      maxWidth: 'none',
      height: '100%',
      width: '100%',
    },
    [theme.breakpoints.between('sm', 'xl')]: {
      width: '70vw',
    },
    width: '50vw',
  },
  table: {
    borderCollapse: 'separate',
    '& .MuiDialog-paper': {
      maxWidth: theme.spacing(86),
    },
    '& th': {
      borderBottom: 'none',
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiDialogContent-root': {
        padding: theme.spacing(1),
      },
    },
  },
  returnRow: {
    borderRadius: theme.spacing(.5),
    '& td': {
      paddingRight: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.grey['250']}`,
      '&:first-of-type': {
        borderLeft: `1px solid ${theme.palette.grey['250']}`,
        borderTopLeftRadius: theme.spacing(.5),
        borderBottomLeftRadius: theme.spacing(.5),
      },
      '&:last-child': {
        borderRight: `1px solid ${theme.palette.grey['250']}`,
        borderTopRightRadius: theme.spacing(.5),
        borderBottomRightRadius: theme.spacing(.5),
      },
    },
  },
  emptyRow: {
    height: theme.spacing(1),
  },
  remainingContainer: {
    padding: '20px',
  },
  containerDetails: {
    '& > div': {
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        '& .MuiGrid-grid-md-4:not(:last-child) ': {
          marginRight: theme.spacing(1.5),
        },
        '& .MuiGrid-grid-md-4:not(:first-of-type) ': {
          marginLeft: theme.spacing(1.5),
        },
        '& .MuiGrid-grid-md-8': {
          marginLeft: theme.spacing(1.5),
        },
      },
    },
  },
}));
