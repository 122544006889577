import { makeStyles } from 'tss-react/mui';
import { styleMobileTableItem } from 'theme/mixins/table';

export const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  root: {
    padding: theme.spacing(2),
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
    '& .MuiAccordionSummary-root, & .MuiAccordionDetails-root': {
      padding: 0,
    },
    '& .MuiGrid-justify-xs-space-between p:first-of-type, & .MuiAccordionSummary-root p:first-of-type': {
      fontWeight: 700,
    },
  },
  button: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.navigation.main,
  },
}));
