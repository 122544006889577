import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import { CircularLoader } from 'components';
import { RetailerCard } from '../common';
import { Column } from 'layouts';

export const DesktopView = ({
  isLoading,
  retailer,
  ...props
}) => {
  if (isLoading || !retailer) {
    return <CircularLoader />;
  } else {
    return (
      <Column p={3}>
        <Grid container direction="column">
          <RetailerCard retailer={retailer} {...props}/>
        </Grid>
      </Column>
    );
  }
};

DesktopView.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  retailer: PropTypes.object,
};
