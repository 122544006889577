import React, { useEffect, useState } from 'react';
import groupBy from 'lodash/groupBy';

import {
  Paper,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Table,
  IconButton,
  Popover,
  Typography,
} from '@mui/material';
import { useTranslator } from 'i18n';
import { ActionButton } from 'components';
import Assignment from '@mui/icons-material/Assignment';
import BlockIcon from '@mui/icons-material/Block';
import { makeStyles } from 'tss-react/mui';
import { styleTableRow } from 'theme/mixins/table';
import InfoIcon from '@mui/icons-material/Info';
import { fetchEvaluateStock } from 'api/storage-products';

const useStyles = makeStyles()(theme => styleTableRow(theme, {
  button: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  hint: {
    maxWidth: 320,
  },
}));

export const OrderReturnsTable = ({ order, onStockEvaluation, onMarkReturnWithoutStock }) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState({});
  const [evaluateStockPositions, setEvaluateStockPositions] = useState([]);
  const [groupReturnPositions, setGroupReturnPositions] = useState({});

  const setEvaluateStock = async (orderPositionReturnId) => {
    if (!evaluateStockPositions.find(pos => pos.id === orderPositionReturnId)) {
      const evaluateStock = await fetchEvaluateStock(orderPositionReturnId);
      setEvaluateStockPositions([...evaluateStockPositions, {
        id: orderPositionReturnId,
        positions: evaluateStock,
      }]);
    }
  };

  const getEvaluateStock = (orderPositionReturnId) => {
    const result = evaluateStockPositions.find(pos => pos.id === orderPositionReturnId);
    return (result && result.positions.length) ? result.positions : null;
  };

  const getStorageName = (storage) => {
    return [storage.id + ' —'].concat([
      storage.building,
      storage.floor,
      storage.room,
      storage.row,
      storage.shelf,
      storage.level,
    ].filter(Boolean)).join(' ');
  };

  const handlePopoverOpen = (name, event) => {
    event.persist();
    setAnchorEl(prevState => ({
      ...prevState,
      [name]: event.currentTarget,
    }));
  };

  const handlePopoverClose = (name) => {
    setAnchorEl(prevState => ({
      ...prevState,
      [name]: null,
    }));
  };

  useEffect(() => {
    if (order) {
      const returnPositions = [];
      order.positions.forEach(pos => {
        pos.orderPositionReturns.forEach(returnPosition => {
          if (returnPosition.orderReturn.isWithoutStock) return;

          returnPositions.push({
            ...returnPosition,
            product: pos.product,
          });
        });
      });

      setGroupReturnPositions(groupBy(returnPositions, 'createdAt'));
    }
  }, [order, setGroupReturnPositions]);

  return (
    <Paper style={{ width: '100%', margin: 10 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t.translate('EAN')}</TableCell>
              <TableCell>{t.translate('Product name')}</TableCell>
              <TableCell>{t.translate('Quantity')}</TableCell>
              <TableCell>{t.translate('Evaluated')}</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(groupReturnPositions).map((key, index) => (
              <React.Fragment key={`${key} - ${index}`}>
                <TableRow>
                  <TableCell
                    colSpan={5}
                    align="center"
                    style={{ backgroundColor: '#f5f5f5', padding: '5px 0' }}
                  >
                    {t.formatDate(key)} {t.formatTime(key)}
                  </TableCell>
                </TableRow>
                {groupReturnPositions[key].map(position => (
                  <TableRow key={`${key} - ${position.id}`}>
                    <TableCell>{position.product.ean}</TableCell>
                    <TableCell
                      title={position.product.details[0]?.name || '—'}
                      style={{ maxWidth: 200, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      {position.product.details[0]?.name || '—'}
                    </TableCell>
                    <TableCell>{`${position.returnedQuantity}/${position.quantity}`}</TableCell>
                    <TableCell>{position.evaluatedQuantity}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label="hint"
                        aria-owns={Boolean(anchorEl[`pos${position.id}`]) ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={e => {
                          setEvaluateStock(position.id);
                          handlePopoverOpen(`pos${position.id}`, e);
                        }}
                        onMouseLeave={() => handlePopoverClose(`pos${position.id}`) }
                        onClick={e => {
                          setEvaluateStock(position.id);
                          handlePopoverOpen(`pos${position.id}`, e);
                        }}
                        size="large">
                        <InfoIcon />
                      </IconButton>
                      <Popover
                        id="mouse-over-popover"
                        className={classes.popover}
                        classes={{ paper: classes.paper }}
                        open={Boolean(anchorEl[`pos${position.id}`])}
                        anchorEl={anchorEl[`pos${position.id}`]}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        onClose={() => handlePopoverClose(`pos${position.id}`)}
                        disableRestoreFocus
                      >
                        {
                          getEvaluateStock(position.id) ?
                            getEvaluateStock(position.id).map((pos, index) => (
                              <Typography variant="body2" className={classes.hint} key={`${index} - ${pos.id}`}>
                                {`${getStorageName(pos.storage)} Quantity: ${pos.totalReturnedStock}`}
                              </Typography>
                            )) :
                            <Typography variant="body2" className={classes.hint}>
                              {t.translate('No stock')}
                            </Typography>
                        }
                      </Popover>
                      <ActionButton
                        className={classes.button}
                        tooltipPlacement="bottom"
                        aria-label={t.translate('Evaluate')}
                        tooltip={t.translate('Evaluate')}
                        icon={<Assignment/>}
                        disabled={position.evaluatedQuantity === position.returnedQuantity}
                        onClick={() => onStockEvaluation(position)}
                      />
                      <ActionButton
                        className={classes.button}
                        tooltipPlacement="bottom"
                        aria-label={t.translate('Mark the return as a return without stock')}
                        tooltip={t.translate('Mark the return as a return without stock')}
                        icon={<BlockIcon/>}
                        disabled={position.orderReturn.isWithoutStock || position.returnedQuantity !== 0}
                        onClick={() => onMarkReturnWithoutStock({ orderReturnId: position.orderReturnId })}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

