import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useTranslator } from 'i18n';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import Divider from '@mui/material/Divider';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import { useStyles } from './ProductItem.jsx';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

export const ProductItem = ({ product }) => {
  const history = useHistory();
  const { classes } = useStyles();
  const t = useTranslator();

  const openProduct = product => history.push(`/products/${product.id}`);

  return (
    <Paper className={classes.root}>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item className={classes.contentItem}>
            <Typography variant="body2" className={classes.bold}>{product.details[0]?.name || '—'}</Typography>
            <Grid item container direction="row" >
              <Typography variant="body2" className={classes.bold}>EAN:&nbsp;</Typography>
              <Typography variant="body2" >{product.ean || '—'}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item className={classes.contentItem}>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Product-ID')}</Typography>
              <Typography variant="body2">{product.id}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>ALZURA-ID</Typography>
              <Typography variant="body2">{product.alzuraId || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Product type')}</Typography>
              <Typography variant="body2">{product.type?.code || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Manufacturer no.')}</Typography>
              <Typography variant="body2">{product.manufacturerNumber || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Manufacturer')}</Typography>
              <Typography variant="body2">{product.manufacturerName || '—'}</Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider className={classes.divider}/>
      </Accordion>
      <Grid container direction="column" className={classes.paper}>
        <Grid item className={classes.contentItem}>
          <Grid container justifyContent="space-between">
            <Typography variant="body2" className={classes.bold}>{t.translate('Total stock')}</Typography>
            <Typography variant="body2">{product.stock}</Typography>
          </Grid>
        </Grid>
        <Grid item container className={classes.buttons} justifyContent="space-between" >
          <Button
            aria-label={t.translate('Open product')}
            className={classes.button}
            startIcon={<InfoIcon />}
            onClick={() => openProduct(product)}
          >
            {t.translate('Details')}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

ProductItem.propTypes = {
  product: PropTypes.shape({
    supplierItemNumber: PropTypes.string,
    manufacturerNumber: PropTypes.string,
    manufacturerName: PropTypes.string,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    fromApi: PropTypes.bool.isRequired,
    photo: PropTypes.object,
    type: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    name: PropTypes.string,
    ean: PropTypes.string,
    alzuraId: PropTypes.string,
    id: PropTypes.number.isRequired,
  }),
};

export default ProductItem;
