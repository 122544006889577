import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useList } from 'hooks/list';
import { useTranslator } from 'i18n';
import { useTableSortableHeadCell } from 'hooks/table';

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ActionButton, Pagination } from 'components';
import { wrapTableRowClick } from 'utils/html';
import tables from 'config/tables';

const useStyles = makeStyles()(theme => ({
  cellPadding: {
    '& .MuiTableRow-root .MuiTableCell-root:first-of-type': {
      paddingLeft: theme.spacing(2),
    },
    '& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:last-child': {
      paddingRight: theme.spacing(2),
    },
  },
  priceCell: {
    whiteSpace: 'nowrap',
  },
}));

export const CustomersTable = ({ customers, meta }) => {
  const t = useTranslator();
  const history = useHistory();
  const list = useList(tables.customers.customers);
  const { classes } = useStyles();

  const SortableCell = useTableSortableHeadCell({
    changeSorting: list.changeSorting,
    sorting: list.sorting,
  });

  const openCustomer = customer => history.push(`/customers/${customer.id}`);

  return (
    <Paper>
      <TableContainer>
        <Table stickyHeader className={classes.cellPadding}>
          <TableHead>
            <TableRow>
              <SortableCell name="number">{t.translate('Number')}</SortableCell>
              <SortableCell name="name">{t.translate('Name')}</SortableCell>
              <SortableCell name="company">{t.translate('Company')}</SortableCell>
              <SortableCell name="lastOrderDate">{t.translate('Last order')}</SortableCell>
              <SortableCell align="right" name="ordersCount">{t.translate('Orders count')}</SortableCell>
              <SortableCell align="right" name="ordersTotalValueNet">{t.translate('Net total')}</SortableCell>
              <SortableCell align="right" name="ordersTotalValueGross">{t.translate('Gross total')}</SortableCell>
              <TableCell align="right">{t.translate('Actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer, index) => (
              <TableRow key={index} hover onClick={wrapTableRowClick(() => openCustomer(customer))}>
                <TableCell>{customer.number}</TableCell>
                <TableCell>{customer.name}</TableCell>
                <TableCell>{customer.company || '—'}</TableCell>
                <TableCell>{t.formatDate(customer.lastOrderDate)} {t.formatTime(customer.lastOrderDate)}</TableCell>
                <TableCell align="right">{customer.ordersCount ?? '—'}</TableCell>
                <TableCell align="right" className={classes.priceCell}>{t.formatPrice(Number(customer.ordersTotalValueNet))} &euro;</TableCell>
                <TableCell align="right" className={classes.priceCell}>{t.formatPrice(Number(customer.ordersTotalValueGross))} &euro;</TableCell>
                <TableCell align="right">
                  <ActionButton
                    aria-label={t.translate('Open customer')}
                    tooltip={t.translate('verb:Open')}
                    icon={<ChevronRightIcon />}
                    onClick={() => openCustomer(customer)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination {...list.paginationProps} count={meta.totalEntities} />
    </Paper>
  );
};

CustomersTable.propTypes = {
  meta: PropTypes.shape({
    totalEntities: PropTypes.number.isRequired,
  }).isRequired,
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      company: PropTypes.string,
      email: PropTypes.string,
      lastOrderDate: PropTypes.string,
      ordersCount: PropTypes.number,
      ordersTotalValueNet: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      ordersTotalValueGross: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
};
