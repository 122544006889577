import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';
import {
  didUpdate,
  useForm,
} from 'hooks';

import { makeStyles } from 'tss-react/mui';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';

import {
  FullScreenDialogHeader,
  FullScreenDialog,
  InputControl,
  BusyButton,
} from 'components';

import { createWarehouse, modifyWarehouse } from 'api/warehouses';
import { styleDefaultFormDialog } from 'theme/mixins/dialog';
import toaster from 'services/toaster';

const useStyles = makeStyles()(styleDefaultFormDialog);
const makeId = postfix => `warehouse-dialog--${postfix}`;

export const WarehouseDialog = ({
  onClose,
  onSave,
  warehouse,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const title = warehouse
    ? 'Edit warehouse'
    : 'Create warehouse';

  const initialModel = warehouse ? {
    country: warehouse.address.country,
    street: warehouse.address.street,
    house: warehouse.address.house,
    city: warehouse.address.city,
    zip: warehouse.address.zip,
    name: warehouse.address.name || '',
    warehouseName: warehouse.name,
  } : {
    country: '',
    street: '',
    house: '',
    city: '',
    zip: '',
    name: '',
    warehouseName: '',
  };

  const {
    catchHttpError,
    isModelValid,
    setFormValue,
    resetForm,
    errors,
    model,
  } = useForm(initialModel);

  didUpdate(() => void (props.open && resetForm()), [props.open]);

  const validateModel = errors => {
    if (!model.warehouseName.trim().length) {
      errors.warehouseName = t.translate('Please specify a warehouse name.');
    }
    if (!model.country.trim().length) {
      errors.country = t.translate('Please specify a country.');
    }
    if (!model.city.trim().length) {
      errors.city = t.translate('Please specify a city.');
    }
    if (!model.street.trim().length) {
      errors.street = t.translate('Please specify a street.');
    }
    if (!model.house.trim().length) {
      errors.house = t.translate('Please specify a house number.');
    }
    if (!model.zip.trim().length) {
      errors.zip = t.translate('Please specify a zip code.');
    }
    if (!model.name.trim().length) {
      errors.name = t.translate('Please specify an address name.');
    }
  };

  const submit = async () => {
    try {
      if (!isModelValid(validateModel)) {
        return;
      }

      if (warehouse) {
        await modifyWarehouse(warehouse.id, customizePayload(model));
        toaster.success(t.translate('Warehouse has been saved successfully.'));
      } else {
        await createWarehouse(customizePayload(model));
        toaster.success(t.translate('New warehouse has been created successfully.'));
      }

      onSave();
      onClose();
    } catch (err) {
      catchHttpError(err);
    }
  };

  const customizePayload = (model) => ({
    warehouseName: model.warehouseName,
    address: {
      country: model.country,
      city: model.city,
      street: model.street,
      house: model.house,
      zip: model.zip,
      name: model.name,
    },
  });

  return (
    <FullScreenDialog {...props} form onClose={onClose}>
      <FullScreenDialogHeader title={t.translate(title)} onClose={onClose}>
        <BusyButton color="inherit" submit onClick={submit}>{t.translate('Save')}</BusyButton>
      </FullScreenDialogHeader>
      <DialogContent className={classes.content}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <InputControl
              id={makeId('warehouseName')}
              error={errors.warehouseName}
              label={t.translate('Warehouse name')}
              value={model.warehouseName}
              onChange={setFormValue('warehouseName')}
            />
          </Grid>
          <Grid item>
            <InputControl
              id={makeId('country')}
              error={errors.country}
              label={t.translate('Country')}
              value={model.country}
              onChange={setFormValue('country')}
            />
          </Grid>
          <Grid item>
            <InputControl
              id={makeId('city')}
              error={errors.city}
              label={t.translate('City')}
              value={model.city}
              onChange={setFormValue('city')}
            />
          </Grid>
          <Grid item>
            <InputControl
              id={makeId('street')}
              error={errors.street}
              label={t.translate('Street')}
              value={model.street}
              onChange={setFormValue('street')}
            />
          </Grid>
          <Grid item>
            <InputControl
              id={makeId('house')}
              error={errors.house}
              label={t.translate('House number')}
              value={model.house}
              onChange={setFormValue('house')}
            />
          </Grid>
          <Grid item>
            <InputControl
              id={makeId('zip')}
              error={errors.zip}
              label={t.translate('Zip code')}
              value={model.zip}
              onChange={setFormValue('zip')}
            />
          </Grid>
          <Grid item>
            <InputControl
              id={makeId('name')}
              error={errors.name}
              label={t.translate('Name')}
              value={model.name}
              onChange={setFormValue('name')}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </FullScreenDialog>
  );
};

WarehouseDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  warehouse: PropTypes.shape({
    address: PropTypes.shape({
      country: PropTypes.string.isRequired,
      street: PropTypes.string.isRequired,
      house: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
  }),
};
