import React, { useEffect, useMemo, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslator } from '../../i18n';
import { DialogContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { BusyButton, CheckboxControl, DayPicker, InputControl, MonthPicker, PriceControl } from '../../components';
import { useFormik } from 'formik';
import toaster from '../../services/toaster';
import * as Yup from 'yup';
import { makeStyles } from 'tss-react/mui';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import { CostpriceProductPriceTypes } from '../../consts';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { modifySupplier } from '../../api/suppliers';
import PropTypes from 'prop-types';
import numeral from 'numeral';

const makeId = postfix => `seasonal-costs-dialog--${postfix}`;

const useStyles = makeStyles()(theme =>  ({
  priceSection: {
    marginBottom: theme.spacing(1),
  },
  containerLabel: {
    fontWeight: 500,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  actionsGrid: {
    marginBottom: theme.spacing(3),
  },
  checkboxContainer: {
    '& .MuiFormControlLabel-root': {
      display: 'block',
      marginLeft: 0,
      marginRight: 0,
    },
    '& .MuiFormControl-root': {
      marginTop: 0,
    },
  },
  noMargin: {
    marginTop: '0 !important',
  },
  cellVerticalAlign: {
    verticalAlign: 'top',
  },
  dialogActions: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
}));

export const CostpriceDetailsDialog = ({
  onClose,
  onSave,
  supplier,
  activeMarketplaces,
  isMobileView,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const [initialValues, setInitialValues] = useState({
    summerStartDate: '03-01'.split('-'),
    summerEndDate: '05-31'.split('-'),
    winterStartDate: '10-01'.split('-'),
    winterEndDate: '12-31'.split('-'),
    activePricesCalculations: activeMarketplaces?.map(marketplace => ({
      marketplaceCountryId: marketplace.id,
      countryCode: marketplace.country.countryCode,
      costpriceProductPriceType: 'netPrice',
      billFeesToPartner: false,
      invoicingFee: t.formatDecimalToPercentage(0),
      marketplaceFee: t.formatDecimalToPercentage(0),
      premiumFee: t.formatDecimalToPercentage(0),
      priceCalculationFee: t.formatDecimalToPercentage(0),
      baseShippingCosts: t.formatPrice(0),
      offSeason: t.formatPrice(0),
      summerSeason: t.formatPrice(0),
      winterSeason: t.formatPrice(0),
    })) ?? [],
  });

  const costpriceProductPriceTypeOptions = useMemo(
    () => new Map(['generalPrice', 'netPrice'].map(key => [key, t.translate(CostpriceProductPriceTypes[key])])),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t.intl.locale]
  );

  const onSupplierSave = () => {
    toaster.success(t.translate('Supplier has been saved successfully.'));
  };

  const onSubmit = async () => {
    try {
      const payload = {
        ...supplier,
        ...supplier.address,
        summerStartDate: values.summerStartDate.join('-'),
        summerEndDate: values.summerEndDate.join('-'),
        winterStartDate: values.winterStartDate.join('-'),
        winterEndDate: values.winterEndDate.join('-'),
        pricesCalculations: values.activePricesCalculations.map(value => ({
          ...value,
          invoicingFee: t.parsePercentage(value.invoicingFee, 4),
          marketplaceFee: t.parsePercentage(value.marketplaceFee, 4),
          premiumFee: t.parsePercentage(value.premiumFee, 4),
          priceCalculationFee: t.parsePercentage(value.priceCalculationFee, 4),
          baseShippingCosts: numeral(value.baseShippingCosts).value(),
          offSeason: numeral(value.offSeason).value(),
          summerSeason: numeral(value.summerSeason).value(),
          winterSeason: numeral(value.winterSeason).value(),
        })),
      };

      if (supplier) {
        await modifySupplier(supplier.id, payload);
        onSupplierSave();
      }
      onSave();
      onClose();
    } catch (err) {
      toaster.error(Object.values(err.collection)[0][0]);
    }
  };

  const validationSchema = Yup
    .object()
    .shape({
      activePricesCalculations: Yup.array().of(
        Yup.object().shape({
          billFeesToPartner: Yup.boolean(),
          invoicingFee: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
          marketplaceFee: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
          premiumFee: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
          priceCalculationFee: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
          baseShippingCosts: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
          offSeason: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
          summerSeason: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
          winterSeason: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
        })
      ),
    });

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    enableReinitialize: true,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    if (supplier){
      const activePricesCalculations = supplier.pricesCalculations
        .filter(item => item.marketplaceCountry.isActive)
        .map((item) => ({
          ...item,
          countryCode: item.marketplaceCountry.countryCode,
          marketplaceCountryId: item.marketplaceCountryId,
          costpriceProductPriceType: item.costpriceProductPriceType,
          billFeesToPartner: item.billFeesToPartner,
          invoicingFee: t.formatDecimalToPercentage(Number(item.invoicingFee), 4),
          marketplaceFee: t.formatDecimalToPercentage(Number(item.marketplaceFee), 4),
          premiumFee: t.formatDecimalToPercentage(Number(item.premiumFee), 4),
          priceCalculationFee: t.formatDecimalToPercentage(Number(item.priceCalculationFee), 4),
          baseShippingCosts: t.formatPrice(Number(item.baseShippingCosts)),
          offSeason: t.formatPrice(Number(item.offSeason)),
          summerSeason: t.formatPrice(Number(item.summerSeason)),
          winterSeason: t.formatPrice(Number(item.winterSeason)),
        }));
      setInitialValues({
        summerStartDate: supplier.summerStartDate?.split('-') || ['03', '01'],
        summerEndDate: supplier.summerEndDate?.split('-') || ['05', '31'],
        winterStartDate: supplier.winterStartDate?.split('-') || ['10', '01'],
        winterEndDate: supplier.winterEndDate?.split('-') || ['12', '31'],
        activePricesCalculations,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier]);

  return (
    <Dialog
      {...props}
      scroll="body"
      fullWidth
      maxWidth="xl"
      fullScreen={isMobileView}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">{t.translate('Costprice Details')}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body1" className={classes.containerLabel}>{t.translate('Summer season')}</Typography>
            <Grid container spacing={4} className={classes.actionsGrid}>
              <Grid item xs={4}>
                <DayPicker
                  id={makeId('summer-start-date-day')}
                  name="summerStartDate"
                  value={values.summerStartDate[1]}
                  onChange={(e) => setFieldValue('summerStartDate', [values.summerStartDate[0], e.target.value])}
                  month={values.summerStartDate[0]}
                  label={t.translate('Start date day')}
                  error={errors.summerStartDate}
                />
              </Grid>
              <Grid item xs={7}>
                <MonthPicker
                  id={makeId('summer-start-date-month')}
                  name="summerStartDate"
                  value={values.summerStartDate[0]}
                  onChange={(e) => setFieldValue('summerStartDate', [e.target.value, values.summerStartDate[1]])}
                  label={t.translate('Start date month')}
                  error={errors.summerStartDate}
                />
              </Grid>
            </Grid>
            <Grid container spacing={4} className={classes.actionsGrid}>
              <Grid item xs={4}>
                <DayPicker
                  id={makeId('summer-end-date-day')}
                  name="summerEndDate"
                  value={values.summerEndDate[1]}
                  month={values.summerEndDate[0]}
                  onChange={(e) => setFieldValue('summerEndDate', [values.summerEndDate[0], e.target.value])}
                  label={t.translate('End date day')}
                  error={errors.summerEndDate}
                />
              </Grid>
              <Grid item xs={7}>
                <MonthPicker
                  id={makeId('summer-end-date-month')}
                  name="summerEndDate"
                  value={values.summerEndDate[0]}
                  onChange={(e) => setFieldValue('summerEndDate', [e.target.value, values.summerEndDate[1]])}
                  label={t.translate('End date month')}
                  error={errors.summerEndDate}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" className={classes.containerLabel}>{t.translate('Winter season')}</Typography>
            <Grid container spacing={4} className={classes.actionsGrid}>
              <Grid item xs={4}>
                <DayPicker
                  id={makeId('winter-start-date-day')}
                  name="winterStartDate"
                  value={values.winterStartDate[1]}
                  month={values.winterStartDate[0]}
                  onChange={(e) => setFieldValue('winterStartDate', [values.winterStartDate[0], e.target.value])}
                  label={t.translate('Start date day')}
                  error={errors.winterStartDate}
                />
              </Grid>
              <Grid item xs={8}>
                <MonthPicker
                  id={makeId('winter-start-date-month')}
                  name="winterStartDate"
                  value={values.winterStartDate[0]}
                  onChange={(e) => setFieldValue('winterStartDate', [e.target.value, values.winterStartDate[1]])}
                  label={t.translate('Start date month')}
                  error={errors.winterStartDate}
                />
              </Grid>
            </Grid>
            <Grid container spacing={4} className={classes.actionsGrid}>
              <Grid item xs={4}>
                <DayPicker
                  id={makeId('winter-end-date-day')}
                  name="winterEndDate"
                  value={values.winterEndDate[1]}
                  month={values.winterEndDate[0]}
                  onChange={(e) => setFieldValue('winterEndDate', [values.winterEndDate[0], e.target.value])}
                  label={t.translate('End date day')}
                  error={errors.winterEndDate}
                />
              </Grid>
              <Grid item xs={8}>
                <MonthPicker
                  id={makeId('winter-end-date-month')}
                  name="winterEndDate"
                  value={values.winterEndDate[0]}
                  onChange={(e) => setFieldValue('winterEndDate', [e.target.value, values.winterEndDate[1]])}
                  label={t.translate('End date month')}
                  error={errors.winterEndDate}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="column">
          <Typography variant="body1" className={classes.containerLabel}>{t.translate('Fees & seasonal costs')}</Typography>
          <TableContainer className={classes.tableContainer} >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Country')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Bill fees to partner')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center" style={{ width: 150 }}>{t.translate('Calculate fees based on')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Invoicing & support fee (%)')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Marketplace fee (%)')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Premium fee (%)')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Price calculation fee (%)')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Base shipping costs')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Off season')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Summer season')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Winter season')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  values.activePricesCalculations.map((position, index) => (
                    <TableRow key={`position-${index}`}>
                      <TableCell align="center">
                        {t.translate(`country:${position.countryCode}`)}
                      </TableCell>
                      <TableCell className={classes.checkboxContainer} align="center">
                        <CheckboxControl
                          className={classes.checkboxContainer}
                          name={`activePricesCalculations[${index}].billFeesToPartner`}
                          checked={!!position.billFeesToPartner}
                          onChange={(...args) => {
                            handleChange(...args);
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormControl
                          fullWidth
                          disabled={!position.billFeesToPartner}
                        >
                          <Select
                            id="calculate-fees"
                            name={`activePricesCalculations[${index}].costpriceProductPriceType`}
                            value={position.costpriceProductPriceType}
                            displayEmpty
                            onChange={handleChange}
                          >
                            {Array.from(costpriceProductPriceTypeOptions).map(([value, text]) => (
                              <MenuItem key={value} value={value}>
                                {t.translate(text)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center">
                        <InputControl
                          inputProps={{ style: { textAlign: 'right' } }}
                          className={classes.noMargin}
                          id={makeId('invoicing-fee')}
                          name={`activePricesCalculations[${index}].invoicingFee`}
                          error={!!errors.activePricesCalculations && !!errors.activePricesCalculations[index]?.invoicingFee}
                          value={position.invoicingFee}
                          onChange={handleChange}
                          disabled={!position.billFeesToPartner}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <InputControl
                          inputProps={{ style: { textAlign: 'right' } }}
                          className={classes.noMargin}
                          id={makeId('marketplace-fee')}
                          name={`activePricesCalculations[${index}].marketplaceFee`}
                          error={!!errors.activePricesCalculations && !!errors.activePricesCalculations[index]?.marketplaceFee}
                          value={position.marketplaceFee}
                          onChange={handleChange}
                          disabled={!position.billFeesToPartner}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <InputControl
                          inputProps={{ style: { textAlign: 'right' } }}
                          className={classes.noMargin}
                          id={makeId('premium-fee')}
                          name={`activePricesCalculations[${index}].premiumFee`}
                          error={!!errors.activePricesCalculations && !!errors.activePricesCalculations[index]?.premiumFee}
                          value={position.premiumFee}
                          onChange={handleChange}
                          disabled={!position.billFeesToPartner}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <InputControl
                          inputProps={{ style: { textAlign: 'right' } }}
                          className={classes.noMargin}
                          id={makeId('price-calculation-fee')}
                          name={`activePricesCalculations[${index}].priceCalculationFee`}
                          error={!!errors.activePricesCalculations && !!errors.activePricesCalculations[index]?.priceCalculationFee}
                          value={position.priceCalculationFee}
                          onChange={handleChange}
                          disabled={!position.billFeesToPartner}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <PriceControl
                          className={classes.noMargin}
                          id={makeId('base-shipping-costs')}
                          name={`activePricesCalculations[${index}].baseShippingCosts`}
                          error={!!errors.activePricesCalculations && !!errors.activePricesCalculations[index]?.baseShippingCosts}
                          value={position.baseShippingCosts}
                          onChange={handleChange}
                          disabled={!position.billFeesToPartner}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <PriceControl
                          className={classes.noMargin}
                          id={makeId('off-season')}
                          name={`activePricesCalculations[${index}].offSeason`}
                          error={!!errors.activePricesCalculations && !!errors.activePricesCalculations[index]?.offSeason}
                          value={position.offSeason}
                          onChange={handleChange}
                          disabled={!position.billFeesToPartner}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <PriceControl
                          className={classes.noMargin}
                          id={makeId('summer-season')}
                          name={`activePricesCalculations[${index}].summerSeason`}
                          error={!!errors.activePricesCalculations && !!errors.activePricesCalculations[index]?.summerSeason}
                          value={position.summerSeason}
                          onChange={handleChange}
                          disabled={!position.billFeesToPartner}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <PriceControl
                          className={classes.noMargin}
                          id={makeId('winter-season')}
                          name={`activePricesCalculations[${index}].winterSeason`}
                          error={!!errors.activePricesCalculations && !!errors.activePricesCalculations[index]?.winterSeason}
                          value={position.winterSeason}
                          onChange={handleChange}
                          disabled={!position.billFeesToPartner}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
        <BusyButton color="primary" submit onClick={handleSubmit}>{t.translate('Save')}</BusyButton>
      </DialogActions>
    </Dialog>
  );
};

CostpriceDetailsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  supplier: PropTypes.shape({
    address: PropTypes.shape({
      country: PropTypes.string.isRequired,
      street: PropTypes.string.isRequired,
      house: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
    }).isRequired,
    summerStartDate: PropTypes.string,
    summerEndDate: PropTypes.string,
    winterStartDate: PropTypes.string,
    winterEndDate: PropTypes.string,
    pricesCalculations: PropTypes.array,
  }),
};
