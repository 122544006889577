import { makeStyles } from 'tss-react/mui';
import { styleMobileTableItem } from 'theme/mixins/table';

export const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  accordion: {
    backgroundColor: theme.palette.backgrounds.secondary,
    boxShadow: 'none',
    '& .MuiCollapse-root': {
      background: theme.palette.common.white,
      paddingTop: theme.spacing(1),
    },
  },
  bold: {
    fontWeight: 500,
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
  },
  button: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.l10,
    fontSize: '0.75rem',
    textTransform: 'none',
  },
  actionButton: {
    color: theme.palette.primary.l900,
  },
  contentItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  divider: {
    margin: theme.spacing(0, 2),
  },
}));
