import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { TrackingLink } from 'components';

export const OrderDetails = ({ order, ...props }) => {
  const t = useTranslator();

  return (
    <Grid {...props} container direction="column">
      <Grid item container>
        <Grid item xs={6}>
          <Typography>{t.translate('Status')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{t.translate('Date')}</Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={6}>
          <Typography>{t.translate(`status:${order.status}`)}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{t.formatDate(order.date)} {t.formatTime(order.date)}</Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={6}>
          <Typography>{t.translate('Net sum')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{t.translate('Gross sum')}</Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={6}>
          <Typography>{t.formatPrice(Number(order.netSum))} &euro;</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{t.formatPrice(Number(order.grossSum))} &euro;</Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12}>
          <Typography>{t.translate('Delivery address')}</Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12}>
          <Typography>
            {order.deliveryAddress?.country},&nbsp;
            {order.deliveryAddress?.city},&nbsp;
            {order.deliveryAddress?.street},&nbsp;
            {order.deliveryAddress?.house && `${order.deliveryAddress.house}, `}
            {order.deliveryAddress?.name && `${order.deliveryAddress.name}, `}
            {order.deliveryAddress?.zip}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12}>
          <Typography>{t.translate('Tracking')}</Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12}>
          <TrackingLink tracking={order.tracking} />
        </Grid>
      </Grid>
    </Grid>
  );
};

OrderDetails.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    netSum: PropTypes.string,
    grossSum: PropTypes.string,
    deliveryAddress: PropTypes.shape({
      name: PropTypes.string,
      city: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      street: PropTypes.string.isRequired,
      house: PropTypes.string,
      zip: PropTypes.string.isRequired,
    }),
    tracking: PropTypes.array,
  }).isRequired,
};
