import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    alignItems: 'center',
    background: theme.palette.primary.l100,
    bottom: 0,
    display: 'flex',
    height: theme.spacing(8),
    left: 0,
    padding: theme.spacing(0, 2),
    position: 'fixed',
    right: 0,
  },
  thumbnail: {
    paddingLeft: theme.spacing(11),
    transition: theme.transitions.create('padding-left', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  full: {
    paddingLeft: theme.spacing(40),
    transition: theme.transitions.create('padding-left', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
}));
