import api from '.';

export const fetchCountries = () => api.get('/country');

export const modifyCountries = (payload) => api.put(`/country`, payload);

export const modifyCountry = (countryId, payload) => api.put(`/country/${countryId}`, payload);

export default {
  fetchManyCountries: fetchCountries,
  modifyCountries,
  modifyCountry,
};
