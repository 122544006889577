import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslator } from 'i18n';
import {
  useScreen,
  useDialog,
  useList,
} from 'hooks';

import { deleteSupplier } from 'api/suppliers';

import AddIcon from '@mui/icons-material/Add';
import { Fab } from 'components';
import {
  DesktopView,
  MobileView,
  Header,
} from 'views/suppliers/overview';
import {
  ConfirmingDialog,
} from 'dialogs';

import { selectActionPermission } from 'store/selectors/account';
import { fetchSuppliers } from 'services/suppliers';
import { isLoading } from 'store/selectors/app';
import {
  selectOverviewSuppliersMetadata,
  selectOverviewSuppliers,
} from 'store/selectors/suppliers';

import toaster from 'services/toaster';
import tables from 'config/tables';
import { useHistory } from 'react-router-dom';

export const SuppliersOverview = () => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const list = useList(tables.suppliers.suppliers);
  const t = useTranslator();
  const history = useHistory();

  const canDeleteSupplier = useSelector(selectActionPermission('suppliers', 'delete'));
  const canCreateSupplier = useSelector(selectActionPermission('suppliers', 'create'));

  const isSuppliersLoading = useSelector(isLoading('suppliers.overview.suppliers'));
  const suppliersMetadata = useSelector(selectOverviewSuppliersMetadata);
  const suppliers = useSelector(selectOverviewSuppliers);

  const confirmingDialog = useDialog({ supplier: null });

  const loadSuppliers = useCallback(() => dispatch(fetchSuppliers(list.compile())), [dispatch, list]);

  useEffect(() => void loadSuppliers(), [loadSuppliers]);

  const removeSupplier = async () => {
    try {
      await deleteSupplier(confirmingDialog.data.supplier.id);
      toaster.success(t.translate('Supplier has been deleted successfully.'));
      loadSuppliers();
    } catch (err) {}
  };

  const openCreateSupplierPage = () => history.push(`/new-supplier`);

  return (
    <>
      <Helmet>
        <title>{t.translate('Suppliers')} | {t.translate('app:title')}</title>
      </Helmet>

      {canCreateSupplier && (
        <Fab onClick={openCreateSupplierPage} aria-label="Create supplier" variant="extended">
          <AddIcon />
          {t.translate('Create supplier')}
        </Fab>
      )}

      {canDeleteSupplier && (
        <ConfirmingDialog
          {...confirmingDialog.props}
          title={t.translate('Delete supplier')}
          acceptButtonText={t.translate(`Yes, I'm sure`)}
          declineButtonText={t.translate('No, cancel')}
          onAccept={removeSupplier}
        >
          {t.translate('Are you sure you want to delete the supplier?')}
        </ConfirmingDialog>
      )}

      <Header isMobile={screen.isMobile}/>

      {screen.isMobile ? (
        <MobileView
          canDeleteSupplier={canDeleteSupplier}
          onSupplierDelete={supplier => confirmingDialog.open({ supplier })}
          isSuppliersLoading={isSuppliersLoading}
          suppliersMetadata={suppliersMetadata}
          suppliers={suppliers}
        />
      ) : (
        <DesktopView
          canDeleteSupplier={canDeleteSupplier}
          onSupplierDelete={supplier => confirmingDialog.open({ supplier })}
          isSuppliersLoading={isSuppliersLoading}
          suppliersMetadata={suppliersMetadata}
          suppliers={suppliers}
          list={list}
        />
      )}
    </>
  );
};
