import { logOut } from 'services/authentication';

const createMenuItem = (text, name, path, iconId, divider) => (customHandler) => ({ text, name, path, iconId, divider, customHandler });

export const menuItems = [
  createMenuItem('Dashboard', 'dashboard', '/dashboard', 'dashboard')(),
  createMenuItem('Reports', 'reports', '/reports/statistic-reports', 'equalizer', true)(),
  createMenuItem('Orders', 'orders', '/orders', 'shoppingCart')(),
  createMenuItem('Pickup sheets', 'pickupSheets', '/pickup-sheets', 'assessment')(),
  createMenuItem('Products', 'products', '/products', 'assignmentReady')(),
  createMenuItem('Inbound stock', 'inboundStocks', '/inbound-stock', 'assignmentUpload')(),
  createMenuItem('Purchases', 'purchases', '/replenishment-suggestions', 'shoppingBasketRoundedIcon')(),
  createMenuItem('Customers', 'customers', '/customers', 'accountCart')(),
  createMenuItem('Suppliers', 'suppliers', '/suppliers', 'localShipping')(),
  createMenuItem('Warehouses', 'warehouses', '/warehouses', 'domain')(),
  createMenuItem('Storages', 'storages', '/storages', 'cupboard', true)(),
  createMenuItem('Settings', 'account', '/settings/account', 'settings')(),
  createMenuItem('Changes history', 'changesHistory', '/changes-history', 'changesHistory')(),
  createMenuItem('Logout', 'logout', null, 'exitDoor')(logOut),
];
