import React, { useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import has from 'lodash/has';

import { useTranslator } from 'i18n';
import {
  useDialog,
  useScreen,
  useRoute,
  useList,
} from 'hooks';

import { CustomerDialog } from 'dialogs';
import {
  DesktopView,
  MobileView,
} from 'views/customers/details';

import {
  activeCustomerLoading,
  activeCustomer,
} from 'store/selectors/customers';
import {
  selectActionPermission,
} from 'store/selectors/account';

import { setActiveCustomer } from 'store/actions/customers';
import { fetchActiveCustomer } from 'services/customers';
import toaster from 'services/toaster';
import { download } from 'services/files';
import { exportOne } from 'api/customers';

import tables from 'config/tables';

const isSomeEqual = (first, second = {}) => {
  if (has(first, [Object.keys(second || {})])) {
    return [Object.keys(second)].some(key => first[key] !== second[key]);
  }
  return true;
};

export const CustomerDetails = () => {
  const t = useTranslator();
  const screen = useScreen();
  const params = useParams();
  const dispatch = useDispatch();
  const list = useList(tables.customers.orders);
  const route = useRoute();

  const canExportCustomer = useSelector(selectActionPermission('customers', 'export'));
  const canEditCustomer = useSelector(selectActionPermission('customers', 'edit'));
  const isLoading = useSelector(activeCustomerLoading);
  const customer = useSelector(activeCustomer);

  const editingDialog = useDialog();

  const fetchCustomer = useCallback((id) => {
    dispatch(
      fetchActiveCustomer({ id, ...list.compile() })
    );
  }, [dispatch, list]);

  const exportCustomer = async() => {
    const file = await exportOne(customer.id);
    if (file) {
      fetchCustomer(params.customerId);
      toaster.success(t.translate('The customer has been successfully exported.'));
      download(file);
    }
  };

  useEffect(() => {
    if (Number(params.customerId) !== customer?.id) {
      fetchCustomer(params.customerId);
    }
  }, [fetchCustomer, params.customerId, customer]);

  useEffect(() => {
    if (isSomeEqual(route.search, list.compile())) {
      fetchCustomer(params.customerId);
    }
  }, [fetchCustomer, list, params.customerId, route.search]);

  useEffect(() => {
    return () => {
      dispatch(setActiveCustomer(null));
    };
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{customer ? `${customer.number} | ` : ''}{t.translate('Customer')} | {t.translate('app:title')}</title>
      </Helmet>

      {customer && editingDialog.visible && (
        <CustomerDialog
          {...editingDialog.props}
          customer={customer}
          onSave={() => fetchCustomer(customer.id)}
          isMobileView={screen.isMobile}
        />
      )}

      {screen.isMobile ? (
        <MobileView
          loading={isLoading}
          customer={customer}
          canExportCustomer={canExportCustomer}
          onExport={exportCustomer}
          canEditCustomer={canEditCustomer}
          onCustomerEdit={() => editingDialog.open()}
        />
      ) : (
        <DesktopView
          loading={isLoading}
          customer={customer}
          canExportCustomer={canExportCustomer}
          onExport={exportCustomer}
          canEditCustomer={canEditCustomer}
          onCustomerEdit={() => editingDialog.open()}
        />
      )}
    </>
  );
};
