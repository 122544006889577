import {
  VanillaTranslator,
  ReactTranslator,
} from './Translator';
import store from 'store';

export const useTranslator = () => new ReactTranslator();

export const createTranslator = () => {
  const { messages, locale } = store.getState().i18n;

  return new VanillaTranslator({
    messages: messages || {},
    locale,
  });
};
