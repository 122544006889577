import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useScreen, useDialog, didUpdate, useList } from 'hooks';
import { useTranslator } from 'i18n';

import {
  AdjustOrderDialog,
  CancellingOrderDialog,
  OrderProductDescriptionDialog,
  ShipAgainDialog,
  ReturnStockDialog,
} from 'dialogs';
import {
  DesktopView,
  MobileView,
  Header,
} from 'views/orders/details';

import { isLoading } from 'store/selectors/app';
import { selectActionPermission } from 'store/selectors/account';
import { setActiveOrder } from 'store/actions/orders';
import { fetchOrder } from 'services/orders';
import { updateOutboundStatus } from 'api/orders';
import { fetchStockOperations } from '../../../api/stock-operations';
import tables from 'config/tables';
import { MobileStockHistoryDialog } from '../../../views/orders/details/MobileStockHistoryDialog/MobileStockHistoryDialog';
import { DesktopStockHistoryDialog } from '../../../views/orders/details/DesktopStockHistoryDialog/DesktopStockHistoryDialog';

export const OrderDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const screen = useScreen();
  const params = useParams();
  const t = useTranslator();

  const adjustDialog = useDialog({ order: null });
  const cancellingDialog = useDialog({ order: null });
  const shipAgainDialog = useDialog({ order: null });
  const productDescription = useDialog({ position: null });
  const returnStockDialog = useDialog({ order: null });

  const isOrderLoading = useSelector(isLoading('orders.details.activeOrder'));
  const canPerformOrderActions = useSelector(selectActionPermission('orders', 'actions'));
  const canApproveOrder = useSelector(selectActionPermission('orders', 'approve'));
  const canEditOrder = useSelector(selectActionPermission('orders', 'edit'));

  const order = useSelector(({ orders }) => orders.activeOrder);

  const updateData = useCallback(() => dispatch(fetchOrder(params.orderId)), [dispatch, params.orderId]);

  const updateOrderOutboundStatus = async () => {
    await updateOutboundStatus(params.orderId);

    updateData();
  };

  useEffect(() => {
    updateData();
    return () => {
      dispatch(setActiveOrder(null));
    };
  }, [updateData, dispatch]);

  const stockHistoryDialog = useDialog();

  const list = useList('history', tables.returns.history);

  const showStockHistory = async productId => {
    try {
      const operations = await fetchStockOperations({ productId, ...list.compile() });
      stockHistoryDialog.open({ operations }, { productId });
    } catch (err) {}
  };

  didUpdate(() => {
    if (stockHistoryDialog.visible) {
      const getStockOperations = async () => {
        try {
          const operations = await fetchStockOperations({
            productId: stockHistoryDialog.options.productId,
            ...list.compile(),
          });
          stockHistoryDialog.setData({ operations });
        } catch (err) {}
      };
      getStockOperations();
    }
  }, [location.search]);

  return (
    <>
      <Helmet>
        <title>{order ? `${order.number} | ` : ''}{t.translate('Orders')} | {t.translate('app:title')}</title>
      </Helmet>

      {screen.isMobile
        ? <MobileStockHistoryDialog {...stockHistoryDialog.props} />
        : <DesktopStockHistoryDialog {...stockHistoryDialog.props} />}

      {cancellingDialog.data.order && canPerformOrderActions && (
        <CancellingOrderDialog
          {...cancellingDialog.props}
          isMobileView={screen.isMobile}
          onSave={updateData}
        />
      )}

      {returnStockDialog.data.order && canPerformOrderActions && (
        <ReturnStockDialog
          {...returnStockDialog.props}
          isMobileView={screen.isMobile}
          onSave={updateData}
        />
      )}

      {adjustDialog.data.order && canEditOrder && (
        <AdjustOrderDialog
          {...adjustDialog.props}
          onSave={updateData}
          isMobileView={screen.isMobile}
        />
      )}

      {productDescription.data.position && (
        <OrderProductDescriptionDialog
          title="Product details"
          isMobile={screen.isMobile}
          {...productDescription.props}
        />
      )}
      {shipAgainDialog.data.order && <ShipAgainDialog {...shipAgainDialog.props} updateData={updateData}/>}

      <Header
        onOrderApprove={updateData}
        onOrderAdjust={() => adjustDialog.open({ order })}
        onOrderCancel={() => cancellingDialog.open({ order })}
        onOrderShipAgain={() => shipAgainDialog.open({ order })}
        onOrderReturnStock={() => returnStockDialog.open({ order })}
        onUpdateOutboundStatus={updateOrderOutboundStatus}
        canApproveOrder={canApproveOrder}
        canEditOrder={canEditOrder}
        canPerformOrderActions={canPerformOrderActions}
        order={order}
        isMobile={screen.isMobile}
      />

      {screen.isMobile ? (
        <MobileView
          onProductDescriptionOpen={position => productDescription.open({ position })}
          order={order}
          isLoading={isOrderLoading}
          onStockHistoryShow={showStockHistory}
        />
      ) : (
        <DesktopView
          onProductDescriptionOpen={position => productDescription.open({ position })}
          canPerformOrderActions={canPerformOrderActions}
          order={order}
          isLoading={isOrderLoading}
          onStockHistoryShow={showStockHistory}
        />
      )}
    </>
  );
};
