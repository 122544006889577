import React from 'react';

import { useSelector } from 'react-redux';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { BooleanChip } from 'components';
import { createPermissionsListData } from 'services/account';

const useStyles = makeStyles()(theme => ({
  tableItem: {
    margin: theme.spacing(1.5, -3, 0),
    '& .MuiTableCell-root': {
      '&:first-of-type': {
        paddingLeft: theme.spacing(3),
      },
      '&:last-child': {
        borderRightWidth: 0,
        paddingRight: theme.spacing(3),
      },
    },
  },
  actionsGrid: {
    flexWrap: 'wrap',
  },
}));

export const Permissions = () => {
  const t = useTranslator();
  const { classes } = useStyles();

  const user = useSelector(({ users }) => users.details.user);
  const permissions = !user.isAdmin && createPermissionsListData(user.permissions);

  if (user.isAdmin) {
    return <Typography>{t.translate('The user is an administrator.')}</Typography>;
  } else {
    return (
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h6">{t.translate('Permissions')}</Typography>
        </Grid>
        <Grid item className={classes.tableItem}>
          <Table>
            <TableBody>
              {permissions.map(section => (
                <TableRow key={section.key}>
                  <TableCell>
                    <Typography>{t.translate(section.name)}</Typography>
                  </TableCell>
                  <TableCell>
                    <Grid container spacing={1} className={classes.actionsGrid}>
                      {section.actions.map(action => (
                        <Grid item key={action.key}>
                          <BooleanChip
                            label={t.translate(`permission:${action.key}`)}
                            value={action.value}
                            disabled={action.key !== 'view' && !user.permissions[section.key].view}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    );
  }
};
