import React from 'react';
import { useTranslator } from 'i18n';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import { Dialog, Paper } from '@mui/material';
import { BooleanIcon, Color } from 'components';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const useStyles = makeStyles()(theme => ({
  paper: {
    padding: theme.spacing(4),
  },
  bold: {
    fontWeight: 500,
  },
}));

export const StorageInfoDialog = ({
  onClose,
  storage,
  ...props
}) => {
  const { classes } = useStyles();
  const t = useTranslator();

  return storage && (
    <Dialog
      {...props}
      id="select-warehouse"
      fullWidth={true}
      maxWidth={'md'}
      onClose={onClose}
    >
      <Paper className={classes.paper}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell variant="head">
                  <Typography variant="body2" className={classes.bold}>{t.translate('Warehouse')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{storage.warehouse.name}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">
                  <Typography variant="body2" className={classes.bold}>{t.translate('Type')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{t.translate(`storageType:${storage.type}`)}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">
                  <Typography variant="body2" className={classes.bold}>{t.translate('Exclude from available stock')}</Typography>
                </TableCell>
                <TableCell>
                  <BooleanIcon truthy={storage.excludeStock} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">
                  <Typography variant="body2" className={classes.bold}>{t.translate('Building')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{storage.building}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">
                  <Typography variant="body2" className={classes.bold}>{t.translate('Floor')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{storage.floor || '—'}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">
                  <Typography variant="body2" className={classes.bold}>{t.translate('Room')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{storage.room}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">
                  <Typography variant="body2" className={classes.bold}>{t.translate('Row')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{storage.row}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">
                  <Typography variant="body2" className={classes.bold}>{t.translate('Shelf')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{storage.shelf}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">
                  <Typography variant="body2" className={classes.bold}>{t.translate('Level')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{storage.level || '—'}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">
                  <Typography variant="body2" className={classes.bold}>{t.translate('Box number')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{storage.boxNumber}</Typography>
                </TableCell>
              </TableRow>
              {storage.boxColor && (
                <TableRow>
                  <TableCell variant="head">
                    <Typography variant="body2" className={classes.bold}>{t.translate('Box color')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Color box color={storage.boxColor} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Dialog>
  );
};
