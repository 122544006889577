import { PURCHASES } from 'store/types';
import { purchases as is } from 'store/initialState';

const setPurchases = (state, { data, meta }) => ({
  ...state,
  overview: {
    ...state.overview,
    purchases: {
      data,
      meta: {
        totalEntities: meta.totalEntities,
      },
    },
  },
});

const setPurchase = (state, purchase) => ({
  ...state,
  details: {
    ...state.details,
    purchase,
  },
});

const setPurchasePositions = (state, { data, meta }) => ({
  ...state,
  details: {
    ...state.details,
    purchasePositions: {
      data,
      meta: {
        totalEntities: meta.totalEntities,
      },
    },
  },
});

const setIsLoadingPurchases = (state, payload) => ({
  ...state,
  overview: {
    ...state.overview,
    isLoadingPurchases: payload,
  },
});

export default (state = is, { type, payload }) => {
  switch (type) {
    case PURCHASES.OVERVIEW.SET_PURCHASES:
      return setPurchases(state, payload);
    case PURCHASES.DETAILS.SET_PURCHASE:
      return setPurchase(state, payload);
    case PURCHASES.DETAILS.SET_PURCHASE_POSITIONS:
      return setPurchasePositions(state, payload);
    case PURCHASES.OVERVIEW.SET_IS_LOADING_PURCHASES:
      return setIsLoadingPurchases(state, payload);
    default:
      return state;
  }
};
