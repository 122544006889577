import React from 'react';

import { Products, OutdatedProducts } from './subPages';

export const OUTDATED_PRODUCTS_LABEL = 'Outdated products';

export const routes = [{
  homePath: '/products',
  subPaths: [],
  Page: (props) => <Products {...props}/>,
  name: 'products',
  label: 'Products',
}, {
  homePath: '/outdated-products',
  subPaths: [],
  Page: () => <OutdatedProducts/>,
  name: 'products',
  label: OUTDATED_PRODUCTS_LABEL,
}];

export const tabsOptions = [{
    label: 'Products',
    name: '/products',
    view: 'products',
  }, {
    label: OUTDATED_PRODUCTS_LABEL,
    name: '/outdated-products',
    view: 'products',
  },
];
