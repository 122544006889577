import React from 'react';
import PropTypes from 'prop-types';

import { useList } from 'hooks/list';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { Column } from 'layouts';
import { Pagination } from 'components';
import { StorageCard } from '../../common';
import { ProductItem } from './ProductItem/ProductItem';

import tables from 'config/tables';

const useStyles = makeStyles()(theme => ({
  productPaper: {
    padding: theme.spacing(2),
  },
  pagination: {
    background: theme.palette.common.white,
    bottom: theme.spacing(0),
    left: 0,
    position: 'fixed',
    right: 0,
  },
}));

export const MobileView = ({ storageProducts, storage }) => {
  const { classes } = useStyles();
  const list = useList(tables.products.storages);

  const { data, meta } = storageProducts;

  if (storage) {
    return (
      <>
        <Column p={1} pb={15.5}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Paper className={classes.productPaper}>
                <StorageCard
                  storage={storage}
                />
              </Paper>
            </Grid>
            {data.map(storageProduct => (
              <Grid item key={storageProduct.id}>
                <ProductItem
                  storageProduct={storageProduct}
                />
              </Grid>
            ))}
          </Grid>
        </Column>
        <Pagination
          {...list.paginationProps}
          count={meta.totalEntities}
          direction="row"
          className={classes.pagination}
        />
      </>
    );
  } else {
    return <Column p={1} pb={15.5} />;
  }
};

MobileView.propTypes = {
  storageProducts: PropTypes.object.isRequired,
  storage: PropTypes.object,
};
