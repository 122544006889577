import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useTranslator } from 'i18n';

import { useDialog } from 'hooks';

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Grid from '@mui/material/Grid';

import { StorageSuppliersDialog } from 'dialogs';


const useStyles = makeStyles()(theme => ({
  root: {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(.5),
      padding: theme.spacing(1),
    },
  },
  buttonContainer: {
    padding: theme.spacing(2),
  },
  button: {
    color: theme.palette.primary.main,
    backgroundColor: '#f1f5ff',
    textTransform: 'none',
    width: '100%',
  },
  cellPadding: {
    height: '100%',
    '& .MuiTableRow-root .MuiTableCell-root:first-of-type': {
      paddingLeft: theme.spacing(3),
    },
    '& .MuiTableCell-alignRight': {
      paddingRight: theme.spacing(3),
    },
  },
  container: {
    height: '100%',
  },
}));

export const SuppliersDetails = ({ suppliers }) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const history = useHistory();

  const storageSuppliersDialog = useDialog();

  const openSupplier = id => history.push(`/edit-supplier/${id}`);

  return <>
    <StorageSuppliersDialog {...storageSuppliersDialog.props} />

    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table className={classes.cellPadding}>
          <TableHead>
            <TableRow>
              <TableCell name="number">{t.translate('Supplier number')}</TableCell>
              <TableCell name="name">{t.translate('Supplier name')}</TableCell>
              <TableCell name="stock" align="right">{t.translate('Stock')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {suppliers.length > 5
              ? <>
                  {suppliers.slice(0, 5).map(({ totalStock, supplier }, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Link onClick={() => openSupplier(supplier.id)}>{supplier.number}</Link>
                      </TableCell>
                      <TableCell>{supplier.name}</TableCell>
                      <TableCell align="right">{totalStock}</TableCell>
                    </TableRow>
                  ))}
                </>
              : suppliers.map(({ totalStock, supplier }, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Link onClick={() => openSupplier(supplier.id)}>{supplier.number}</Link>
                    </TableCell>
                    <TableCell>{supplier.name}</TableCell>
                    <TableCell align="right">{totalStock}</TableCell>
                  </TableRow>
                ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      {suppliers.length > 5 && (
        <Grid container className={classes.buttonContainer}>
          <Button
            className={classes.button}
            variant="text"
            startIcon={<LocalShippingIcon />}
            onClick={() => storageSuppliersDialog.open({ suppliers })}>
            {t.translate('Show all suppliers')}
          </Button>
        </Grid>
      )}
    </Paper>
  </>;
};

SuppliersDetails.propTypes = {
  suppliers: PropTypes.arrayOf(PropTypes.object),
};
