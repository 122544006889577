import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { CardFeature } from 'components';

import { styleMobileTableItem } from 'theme/mixins/table';
import { StorageProductOrSpecialCaseActionReasons } from 'consts';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  paper: {
    cursor: 'pointer',
    marginBottom: theme.spacing(0),
  },
}));

export const OperationItem = ({ operation }) => {
  const { classes } = useStyles();
  const t = useTranslator();

  return (
    <Grid item>
      <Paper className={classes.paper}>
        <Grid container direction="column" spacing={1}>
          <Grid item container direction="column">
            <CardFeature
              label={t.translate('Date')}
              value={`${t.formatDate(operation.createdAt)} ${t.formatTime(operation.createdAt)}`}
            />
            <CardFeature
              label={t.translate('User')}
              value={operation.user?.name || '—'}
            />
            <CardFeature
              label={t.translate('Storage')}
              value={operation.storageId}
            />
            <CardFeature
              label={t.translate('Stock')}
              value={(operation.addedStock > 0 ? '+' : '') + operation.addedStock}
            />
            <CardFeature
              label={t.translate('table-column: Reason')}
              value={t.translate(StorageProductOrSpecialCaseActionReasons[operation.reason].text)}
            />
            <CardFeature
              label={t.translate('Order number')}
              value={operation.orderCode || (operation.manual ? t.translate('Manually created') : '—')}
            />
            <CardFeature
              label={t.translate('table-column: Annotation')}
              value={operation.comment || '—'}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

OperationItem.propTypes = {
  operation: PropTypes.shape({
    addedStock: PropTypes.number.isRequired,
    orderCode: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    comment: PropTypes.string,
    reason: PropTypes.oneOf(Object.keys(StorageProductOrSpecialCaseActionReasons)).isRequired,
    manual: PropTypes.bool,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    storageId: PropTypes.number.isRequired,
  }).isRequired,
};
