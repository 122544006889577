import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';
import Carousel from 'react-material-ui-carousel';

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { ZoomPicture } from 'components';

const useStyles = makeStyles()(theme => ({
  root: {
    maxWidth: 'none',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'none',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  carousel: {
    '&>div:first-of-type>div': {
      position: 'relative',
    },
  },
}));

export const ImageDialog = ({
  onClose,
  images,
  additionalImages,
  title,
  onSetDefaultImage,
  isMobile,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const [activeImage, setActiveImage] = useState({});

  return (
    <Dialog onClose={onClose} {...props}>
      {title && <DialogTitle>{t.translate(title)}</DialogTitle>}
      <DialogContent>
        <Carousel
          sx={classes.carousel}
          navButtonsAlwaysVisible
          changeOnFirstRender
          autoPlay={false}
          onChange={(index) => setActiveImage(images[index])}
        >
          {
            images.map((image, i) => (
            <ZoomPicture
              key={i}
              image={image}
              style={{
                paddingTop: 0,
                height: isMobile ? '30vh' : '45vh',
                width: isMobile ? 'inherit' : '45vw',
              }}
              imageStyle={{
                position: 'initial',
                objectFit: isMobile ? 'contain' : 'none',
              }}
            />
            ))
          }
        </Carousel>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button color="primary" onClick={onClose}>{t.translate('Close')}</Button>
        {additionalImages.find(image => image?.id === activeImage?.id && !image.isDefault) && (
          <Button color="primary" onClick={() => onSetDefaultImage(activeImage)}>{t.translate('Set default')}</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ImageDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        filePath: PropTypes.string.isRequired,
      }),
    ])
  ),
  additionalImages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      filePath: PropTypes.string.isRequired,
    })
  ),
  isMobile: PropTypes.bool,
  onSetDefaultImage: PropTypes.func,
};
