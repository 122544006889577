import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from '@mui/material';

import sprite from 'static/images/tracking-service-icon-set.svg';

export const TrackingServiceSpriteIcon = ({ id = '', ...props }) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <use href={sprite + `#${id}`} />
  </SvgIcon>
);

TrackingServiceSpriteIcon.propTypes = {
  id: PropTypes.string.isRequired,
};
