import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { useTranslator } from 'i18n';

import { OutdatedDesktopView } from 'views/products/overview';
import { Fab } from 'components';
import CreateIcon from '@mui/icons-material/Create';
import { useDispatch } from 'react-redux';
import { fetchOutdatedProducts } from 'services/outdated-products';
import { useDialog, useScreen } from 'hooks';
import { ProductsMappingDialog } from 'dialogs';

export const OutdatedProducts = ({ productTypes }) => {
  const [selectedStorageProducts, setSelectedStorageProducts] = useState([]);
  const dispatch = useDispatch();
  const t = useTranslator();
  const screen = useScreen();

  const fetchData = useCallback(() => {
    dispatch(fetchOutdatedProducts());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const productsMappingDialog = useDialog();

  return (
    <>
      {productsMappingDialog.visible && (
        <ProductsMappingDialog
          {...productsMappingDialog.props}
          isMobileView={screen.isMobile}
          selectedStorageProducts={selectedStorageProducts}
          onSave={() => {
            setSelectedStorageProducts([]);
            fetchData();
          }}
        />
      )}

      <Helmet>
        <title>{t.translate('Outdated products')} | {t.translate('app:title')}</title>
      </Helmet>

      {Boolean(selectedStorageProducts.length) && (
        <>
          <Fab
            onClick={() => productsMappingDialog.open()}
            aria-label="Create new product"
            variant="extended"
          >
            <CreateIcon/>
            {t.translate('Create new product')}
          </Fab>
        </>
      )}

      <OutdatedDesktopView
        productTypes={productTypes}
        selectedStorageProducts={selectedStorageProducts}
        setSelectedStorageProducts={setSelectedStorageProducts}
      />
    </>
  );
};
