import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, Switch, Route, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslator } from 'i18n';
import { useDialog, useScreen } from 'hooks';

import { Box } from '@mui/material';

import { Header } from 'views/purchases/overview/DesktopView/Header/Header';
import { routes, tabsOptions } from './config';
import {
  isAuthenticatedUserAdministrator,
  selectActionPermission,
  selectPermissions,
} from 'store/selectors/account';
import { ImportPurchasesDialog } from 'dialogs';
import { importPurchases } from 'api/purchases';
import toaster from 'services/toaster';

export const PurchasesOverview = () => {
  const history = useHistory();
  const screen = useScreen();
  const t = useTranslator();
  const isAdmin = useSelector(isAuthenticatedUserAdministrator);
  const permissions = useSelector(selectPermissions);
  const canImportPurchases = useSelector(selectActionPermission('purchases', 'import'));

  const matchWithSuggestions = useRouteMatch(routes[routes.length - 1].homePath);

  const shouldShowMenuItem = option => {
    const name = option.view;
    const subView = option.subView;
    return isAdmin || permissions[name] === undefined || (subView ? (permissions[name].view && permissions[name][subView]) : permissions[name].view);
  };
  const allowedRoutes = routes.filter(({ name, subView }) => isAdmin || permissions[name] === undefined || (subView ? (permissions[name].view && permissions[name][subView]) : permissions[name].view));

  let activeRoute = !screen.isMobile ? (
    allowedRoutes.find(({ homePath }) => homePath === history.location.pathname || homePath === matchWithSuggestions?.path)
    || allowedRoutes[0]
  ) : allowedRoutes.find(({ homePath }) => homePath === history.location.pathname || homePath === matchWithSuggestions?.path);

  useEffect(() => {
    if (!activeRoute?.homePath === matchWithSuggestions?.path && !activeRoute?.subPaths.includes(history.location.pathname)) {
      history.replace(activeRoute.paths[0]);
    }
  }, [activeRoute, history, screen.isMobile, matchWithSuggestions]);

  const allowedOptions = tabsOptions.filter(option => shouldShowMenuItem(option));
  const activeOption = allowedOptions.find(({ name }) => history.location.pathname.includes(name))?.name;

  const importPurchasesDialog = useDialog();

  const onPurchasesImport = async ({ file, supplierId, warehouseId }) => {
    try {
      const formData = new FormData();

      formData.set('file', file);
      formData.set('supplierId', supplierId);
      formData.set('warehouseId', warehouseId);
      await importPurchases(formData);

      toaster.success(t.translate('Purchases have been added to an import queue.'));

      importPurchasesDialog.close();
    } catch (error) {}
  };

  const onImportPurchasesClick = () => importPurchasesDialog.open();

  return (
    <>
      <Helmet>
        <title>{t.translate('app:title')}</title>
      </Helmet>

      <Header
        isMobile={screen.isMobile}
        onImportPurchasesClick={onImportPurchasesClick}
        routeTitle={activeRoute?.label}
        screen={screen}
        allowedOptions={allowedOptions}
        activeOption={activeOption}
      />

      {canImportPurchases && importPurchasesDialog.visible && (
        <ImportPurchasesDialog
          {...importPurchasesDialog.props}
          onPurchasesImport={onPurchasesImport}
        />
      )}

      <Box display="flex" flexGrow={1} width={1}>
        <Switch>
          {Object.entries(allowedRoutes).map(([key, { name, homePath, subPaths, Page }]) => (
            shouldShowMenuItem(name) && (
              <Route key={key} exact path={[homePath, ...subPaths]}>
                <Page />
              </Route>
            )
          ))}
        </Switch>
      </Box>
    </>
  );
};
