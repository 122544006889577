import React, { useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';

import { useScreen, useList } from 'hooks';
import { useTranslator } from 'i18n';

import {
  Header,
  DesktopView,
  MobileView,
} from 'views/customers/overview';
import { customersLoading, customersDataWithMeta } from 'store/selectors/customers';
import { fetchCustomers } from 'services/customers';
import tables from 'config/tables';

export const CustomersOverview = () => {
  const t = useTranslator();
  const list = useList(tables.customers.customers);
  const screen = useScreen();
  const dispatch = useDispatch();
  const isLoading = useSelector(customersLoading);
  const { data, meta } = useSelector(customersDataWithMeta);

  const loadCustomers = useCallback(() => {
    dispatch(
      fetchCustomers({
        searchQuery: list.filters.q,
        ...list.compile(),
      })
    );
  }, [dispatch, list]);

  useEffect(() => {
    loadCustomers();
  }, [loadCustomers]);

  return (
    <>
      <Helmet>
        <title>{t.translate('Customers')} | {t.translate('app:title')}</title>
      </Helmet>

      <Header isMobile={screen.isMobile}/>

      {screen.isMobile ? (
        <MobileView loading={isLoading} customers={data} meta={meta}/>
      ) : (
        <DesktopView loading={isLoading} customers={data} meta={meta}/>
      )}
    </>
  );
};
