import api from '.';

export const fetchTranslations = () => api.get('/translations');

export const fetchApiVersion = () => api.get('/version');

export default {
  fetchTranslations,
  fetchApiVersion,
};
