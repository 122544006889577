import { detectLocale, loadLocale } from 'services/i18n/locale';

export const reports = {
  overview: {
    statisticsReports: {
      data: [/** @instanceof StatisticsReport */],
      meta: {
        totalEntities: 0,
      },
    },
    warehouseInventoryReports: {
      data: [/** @instanceof WarehouseInventoryReport */],
      meta: {
        totalEntities: 0,
      },
    },
  },
};

export const specialCases = {
  activeSpecialCase: null,
  specialCases: {
    users: [/** @instanceof User */],
    data: [/** @instanceof SpecialCase */],
    meta: {
      totalEntities: 0,
    },
  },
  history: {
    data: [/** @instance SpecialCaseOperation */],
    meta: {
      totalEntities: 0,
    },
  },
};

export const pickupSheets = {
  activePickupSheet: null,
  pickupSheets: {
    data: [/** @instanceof PickupSheet */],
    meta: {
      totalEntities: 0,
    },
  },
  requests: {
    addProduct: {
      loading: false,
      error: '',
    },
  },
};

export const warehouses = {
  overview: {
    data: [/** @instanceof Warehouse */],
    meta: {
      totalEntities: 0,
    },
  },
  details: {
    warehouse: null,
  },
};

export const suppliers = {
  overview: {
    suppliers: {
      data: [/** @instanceof Supplier */],
      meta: {
        totalEntities: 0,
      },
    },
  },
  details: {
    supplier: null,
  },
};

export const dashboard = {
  statistics: {
    pickupSheets: {},
    packages: {},
    orders: {},
    customers: {},
    products: {},
    stock: {},
    storageUsage: [],
    warehouseFees: [],
  },
  productFunnel: {
    activeProducts: {},
    productsWithStock: {},
    productsFromApi: {},
    productsWithout3DImages: {},
    productsWithNotLowestPrice: {},
    notSoldProducts: {},
  },
};

export const customers = {
  activeCustomer: null,
  activeCustomerLoading: false,
  customers: {
    customersLoading: false,
    data: [/** @instanceof Customer */],
    meta: {
      totalEntities: 0,
    },
  },
};

export const storages = {
  details: {
    storage: null,
    storageProducts: {
      data: [/** @instanceof StorageProducts */],
      meta: {
        totalEntities: 0,
      },
    },
  },
  overview: {
    warehouses: [/** @instanceof Warehouse */],
    storages: {
      data: [/** @instanceof Storage */],
      meta: {
        totalEntities: 0,
      },
    },
  },
};

export const products = {
  products: {
    suppliers: [/** @instanceof Supplier */],
    data: [/** @instanceof Product */],
    meta: {
      totalEntities: 0,
    },
  },
  prices: null,
  storages: {
    product: null,
    data: [/** @instanceof StorageProduct */],
    meta: {
      totalEntities: 0,
    },
  },
  history: {
    storageProduct: null,
    suppliers: [/** @instanceof Supplier */],
    data: [/** @instanceof StorageProductOperation */],
    meta: {
      totalEntities: 0,
    },
  },
};

export const changesHistory = {
  changesHistory: {
    data: [/** @instanceof Changes history*/],
    meta: {
      totalEntities: 0,
    },
  },
};

export const purchases = {
  overview: {
    isLoadingPurchases: false,
    purchases: {
      data: [/** @instanceof Purchase */],
      meta: {
        totalEntities: 0,
      },
    },
  },
  details: {
    purchase: null,
    purchasePositions: {
      data: [/** @instanceof PurchasePosition */],
      meta: {
        totalEntities: 0,
      },
    },
  },
};

export const replenishmentSuggestions = {
  overview: {
    replenishmentSuggestions: {
      data: [/** @instanceof ReplenishmentSuggestion */],
      meta: {
        totalEntities: 0,
      },
    },
  },
};

export const outdatedProducts = {
  overview: {
    outdatedProducts: {
      data: [/** @instanceof StorageProduct */],
    },
  },
};

export const account = {
  dailyStatusMail: false,
  permissions: {},
  defaultView: '',
  defaultProductGroups: [],
  warehouse: null,
  isAdmin: false,
  locale: '',
  email: '',
  name: '',
};

export const orders = {
  activeOrder: null,
  statuses: [/** @instanceof OrderStatus */],
  loadingOrders: false,
  data: [/** @instanceof Order */],
  meta: {
    totalEntities: 0,
    lastRetrieved: null,
  },
};

export const rawOrders = {
  isLoading: false,
  data: [/** @instanceof RawOrderTemp */],
  meta: {
    totalEntities: 0,
  },
};

export const users = {
  overview: {
    data: [/** @instanceof User */],
    meta: {
      totalEntities: 0,
    },
  },
  details: {
    warehouses: [/** @instanceof Warehouse */],
    user: null,
  },
};

export const companies = {
  overview: {
    data: [/** @instanceof Company */],
    meta: {
      totalEntities: 0,
    },
  },
  details: {
    company: null,
  },
};

export const retailers = {
  overview: {
    data: [/** @instanceof Retailer */],
    meta: {
      totalEntities: 0,
    },
  },
  details: {
    retailer: null,
  },
};

export const i18n = {
  messages: {},
  locale: loadLocale() || detectLocale(),
};

export const app = {
  navigation: {
    visible: localStorage.getItem('app.navigation_type') === 'full',
  },
  apiVersion: '',
  loading: {},
  header: {
    height: 0,
  },
};

export default {
  changesHistory,
  specialCases,
  pickupSheets,
  warehouses,
  suppliers,
  dashboard,
  customers,
  purchases,
  replenishmentSuggestions,
  outdatedProducts,
  storages,
  products,
  reports,
  account,
  orders,
  companies,
  rawOrders,
  users,
  i18n,
  app,
};
