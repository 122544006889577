import { makeStyles } from 'tss-react/mui';
import { styleMobileTableItem } from 'theme/mixins/table';

export const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  accordion: {
    background: theme.palette.backgrounds.main['50'],
    padding: theme.spacing(2),
    paddingBottom: 0,
    boxShadow: 'none',
    '& .MuiAccordionSummary-root, & .MuiAccordionDetails-root': {
      padding: 0,
    },
  },
  bold: {
    fontWeight: 500,
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  button: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.l10,
    fontSize: '0.75rem',
    textTransform: 'none',
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  contentItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2),
  },
  item: {
    margin: theme.spacing(.5),
    marginBottom: theme.spacing(2),
  },
}));
