import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './style';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import {
  LocaleSwitcher,
  AppVersion,
  Logo,
} from 'components';

export const Auth = ({
  title,
  children,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid className={classes.page} container direction="column" spacing={isXs ? 2 : 6}>
      <Grid item className={classes.logoItem}>
        <Logo width={isXs ? 200 : 256} />
      </Grid>
      <Grid item container justifyContent="center" flexWrap="wrap">
        <Paper className={classes.paper}>
          <Grid container direction="column">
            <Grid item>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h6">{title}</Typography>
                </Grid>
                <Grid item>
                  <LocaleSwitcher className={classes.localeSwitcher} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>{children}</Grid>
          </Grid>
        </Paper>
        <AppVersion />
      </Grid>
    </Grid>
  );
};

Auth.propTypes = {
  title: PropTypes.string.isRequired,
};
