import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const ConfirmingDialog = ({
  onAccept,
  onClose,
  declineButtonText,
  acceptButtonText,
  title,
  children,
  ...props
}) => {
  const accept = () => {
    onAccept();
    onClose();
  };

  return (
    <Dialog scroll="body" {...props} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>{declineButtonText}</Button>
        <Button color="primary" onClick={accept}>{acceptButtonText}</Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmingDialog.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  declineButtonText: PropTypes.string,
  acceptButtonText: PropTypes.string,
};

ConfirmingDialog.defaultProps = {
  declineButtonText: 'Decline',
  acceptButtonText: 'Accept',
};
