import { STORAGES } from 'store/types';
import { pack } from 'store/utils';

export const setOverviewWarehouses = warehouses => dispatch => dispatch(pack(STORAGES.OVERVIEW.SET_WAREHOUSES, warehouses));

export const setOverviewStorages = storages => dispatch => dispatch(pack(STORAGES.OVERVIEW.SET_STORAGES, storages));

export const setStorage = payload => dispatch => dispatch(pack(STORAGES.DETAILS.SET_STORAGE, payload));

export const setStorageProducts = payload => dispatch => dispatch(pack(STORAGES.DETAILS.SET_STORAGE_PRODUCTS, payload));

export const clearStorageProducts = () => dispatch => dispatch(pack(STORAGES.DETAILS.CLEAR_STORAGE_PRODUCTS));
