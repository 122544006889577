import { useState, useMemo } from 'react';
import { upload as uploadFile } from 'api/files';
import toaster from 'services/toaster';

export const useUpload = ({
  onUploaded,
  onUpload,
  onFailed,
  t,
}) => {
  const [status, setStatus] = useState('ready');

  return useMemo(() => {
    const isSucceeded = status === 'succeeded';
    const isFailed = status === 'failed';
    const isReady = status === 'ready';
    const isBusy = status === 'busy';

    const upload = async data => {
      onUpload && onUpload();
      setStatus('busy');
      try {
        const file = await uploadFile(data);
        toaster.success(t.translate('File is uploaded successfully.'));
        setStatus('succeeded');
        onUploaded && onUploaded(file);
      } catch (err) {
        setStatus('failed');
        onFailed && onFailed();
      }
    };

    return {
      isSucceeded,
      isFailed,
      isReady,
      isBusy,
      upload,
      reset: () => setStatus('ready'),
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
};
