import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';

import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { AlzuraSpriteIcon } from 'components';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useStyles } from '../Tables';

import {
  TableCell,
  TableRow,
  Table,
} from 'components';

export const OrdersTable = ({ orders }) => {
  const { classes } = useStyles();
  const t = useTranslator();

  const createRowCreator = t => (label, values, key) => ({ values, label: t.translate(label), key });

  const createRow = createRowCreator(t);
  const rows = [
    createRow('Today', orders, 'daily'),
    createRow('Week', orders, 'weekly'),
    createRow('Month', orders, 'monthly'),
    createRow('Year', orders, 'yearly'),
  ];

  return (
    <TableContainer>
      <Table stickyHeader className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell fixed width={130} name="orders">
              <Grid className={classes.titleFixedColumn}>
                <AlzuraSpriteIcon id='shoppingCart'/>
                <Typography>{t.translate('Orders')}</Typography>
              </Grid>
            </TableCell>
            <TableCell width={100} className={classes.headerCell} name="received" align="right">
              {t.translate('Received')}
            </TableCell>
            <TableCell width={100} className={classes.headerCell} name="canceled" align="right">
              {t.translate('Canceled')}
            </TableCell>
            <TableCell width={100} className={classes.headerCell} name="fulfilled" align="right">
              {t.translate('Fulfilled')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((item, index) => (
            <TableRow key={index} className={classes.tableRow}>
              <TableCell className={classes.fixedCell}>
                <Typography>
                  {item.label}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell} align="right" >{item.values[item.key]?.received ?? '—'}</TableCell>
              <TableCell className={classes.cell} align="right" >{item.values[item.key]?.cancelled ?? '—'}</TableCell>
              <TableCell className={classes.cell} align="right" >{item.values[item.key]?.fulfilled ?? '—'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

OrdersTable.propTypes = {
  yearly: PropTypes.shape({
    label: PropTypes.string.isRequired,
    received: PropTypes.string,
    cancelled: PropTypes.string,
    fulfilled: PropTypes.string,
  }),
  monthly: PropTypes.shape({
    label: PropTypes.string.isRequired,
    received: PropTypes.string,
    cancelled: PropTypes.string,
    fulfilled: PropTypes.string,
  }),
  weekly: PropTypes.shape({
    label: PropTypes.string.isRequired,
    received: PropTypes.string,
    cancelled: PropTypes.string,
    fulfilled: PropTypes.string,
  }),
  daily: PropTypes.shape({
    label: PropTypes.string.isRequired,
    received: PropTypes.string,
    cancelled: PropTypes.string,
    fulfilled: PropTypes.string,
  }),
};
