import React, { useState, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import { useTranslator } from 'i18n';
import { useScreen } from 'hooks';

import {
  DesktopView,
  MobileView,
  Header,
} from 'views/inbound-stocks';
import { CircularLoader } from 'components';

import { selectAuthenticatedUserWarehouse } from 'store/selectors/account';

export const InboundStock = () => {
  const screen = useScreen();
  const t = useTranslator();

  const warehouse = useSelector(selectAuthenticatedUserWarehouse);

  const [isLoading, setIsLoading] = useState(true);

  useLayoutEffect(() => {
    setIsLoading(false);
    return () => {
      setIsLoading(false);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{t.translate('Stock booking')} | {t.translate('app:title')}</title>
      </Helmet>

      <Header isMobile={screen.isMobile} />

      {isLoading ? (
        <CircularLoader />
      ) : (
        screen.isMobile ? (
          <MobileView
            isLoading={!warehouse}
            isMobile={screen.isMobile}
          />
        ) : (
          <DesktopView
            isLoading={!warehouse}
            isMobile={screen.isMobile}
          />
        )
      )}
    </>
  );
};
