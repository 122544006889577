import printJs from 'print-js';
import React, { useCallback, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslator } from 'i18n';
import {
  useScreen,
  useDialog,
  didMount,
  useLoading,
} from 'hooks';

import {
  ModifyingPickupSheetProduct,
  PackingPickupSheetDialog,
  ConfirmingDialog,
  ProductLocationDialog,
} from 'dialogs';
import {
  LabellingButton,
  ClosingButton,
  DesktopView,
  MobileView,
  Header,
} from 'views/pickup-sheets/details';

import { printPickupSheetDocument } from 'services/pickup-sheets';
import { selectActionPermission } from 'store/selectors/account';
import { setActivePickupSheet, updateActivePickupSheetProduct } from 'store/actions/pickup-sheets';
import { isLoading } from 'store/selectors/app';
import { closePickupSheet, deletePickupSheet, labelPickupSheet, unpackPickupSheet } from 'api/pickup-sheets';
import { fetchProductStorageLocation } from 'api/storage-products';
import { packPickupSheetProduct } from 'api/pickup-sheet-products';
import { fetchPickupSheet } from 'services/pickup-sheets';
import toaster from 'services/toaster';
import { makeSourceLink } from 'services/files';

export const PickupSheetDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const screen = useScreen();
  const params = useParams();
  const t = useTranslator();
  const loading = useLoading();
  const packingDialogRef = useRef();

  const canDeletePickupSheet = useSelector(selectActionPermission('pickupSheets', 'delete'));
  const canClosePickupSheet = useSelector(selectActionPermission('pickupSheets', 'close'));
  const canPackPickupSheet = useSelector(selectActionPermission('pickupSheets', 'pack'));
  const canUnpackPickupSheet = useSelector(selectActionPermission('pickupSheets', 'unpack'));
  const canEditPickupSheet = useSelector(selectActionPermission('pickupSheets', 'edit'));
  const canPrintPickupSheet = useSelector(selectActionPermission('pickupSheets', 'print'));
  const canScanPickupSheet = useSelector(selectActionPermission('pickupSheets', 'label'));
  const isPickupSheetLoading = useSelector(isLoading('pickupSheets.details.pickupSheet'));
  const pickupSheet = useSelector(({ pickupSheets }) => pickupSheets.activePickupSheet);

  const confirmingDialog = useDialog();
  const confirmingUnpackDialog = useDialog({ package: null });
  const modifyingDialog = useDialog({ pickupSheetProduct: null });
  const packingDialog = useDialog();
  const productLocationDialog = useDialog({ storageProducts: null });

  const fetchData = useCallback(() => dispatch(fetchPickupSheet(params.pickupSheetId)), [dispatch, params.pickupSheetId]);

  didMount(() => () => dispatch(setActivePickupSheet(null)));

  useEffect(() => void fetchData(), [fetchData]);

  const showProductLocation = async (payload) => {
    try {
      const location = await fetchProductStorageLocation(payload);
      productLocationDialog.open({ storageProducts: location });
    } catch (err) {}
  };

  const removePickupSheet = async () => {
    try {
      await deletePickupSheet(pickupSheet.id);
      toaster.success(t.translate('Pickup sheet has been deleted successfully.'));
      history.push('/pickup-sheets');
    } catch (err) {}
  };

  const decreaseScannedPickupSheetProduct = async (id) => {
    try {
      const pickupSheetProduct = await packPickupSheetProduct(id, { decrease: true });
      dispatch(updateActivePickupSheetProduct(pickupSheetProduct));
      const { markedQuantity, quantity } = pickupSheetProduct;
      toaster['info']({
        content: t.translate('Amount: {quantity} / Marked: {markedQuantity}', { markedQuantity, quantity }),
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        ...(screen.isMobile && { style: { bottom: 20 } }),
      });

      dispatch(setActivePickupSheet(null));
      fetchData();
    } catch (err) {}
  };

  const unpackPackage = async () => {
    const { package: packageId } = confirmingUnpackDialog.data;
    await unpackPickupSheet({ pickupSheetId: Number(params.pickupSheetId), packageId });
    toaster.success(t.translate('Package has been unpacked successfully.'));
    fetchData();
  };

  const onNextStepScan = (packingDialog, pickupSheet) => {
    if (pickupSheet?.status === 'open' && pickupSheet.pickupSheetProducts?.length && !packingDialog.visible) {
      return packingDialog.open();
    }

    if (packingDialog.visible) {
      return packingDialogRef.current.onSubmit(
        packingDialogRef.current.values, {
          setErrors: packingDialogRef.current.setErrors,
        }
      );
    }

    if (pickupSheet?.status === 'packed') {
      return onLabellingButtonClick();
    }

    if (pickupSheet?.status === 'labelled') {
      return onClosingButtonClick();
    }
  };

  const onLabellingButtonClick = async () => {
    try {
      const packingLabelsDocument = await labelPickupSheet(pickupSheet.id);

      if (packingLabelsDocument) {
        printJs(makeSourceLink(packingLabelsDocument.filePath));
        fetchData();
      }
    } catch (err) {}
  };

  const onClosingButtonClick = loading.lock(async () => {
    try {
      await closePickupSheet(pickupSheet.id);

      toaster.success({
        content: t.translate('The pickup sheet has been closed successfully.'),
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      });
      history.push('/pickup-sheets');
    } catch (err) {}
  });

  return (
    <>
      <Helmet>
        <title>{pickupSheet ? `${pickupSheet.id} | ` : ''}{t.translate('Pickup sheets')} | {t.translate('app:title')}</title>
      </Helmet>

      {canDeletePickupSheet && (
        <ConfirmingDialog
          {...confirmingDialog.props}
          title={t.translate('Delete pickup sheet')}
          acceptButtonText={t.translate(`Yes, I'm sure`)}
          declineButtonText={t.translate('No, cancel')}
          onAccept={removePickupSheet}
        >
          {t.translate('Are you sure you want to delete the pickup sheet?')}
        </ConfirmingDialog>
      )}
      {canEditPickupSheet && modifyingDialog.data.pickupSheetProduct && (
        <ModifyingPickupSheetProduct
          {...modifyingDialog.props}
          onSave={() => fetchData()}
          isMobile={screen.isMobile}
        />
      )}
      {canPackPickupSheet && pickupSheet && (
        <PackingPickupSheetDialog
          {...packingDialog.props}
          ref={packingDialogRef}
          pickupSheet={pickupSheet}
          onSave={() => fetchData()}
        />
      )}
      {productLocationDialog.visible && (
        <ProductLocationDialog
          {...productLocationDialog.props}
          isMobile={screen.isMobile}
          title={'Storage location information'}
        />
      )}
      {canUnpackPickupSheet  && (
        <ConfirmingDialog
          {...confirmingUnpackDialog.props}
          title={t.translate('Unpack package')}
          acceptButtonText={t.translate(`Yes, I'm sure`)}
          declineButtonText={t.translate('No, cancel')}
          onAccept={unpackPackage}
        >
          {t.translate('Are you sure you want to unpack package?')}
        </ConfirmingDialog>
      )}

      {pickupSheet?.status === 'packed' && <LabellingButton onClick={onLabellingButtonClick} />}
      {(pickupSheet?.status === 'labelled' || pickupSheet?.status === 'label-fetched') && canClosePickupSheet && <ClosingButton onClick={onClosingButtonClick} />}

      <Header
        isHandheldScannerDisabled={modifyingDialog.visible || packingDialog.visible}
        onPickupSheetDelete={() => confirmingDialog.open()}
        onPickupSheetPrint={() => printPickupSheetDocument(pickupSheet)}
        onNextStepScan={onNextStepScan}
        packingDialog={packingDialog}
        canDeletePickupSheet={canDeletePickupSheet}
        canPrintPickupSheet={canPrintPickupSheet}
        canScanPickupSheet={canScanPickupSheet}
        pickupSheet={pickupSheet}
        fetchData={fetchData}
        isMobile={screen.isMobile}
      />

      {screen.isMobile ? (
        <MobileView
          onPickupSheetProductEdit={pickupSheetProduct => modifyingDialog.open({ pickupSheetProduct })}
          onProductLocationShow={showProductLocation}
          onProductDecreaseScanned={decreaseScannedPickupSheetProduct}
          canEditPickupSheet={canEditPickupSheet}
          pickupSheet={pickupSheet}
          isLoading={isPickupSheetLoading}
          canPackPickupSheet={canPackPickupSheet}
          onPackPickupSheet={() => packingDialog.open()}
          canUnpackPickupSheet={canUnpackPickupSheet && pickupSheet?.status !== 'closed'}
          onUnpackPackage={packageId => confirmingUnpackDialog.open({ package: packageId })}
        />
      ) : (
        <DesktopView
          onPickupSheetProductEdit={pickupSheetProduct => modifyingDialog.open({ pickupSheetProduct })}
          onProductLocationShow={showProductLocation}
          onProductDecreaseScanned={decreaseScannedPickupSheetProduct}
          canEditPickupSheet={canEditPickupSheet}
          pickupSheet={pickupSheet}
          isLoading={isPickupSheetLoading}
          canPackPickupSheet={canPackPickupSheet}
          onPackPickupSheet={() => packingDialog.open()}
          canUnpackPickupSheet={canUnpackPickupSheet && pickupSheet?.status !== 'closed'}
          onUnpackPackage={packageId => confirmingUnpackDialog.open({ package: packageId })}
        />
      )}
    </>
  );
};
