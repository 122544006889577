import React from 'react';
import printJs from 'print-js';
import { useLoading } from 'hooks/loading';
import { makeStyles } from 'tss-react/mui';
import PrintIcon from '@mui/icons-material/Print';
import CircularProgress from '@mui/material/CircularProgress';
import { Fab } from 'components';
import { printPickupSheets } from 'api/pickup-sheets';
import { makeSourceLink } from '../../../../services/files';
import { useTranslator } from '../../../../i18n';

const useStyles = makeStyles()(theme => ({
  root: {
    bottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(4),
    },
  },
}));

export const PrintingFab = () => {
  const t = useTranslator();
  const loading = useLoading();
  const { classes } = useStyles();

  const print = loading.lock(async () => {
    try {
      const { filePath } = await printPickupSheets();

      await printJs(makeSourceLink(filePath));
    } catch (err) {}
  });

  return (
    <Fab onClick={print} className={classes.root} aria-label="Print pickup sheets" variant="extended">
      {loading.progress
        ? <CircularProgress color="inherit" size={20} />
        : (
          <>
            <PrintIcon />
            {t.translate('Print pickup sheets')}
          </>
        )
      }

    </Fab>
  );
};
