import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    '&.MuiGrid-container': {
      marginBottom: `${theme.spacing(1)} !important`,
    },
  },
  containerItem: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grey['250']}`,
    borderRadius: theme.spacing(.5),
  },
  expanded: {
    backgroundColor: theme.palette.backgrounds.grey['240'],
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  bold: {
    fontWeight: 500,
  },
}));
