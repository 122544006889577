import React from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@mui/material/FormHelperText';

export const FormControlError = ({ error }) => Array.isArray(error)
  ? error.map((error, index) => <FormHelperText key={index} style={{ marginLeft: '0px', color: '#d32f2f' }}>{error}</FormHelperText>)
  : <FormHelperText style={{ marginLeft: '0px', color: '#d32f2f' }}>{error}</FormHelperText>;

FormControlError.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.string
    ),
  ]),
};
