import React from 'react';
import PropTypes from 'prop-types';

import { didUpdate, useForm } from 'hooks';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {
  IntegerFormControl,
  FormControlError,
  BusyButton,
} from 'components';

import { modifyPickupSheetProduct } from 'api/pickup-sheet-products';
import { styleFormDialog } from 'theme/mixins/dialog';
import toaster from 'services/toaster';

const useStyles = makeStyles()(theme => styleFormDialog(theme, {
  summary: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: `${theme.spacing(1)} !important`,
    },
  },
}));

export const ModifyingPickupSheetProduct = ({
  onClose,
  onSave,
  pickupSheetProduct,
  isMobile,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const form = useForm({
    markedQuantity: pickupSheetProduct.markedQuantity,
  });
  const {
    catchHttpError,
    resetForm,
    setValue,
    errors,
    model,
  } = form;

  didUpdate(() => {
    if (props.open) {
      resetForm();
    }
  }, [props.open]);

  const submit = (pickupSheetProductId => async () => {
    try {
      const payload = await modifyPickupSheetProduct(pickupSheetProductId, model);
      toaster.success({
        content: t.translate('Product has been saved successfully.'),
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        ...(isMobile && { style: { bottom: 20 } }),
      });

      onSave(payload);
      onClose();
    } catch (err) {
      catchHttpError(err);
    }
  })(pickupSheetProduct.id);

  return (
    <Dialog scroll="body" {...props} className={classes.root} onClose={onClose}>
      <DialogTitle className={classes.title}>{t.translate('Edit product')}</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container direction="column" spacing={3}>

{/** Summary */}

          <Grid item className={classes.summary}>
            <Grid container>
              <Grid item>
                <Typography color="textSecondary" variant="body2">Alzura ID: {pickupSheetProduct.product.alzuraId || '—'}</Typography>
                <Typography color="textSecondary" variant="body2">{t.translate('Designation')}: {pickupSheetProduct.product.details[0]?.name || '—'}</Typography>
                <Typography color="textSecondary" variant="body2">{t.translate('Quantity')}: {pickupSheetProduct.quantity}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <FormControl fullWidth error={!!errors.stock}>

{/** Marked quantity */}

              <IntegerFormControl
                initialValue={pickupSheetProduct.markedQuantity}
                rightLabel={t.translate('New packed quantity')}
                leftLabel={t.translate('Old packed quantity')}
                value={model.markedQuantity - pickupSheetProduct.markedQuantity}
                min={pickupSheetProduct.packedQuantity}
                max={pickupSheetProduct.quantity}
                onChange={value => setValue('markedQuantity')(value + pickupSheetProduct.markedQuantity)}
              />
              {!!errors.markedQuantity && <FormControlError error={errors.markedQuantity} />}

            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
        <BusyButton onClick={submit}>{t.translate('Save')}</BusyButton>
      </DialogActions>
    </Dialog>
  );
};

ModifyingPickupSheetProduct.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
  pickupSheetProduct: PropTypes.shape({
    packedQuantity: PropTypes.number.isRequired,
    markedQuantity: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    product: PropTypes.shape({
      name: PropTypes.string,
      alzuraId: PropTypes.string,
    }),
    id: PropTypes.number.isRequired,
  }).isRequired,
};
