import api from '.';
import { extractListParams } from 'utils/querying';

export const fetchPurchases = ({
  supplierNumber,
  status,
  isSkipFulfilled,
  isIncludePositions,
  ...params
}) => api.get('/purchases', { params: {
  ...extractListParams(params),
  ...(status && { status }),
  ...(supplierNumber && { supplierNumber }),
  ...(isSkipFulfilled && { isSkipFulfilled }),
  ...(isIncludePositions && { isIncludePositions }),
} });

export const fetchPurchasePositions = ({
  manufacturerName,
  manufacturerNumber,
  purchaseId,
  name,
  ean,
  id,
  ...params
}) => api.get(`/purchases/${purchaseId}/positions`, {
  params: {
    ...extractListParams(params),
    ...(manufacturerNumber && { manufacturerNumber }),
    ...(manufacturerName && { manufacturerName }),
    ...(name && { name }),
    ...(ean && { ean }),
    ...(id && { id }),
  },
});

export const createPurchase = payload => api.post('/purchases', payload);

export const fetchPurchase = purchaseId => api.get(`/purchases/${purchaseId}`);

export const modifyPurchase = (purchaseId, payload) => api.put(`/purchases/${purchaseId}`, payload);

export const correctPurchase = (purchaseId, payload) => api.put(`/purchases/${purchaseId}/correction`, payload);

export const correctPosition = (purchaseId, positionId, payload) => api.post(`/purchases/${purchaseId}/correction/${positionId}`, payload);

export const deletePurchase = purchaseId => api.delete(`/purchases/${purchaseId}`);

export const approvePurchase = purchaseId => api.patch(`/purchases/${purchaseId};approve`);

export const declinePurchase = purchaseId => api.patch(`/purchases/${purchaseId};decline`);

export const importPurchases = (data) => api.post('/purchases;import', data, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export const exportPurchase = purchaseId => api.get(`/purchases/${purchaseId};export`);

export default {
  deleteOne: deletePurchase,
  modifyOne: modifyPurchase,
  fetchMany: fetchPurchases,
  createOne: createPurchase,
  fetchOne: fetchPurchase,
  approvePurchase: approvePurchase,
  declinePurchase: declinePurchase,
  fetchPurchasePositions,
  exportPurchase,
  correctPosition,
};
