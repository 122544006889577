import React, { useMemo } from 'react';

import { useTranslator } from 'i18n';
import { useList } from 'hooks';

import { makeStyles } from 'tss-react/mui';
import Radio from '@mui/material/Radio';
import Grid from '@mui/material/Grid';

import {
  CheckableMenuItem,
  TuneBox,
} from 'components';

import tables from 'config/tables';

const useStyles = makeStyles()(theme => ({
  title: {
    padding: theme.spacing(1.25, 2),
    fontWeight: 500,
    backgroundColor: theme.palette.backgrounds.secondary,
  },
  popover: {
    '& .MuiPopover-paper': {
      marginLeft: theme.spacing(.5),
    },
  },
}));

const pickupSheetStatuses = [
  ['all', 'All'],
  ['open', 'Open'],
  ['packed', 'Packed'],
  ['closed', 'Closed'],
];

export const PickupSheetsFilter = props => {
  const t = useTranslator();
  const list = useList(tables.pickupSheets.pickupSheets);
  const { classes } = useStyles();
  const statuses = useMemo(() => pickupSheetStatuses, []);

  const filters = {
    statuses: Array.isArray(list.filters.statuses) ? list.filters.statuses : [],
    printed: ['only', 'not', 'not-only'].includes(list.filters.printed) ? list.filters.printed : 'not-only',
  };

  const changeFilter = (property, value) => e => list.changeFilter(
    property,
    value === 'all' ? [] : [value]
  );

  return (
    <TuneBox
      width={280}
      {...props}
      PopoverProps={{ className: classes.popover }}
    >
      <Grid container direction="column">
        <Grid container className={classes.title}>
          {t.translate('Filter by status')}
        </Grid>
        {statuses.map(([key, text]) => (
          <CheckableMenuItem
            key={key}
            label={t.translate(text)}
            checked={filters.statuses[0] ? filters.statuses[0] === key : 'all' === key}
            onChange={changeFilter('statuses', key)}
            control={<Radio color="secondary" />}
          />
        ))}
      </Grid>
    </TuneBox>
  );
};
