import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from './MenuItem.jsx';
import { Grid, Typography } from '@mui/material';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';

export const MenuItem = ({
  description,
  chevron,
  active,
  title,
  ...props
}) => {
  const { classes } = useStyles();

  return (
    <Grid
      {...props}
      container
      direction="column"
      className={clsx(classes.root, active && classes.activeRoot)}
    >
      <Grid item>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">{description}</Typography>
      </Grid>
      {chevron && <ChevronRightIcon className={classes.chevron} />}
    </Grid>
  );
};

MenuItem.propTypes = {
  description: PropTypes.string.isRequired,
  chevron: PropTypes.bool,
  active: PropTypes.bool,
  title: PropTypes.string.isRequired,
};
