import React, { useEffect, useState } from 'react';
import { useTranslator } from 'i18n';
import { CircularLoader, Pagination } from 'components';
import {
  Table,
  TableContainer,
  TableBody,
  Dialog,
  TableRow,
  TableCell,
  TableHead,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from '@mui/material';
import { useTableSortableHeadCell } from '../../hooks';
import { fetchDaily } from '../../api/stock-operations';

export const ProductDailyStockChangesDialog = ({ onClose, supplierId, productId, ...props }) => {
  const t = useTranslator();
  const [dailyStats, setDailyStats] = useState([]);
  const [totalEntities, setTotalEntities] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 10,
    orderBy: 'createdAt',
    order: 'desc',
  });

  const paginationHandlers = {
    page: (event, value) => setPagination({ ...pagination, page: value }),
    rowsPerPage: (value) => setPagination({ ...pagination, rowsPerPage: value }),
    changeSorting: ({ orderBy, order }) => setPagination({ ...pagination, order, orderBy }),
  };

  const SortableCell = useTableSortableHeadCell({
    changeSorting: paginationHandlers.changeSorting,
    sorting: {
      orderBy: pagination.orderBy,
      order: pagination.order,
    },
  });

  useEffect(() => {
    fetchDaily({ supplierId, productId, ...pagination })
      .then(({ data, meta }) => {
        setDailyStats(data);
        setTotalEntities(meta.totalEntities);
        setIsLoading(false);
      });
  }, [pagination, supplierId, productId]);

  return (
    <Dialog
      {...props}
      id="daily-history-dialog"
      fullWidth={true}
      maxWidth={'sm'}
      onClose={onClose}
    >
      <DialogTitle id="max-width-dialog-title">
        {t.translate('Daily history')}
      </DialogTitle>
      <DialogContent>
        {isLoading
          ? <CircularLoader style={{ margin: 50 }} />
          : (<>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <SortableCell name="createdAt">{t.translate('Date')}</SortableCell>
                    <TableCell>{t.translate('Inbound')}</TableCell>
                    <TableCell>{t.translate('Sold')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dailyStats.map(({ date, inbound, sold }) => (
                    <React.Fragment key={date}>
                      <TableRow>
                        <TableCell>{t.formatDate(date)}</TableCell>
                        <TableCell>{Number(inbound) || ''}</TableCell>
                        <TableCell>{Number(sold) || ''}</TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              style={{ justifyContent: 'normal' }}
              onRowsPerPageChange={paginationHandlers.rowsPerPage}
              onPageChange={paginationHandlers.page}
              rowsPerPage={pagination.rowsPerPage}
              page={pagination.page}
              count={totalEntities}
            />
          </>)
        }
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
          disabled={isLoading}
        >
          {t.translate('dialog-action:Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
