import api from '.';

export const modifyPickupSheetProduct = (pickupSheetProductId, payload) => api.put(`/pickup-sheet-products/${pickupSheetProductId}`, payload);

export const packPickupSheetProduct = (pickupSheetProductId, payload = {}) => api.put(`/pickup-sheet-products/${pickupSheetProductId};packed`, payload);

export const handelProductATPM = (payload) => api.put(`/pickup-sheet-products;ATPM`, payload);

export default {
  modifyOne: modifyPickupSheetProduct,
  packOne: packPickupSheetProduct,
  handelProductATPM,
};
