import { createSelector } from 'reselect';

export const usersData = state => state.users.overview.data;

export const usersMeta = state => state.users.overview.meta;

export const selectDetailsUser = ({ users }) => users.details.user;

export const usersDataWithMeta = createSelector([
  usersData,
  usersMeta,
], (data, meta) => ({
  data,
  meta,
}));
