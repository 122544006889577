import { makeStyles } from 'tss-react/mui';
import { styleMobileTableItem } from 'theme/mixins/table';

export const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  active: {
    fontSize: '1.05rem',
  },
  bold: {
    fontWeight: 500,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  button: {
    backgroundColor: theme.palette.primary.l10,
    color: theme.palette.primary.main,
    fontSize: '0.75rem',
    textTransform: 'none',
  },
  item: {
    background: theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
  noteBox: {
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  headline: {
    background: theme.palette.backgrounds.main['50'],
    padding: theme.spacing(1, 2),
  },
}));
