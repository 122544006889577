import ordersApi from 'api/orders';
import {
  setActiveOrder,
  setStatuses,
  setOrders,
  setLoadingOrders,
} from 'store/actions/orders';
import {
  startLoading,
  stopLoading,
} from 'store/actions/app';
import { STUCK_ORDERS } from '../consts';

export const fetchStatuses = params => async dispatch => {
  try {
    const statuses = await ordersApi.fetchManyStatuses(params);
    dispatch(setStatuses(statuses));
  } catch (err) {}
};

export const fetchOrders = params => async dispatch => {
  try {
    if (params.status === STUCK_ORDERS) return;

    dispatch(setLoadingOrders(true));
    const orders = await ordersApi.fetchMany(params);
    dispatch(setOrders(orders));
    dispatch(setLoadingOrders(false));
  } catch (err) {
    dispatch(setLoadingOrders(false));
  }
};

export const fetchOrder = orderId => async dispatch => {
  try {
    dispatch(startLoading('orders.details.activeOrder'));
    const order = await ordersApi.fetchOne(orderId);
    dispatch(setActiveOrder(order));
    dispatch(stopLoading('orders.details.activeOrder'));
  } catch (err) {
    dispatch(stopLoading('orders.details.activeOrder'));
  }
};

/**
 * Makes order status text.
 *
 * @argument {object}          order
 * @property {number?}         order.pickupSheetId
 * @property {boolean?}        order.inReview
 * @property {string?}         order.status
 * @property {ReactTranslator} t
 * @returns  {string}
 */
export const textOrderStatus = (order, { t }) => {
  if (order.inReview) {
    return t.translate('status:in-review');
  } else if (order.status) {
    return t.translate(`status:${order.status}`);
  } else if (order.pickupSheetId) {
    return t.translate('In pickup');
  } else {
    return '—';
  }
};
