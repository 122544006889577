import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import { FormControlError } from 'components';

export const PriceControl = ({
  label,
  error,
  classNameError,
  ...props
}) => {
  return (
    <FormControl fullWidth error={!!error} className={classNameError}>
      {label && <InputLabel shrink htmlFor={props.id} variant="standard">{label}</InputLabel>}
      <Input
        {...props}
        inputProps={{ style: { textAlign: 'right' } }}
        endAdornment={<InputAdornment position="end"> €</InputAdornment>}
      />
      {!!error && <FormControlError error={error} />}
    </FormControl>
  );
};

PriceControl.propTypes = {
  label: PropTypes.string,
  error: PropTypes.any,
  id: PropTypes.string.isRequired,
};
