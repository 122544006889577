import React, { useEffect } from 'react';
import qs from 'qs';

import { useHistory } from 'react-router-dom';
import { useTranslator } from 'i18n';
import { useStyles } from './style.jsx';
import { useForm } from 'hooks';

import Grid from '@mui/material/Grid';
import { Auth } from 'layouts';
import {
  InputControl,
  BusyButton,
} from 'components';

import {
  checkPasswordRecoveryToken,
  recoveryPassword,
} from 'api/authentication';
import toaster from 'services/toaster';
import config from 'config';

const makeId = postfix => `recovering-password--${postfix}`;

export const PasswordRecovery = () => {
  const t = useTranslator();
  const { classes } = useStyles();
  const history = useHistory();
  const { token, email } = history.location.search
    ? qs.parse(history.location.search.slice(1))
    : {};

  const {
    catchHttpError,
    setFormValue,
    isModelValid,
    errors,
    model,
  } = useForm({
    passwordConfirm: '',
    password: '',
  });

  const validateModel = errors => {
    if (model.password !== model.passwordConfirm) {
      errors.passwordConfirm = t.translate('Passwords do not match.');
    }
  };

  const submit = async () => {
    if (!isModelValid(validateModel)) {
      return;
    }

    try {
      await recoveryPassword(token, email, model.password);
      toaster.success(t.translate('Password has been changed successfully.'));
      history.push(config.auth.paths.login);
    } catch (err) {
      catchHttpError(err);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await checkPasswordRecoveryToken(token, email);
      } catch (err) {}
    })();
  }, [token, email]);

  return (
    <>
      <Auth title={t.translate('Password recovery')}>
        <form action="#">
          <Grid container direction="column">
            <Grid item className={classes.passwordItem}>
              <InputControl
                id={makeId('password')}
                error={errors.password}
                label={t.translate('Password')}
                value={model.password}
                onChange={setFormValue('password')}
              />
            </Grid>
            <Grid item className={classes.passwordConfirmItem}>
              <InputControl
                id={makeId('password-confirm')}
                error={errors.passwordConfirm}
                label={t.translate('Confirm password')}
                value={model.passwordConfirm}
                onChange={setFormValue('passwordConfirm')}
              />
            </Grid>
            <Grid item className={classes.submitItem}>
              <BusyButton
                fullWidth
                submit
                color="secondary"
                variant="contained"
                onClick={submit}
              >
                {t.translate('Save')}
              </BusyButton>
            </Grid>
          </Grid>
        </form>
      </Auth>
    </>
  );
};
