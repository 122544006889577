import warehousesApi from 'api/warehouses';
import {
  setOverviewWarehouses,
  setDetailsWarehouse,
} from 'store/actions/warehouses';

export const fetchWarehouses = params => async dispatch => {
  try {
    const warehouses = await warehousesApi.fetchMany(params);
    dispatch(setOverviewWarehouses(warehouses));
  } catch (err) {}
};

export const fetchWarehousesForFilter = params => async dispatch => {
  try {
    const warehouses = await warehousesApi.fetchManyForFilter(params);
    dispatch(setOverviewWarehouses(warehouses));
  } catch (err) {}
};

export const fetchWarehouse = warehouseId => async dispatch => {
  try {
    const warehouse = await warehousesApi.fetchOne(warehouseId);
    dispatch(setDetailsWarehouse(warehouse));
  } catch (err) {}
};
