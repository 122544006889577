import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';
import { useRoute } from 'hooks';

import { makeStyles } from 'tss-react/mui';

import { Tabs } from 'components';
import { StorageTypes } from '../../../../consts';

const useStyles = makeStyles()(theme => ({
  appBar: {
    height: theme.spacing(7),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(6),
    },
    width: '100%',
    boxShadow: 'none',
  },
  tab: {
    height: theme.spacing(7),
    paddingLeft: theme.spacing(4.125),
    paddingRight: theme.spacing(4.125),
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(6),
    },
  },
  scroller: {
    '&.MuiTabs-fixed::before': {
      background: theme.palette.primary.main,
    },
  },
  indicator: {
    backgroundColor: theme.palette.primary.l50,
  },
}));

export const StorageTabs = React.memo((props) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const route = useRoute();

  const tabs = [{
    name: StorageTypes.DEFAULT,
    label: t.translate(`storageType:${StorageTypes.DEFAULT}`),
  }, {
    name: StorageTypes.TRANSIT,
    label: t.translate(`storageType:${StorageTypes.TRANSIT}`),
  }, {
    name: StorageTypes.TEMPORARY,
    label: t.translate(`storageType:${StorageTypes.TEMPORARY}`),
  }, {
    name: StorageTypes.EVALUATION,
    label: t.translate(`storageType:${StorageTypes.EVALUATION}`),
  }];

  return (
    <Tabs
      initialValue={route.search.type || StorageTypes.DEFAULT}
      items={tabs}
      appBar={{ className: classes.appBar, color: 'default' }}
      tabs={{
        variant: 'fullWidth',
        textColor: 'inherit',
        classes: {
          scroller: classes.scroller,
          indicator: classes.indicator,
        },
      }}
      tab={{ className: classes.tab }}
      {...props}
    />
  );
});

StorageTabs.propTypes = {
  onChange: PropTypes.func.isRequired,
};
