import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';

import { Picture } from 'components';
import { styleTableRow } from 'theme/mixins/table';

import numeral from 'numeral';

const useStyles = makeStyles()(theme => styleTableRow(theme, {
  defaultFontSize: {
    fontSize: '0.875rem',
  },
  buttonContainer: {
    width: theme.spacing(6),
  },
  button: {
    color: theme.palette.red.coral,
    borderColor: theme.palette.red.coral,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.backgrounds.red,
    },
  },
}));

const defaultAdditionalImage = (additionalImages = []) => (
  additionalImages?.find(image => image.isDefault)
);

const PackageProductCells = ({ pickupSheetProducts, canUnpackPickupSheet, packageProduct, classes }) => {
  const pickupSheetProduct = pickupSheetProducts?.find(product => product.id === Number(packageProduct.pickupSheetProductId));
  if (pickupSheetProduct) {
    return (
      <>
        <TableCell>
          <Picture
            height={54}
            width={54}
            image={defaultAdditionalImage(pickupSheetProduct.product.additionalImages) || pickupSheetProduct.product.photo || pickupSheetProduct.product.imageUrl}
          />
        </TableCell>
        <TableCell className={classes.defaultFontSize}>{pickupSheetProduct.supplierId || '—'}</TableCell>
        <TableCell className={classes.defaultFontSize}>{pickupSheetProduct.product.details[0]?.name || '—'}</TableCell>
        <TableCell className={classes.defaultFontSize}>{pickupSheetProduct.product.ean || '—'}</TableCell>
        <TableCell className={classes.defaultFontSize}>{packageProduct.quantity}</TableCell>
        <TableCell>{`${numeral(pickupSheetProduct.weight * packageProduct.quantity).format('0.00')} kg`}</TableCell>
        {canUnpackPickupSheet && (<TableCell />)}
      </>
    );
  } else {
    return null;
  }
};

export const PackageTable = ({
  canUnpackPickupSheet,
  pickupSheetProducts,
  pickupSheetPackage,
  onUnpackPackage,
}) => {
  const { classes } = useStyles();
  const t = useTranslator();

  return (
    <Paper>
      <TableContainer>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.defaultFontSize}>{t.translate('Product photo')}</TableCell>
              <TableCell className={classes.defaultFontSize}>{t.translate('Supplier ID')}</TableCell>
              <TableCell className={classes.defaultFontSize}>{t.translate('Product name')}</TableCell>
              <TableCell className={classes.defaultFontSize}>{t.translate('Product EAN code')}</TableCell>
              <TableCell className={classes.defaultFontSize}>{t.translate('Quantity')}</TableCell>
              <TableCell className={classes.defaultFontSize}>{t.translate('Weight')}</TableCell>
              {canUnpackPickupSheet && (
                <TableCell className={classes.buttonContainer}>
                  <Button
                    className={classes.button}
                    aria-label="unpack package"
                    variant="outlined"
                    size="small"
                    onClick={() => onUnpackPackage(pickupSheetPackage.id)}
                  >
                    {t.translate('Unpack')}
                  </Button>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {pickupSheetPackage.packageProducts?.map((packageProduct, index) => (
              <TableRow key={index}>
                <PackageProductCells
                  pickupSheetProducts={pickupSheetProducts}
                  packageProduct={packageProduct}
                  canUnpackPickupSheet={canUnpackPickupSheet}
                  classes={classes}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

PackageTable.propTypes = {
  canUnpackPickupSheet: PropTypes.bool.isRequired,
  onUnpackPackage: PropTypes.func.isRequired,
  pickupSheetPackage: PropTypes.shape({
    packageProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  pickupSheetProducts: PropTypes.arrayOf(
    PropTypes.shape({
      packedQuantity: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
      product: PropTypes.shape({
        description: PropTypes.string,
        photo: PropTypes.object,
        name: PropTypes.string,
        ean: PropTypes.string,
        alzuraId: PropTypes.string,
        imageUrl: PropTypes.string,
        additionalImages: PropTypes.array,
      }).isRequired,
    }).isRequired
  ).isRequired,
};
