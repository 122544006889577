import React, { memo, useState } from 'react';
import {
  Dialog,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  areEqual,
  FixedSizeList,
} from 'react-window';
import groupBy from 'lodash/groupBy';
import { makeStyles } from 'tss-react/mui';
import { useTranslator } from '../../i18n';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { DialogActions, DialogTitle, DialogContent, Input, Button } from '@mui/material';
import toaster from '../../services/toaster';
import { createPurchaseFromReplenishmentSuggestions } from '../../api/replenishment-suggestions';
import { PriceControl } from 'components';

const stockButtonSizeSpacing = 4.5;

const useStyles = makeStyles()(theme => ({
  table: {
    height: '100%',
    width: '100%',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    border: 'none',
    flexShrink: 0,
    minWidth: 0,
    overflow: 'hidden',
    flexBasis: '15%',
    '&:nth-of-type(1)': {
      flexBasis: '5%',
    },
    '&:nth-of-type(2)': {
      flexBasis: '15%',
    },
    '&:nth-of-type(3)': {
      flexBasis: '10%',
    },
    '&:nth-of-type(4)': {
      flexBasis: '15%',
    },
    '&:nth-of-type(5)': {
      flexBasis: '10%',
    },
    '&:nth-of-type(6)': {
      flexBasis: '15%',
    },
    '&:nth-of-type(7)': {
      flexBasis: '15%',
    },
    '&:nth-of-type(8)': {
      flexBasis: '15%',
    },
  },
  numberRow: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    backgroundColor: theme.palette.backgrounds.grey[240],
  },
  orderHeader: {
    width: '100%', fontWeight: 500,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    boxSizing: 'border-box',
    minWidth: '100%',
    width: '100%',
    boxShadow: '0 -1px 0 rgb(224, 224, 224)',
  },
  stockButton: {
    height: theme.spacing(stockButtonSizeSpacing),
    minHeight: theme.spacing(stockButtonSizeSpacing),
    minWidth: theme.spacing(stockButtonSizeSpacing),
    width: theme.spacing(stockButtonSizeSpacing),
    '& .MuiSvgIcon-root': {
      height: 12,
      width: 12,
    },
  },
  stockInput: {
    margin: theme.spacing(0, 1),
    width: 40,
    '& input': {
      textAlign: 'center',
    },
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const makeId = postfix => `create-purchases-dialog--${postfix}`;

const Row = memo(({ index, style, data }) => {
  const {
    supplierSuggestions,
    classes,
    cellProps,
    onChangeQuantity,
    onChangePurchasePrice,
  } = data;
  const suggestion = supplierSuggestions[index];
  return (
    <TableRow
      style={style}
      key={suggestion.id}
      component="div"
      className={classes.row}
    >
      <TableCell {...cellProps}>{suggestion.product.id}</TableCell>
      <TableCell {...cellProps}>{suggestion.product.alzuraId}</TableCell>
      <TableCell {...cellProps}>{suggestion.product.manufacturerNumber}</TableCell>
      <TableCell {...cellProps}>{suggestion.product.manufacturerName}</TableCell>
      <TableCell {...cellProps}>{suggestion.product.ean}</TableCell>
      <TableCell {...cellProps}><p className={classes.ellipsis}>{suggestion.product.details[0]?.name}</p></TableCell>
      <TableCell {...cellProps}>
        <PriceControl
          id={makeId('purchase-price', suggestion.id)}
          value={suggestion.newPurchasePrice}
          onChange={({ target: { value } }) => {
            onChangePurchasePrice(suggestion, value);
          }}
        />
      </TableCell>
      <TableCell {...cellProps}>
        <Button
          className={classes.stockButton}
          disabled={suggestion.newQuantity <= 1}
          variant="contained"
          color="secondary"
          onClick={() => onChangeQuantity(suggestion, null, '-')}
          aria-label="subtract stock"
        >
          <RemoveIcon />
        </Button>
        <Input
          className={classes.stockInput}
          value={suggestion.newQuantity}
          id={'stock'}
          onChange={(e) => onChangeQuantity(suggestion, isNaN(Number(e.target.value)) ? 0 : e.target.value)}
        />
        <Button
          className={classes.stockButton}
          variant="contained"
          color="secondary"
          onClick={() => onChangeQuantity(suggestion, null, '+')}
          aria-label="add stock"
        >
          <AddIcon />
        </Button>
      </TableCell>
    </TableRow>);
}, areEqual);

export const ReplenishmentSuggestionDialog = ({
  isMobile,
  onClose,
  selectedSuggestions,
  setSelectedSuggestions,
  onSuccess,
  scrollWidth,
  ...props
}) => {
  const { classes } = useStyles();
  const t = useTranslator();

  const [isLoading, setIsLoading] = useState(false);

  const cellProps = {
    component: 'div',
    className: classes.cell,
  };

  const onChangeQuantity = (targetPosition, value, action) => {
    const suggestionsList = [...selectedSuggestions];
    switch (action) {
      case '+':
        targetPosition.newQuantity++;
        break;
      case '-':
        targetPosition.newQuantity--;
        break;
      default:
        targetPosition.newQuantity = value;
        break;
    }

    setSelectedSuggestions([...suggestionsList]);
  };

  const onChangePurchasePrice = (targetPosition, value) => {
    const suggestionsList = [...selectedSuggestions];
    targetPosition.newPurchasePrice = value;

    setSelectedSuggestions([...suggestionsList]);
  };

  const groupedSuggestions = groupBy(selectedSuggestions, 'supplierId');

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      selectedSuggestions.forEach(position => {
        if (position.newQuantity < 1) {
          throw new Error('Purchase quantity must be positive.');
        }
        if (Number(position.newPurchasePrice) <= 0) {
          throw new Error('Purchase price must be positive.');
        }
      });

      const groupedSuggestions = groupBy(selectedSuggestions, 'supplierId');

      const payload = Object.keys(groupedSuggestions).map(key => ({
        supplierId: Number(key),
        positions: groupedSuggestions[key]
          .map(({ id, newQuantity, newPurchasePrice }) => ({
            id,
            quantity: newQuantity,
            purchasePrice: t.parsePrice(newPurchasePrice),
          })),
      }));

      await createPurchaseFromReplenishmentSuggestions({ suggestions: payload });

      toaster.success(t.translate('Purchases had been created successfully.'));

      setSelectedSuggestions([]);
      onSuccess();
      onClose();
    }
    catch (err) {
      toaster.error(err.message ?? Object.values(err.collection)[0][0]);
    }
    finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={props.open}
      fullWidth
      fullScreen={isMobile}
      scroll="body"
      maxWidth="lg"
      onClose={onClose}
    >
      <DialogTitle>
        {t.translate('Send replenishment request to partner')}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" className={classes.container}>
          <TableContainer>
            <Table className={classes.table} component="div">
              <TableHead component="div">
                <TableRow component="div" className={classes.row} style={{ paddingRight: `${scrollWidth}px` }}>
                  <TableCell {...cellProps}>{t.translate('Product ID')}</TableCell>
                  <TableCell {...cellProps}>{t.translate('Alzura ID')}</TableCell>
                  <TableCell {...cellProps}>{t.translate('Manufacturer no.')}</TableCell>
                  <TableCell {...cellProps}>{t.translate('Manufacturer name')}</TableCell>
                  <TableCell {...cellProps}>EAN</TableCell>
                  <TableCell {...cellProps}>{t.translate('Product name')}</TableCell>
                  <TableCell {...cellProps}>{t.translate('Purchase price')}</TableCell>
                  <TableCell {...cellProps}>{t.translate('Quantity')}</TableCell>
                </TableRow>
              </TableHead>
              {Object.keys(groupedSuggestions).map((supplierId, index) => {
                const supplierSuggestions = groupedSuggestions[supplierId];

                return (
                  <TableBody component="div" key={index}>
                    <TableRow component="div" className={classes.numberRow}>
                      <TableCell component="div" colSpan={10} align="center" className={classes.orderHeader}>
                        <div>{supplierSuggestions[0].supplier.number} - {supplierSuggestions[0].supplier.name}</div>
                      </TableCell>
                    </TableRow>
                    <FixedSizeList
                      style={{ scrollbarGutter: 'stable' }}
                      height={Math.min(59 * supplierSuggestions.length, 600)} // 59 - height of one row
                      itemSize={59}
                      itemCount={supplierSuggestions.length}
                      overscanCount={5}
                      itemData={{
                        supplierSuggestions,
                        classes,
                        cellProps,
                        onChangeQuantity,
                        onChangePurchasePrice,
                      }}
                    >
                      {Row}
                    </FixedSizeList>
                  </TableBody>
                );
              })}
            </Table>
          </TableContainer>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.button}>
        <Button color="primary" onClick={onClose}>
          {t.translate('dialog-action:Cancel')}
        </Button>
        <Button
          color="primary"
          onClick={onSubmit}
          disabled={isLoading}
        >
          {t.translate('Create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
