import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import { Alert, AlertTitle } from '@mui/material';

import { useTranslator } from 'i18n';

import { Column } from 'layouts';
import { CircularLoader, ItemHeadline } from 'components';

import { CustomerCard } from './CustomerCard/CustomerCard';
import { ProductsTable } from './ProductsTable/ProductsTable';
import { PackageTable } from './PackageTable/PackageTable';
import { NextPackageTable } from './NextPackageTable/NextPackageTable';

import numeral from 'numeral';

const normalise = (value, max) => ((value - 0) * 100) / (max - 0);

export const DesktopView = ({
  canEditPickupSheet,
  canPackPickupSheet,
  canUnpackPickupSheet,
  onPackPickupSheet,
  onPickupSheetProductEdit,
  onProductLocationShow,
  onProductDecreaseScanned,
  onUnpackPackage,
  pickupSheet,
  isLoading,
}) => {
  const t = useTranslator();

  return (
    <Column p={3} pt={2}>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <Grid container direction="column">
          {pickupSheet && (
            <>
              <Grid container spacing={3} style={{ flexWrap: 'wrap' }}>
                <Grid item xs={12} container direction="column">
                  <ItemHeadline text={t.translate('PickupSheet details')} />
                  <CustomerCard pickupSheet={pickupSheet}/>
                </Grid>
                {!!pickupSheet?.orders.length && pickupSheet?.orders?.[0].shippingNeutral && (
                  <Grid item xs={12} container direction="row" justifyContent="center">
                    <Alert variant="filled" severity="warning">
                      <AlertTitle>{t.translate('Neutral Shipping')}</AlertTitle>
                      {t.translate('This pickup sheet will be sent with neutral packaging')}
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12} container direction="column">
                  <ItemHeadline text={t.translate('PickupSheet products')}/>
                  <ProductsTable
                    onPickupSheetProductEdit={onPickupSheetProductEdit}
                    onProductLocationShow={onProductLocationShow}
                    canEditPickupSheet={canEditPickupSheet}
                    pickupSheet={pickupSheet}
                  />
                </Grid>
                {pickupSheet.status === 'open' && (
                  <Grid item xs={pickupSheet.pickupSheetPackages.length > 1 ? 6 : 12} container direction="column">
                    <ItemHeadline text={t.translate('Package')}/>
                    <NextPackageTable
                      onPackPickupSheet={onPackPickupSheet}
                      onProductDecreaseScanned={onProductDecreaseScanned}
                      canEditPickupSheet={canEditPickupSheet}
                      canPackPickupSheet={canPackPickupSheet}
                      pickupSheet={pickupSheet}
                    />
                  </Grid>
                )}
                {pickupSheet.pickupSheetPackages.reverse().map((pickupSheetPackage, index) => (
                  <Grid item xs={pickupSheet.pickupSheetPackages.length > 1 ? 6 : 12} container direction="column" key={index}>
                    <ItemHeadline
                      text={`${t.translate('Package')} ${pickupSheet.pickupSheetPackages.length - index} ${pickupSheetPackage?.packingLabelNumber ? `- ${pickupSheetPackage.packingLabelNumber}` : ''} ${pickupSheetPackage?.dpdReference ? `- ${pickupSheetPackage.dpdReference}` : ''}`}
                      secondaryText={`${t.translate('Weight')} ${numeral(pickupSheetPackage.weight).format('0.00') || 0} kg`}
                      secondaryComponent={
                        <LinearProgress
                          variant="determinate"
                          value={normalise(Number(pickupSheetPackage.weight) || 0, pickupSheet.maximumPackageWeight)}
                          style={{ width: '20%' }}
                        />
                      }
                    />
                    <PackageTable
                      pickupSheetProducts={pickupSheet.pickupSheetProducts}
                      pickupSheetPackage={pickupSheetPackage}
                      canUnpackPickupSheet={canUnpackPickupSheet}
                      onUnpackPackage={onUnpackPackage}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Column>
  );
};

DesktopView.propTypes = {
  onPickupSheetProductEdit: PropTypes.func.isRequired,
  onProductLocationShow: PropTypes.func.isRequired,
  onProductDecreaseScanned: PropTypes.func.isRequired,
  onPackPickupSheet: PropTypes.func.isRequired,
  canUnpackPickupSheet: PropTypes.bool.isRequired,
  onUnpackPackage: PropTypes.func.isRequired,
  canEditPickupSheet: PropTypes.bool.isRequired,
  canPackPickupSheet: PropTypes.bool.isRequired,
  pickupSheet: PropTypes.object,
  isLoading: PropTypes.bool,
};
