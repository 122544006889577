import React from 'react';
import PropTypes from 'prop-types';

import { CircularLoader } from 'components';
import { Column } from 'layouts';

import { RetailersTable } from './RetailersTable/RetailersTable';

export const DesktopView = ({
  isLoading,
  ...props
}) => (
  <Column filled>
    {isLoading
      ? <CircularLoader />
      : <RetailersTable {...props} />}
  </Column>
);

DesktopView.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};
