import React, { useState } from 'react';
import { useTranslator } from '../../../../i18n';
import InfoIcon from '@mui/icons-material/Info';
import { makeStyles } from 'tss-react/mui';
import { Typography, IconButton, Popover } from '@mui/material';

const useStyles = makeStyles()(() => ({
  popover: {
    pointerEvents: 'none',
  },
  hint: {
    maxWidth: 320,
    padding: 10,
  },
}));

export const Hint = ({ text }) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  return <>
    <IconButton
      aria-label="hint"
      aria-owns={!!anchorEl ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={e => setAnchorEl(e.currentTarget)}
      onMouseLeave={() => setAnchorEl(null)}
      onClick={e => setAnchorEl(e.currentTarget)}
      size="large">
      <InfoIcon/>
    </IconButton>
    <Popover
      id="mouse-over-popover"
      className={classes.popover}
      classes={{ paper: classes.paper }}
      open={!!anchorEl}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={() => setAnchorEl(null)}
      disableRestoreFocus
    >
      <Typography variant="body2" className={classes.hint}>
        {t.translate(text)}
      </Typography>
    </Popover>
  </>;
};
