import React from 'react';

import {
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Table,
} from '@mui/material';

import { useTranslator } from 'i18n';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';

const useStyles = makeStyles()(theme => ({
  fontBold: {
    fontWeight: 500,
  },
  cardItem: {
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: 70,
  },
}));

export const CollapsedRow = ({ changes }) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <TableContainer className={classes.container}>
      <Table className={classes.cardItem}>
        <TableHead>
          <TableRow>
            <TableCell width={300} className={classes.fontBold}>{`${t.translate('Field')}`}</TableCell>
            <TableCell width={200} className={classes.fontBold}>{`${t.translate('Previous value')}`}</TableCell>
            <TableCell className={classes.fontBold}>{`${t.translate('Value')}`}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {changes.map((position, index) => (
            <TableRow key={index}>
              <TableCell>{t.translate(_.capitalize(_.toLower(_.startCase(position.field))))}</TableCell>
              <TableCell>{`${position.previousValue}`}</TableCell>
              <TableCell>{`${position.value}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
