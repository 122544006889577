import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import clsx from 'clsx';

const useStyles = makeStyles()(theme => ({
  checkIcon: {
    color: theme.palette.green.main,
  },
  cancelIcon: {
    color: theme.palette.red.coral,
  },
  defaultIcon: {
    color: theme.palette.text.secondary,
  },
}));

export const BooleanIcon = ({ truthy, isDefault, className }) => {
  const { classes } = useStyles();

  return truthy
    ? <CheckCircleIcon className={clsx(classes.checkIcon, className && className)}/>
    : <CancelIcon className={isDefault ? clsx(classes.defaultIcon, className && className) : clsx(classes.cancelIcon, className && className)}/>;
};

BooleanIcon.propTypes = {
  truthy: PropTypes.bool,
  isDefault: PropTypes.bool,
  className: PropTypes.string,
};
