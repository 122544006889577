import React, { useState } from 'react';

import { makeStyles } from 'tss-react/mui';
import {
  IconButton,
  Typography,
  Grid,
} from '@mui/material';

import {
  VisibilityOff as VisibilityOffIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';

const useStyles = makeStyles()(theme => ({
  root: {
    alignItems: props => props.visible ? 'flex-start': 'center',
    display: 'flex',
    paddingLeft: theme.spacing(2),
  },
}));

export const PasswordText = ({ children, gridProps, ...props }) => {
  const [visible, setVisible] = useState(false);
  const { classes } = useStyles({ visible });

  return (
    <Grid className={classes.root} { ...gridProps }>
      <Typography
        variant="body2"
        color="textSecondary"
        {...props}
      >
        {visible ? children : (<>&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;</>)}
      </Typography>
      <IconButton
        aria-label="toggle visibility"
        onClick={() => setVisible(!visible)}
        size="large">
        {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </IconButton>
      </Grid>
  );
};
