import React from 'react';
import { SvgIcon } from '@mui/material';

export const QrCodeIcon = props => (
  <SvgIcon {...props} titleAccess="Qr code" viewBox="0 0 513.37 513.37">
    <g>
      <path d="m497.691 0h-120.8c-8.284 0-15 6.716-15 15s6.716 15 15 15h105.801v114.818c0 8.284 6.716 15 15 15s15-6.716 15-15v-129.818c-.001-8.284-6.716-15-15.001-15z" />
      <path d="m15.77 150.801c8.284 0 15-6.716 15-15v-105.801h114.818c8.284 0 15-6.716 15-15s-6.716-15-15-15h-129.818c-8.284 0-15 6.716-15 15v120.801c0 8.284 6.715 15 15 15z" />
      <path d="m136.479 483.37h-105.8v-114.818c0-8.284-6.716-15-15-15s-15 6.716-15 15v129.818c0 8.284 6.716 15 15 15h120.801c8.284 0 15-6.716 15-15s-6.716-15-15.001-15z" />
      <path d="m497.601 362.569c-8.284 0-15 6.716-15 15v105.801h-114.819c-8.284 0-15 6.716-15 15s6.716 15 15 15h129.818c8.284 0 15-6.716 15-15v-120.801c.001-8.284-6.715-15-14.999-15z" />
      <path d="m196.857 297.962h-121.227c-8.284 0-15 6.716-15 15v124.008c0 8.284 6.716 15 15 15h121.228c8.284 0 15-6.716 15-15v-124.008c-.001-8.284-6.716-15-15.001-15zm-15 124.008h-91.227v-94.008h91.228v94.008z" />
      <path d="m196.857 240.915h-32.166c-8.284 0-15 6.716-15 15s6.716 15 15 15h32.166c8.284 0 15-6.716 15-15s-6.715-15-15-15z" />
      <path d="m75.63 270.915h31.848c8.284 0 15-6.716 15-15s-6.716-15-15-15h-31.848c-8.284 0-15 6.716-15 15s6.716 15 15 15z" />
      <path d="m256.742 213.684c8.284 0 15-6.716 15-15v-124.008c0-8.284-6.716-15-15-15s-15 6.716-15 15v124.008c0 8.284 6.716 15 15 15z" />
      <path d="m453.074 436.97v-71.666c0-8.284-6.716-15-15-15h-73.677v-26.062c0-8.284-6.716-15-15-15h-77.655v-38.327h113.232v20.925c0 8.284 6.716 15 15 15s15-6.716 15-15v-20.925h23.1c8.284 0 15-6.716 15-15s-6.716-15-15-15h-181.332c-8.284 0-15 6.716-15 15v180.368c0 8.284 6.716 15 15 15s15-6.716 15-15v-37.81h24.283c8.284 0 15-6.716 15-15s-6.716-15-15-15h-24.283v-29.231h62.655v26.062c0 8.284 6.716 15 15 15h73.677v41.666h-112.912c-8.284 0-15 6.716-15 15s6.716 15 15 15h127.912c8.284 0 15-6.716 15-15z" />
      <path d="m196.857 59.676h-121.227c-8.284 0-15 6.716-15 15v124.008c0 8.284 6.716 15 15 15h121.228c8.284 0 15-6.716 15-15v-124.008c-.001-8.284-6.716-15-15.001-15zm-15 124.008h-91.227v-94.008h91.228v94.008z" />
      <path d="m316.848 213.684h121.227c8.284 0 15-6.716 15-15v-124.008c0-8.284-6.716-15-15-15h-121.227c-8.284 0-15 6.716-15 15v124.008c0 8.284 6.715 15 15 15zm15-124.008h91.227v94.008h-91.227z" />
      <path d="m124.692 394.927h23.104c4.397 0 7.962-3.565 7.962-7.962v-24c0-4.397-3.565-7.962-7.962-7.962h-23.104c-4.397 0-7.962 3.565-7.962 7.962v24c0 4.397 3.565 7.962 7.962 7.962z" />
      <path d="m124.692 156.641h23.104c4.397 0 7.962-3.565 7.962-7.962v-23.999c0-4.397-3.565-7.962-7.962-7.962h-23.104c-4.397 0-7.962 3.565-7.962 7.962v23.999c0 4.398 3.565 7.962 7.962 7.962z" />
      <path d="m389.013 117.359h-23.104c-4.397 0-7.962 3.565-7.962 7.962v23.999c0 4.397 3.565 7.962 7.962 7.962h23.104c4.397 0 7.962-3.565 7.962-7.962v-23.999c0-4.397-3.565-7.962-7.962-7.962z" />
    </g>
  </SvgIcon>
);
