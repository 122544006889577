import { PURCHASES } from 'store/types';
import { pack } from 'store/utils';

export const setPurchases = payload => dispatch => dispatch(pack(PURCHASES.OVERVIEW.SET_PURCHASES, payload));

export const setPurchase = payload => dispatch => dispatch(pack(PURCHASES.DETAILS.SET_PURCHASE, payload));

export const setPurchasePositions = payload => dispatch => dispatch(pack(PURCHASES.DETAILS.SET_PURCHASE_POSITIONS, payload));

export const setIsLoadingPurchases = payload => dispatch => dispatch(pack(PURCHASES.OVERVIEW.SET_IS_LOADING_PURCHASES, payload));
