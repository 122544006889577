import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';

import TableContainer from '@mui/material/TableContainer';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Grid from '@mui/material/Grid';
import { useStyles } from '../Tables';

import {
  TableCell,
  TableRow,
  Table,
} from 'components';

export const ProductFunnelTable = ({
  productFunnel,
}) => {
  const { classes } = useStyles();
  const t = useTranslator();

  const createRowCreator = t => (label, values, key) => ({ values, label: t.translate(label), key });

  const createRow = createRowCreator(t);

  const rows = [
    createRow('Total number of products', productFunnel, 'allActiveProduct'),
    createRow('Products with stock', productFunnel, 'productsWithStock'),
    createRow('Products with TecDoc / T24 data', productFunnel, 'productsFromApi'),
    createRow('Not sold in last 90 days', productFunnel, 'notSoldProductStatistics'),
  ];

  return (
    <TableContainer>
      <Table stickyHeader className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell fixed width={130} name="orders">
              <Grid className={classes.titleFixedColumn}>
                <AssessmentIcon />
                <Typography>{t.translate('Product funnel')}</Typography>
              </Grid>
            </TableCell>
            <TableCell width={50} className={classes.headerCell} name="received" align="right">
              {t.translate('Germany')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((item, index) => (
            <TableRow key={index} className={classes.tableRow}>
              <TableCell className={classes.fixedCell}>
                <Typography>
                  {item.label}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell} align="right" >{item.values[item.key]?.amount ?? '—'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ProductFunnelTable.propTypes = {
  allActiveProduct: PropTypes.shape({
    products: PropTypes.array,
    amount: PropTypes.number,
  }),
  productsWithStock: PropTypes.shape({
    products: PropTypes.array,
    amount: PropTypes.number,
  }),
  productsFromApi: PropTypes.shape({
    products: PropTypes.array,
    amount: PropTypes.number,
  }),
  productsWithout3DImages: PropTypes.shape({
    products: PropTypes.array,
    amount: PropTypes.number,
  }),
  notSoldProducts: PropTypes.shape({
    amount: PropTypes.number,
  }),
};
