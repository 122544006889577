import { REPLENISHMENT_SUGGESTIONS } from 'store/types';
import { replenishmentSuggestions as is } from 'store/initialState';

const setReplenishmentSuggestions = (state, { data, meta }) => ({
  ...state,
  overview: {
    ...state.overview,
    replenishmentSuggestions: {
      data,
      meta: {
        totalEntities: meta.totalEntities,
      },
    },
  },
});

export default (state = is, { type, payload }) => {
  switch (type) {
    case REPLENISHMENT_SUGGESTIONS.OVERVIEW.SET_REPLENISHMENT_SUGGESTIONS:
      return setReplenishmentSuggestions(state, payload);
    default:
      return state;
  }
};
