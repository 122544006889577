import api from '.';
import { extractListParams } from 'utils/querying';

export const fetchStorageProducts = ({
  storageProductId,
  productId,
  storageId,
  supplierId,
  orderCode,
  isSkipEmpty,
  manufacturingDate,
  isManufacturingDateAgnostic = false,
  ...params
}) => api.get('/storage-products', {
  params: {
    ...extractListParams(params),
    ...(productId && { productId }),
    ...(storageId && { storageId }),
    ...(supplierId && { supplierId }),
    ...(orderCode && { orderCode }),
    ...(isSkipEmpty && { isSkipEmpty }),
    ...(manufacturingDate && { manufacturingDate }),
    isManufacturingDateAgnostic,
  },
});

export const createStorageProduct = payload => api.post('/storage-products', payload);

export const fetchStorageProduct = storageProductId => api.get(`/storage-products/${storageProductId}`);

export const fetchOutdated = () => api.get(`/storage-products;outdated`);

export const modifyStorageProduct = (storageProductId, payload) => api.put(`/storage-products/${storageProductId}`, payload);

export const evaluateStock = (payload) => api.patch(`/storage-products;evaluate-stock`, payload);

export const fetchEvaluateStock = (orderPositionReturnId) => api.get(`/storage-products;evaluate-stock/${orderPositionReturnId}`);

export const fetchProductStorageLocation = (payload) => api.get(`/storage-products;storage-location`, {
  params: {
    ...payload,
  },
});

export const fetchStorageProductsLocations = ({
  productId,
  storageId,
  orderCode,
  ...params
}) => api.get('/storage-products;storages-locations', {
  params: {
    ...extractListParams(params),
    ...(productId && { productId }),
    ...(storageId && { storageId }),
    ...(orderCode && { orderCode }),
  },
});

export const reassignStock = ({
  sourceStorageId,
  destinationStorageId,
  productId,
  supplierId,
  manufacturingDate,
  stockAmount,
}) => api.patch('/storage-products;reassign-stock', null, {
  params: {
    sourceStorageId,
    destinationStorageId,
    ...(productId && { productId }),
    ...(supplierId && { supplierId }),
    ...(manufacturingDate && { manufacturingDate }),
    ...(stockAmount && { stockAmount }),
  },
});

export const correctStock = payload => {
  const { productId, supplierId, storageId, ...rest } = payload;

  return api.put('/storage-products', rest, {
    params: {
      productId,
      supplierId,
      storageId,
    },
  });
};

export default {
  fetchMany: fetchStorageProducts,
  modifyOne: modifyStorageProduct,
  createOne: createStorageProduct,
  fetchOne: fetchStorageProduct,
  fetchProductLocation: fetchProductStorageLocation,
  fetchStorageProductsLocations,
  reassignStock,
  correctStock,
  evaluateStock,
};
