import { createSelector } from 'reselect';

export const selectOrdersLoading = state => state.orders.loadingOrders;

export const selectOrdersData = state => state.orders.data;

export const selectOrdersMeta = state => state.orders.meta;

export const selectOrdersDataWithMeta = createSelector([
  selectOrdersData,
  selectOrdersMeta,
], (data, meta) => ({
  data,
  meta,
}));
