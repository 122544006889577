import {
  yellow,
  green,
  grey,
  blue,
  red,
} from '@mui/material/colors';

export const storageBoxColors = {
  black: grey[900],
  yellow: yellow[500],
  green: green[500],
  grey: grey[500],
  blue: blue[500],
  red: red[500],
};

export const colors = {
  ...storageBoxColors,
};
