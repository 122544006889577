import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  title: {
    paddingTop: `${theme.spacing(2.5)} !important`,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: `${theme.spacing(2.25)} !important`,
      paddingRight: `${theme.spacing(2.25)} !important`,
    },
  },
  content: {
    paddingTop: '0 !important',
    width: 400,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: `${theme.spacing(2.25)} !important`,
      paddingRight: `${theme.spacing(2.25)} !important`,
      width: 308,
    },
  },
  actions: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: `${theme.spacing(2.25)} !important`,
      paddingRight: `${theme.spacing(2.25)} !important`,
    },
  },
  emailItem: {
    paddingTop: theme.spacing(4),
  },
}));
