import React, { useState, useMemo } from 'react';

import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';

import { useRoute } from 'hooks/route';
import { goToPage } from 'utils/html';
import { findRelatedProductOrders } from 'utils/querying';
import { useTranslator } from 'i18n';

const useStyles = makeStyles()((theme) => ({
  expandLink: {
    fontSize: '0.875em',
  },
  listOrders: {
    color: theme.palette.secondary.main,
    fontSize: '0.875rem',
  },
}));

export const OrderLink = ({ pickupSheetProduct = {}, orders = [], showList }) => {
  const [showAll, setShowAll] = useState(false);
  const route = useRoute();
  const { classes } = useStyles();
  const t = useTranslator();

  const productOrders = findRelatedProductOrders(pickupSheetProduct, orders);

  const sortedOrders = useMemo(() =>
    productOrders?.sort((a,b) => new Date(b?.date) - new Date(a?.date)) || []
  , [productOrders]);

  const firstOrder = (
    <Link onClick={() => goToPage('orders', sortedOrders[0]?.id, route)}>
      {sortedOrders[0]?.number}
    </Link > || null
  );

  const links = sortedOrders?.map((order, index) => (
    <Link key={index} onClick={() => goToPage('orders', order?.id, route)}>
      {order?.number}
      {index !== sortedOrders?.length - 1 &&  ', '}
    </Link >
  )) || null;

  if (showList) {
    return sortedOrders?.map((order, index) => (
      <Link className={classes.listOrders} key={index} onClick={() => goToPage('orders', order?.id, route)}>
        {order?.number}
      </Link >
    ));
  }

  return (
    <>
      {!showAll && firstOrder}
      {showAll && links}
      {sortedOrders?.length > 1
      && (
        <Box my={0.5}>
          <Link className={classes.expandLink} onClick={(() => setShowAll(!showAll))}>
            {showAll ? `${t.translate('Hide')} <<` : `${t.translate('Show more')} >>`}
          </Link>
        </Box>
      )}
    </>
  );
};
