import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      '& .MuiPaper-root': {
        padding: theme.spacing(2),
      },
    },
    [theme.breakpoints.up('md')]: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: theme.spacing(84),
        minWidth: theme.spacing(84),
      },
    },
    '& .MuiFormControl-root': {
      marginTop: theme.spacing(2),
    },
    '& .MuiDialogContent-root': {
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('lg')]: {
      '& .MuiDialogContent-root': {
        padding: theme.spacing(1),
      },
    },
  },
  dialogTitle: {
    paddingTop: theme.spacing(3),
    paddingBottom: 0,
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1),
    },
  },
  textSecondary: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  bold: {
    fontWeight: 500,
    fontSize: theme.spacing(2.5),
  },
  backButton: {
    padding: 0,
    marginRight: theme.spacing(1.5),
  },
  moreButton: {
    minWidth: '195px',
    [theme.breakpoints.down('lg')]: {
      minWidth: '100%',
    },
  },
}));
