import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';
import { PriceControl } from 'components';
import { makeStyles } from 'tss-react/mui';
import { correctPosition } from 'api/purchases';
import { styleFormDialog } from 'theme/mixins/dialog';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import RemoveIcon from '@mui/icons-material/Remove';
import Input from '@mui/material/Input';
import AddIcon from '@mui/icons-material/Add';

import toaster from 'services/toaster';

const stockButtonSizeSpacing = 4.5;

const useStyles = makeStyles()((theme) =>
  styleFormDialog(theme, {
    content: {
      '&.MuiDialogContent-root': {
        width: 'auto',
        overflowY: 'initial',
        paddingBottom: theme.spacing(0.5),
        wordBreak: 'break-word',
      },
      '&.MuiDialogContent-root .MuiGrid-item': {
        paddingBottom: theme.spacing(1),
      },
      '&.MuiDialogContent-root .MuiGrid-item > p:first-child': {
        fontWeight: 500,
      },
    },
    button: {
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        right: theme.spacing(2),
        bottom: theme.spacing(2),
      },
    },
    stockButton: {
      height: theme.spacing(stockButtonSizeSpacing),
      minHeight: theme.spacing(stockButtonSizeSpacing),
      minWidth: theme.spacing(stockButtonSizeSpacing),
      width: theme.spacing(stockButtonSizeSpacing),
      '& .MuiSvgIcon-root': {
        height: 12,
        width: 12,
      },
    },
    stockInput: {
      margin: theme.spacing(0, 1),
      width: 40,
      '& input': {
        textAlign: 'center',
      },
    },
    minWidthColumn: {
      minWidth: 200,
    },
  }));

export const EditPurchasePositionDialog = ({
  onSuccess,
  onClose,
  isMobile,
  position: positionData,
  purchaseId,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const [dialogData, setDialogData] = useState({
    position: null,
  });

  useEffect(() => {
    if (positionData) {
      setDialogData({
        position: positionData,
      });
    }
  }, [positionData]);

  const onChangeQuantity = (targetPosition, value, action) => {
    const positionList = dialogData.position;
    switch (action) {
      case '+':
        targetPosition.quantity++;
        break;
      case '-':
        targetPosition.quantity--;
        break;
      default:
        targetPosition.quantity = value;
        break;
    }

    if (!!positionList && (targetPosition.quantity < targetPosition.deliveredQuantity)) {
      targetPosition.quantity = targetPosition.deliveredQuantity;
    }

    setDialogData({ position: positionList });
  };

  const onSubmit = async () => {
    try {
      if (dialogData.position.purchasePrice <= 0) {
        toaster.error({ content: t.translate('Purchase price must be positive.'), anchorOrigin: { vertical: 'bottom', horizontal: 'center' } });
        throw new Error('Purchase price must be positive.');
      }
      if (dialogData.position.quantity <= 0) {
        toaster.error({ content: t.translate('Purchase quantity must be positive.'), anchorOrigin: { vertical: 'bottom', horizontal: 'center' } });
        throw new Error('Purchase quantity must be positive.');
      }
      dialogData.position.purchasePrice = t.parsePrice(dialogData.position.purchasePrice);

      if (purchaseId && dialogData.position?.id) {
        await correctPosition(purchaseId, dialogData.position?.id, dialogData.position);
      }
      onSuccess && onSuccess();

      await onClose();
    } catch (err) { }
  };

  const open = Boolean(null);

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      scroll="body"
      maxWidth="xl"
      open={props.open}
      onClose={onClose}
    >
      <DialogTitle>{t.translate('Correction position')}</DialogTitle>
      <Grid container direction="column" justifyContent="space-between">
        <DialogContent className={classes.content}>
              {!!dialogData.position && (
                <>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell width={100}>{t.translate('Product ID')}</TableCell>
                          <TableCell className={classes.minWidthColumn}>{t.translate('Name')}</TableCell>
                          <TableCell className={classes.minWidthColumn}>{t.translate('Manufacturer no.')}</TableCell>
                          <TableCell className={classes.minWidthColumn}>{t.translate('Manufacturer')}</TableCell>
                          <TableCell width={200} align="center">{t.translate('EAN')}</TableCell>
                          <TableCell width={150} align="center">{t.translate('Purchase price')}</TableCell>
                          <TableCell width={200} align="center">{t.translate('Quantity')}</TableCell>
                          <TableCell width={120} align="center">{t.translate('Total net')}</TableCell>
                          <TableCell width={75} align="right">{t.translate('Actions')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                              <TableRow key={`position-${dialogData.position.id}`}>
                                <TableCell>{dialogData.position.productId}</TableCell>
                                <TableCell>{dialogData.position.productName || dialogData.position.product?.details[0]?.name || '—'}</TableCell>
                                <TableCell>{dialogData.position.product?.manufacturerNumber || '—'}</TableCell>
                                <TableCell>{dialogData.position.product?.manufacturerName || '—'}</TableCell>
                                <TableCell align="center">{dialogData.position.ean || '—'}</TableCell>
                                <TableCell align="center">
                                  <PriceControl
                                    id={`position-dialog--purchase-price-${dialogData.position.id}`}
                                    value={dialogData.position.purchasePrice}
                                    onChange={({ target: { value } }) => {
                                      const position = dialogData.position;
                                      position.purchasePrice = value;
                                      setDialogData({ ...dialogData, position });
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    className={classes.stockButton}
                                    disabled={dialogData.position.quantity <= 0 || (dialogData.position.quantity === dialogData.position.deliveredQuantity)}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => onChangeQuantity(dialogData.position, null, '-')}
                                    aria-label="subtract stock"
                                  >
                                    <RemoveIcon/>
                                  </Button>
                                  <Input
                                    className={classes.stockInput}
                                    value={dialogData.position.quantity}
                                    onChange={(e) => onChangeQuantity(dialogData.position, isNaN(Number(e.target.value)) ? 0 : e.target.value)}
                                  />
                                  <Button
                                    className={classes.stockButton}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => onChangeQuantity(dialogData.position, null, '+')}
                                    aria-label="add stock"
                                  >
                                    <AddIcon/>
                                  </Button>
                                </TableCell>
                                <TableCell align="right">
                                  {t.formatPrice(t.parsePrice(dialogData.position.purchasePrice) * dialogData.position.quantity)}&nbsp;&euro;
                                </TableCell>
                              </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
        </DialogContent>
        <DialogActions className={classes.button}>
            <Button color="primary" onClick={() => onClose()}>
              {t.translate('dialog-action:Cancel')}
            </Button>
            <Button
              color="primary"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={() => onSubmit()}
            >
              {t.translate('Save')}
            </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

EditPurchasePositionDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
