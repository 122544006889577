import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';

import {
    BooleanIcon,
    BusyButton,
} from 'components';

import { createOneWarehouseInventoryReport } from 'api/reports';
import { styleFormDialog } from 'theme/mixins/dialog';
import { download } from 'services/files';
import toaster from 'services/toaster';

const useStyles = makeStyles()(theme => styleFormDialog(theme, {
  content: {
    '&.MuiDialogContent-root': {
      width: 252,
    },
  },
}));

export const CreatingWarehouseInventoryDialog = ({
  warehouses,
  onClose,
  onSave,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  const submit = async () => {
    try {
      const warehouseId = selectedWarehouse.id;
      const document = await createOneWarehouseInventoryReport({ warehouseId });
      toaster.success(t.translate('Statistics report has been saved successfully.'));
      download(document);

      onSave();
      onClose();
    } catch (err) {}
  };

  return (
    <Dialog {...props}>
      <DialogTitle className={classes.title}>{t.translate('Select warehouse')}</DialogTitle>
      <DialogContent className={classes.content}>
        <List>
          {warehouses.map(warehouse => (
            <ListItem
              className={classes.listItem}
              key={warehouse.id}
              button
              onClick={() => setSelectedWarehouse(warehouse.id === selectedWarehouse?.id ? null : warehouse)}
            >
              <Grid container spacing={2}>
                <Grid item className={classes.idItem}>
                  <Typography>{warehouse.id}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography>{warehouse.name}</Typography>
                </Grid>
                {warehouse.id === selectedWarehouse?.id && (
                  <Grid item>
                    <BooleanIcon truthy />
                  </Grid>
                )}
              </Grid>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <BusyButton onClick={onClose}>{t.translate('Close')}</BusyButton>
        <BusyButton disabled={!selectedWarehouse} onClick={submit}>{t.translate('Confirm')}</BusyButton>
      </DialogActions>
    </Dialog>
  );
};

CreatingWarehouseInventoryDialog.propTypes = {
  warehouses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
