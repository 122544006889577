import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  AppHeaderPrimaryToolbar,
  AppHeaderBackButton,
  AppHeaderRightBox,
  AppHeaderTitle,
  AppHeader,
} from 'components';

import { StatusBar } from './StatusBar/StatusBar';
import { MobileControls } from './MobileControls/MobileControls';
import { DesktopControls } from './DesktopControls/DesktopControls';

export const Header = ({
  pickupSheet,
  packingDialog,
  fetchData,
  isMobile,
  onNextStepScan,
  ...props
}) => {
  const history = useHistory();

  return (
    <AppHeader>
      <AppHeaderPrimaryToolbar>
        <AppHeaderBackButton edge="start" onClick={() => history.goBack()} />
        <AppHeaderTitle>PUS: {pickupSheet?.id}</AppHeaderTitle>
        <AppHeaderRightBox alignItems="center">
          <StatusBar status={pickupSheet?.status} isMobile={isMobile} />
          {isMobile ? (
            <MobileControls {...props} pickupSheet={pickupSheet} fetchData={fetchData} />
          ) : (
            <DesktopControls {...props} pickupSheet={pickupSheet} packingDialog={packingDialog} fetchData={fetchData} onNextStepScan={onNextStepScan}/>
          )}
        </AppHeaderRightBox>
      </AppHeaderPrimaryToolbar>
    </AppHeader>
  );
};

Header.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  pickupSheet: PropTypes.object,
};
