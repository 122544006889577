import React from 'react';
import { Paper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { styleMobileTableItem } from '../../../../../theme/mixins/table';
import { useTranslator } from '../../../../../i18n';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  root: {
    padding: theme.spacing(2),
  },
  featureLabel: {
    fontWeight: 500,
  },
}));

export const StatusHistory = ({ statusHistory }) => {
  const { classes } = useStyles();
  const t = useTranslator();

  return (
    <Paper className={classes.root}>
      <Grid container direction="column" spacing={2}>
        {statusHistory.map( (item, index) => {
          if (item.params) {
            Object.keys(item.params).forEach(param => {
              if (!Number(item.params[param])) item.params[param] = t.translate(item.params[param]);
            });
          }
          return (
            <Grid item container key={`${index}-history`}>
              <Grid item>
                <Typography variant="body2">
                  {`${t.formatDate(item.updatedAt)} ${t.formatTime(item.updatedAt)}`} - {`${item.user?.name || t.translate('System')}: ${t.translate(item.status, item.params)}`}
                </Typography>
              </Grid>
            </Grid>
          );
        }
        )}
      </Grid>
    </Paper>
  );
};
