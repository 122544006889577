import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { styleMobileTableItem } from '../../../../../theme/mixins/table';
import { useTranslator } from '../../../../../i18n';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  container: {
    padding: theme.spacing(2),
  },
  featureLabel: {
    fontWeight: 500,
  },
  returnPosition: {
    paddingTop: '5px',
  },
  total: {
    paddingTop: '5px',
  },
  returnDate: {
    textAlign: 'center',
    backgroundColor: '#f5f5f5',
    padding: '10px 0',
  },
}));

export const ReturnFees = ({ returns }) => {
  const { classes, cx } = useStyles();
  const t = useTranslator();

  const totalFees = returns.reduce((acc, orderReturn) =>  acc + orderReturn.totalFee, 0);

  return (
    <Paper>
      <Grid container direction="column">
        {returns.map((orderReturn, index) => (
          <React.Fragment key={`return-fee-${index}`}>
            <Grid item container key={`${index}-fees`} direction="column">
              <div className={classes.returnDate}>{t.formatDate(orderReturn.createdAt)} {t.formatTime(orderReturn.createdAt)}</div>
              <Grid item container direction="column" className={classes.container}>
              {orderReturn.positions.map((position, i) => (
                <React.Fragment key={`${index}-${i}-fees`}>
                  <Grid item container className={classes.returnPosition}>
                    <Grid item xs={4}>
                      <Typography variant="body2" className={classes.featureLabel}>{`${t.translate('Product-ID')}`}</Typography>
                      <Typography variant="body2" className={classes.featureLabel}>{`${t.translate('Quantity')}`}</Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="body2" align="right">{`${position.orderPosition.productId}`}</Typography>
                      <Typography variant="body2" align="right">{`${position.quantity}`}</Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </React.Fragment>
              ))}
              </Grid>
              <Grid item container className={classes.container} style={{ paddingTop: 0 }}>
                <Grid item xs={4}>
                  <Typography variant="body2" className={classes.featureLabel}>{`${t.translate('Return shipment')}`}</Typography>
                  <Typography variant="body2" className={classes.featureLabel}>{`${t.translate('Fixed fee')}`}</Typography>
                  <Typography variant="body2" className={classes.featureLabel}>{`${t.translate('Variable fee')}`}</Typography>
                  <Typography variant="body2" className={classes.featureLabel}>{`${t.translate('Additional fee')}`}</Typography>
                  <Typography variant="body2" className={cx(classes.featureLabel, classes.total)}>{`${t.translate('Total')}`}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="body2" align="right">{orderReturn.returnShipment ? t.translate(`returnShipment:${orderReturn.returnShipment}`) : '-'}</Typography>
                  <Typography variant="body2" align="right">{`${t.formatPrice(orderReturn.fixedFee)}`} &euro;</Typography>
                  <Typography variant="body2" align="right">{`${t.formatPrice(orderReturn.variableFee)}`} &euro;</Typography>
                  <Typography variant="body2" align="right">{`${t.formatPrice(orderReturn.additionalFee)}`} &euro;</Typography>
                  <Typography variant="body2" align="right" className={classes.total}>{`${t.formatPrice(orderReturn.totalFee)}`} &euro;</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
          </React.Fragment>
        ))}
        <Divider />
        <Grid item container className={classes.container}>
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.featureLabel}>{`${t.translate('Total fees')}`}</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2" align="right">{`${t.formatPrice(totalFees)}`} &euro;</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

ReturnFees.propTypes = {
  positions: PropTypes.arrayOf(PropTypes.shape({
    orderPositionReturns: PropTypes.array,
  })),
};
