import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { TrackingServiceSpriteIcon } from '../TrackingServiceSpriteIcon/TrackingServiceSpriteIcon';

const services = {
  'GLS': 'https://www.gls-pakete.de/sendungsverfolgung?match=',
  'UPS': 'https://wwwapps.ups.com/WebTracking/processInputRequest?sort_by=status&tracknums_displayed=1&TypeOfInquiryNumber=T&loc=de_DE&InquiryNumber1=',
  'DPD': 'https://tracking.dpd.de/status/de_DE/parcel/',
  'DHL': 'http://nolp.dhl.de/nextt-online-public/set_identcodes.do?lang=de&idc=',
  'FedEx': 'https://www.fedex.com/apps/fedextrack/?tracknumbers=',
};

const getParcelUrl = (service, number) => service === 'FedEx' ? `${services[service]}${number}&cntry_code=nl` : `${services[service]}${number}`;

const useStyles = makeStyles()(theme => ({
  button: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.l10,
    borderRadius: theme.spacing(0.5),
    color: theme.palette.primary.main,
    display: 'flex',
    textTransform: 'none',
    '& svg': {
      borderRadius: theme.spacing(0.5),
      fontSize: '1rem',
      padding: theme.spacing(0.5),
    },
    '& p': {
      fontSize: '0.625rem',
      padding: theme.spacing(0, 0.75),
    },
  },
  expandLink: {
    fontSize: '0.875em',
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  DeutschePostColor: {
    backgroundColor: theme.palette.orange['255'],
  },
  DHLColor: {
    backgroundColor: theme.palette.orange['250'],
  },
  HermesColor: {
    backgroundColor: theme.palette.cyan.main,
  },
  DPDColor: {
    backgroundColor: theme.palette.red.coral,
  },
  GLSColor: {
    backgroundColor: theme.palette.secondary['20'],
  },
  UPSColor: {
    backgroundColor: theme.palette.brown.main,
  },
  FedExColor: {
    backgroundColor: theme.palette.purple.main,
  },
  NOXColor: {
    backgroundColor: theme.palette.common.black,
  },
}));

export const TrackingLink = ({
  tracking = [],
  direction,
  LinkWrapper,
  button = false,
}) => {
  const [showAll, setShowAll] = useState(false);
  const { classes } = useStyles();
  const t = useTranslator();

  const firstLink = tracking[0]?.number ? (
    <Link
      target="_blank"
      href={getParcelUrl(tracking[0]?.service, tracking[0]?.number)}
      rel="noopener"
    >
      {tracking[0]?.number}
    </Link >
  ) : null;

  const links = tracking?.map(({ number, service }, index) => (
    <Link
      target="_blank"
      href={getParcelUrl(service, number)}
      rel="noopener"
      key={number}
    >
      {number}
      {index !== tracking?.length - 1 &&  ', '}
    </Link >
  ));

  const toggler = (
    <Link className={classes.expandLink} onClick={(() => setShowAll(!showAll))}>
      {showAll ? `${t.translate('Hide')} <<` : `${t.translate('Show more')} >>`}
    </Link>
  );

  if (button) {
    return tracking?.map(({ number, service }, index) => (
      <Grid key={index}>
        <Link
          className={classes.link}
          target="_blank"
          href={getParcelUrl(service, number)}
          rel="noopener"
        >
          <Grid className={classes.button}>
            <TrackingServiceSpriteIcon id={service} className={classes[`${service}Color`]}/>
            <Typography variant="body2">{number}</Typography>
          </Grid>
        </Link>
      </Grid>
    ));
  }

  return (
    <Grid container direction={direction || 'column'}>
      {!showAll && (LinkWrapper ? <LinkWrapper>{firstLink}</LinkWrapper> : firstLink)}
      {showAll && (LinkWrapper ? links.map(link => <LinkWrapper>{link}</LinkWrapper>) : links)}
      {tracking?.length > 1
      && (
        <Box my={0.5} className={classes.box}>
          {LinkWrapper ? <LinkWrapper>{toggler}</LinkWrapper> : toggler}
        </Box>
      )}
    </Grid>
  );
};

TrackingLink.propTypes = {
  tracking: PropTypes.arrayOf(PropTypes.shape({
    number: PropTypes.string.isRequired,
    service: PropTypes.string.isRequired,
  })).isRequired,
  direction: PropTypes.string,
  button: PropTypes.bool,
};
