import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    background: theme.palette.primary.l25,
    boxSizing: 'border-box',
    position: 'relative',
    transition: theme.transitions.create(['height', 'padding-right'], {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  thumbnailRoot: {
    height: theme.spacing(8.75),
    paddingRight: theme.spacing(2),
    transition: theme.transitions.create(['height', 'padding-right'], {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.sharp,
    }),
  },
  nameBox: {
    left: theme.spacing(3.5),
    position: 'absolute',
    top: theme.spacing(7),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  logoBox: {
    height: '94px',
    overflow: 'hidden',
    position: 'relative',
    transition: theme.transitions.create('height', {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.sharp,
    }),
    width: '304px',
  },
  thumbnailLogoBox: {
    height: theme.spacing(8.75),
    overflow: 'hidden',
    transition: theme.transitions.create('height', {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.sharp,
    }),
    width: 'initial',
  },
  logo: {
    height: 'initial',
    left: theme.spacing(3.5),
    position: 'absolute',
    top: theme.spacing(2.5),
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
    width: '55%',
  },
  logoBackground: {
    height: '100%',
    left: 0,
    paddingLeft: theme.spacing(2),
    position: 'absolute',
    top: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
    width: '100%',
  },
  thumbnailLogo: {
    left: theme.spacing(2),
    top: theme.spacing(2.25),
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.sharp,
    }),
    width: theme.spacing(28.5),
  },
}));
