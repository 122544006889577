import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';

import { useTranslator } from 'i18n';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { ColorCircle, ColorBox } from 'components';
import { colors } from 'config/colors';

export const Color = ({ color = 'black', box, ...props }) => {
  const t = useTranslator();

  return (
    <Box component="span" {...props}>
      {box ? (
        <ColorBox color={colors[color]} mb={-.125} mr={.5} />
      ) : (
        <ColorCircle color={colors[color]} mb={-.125} mr={.5} />
      )}
      <Typography component="span" variant="body2">{t.translate(capitalize(color === 'black' ? 'no color' : color))}</Typography>
    </Box>
  );
};

Color.propTypes = {
  color: PropTypes.string.isRequired,
};
