import api from '.';

export const fetchFTPExportingStockOptions = () => api.get('/options/ftp-exporting-stock');
export const modifyFTPExportingStockOptions = payload => api.put('/options/ftp-exporting-stock', payload);

export const fetchProductsOptions = () => api.get('/options/products');
export const modifyProductsOptions = payload => api.put('/options/products', payload);

export const fetchReplenishmentSuggestionsOptions = () => api.get('/options/replenishment-suggestions');
export const modifyReplenishmentSuggestionsOptions = payload => api.put('/options/replenishment-suggestions', payload);

export default {
  modifyFTPExportingStockOptions,
  fetchFTPExportingStockOptions,
  modifyProductsOptions,
  fetchProductsOptions,
  fetchReplenishmentSuggestionsOptions,
  modifyReplenishmentSuggestionsOptions,
};
