import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  container: {
    flexWrap: 'wrap',
  },
  photoItem: {
    display: 'flex',
  },
  photo: {
    flexShrink: 0,
    height: theme.spacing(15),
    marginRight: theme.spacing(2),
    width: theme.spacing(15),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  booleanItem: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  buttons: {
    margin: theme.spacing(-1.5, -1.5, 0, 0),
  },
  name: {
    fontWeight: 'bold',
  },
  productValue: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  priceAlign: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'initial',
    },
  },
  disabledWrap: {
    whiteSpace: 'nowrap',
  },
  xsJustify: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'initial',
    },
  },
  centerValue: {
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'initial',
    },
  },
  carousel: {
    overflow: 'initial',
  },
}));
