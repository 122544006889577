import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import { useTranslator } from 'i18n';

import { OrdersOverviewCard } from './OrdersOverviewCard/OrdersOverviewCard';
import { CustomerCard } from '../common/CustomerCard/CustomerCard';
import { OrdersTable } from './OrdersTable/OrdersTable';
import { CircularLoader, ItemHeadline } from 'components';
import { Header } from '../common';
import { Column } from 'layouts';

export const DesktopView = ({
  loading,
  customer,
  canExportCustomer,
  onExport,
  canEditCustomer,
  onCustomerEdit,
}) => {
  const t = useTranslator();

  const openOrders = customer?.orders.filter(order => order.status !== 'denied' && order.status !== 'closed' && order.status !== 'completed').length;
  const closedOrders = customer?.orders.filter(order => order.status === 'closed' || order.status === 'completed').length;

  return (
    <>
      <Header
        customer={customer}
        canExportCustomer={canExportCustomer}
        onExport={onExport}
        canEditCustomer={canEditCustomer}
        onCustomerEdit={onCustomerEdit}
      />
      <Column p={3}>
        {loading ? (
          <CircularLoader />
        ) : (
          <Grid container direction="column">
            {customer && (
              <>
                <Grid container spacing={3} style={{ flexWrap: 'wrap' }}>
                  <Grid item xs={12} md={6} container direction="column">
                    <ItemHeadline text={t.translate('Customer information')} />
                    <CustomerCard customer={customer} />
                  </Grid>
                  <Grid item xs={12} md={6} container direction="column">
                    <ItemHeadline text={t.translate('Order overview')} />
                    <OrdersOverviewCard
                      openOrders={openOrders}
                      closedOrders={closedOrders}
                      ordersTotalValueNet={customer.ordersTotalValueNet}
                      ordersTotalValueGross={customer.ordersTotalValueGross}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <OrdersTable customer={customer} />
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Column>
    </>
  );
};

DesktopView.propTypes = {
  loading: PropTypes.bool.isRequired,
  customer: PropTypes.shape({
    address: PropTypes.shape({
      data: PropTypes.shape({
        city: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        street: PropTypes.string.isRequired,
        house: PropTypes.string,
        zip: PropTypes.string.isRequired,
      }),
    }),
    invoiceWithoutVat: PropTypes.bool.isRequired,
    number: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    company: PropTypes.string,
    email: PropTypes.string,
    ordersCount: PropTypes.number,
    ordersTotalValueNet: PropTypes.number,
    ordersTotalValueGross: PropTypes.number,
    id: PropTypes.number.isRequired,
  }),
  canExportCustomer: PropTypes.bool.isRequired,
  onExport: PropTypes.func.isRequired,
  canEditCustomer: PropTypes.bool.isRequired,
  onCustomerEdit: PropTypes.func.isRequired,
};
