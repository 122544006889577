import api from '.';
import { extractListParams } from 'utils/querying';

export const fetchPickupSheets = ({
  statuses,
  printed,
  ...params
}) => api.get('/pickup-sheets', {
  params: {
    ...extractListParams(params),
    ...(statuses && { statuses }),
    ...(printed && printed !== 'not-only' && {
      printed: Number(printed === 'only'),
    }),
  },
});

export const printPickupSheets = () => api.get('/pickup-sheets;printed');

export const createPickupSheet = (isCreateMany = false) => api.post('/pickup-sheets', null, { params: { isCreateMany } });

export const createPickupSheetTransit = payload => api.post('/pickup-sheets;skip-transit-storage', payload);

export const createPickupSheetForAdditionalShipment = payload => api.post('/pickup-sheets;additional-shipment', payload);

export const createTicketForTransitStorage = payload => api.post('/pickup-sheets;print-transit-storage-ticket', payload);

export const fetchPickupSheet = pickupSheetId => api.get(`/pickup-sheets/${pickupSheetId}`);

export const deletePickupSheet = pickupSheetId => api.delete(`/pickup-sheets/${pickupSheetId}`);

export const printPickupSheet = pickupSheetId => api.get(`/pickup-sheets/${pickupSheetId};printed`);

export const labelPickupSheet = pickupSheetId => api.put(`/pickup-sheets/${pickupSheetId};labelled`);

export const closePickupSheet = pickupSheetId => api.put(`/pickup-sheets/${pickupSheetId};closed`);

export const packPickupSheet = (pickupSheetId, payload) => api.put(`/pickup-sheets/${pickupSheetId};packed`, payload);

export const unpackPickupSheet = payload => api.put(`/pickup-sheets;unpackPackage`, payload);

export default {
  createTicketForTransitStorage,
  printMany: printPickupSheets,
  fetchMany: fetchPickupSheets,
  deleteOne: deletePickupSheet,
  createOne: createPickupSheet,
  unpackOne: unpackPickupSheet,
  printOne: printPickupSheet,
  labelOne: labelPickupSheet,
  fetchOne: fetchPickupSheet,
  closeOne: closePickupSheet,
  packOne: packPickupSheet,
  createPickupSheetTransit,
  createPickupSheetForAdditionalShipment,
};
