import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslator } from 'i18n';
import { Header, DesktopView } from 'views/suppliers/settings';
import { fetchSupplier } from 'services/suppliers';
import { didMount, useScreen } from 'hooks';
import { editTabsOptions, editRoutes, createRoutes, createTabsOptions } from './config';
import { selectDetailsSupplier } from 'store/selectors/suppliers';
import marketplacesApi from 'api/marketplaces';
import { exportSupplier } from 'api/suppliers';
import toaster from 'services/toaster';
import { download } from 'services/files';
import { selectActionPermission, selectAuthenticatedUser } from 'store/selectors/account';
import ErrorIcon from '@mui/icons-material/Error';
import { tabHasError } from '../helpers';
import socket, { SOCKET_EVENTS } from '../../../bootstrap/socket';

export const SupplierPage = () => {
  const t = useTranslator();
  const history = useHistory();
  const screen = useScreen();

  const dispatch = useDispatch();
  const params = useParams();
  const supplier = useSelector(selectDetailsSupplier);
  const canEditSupplierPermission = useSelector(selectActionPermission('suppliers', 'edit'));
  const [activeMarketplaces, setActiveMarketplaces] = useState(null);
  const [tabs, setTabs] = useState(params.supplierId ? editTabsOptions(params.supplierId) : createTabsOptions);
  const [formState, setFormState] = useState({
    onSave: () => {},
    isEditMode: !params.supplierId,
    errors: null,
  });
  const activeTab = tabs.find(({ name }) => history.location.pathname.includes(name))?.name;
  const [routes, setRoutes] = useState(params.supplierId ? editRoutes(params.supplierId) : createRoutes);
  const callFetchingSupplier = useCallback((id) => dispatch(fetchSupplier(id)), [dispatch]);
  const authenticatedUser = useSelector(selectAuthenticatedUser);

  const isEditedByAnotherUser = supplier?.editedByUserId ? supplier.editedByUserId !== authenticatedUser?.id : false;
  const canEditSupplier = canEditSupplierPermission && !isEditedByAnotherUser;

  useEffect(() => {
    if (params.supplierId && Number(params.supplierId) !== supplier?.id) {
      callFetchingSupplier(params.supplierId);
    }
  }, [callFetchingSupplier, params.supplierId, supplier]);

  let activeRoute = !screen.isMobile ? (
    routes.find(({ homePath }) => homePath === history.location.pathname)
    || routes[0]
  ) : routes.find(({ homePath }) => homePath === history.location.pathname);

  const handleExportSupplier = async () => {
    const file = await exportSupplier(supplier.id);

    if (file) {
      toaster.success(t.translate('The supplier has been successfully exported.'));

      download(file);
    }
  };

  didMount(() => {
    socket.on(SOCKET_EVENTS.SUPPLIERS.EDITED_BY, async ({ entityId }) => {
      if (entityId === Number(params.supplierId)) dispatch(fetchSupplier(entityId));
    });
  });

  useEffect(() => {
    return () => {
      socket.emit(SOCKET_EVENTS.SUPPLIERS.EDITED_BY, { entityId: Number(params.supplierId), isEdited: false });
      socket.off(SOCKET_EVENTS.SUPPLIERS.EDITED_BY);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!routes.find(({ homePath }) => history.location.pathname === homePath)) {
      history.replace(activeRoute.homePath);
    }
  }, [activeRoute, history, routes]);

  useEffect(() => {
    setRoutes(params.supplierId ? editRoutes(params.supplierId) : createRoutes);
  }, [params.supplierId]);

  useEffect(() => {
    (async () => {
      const { data: marketplaces } = await marketplacesApi.fetchMarketplaces();

      setActiveMarketplaces(marketplaces.filter(marketplace => marketplace.isActive));
    })();
  }, []);

  useEffect(() => {
    const { errors, isEditMode } = formState;
    setTabs((tabs) => tabs.map((tab) => ({
      ...tab,
      Icon: tabHasError(tab, errors) ? <ErrorIcon style={{ color: '#f44336' }}/> : undefined,
    })));

    if (supplier) {
      socket.emit(SOCKET_EVENTS.SUPPLIERS.EDITED_BY, { entityId: supplier.id, isEdited: isEditMode });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  return (
    <>
      <Helmet>
        <title>{t.translate('Supplier settings')}</title>
      </Helmet>

      <Header
        supplier={params.supplierId ? supplier : null}
        tabs={tabs}
        isMobile={screen.isMobile}
        activeTab={activeTab}
        formState={formState}
        setFormState={setFormState}
        onSupplierExport={() => handleExportSupplier()}
        canEditSupplier={canEditSupplier}
        isEditedByAnotherUser={isEditedByAnotherUser}
      />
      {activeMarketplaces &&
        <DesktopView
          routes={routes}
          supplier={params.supplierId ? supplier : null}
          formState={formState}
          setFormState={setFormState}
          callFetchingSupplier={callFetchingSupplier}
          onSupplierUpdate={() => callFetchingSupplier(supplier.id)}
          activeMarketplaces={activeMarketplaces}
        />
      }
    </>
  );
};
