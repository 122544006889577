import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import { Column } from 'layouts';

import { PositionsTable } from './PositionsTable/PositionsTable';
import { PurchaseCard } from '../common';
import Grid from '@mui/material/Grid';
import { ItemHeadline } from 'components';
import { StatusHistory } from '../../../orders/details/DesktopView/StatusHistory/StatusHistory';
import { useTranslator } from 'i18n';

const useStyles = makeStyles()(theme => ({
  cardLabel: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(2),
    },
  },
}));


export const DesktopView = ({
  purchase,
  positions,
  isMobile,
  canCorrectionPosition,
  onPositionCorrection,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  if (purchase && positions) {
    return (
      <Column pb={8} p={3} pt={0}>
        <PurchaseCard purchase={purchase} comment={purchase.comment} isMobile={isMobile} />
        <PositionsTable
            purchase={purchase}
            positions={positions}
            canCorrectionPosition={canCorrectionPosition}
            onPositionCorrection={onPositionCorrection}
        />
        {!!purchase.history.length &&
        <Grid item xs={6} container direction="column">
          <ItemHeadline text={t.translate('History')} className={classes.cardLabel}/>
          <StatusHistory statusHistory={purchase.history}/>
        </Grid>
        }
      </Column>
    );
  } else {
    return <Column p={3} pb={8} />;
  }
};

DesktopView.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  purchase: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    warehouse: PropTypes.shape({
      name: PropTypes.string,
    }),
    supplier: PropTypes.shape({
      name: PropTypes.string,
    }),
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
    editedByUser: PropTypes.shape({
      name: PropTypes.string,
    }),
    reviewedByUser: PropTypes.shape({
      name: PropTypes.string,
    }),
    status: PropTypes.string,
  }),
  canCorrectionPosition: PropTypes.bool.isRequired,
  onPositionCorrection: PropTypes.func.isRequired,
};
