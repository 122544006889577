import { useState } from 'react';

export const useAutocomplete = ({
  getOptionValue,
  optionPropertyName,
  modelPropertyName,
  formikHandle = false,
  form: {
    setValue,
    errors,
    model,
  },
  onSelect,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const select = (e, option) =>  {
    setSelectedOption(option);
    if (onSelect) {
      onSelect(option);
    } else if (option) {
      if (getOptionValue) {
        formikHandle ? setValue(modelPropertyName, getOptionValue(option)) : setValue(modelPropertyName)(getOptionValue(option));
      } else {
        formikHandle ? setValue(modelPropertyName, option[optionPropertyName].toString()) : setValue(modelPropertyName)(option[optionPropertyName].toString());
      }
    } else {
      formikHandle ? setValue(modelPropertyName, '') : setValue(modelPropertyName)('');
    }
  };

  const complete = option => setSelectedOption(option || null);

  const props = {
    helperText: !!errors[modelPropertyName] && (
      Array.isArray(errors[modelPropertyName])
        ? errors[modelPropertyName][0]
        : errors[modelPropertyName]
    ),
    fullWidth: true,
    error: !!errors[modelPropertyName],
    value: model[modelPropertyName],
    onChange: formikHandle ? (e) => setValue(modelPropertyName, e) : setValue(modelPropertyName),
    onSelect: select,
    onComplete: complete,
    InputLabelProps: { shrink: true },
  };

  return {
    setSelectedOption,
    selectedOption,
    props,
  };
};
