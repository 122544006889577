import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import config from 'config';

export const AppVersion = props => {
  const apiVersion = useSelector(({ app }) => app.apiVersion);

  return (
    <Box textAlign="center" width={1} {...props} style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
      <Typography variant="caption">App v. {config.appVersion} | API v. {apiVersion}</Typography>
    </Box>
  );
};
