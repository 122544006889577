import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import toaster from 'services/toaster';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import { Alert } from '@mui/material';

import {
  BusyButton,
  CheckboxControl,
  FormControlError,
} from 'components';

import { StorageItem } from './StorageItem/StorageItem';

import {
  useDialog,
} from 'hooks';

import { ConfirmingDialog } from '..';

import { extractApiErrors } from 'utils/api';
import { reassignStock } from 'api/storage-products';

import { fetchStorage } from 'api/storages';
import { StorageReassignAutocomplete } from '../../autocompletes';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';

import { fetchWarehouses } from '../../services/warehouses';
import { StorageTypes } from '../../consts';
import { getStorageName } from 'utils/storages';

const useStyles = makeStyles()(theme => ({
  root: {
    maxWidth: 'none',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      margin: 0,
      maxHeight: 'none',
      maxWidth: 'none',
      height: '100%',
      width: '100%',
    },
    [theme.breakpoints.between('sm', 'xl')]: {
      width: '70vw',
    },
    width: '50vw',
  },
  alert: {
    '&.MuiAlert-filledError': {
      backgroundColor: theme.palette.alert.error,
    },
  },
  content: {
    '&.MuiDialogContent-root': {
      overflowY: 'initial',
      paddingBottom: theme.spacing(.5),
    },
  },
  item: {
    '&:first-of-type': {
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(1.5),
      },
    },
    '&:last-child': {
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(1.5),
      },
    },
  },
}));

const makeId = postfix => `storage-reassign-dialog--${postfix}`;

export const StorageReassignDialog = ({
  storageProduct,
  onComplete,
  onClose,
  isMobile,
  canChangeStockAmount,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const confirmingDialog = useDialog();

  const [sourceStorageLocation, setSourceStorageLocation] = useState(null);
  const [destinationStorageLocation, setDestinationStorageLocation] = useState(null);
  const [isAllStockReassign, setIsAllStockReassign] = useState(true);

  const dispatch = useDispatch();

  const warehouses = useSelector(({ warehouses }) => warehouses.overview.data );
  const warehouseId = useSelector(({ account }) => account.warehouse.id);

  const [initialValues] = useState({
    sourceStorageLocationSearch: '',
    destinationStorageLocationSearch: '',
    stockAmount: '',
    isAllStockReassign,
    sourceWarehouseId: warehouseId,
    destinationWarehouseId: warehouseId,
  });

  const validationSchema = Yup
    .object()
    .shape({
      sourceStorageLocationSearch: Yup
        .string()
        .required(t.translate('The source storage location is a required field')),
      destinationStorageLocationSearch: Yup
        .string()
        .required(t.translate('The destination storage location is a required field'))
        .test('match', t.translate('The destination storage location and the source storage location should not be the same'), function () {
          return this.parent.sourceStorageLocationSearch !== this.parent.destinationStorageLocationSearch;
        }),
      ...(canChangeStockAmount && {
        stockAmount: Yup.number().when('isAllStockReassign', {
          is: false,
          then: Yup.number().positive().max(storageProduct.totalStock).integer(),
        }),
      }),
      isAllStockReassign: Yup.boolean(),
      sourceWarehouseId: Yup.number().required(),
      destinationWarehouseId: Yup.number().required(),
    });

  const onSubmit = async (values, { setErrors }) => {
    try {
      await reassignStock({
        sourceStorageId: sourceStorageLocation.id,
        destinationStorageId: destinationStorageLocation.id,
        ...(storageProduct && { productId: storageProduct.product.id }),
        ...(storageProduct && { supplierId: storageProduct.supplierId }),
        ...(storageProduct && { manufacturingDate: storageProduct.manufacturingDate }),
        ...(!isAllStockReassign && { stockAmount: values.stockAmount }),
      });

      toaster.success(t.translate('Stock has been reassigned successfully.'));

      onComplete && onComplete();
      onClose();
    } catch (err) {
      setErrors(extractApiErrors(err));
    }
  };

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    isValid,
    values,
    errors,
  } = useFormik({
    validateOnMount: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    const setDataInReassignDialog = async () => {
      setFieldValue('sourceStorageLocationSearch', getStorageName(storageProduct.storage));
      const storage = await fetchStorage(storageProduct.storage.id);
      setSourceStorageLocation(storage);
      setFieldValue('sourceWarehouseId', storage.warehouse.id);
    };

    if(storageProduct) {
      setDataInReassignDialog();
    }
  }, [storageProduct, setFieldValue]);

  useEffect( () => {
    dispatch(fetchWarehouses({  meta: ['list-set']  }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // it is not possible to reassign stock from transit to any other type
  const isValidStorageTypes = (sourceType, destinationType) =>
    !((sourceType === StorageTypes.TRANSIT && destinationType !== StorageTypes.TRANSIT)
    || (sourceType !== StorageTypes.TRANSIT && destinationType === StorageTypes.TRANSIT));

  return (
    <>
      <ConfirmingDialog
        {...confirmingDialog.props}
        title={t.translate('Reassign all stocks')}
        acceptButtonText={t.translate(`Yes, I'm sure`)}
        declineButtonText={t.translate('No, back')}
        onAccept={handleSubmit}
      >
        {t.translate('Are you sure you want to reassign all stocks to the new storage?')}
      </ConfirmingDialog>

      <Dialog {...props} classes={{ paperWidthSm: classes.root }} onClose={onClose}>
        <DialogTitle className={classes.title}>{t.translate('Reassign stock')}</DialogTitle>
        <DialogContent className={classes.content}>
          {canChangeStockAmount && (<>
            <Typography variant="body2">{`${t.translate('Stock on this location')}: ${storageProduct.totalStock}`}</Typography>
            <Grid container>
              <CheckboxControl
                id={'isAllStockReassign'}
                checked={isAllStockReassign}
                fullWidth={false}
                onChange={(...args) => {
                  setIsAllStockReassign(!isAllStockReassign);
                  handleChange(...args);
                }}
              />
              <Input
                id={'stockAmount'}
                placeholder={t.translate(isAllStockReassign ? 'All stocks' : 'Enter stock amount')}
                disabled={isAllStockReassign}
                value={values.stockAmount}
                onChange={handleChange}
                error={!!errors.stockAmount && !isAllStockReassign}
              />
            </Grid>
          </>)}
          <Grid container spacing={3} style={{ flexWrap: isMobile ? 'wrap' : 'nowrap' }}>
            <Grid item container direction="column" spacing={1} xs={12} sm={6} className={classes.item}>
              <Grid item>
                <FormControl
                  fullWidth
                  error={!!errors.sourceWarehouseId}
                  style={{ marginBottom: 10 }}
                  disabled={!!storageProduct}
                >
                  <InputLabel shrink htmlFor={props.id}>{t.translate('Source warehouse')}</InputLabel>
                  <Select
                    id={makeId('source-warehouse-id')}
                    name="sourceWarehouseId"
                    value={warehouses.length && values.sourceWarehouseId ? values.sourceWarehouseId : ''}
                    onChange={handleChange}
                  >
                    {warehouses.map(warehouse => (
                      <MenuItem key={warehouse.id} value={warehouse.id}>{warehouse.id} — {warehouse.name}</MenuItem>
                    ))}
                  </Select>
                  {errors.sourceWarehouseId && <FormControlError error={errors.sourceWarehouseId}/>}
                </FormControl>
                <StorageReassignAutocomplete
                  id={makeId('source-storage-number')}
                  error={errors.sourceStorageLocationSearch}
                  onChange={(value) => {
                    setSourceStorageLocation(null);
                    setFieldValue('sourceStorageLocationSearch', value);
                  }}
                  value={values.sourceStorageLocationSearch}
                  warehouseId={values.sourceWarehouseId}
                  label={t.translate('Source storage location')}
                  disabled={!!storageProduct}
                  name="sourceStorageLocationSearch"
                  onSelect={async (event, selectedOption) => {
                    if (selectedOption) {
                      const storage = await fetchStorage(selectedOption.value);
                      setSourceStorageLocation(storage);
                      await setFieldValue('sourceStorageLocationSearch', selectedOption.label);
                    }
                  }}
                />
              </Grid>
              {sourceStorageLocation && <StorageItem storage={sourceStorageLocation} />}
            </Grid>
            <Grid item container direction="column" spacing={1} xs={12} sm={6} className={classes.item}>
              <Grid item>
                <FormControl fullWidth error={!!errors.destinationWarehouseId} style={{ marginBottom: 10 }}>
                  <InputLabel shrink htmlFor={props.id}>{t.translate('Destination warehouse')}</InputLabel>
                  <Select
                    id={makeId('destinationWarehouseId')}
                    name="destinationWarehouseId"
                    value={warehouses.length && values.destinationWarehouseId ? values.destinationWarehouseId : ''}
                    onChange={handleChange}
                  >
                    {warehouses.map(warehouse => (
                      <MenuItem key={warehouse.id} value={warehouse.id}>{warehouse.id} — {warehouse.name}</MenuItem>
                    ))}
                  </Select>
                  {errors.destinationWarehouseId && <FormControlError error={errors.destinationWarehouseId}/>}
                </FormControl>
                <StorageReassignAutocomplete
                  id={makeId('destination-storage-number')}
                  error={errors.destinationStorageLocationSearch}
                  onChange={(value) => {
                    setDestinationStorageLocation(null);
                    setFieldValue('destinationStorageLocationSearch', value);
                  }}
                  warehouseId={values.destinationWarehouseId}
                  value={values.destinationStorageLocationSearch}
                  label={t.translate('Destination storage location')}
                  name="destinationStorageLocationSearch"
                  onSelect={async (event, selectedOption) => {
                    if (selectedOption) {
                      const storage = await fetchStorage(selectedOption.value);
                      setDestinationStorageLocation(storage);
                      await setFieldValue('destinationStorageLocationSearch', selectedOption.label);
                    }
                  }}
                />
              </Grid>
              {destinationStorageLocation && <StorageItem storage={destinationStorageLocation} />}
            </Grid>
          </Grid>
          {(destinationStorageLocation && !isValidStorageTypes(sourceStorageLocation?.type, destinationStorageLocation?.type)) && (
            <Grid container justifyContent="flex-end">
              <Alert variant="filled" severity="error" className={classes.alert}>
                {t.translate('Cannot reassign stock between storages with incompatible types.')}
              </Alert>
            </Grid>
          )}
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button color="primary" onClick={onClose}>{t.translate('Cancel')}</Button>
          <BusyButton disabled={!isValid || !isValidStorageTypes(sourceStorageLocation?.type, destinationStorageLocation?.type)} onClick={() => confirmingDialog.open()}>{t.translate('Reassign')}</BusyButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

StorageReassignDialog.propTypes = {
  storageProduct: PropTypes.shape({
    storage: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    product: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    supplierId: PropTypes.number,
  }),
  onComplete: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  canChangeStockAmount: PropTypes.bool,
};
