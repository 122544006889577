import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import GetAppIcon from '@mui/icons-material/GetApp';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import { styleMobileTableItem } from 'theme/mixins/table';
import { makeSourceLink } from 'services/files';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  paper: {
    padding: 0,
    marginBottom: theme.spacing(0),
    '& .MuiLink-underlineHover:hover': {
      textDecoration: 'none',
    },
  },
  headline: {
    backgroundColor: theme.palette.primary.l10,
    padding: theme.spacing(2),
    boxShadow: '0px 1px 0px 0px rgba(0,0,0,0.2)',
  },
  itemContainer: {
    padding: theme.spacing(0, 2),
    marginTop: theme.spacing(.5),
  },
  bold: {
    fontWeight: 600,
  },
  buttons: {
    padding: theme.spacing(2),
  },
}));

export const StatisticsReportItem = ({ statisticsReport }) => {
  const { classes } = useStyles();
  const t = useTranslator();

  return (
    <Grid item>
      <Paper className={classes.paper}>
        <Grid container direction="column" >
          <Grid item className={classes.headline}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography variant="body1" className={classes.bold}>{t.translate('Stock changes')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{statisticsReport.user.name}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item container direction="column">
              <Grid container justifyContent="space-between" spacing={1} className={classes.itemContainer}>
                <Grid item>
                  <Typography variant="body2" className={classes.bold}>{t.translate('Time')}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{t.formatTime(statisticsReport.createdAt)}</Typography>
                </Grid>
              </Grid>

              <Grid container justifyContent="space-between" spacing={1} className={classes.itemContainer}>
                <Grid item>
                  <Typography variant="body2" className={classes.bold}>{t.translate('Start date')}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{t.formatDate(statisticsReport.startDate)}</Typography>
                </Grid>
              </Grid>

              <Grid container justifyContent="space-between" spacing={1} className={classes.itemContainer}>
                <Grid item>
                  <Typography variant="body2" className={classes.bold}>{t.translate('End date')}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{t.formatDate(statisticsReport.endDate)}</Typography>
                </Grid>
              </Grid>

              <Grid container direction="row-reverse" spacing={1} className={classes.buttons}>
                <Grid item >
                  <Link href={makeSourceLink(statisticsReport.document.filePath)}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      startIcon={<GetAppIcon id='assignmentAdd'/>}
                    >
                      {t.translate('Download')}
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

StatisticsReportItem.propTypes = {
  statisticsReport: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    document: PropTypes.object.isRequired,
    endDate: PropTypes.string.isRequired,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    id: PropTypes.number.isRequired,
  }).isRequired,
};
