import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  useScreen,
  useDialog,
  useList,
} from 'hooks';

import AddIcon from '@mui/icons-material/Add';

import { Fab, CircularLoader } from 'components';
import {
  UserDialog,
} from 'dialogs';
import {
  DesktopView,
  MobileView,
} from 'views/users/overview';

import { selectActionPermission } from 'store/selectors/account';
import { isLoading } from 'store/selectors/app';
import { fetchUsers } from 'services/users';

import tables from 'config/tables';

export const UsersOverview = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const screen = useScreen();
  const list = useList(tables.users.users);
  const isLoadingUsers = useSelector(isLoading('users.overview.users'));
  const canCreateNewUser = useSelector(selectActionPermission('users', 'create'));

  const creatingDialog = useDialog();

  const fetchData = useCallback(() => dispatch(fetchUsers(list.compile())), [dispatch, list]);

  useEffect(() => void fetchData(), [fetchData, location.search]);

  if (isLoadingUsers) {
    return <CircularLoader />;
  }

  return (
    <>
      {canCreateNewUser && (
        <Fab onClick={() => creatingDialog.open()} aria-label="Create user">
          <AddIcon />
        </Fab>
      )}

      {creatingDialog.visible && (
        <UserDialog
          {...creatingDialog.props}
          onSave={fetchData}
        />
      )}

      {screen.isMobile
        ? <MobileView/>
        : <DesktopView/>}
    </>
  );
};
