import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useTranslator } from 'i18n';

import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';

import {
  AppHeaderPrimaryToolbar,
  AppHeaderRightBox,
  AppHeaderBackButton,
  AppHeaderTitle,
  AppHeader,
  ActionButton,
} from 'components';

export const Header = ({ warehouse, canEditWarehouse, onWarehouseEdit }) => {
  const t = useTranslator();
  const history = useHistory();

  return (
    <AppHeader>
      <AppHeaderPrimaryToolbar>
        <AppHeaderBackButton edge="start" onClick={() => history.goBack()} />
        <AppHeaderTitle>{t.translate('Warehouse')}: {warehouse?.name || '—'}</AppHeaderTitle>
        {canEditWarehouse && (
          <AppHeaderRightBox alignItems="center"  onClick={() => onWarehouseEdit(warehouse)}>
            <ActionButton
              aria-label={t.translate('Edit the warehouse')}
              tooltip={t.translate('Edit')}
              icon={<EditIcon />}
              color="inherit"
            />
            <Typography variant="body2">{t.translate('Edit')}</Typography>
          </AppHeaderRightBox>
        )}
      </AppHeaderPrimaryToolbar>
    </AppHeader>
  );
};

Header.propTypes = {
  warehouse: PropTypes.object,
  onWarehouseEdit: PropTypes.func.isRequired,
  canEditWarehouse: PropTypes.bool.isRequired,
};
