import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import {
  AppHeaderPrimaryToolbar,
  AppHeaderTitle,
} from 'components';

const useStyles = makeStyles()({
  root: {
    position: 'sticky',
  },
});

export const FullScreenDialogHeader = ({
  onClose,
  title,
  children,
}) => {
  const { classes } = useStyles();

  return (
    <AppBar className={classes.root}>
      <AppHeaderPrimaryToolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="close dialog"
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
        <AppHeaderTitle mr={2}>{title}</AppHeaderTitle>
        {children}
      </AppHeaderPrimaryToolbar>
    </AppBar>
  );
};

FullScreenDialogHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
