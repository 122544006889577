import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  container: {
    flexWrap: 'wrap',
  },
  storageValue: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));
