import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import { useStyles } from '../Tables';
import {
  TableCell,
  TableRow,
  Table,
} from 'components';

export const WarehouseFeesTable = ({ warehouseFees, warehouses }) => {
  const { classes } = useStyles();
  const t = useTranslator();

  return (
    <TableContainer>
      <Table stickyHeader className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell fixed width={130}>
              <Grid className={classes.titleFixedColumn}>
                <AssessmentIcon />
                <Typography>{t.translate('Warehouse fees')}</Typography>
              </Grid>
            </TableCell>
            <TableCell width={80} className={classes.headerCell} name="shipment" align="right">
              {t.translate('Shipment')}
            </TableCell>
            <TableCell width={80} className={classes.headerCell} name="storage_action" align="right">
              {t.translate('Storage Action')}
            </TableCell>
            <TableCell width={80} className={classes.headerCell} name="storage_usage" align="right">
              {t.translate('Storage Usage')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {warehouses.map(warehouse => {
            const feesWarehouse = warehouseFees.find(elem => elem.warehouseId === warehouse.id);
            return (
              <TableRow key={`${warehouse.id} - ${warehouse.name}`} className={classes.tableRow}>
                <TableCell className={classes.fixedCell}>
                  <Typography>
                    {`${warehouse.id} - ${warehouse.name}`}
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell} align="right" >
                  {feesWarehouse?.supplierShipmentsFees ?
                    `${t.formatPrice(feesWarehouse.supplierShipmentsFees)} €` : `${t.formatPrice(0)} €`}
                </TableCell>
                <TableCell className={classes.cell} align="right" >
                  {feesWarehouse?.supplierStorageActionsFees ?
                    `${t.formatPrice(feesWarehouse.supplierStorageActionsFees)} €` : `${t.formatPrice(0)} €`}
                </TableCell>
                <TableCell className={classes.cell} align="right" >
                  {feesWarehouse?.supplierStorageUsageFees ?
                    `${t.formatPrice(feesWarehouse.supplierStorageUsageFees)} €` : `${t.formatPrice(0)} €`}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

WarehouseFeesTable.propTypes = {
  warehouseFees: PropTypes.arrayOf(PropTypes.shape({
    warehouseId: PropTypes.number,
    warehouseName: PropTypes.string,
    supplierShipmentsFees: PropTypes.number,
    supplierStorageActionsFees: PropTypes.number,
    supplierStorageUsageFees: PropTypes.number,
  })),
  warehouses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

