import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export const ActionButton = ({
  tooltipPlacement,
  tooltip,
  icon,
  ...props
}) => (
  <Tooltip title={tooltip} {...(tooltipPlacement && { placement: tooltipPlacement })}>
    <span>
      <IconButton variant="contained" {...props} size="large">
        {icon}
      </IconButton>
    </span>
  </Tooltip>
);

ActionButton.propTypes = {
  tooltipPlacement: PropTypes.string,
  tooltip: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
};
