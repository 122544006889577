import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';
import { useRoute } from 'hooks/route';
import { useList } from 'hooks';

import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import PublishIcon from '@mui/icons-material/Publish';

import { QrCodeScanner } from '../../QrCodeScanner/QrCodeScanner';
import { selectActionPermission } from '../../../../../store/selectors/account';
import {
  AppHeaderSecondaryToolbar,
  AppHeaderPrimaryToolbar,
  AppHeaderMenuButton,
  AppHeaderRightBox,
  QuerySearchInput,
  SuppliersFilter,
  AppHeaderTitle,
  AppHeader,
  AlzuraSpriteIcon,
  MoreBox,
} from 'components';
import { EanCodeScanDialog } from 'dialogs';

import Grid from '@mui/material/Grid';
import GetAppIcon from '@mui/icons-material/GetApp';
import Button from '@mui/material/Button';

import tables from 'config/tables';

const useStyles = makeStyles()(theme => ({
  buttonContainer: {
    '& .MuiIconButton-root': {
      borderRadius: theme.spacing(.5),
      padding: theme.spacing(1),
    },
  },
  searchInputUsed: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.l900,
  },
  button: {
    color: theme.palette.text.secondary,
    height: 'inherit',
    textTransform: 'none',
    minWidth: theme.spacing(4),
    justifyContent: 'left',
    padding: `${theme.spacing(1.5)} ${theme.spacing(3)} !important`,
  },
}));

export const Header = ({
  productTypes,
  productGroups,
  onImportProductsClick,
  onCreateProduct,
  onExportProductsClick,
  canExportProducts,
}) => {
  const canImportProducts = useSelector(selectActionPermission('products', 'import'));
  const canCreateProduct = useSelector(selectActionPermission('products', 'create'));

  const { classes } = useStyles();
  const t = useTranslator();
  const route = useRoute();
  const list = useList(tables.products.products);

  const [visibleSearchInput, setVisibleSearchInput] = useState(false);
  const [scannerVisible, setScannerVisible] = useState(false);

  const applyEanCode = eanCode => route.push({ search: { ...route.search, q: eanCode } });

  return <>
    <EanCodeScanDialog
      open={scannerVisible}
      onClose={() => setScannerVisible(false)}
      onDecode={applyEanCode}
    />

    <AppHeader>
      <AppHeaderPrimaryToolbar>
        <AppHeaderMenuButton edge="start" />
        <AppHeaderTitle>{t.translate('Products')}</AppHeaderTitle>
        <AppHeaderRightBox>
          <MoreBox isMobile={true}>
            <Grid container direction="column">
              {canCreateProduct && (
                <Button
                  className={classes.button}
                  startIcon={<AlzuraSpriteIcon id='assignmentAdd' />}
                  onClick={onCreateProduct}
                >
                  {t.translate('Create product')}
                </Button>
              )}
              {canImportProducts && (
                <Button
                  className={classes.button}
                  startIcon={<PublishIcon />}
                  onClick={onImportProductsClick}
                >
                  {t.translate('Import')}
                </Button>
              )}
              {canExportProducts && (
                <Button
                  className={classes.button}
                  startIcon={<GetAppIcon />}
                  onClick={onExportProductsClick}
                >
                  {t.translate('Export')}
                </Button>
              )}
            </Grid>
          </MoreBox>
          <Grid container spacing={1} alignItems="center" className={classes.buttonContainer}>
            <Grid item>
              <QrCodeScanner />
            </Grid>
            <Grid item>
              <SuppliersFilter tables={list} type="supplierIds" productTypes={productTypes} productGroups={productGroups}/>
            </Grid>
            <Grid item>
              <IconButton
                color="inherit"
                onClick={() => setVisibleSearchInput(!visibleSearchInput)}
                aria-label="search input"
                className={visibleSearchInput ? classes.searchInputUsed : ''}
                size="large">
                <SearchIcon />
              </IconButton>
            </Grid>
          </Grid>
        </AppHeaderRightBox>
      </AppHeaderPrimaryToolbar>
      {visibleSearchInput && (
        <AppHeaderSecondaryToolbar pl={1} pr={1}>
          <Tooltip placement="bottom" title={t.translate('Search by product ID, EAN, Alzura ID, name, description, manufacturer no or name')} >
            <Grid container>
              <QuerySearchInput fullWidth placeholder={t.translate('Search by product ID, EAN, Alzura ID, name, description, manufacturer no or name')}/>
            </Grid>
          </Tooltip>
        </AppHeaderSecondaryToolbar>
      )}
    </AppHeader>
  </>;
};

Header.propTypes = {
  productTypes: PropTypes.array,
  onImportProductsClick: PropTypes.func.isRequired,
  onCreateProduct: PropTypes.func.isRequired,
  onExportProductsClick: PropTypes.func.isRequired,
  canExportProducts: PropTypes.bool,
};
