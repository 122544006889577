import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { FormControlError } from 'components';

export const daysInMonth = {
  '01': 31,
  '02': 28,
  '03': 31,
  '04': 30,
  '05': 31,
  '06': 30,
  '07': 31,
  '08': 31,
  '09': 30,
  '10': 31,
  '11': 30,
  '12': 31,
};

export const daysValues = {
  1: '01',
  2: '02',
  3: '03',
  4: '04',
  5: '05',
  6: '06',
  7: '07',
  8: '08',
  9: '09',
  10: '10',
  11: '11',
  12: '12',
  13: '13',
  14: '14',
  15: '15',
  16: '16',
  17: '17',
  18: '18',
  19: '19',
  20: '20',
  21: '21',
  22: '22',
  23: '23',
  24: '24',
  25: '25',
  26: '26',
  27: '27',
  28: '28',
  29: '29',
  30: '30',
  31: '31',
};

const dayOptions = (days = 31) => {
  const items = [];
  for (let i = 1; i <= days; ++i) {
    items.push(
      <MenuItem key={i} value={daysValues[i]}>{i}</MenuItem>
    );
  }
  return items;
};

export const DayPicker = ({
  disabled = false,
  value,
  defaultValue,
  onChange,
  label,
  error,
  month,
  ...props
}) => {
  let days;
  if (month) {
    if (new Date().getFullYear() % 4 === 0 && month === 1) {
      days = 29;
    } else {
      days = daysInMonth[month];
    }
  } else {
    days = 31;
  }

  return (
    <FormControl fullWidth error={!!error}>
      <InputLabel shrink htmlFor={props.id}>{label}</InputLabel>
      <Select
        id="day-select"
        value={value}
        onChange={onChange}
        disabled={disabled}
        defaultValue={defaultValue || '01'}
        {...props}
      >
        {dayOptions(days)}
      </Select>
      {!!error && <FormControlError error={error} />}
    </FormControl>
  );
};

DayPicker.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  month: PropTypes.string.isRequired,
  error: PropTypes.any,
};
