import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslator } from 'i18n';
import {
  didMount,
  useDialog,
  useList,
  useScreen,
} from 'hooks';
import {
  DesktopView,
  MobileView,
  Header,
} from 'views/purchases/details';
import { fetchPurchase, fetchPurchasePositions } from 'services/purchases';
import {
  selectActionPermission, selectAuthenticatedUser,
} from 'store/selectors/account';
import { setPurchase } from 'store/actions/purchases';
import { ConfirmingDialog, PurchaseDialog, EditPurchasePositionDialog } from 'dialogs';
import toaster from 'services/toaster';

import { approvePurchase, declinePurchase, deletePurchase, exportPurchase } from 'api/purchases';
import { PurchaseStatuses } from 'consts';
import socket, { SOCKET_EVENTS } from 'bootstrap/socket';
import tables from '../../../config/tables';
import { selectDetailsPurchasePositions } from '../../../store/selectors/purchases';
import { download } from '../../../services/files';

export const PurchaseDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const screen = useScreen();
  const t = useTranslator();
  const list = useList(tables.purchases.positions);
  const location = useLocation();

  const confirmDeleteDialog = useDialog({ purchase: null });
  const confirmApproveDialog = useDialog({ purchase: null });
  const confirmDeclineDialog = useDialog({ purchase: null });

  const onDeletePurchase = async () => {
    try {
      await deletePurchase(confirmDeleteDialog.data.purchase.id);
      toaster.success(t.translate('Purchase has been deleted successfully.'));
      history.goBack();
    } catch (err) {}
  };

  const onApprovePurchase = async () => {
    try {
      await approvePurchase(confirmApproveDialog.data.purchase.id);
      toaster.success(t.translate('Purchase has been approved successfully.'));
      history.goBack();
    } catch (err) {}
  };

  const onDeclinePurchase = async () => {
    try {
      await declinePurchase(confirmDeclineDialog.data.purchase.id);
      toaster.success(t.translate('Purchase has been declined successfully.'));
      history.goBack();
    } catch (err) {}
  };

  const purchaseDialog = useDialog(null);
  const positionDialog = useDialog(null);

  const authenticatedUser = useSelector(selectAuthenticatedUser);

  const canEditPurchasePermission = useSelector(selectActionPermission('purchases', 'edit'));
  const canCorrectionPurchasePermission = useSelector(selectActionPermission('purchases', 'correction'));
  const hasSupervisorPermission = useSelector(selectActionPermission('purchases', 'supervisor'));

  const purchase = useSelector(({ purchases }) => purchases.details.purchase);
  const positions = useSelector(selectDetailsPurchasePositions);

  const isEveryPositionNotDelivered = purchase?.positions.every(({ deliveredQuantity }) => deliveredQuantity === 0) ?? false;

  const callFetchingPurchase = useCallback((id) => {
    dispatch(fetchPurchase(id));
  }, [dispatch]);

  didMount(() => {
    socket.on(SOCKET_EVENTS.PURCHASES.EDITED_BY, async ({ entityId }) => {
      if (entityId === Number(params.purchaseId)) dispatch(fetchPurchase(entityId));
    });
  });

  useEffect(() => {
    return () => {
      socket.emit(SOCKET_EVENTS.PURCHASES.EDITED_BY, { entityId: Number(params.purchaseId), isEdited: false });
      socket.off(SOCKET_EVENTS.PURCHASES.EDITED_BY);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Number(params.purchaseId) !== purchase?.id) {
      callFetchingPurchase(params.purchaseId);
    }
  }, [callFetchingPurchase, params.purchaseId, purchase, location.search]);

  useEffect(() => {
    dispatch(fetchPurchasePositions(params.purchaseId, list.compile()));
  }, [params.purchaseId, location.search, purchase, dispatch, list]);

  useEffect(() => () => dispatch(setPurchase(null)), [dispatch]);

  const isEditedByAnotherUser = purchase?.editedByUserId ? purchase.editedByUserId !== authenticatedUser?.id : false;

  const canDeletePurchase =
    (!purchase?.editedByUserId || purchase.editedByUserId === authenticatedUser.id)
    && canEditPurchasePermission
    && (
      [PurchaseStatuses.draft].includes(purchase?.status)
      || (purchase?.status !== PurchaseStatuses.received && hasSupervisorPermission && isEveryPositionNotDelivered)
    );

  const canEditPurchase =
    (!purchase?.editedByUserId || purchase.editedByUserId === authenticatedUser.id)
    && canEditPurchasePermission
    && (
      [PurchaseStatuses.draft, PurchaseStatuses.approvalNeeded, PurchaseStatuses.declined].includes(purchase?.status)
      || (purchase?.status !== PurchaseStatuses.received && hasSupervisorPermission && isEveryPositionNotDelivered)
    );

  const canCorrectionPurchase =
    (!purchase?.editedByUserId || purchase.editedByUserId === authenticatedUser.id)
    && canCorrectionPurchasePermission
    && (purchase?.status === PurchaseStatuses.open)
    && !canEditPurchase;

  const canApproveAndDeclinePurchase = purchase?.status === PurchaseStatuses.approvalNeeded && hasSupervisorPermission;

  const onPurchaseExport = async () => {
    try {
      const file = await exportPurchase(purchase.id);
      if (file) {
        toaster.success(t.translate('Purchase has been successfully exported.'));
        download(file);
      }
    } catch (err) {}
  };

  return (
    <>
      <Helmet>
        <title>{t.translate('Purchase')} | {t.translate('app:title')}</title>
      </Helmet>

      {purchaseDialog.visible && (
        <PurchaseDialog
          {...purchaseDialog.props}
          isMobile={screen.isMobile}
          onSuccess={() => callFetchingPurchase(params.purchaseId)}
        />
      )}
      {positionDialog.visible && (
        <EditPurchasePositionDialog
          {...positionDialog.props}
          isMobile={screen.isMobile}
          onSuccess={() => callFetchingPurchase(params.purchaseId)}
        />
      )}

      {canEditPurchase && (
        <ConfirmingDialog
          {...confirmDeleteDialog.props}
          title={t.translate('Delete purchase')}
          acceptButtonText={t.translate(`Yes, I'm sure`)}
          declineButtonText={t.translate('No, cancel')}
          onAccept={onDeletePurchase}
        >
          {t.translate('Are you sure you want to delete the purchase?')}
        </ConfirmingDialog>
      )}

      {canApproveAndDeclinePurchase && (
        <ConfirmingDialog
          {...confirmApproveDialog.props}
          title={t.translate('Approve purchase')}
          acceptButtonText={t.translate(`Yes, I'm sure`)}
          declineButtonText={t.translate('No, cancel')}
          onAccept={onApprovePurchase}
        >
          {t.translate('Are you sure you want to approve the purchase?')}
        </ConfirmingDialog>
      )}

      {canApproveAndDeclinePurchase && (
        <ConfirmingDialog
          {...confirmDeclineDialog.props}
          title={t.translate('Decline purchase')}
          acceptButtonText={t.translate(`Yes, I'm sure`)}
          declineButtonText={t.translate('No, cancel')}
          onAccept={onDeclinePurchase}
        >
          {t.translate('Are you sure you want to decline the purchase?')}
        </ConfirmingDialog>
      )}

      <Header
        purchase={purchase}
        isMobile={screen.isMobile}
        canApproveAndDeclinePurchase={canApproveAndDeclinePurchase}
        canEditPurchase={canEditPurchase}
        canCorrectionPurchase={canCorrectionPurchase}
        canDeletePurchase={canDeletePurchase}
        isEditedByAnotherUser={isEditedByAnotherUser}
        onPurchaseEdit={purchase => purchaseDialog.open({ purchaseData: purchase })}
        onPurchaseCorrection={purchase => purchaseDialog.open({ purchaseData: purchase, isCorrection: true })}
        onPurchaseDelete={purchase => confirmDeleteDialog.open({ purchase })}
        onPurchaseApprove={purchase => confirmApproveDialog.open({ purchase })}
        onPurchaseDecline={purchase => confirmDeclineDialog.open({ purchase })}
        onPurchaseExport={onPurchaseExport}
      />

      {screen.isMobile ? (
        <MobileView
          purchase={purchase}
          positions={positions}
          isMobile={screen.isMobile}
        />
      ) : (
        <DesktopView
          positions={positions}
          purchase={purchase}
          isMobile={screen.isMobile}
          canCorrectionPosition={canCorrectionPurchase}
          onPositionCorrection={(position, purchaseId) => positionDialog.open({ position, purchaseId })}
        />
      )}
    </>
  );
};
