import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { didUpdate } from 'hooks';
import { useTranslator } from 'i18n';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { makeStyles } from 'tss-react/mui';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ToggleButton from '@mui/material/ToggleButton';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { BuildingAutocomplete, useBuildingAutocomplete } from 'autocompletes';
import {
  FormControlError,
  InputControl,
  BusyButton,
  CheckboxControl,
} from 'components';

import { modifyStorage, createStorage } from 'api/storages';
import { extractApiErrors } from 'utils/api';
import { storageBoxColors } from 'config/colors';
import toaster from 'services/toaster';
import { StorageTypes } from '../../consts';

const makeId = postfix => `storage-dialog--${postfix}`;

const useStyles = makeStyles()(theme => ({
  boxColor: {
    marginTop: theme.spacing(3),
    '& label': {
      fontSize: '0.8rem',
    },
  },
  boxDetails: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  dialogActions: {
    margin: `${theme.spacing(4)} 0 ${theme.spacing(2)}`,
  },
  button: {
    border: `2px solid ${theme.palette.grey['205']}`,
    borderRadius: '4px',
    color: theme.palette.grey['205'],
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    width: '30%',
    '&:nth-of-type(n+4)': {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
      width: '48%',
    },
    '& svg': {
      position: 'absolute',
      left: theme.spacing(2.25),
    },
    '&:hover': {
      background: 'none',
    },
    '&.MuiToggleButton-root.Mui-selected': {
      background: 'none',
    },
    '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
      borderWidth: '2px',
    },
  },
  toggleButtonContainer: {
    margin: theme.spacing(0, -1.5),
  },
  toggleButton: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 1.5),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
}));

export const StorageDialog = ({
  isMobile,
  onClose,
  onSave,
  storage,
  canEditTransitOption,
  canEditExcludeStockOption,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const title = storage
    ? 'Edit storage'
    : 'Create storage';

  const warehouseId = useSelector(({ account }) => account.warehouse.id);

  const initialValues = {
    boxNumber: storage?.boxNumber || '',
    boxColor: storage?.boxColor || null,
    building: storage?.building || '',
    floor: storage?.floor || '',
    shelf: storage?.shelf || '',
    level: storage?.level || '',
    room: storage?.room || '',
    row: storage?.row || '',
    length: storage?.length || '',
    height: storage?.height || '',
    width: storage?.width || '',
    warehouseId: storage?.warehouse.id || warehouseId,
    type: storage?.type || StorageTypes.DEFAULT,
    excludeStock: !!storage?.excludeStock,
  };

  const warehouses = useSelector(({ warehouses }) => warehouses.overview.data );

  const validationSchema = Yup
    .object()
    .shape({
      building: Yup
        .string()
        .trim()
        .required(t.translate('Please specify a building.')),
    });

  const onSubmit = async (values, { setErrors }) => {
    try {
      const payload = {
        ...values,
        excludeStock: [StorageTypes.TEMPORARY, StorageTypes.EVALUATION, StorageTypes.TRANSIT].includes(values.type) ? true : values.excludeStock,
      };
      if (storage) {
        await modifyStorage(storage.id, payload);
        toaster.success(t.translate('Storage has been saved successfully.'));
      } else {
        await createStorage(payload);
        toaster.success(t.translate('New storage has been created successfully.'));
      }

      onSave();
      onClose();
    } catch (err) {
      setErrors(extractApiErrors(err));
    }
  };

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    resetForm,
    values,
    errors,
  } = useFormik({
    enableReinitialize: true,
    validateOnMount: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  const buildingAutocomplete = useBuildingAutocomplete({ form: { setValue: setFieldValue, errors, model: values } });

  didUpdate(() => {
    if (props.open) {
      buildingAutocomplete.setSelectedOption(storage?.building ? {
        building: storage.building,
        id: storage.building,
      } : null);
      resetForm();
    }
  }, [props.open]);

  return (
    <Dialog
      {...props}
      scroll="body"
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">{t.translate(title)}</DialogTitle>
      <DialogContent>
        <Grid>
          <Grid container style={{ flexWrap: 'wrap' }} spacing={3}>
            <Grid item sm={12} xs={12}>
              <FormControl fullWidth error={!!errors.warehouseId}>
                <InputLabel shrink htmlFor={props.id}>{t.translate('Warehouse')}</InputLabel>
                <Select id={makeId('warehouse-id')} name="warehouseId" value={values.warehouseId} onChange={handleChange}>
                  {warehouses.map(warehouse => (
                    <MenuItem key={warehouse.id} value={warehouse.id}>{warehouse.id} — {warehouse.name}</MenuItem>
                  ))}
                </Select>
                {errors.warehouseId && <FormControlError error={errors.warehouseId} />}
              </FormControl>
            </Grid>
            <Grid item sm={12} xs={12}>
              <FormControl fullWidth error={!!errors.supplierId}>
                <InputLabel shrink htmlFor={props.id}>{t.translate('Storage type')}</InputLabel>
                <Select
                  id={makeId('type')}
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                  disabled={!canEditTransitOption && values.type === StorageTypes.TRANSIT}
                >
                  {Object.keys(StorageTypes)
                    .filter((type) => type !== StorageTypes.TRANSIT || canEditTransitOption)
                    .map(type => (
                      <MenuItem key={type} value={type}>{t.translate(`storageType:${StorageTypes[type]}`)}</MenuItem>
                    ))}
                </Select>
                {errors.type && <FormControlError error={errors.type}/>}
              </FormControl>
            </Grid>
            {canEditExcludeStockOption && [StorageTypes.DEFAULT].includes(values.type) && (
              <Grid item sm={12} xs={12}>
                <CheckboxControl
                  id={makeId('excludeStock')}
                  name="excludeStock"
                  error={errors.excludeStock}
                  label={t.translate('Exclude from available stock')}
                  checked={!!values.excludeStock}
                  onChange={handleChange}
                />
              </Grid>
            )}
            <Grid item id={makeId('building')} sm={4} xs={12}>
              <BuildingAutocomplete
                {...buildingAutocomplete.props}
                id={makeId('building')}
                name="building"
                label={t.translate('Building')}
              />
            </Grid>
            <Grid item xs={12} sm={4} >
              <InputControl
                id={makeId('floor')}
                name="floor"
                error={errors.floor}
                label={t.translate('Floor')}
                value={values.floor}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} >
              <InputControl
                id={makeId('room')}
                name="room"
                error={errors.room}
                label={t.translate('Room')}
                value={values.room}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <InputControl
                id={makeId('row')}
                name="row"
                error={errors.row}
                label={t.translate('Row')}
                value={values.row}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <InputControl
                id={makeId('shelf')}
                name="shelf"
                error={errors.shelf}
                label={t.translate('Shelf')}
                value={values.shelf}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <InputControl
                id={makeId('level')}
                name="level"
                error={errors.level}
                label={t.translate('Level')}
                value={values.level}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Typography variant="body1" className={classes.boxDetails}>{t.translate('Box details')}</Typography>
          <Grid container spacing={3} style={{ flexWrap: 'wrap' }}>
            <Grid item xs={12} sm={3}>
              <InputControl
                id={makeId('box-number')}
                name="boxNumber"
                error={errors.boxNumber}
                label={t.translate('Box number')}
                value={values.boxNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4} sm={3}>
              <InputControl
                id={makeId('width')}
                name="width"
                error={errors.width}
                label={`${t.translate('Width')}, cm`}
                value={values.width}
                onChange={handleChange}
                type="number"
              />
            </Grid>
            <Grid item xs={4} sm={3}>
              <InputControl
                id={makeId('height')}
                name="height"
                error={errors.height}
                label={`${t.translate('Height')}, cm`}
                value={values.height}
                onChange={handleChange}
                type="number"
              />
            </Grid>
            <Grid item xs={4} sm={3}>
              <InputControl
                id={makeId('length')}
                name="length"
                error={errors.length}
                label={`${t.translate('Length')}, cm`}
                value={values.length}
                onChange={handleChange}
                type="number"
              />
            </Grid>
          </Grid>
          <Grid className={classes.boxColor}>
            <FormLabel>{t.translate('Box color')}</FormLabel>
          </Grid>
          <Grid container spacing={3} className={classes.toggleButtonContainer}>
            <ToggleButtonGroup
              value={values.boxColor || 'black'}
              onChange={(event, color) => {
                setFieldValue('boxColor', color);
              }}
              exclusive
              className={classes.toggleButton}
            >
              {Object.entries(storageBoxColors).map(([color, hex]) => (
                <ToggleButton
                  key={color}
                  variant="outlined"
                  style={color !== 'black' ? { color: hex, borderColor: hex } : { borderStyle: 'dashed' }}
                  className={classes.button}
                  value={color !== 'black' ? color : ''}
                >
                  {(values.boxColor === color || (!values.boxColor && color === 'black')) && <CheckCircleIcon />}
                  {color !== 'black' ? t.translate(color): t.translate('no color')}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>
          <Divider/>
          <DialogActions className={classes.dialogActions}>
            <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
            <BusyButton color="primary" submit onClick={handleSubmit}>{t.translate('Save')}</BusyButton>
          </DialogActions>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

StorageDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  storage: PropTypes.shape({
    warehouse: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    type: PropTypes.string.isRequired,
    boxNumber: PropTypes.string,
    boxColor: PropTypes.string,
    building: PropTypes.string.isRequired,
    shelf: PropTypes.string,
    level: PropTypes.string,
    room: PropTypes.string,
    row: PropTypes.string,
    id: PropTypes.number.isRequired,
  }),
  canEditTransitOption: PropTypes.bool.isRequired,
  canEditExcludeStockOption: PropTypes.bool.isRequired,
};
