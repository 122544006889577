import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';

import {
  AppHeaderPrimaryToolbar,
  AppHeaderBackButton,
  AppHeaderTitle,
  AppHeader,
} from 'components';

const useStyles = makeStyles()(() => ({
  button: {
    color: 'inherit',
    fontWeight: 'normal',
    height: 'inherit',
    textTransform: 'none',
  },
}));

export const Header = ({
  storage,
  canEditStorage,
  canDeleteStorage,
  onStoragePrint,
  onStorageEdit,
  onStorageDelete,
}) => {
  const t = useTranslator();
  const history = useHistory();
  const { classes } = useStyles();

  return (
    <AppHeader>
      <AppHeaderPrimaryToolbar>
        <AppHeaderBackButton edge="start" onClick={() => history.goBack()} />
        <AppHeaderTitle>{t.translate('Storage')}: {storage?.id}</AppHeaderTitle>
          <Button
            className={classes.button}
            startIcon={<PrintIcon />}
            onClick={() => onStoragePrint(storage)}
          >
            {t.translate('Print')}
          </Button>
          {canEditStorage && (
            <Button
              className={classes.button}
              startIcon={<EditIcon />}
              onClick={() => onStorageEdit(storage)}
            >
              {t.translate('Edit')}
            </Button>
          )}
          {canDeleteStorage && (
            <Button
              className={classes.button}
              startIcon={<DeleteIcon />}
              onClick={() => onStorageDelete(storage)}
            >
              {t.translate('Delete')}
            </Button>
          )}
      </AppHeaderPrimaryToolbar>
    </AppHeader>
  );
};
