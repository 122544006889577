import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useStyles } from '../styles';
import { InputControl } from 'components';
import { useTranslator } from 'i18n';
import { PageWrapper } from '../../../suppliers/settings/PageWrapper/PageWrapper';

const makeId = postfix => `companies-bank-account-settings--${postfix}`;

export const BankAccountSettings = ({ isEditMode, values, handleChange, errors }) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <PageWrapper>
      <Grid item xl={8} lg={10} xs={12} className={classes.containerWrap}>
        <Grid container spacing={4}>
          <Grid container item xs={12} md={6} className={classes.containerWrap} spacing={1}>
            <Grid container item xs={12} md={9}>
              <Typography variant="h6" className={classes.marginBottom}>{t.translate('Bank data')}</Typography>
            </Grid>
            <Grid container item xs={12} md={9} className={classes.containerWrap} spacing={1}>
            <Grid item xs={8} className={classes.marginBottom}>
              <InputControl
                id={makeId('bankName')}
                name="bankName"
                error={errors.bankName}
                label={t.translate('Bank name')}
                value={values.bankName}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Grid>
            <Grid item xs={8} className={classes.marginBottom}>
              <InputControl
                id={makeId('bankAccountOwner')}
                name="bankAccountOwner"
                error={errors.bankAccountOwner}
                label={t.translate('Account owner')}
                value={values.bankAccountOwner}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Grid>
            <Grid item xs={8} className={classes.marginBottom}>
              <InputControl
                id={makeId('bankIBAN')}
                name="bankIBAN"
                error={errors.bankIBAN}
                label={t.translate('IBAN')}
                value={values.bankIBAN}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Grid>
            <Grid item xs={8} className={classes.marginBottom}>
              <InputControl
                id={makeId('bankBIC')}
                name="bankBIC"
                error={errors.bankBIC}
                label={t.translate('BIC')}
                value={values.bankBIC}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};
