import React, { useState } from 'react';
import { useTranslator } from 'i18n';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import {
  CardFeature,
} from 'components';
import { styleMobileTableItem } from 'theme/mixins/table';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  paper: {
    padding: 0,
  },
  volumeValue: {
    marginTop: theme.spacing(-.5),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  headline: {
    background: theme.palette.primary.l10,
    padding: theme.spacing(1, 2),
  },
  cardContainer: {
    padding: theme.spacing(1, 2),
  },
  featureLabel: {
    fontWeight: 500,
  },
  buttonsContainer: {
    margin: theme.spacing(1, 0),
    marginTop: theme.spacing(2),
  },
  button: {
    backgroundColor: theme.palette.primary.l10,
    color: theme.palette.primary.main,
    fontSize: '0.75rem',
    textTransform: 'none',
  },
  iconButton: {
    color: theme.palette.primary.l900,
  },
}));

export const PurchasePositionItem = ({
  position,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <Grid item>
      <Paper className={classes.paper}>
        <Grid container direction="column">
          <Grid container justifyContent="space-between" className={classes.headline}>
            <Grid item >
              <Typography variant="h6">ID: {position.id}</Typography>
            </Grid>
            <Grid item >
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open
                  ? <KeyboardArrowDownIcon />
                  : <KeyboardArrowRightIcon />}
              </IconButton>
            </Grid>
          </Grid>
          <Grid item container direction="column" className={classes.cardContainer}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Grid item xs p={2} >
                <Grid container direction="column">
                  <CardFeature
                    label={t.translate('Purchase price')}
                    value={position.purchasePrice}
                    justifyContent="space-between"
                    classes={{ label: classes.featureLabel }}
                  />
                  <CardFeature
                    label={t.translate('Quantity')}
                    value={position.quantity}
                    justifyContent="space-between"
                    classes={{ label: classes.featureLabel }}
                  />
                  <Divider className={classes.divider}/>
                </Grid>
              </Grid>
            </Collapse>
            {position.ean && (
              <CardFeature
                label={'EAN'}
                value={position.ean}
                justifyContent="space-between"
                classes={{ label: classes.featureLabel }}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

PurchasePositionItem.propTypes = {
};
