import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import GetAppIcon from '@mui/icons-material/GetApp';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';

import {
  AppHeaderPrimaryToolbar,
  AppHeaderBackButton,
  AppHeaderTitle,
  AppHeader,
  AppHeaderRightBox,
} from 'components';

const useStyles = makeStyles()((theme) => ({
  button: {
    color: 'inherit',
    height: 'inherit',
    textTransform: 'none',
    minWidth: theme.spacing(4),
    marginRight: theme.spacing(2),
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
}));

export const Header = ({
  customer,
  canExportCustomer,
  onExport,
  canEditCustomer,
  onCustomerEdit,
}) => {
  const history = useHistory();;
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <AppHeader>
      <AppHeaderPrimaryToolbar>
        <AppHeaderBackButton edge="start" onClick={() => history.goBack()} />
        <AppHeaderTitle>{customer?.number || null}</AppHeaderTitle>
        <AppHeaderRightBox alignItems="center">
          {canExportCustomer && (
            <Button
              className={classes.button}
              startIcon={<GetAppIcon />}
              onClick={onExport}
            >
              {t.translate('Export')}
            </Button>
          )}
          {canEditCustomer && (
            <Button
              className={classes.button}
              startIcon={<EditIcon />}
              onClick={() => onCustomerEdit(customer)}
            >
              {t.translate('Edit')}
            </Button>
          )}
        </AppHeaderRightBox>
      </AppHeaderPrimaryToolbar>
    </AppHeader>
  );
};

Header.propTypes = {
  customer: PropTypes.shape({
    number: PropTypes.string.isRequired,
  }),
  canExportCustomer: PropTypes.bool.isRequired,
  onExport: PropTypes.func.isRequired,
  canEditCustomer: PropTypes.bool.isRequired,
  onCustomerEdit: PropTypes.func.isRequired,
};
