import React from 'react';
import PropTypes from 'prop-types';
import { useAutocomplete } from 'hooks';
import { Autocomplete } from 'components';
import { fetchSuppliersForFilter } from 'api/suppliers';

import FormControl from '@mui/material/FormControl';
import { FormControlError } from 'components';

export const SupplierAutocomplete = ({
  value,
  error,
  ...props
}) => {
  const fetchOptions = async () => (await fetchSuppliersForFilter({ searchQuery: value })).data?.filter(supplier => supplier.active);

  return (
    <FormControl fullWidth error={!!error}>
      <Autocomplete
        getOptionLabel={({ number, name }) => `${number} — ${name}`}
        fetchOptions={fetchOptions}
        findOption={v => v.number === value.trim()}
        value={value}
        {...props}
      />
      {!!error && <FormControlError error={error} />}
    </FormControl>
  );
};

SupplierAutocomplete.propTypes = {
  value: PropTypes.string.isRequired,
};

export const useSupplierAutocomplete = options => useAutocomplete({
  optionPropertyName: 'number',
  modelPropertyName: 'supplierNumber',
  ...options,
});
