import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './CustomerItem.jsx';
import { useTranslator } from 'i18n';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export const CustomerItem = ({
  onOpen,
  customer,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <Grid item className={classes.item}>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item className={classes.contentItem}>
            <Typography variant="body1" className={classes.bold}>{customer.company || '—'}</Typography>
            <Box>
              <Typography variant="body2" component="span" className={classes.bold}>{t.translate('Number')}:&nbsp;</Typography>
              <Typography variant="body2" component="span">{customer.number || '—'}</Typography>
            </Box>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item className={classes.contentItem}>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Name')}</Typography>
              <Typography variant="body2">{customer.name || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Last order')}</Typography>
              <Typography variant="body2">{t.formatDate(customer.lastOrderDate)} {t.formatTime(customer.lastOrderDate)}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Orders count')}</Typography>
              <Typography variant="body2">{customer.ordersCount ?? '—'}</Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Paper className={classes.paper}>
        <Grid container direction="column">
          <Grid item className={classes.contentItem}>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Net total')}</Typography>
              <Typography variant="body2">{t.formatPrice(Number(customer.ordersTotalValueNet))} &euro;</Typography>
            </Grid>
          </Grid>
          <Grid item className={classes.contentItem}>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Gross total')}</Typography>
              <Typography variant="body2">{t.formatPrice(Number(customer.ordersTotalValueGross))} &euro;</Typography>
            </Grid>
          </Grid>
          <Grid item className={classes.buttons}>
            <Button
              aria-label={t.translate('Open customer')}
              className={classes.button}
              startIcon={<InfoIcon />}
              onClick={() => onOpen(customer)}
            >
              {t.translate('Details')}
            </Button>
        </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

CustomerItem.propTypes = {
  onOpen: PropTypes.func.isRequired,
  customer: PropTypes.shape({
    number: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    company: PropTypes.string,
    email: PropTypes.string,
    lastOrderDate: PropTypes.string,
    ordersCount: PropTypes.number,
    ordersTotalValueNet: PropTypes.number,
    ordersTotalValueGross: PropTypes.number,
    id: PropTypes.number.isRequired,
  }).isRequired,
};
