import customersApi from '../api/customers';
import {
  setActiveCustomerLoading,
  setActiveCustomer,
  setCustomersLoading,
  setCustomers,
} from '../store/actions/customers';

export const fetchActiveCustomer = customerId => async dispatch => {
  try {
    dispatch(setActiveCustomerLoading(true));
    const customer = await customersApi.fetchOne(customerId);
    dispatch(setActiveCustomer(customer));
    dispatch(setActiveCustomerLoading(false));
  } catch (err) {
    dispatch(setActiveCustomerLoading(false));
  }
};

export const fetchCustomers = params => async dispatch => {
  try {
    dispatch(setCustomersLoading(true));
    const customers = await customersApi.fetchMany(params);
    dispatch(setCustomers(customers));
    dispatch(setCustomersLoading(false));
  } catch (err) {
    dispatch(setCustomersLoading(false));
  }
};
