import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import Chip from '@mui/material/Chip';
import clsx from 'clsx';
import { useTranslator } from '../../i18n';

const useStyles = makeStyles()(theme => ({
  chip: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(.5),
    borderRadius: theme.spacing(0.5),
    height: '26px',
    '& .MuiChip-label': {
      color: theme.palette.common.white,
      paddingLeft: theme.spacing(.75),
    },
  },
  redChip: {
    backgroundColor: '#c62828',
  },
  orangeChip: {
    backgroundColor: '#ef6c00',
  },
  greyChip: {
    backgroundColor: '#9e9e9e',
  },
  greenChip: {
    backgroundColor: '#4caf50',
  },
  blueChip: {
    backgroundColor: '#03a9f4',
  },
}));

const statusColors = {
  OPEN: 'orangeChip',
  IN_PROGRESS: 'blueChip',
  DONE: 'greenChip',
  CLOSED: 'greyChip',
};

const labels = {
  OPEN: 'replenishment-suggestion-status:OPEN',
  IN_PROGRESS: 'replenishment-suggestion-status:IN_PROGRESS',
  DONE: 'replenishment-suggestion-status:DONE',
  CLOSED: 'replenishment-suggestion-status:CLOSED',
};

const getStatusColor = (status) => statusColors[status];

export const ReplenishmentStatusBadge = ({ status, ...props }) => {
  const { classes } = useStyles();
  const t = useTranslator();

  return (
    <Chip
      {...props}
      color={'default'}
      label={t.translate(labels[status])}
      className={clsx(classes.chip, classes[getStatusColor(status)])}
    />
  );
};

ReplenishmentStatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};
