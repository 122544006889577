import { alpha } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme, { open }) => ({
  root: {
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: open ? alpha(theme.palette.common.white, 0.15) : 'initial',
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    display: 'flex',
    flexShrink: 0,
    height: theme.spacing(4.5),
    marginLeft: 0,
    position: 'relative',
    width: '100%',
  },
  rootDynamicWidth: {
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  searchIcon: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 1.5),
    position: open ? 'absolute' : 'relative',
    pointerEvents: 'none',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputRootDynamicWidth: {
    [theme.breakpoints.up('sm')]: {
      transition: theme.transitions.create('width'),
      width: '24ch',
    },
  },
  inputFocusedDynamicWidth: {
    [theme.breakpoints.up('sm')]: {
      width: '44ch',
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1.5, 1, 5.5),
    width: '100%',
  },
  clearButton: {
    height: theme.spacing(4),
    padding: 0,
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(.5),
    width: theme.spacing(4),
    '& .MuiSvgIcon-root': {
      color: theme.palette.common.white,
      fontSize: '1.2rem',
    },
  },
}));
