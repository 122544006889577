import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { styleMobileTableItem } from 'theme/mixins/table';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  bold: {
    fontWeight: 500,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  button: {
    backgroundColor: theme.palette.primary.l10,
    color: theme.palette.primary.main,
    fontSize: '0.75rem',
    textTransform: 'none',
  },
  item: {
    background: theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
  headline: {
    background: theme.palette.backgrounds.main['50'],
    padding: theme.spacing(1, 2),
  },
}));

export const WarehouseItem = ({
  onWarehouseDelete,
  onWarehouseOpen,
  canDeleteWarehouse,
  warehouse,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <Grid item className={classes.item}>
      <Paper>
        <Grid item container direction="column" className={classes.headline}>
          <Typography variant="body2" className={classes.bold}>{warehouse.name || '—'}</Typography>
          <Box>
            <Typography variant="body2" component="span" className={classes.bold}>ID:&nbsp;</Typography>
            <Typography variant="body2" component="span">{warehouse.id || '—'}</Typography>
          </Box>
        </Grid>
        <Divider />
        <Grid item container direction="column" className={classes.paper}>
          <Grid justifyContent="space-between" item container>
            <Typography variant="body2" className={classes.bold}>{t.translate('Address')}</Typography>
            <Grid item>
              <Typography variant="body2">
                {warehouse.address?.name}
              </Typography>
              <Typography variant="body2">
                {warehouse.address?.street}&nbsp;{warehouse.address?.house}
              </Typography>
              <Typography variant="body2">
                {warehouse.address?.zip}&nbsp;{warehouse.address?.city}
              </Typography>
              <Typography variant="body2">{warehouse.address?.country}</Typography>
            </Grid>
          </Grid>
          <Grid item className={classes.buttons}>
            <Button
              aria-label={t.translate('Open the warehouse')}
              className={classes.button}
              startIcon={<InfoIcon />}
              onClick={onWarehouseOpen}
            >
              {t.translate('Details')}
            </Button>
            {canDeleteWarehouse && (
              <Button
                aria-label={t.translate('Delete the warehouse')}
                className={classes.button}
                startIcon={<DeleteIcon />}
                onClick={() => onWarehouseDelete(warehouse)}
              >
                {t.translate('Delete')}
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

WarehouseItem.propTypes = {
  onWarehouseDelete: PropTypes.func.isRequired,
  onWarehouseOpen: PropTypes.func.isRequired,
  canDeleteWarehouse: PropTypes.bool.isRequired,
  warehouse: PropTypes.shape({
    address: PropTypes.shape({
      country: PropTypes.string.isRequired,
      street: PropTypes.string.isRequired,
      house: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    name: PropTypes.string.isRequired,
  }).isRequired,
};
