import React from 'react';
import { StoragesTable } from './StoragesTable/StoragesTable';
import { Header } from './Header/Header';
import { Column } from 'layouts';

export const DesktopView = ({
  canReassignStock,
  onStorageReassign,
  onStoragesPrint,
  onStoragesExport,
  onStoragesImport,
  onTabChange,
  ...props
}) => (
  <>
    <Header
      canReassignStock={canReassignStock}
      onStorageReassign={onStorageReassign}
      onStoragesPrint={onStoragesPrint}
      onStoragesExport={onStoragesExport}
      onStoragesImport={onStoragesImport}
      onTabChange={onTabChange}
    />
    <Column p={3}>
      <StoragesTable {...props} />
    </Column>
  </>
);
