import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  textSecondary: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  bold: {
    fontWeight: 500,
    fontSize: theme.spacing(2.5),
  },
  backButton: {
    padding: 0,
    marginRight: theme.spacing(1.5),
  },
  moreButton: {
    minWidth: '195px',
    [theme.breakpoints.down('md')]: {
      minWidth: '100%',
    },
  },
}));
