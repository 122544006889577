import React, { useState, useCallback } from 'react';
import {
  TableCell,
  SortLabel,
} from 'components';

export const useTableSortableHeadCell = ({
  changeSorting,
  sorting,
  ripple,
}) => useCallback(({ name, children, ...props }) => (
  <TableCell {...props}>
    <SortLabel
      name={name}
      sorting={sorting}
      disableRipple={!ripple}
      onSortingChange={changeSorting}
    >
      {children}
    </SortLabel>
  </TableCell>
  // eslint-disable-next-line react-hooks/exhaustive-deps
), [sorting]);

export const useTableLocallySortableHeadCell = ({
  changeSorting,
  defaultSorting,
  ...props
}) => {
  const [sorting, setSorting] = useState(defaultSorting);
  return useTableSortableHeadCell({
    ...props,
    changeSorting: sorting => {
      changeSorting(sorting);
      setSorting(sorting);
    },
    sorting,
  });
};
