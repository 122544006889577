import React, { useState } from 'react';
import {
  Grid,
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  MenuItem,
  Select,
} from '@mui/material';
import { useTranslator } from '../../i18n';
import toaster from '../../services/toaster';
import { extractApiErrors } from '../../utils/api';
import { closeReplenishmentSuggestions } from '../../api/replenishment-suggestions';
import { InputControl } from 'components';
import dayjs from 'dayjs';

const makeId = postfix => `close-replenishment-suggestion-dialog--${postfix}`;

export const CloseReplenishmentSuggestionDialog = ({
  isMobile,
  onClose,
  selectedSuggestions,
  setSelectedSuggestions,
  onSuccess,
  ...props
}) => {
  const t = useTranslator();

  const [daysOnPause, setDaysOnPause] = useState('');
  const [comment, setComment] = useState('');

  const onSubmit = async () => {
    try {
      const payload = {
        replenishmentSuggestions: selectedSuggestions
          .map((suggestion) => ({
            id: suggestion.id,
          })),
        comment,
        creationPausedUntil: dayjs().add(daysOnPause, 'day').format('YYYY-MM-DD'),
      };

      await closeReplenishmentSuggestions(payload);
      toaster.success(t.translate('Replenishment suggestions has been closed.'));

      setSelectedSuggestions([]);
      onSuccess();
      onClose();
    }
    catch (err) {
      toaster.error(extractApiErrors(err));
    }
  };

  const pauseOptions = [{ text: '7', value: 7 }, { text: '14', value: 14 }, { text: '30', value: 30 }];

  return (
    <Dialog
      open={props.open}
      fullWidth
      fullScreen={isMobile}
      scroll="body"
      maxWidth="lg"
      onClose={onClose}
    >
      <DialogTitle>
        {t.translate('Close selected suggestions')}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <InputControl
              id={makeId('daysOnPause')}
              label={t.translate('Days on pause')}
              InputComponent={() => (
                <Select
                  value={daysOnPause}
                  onChange={({ target: { value } }) => setDaysOnPause(value)}
                >
                  {pauseOptions.map(({ text, value }, index) => (
                    <MenuItem key={index} value={value}>{text ? t.translate(text) : ''}</MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <InputControl
              id={makeId('comment')}
              label={t.translate('Comment')}
              multiline
              rows={5}
              value={comment}
              onChange={({ target: { value } }) => setComment(value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t.translate('dialog-action:Cancel')}
        </Button>
        <Button
          color="primary"
          onClick={onSubmit}
        >
          {t.translate('Submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
