import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './IntegerFormControl.jsx';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';

export const IntegerFormControl = ({
  onChange,
  initialValue,
  rightLabel,
  leftLabel,
  disabled,
  value,
  min,
  max,
}) => {
  const { classes } = useStyles();

  const change = value => {
    if ((value.length === 1 && value === '-')
      || (value.length === 2 && (value === '-0' || value === '0-'))) {
      onChange('-');
      return;
    }

    let pureValue = parseInt(value, 10);

    if (isNaN(pureValue)) {
      pureValue = 0;
    }

    const sum = pureValue + initialValue;

    const checkMin = () => typeof min === 'number'
      ? sum < min ? min - initialValue : pureValue
      : pureValue;

    onChange(
      typeof max === 'number'
        ? sum > max
          ? max - initialValue
          : checkMin()
        : checkMin()
    );
  };

  const canNotSubtract = disabled || (typeof min === 'number' && value + initialValue <= min);
  const canNotAdd = disabled || (typeof max === 'number' && value + initialValue >= max);

  return (
    <Grid
      className={classes.root}
      container
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
    >
      <Grid item xs className={classes.labelItem}>
        <Typography color="textSecondary" variant="body2">
          <span>{leftLabel}</span>: {initialValue}
        </Typography>
      </Grid>
      <Grid item className={classes.controlsItem}>
        <Button
          className={classes.button}
          disabled={canNotSubtract}
          variant="contained"
          color="secondary"
          onClick={() => change(value - 1)}
          aria-label="subtract stock"
        >
          <RemoveIcon />
        </Button>
        <Input
          className={classes.input}
          disabled={disabled}
          value={(value > 0 ? '+' : '') + value}
          onChange={e => change(e.target.value)}
          aria-label="stock"
        />
        <Button
          className={classes.button}
          disabled={canNotAdd}
          variant="contained"
          color="secondary"
          onClick={() => change(value + 1)}
          aria-label="add stock"
        >
          <AddIcon />
        </Button>
      </Grid>
      <Grid item xs className={classes.labelItem}>
        <Typography color="textSecondary" variant="body2" align="right">
          <span>{rightLabel}</span>: {value + initialValue}
        </Typography>
      </Grid>
    </Grid>
  );
};

IntegerFormControl.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.number,
  rightLabel: PropTypes.string.isRequired,
  leftLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
};

IntegerFormControl.defaultProps = {
  initialValue: 0,
  disabled: false,
};
