import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslator } from 'i18n';
import {
  AppHeaderPrimaryToolbar,
  AppHeaderBackButton,
  AppHeaderRightBox,
  QuerySearchInput,
  AppHeaderTitle,
  AppHeader,
} from 'components';

export const Header = () => {
  const t = useTranslator();
  const history = useHistory();

  const storageProduct = useSelector(({ products }) => products.history.storageProduct);

  return (
    <AppHeader>
      <AppHeaderPrimaryToolbar>
        <AppHeaderBackButton edge="start" onClick={() => history.goBack()} />
        <AppHeaderTitle>{t.translate('Storage')}: {storageProduct?.storage.id}</AppHeaderTitle>
        <AppHeaderRightBox>
          <QuerySearchInput placeholder={t.translate('Search by order no or user name')} />
        </AppHeaderRightBox>
      </AppHeaderPrimaryToolbar>
    </AppHeader>
  );
};
