import merge from 'lodash/merge';

export const styleTable = (theme, styles = {}) => merge({
  marginLeft: theme.spacing(-3),
  marginRight: theme.spacing(-3),
  '& th:first-of-type, & td:first-of-type': {
    paddingLeft: theme.spacing(3),
  },
  '& th:last-child, & td:last-child': {
    paddingRight: theme.spacing(3),
  },
}, styles);

export const styleTableRow = (theme, styles = {}) => merge({
  table: {
    '& th:first-of-type, & td:first-of-type': {
      paddingLeft: theme.spacing(2),
    },
  },
}, styles);

export const styleMobileTableItem = (theme, styles = {}) => merge({
  paper: {
    padding: theme.spacing(2),
  },
  headline: {
    display: 'flex',
    width: '100%',
    '& .MuiGrid-item': {
      alignItems: 'center',
      display: 'flex',
      '&:last-child': {
        justifyContent: 'right',
      },
    },
  },
  summary: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(-1.5),
    '& > p': {
      marginTop: theme.spacing(.5),
    },
  },
  label: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(.25),
  },
}, styles);
