import React from 'react';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslator } from 'i18n';
import {
  useTableSortableHeadCell,
  useList,
} from 'hooks';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {
  ActionButton,
  BooleanIcon,
  Pagination,
  CustomTableRow,
} from 'components';

import { selectAuthenticatedUser } from 'store/selectors/account';
import { usersDataWithMeta } from 'store/selectors/users';
import { humanizeDefaultView } from 'services/account';
import { wrapTableRowClick } from 'utils/html';
import { humanizeLocale } from 'services/i18n/locale';
import tables from 'config/tables';

export const UsersTable = () => {
  const history = useHistory();
  const list = useList(tables.users.users);
  const t = useTranslator();

  const { data: users, meta } = useSelector(usersDataWithMeta);
  const account = useSelector(selectAuthenticatedUser);

  const SortableCell = useTableSortableHeadCell({
    changeSorting: list.changeSorting,
    sorting: list.sorting,
  });

  const openUser = user => history.push(`/settings/users/${user.id}`);
  const openAccount = () => history.push('/settings/account');

  return (
    <>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <SortableCell name="id">ID</SortableCell>
              <SortableCell name="name">{t.translate('Name')}</SortableCell>
              <SortableCell name="email">{t.translate('Email')}</SortableCell>
              <SortableCell name="isAdmin">{t.translate('Administrator')}</SortableCell>
              <SortableCell name="isActive">{t.translate('Active')}</SortableCell>
              <SortableCell name="defaultView">{t.translate('Default view')}</SortableCell>
              <SortableCell name="locale">{t.translate('Language')}</SortableCell>
              <SortableCell name="createdAt">{t.translate('Created')}</SortableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, index) => (
              <CustomTableRow key={index} hover onClick={wrapTableRowClick(() => user.id === account.id ? openAccount() : openUser(user))} >
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <BooleanIcon truthy={user.isAdmin} />
                </TableCell>
                <TableCell>
                  <BooleanIcon truthy={user.isActive} />
                </TableCell>
                <TableCell>{t.translate(humanizeDefaultView(user.defaultView))}</TableCell>
                <TableCell>{humanizeLocale(user.locale, { t })}</TableCell>
                <TableCell>{t.formatDate(user.createdAt)} {t.formatTime(user.createdAt)}</TableCell>
                <TableCell align="right">
                  {user.id === account.id ? (
                    <ActionButton
                      aria-label={t.translate('My account')}
                      tooltip={t.translate('verb:Open')}
                      icon={<AccountCircleIcon />}
                      onClick={openAccount}
                    />
                  ) : (
                    <ActionButton
                      aria-label={t.translate('Open user')}
                      tooltip={t.translate('verb:Open')}
                      icon={<ChevronRightIcon />}
                      onClick={() => openUser(user)}
                    />
                  )}
                </TableCell>
              </CustomTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination {...list.paginationProps} count={meta.totalEntities} />
    </>
  );
};

UsersTable.propTypes = {};
