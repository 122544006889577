import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { useFormik } from 'formik';
import { useReactToPrint } from 'react-to-print';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import {
  StorageTicket,
  InputControl,
  BusyButton,
} from 'components';

import { extractApiErrors } from 'utils/api';
import { styleFormDialog } from 'theme/mixins/dialog';
import { fetchStorages } from 'api/storages';
import toaster from 'services/toaster';

const useStyles = makeStyles()(theme => styleFormDialog(theme, {
  content: {
    '&.MuiDialogContent-root': {
      width: 312,
    },
  },
  ticket: {
    pageBreakAfter: 'always',
  },
}));

const makeId = postfix => `printing-storages-dialog--${postfix}`;

export const PrintingStoragesDialog = ({
  onClose,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const [storages, setStorages] = useState(null);
  const ticketRef = useRef(null);

  const initialValues = {
    startId: '',
    endId: '',
  };

  const validationSchema = Yup
    .object()
    .shape({
      startId: Yup
        .number()
        .integer(t.translate('The start ID must be a positive integer number.'))
        .positive(t.translate('The start ID must be a positive integer number.'))
        .lessThan(Yup.ref('endId'), t.translate('The start ID must be less than the end ID.')),
      endId: Yup
        .number()
        .integer(t.translate('The end ID must be a positive integer number.'))
        .positive(t.translate('The end ID must be a positive integer number.')),
    });

  const onSubmit = async (values, { setErrors }) => {
    try {
      const storages = await fetchStorages(values);
      if (storages.meta.totalEntities) {
        while (storages.data.length < storages.meta.totalEntities) {
          storages.data.push(...(await fetchStorages({ ...values, offset: storages.data.length })).data);
        }
        setStorages(storages);
      } else {
        toaster.warning(t.translate('No storages found.'));
      }
    } catch (err) {
      setErrors(extractApiErrors(err));
    }
  };

  const {
    handleSubmit,
    handleChange,
    resetForm,
    values,
    errors,
  } = useFormik({
    validateOnMount: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  const handlePrint = useReactToPrint({
    content: () => ticketRef.current,
    onAfterPrint: () => {
      setStorages(null);
      onClose();
    },
  });

  useEffect(() => {
    if (storages) {
      handlePrint();
    }
  }, [handlePrint, storages]);

  useEffect(() => {
    if (props.open) {
      resetForm();
    }
  }, [setStorages, resetForm, props.open]);

  return (
    <Dialog scroll="body" {...props} className={classes.root} onClose={onClose}>
      <DialogTitle className={classes.title}>{t.translate('Print storages')}</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container direction="column" align="center" spacing={3}>
          <Grid item>
            <InputControl
              id={makeId('start-id')}
              name="startId"
              error={errors.startId}
              label={t.translate('Start ID')}
              value={values.startId}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <InputControl
              id={makeId('end-id')}
              name="endId"
              error={errors.endId}
              label={t.translate('End ID')}
              value={values.endId}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <div
          style={{
            height: 0,
            overflow: 'hidden',
            position: 'relative',
            width: '2cm',
          }}
        >
          <div ref={ticketRef}>
            {storages?.data.map(storage => (
              <StorageTicket
                key={storage.id}
                storage={storage}
                className={classes.ticket}
              />
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button color="primary" onClick={onClose}>{t.translate('Cancel')}</Button>
        <BusyButton onClick={handleSubmit}>{t.translate('Print')}</BusyButton>
      </DialogActions>
    </Dialog>
  );
};

PrintingStoragesDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};
