import React from 'react';
import { useSelector } from 'react-redux';

import { useTranslator } from 'i18n';
import {
  AppHeaderPrimaryToolbar,
  AppHeaderRightBox,
  QuerySearchInput,
  AppHeaderTitle,
  AppHeader,
  AppHeaderMenuButton,
  ViewSwitch,
} from 'components';

import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import PublishIcon from '@mui/icons-material/Publish';

import { PurchasesFilter } from '../PurchasesFilter/PurchasesFilter';
import { selectActionPermission } from 'store/selectors/account';

import { REPLENISHMENT_SUGGESTIONS_LABEL } from 'pages/purchases/Overview/config';

export const Header = ({
  isMobile,
  onImportPurchasesClick,
  routeTitle,
  activeOption,
  allowedOptions,
  screen,
}) => {
  const t = useTranslator();
  const canImportPurchases = useSelector(selectActionPermission('purchases', 'import'));

  return (
    <AppHeader>
      <AppHeaderPrimaryToolbar>
        {isMobile && <AppHeaderMenuButton edge="start" />}
        <AppHeaderTitle>{t.translate(routeTitle || 'Purchases')}</AppHeaderTitle>
        <AppHeaderRightBox>
          {routeTitle !== REPLENISHMENT_SUGGESTIONS_LABEL
            ? <Grid container spacing={2} alignItems="center">
              {canImportPurchases && (
                <Grid item>
                  <Button
                    startIcon={<PublishIcon/>}
                    onClick={onImportPurchasesClick}
                    color="inherit"
                  >
                    {t.translate('Import')}
                  </Button>
                </Grid>
              )}
              <Grid item>
                <PurchasesFilter/>
              </Grid>
              <Grid item>
                <Tooltip
                  placement="bottom"
                  title={t.translate('Search by product ID, EAN, Alzura ID, name, description, manufacturer no or name')}
                >
                  <Grid>
                    <QuerySearchInput/>
                  </Grid>
                </Tooltip>
              </Grid>
            </Grid>
            : <QuerySearchInput placeholder={t.translate('Search by supplier number or name')} hideInput={isMobile}/>
          }
        </AppHeaderRightBox>
      </AppHeaderPrimaryToolbar>
      {allowedOptions.length > 1 ? <ViewSwitch options={allowedOptions} isMobile={screen.isMobile} activeOption={activeOption}/> : null}
    </AppHeader>
  );
};

Header.propTypes = {
};
