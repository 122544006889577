import {
  Permissions,
  DefaultView,
  Password,
  Language,
  Data,
} from 'views/account';

const createRoute = (key, title, description, paths, View) => ({
  View,
  description,
  title,
  paths,
  key,
});

export const routes = [
  createRoute('data', 'User data', 'Change email and name', ['/settings/account/data', '/settings/account'], Data),
  createRoute('password', 'Password', 'Change password', ['/settings/account/password'], Password),
  createRoute('language', 'Language', 'Change application language', ['/settings/account/language'], Language),
  createRoute('defaultView', 'Default view', 'Change default view', ['/settings/account/default-view'], DefaultView),
  createRoute('permissions', 'Permissions', 'See application permissions', ['/settings/account/permissions'], Permissions),
];
