import companiesApi from 'api/companies';
import {
  setOverviewCompanies,
  setDetailsCompany,
} from 'store/actions/companies';
import {
  startLoading,
  stopLoading,
} from 'store/actions/app';

export const fetchCompanies = params => async dispatch => {
  try {
    dispatch(startLoading('companies.overview.companies'));
    const companies = await companiesApi.fetchMany(params);
    dispatch(setOverviewCompanies(companies));
    dispatch(stopLoading('companies.overview.companies'));
  } catch (err) {
    dispatch(stopLoading('companies.overview.companies'));
  }
};

export const fetchCompany = companyId => async dispatch => {
  try {
    dispatch(startLoading('companies.details.companies'));
    const company = await companiesApi.fetchOne(companyId);
    dispatch(setDetailsCompany(company));
    dispatch(stopLoading('companies.details.companies'));
  } catch (err) {
    dispatch(stopLoading('companies.details.companies'));
  }
};
