import { USERS } from 'store/types';
import { users as initialState } from 'store/initialState';

const setOverviewUsers = (state, { data, meta }) => ({
  ...state,
  overview: {
    data,
    meta: {
      totalEntities: meta.totalEntities,
    },
  },
});

const setDetailsUserWarehouseAvailability = (state, { isEnabled, warehouseId }) => ({
  ...state,
  details: {
    ...state.details,
    user: {
      ...state.details.user,
      allowedWarehouseIds: isEnabled
        ? state.details.user.allowedWarehouseIds.includes(warehouseId)
          ? state.details.user.allowedWarehouseIds
          : [...state.details.user.allowedWarehouseIds, warehouseId]
        : state.details.user.allowedWarehouseIds.includes(warehouseId)
          ? state.details.user.allowedWarehouseIds.filter(v => v !== warehouseId)
          : state.details.user.allowedWarehouseIds,
    },
  },
});

const setDetailsWarehouses = (state, warehouses) => ({
  ...state,
  details: {
    ...state.details,
    warehouses,
  },
});

const setDetailsUser = (state, user) => ({
  ...state,
  details: {
    ...state.details,
    user,
  },
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case USERS.OVERVIEW.SET_USERS:
    return setOverviewUsers(state, payload);
  case USERS.DETAILS.SET_USER_WAREHOUSE_AVAILABILITY:
    return setDetailsUserWarehouseAvailability(state, payload);
  case USERS.DETAILS.SET_WAREHOUSES:
    return setDetailsWarehouses(state, payload);
  case USERS.DETAILS.SET_USER:
    return setDetailsUser(state, payload);
  default:
    return state;
  }
};
