import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';
import { useScreen } from 'hooks';

import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { QrCodeScanDialog } from 'dialogs';

export const QrCodeScanButton = ({ onComplete }) => {
  const t = useTranslator();
  const screen = useScreen();
  const [dialogVisible, setDialogVisible] = useState(false);

  return <>
    {dialogVisible && (
      <QrCodeScanDialog
        open={dialogVisible}
        onClose={() => setDialogVisible(false)}
        onDecode={onComplete}
      />
    )}

    {screen.isMobile ? (
      <IconButton
        color="inherit"
        onClick={() => setDialogVisible(true)}
        aria-label="scan qr code"
        size="large">
        <PhotoCameraIcon />
      </IconButton>
    ) : (
      <Button
        color="inherit"
        onClick={() => setDialogVisible(true)}
        aria-label="scan qr code"
        startIcon={<PhotoCameraIcon />}
        style={{ textTransform: 'none' }}
      >
        {t.translate('Scan')}
      </Button>
    )}
  </>;
};

QrCodeScanButton.propTypes = {
  onComplete: PropTypes.func.isRequired,
};
