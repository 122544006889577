import React from 'react';
import PropTypes from 'prop-types';
import { useRoute } from 'hooks/route';
import { useTranslator } from 'i18n';
import Link from '@mui/material/Link';
import { StorageProductOrSpecialCaseActionReasons } from 'consts';

export const StockOperationOrder = ({
  isHref,
  operation: {
    pickupSheetId,
    orderCode,
    reason,
    manual,
    order,
    targetStorageId,
  },
}) => {
  const t = useTranslator();
  const route = useRoute();

  const getProps = (page) => {
    const links = {
      order: `/orders/${order?.id}`,
      storage: `/storages/${targetStorageId}`,
      pickupSheet: `/pickup-sheets/${pickupSheetId}`,
    };

    const link = links[page];

    return isHref ? { href: link } : { onClick: () => route.history.push(link) };
  };

  return reason === 'order' || reason === 'reserve'
    ? <Link { ...getProps('order') }>{order?.number || '—'}</Link>
    : reason === 'reassign-stock' && targetStorageId
      ? <Link { ...getProps('storage') }>{`${t.translate('Storage')} ${targetStorageId}`}</Link>
      : reason === 'pickup-sheet'
        ? <Link { ...getProps('pickupSheet') }>{pickupSheetId}</Link>
        : orderCode || (manual ? t.translate('Manually created') : '—');
};

StockOperationOrder.propTypes = {
  isHref: PropTypes.bool,
  operation: PropTypes.shape({
    pickupSheetId: PropTypes.number,
    orderCode: PropTypes.string,
    reason: PropTypes.oneOf(Object.keys(StorageProductOrSpecialCaseActionReasons)).isRequired,
    manual: PropTypes.bool,
    order: PropTypes.shape({
      number: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
    targetStorageId: PropTypes.number,
  }),
};
