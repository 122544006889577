const KEYS = {
  USER_DEFAULT_PRODUCT_GROUPS: 'product.default_product_groups',
};

export const setUserDefaultProductGroups = (defaultProductGroups) => {
  if (defaultProductGroups && Array.isArray(defaultProductGroups)) {
    localStorage.setItem(KEYS.USER_DEFAULT_PRODUCT_GROUPS, JSON.stringify(defaultProductGroups));
  }
};

export const getUserDefaultProductGroups = () => {
  let defaultProductGroups;
  const ls = localStorage.getItem(KEYS.USER_DEFAULT_PRODUCT_GROUPS);
  try {
    defaultProductGroups = JSON.parse(ls);
  } catch (err) {
    defaultProductGroups = null;
  }
  return defaultProductGroups;
};

export const cleanUserDefaultProductGroups = () => {
  localStorage.removeItem(KEYS.USER_DEFAULT_PRODUCT_GROUPS);
};
