import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';

import OrderItem from './OrderItem/OrderItem';
import { Column } from 'layouts';
import {
  CircularLoader,
} from 'components';

import {
  selectOrdersLoading,
  selectOrdersData,
} from 'store/selectors/orders';

export const MobileView = ({
  onOrderApprove,
  onOrderCancel,
  canApprove,
  canPerformOrderActions,
}) => {
  const isLoading = useSelector(selectOrdersLoading);
  const orders = useSelector(selectOrdersData);

  return (
    <>
      <Column p={1} pb={8.5} pt={2}>
        {isLoading ? (
          <CircularLoader />
        ) : (
          <Grid container direction="column" spacing={1}>
            {orders.map(order => (
              <OrderItem
                key={order.id}
                order={order}
                canPerformOrderActions={canPerformOrderActions}
                canApprove={canApprove}
                onApprove={() => onOrderApprove(order)}
                onCancel={() => onOrderCancel(order)}
              />
            ))}
          </Grid>
        )}
      </Column>
    </>
  );
};

MobileView.propTypes = {
  onOrderApprove: PropTypes.func.isRequired,
  onOrderCancel: PropTypes.func.isRequired,
  canApprove: PropTypes.bool.isRequired,
  canPerformOrderActions: PropTypes.bool.isRequired,
};
