import React from 'react';
import PropTypes from 'prop-types';

import Input from '@mui/material/Input';
import { TableCell } from '..';

export const TextFilterCell = ({
  onToggle,
  onChange,
  enabled,
  value,
  className,
  cellClassName,
  ...props
}) => (
  <TableCell className={ cellClassName } {...props}>
    <Input
      value={value}
      disableUnderline
      onChange={({ target: { value } }) => onChange(value)}
      classes={{ input: className }}
    />
  </TableCell>
);

TextFilterCell.propTypes = {
  onToggle: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
};
