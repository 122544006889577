import { useDialog } from './dialog';
import { useState } from 'react';
import {
  fetchB2BProducts,
  fetchProducts,
  fetchProduct,
} from 'api/products';
import tables from 'config/tables';
import axios from 'axios';

export const useProductCodesFormControls = ({
  setManufacturerNumber,
  setManufacturerId,
  setProductEan,
  setProductAlzuraId,
  setRestProductData,
}) => {
  const [foundB2BProduct, setFoundB2BProduct] = useState(null);
  const [foundProduct, setFoundProduct] = useState(null);
  const [cancelSource, setCancelSource] = useState(null);

  const selectingDialog = useDialog({
    b2bProducts: null,
    products: null,
  }, {
    tableOptions: tables.dialogs.selectingProduct,
    properties: {},
    fetching: {},
  });

  const selectProduct = ({
    manufacturerNumber,
    manufacturerId,
    ean,
    alzuraId,
  }) => ({
    identified,
    b2bProduct,
    product,
  }) => {
    setFoundB2BProduct(b2bProduct || null);
    setFoundProduct(product || null);
    if (product) {
      if (!ean) {
        setProductEan(product.ean || '');
      }
      if (!alzuraId) {
        setProductAlzuraId(product.alzuraId || '');
      }
      setManufacturerId(product.manufacturerId || '');
      setManufacturerNumber(product.manufacturerNumber || '');
      setRestProductData({ product });
    } else if (b2bProduct) {
      if (!manufacturerNumber) {
        setManufacturerNumber(b2bProduct.manufacturerNumber || '');
      }
      if (!ean) {
        setProductEan(b2bProduct.ean || '');
      }
      if (!manufacturerId) {
        setManufacturerId(b2bProduct.manufacturerId || '');
      }
      setProductAlzuraId(b2bProduct.alzuraId || '');
      setRestProductData({ identified, b2bProduct });
    } else {
      if (!manufacturerNumber) {
        setManufacturerNumber('');
      }
      if (!ean) {
        setProductEan('');
      }
      if (!alzuraId) {
        setProductAlzuraId('');
      }
      if (!manufacturerId) {
        setManufacturerId('');
      }
      setRestProductData({});
    }
  };

  const changeProductCode = async ({
    manufacturerNumber,
    ean,
    manufacturerId,
    alzuraId,
    id,
  }) => {
    if (cancelSource) {
      cancelSource.cancel();
    }
    const source = axios.CancelToken.source();
    setCancelSource(source);

    if (manufacturerNumber !== undefined) {
      setManufacturerNumber(manufacturerNumber);
    }
    if (ean !== undefined) {
      setProductEan(ean);
    }
    if (alzuraId !== undefined) {
      setProductAlzuraId(alzuraId);
    }
    if (manufacturerId !== undefined) {
      setManufacturerId(manufacturerId);
    }

    let b2bProducts;
    let products;
    let product;
    let fetched = false;
    try {
      if (id) {
        selectingDialog.patchOptions({ fetching: { id: true } });
        product = await fetchProduct(id, { cancelToken: source.token });
        fetched = true;
      } else if (ean) {
        selectingDialog.patchOptions({ fetching: { ean: true } });
        products = await fetchProducts({ ...selectingDialog.options.tableOptions, ean }, { cancelToken: source.token });
        if (!products.data.length) {
          b2bProducts = await fetchB2BProducts({ ean }, { cancelToken: source.token });
        }
        fetched = true;
      } else if (alzuraId) {
        selectingDialog.patchOptions({ fetching: { alzuraId: true } });
        products = await fetchProducts({ ...selectingDialog.options.tableOptions, alzuraId }, { cancelToken: source.token });
        fetched = true;
      } else if (manufacturerNumber) {
        selectingDialog.patchOptions({ fetching: { manufacturerNumber: true } });
        b2bProducts = await fetchB2BProducts({ manufacturerNumber }, { cancelToken: source.token });
        fetched = true;
      } else if (manufacturerNumber) {
        selectingDialog.patchOptions({ fetching: { manufacturerId: true } });
        b2bProducts = await fetchB2BProducts({ manufacturerId }, { cancelToken: source.token });
        fetched = true;
      }
    } catch (err) {
      fetched = !axios.isCancel(err);
    }

    if (fetched) {
      setCancelSource(null);
      if (products?.data.length) {
        selectingDialog.open({ b2bProducts: null, products }, { properties: { manufacturerNumber, manufacturerId, ean, alzuraId }, fetching: {} });
      } else if (b2bProducts?.length) {
        selectingDialog.open({ b2bProducts, products: null }, { properties: { manufacturerNumber, manufacturerId, ean, alzuraId }, fetching: {} });
      } else {
        selectingDialog.patch({ b2bProducts: null, products: null }, { properties: {}, fetching: {} });
        selectProduct({
          manufacturerNumber,
          manufacturerId,
          ean,
          alzuraId,
        })({
          b2bProduct: null,
          product: product ?? null,
        });
      }
    }
  };

  const updateProducts = async tableOptions => {
    try {
      const { ean, alzuraId } = selectingDialog.options.properties;
      const products = await fetchProducts({
        ...selectingDialog.options.tableOptions,
        ...tableOptions,
        ...(ean && { ean }),
        ...(alzuraId && { alzuraId }),
      });
      selectingDialog.patch({ products }, {
        tableOptions: {
          ...selectingDialog.options.tableOptions,
          ...tableOptions,
        },
      });
    } catch (err) {}
  };

  return {
    changeProductCode,
    updateProducts,
    selectProduct,
    selectingDialog,
    foundB2BProduct,
    foundProduct,
  };
};
