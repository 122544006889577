import React from 'react';
import PropTypes from 'prop-types';

import { Column } from 'layouts';

import { ProductsTable } from './ProductsTable/ProductsTable';
import { StorageCard } from './StorageCard/StorageCard';

export const DesktopView = ({
  canViewStorageProducts,
  storageProducts,
  storage,
}) => {
  if (storage) {
    return (
      <Column pb={8} p={3} pt={0}>
        <StorageCard storage={storage} />
        {canViewStorageProducts && <ProductsTable storageProducts={storageProducts} />}
      </Column>
    );
  } else {
    return <Column p={3} pb={8} />;
  }
};

DesktopView.propTypes = {
  canViewStorageProducts: PropTypes.bool.isRequired,
  storageProducts: PropTypes.object.isRequired,
  storage: PropTypes.object,
};
