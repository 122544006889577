import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import toaster from 'services/toaster';
import { didUpdate, useForm } from 'hooks';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';

import {
  OrderAutocomplete,
  useOrderAutocomplete,
  useSupplierAutocomplete,
} from 'autocompletes';

import {
  ProductQrCodeControl,
  StockFormControl,
  FormControlError,
  PriceControl,
  InputControl,
  BusyButton,
} from 'components';

import { modifySpecialCase } from 'api/special-cases';
import {
  SpecialCaseStatuses,
} from 'consts';
import {
  determineStatuses,
  isStatusPrevious,
  makeId,
} from './service';

const useStyles = makeStyles()(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: theme.spacing(84),
    },
  },
  title: {
    paddingBottom: 0,
  },
  content: {
    padding: theme.spacing(3),
  },
  stockFormContainer: {
    padding: theme.spacing(3),
    border: `solid 1px ${theme.palette.grey['255']}`,
  },
  buttonContainer: {
    padding: theme.spacing(3),
  },
}));

export const SpecialCaseModifyDialog = ({
  specialCase,
  isMobile,
  onClose,
  onSave,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const statuses = useMemo(() => determineStatuses(specialCase.status), [specialCase.status]);

  const form = useForm({
    productName: specialCase.productName || '',
    supplierNumber: '',
    comment: '',
    price: t.formatPrice(specialCase.price || 0),
    status: specialCase.status,
    stock: 0,
    alzuraId: specialCase.product?.alzuraId || '',
    productId: specialCase.product?.id || '',
    orderCode: specialCase.orderCode || '',
    ean: specialCase.product?.ean || '',
  });
  const {
    catchHttpError,
    isModelValid,
    setFormValue,
    resetForm,
    setValue,
    errors,
    model,
  } = form;

  const supplierAutocomplete = useSupplierAutocomplete({ form });
  const orderAutocomplete = useOrderAutocomplete({ form });

  didUpdate(() => {
    if (props.open) {
      orderAutocomplete.setSelectedOption(specialCase.orderCode ? { code: specialCase.orderCode } : null);
      resetForm();
    }
  }, [props.open]);

  const validateModel = errors => {
    if (model.productId && !model.productName) {
      errors.productName = t.translate('Please specify a product name.');
    }
    if (t.parsePrice(model.price) === undefined) {
      errors.price = t.translate('Please specify a valid purchase price.');
    }
    if (!orderAutocomplete.selectedOption && model.orderCode) {
      errors.orderCode = t.translate('Please specify an existing order.');
    }
    if (isStatusPrevious(model.status, specialCase.status, statuses) && !model.comment.trim()) {
      errors.comment = t.translate('Please enter a comment.');
    }
  };

  const submit = async () => {
    try {
      if (!isModelValid(validateModel)) {
        return;
      }

      const payload = {
        ...(({
          price,
          ...model
        }) => ({
          ...model,
          price: t.parsePrice(price),
        }))(model),
        ...(orderAutocomplete.selectedOption ? {
          orderCode: orderAutocomplete.selectedOption.code,
        } : {}),
        ...(supplierAutocomplete.selectedOption && { supplierId: supplierAutocomplete.selectedOption.id }),
      };

      await modifySpecialCase(specialCase.id, payload);
      toaster.success(t.translate('Special case has been saved successfully.'));

      onSave();
      onClose();
    } catch (err) {
      catchHttpError(err);
    }
  };

  return (
    <Dialog
      {...props}
      fullScreen={isMobile}
      onClose={onClose}
      className={classes.root}
    >
      <DialogTitle className={classes.title}>
        {t.translate('Edit special case')}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container direction="column" spacing={3}>
          <Grid item container spacing={3} direction={isMobile ? 'column' : 'row'}>
            <Grid item md={6}>
              <FormControl fullWidth error={!!errors.status}>
                <InputLabel shrink htmlFor={makeId('status')}>Status</InputLabel>
                <Select id={makeId('status')} value={model.status} onChange={setFormValue('status')}>
                  {statuses.map(({ key, text, disabled }) => (
                    <MenuItem key={key} value={key} disabled={disabled}>{t.translate(text)}</MenuItem>
                  ))}
                </Select>
                {!!errors.status && <FormControlError error={errors.status} />}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <OrderAutocomplete
                {...orderAutocomplete.props}
                label={t.translate('Order no')}
              />
            </Grid>
          </Grid>

          <Grid item container spacing={3} direction={isMobile ? 'column' : 'row'}>
            <Grid item md={4}>
              <InputControl
                id={makeId('ean')}
                error={errors.ean}
                label={'EAN'}
                value={model.ean}
                onChange={setFormValue('ean')}
              />
            </Grid>
            <Grid item md={4}>
              <InputControl
                id={makeId('alzura-id')}
                error={errors.alzuraId}
                label={'Alzura ID'}
                value={model.alzuraId}
                onChange={setFormValue('alzuraId')}
              />
            </Grid>
            <Grid item md={4}>
              <ProductQrCodeControl
                id={makeId('product-id')}
                error={errors.productId}
                label={t.translate('Product ID')}
                value={model.productId}
                onChange={setValue('productId')}
              />
            </Grid>
          </Grid>

          <Grid item container spacing={3} direction={isMobile ? 'column' : 'row'}>
            <Grid item md={8}>
              <InputControl
                id={makeId('product-name')}
                error={errors.productName}
                label={t.translate('Designation')}
                value={model.productName}
                onChange={setFormValue('productName')}
              />
            </Grid>
            <Grid item md={4}>
              <PriceControl
                id={makeId('price')}
                error={errors.price}
                label={t.translate('Price')}
                value={model.price}
                onChange={setFormValue('price')}
              />
            </Grid>
          </Grid>

          <Grid item container spacing={3} direction={isMobile ? 'column' : 'row'}>
            <Grid item md={12}>
              <InputLabel shrink htmlFor={props.id}>{t.translate('Stock')}</InputLabel>
              <Grid className={classes.stockFormContainer}>
                <FormControl fullWidth error={!!errors.stock}>
                  <StockFormControl
                    actions={['add', ...(model.stock > 0 ? ['subtract'] : [])]}
                    stock={specialCase.stock}
                    value={model.stock}
                    onChange={setValue('stock')}
                  />
                  {!!errors.stock && <FormControlError error={errors.stock} />}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container direction={isMobile ? 'column' : 'row'}>
            <Grid item md={12}>
              <InputControl
                id={makeId('comment')}
                rows={2}
                error={errors.comment}
                label={t.translate('Comment')}
                value={model.comment}
                multiline
                placeholder={t.translate('Annotations')}
                onChange={setFormValue('comment')}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.buttonContainer}>
        <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
        <BusyButton color="primary" submit onClick={submit}>{t.translate('Save')}</BusyButton>
      </DialogActions>
    </Dialog>
  );
};

SpecialCaseModifyDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  specialCase: PropTypes.shape({
    productName: PropTypes.string,
    product: PropTypes.shape({
      id: PropTypes.number,
    }),
    status: PropTypes.oneOf(Object.keys(SpecialCaseStatuses)).isRequired,
    stock: PropTypes.number.isRequired,
    storageId: PropTypes.number.isRequired,
    orderCode: PropTypes.string,
    id: PropTypes.number.isRequired,
  }).isRequired,
};
