import { makeStyles } from 'tss-react/mui';
import { styleMobileTableItem } from 'theme/mixins/table';

export const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  headline: {
    marginBottom: theme.spacing(-.5),
  },
  administratorLabel: {
    marginRight: theme.spacing(.5),
  },
  summaryGrid: {
    minHeight: '100%',
  },
  datetimeItem: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: theme.spacing(2.25),
    marginTop: theme.spacing(2),
  },
  buttonsItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(-1.5),
    marginRight: theme.spacing(-1.5),
  },
  disabled: {
    backgroundColor: theme.palette.action.hover,
  },
}));
