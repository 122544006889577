import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  popover: {
    top: theme.spacing(.5),
    '& .MuiPopover-paper': {
      marginLeft: theme.spacing(1),
      overflow: 'visible',
    },
  },
  arrow: {
    color: theme.palette.common.white,
    fontSize: '2rem',
    position: 'absolute',
    right: theme.spacing(1.5),
    top: theme.spacing(-2.25),
  },
  box: {
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    position: 'relative',
  },
  button: {
    color: 'inherit',
    height: 'inherit',
    textTransform: 'none',
    minWidth: theme.spacing(4),
  },
}));
