import api from '.';
import { extractListParams } from 'utils/querying';

export const fetchStatisticsReports = params => api.get('/statistics-reports', { params: extractListParams(params) });

export const createStatisticsReport = payload => api.post('/statistics-reports', payload);

export const fetchManyWarehouseInventoryReports = params => api.get('/warehouse-inventory-reports', { params: extractListParams(params) });

export const createOneWarehouseInventoryReport = payload => api.post('/warehouse-inventory-reports', payload);

export default {
  fetchStatisticsReports,
  createStatisticsReport,
  fetchManyWarehouseInventoryReports,
  createOneWarehouseInventoryReport,
};
