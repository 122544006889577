import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: theme.zIndex.cameraStream,
  },
  viewport: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    '& video': {
      height: '100%',
    },
  },
  actions: {
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
    left: 0,
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    position: 'absolute',
    right: 0,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
    },
  },
  button: {
    color: theme.palette.common.white,
  },
}));
