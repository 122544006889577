import React, { useState } from 'react';

import { useRoute } from 'hooks/route';

import { useTranslator } from 'i18n';
import { EanCodeScanDialog } from 'dialogs';
import { useScreen } from 'hooks';

import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

export const QrCodeScanner = () => {
  const screen = useScreen();
  const t = useTranslator();
  const route = useRoute();

  const [scannerVisible, setScannerVisible] = useState(false);

  const applyEanCode = eanCode => route.push({ search: { ...route.search, q: eanCode } });

  return <>
    <EanCodeScanDialog
      open={scannerVisible}
      onClose={() => setScannerVisible(false)}
      onDecode={applyEanCode}
    />

    {screen.isMobile ? (
      <IconButton
        variant="contained"
        color="inherit"
        onClick={() => setScannerVisible(true)}
        aria-label="toggle table tune box"
        size="large">
        <PhotoCameraIcon />
      </IconButton>
    ) : (
      <Button
        color="inherit"
        onClick={() => setScannerVisible(true)}
        aria-label="toggle table tune box"
        startIcon={<PhotoCameraIcon />}
        style={{ textTransform: 'none' }}
      >
        {t.translate('Scan')}
      </Button>
    )}
  </>;
};
