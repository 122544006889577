import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslator } from 'i18n';
import {
  useScreen,
  useDialog,
  useList,
} from 'hooks';

import AddIcon from '@mui/icons-material/Add';

import { Fab } from 'components';
import {
  DesktopView,
  MobileView,
  Header,
} from 'views/warehouses/overview';
import {
  ConfirmingDialog,
  WarehouseDialog,
} from 'dialogs';

import { selectActionPermission } from 'store/selectors/account';
import { fetchWarehouses } from 'services/warehouses';
import { deleteWarehouse } from 'api/warehouses';

import toaster from 'services/toaster';
import tables from 'config/tables';

export const WarehousesOverview = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const screen = useScreen();
  const list = useList(tables.warehouses.warehouses);
  const t = useTranslator();

  const canDeleteWarehouse = useSelector(selectActionPermission('warehouses', 'delete'));
  const canCreateWarehouse = useSelector(selectActionPermission('warehouses', 'create'));
  const totalEntities = useSelector(({ warehouses }) => warehouses.overview.meta.totalEntities);
  const warehouses = useSelector(({ warehouses }) => warehouses.overview.data);

  const confirmingDialog = useDialog({ warehouse: null });
  const creatingDialog = useDialog();

  const fetchData = useCallback(() => dispatch(fetchWarehouses(list.compile())), [dispatch, list]);

  useEffect(() => void fetchData(), [fetchData, location.search]);

  const removeWarehouse = async () => {
    try {
      await deleteWarehouse(confirmingDialog.data.warehouse.id);
      toaster.success(t.translate('Warehouse has been deleted successfully.'));
      fetchData();
    } catch (err) {}
  };

  return (
    <>
      <Helmet>
        <title>{t.translate('Warehouses')} | {t.translate('app:title')}</title>
      </Helmet>

      {canDeleteWarehouse && (
        <ConfirmingDialog
          {...confirmingDialog.props}
          title={t.translate('Delete warehouse')}
          acceptButtonText={t.translate(`Yes, I'm sure`)}
          declineButtonText={t.translate('No, cancel')}
          onAccept={removeWarehouse}
        >
          {t.translate('Are you sure you want to delete the warehouse?')}
        </ConfirmingDialog>
      )}

      {canCreateWarehouse && (
        <Fab onClick={() => creatingDialog.open()} aria-label="Create warehouse" variant="extended">
          <AddIcon />
          {t.translate('Create warehouse')}
        </Fab>
      )}

      {creatingDialog.visible && (
        <WarehouseDialog
          {...creatingDialog.props}
          onSave={fetchData}
        />
      )}

      <Header isMobile={screen.isMobile}/>

      {screen.isMobile
        ? <MobileView
            onWarehouseDelete={warehouse => confirmingDialog.open({ warehouse })}
            canDeleteWarehouse={canDeleteWarehouse}
            totalEntities={totalEntities}
            warehouses={warehouses}
          />
        : <DesktopView
            onWarehouseDelete={warehouse => confirmingDialog.open({ warehouse })}
            canDeleteWarehouse={canDeleteWarehouse}
            totalEntities={totalEntities}
            warehouses={warehouses}
          />}
    </>
  );
};
