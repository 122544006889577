import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import GetAppIcon from '@mui/icons-material/GetApp';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import { ActionButton } from 'components';

import { styleMobileTableItem } from 'theme/mixins/table';
import { makeSourceLink } from 'services/files';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  paper: {
    marginBottom: theme.spacing(0),
  },
  headline: {
    marginBottom: theme.spacing(-.5),
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(-1.5),
    marginRight: theme.spacing(-1.5),
  },
}));

export const WarehouseInventoryReportsItem = ({ warehouseInventoryReport }) => {
  const { classes } = useStyles();
  const t = useTranslator();

  return (
    <Grid item>
      <Paper className={classes.paper}>
        <Grid container direction="column" spacing={1}>
          <Grid item className={classes.headline}>
            <Grid container spacing={1}>
              <Grid item xs>
                <Typography variant="h6">#{warehouseInventoryReport.id}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{t.translate('Warehouse inventory')}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item container direction="column">
              <Grid item>
                <Typography variant="body2">{t.formatDate(warehouseInventoryReport.createdAt)}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{t.formatTime(warehouseInventoryReport.createdAt)}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{t.translate('Warehouse')} #{warehouseInventoryReport.warehouseId}</Typography>
              </Grid>
              {warehouseInventoryReport.user && (
                <Grid item>
                  <Typography variant="body2">{warehouseInventoryReport.user.name}</Typography>
                </Grid>
              )}
            </Grid>
            <Grid item className={classes.buttons}>
              <Link href={makeSourceLink(warehouseInventoryReport.document.filePath)}>
                <ActionButton
                  aria-label={t.translate('Download the statistics report')}
                  tooltipPlacement="left"
                  tooltip={t.translate('Download')}
                  icon={<GetAppIcon />}
                />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

WarehouseInventoryReportsItem.propTypes = {
  warehouseInventoryReport: PropTypes.shape({
    document: PropTypes.object.isRequired,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    id: PropTypes.number.isRequired,
  }).isRequired,
};
