import React from 'react';
import PropTypes from 'prop-types';
import { useList } from 'hooks/list';

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { StatisticsReportItem } from './StatisticsReportItem/StatisticsReportItem';
import { Pagination } from 'components';
import { Column } from 'layouts';

import tables from 'config/tables';

import groupBy from 'lodash/groupBy';
import dayjs from 'dayjs';

const useStyles = makeStyles()(theme => ({
  yearContainer: {
    margin: theme.spacing(.5),
    textAlign: 'center',
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  pagination: {
    background: theme.palette.common.white,
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
  },
}));

export const MobileView = ({
  statisticsReportsMetadata,
  statisticsReports,
}) => {
  const { classes } = useStyles();
  const list = useList(tables.statistics.reports);

  const statisticsReportsGroups = groupBy(statisticsReports, ({ startDate }) => dayjs.utc(startDate).format('YYYY'));
  return (
    <>
      <Column p={1} pb={7.5} mb={7.5}>
        <Grid container direction="column" spacing={1} >
          {Object.entries(statisticsReportsGroups).map(([year, statisticsReports]) => (
            <React.Fragment key={year}>
              <Paper className={classes.yearContainer} style={{ margin: '4px', textAlign: 'center', padding: '8px', marginTop: '16px' }}>
                <b>{year}</b>
              </Paper>
              {statisticsReports.map(statisticsReport => (
                <StatisticsReportItem
                  key={statisticsReport.id}
                  statisticsReport={statisticsReport}
                />
              ))}
            </React.Fragment>
          ))}
        </Grid>
      </Column>
      <Pagination
        {...list.paginationProps}
        count={statisticsReportsMetadata.totalEntities}
        direction="row"
        className={classes.pagination}
      />
    </>
  );
};

MobileView.propTypes = {
  statisticsReportsMetadata: PropTypes.shape({
    totalEntities: PropTypes.number.isRequired,
  }).isRequired,
  statisticsReports: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
};
