import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import { useStyles } from './StorageCard.jsx';
import { useTranslator } from 'i18n';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {
  Color,
  BooleanIcon,
} from 'components';

export const StorageCard = ({
  storage,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container spacing={2} className={classes.container}>
        <Grid item xs={6} sm={4}>
          <Box component="div" className={classes.storageValue}>
            <Typography variant="body2">ID:&nbsp;</Typography>
            <Typography variant="body2" color="textSecondary">{storage.id}</Typography>
          </Box>
          <Box component="div" className={classes.storageValue}>
            <Typography variant="body2">{t.translate('Warehouse')}:&nbsp;</Typography>
            <Typography variant="body2" color="textSecondary">{storage.warehouse.name || '—'}</Typography>
          </Box>
          <Box component="div" className={classes.storageValue}>
            <Typography variant="body2">{t.translate('Type')}</Typography>
            <Typography variant="body2">{t.translate(`storageType:${storage.type}`)}</Typography>
          </Box>
          <Box component="div" className={classes.storageValue}>
            <Typography variant="body2">{t.translate('Exclude from available stock')}</Typography>
            <BooleanIcon truthy={storage.excludeStock} />
          </Box>
          <Box component="div" className={classes.storageValue}>
            <Typography variant="body2">{t.translate('Building')}:&nbsp;</Typography>
            <Typography variant="body2" color="textSecondary">{storage.building || '—'}</Typography>
          </Box>
          <Box component="div" className={classes.storageValue}>
            <Typography variant="body2">{t.translate('Floor')}:&nbsp;</Typography>
            <Typography variant="body2" color="textSecondary">{storage.floor || '—'}</Typography>
          </Box>
          <Box component="div" className={classes.storageValue}>
            <Typography variant="body2">{t.translate('Room')}:&nbsp;</Typography>
            <Typography variant="body2" color="textSecondary">{storage.room || '—'}</Typography>
          </Box>
          <Box component="div" className={classes.storageValue}>
            <Typography variant="body2">{t.translate('Row')}:&nbsp;</Typography>
            <Typography variant="body2" color="textSecondary">{storage.row || '—'}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Box component="div" className={classes.storageValue}>
            <Typography variant="body2">{t.translate('Shelf')}:&nbsp;</Typography>
            <Typography variant="body2" color="textSecondary">{storage.shelf || '—'}</Typography>
          </Box>
          <Box component="div" className={classes.storageValue}>
            <Typography variant="body2">{t.translate('Level')}:&nbsp;</Typography>
            <Typography variant="body2" color="textSecondary">{storage.level || '—'}</Typography>
          </Box>
          <Box component="div" className={classes.storageValue}>
            <Typography variant="body2">{t.translate('Box number')}:&nbsp;</Typography>
            <Typography variant="body2" color="textSecondary">{storage.boxNumber || '—'}</Typography>
          </Box>
          <Box component="div" className={classes.storageValue}>
            <Typography variant="body2">{t.translate('Box color')}:&nbsp;</Typography>
            <Typography variant="body2" color="textSecondary">{storage.boxColor ? <Color color={storage.boxColor} /> : '—'}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Box component="div" className={classes.storageValue}>
            <Typography variant="body2">{t.translate('Width')}:&nbsp;</Typography>
            <Typography variant="body2" color="textSecondary">{storage.width ? `${numeral(storage.width).format('0.00')} cm` : '—'}</Typography>
          </Box>
          <Box component="div" className={classes.storageValue}>
            <Typography variant="body2">{t.translate('Height')}:&nbsp;</Typography>
            <Typography variant="body2" color="textSecondary">{storage.height ? `${numeral(storage.height).format('0.00')} cm` : '—'}</Typography>
          </Box>
          <Box component="div" className={classes.storageValue}>
            <Typography variant="body2">{t.translate('Length')}:&nbsp;</Typography>
            <Typography variant="body2" color="textSecondary">{storage.length ? `${numeral(storage.length).format('0.00')} cm` : '—'}</Typography>
          </Box>
          <Box component="div" className={classes.storageValue}>
            <Typography variant="body2">{t.translate('Volume')}:&nbsp;</Typography>
            <Typography variant="body2" color="textSecondary">{storage.volume ? <>{numeral(storage.volume).format('0.000000')} m<sup>3</sup></> : '—'}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

StorageCard.propTypes = {
  storage: PropTypes.shape({
    id: PropTypes.number.isRequired,
    warehouse: PropTypes.shape({
      name: PropTypes.string,
    }),
    type: PropTypes.string.isRequired,
    excludeStock: PropTypes.bool,
    building: PropTypes.string,
    floor: PropTypes.string,
    room: PropTypes.string,
    row: PropTypes.string,
    shelf: PropTypes.string,
    level: PropTypes.string,
    boxNumber: PropTypes.string,
    boxColor: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    length: PropTypes.number,
    volume: PropTypes.number,
  }),
};
