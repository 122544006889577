import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'hooks/form';
import { didUpdate } from 'hooks/lifecycle';
import { useTranslator } from 'i18n';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { LocalizationProvider , DatePicker  } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BusyButton } from 'components';
import { createStatisticsReport } from 'api/reports';
import { styleFormDialog } from 'theme/mixins/dialog';
import { download } from 'services/files';
import toaster from 'services/toaster';
import dayjs from 'dayjs';

const useStyles = makeStyles()(theme => styleFormDialog(theme, {
  content: {
    '&.MuiDialogContent-root': {
      width: 252,
    },
  },
}));
const makeId = postfix => `creating-statistics-report-dialog--${postfix}`;

export const CreatingStatisticsReportDialog = ({
  onClose,
  onSave,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const {
    catchHttpError,
    resetForm,
    setValue,
    errors,
    model,
  } = useForm({
    startDate: dayjs().subtract(1, 'day'),
    endDate: dayjs().subtract(1, 'day'),
  });

  didUpdate(() => {
    if (props.open) {
      resetForm();
    }
  }, [props.open]);

  const submit = async () => {
    try {
      const document = await createStatisticsReport(model);
      toaster.success(t.translate('Statistics report has been saved successfully.'));
      download(document);

      onSave();
      onClose();
    } catch (err) {
      catchHttpError(err);
    }
  };

  const getErrorProp = (value) => {
    return !!errors[value] ? {
      slotProps: {
        textField: {
          error: true,
          helperText: errors[value],
        },
      },
    } : {};
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog scroll="body" {...props} className={classes.root} onClose={onClose}>
        <DialogTitle className={classes.title}>{t.translate('Create statistics report')}</DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container direction="column" align="center" spacing={3}>
            <Grid item>
              <DatePicker
                id={makeId('start-date')}
                label={t.translate('Start date')}
                value={model.startDate}
                views={['year', 'month', 'day']}
                onChange={setValue('startDate')}
                {...getErrorProp('startDate')}
              />
            </Grid>
            <Grid item>
              <DatePicker
                id={makeId('end-date')}
                label={t.translate('End date')}
                value={model.endDate}
                views={['year', 'month', 'day']}
                onChange={setValue('endDate')}
                {...getErrorProp('endDate')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button color="primary" onClick={onClose}>{t.translate('Cancel')}</Button>
          <BusyButton onClick={submit}>{t.translate('Save')}</BusyButton>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

CreatingStatisticsReportDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
