import React from 'react';
import { useHistory, Switch, Route } from 'react-router-dom';

import { useTranslator } from 'i18n';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { MenuItem } from 'components';
import { Column } from 'layouts';

import { routes } from './consts';

export const Account = () => {
  const t = useTranslator();
  const history = useHistory();
  const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));

  let activeRoute = Object.values(routes).find(({ paths }) => paths[0] === history.location.pathname);

  const isMenuRoute = !activeRoute;
  const isMenuView = isXs && isMenuRoute;
  const hasMenu = !isXs || isMenuRoute;

  if (!isXs && isMenuRoute) {
    activeRoute = routes.data;
  }

  return (
    <Box display="flex" flexGrow={1} width={1}>
      {hasMenu && (
        <Column shrink={!isXs} filled>
          <Box display="flex" flexDirection="column">
            {routes.map(({ key, paths, title, description }) => (
              <MenuItem
                key={key}
                active={
                  isXs
                    ? paths[0] === history.location.pathname
                    : paths.includes(history.location.pathname)
                }
                chevron
                title={t.translate(title)}
                description={t.translate(description)}
                onClick={() => history.push(paths[0])}
              />
            ))}
          </Box>
        </Column>
      )}
      {!isMenuView && (
        <Column filled p={3} pt={2.5}>
          <Switch>
            {routes.map(({ key, paths, View }) => (
              <Route key={key} exact path={paths}>
                <View />
              </Route>
            ))}
          </Switch>
        </Column>
      )}
    </Box>
  );
};
