import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { BusyButton } from 'components';

import { modifyAuthenticatedUser } from 'api/users';
import { setDefaultView, setDefaultProductGroups } from 'store/actions/account';
import { menuItems } from 'config/navigation';
import {
  selectDefaultProductGroups,
  selectAuthenticatedUserDefaultView,
  isAuthenticatedUserAdministrator,
  selectPermissions,
} from 'store/selectors/account';

import toaster from 'services/toaster';
import config from 'config';
import Input from '@mui/material/Input';
import { Checkbox, ListItemText } from '@mui/material';
import { fetchProductGroups } from '../../../api/product-groups';
import { setUserDefaultProductGroups } from '../../../services/local-storage';
import { ProductGroupBadge } from '../../../components/ProductGroupBadge/ProductGroupBadge';

const useStyles = makeStyles()(theme => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  actionsItem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  badgeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export const DefaultView = () => {
  const [productGroups, setProductGroups] = useState([]);
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const t = useTranslator();

  useEffect(() => {
    fetchProductGroups().then((groups) => setProductGroups(groups.data));
  }, []);

  const isAdministrator = useSelector(isAuthenticatedUserAdministrator);
  const permissions = useSelector(selectPermissions);
  const defaultView = useSelector(selectAuthenticatedUserDefaultView);
  const defaultProductGroups = useSelector(selectDefaultProductGroups);

  const initialValues = { defaultView: '', defaultProductGroups: [] };

  const onSubmit = async values => {
    try {
      await modifyAuthenticatedUser(values);

      dispatch(setDefaultProductGroups(values.defaultProductGroups));
      dispatch(setDefaultView(values.defaultView));

      setUserDefaultProductGroups(values.defaultProductGroups);

      toaster.success(t.translate('Default view has been changed successfully.'));
    } catch (err) {}
  };

  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
  } = useFormik({
    initialValues,
    onSubmit,
  });

  useEffect(() => void setValues({
    defaultView,
    defaultProductGroups,
  }), [setValues, defaultView, defaultProductGroups]);

  const defaultViews = menuItems.filter(
    ({ path, name }) => name === 'account'
    || path === values.defaultView
    || isAdministrator
    || permissions[name]?.view
  );

  return (
    <form action="#">
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Typography variant="h6">{t.translate('Change default view page')}</Typography>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="account-settings--default-view">{t.translate('Default view')}</InputLabel>
            <Select
                id="account-settings--default-view"
                name="defaultView"
                value={values.defaultView || config.auth.paths.authenticated}
                className={classes.selectEmpty}
                displayEmpty
                onChange={handleChange}
            >
              {defaultViews.map(item => (
                  <MenuItem key={item.name} value={item.path}>
                    {t.translate(item.text)}
                  </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <InputLabel shrink id="default-product-groups-label">{t.translate('Default product groups')}</InputLabel>
            <Select
                className={classes.selectEmpty}
                labelId="default-product-groups-label"
                id="default-product-groups"
                name="defaultProductGroups"
                multiple
                value={values.defaultProductGroups}
                onChange={(event) => {
                  setValues({ defaultProductGroups: (event.target.value || []).filter(item => !!item) });
                }}
                input={<Input />}
                renderValue={(selected) =>
                  <Grid className={classes.badgeContainer}>
                    {productGroups.filter((g) => selected.includes(g.id)).map(g => <ProductGroupBadge groupName={g.name} id={g.id} />)}
                  </Grid>
                }
            >
              {productGroups.map((group) => (
                  <MenuItem key={`menu-item-${group.id}`} value={group.id}>
                    <Checkbox checked={values.defaultProductGroups.indexOf(group.id) > -1} />
                    <ListItemText primary={group.name} />
                  </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item className={classes.actionsItem}>
          <BusyButton
              color="secondary"
              submit
              variant="contained"
              onClick={handleSubmit}
          >
            {t.translate('Save')}
          </BusyButton>
        </Grid>
      </Grid>
    </form>
  );
};
