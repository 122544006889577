import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Link from '@mui/material/Link';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  Button,
  TextField,
  Grid,
  Select,
  MenuItem,
} from '@mui/material';

import { useTranslator } from 'i18n';
import { useStyles } from './ImportProductsDialog.jsx';
import { makeSourcePublicLink } from '../../services/files';
import { fetchSuppliers } from '../../api/suppliers';
import { useList } from '../../hooks';
import tables from '../../config/tables';
import { ProductImportTypes, SupplierHandlingTypes } from 'consts';
import marketplacesApi from 'api/marketplaces';

export const ImportProductsDialog = ({
  onProductsImport,
  onClose,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const [file, setFile] = useState({ name: '' });
  const [importType, setImportType] = useState(ProductImportTypes.products);
  const [supplier, setSupplier] = useState({});
  const [supplierList, setSupplierList] = useState([]);
  const [activeMarketplaces, setActiveMarketplaces] = useState([]);
  const [selectedMarketplace, setSelectedMarketplace] = useState(null);
  const list = useList(tables.suppliers.suppliers);

  const onImportClick = () => {
    onProductsImport({
      supplierId: supplier?.id,
      marketplaceCountryId: selectedMarketplace?.id,
      importType,
      file,
    });
  };

  useEffect(() => {
    (async () => {
      const { data: marketplaces } = await marketplacesApi.fetchMarketplaces();

      setActiveMarketplaces(marketplaces.filter(marketplace => marketplace.isActive));
    })();
  }, []);

  useEffect( () => {
    fetchSuppliers({
      ...list.compile(),
      limit: -1,
      handlingType: [SupplierHandlingTypes.DEFAULT],
    }).then(({ data }) => setSupplierList(data));
  }, [list]);

  useEffect(() => {
    supplierList.length && setSupplier(supplierList[0]);
  }, [supplierList]);

  return (
    <>
      <Dialog scroll="body" {...props} className={classes.root} id='import-products-dialog'>
        <DialogTitle className={classes.title}>
          <Grid container alignItems={'center'}>
            <Grid item className={classes.dropdownLabel}>{t.translate('Import')}</Grid>
            <Grid item>
              <Select
                id="import-dropdown"
                value={importType}
                onChange={(e) => {
                  setImportType(e.target.value);
                }}
              >
                {Object.keys(ProductImportTypes).map((key, index) => (
                  <MenuItem key={`${ProductImportTypes[key]}-${index}`} value={ProductImportTypes[key]}>
                    {t.translate(ProductImportTypes[key])}
                  </MenuItem>))
                }
              </Select>
            </Grid>
          </Grid>
        </DialogTitle>
        {importType === ProductImportTypes.products ? (
          <DialogContent className={classes.content}>
            <DialogContentText>
              {t.translate('You can use the product import to create products based on a csv file.')}
            </DialogContentText>
            <DialogContentText>
              {t.translate('The file should have the following structure')}:
              <Link underline="hover" className={classes.demoLink} href={makeSourcePublicLink('/demo/products_import_demo.csv')}>
                demo
              </Link>
            </DialogContentText>
            <DialogContentText>
              {t.translate('While importing the file, we will try to find product details for each entry in the file. The details will then be loaded over the Marketplace API.')}
            </DialogContentText>
            <DialogContentText>
              {t.translate('If a product already exists in the App Database, the product will not be created again.')}
            </DialogContentText>
            <DialogContentText>
              {t.translate('You will receive an import status mail after the import is completed.')}
            </DialogContentText>
            <Grid container alignItems={'center'}>
              <Grid item className={classes.dropdownLabel}>{t.translate('Marketplace')}</Grid>
              <Grid item>
                <Select
                  id="marketplaceCountry-dropdown"
                  value={selectedMarketplace || ''}
                  style={{ minWidth: '100px' }}
                  onChange={(e) => {
                    setSelectedMarketplace(e.target.value);
                  }}
                >
                  {activeMarketplaces.map((marketplace, index) => (
                    <MenuItem key={index} value={marketplace}>
                      {t.translate(`country:${marketplace.country.countryCode}`)}
                    </MenuItem>))
                  }
                </Select>
              </Grid>
            </Grid>
            <Box className={classes.inputContainer} style={{ marginTop: 10 }}>
              <TextField className={classes.fileName} variant="outlined" value={file?.name} disabled={true} size="small" />
              <label htmlFor="select-file-input">
                <input id="select-file-input" type="file" accept=".csv" className={classes.selectFileInput} onChange={(event) => setFile(event.target.files[0])} />
                <Button color="primary" className={classes.selectFileButton} variant="contained" component="span" size="small">{t.translate('Select file')}</Button>
              </label>
            </Box>
          </DialogContent>
        ) : (
          <DialogContent className={classes.content}>
            <DialogContentText>
              {t.translate('You can use the supplier price import to update purchase prices and sales prices for a supplier based on a csv file')}
            </DialogContentText>
            <DialogContentText>
              {t.translate('The file should have the following structure')}:
              <Link underline="hover" className={classes.demoLink} href={makeSourcePublicLink('/demo/purchase_prices_import_demo.csv')}>
                demo
              </Link>
            </DialogContentText>
            <DialogContentText>
              {t.translate('While importing the file, we will try to find the correct product for each entry in the file. We will use the suppliers own article number (supplier_item_number) and the supplier number to identify the correct product and price.')}
            </DialogContentText>
            <DialogContentText>
              {t.translate('If a product with this supplier_item_number for this supplier does not exist in the App Database, the price will not be imported and the line will be skipped.')}
            </DialogContentText>
            <DialogContentText>
              {t.translate('You will receive an import status mail after the import is completed.')}
            </DialogContentText>
              <Grid container alignItems={'center'}>
                <Grid item className={classes.dropdownLabel}>{t.translate('Supplier')}</Grid>
                <Grid item>
                  <Select
                    id="supplier-dropdown"
                    value={supplier}
                    onChange={(e) => {
                      setSupplier(e.target.value);
                    }}
                  >
                    {supplierList.map((supplier, index) => (
                      <MenuItem key={`${supplier.number}-${index}`} value={supplier}>
                        {`${supplier.number} - ${supplier.name}`}
                      </MenuItem>))
                    }
                  </Select>
                </Grid>
              </Grid>
            <Box className={classes.inputContainer} style={{ marginTop: 10 }}>
              <TextField className={classes.fileName} variant="outlined" value={file?.name} disabled={true} size="small" />
              <label htmlFor="select-file-input">
                <input id="select-file-input" type="file" accept=".csv" className={classes.selectFileInput} onChange={(event) => setFile(event.target.files[0])} />
                <Button color="primary" className={classes.selectFileButton} variant="contained" component="span" size="small">{t.translate('Select file')}</Button>
              </label>
            </Box>
          </DialogContent>
        )}
        <DialogActions className={classes.actions}>
          <Button color="primary" onClick={onImportClick}>{t.translate('Import')}</Button>
          <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ImportProductsDialog.propTypes = {
  onProductsImport: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
