import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  actionButton: {
    marginTop: theme.spacing(1),
  },
  container: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    maxWidth: 'none',
    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
      margin: 0,
      maxHeight: 'none',
      maxWidth: 'none',
      height: '100%',
      width: '100%',
    },
    width: '75vw',
  },
  qrButton: {
    marginLeft: theme.spacing(1),
    '& .MuiButton-endIcon': {
      marginLeft: 0,
      marginRight: 0,
    },
    width: '100%',
  },
  root: {
    padding: theme.spacing(3),
  },
  stockContainer: {
    margin: theme.spacing(1, 0, 3),
  },
  storageItem: {
    width: '100%',
  },
  fontBold: {
    display: 'block',
    textAlign: 'center',
    fontWeight: 500,
  },
  toggleButton: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    '& button, & .Mui-selected': {
      backgroundColor: `${theme.palette.common.white} !important`,
    },
    '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
      marginLeft: 0,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
  button: {
    border: `2px solid ${theme.palette.grey['205']}`,
    borderRadius: '4px',
    color: theme.palette.grey['205'],
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    width: '30%',
    '&:nth-of-type(n+4)': {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
      width: '48%',
    },
    '& svg': {
      position: 'absolute',
      left: theme.spacing(2.25),
    },
    '&:hover': {
      background: 'none',
    },
    '&.MuiToggleButton-root.Mui-selected': {
      background: 'none',
    },
    '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
      borderWidth: '2px',
    },
  },
  resetFiltersButton: {
    width: '50%',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
