import React from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles } from 'tss-react/mui';
import dayjs from 'dayjs';
import { useTranslator } from 'i18n';
import * as PropTypes from 'prop-types';

const useStyles = makeStyles()(theme => ({
  chip: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(.5),
    borderRadius: theme.spacing(0.5),
    height: '26px',
    '& .MuiChip-label': {
      color: theme.palette.common.white,
      paddingLeft: theme.spacing(.75),
    },
  },
}));

export const DotChip = ({ manufacturingDate }) => {
  const t = useTranslator();
  const { classes } = useStyles();

  if (!manufacturingDate) return '—';

  return (
    <Chip
      color={'default'}
      label={t.formatManufacturingDate(manufacturingDate)}
      className={classes.chip}
      style={{ backgroundColor: dayjs.utc().year(dayjs().year() - 3).isAfter(manufacturingDate) ? '#ff9800' : '#2ECC71' }}
    />
  );
};

DotChip.propTypes = {
  manufacturingDate: PropTypes.string,
};
