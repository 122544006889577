import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth:'672px',
      },
    },
    '& .MuiFormControl-root': {
      marginTop: theme.spacing(2),
    },
  },
  container: {
    paddingBottom: theme.spacing(4),
    alignItems: 'flex-start',
  },
  containerPhoto: {
    paddingBottom: theme.spacing(4),
    '& .MuiFormControl-root': {
      display: 'flex',
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      '& > div:last-child': {
        marginLeft: theme.spacing(3),
      },
    },
  },
  containerIdentify: {
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      '& .MuiGrid-item:first-of-type': {
        marginRight: theme.spacing(1.5),
      },
      '& .MuiGrid-item:last-child': {
        marginLeft: theme.spacing(1.5),
      },
    },
    [theme.breakpoints.down('sm')]: {
      display: 'inherit',
    },
  },
  containerDetails: {
    paddingBottom: theme.spacing(4),
    '& > div': {
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        '& .MuiGrid-grid-md-4:not(:last-child) ': {
          marginRight: theme.spacing(1.5),
        },
        '& .MuiGrid-grid-md-4:not(:first-of-type) ': {
          marginLeft: theme.spacing(1.5),
        },
        '& .MuiGrid-grid-md-8': {
          marginLeft: theme.spacing(1.5),
        },
      },
    },
  },
  containerDimensions: {
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      '& .MuiGrid-item:not(:last-child)': {
        marginRight: theme.spacing(1.5),
      },
      '& .MuiGrid-item:not(:first-of-type)': {
        marginLeft: theme.spacing(1.5),
      },
      '&  .MuiGrid-container': {
        paddingBottom: theme.spacing(4),
      },
    },
  },
  qrButton: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      '& .MuiButton-endIcon': {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  checkboxes: {
    marginTop: theme.spacing(2),
  },
  loader: {
    paddingLeft: '12px',
    display: 'inline-block',
  },
  manufacturerContainer: {
    justifyContent: 'space-between',
  },
  floatingActionButton: {
    marginTop: 24,
  },
  badgeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  lowStockContainer: {
    paddingBottom: theme.spacing(4),
  },
}));
