import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  blockLabel: {
    height: '1.5rem',
    fontWeight: 500,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  containerLabel: {
    fontWeight: 500,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  containerItem: {
    paddingBottom: theme.spacing(3),
  },
  dialogActions: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
}));
