import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { shipAgainReasons } from '../../consts';
import { BusyButton, FormControlError, InputControl } from '../../components';

import { makeStyles } from 'tss-react/mui';
import { useTranslator } from '../../i18n';
import * as Yup from 'yup';
import toaster from '../../services/toaster';
import { extractApiErrors } from '../../utils/api';
import { useFormik } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { CancellingPositionItem } from '../CancellingOrderDialog/CancellingPositionItem/CancellingPositionItem';
import Typography from '@mui/material/Typography';
import { createPickupSheetForAdditionalShipment } from '../../api/pickup-sheets';

export const useStyles = makeStyles()(theme => ({
  expandedContainerItem: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  reasonItem: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    width: 400,
  },
  content: {
    '&.MuiDialogContent-root': {
      padding: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
  },
  containerLabel: {
    fontWeight: 500,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  mobileButton: {
    '& button': {
      width: '100%',
    },
    marginBottom: theme.spacing(2),
  },
  reasonForm: {
    marginBottom: theme.spacing(3),
  },
}));

const makeId = postfix => `ship-again-dialog--${postfix}`;

export const ShipAgainDialog = ({
  onClose,
  order,
  updateData,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const t = useTranslator();
  const { classes } = useStyles();

  const initialValues = {
    parentForm: {
      allOrderCancel: false,
      country: order.deliveryAddress.country,
      street: order.deliveryAddress.street,
      house: order.deliveryAddress.house,
      city: order.deliveryAddress.city,
      zip: order.deliveryAddress.zip,
      reason: '',
    },
    ...order.positions.reduce((acc, { id, product }) => ({
      ...acc,
      [id]: {
        productId: product.id,
        cancelledAmount: 0,
      } }), {}),
  };

  const validationSchema = Yup
    .object()
    .shape({
      parentForm: Yup
        .object()
        .shape({
          reason: Yup
            .string()
            .required(t.translate('Please specify a reason.')),
          country: Yup
            .string()
            .trim()
            .required(t.translate('Please specify a country.')),
          city: Yup
            .string()
            .trim()
            .required(t.translate('Please specify a city.')),
          street: Yup
            .string()
            .trim()
            .required(t.translate('Please specify a street.')),
          house: Yup
            .string()
            .trim()
            .required(t.translate('Please specify a house number.')),
          zip: Yup
            .string()
            .trim()
            .required(t.translate('Please specify a zip code.')),
        }),
    });

  const onSubmit = async (values, { setErrors }) => {
    try {
      setIsLoading(true);
      const positions = Object.keys(values)
        .filter(key => key !== 'parentForm' && !!values[key].cancelledAmount)
        .map(positionId => ({
          orderPositionId: Number(positionId),
          productId: Number(values[positionId].productId),
          amount: Number(values[positionId].cancelledAmount),
        }));

      await createPickupSheetForAdditionalShipment({
        orderId: order.id,
        positions,
        ...values.parentForm,
      });
      toaster.success(t.translate('Order has been shipped again.'));
      onClose();
      updateData();
    } catch (err) {
      setErrors(extractApiErrors(err));
    } finally {
      setIsLoading(false);
    }
  };

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    resetForm,
    values,
    errors,
  } = useFormik({
    enableReinitialize: true,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  const cancelAllPosition = () => {
    setFieldValue(`parentForm.allOrderCancel`, true);
  };

  const positionsLength = Object.keys(values).filter(key => key !== 'parentForm' && !!values[key].cancelledAmount).length;

  return (
    <Dialog {...props} scroll="body" classes={{ paperScrollBody: classes.root }} onClose={onClose}>
      <DialogTitle id="max-width-dialog-title">{t.translate('Ship again')}</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container  direction="column" className={classes.reasonForm}>
          <FormControl fullWidth error={!!errors.parentForm?.reason} className={classes.reasonForm}>
            <InputLabel shrink htmlFor={makeId('reason')}>{t.translate('Reason')}</InputLabel>
            <Select
              id={makeId('reason')}
              name={'reason'}
              value={values.parentForm.reason}
              onChange={(e) => setFieldValue(`parentForm.reason`, e.target.value)}
            >
              {shipAgainReasons.map((value) => (
                <MenuItem key={value} value={value}>{t.translate(`shipAgainReason:${value}`)}</MenuItem>
              ))}
            </Select>
            {!!errors.parentForm?.reason && <FormControlError error={errors.parentForm?.reason} />}
          </FormControl>
        </Grid>
        <Grid item className={classes.mobileButton}>
          {values.parentForm.allOrderCancel ? (
            <Button
              variant="outlined"
              onClick={resetForm}
            >{t.translate('Reset')}
            </Button>
          ) : (
            <Button
              variant="outlined"
              onClick={cancelAllPosition}
            >{t.translate('Select all items')}
            </Button>
          )}
        </Grid>
        <Grid id={makeId('positions-container')}>
          {order.positions.map((position, index) => (
            <CancellingPositionItem
              key={index}
              position={position}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              values={values}
              errors={errors}
              setCancelAllQuantity={values.parentForm.allOrderCancel}
              isShipAgain={true}
            />
          ))
          }
        </Grid>
        <Typography variant="body1" className={classes.containerLabel}>{t.translate('Address')}</Typography>
        <Grid container spacing={4}>
          <Grid item md={9} xs={9}>
            <InputControl
              id={makeId('street')}
              name="parentForm.street"
              error={errors.parentForm?.street}
              label={t.translate('Street')}
              value={values.parentForm.street}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3} xs={3}>
            <InputControl
              id={makeId('house')}
              name="parentForm.house"
              error={errors.parentForm?.house}
              label={t.translate('House number')}
              value={values.parentForm.house}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item md={2}>
            <InputControl
              id={makeId('zip')}
              name="parentForm.zip"
              error={errors.parentForm?.zip}
              label={t.translate('Zip code')}
              value={values.parentForm.zip}
              onChange={handleChange}
            />
          </Grid>

          <Grid item md={5}>
            <InputControl
              id={makeId('city')}
              name="parentForm.city"
              error={errors.parentForm?.city}
              label={t.translate('City')}
              value={values.parentForm.city}
              onChange={handleChange}
            />
          </Grid>

          <Grid item md={5}>
            <InputControl
              id={makeId('country')}
              name="parentForm.country"
              error={errors.parentForm?.country}
              label={t.translate('Country')}
              value={values.parentForm.country}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} disabled={isLoading}>{t.translate('dialog-action:Cancel')}</Button>
        <BusyButton disabled={!positionsLength || isLoading} color="primary" submit onClick={handleSubmit}>{t.translate('Ship')}</BusyButton>
      </DialogActions>
    </Dialog>
  );
};
