import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './style';
import { useUpload } from 'hooks/upload';
import { useTranslator } from 'i18n';

import CircularProgress from '@mui/material/CircularProgress';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import DoneIcon from '@mui/icons-material/Done';
import Grid from '@mui/material/Grid';

import { upload as uploadFile } from 'api/files';
import toaster from 'services/toaster';
import {
  FileUploadActionButton,
  PhotoCamera,
  Picture,
} from 'components';

export const PhotoControl = ({
  disabledControl,
  onDelete,
  onChange,
  photo,
  label,
}) => {
  const { classes } = useStyles();
  const t = useTranslator();

  const [loadingSource, setLoadingSource] = useState(null);
  const [cameraVisible, setCameraVisible] = useState(false);

  const handleUpload = async (data, onError = () => {}) => {
    try {
      const file = await uploadFile(data);
      toaster.success(t.translate('File is uploaded successfully.'));
      setLoadingSource(null);
      onChange(file);
    } catch (err) {
      onError(err);
    }
  };

  const handleUploadCamera = file => {
    setLoadingSource(null);
    onChange(file);
  };

  const {
    isSucceeded,
    isFailed,
    isReady,
    isBusy,
    upload,
    reset,
  } = useUpload({
    onUploaded: handleUploadCamera,
    onUpload: () => setLoadingSource('camera'),
    onFailed: () => setLoadingSource(null),
    t: useTranslator(),
  });

  const uploadPhotoFile = dataUri => {
    const data = new FormData();
    data.append('file', dataUri);
    upload(data);
  };

  return <>
    {cameraVisible && (
      <PhotoCamera
        onClose={() => setCameraVisible(false)}
        onCapture={uploadPhotoFile}
      />
    )}

    <FormControl>
      <Grid
        container
        item md={12}
        className={classes.container}
        spacing={1}
        justifyContent="space-between"
      >
        <Grid item className={classes.imageItem}>
          <Picture image={photo} />
        </Grid>

        {photo && (
          <>
            <Grid item className={classes.removeItem}>
              <Tooltip title={t.translate('Delete image')} placement={'bottom'}>
                <IconButton onClick={onDelete} aria-label="remove a photo" size="large">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Divider orientation="vertical" />
          </>
        )}

        {!disabledControl && (
          <>
            <FileUploadActionButton
              disabled={loadingSource && loadingSource !== 'file'}
              onUploaded={handleUpload}
              onUpLoading={() => setLoadingSource('file')}
              onError={() => setLoadingSource(null)}
            />
            <Divider orientation="vertical" />
            <Tooltip title={t.translate('Use camera')} placement={'bottom'}>
              <IconButton
                disabled={isBusy || (loadingSource && loadingSource !== 'camera')}
                onClick={() => isFailed ? reset() : setCameraVisible(true)}
                aria-label="take a photo"
                size="large">
                {isReady
                    ? <PhotoCameraIcon />
                    : isBusy
                      ? <CircularProgress size={24} />
                      : isSucceeded
                        ? <DoneIcon />
                        : <ClearIcon />}
              </IconButton>
            </Tooltip>
          </>
        )}
      </Grid>
    </FormControl>
  </>;
};

PhotoControl.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  photo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      filePath: PropTypes.string.isRequired,
    }),
  ]),
  label: PropTypes.string.isRequired,
};
