import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  useScreen,
  useDialog,
  useList,
} from 'hooks';

import AddIcon from '@mui/icons-material/Add';

import { Fab } from 'components';
import { RetailerDialog } from 'dialogs';
import {
  DesktopView,
  MobileView,
} from 'views/retailers/overview';

import { selectActionPermission } from 'store/selectors/account';
import { isLoading } from 'store/selectors/app';
import { retailersDataWithMeta } from 'store/selectors/retailers';
import { fetchRetailers } from 'services/retailers';

import tables from 'config/tables';

export const RetailersOverview = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const screen = useScreen();
  const list = useList(tables.retailers.retailers);

  const canCreateRetailer = useSelector(selectActionPermission('retailers', 'create'));
  const isRetailersLoading = useSelector(isLoading('retailers.overview.retailers'));
  const { data: retailers, meta: retailersMetadata } = useSelector(retailersDataWithMeta);

  const creatingDialog = useDialog();

  const fetchData = useCallback(() => dispatch(fetchRetailers(list.compile())), [dispatch, list]);

  useEffect(() => void fetchData(), [fetchData, location.search]);

  return (
    <>
      {canCreateRetailer && (
        <Fab onClick={() => creatingDialog.open()} aria-label="Create retailer">
          <AddIcon />
        </Fab>
      )}

      {creatingDialog.visible && (
        <RetailerDialog
          {...creatingDialog.props}
          onSave={fetchData}
          isMobileView={screen.isMobile}
        />
      )}

      {screen.isMobile
        ? <MobileView
          isLoading={isRetailersLoading}
          retailersMetadata={retailersMetadata}
          retailers={retailers}
        />
        : <DesktopView
          isLoading={isRetailersLoading}
          retailersMetadata={retailersMetadata}
          retailers={retailers}
        />
      }
    </>
  );
};
