import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { useDialog, useList } from 'hooks';

import Grid from '@mui/material/Grid';

import { FilteringDialog } from './FilteringDialog/FilteringDialog';
import { ProductItem } from './ProductItem/ProductItem';
import { Pagination } from 'components';
import { Column } from 'layouts';

import tables from 'config/tables';

export const MobileView = () => {
  const list = useList(tables.products.products);

  const filteringDialog = useDialog();

  const totalEntities = useSelector(({ products }) => products.products.meta.totalEntities);
  const products = useSelector(({ products }) => products.products.data);

  return (
    <>
      <FilteringDialog
        {...filteringDialog.props}
      />

      <Column pt={1}>
        <Grid container direction="column">
          {products.map((product, index) => (
            <ProductItem key={index} product={product}/>
          ))}
          <Grid item>
            <Pagination
              {...list.paginationProps}
              count={totalEntities}
              direction="row"
            />
          </Grid>
        </Grid>
      </Column>
    </>
  );
};

MobileView.propTypes = {
  productTypes: PropTypes.array,
};
