import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { CardFeature } from 'components';
import { styleMobileTableItem } from 'theme/mixins/table';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  paper: {
    height: '100%',
    padding: theme.spacing(1, 0),
  },
  details: {
    padding: theme.spacing(0.5, 2),
  },
  featureLabel: {
    fontWeight: 500,
  },
}));

export const OrdersOverviewCard = ({
  openOrders,
  closedOrders,
  ordersTotalValueNet,
  ordersTotalValueGross,
}) => {
  const { classes } = useStyles();
  const t = useTranslator();

  return (
    <Paper className={classes.paper}>
      <Grid container direction="column">
        <CardFeature
          label={t.translate('Open orders')}
          value={openOrders || 0}
          omitColon
          justifyContent="space-between"
          className={classes.details}
          classes={{ label: classes.featureLabel }}
        />
        <CardFeature
          label={t.translate('Closed orders')}
          value={closedOrders || 0}
          omitColon
          justifyContent="space-between"
          className={classes.details}
          classes={{ label: classes.featureLabel }}
        />
        <Divider />
        <CardFeature
          label={t.translate('Total order value (net)')}
          value={`${t.formatPrice(Number(ordersTotalValueNet) || 0)} (€)`}
          omitColon
          justifyContent="space-between"
          className={classes.details}
          classes={{ label: classes.featureLabel }}
        />
        <CardFeature
          label={t.translate('Total order value (gross)')}
          value={`${t.formatPrice(Number(ordersTotalValueGross) || 0)} (€)`}
          omitColon
          justifyContent="space-between"
          className={classes.details}
          classes={{ label: classes.featureLabel }}
        />
      </Grid>
    </Paper>
  );
};

OrdersOverviewCard.propTypes = {
  openOrders: PropTypes.number.isRequired,
  closedOrders: PropTypes.number.isRequired,
  ordersTotalValueNet: PropTypes.number.isRequired,
  ordersTotalValueGross: PropTypes.number.isRequired,
};
