import React from 'react';

import { useSelector } from 'react-redux';
import { useTranslator } from 'i18n';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { DesktopView } from './DesktopView/DesktopView';
import { MobileView } from './MobileView/MobileView';

import { isAuthenticatedUserAdministrator } from 'store/selectors/account';

export const Permissions = () => {
  const t = useTranslator();
  const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));

  const isAdministrator = useSelector(isAuthenticatedUserAdministrator);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h6">{t.translate('Permissions list')}</Typography>
      </Grid>
      {isAdministrator ? (
        <Grid item>
          <Typography>
            {t.translate('You are logged in as administrator.')}
          </Typography>
        </Grid>
      ) : (
        isXs
          ? <MobileView />
          : <DesktopView />
      )}
    </Grid>
  );
};
