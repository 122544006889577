import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useStyles } from '../styles';
import { InputControl } from 'components';
import { useTranslator } from 'i18n';
import { PageWrapper } from '../../../suppliers/settings/PageWrapper/PageWrapper';
import { Hint } from '../../../suppliers/settings/Hint/Hint';

const makeId = postfix => `companies-datev-settings--${postfix}`;

export const DatevSettings = ({ isEditMode, values, handleChange, errors }) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <PageWrapper>
      <Grid item xl={8} lg={10} xs={12} className={classes.containerWrap}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} className={classes.containerWrap}>
            <Grid container alignItems="center" className={classes.marginBottom}>
              <Typography variant="h6">{t.translate('DATEV number configuration')}</Typography>
              <Hint className={classes.helpTextIcon} text={t.translate('DATEV numbers for customers will be generated based on their Tyre24 account number with the following changes:')} />
            </Grid>
            <Grid item xs={6} className={classes.marginBottom}>
              <InputControl
                id={makeId('DATEVNumberLength')}
                name="DATEVNumberLength"
                error={errors.DATEVNumberLength}
                label={t.translate('Number length')}
                value={values.DATEVNumberLength}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Grid>
            <Grid item xs={6} className={classes.marginBottom}>
              <InputControl
                id={makeId('DATEVPrefix')}
                name="DATEVPrefix"
                error={errors.DATEVPrefix}
                label={t.translate('Number prefix')}
                value={values.DATEVPrefix}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};
