import { APP } from 'store/types';
import { app as is } from 'store/initialState';

export const toggleNavigationVisibility = state => ({
  ...state,
  navigation: {
    ...state.navigation,
    visible: !state.navigation.visible,
  },
});

export const setNavigationVisibility = (state, visible) => ({
  ...state,
  navigation: {
    ...state.navigation,
    visible,
  },
});

export const writeHeaderHeight = (state, height) => ({
  ...state,
  header: {
    ...state.header,
    height,
  },
});

export const setApiVersion = (state, apiVersion) => ({
  ...state,
  apiVersion,
});

export const setLoading = (state, { key, value }) => ({
  ...state,
  loading: {
    ...state.loading,
    [key]: value,
  },
});

export default (state = is, { type, payload }) => {
  switch (type) {
    case APP.TOGGLE_NAVIGATION_VISIBILITY:
      return toggleNavigationVisibility(state);
    case APP.SET_NAVIGATION_VISIBILITY:
      return setNavigationVisibility(state, payload);
    case APP.WRITE_HEADER_HEIGHT:
      return writeHeaderHeight(state, payload);
    case APP.SET_API_VERSION:
      return setApiVersion(state, payload);
    case APP.SET_LOADING:
      return setLoading(state, payload);
    default:
      return state;
  }
};
