import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { Column } from 'layouts';

import { ProductDetailsCard } from '../../common';
import { StorageItem } from './StorageItem/StorageItem';

import { selectActionPermission } from 'store/selectors/account';

const useStyles = makeStyles()(theme => ({
  productPaper: {
    padding: theme.spacing(2),
  },
  pagination: {
    background: theme.palette.common.white,
    bottom: theme.spacing(8),
  },
  cardLabel: {
    color: theme.palette.text.itemHeadline,
    padding: '0 16px',
  },
  productTotals: {
    width: 'auto',
  },
}));

export const MobileView = ({
  onStorageReassignStock,
  onStorageProductEdit,
  onImageFullView,
  isMobile,
}) => {
  const history = useHistory();
  const { classes } = useStyles();
  const t = useTranslator();

  const canReassignStock = useSelector(selectActionPermission('products', 'reassignStock'));
  const canCorrectStock = useSelector(selectActionPermission('products', 'correct'));
  const storageProducts = useSelector(({ products }) => products.storages.data);
  const product = useSelector(({ products }) => products.storages.product);

  const openHistory = storageProduct => history.push(`/products/history/${storageProduct.storage.id}&${product.id}`);

  if (product) {
    return (
      <>
        <Column p={1} pb={15.5}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <ProductDetailsCard
                onImage={onImageFullView}
                product={product}
                isMobile={isMobile}
              />
            </Grid>
            <Grid container justifyContent="space-between" >
              {!!product.stock && (
                <Typography className={classes.cardLabel} variant="body2">{t.translate('Storage locations')}</Typography>
              )}
              <Grid container className={classes.productTotals}>
                <Typography className={classes.cardLabel} variant="body2">{t.translate('Reserved Stock')}: {product.reserved}</Typography>
                <Typography className={classes.cardLabel} variant="body2">{t.translate('Total stock')}: {product.stock}</Typography>
              </Grid>
            </Grid>
            {storageProducts.map(storageProduct => (
              <Grid item key={storageProduct.id}>
                <StorageItem
                  storageProduct={storageProduct}
                  canCorrectStock={canCorrectStock}
                  canReassignStock={canReassignStock}
                  onStorageProductDetail={() => openHistory(storageProduct)}
                  onStorageProductEdit={() => onStorageProductEdit(storageProduct)}
                  onStorageReassignStock={() => onStorageReassignStock(storageProduct)}
                />
              </Grid>
            ))}
          </Grid>
        </Column>
      </>
    );
  } else {
    return <Column p={1} pb={15.5} />;
  }
};

MobileView.propTypes = {
  onStorageReassignStock: PropTypes.func.isRequired,
  onStorageProductEdit: PropTypes.func.isRequired,
  onImageFullView: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
