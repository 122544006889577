import { COMPANIES } from 'store/types';
import { companies as initialState } from 'store/initialState';

const setOverviewCompanies = (state, { data, meta }) => ({
  ...state,
  overview: {
    data,
    meta: {
      totalEntities: meta.totalEntities,
    },
  },
});

const setDetailsCompany = (state, company) => ({
  ...state,
  details: {
    ...state.details,
    company,
  },
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case COMPANIES.OVERVIEW.SET_COMPANIES:
      return setOverviewCompanies(state, payload);
    case COMPANIES.DETAILS.SET_COMPANY:
      return setDetailsCompany(state, payload);
    default:
      return state;
  }
};
