import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from '@mui/material';

import sprite from 'static/images/alzrua-trade-icon-set.svg';

export const AlzuraSpriteIcon = ({ id = '' }) => (
  <SvgIcon width="24" height="24">
    <use href={sprite + `#${id}`} />
  </SvgIcon>
);

AlzuraSpriteIcon.propTypes = {
  id: PropTypes.string.isRequired,
};
