import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import Grid from '@mui/material/Grid';

import { OrderCard } from './OrderCard/OrderCard';
import { ProductItem } from './ProductItem/ProductItem';
import { OrderDetails } from './OrderDetails/OrderDetails';
import { AddressesDetails } from '../../common/AddressesDetails/AddressesDetails';
import { Column } from 'layouts';
import { CircularLoader, ItemHeadline } from 'components';

export const MobileView = ({
  onProductDescriptionOpen,
  order,
  isLoading,
  onStockHistoryShow,
}) => {
  const t = useTranslator();

  return (
    <Column p={1} pt={2}>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <Grid container direction="column">
          {order && (
            <>
              <Grid container style={{ flexWrap: 'wrap' }}>
                <Grid item xs={12} container direction="column">
                  <ItemHeadline text={t.translate('Order details')} />
                  <OrderCard order={order}/>
                </Grid>
                <Grid item xs={12} container direction="column">
                  <ItemHeadline text={t.translate('Products')} badgeCounter={order.positions?.length}/>
                  {order.positions?.map(position => (
                    <ProductItem
                      key={position.id}
                      position={position}
                      onProductDescriptionOpen={onProductDescriptionOpen}
                      order={order}
                      onStockHistoryShow={onStockHistoryShow}
                    />
                  ))}
                </Grid>
                <Grid item container spacing={3} direction="column">
                  <Grid item xs={12} container direction="column">
                    <ItemHeadline text={t.translate('Invoice and shipping address')}/>
                    <AddressesDetails invoiceAddress={order.invoiceAddress} shippingAddress={order.deliveryAddress} order={order}/>
                  </Grid>
                  <Grid item xs={12} container direction="column">
                    <ItemHeadline text={t.translate('Total')}/>
                    <OrderDetails order={order} />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Column>
  );
};

MobileView.propTypes = {
  order: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  onProductDescriptionOpen: PropTypes.func.isRequired,
  onStockHistoryShow: PropTypes.func.isRequired,
};
