import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useTranslator } from 'i18n';
import {
  useScreen,
  useDialog,
  useList,
} from 'hooks';

import {
  selectDetailsStorageProducts,
  selectDetailsStorage,
} from 'store/selectors/storages';

import {
  DesktopView,
  MobileView,
  Header,
} from 'views/storages/details';

import { StorageTicket } from 'components';
import {
  QrCodePrintDialog,
  ConfirmingDialog,
  StorageDialog,
} from 'dialogs';

import {
  selectActionPermission,
} from 'store/selectors/account';

import { deleteStorage } from 'api/storages';

import { fetchStorage, fetchStorageProducts } from 'services/storages';
import { fetchWarehouses } from 'services/warehouses';

import toaster from 'services/toaster';

import { clearStorageProducts } from 'store/actions/storages';
import tables from 'config/tables';

export const StorageDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const screen = useScreen();
  const params = useParams();
  const list = useList(tables.products.products);
  const t = useTranslator();

  const printingDialog = useDialog({ storage: null });
  const storageDialog = useDialog({ storage: null, canEditTransitOption: false });
  const confirmingDialog = useDialog({ storage: null });

  const history = useHistory();

  const removeStorage = async () => {
    try {
      await deleteStorage(confirmingDialog.data.storage.id);
      toaster.success(t.translate('Storage has been deleted successfully.'));
      history.goBack();
    } catch (err) {}
  };

  const canViewStorageProducts = useSelector(selectActionPermission('products', 'view'));
  const canViewWarehouses = useSelector(selectActionPermission('warehouse', 'view'));
  const canDeleteStorage = useSelector(selectActionPermission('storages', 'delete'));
  const canEditStorage = useSelector(selectActionPermission('storages', 'edit'));
  const canEditTransitOption = useSelector(selectActionPermission('storages', 'transit'));
  const canEditExcludeStockOption = useSelector(selectActionPermission('storages', 'excludeStock'));

  const storageProducts = useSelector(selectDetailsStorageProducts);
  const storage = useSelector(selectDetailsStorage);

  const fetchData = () => {
    if (canViewStorageProducts) {
      dispatch(fetchStorageProducts(params.storageId, {
        ...list.compile(),
        isSkipEmpty: true,
        isManufacturingDateAgnostic: true,
      }));
    }

    if (canViewWarehouses) {
      dispatch(fetchWarehouses(list.compile()));
    }

    dispatch(fetchStorage(params.storageId));
    return () => dispatch(clearStorageProducts());
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchData, [params.productId, fetchWarehouses, location.search]);

  return (
    <>
      <Helmet>
        <title>{storage ? `${storage.id} | ` : ''} {t.translate('Storages')} | {t.translate('app:title')}</title>
      </Helmet>

      <QrCodePrintDialog
        {...printingDialog.props}
        {...(printingDialog.data.storage ? {
          ticket: <StorageTicket storage={printingDialog.data.storage} />,
          value: printingDialog.data.storage.id.toString(),
        } : {
          ticket: '',
          value: '',
        })}
      />

      {(storageDialog.data.storage && canEditStorage) && (
        <StorageDialog
          {...storageDialog.props}
          onSave={fetchData}
        />
      )}

      {canDeleteStorage && (
        <ConfirmingDialog
          {...confirmingDialog.props}
          title={t.translate('Delete storage')}
          acceptButtonText={t.translate(`Yes, I'm sure`)}
          declineButtonText={t.translate('No, cancel')}
          onAccept={removeStorage}
        >
          {t.translate('Are you sure you want to delete the storage?')}
        </ConfirmingDialog>
      )}

      <Header
        storage={storage}
        canEditStorage={canEditStorage}
        canDeleteStorage={canDeleteStorage}
        onStoragePrint={storage => printingDialog.open({ storage })}
        onStorageEdit={storage => storageDialog.open({ storage, canEditTransitOption, canEditExcludeStockOption })}
        onStorageDelete={storage => confirmingDialog.open({ storage })}
      />

      {screen.isMobile ? (
        <MobileView
          storageProducts={storageProducts}
          storage={storage}
        />
      ) : (
        <DesktopView
          canViewStorageProducts={canViewStorageProducts}
          storageProducts={storageProducts}
          storage={storage}
        />
      )}
    </>
  );
};
