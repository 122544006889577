import React from 'react';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';

import { OrderStatuses, SupplierHandlingTypes } from 'consts';

const isCancelled = status => [OrderStatuses.DENIED, OrderStatuses.SOLD_OUT, OrderStatuses.PARTIALLY_CANCELLED, OrderStatuses.CANCELLED_BY_SELLER, OrderStatuses.CANCELLED_BY_BUYER].includes(Number(status));

export const selectPositionStatus = (status, orderType = SupplierHandlingTypes.DEFAULT, inReview = false, classes = {}) => {
  if (isCancelled(status)) {
    return { text: 'Cancelled', icon: <CancelOutlinedIcon className={classes.cancelIcon} /> };
  } else if (orderType === SupplierHandlingTypes.TRANSIT && Number(status) === OrderStatuses.PROCESSING) {
    return { text: 'Processing', icon: <HourglassEmptyIcon className={classes.orderedIcon} /> };
  } else if ((orderType === SupplierHandlingTypes.DROPSHIPPING_DEFAULT || orderType === SupplierHandlingTypes.DROPSHIPPING_AUTO_ORDER || orderType === SupplierHandlingTypes.TRANSIT) && Number(status) !== OrderStatuses.CLOSED) {
    return { text: 'Ordered', icon: <HourglassEmptyIcon className={classes.orderedIcon} /> };
  } else if (orderType === SupplierHandlingTypes.DEFAULT && inReview) {
    return { text: 'Out of stock', icon: <HelpOutlineRoundedIcon className={classes.orderedIcon} /> };
  } else if (Number(status) === OrderStatuses.CLOSED) {
    return { text: 'Shipped', icon: <CheckCircleOutlineIcon className={classes.doneIcon} /> };
  } else {
    return { text: 'In stock', icon: <CheckCircleOutlineIcon className={classes.doneIcon} /> };
  }
};
