import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './ProductItem.jsx';
import { useTranslator } from 'i18n';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import {
  OrderLink,
  Picture,
  AlzuraSpriteIcon,
} from 'components';
import { findRelatedProductOrders } from 'utils/querying';

const defaultAdditionalImage = (additionalImages = []) => (
  additionalImages?.find(image => image.isDefault)
);

export const ProductItem = ({
  onPickupSheetProductEdit,
  canEditPickupSheet,
  pickupSheetProduct,
  pickupSheet,
  onProductLocationShow,
}) => {
  const { classes } = useStyles();
  const t = useTranslator();

  const relatedOrders = findRelatedProductOrders(pickupSheetProduct, pickupSheet.orders);

  return (
    <Grid item className={classes.item}>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item className={classes.contentItem}>
            <Typography variant="body2" className={classes.bold}>{pickupSheetProduct.product.details[0]?.name || '—'}</Typography>
            <Box>
              <Typography variant="body2" component="span" className={classes.bold}>EAN:&nbsp;</Typography>
              <Typography variant="body2" component="span" className={classes.bold}>{pickupSheetProduct.product.ean || '—'}</Typography>
            </Box>
            <Box>
              <Typography variant="body2" component="span" className={classes.bold}>Supplier ID:&nbsp;</Typography>
              <Typography variant="body2" component="span" className={classes.bold}>{pickupSheetProduct.supplierId || '—'}</Typography>
            </Box>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item className={classes.contentItem}>
            <Picture
              className={classes.photo}
              image={defaultAdditionalImage(pickupSheetProduct.product.additionalImages) || pickupSheetProduct.product.photo || pickupSheetProduct.product.imageUrl}
            />
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Product ID')}</Typography>
              <Typography variant="body2">{pickupSheetProduct.product.id}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('ALZURA-ID')}</Typography>
              <Typography variant="body2">{pickupSheetProduct.product.alzuraId || '—'}</Typography>
            </Grid>
            <Grid container>
              <Typography variant="body2" className={classes.bold}>{t.translate('Orders')}</Typography>
              {pickupSheet.orders.length ? (
                <Grid container direction="column" align="right">
                  <OrderLink pickupSheetProduct={pickupSheetProduct} orders={pickupSheet.orders} showList />
                </Grid>
              ) : '—'}
            </Grid>
            <Grid container justifyContent="space-between" style={{ flexWrap: 'wrap' }}>
              <Typography variant="body2" className={classes.bold}>{t.translate('Fahrzeug')}</Typography>
              <Typography variant="body2">
                {(
                  relatedOrders[0]
                    ?.positions?.find(position => position.productId === pickupSheetProduct.product.id)
                    ?.attributes?.find(attribute => attribute.generic_key === 'fahrzeug')?.value
                  || relatedOrders[0]
                    ?.positions?.find(position => position.productId === pickupSheetProduct.product.id)
                    ?.attributes?.find(attribute => attribute.generic_key === 'vehicleinformation')?.value
                ) || '—'}
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Paper className={classes.paper}>
        <Grid container direction="column">
          <Grid item className={classes.contentItem}>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Ordered')}</Typography>
              <Typography variant="body2">{pickupSheetProduct.quantity}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Packed')}</Typography>
              {pickupSheetProduct.packedQuantity === pickupSheetProduct.quantity
                ? <CheckCircleOutlineIcon className={classes.doneIcon} />
                : pickupSheetProduct.packedQuantity}
            </Grid>
          </Grid>
          <Grid item className={classes.contentItem}>
            <Grid item align="right" className={classes.storageInfo}>
              <Button
                className={classes.button}
                startIcon={<AlzuraSpriteIcon id="cupboard" />}
                onClick={() => onProductLocationShow(
                  {
                    productId: pickupSheetProduct.product.id,
                    orderId: relatedOrders[0]?.id,
                  }
                )}
              >
                {t.translate('Storage location details')}
              </Button>
            </Grid>
            {canEditPickupSheet && !pickupSheet.packingLabelsDocument && !pickupSheet.status === 'packed' && (
              <Grid item align="right">
                <Button
                  className={classes.button}
                  startIcon={<EditIcon />}
                  onClick={() => onPickupSheetProductEdit(pickupSheetProduct)}
                >
                  {t.translate('Edit the pickup sheet product.')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

ProductItem.propTypes = {
  onPickupSheetProductEdit: PropTypes.func.isRequired,
  onProductLocationShow: PropTypes.func.isRequired,
  canEditPickupSheet: PropTypes.bool.isRequired,
  pickupSheetProduct: PropTypes.shape({
    packedQuantity: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    product: PropTypes.shape({
      description: PropTypes.string,
      photo: PropTypes.object,
      name: PropTypes.string,
      ean: PropTypes.string,
      alzuraId: PropTypes.string,
      imageUrl: PropTypes.string,
      additionalImages: PropTypes.array,
    }).isRequired,
  }).isRequired,
  pickupSheet: PropTypes.object,
};
