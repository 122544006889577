import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: theme.palette.backgrounds.grey[240],
    margin: theme.spacing(2, 0, 1),
    width: '100%',
  },
  storageItem: {
    width: '100%',
  },
  boxColorItem: {
    '&.MuiGrid-item': {
      padding: theme.spacing(1),
    },
  },
  button: {
    border: `2px solid ${theme.palette.grey['205']}`,
    borderRadius: '4px',
    color: theme.palette.grey['205'],
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    width: '30%',
    '&:nth-child(n+4)': {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
      width: '48%',
    },
    '& svg': {
      position: 'absolute',
      left: theme.spacing(2.25),
    },
    '&:hover': {
      background: 'none',
    },
    '&.MuiToggleButton-root.Mui-selected': {
      background: 'none',
    },
    '&.MuiToggleButtonGroup-grouped:not(:first-child)': {
      borderWidth: '2px',
    },
  },
  toggleButton: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    '& button, & .Mui-selected': {
      backgroundColor: `${theme.palette.common.white} !important`,
    },
    '& .MuiToggleButtonGroup-grouped:not(:first-child)': {
      marginLeft: 0,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
}));
