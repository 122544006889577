import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const useStyles = makeStyles()((theme) => ({
  badge: {
    alignItems: 'center',
    background: theme.palette.text.itemHeadline,
    borderRadius: '7px',
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    height: '16px',
    width: '16px',
    '& p': {
      fontSize: '0.6rem',
      fontWeight: 500,
    },
  },
  grid: {
    marginBottom: theme.spacing(1),
  },
  root: {
    color: theme.palette.text.itemHeadline,
    fontWeight: 500,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(0.775),
  },
}));

export const ItemHeadline = ({ text, badgeCounter, secondaryText, secondaryComponent, ...props }) => {
  const { classes } = useStyles();

  return (
    <Grid container justifyContent="space-between">
      <Grid item container alignItems="center" className={classes.grid} {...props}>
        <Typography className={classes.root} variant="body2">{text}</Typography>
        {badgeCounter && (
          <Box className={classes.badge}>
            <Typography variant="body2">{badgeCounter}</Typography>
          </Box>
        )}
      </Grid>
      {secondaryText && (
        <Grid item container justifyContent="flex-end" alignItems="center" className={classes.grid} {...props}>
          {secondaryComponent || null}
          <Typography className={classes.root} variant="body2">{secondaryText}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

ItemHeadline.propTypes = {
  text: PropTypes.string.isRequired,
  badgeCounter: PropTypes.number,
  secondaryText: PropTypes.string,
  secondaryComponent: PropTypes.object,
};
