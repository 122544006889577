import React from 'react';
import PropTypes from 'prop-types';

import { useList } from 'hooks/list';
import { useTranslator } from 'i18n';

import { useDialog } from 'hooks';

import { useTableSortableHeadCell } from 'hooks/table';

import TableContainer from '@mui/material/TableContainer';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InfoIcon from '@mui/icons-material/Info';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';

import {
  TrackingLink,
  ActionButton,
} from 'components';

import { textOrderStatus } from 'services/orders';
import { CustomerOrdersDialog } from 'dialogs';

import { styleMobileTableItem } from 'theme/mixins/table';
import tables from 'config/tables';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  cardLabel: {
    color: theme.palette.text.itemHeadline,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  table: {
    '& tr th': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    '& th:first-of-type, & td:first-of-type': {
      paddingLeft: theme.spacing(2),
    },
    '& td:last-child': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '& td:last-child button': {
      color: theme.palette.primary.main,
    },
  },
}));

export const OrdersTable = ({ customer }) => {
  const t = useTranslator();
  const list = useList(tables.customers.orders);
  const { classes } = useStyles();

  const SortableCell = useTableSortableHeadCell({
    changeSorting: list.changeSorting,
    sorting: list.sorting,
  });

  const customerOrdersDialog = useDialog();

  return (
    <>
      {customerOrdersDialog.visible && (
        <CustomerOrdersDialog
          {...customerOrdersDialog.props}
        />
      )}

      <Typography className={classes.cardLabel} variant="body2">{t.translate('Orders')}</Typography>
      <Paper>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <SortableCell name="date">{t.translate('Date')}</SortableCell>
                <SortableCell name="number">{t.translate('Order no')}</SortableCell>
                <SortableCell name="status">{t.translate('Status')}</SortableCell>
                <TableCell>{t.translate('Tracking')}</TableCell>
                <SortableCell name="netSum">{t.translate('Net sum')}</SortableCell>
                <SortableCell name="grossSum">{t.translate('Gross sum')}</SortableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {customer.orders.map((order, index) => (
                <TableRow key={index}>
                  <TableCell>{t.formatDate(order.date)} {t.formatTime(order.date)}</TableCell>
                  <TableCell>{order.number}</TableCell>
                  <TableCell>{textOrderStatus(order, { t })}</TableCell>
                  <TableCell><TrackingLink tracking={order.tracking} /></TableCell>
                  <TableCell>{t.formatPrice(Number(order.netSum))} &euro;</TableCell>
                  <TableCell><Typography>{t.formatPrice(Number(order.grossSum))} &euro;</Typography></TableCell>
                  <TableCell>
                    <ActionButton
                      tooltipPlacement="left"
                      aria-label={t.translate('Open customer order')}
                      tooltip={t.translate('verb:Open')}
                      icon={<InfoIcon />}
                      onClick={() => customerOrdersDialog.open({ order })}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

OrdersTable.propTypes = {
  customer: PropTypes.shape({
    orders: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      number: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      netSum: PropTypes.string,
      grossSum: PropTypes.string,
      tracking: PropTypes.array,
    })).isRequired,
    deliveryAddress: PropTypes.shape({
      name: PropTypes.string,
      city: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      street: PropTypes.string.isRequired,
      house: PropTypes.string,
      zip: PropTypes.string.isRequired,
    }),
    id: PropTypes.number.isRequired,
  }),
};
