import config from 'config';
import appApi from 'api/app';
import store from 'store';
import {
  toggleNavigation as toggleNavigationVisibility,
  setApiVersion,
} from 'store/actions/app';

export const fetchApiVersion = () => async dispatch => {
  try {
    const version = await appApi.fetchApiVersion();
    dispatch(setApiVersion(version));
  } catch (err) {}
};

export const toggleNavigation = () => dispatch => {
  localStorage.setItem(config.app.navigationType, store.getState().app.navigation.visible ? 'thumbnail' : 'full');
  dispatch(toggleNavigationVisibility());
};

export default {
  toggleNavigation,
  fetchApiVersion,
};
