import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslator } from 'i18n';
import {
  useCancelSource,
  useScreen,
  useDialog,
  didMount,
} from 'hooks';

import { UserDialog } from 'dialogs/UserDialog/UserDialog';
import {
  DesktopView,
  MobileView,
} from 'views/users/details';
import { CircularLoader } from 'components';

import { setDetailsUserWarehouseAvailability, setDetailsUser } from 'store/actions/users';
import { disableUserWarehouse, enableUserWarehouse } from 'api/users';
import { fetchWarehouses, fetchUser } from 'services/users';
import { selectAuthenticatedUser } from 'store/selectors/account';
import { selectDetailsUser } from 'store/selectors/users';
import { isLoading } from 'store/selectors/app';

export const UserDetails = () => {
  const cancelSource = useCancelSource();
  const dispatch = useDispatch();
  const history = useHistory();
  const screen = useScreen();
  const params = useParams();
  const t = useTranslator();

  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const user = useSelector(selectDetailsUser);
  const isLoadingUser = useSelector(isLoading('users.details.user'));

  const isUserAuthenticated = Number(params.userId) === authenticatedUser.id;

  const modifyingDialog = useDialog();

  const fetchData = useCallback(() => dispatch(fetchUser(params.userId)), [dispatch, params.userId]);

  didMount(() => {
    if (!isUserAuthenticated) {
      dispatch(fetchWarehouses());
      fetchData();
    }
    return () => dispatch(setDetailsUser(null));
  });

  const changeWarehouseAvailability = warehouseId => async ({ target: { checked } }) => {
    const cancelToken = cancelSource.issueToken(warehouseId);
    try {
      dispatch(setDetailsUserWarehouseAvailability(warehouseId, checked));
      if (checked) {
        await enableUserWarehouse({ warehouseId, userId: user.id }, { cancelToken });
      } else {
        await disableUserWarehouse({ warehouseId, userId: user.id }, { cancelToken });
      }
      cancelSource.clear(warehouseId)();
    } catch (err) {
      cancelSource.clear(warehouseId)(err);
    }
  };

  if (isUserAuthenticated) {
    history.replace('/forbidden');
  }

  if (isLoadingUser) {
    return <CircularLoader />;
  }

  return !isUserAuthenticated && (
    <>
      <Helmet>
        <title>{user ? `${user.name} | ` : ''}{t.translate('Users administration')} | {t.translate('app:title')}</title>
      </Helmet>

      <UserDialog
        {...modifyingDialog.props}
        user={user}
        onSave={fetchData}
      />

      {screen.isMobile ? (
        <MobileView
          onWarehouseAvailabilityChange={changeWarehouseAvailability}
          onUserEdit={() => modifyingDialog.open()}
          onSave={fetchData}
        />
      ) : (
        <DesktopView
          onWarehouseAvailabilityChange={changeWarehouseAvailability}
          onUserEdit={() => modifyingDialog.open()}
          onSave={fetchData}
        />
      )}
    </>
  );
};
