import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { useTranslator } from 'i18n';
import { useLoading } from 'hooks';

import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from 'tss-react/mui';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { approveOrder } from 'api/orders';
import toaster from 'services/toaster';

import { OrderStatuses, ReturnStatuses, SupplierHandlingTypes } from 'consts';
import { AlzuraSpriteIcon } from '../../../../../components';
import { useSelector } from 'react-redux';
import { isAuthenticatedUserAdministrator } from '../../../../../store/selectors/account';
import GetAppIcon from '@mui/icons-material/GetApp';

const useStyles = makeStyles()({
  button: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    textTransform: 'initial',
  },
  svg: {
    fontSize: '1.5rem',
    '& > *:first-of-type': {
      fontSize: 'inherit',
    },
  },
});

export const DesktopControls = ({
  canApproveOrder,
  onOrderApprove,
  canEditOrder,
  isOrderHasReturns,
  onOrderAdjust,
  onOrderReturnStock,
  canPerformOrderActions,
  onOrderCancel,
  onOrderShipAgain,
  onUpdateOutboundStatus,
  order,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const loading = useLoading();
  const isAdmin = useSelector(isAuthenticatedUserAdministrator);
  const today = dayjs.utc().startOf('day');
  const [isOverdue] = useState(order.positions.some(position => dayjs.utc(position.estimatedDelivery) < today));
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

  const approve = loading.lock(async () => {
    try {
      await approveOrder(order.id);
      toaster.success(t.translate('The order has been approved successfully.'));
      onOrderApprove();
    } catch (err) { }
  });

  const updateStatus = async () => {
    try {
      setIsUpdatingStatus(true);

      await onUpdateOutboundStatus();
      toaster.success(t.translate('The outbound status has been updated successfully.'));
    } catch (err) {
    } finally {
      setIsUpdatingStatus(false);
    }
  };

  const { handlingType } = order.supplier;
  const canBeShippedAgain = canPerformOrderActions
    && order.statusCode === OrderStatuses.CLOSED
    && (handlingType === SupplierHandlingTypes.DEFAULT
      || (order.returnStatus === ReturnStatuses.returned && [SupplierHandlingTypes.DROPSHIPPING_DEFAULT, SupplierHandlingTypes.TRANSIT].includes(handlingType)))
    && (order.additionalShipments.length < 2 || isAdmin);

  return (
    <>
      {order.inReview && canApproveOrder && (
        <Grid item>
          <Button
            color="inherit"
            className={classes.button}
            startIcon={loading.progress
              ? <CircularProgress color="inherit" size={24} />
              : <CheckCircleIcon />}
            onClick={() => approve()}
            classes={{ iconSizeMedium: classes.svg }}
          >
            {t.translate('Approve')}
          </Button>
        </Grid>
      )}
      {(order.statusCode === OrderStatuses.QUEUE
        || order.statusCode === OrderStatuses.PARTIALLY_CANCELLED)
        && canEditOrder
        && !order.orderedAtSupplier
        && (
        <Grid item>
          <Tooltip
            placement="bottom"
            title={t.translate('Adjust the additional costs of an order or increase the ordered amount.')}
          >
            <Button
              color="inherit"
              className={classes.button}
              startIcon={<EditIcon />}
              onClick={onOrderAdjust}
              classes={{ iconSizeMedium: classes.svg }}
            >
              {t.translate('Adjust')}
            </Button>
          </Tooltip>
        </Grid>
      )}
      {(
        (
          order.statusCode === OrderStatuses.COMPLETED
          || order.statusCode === OrderStatuses.CLOSED
          || order.statusCode === OrderStatuses.CANCELLED_BY_SELLER
        ) && canPerformOrderActions
      ) && (
        <Grid item>
          <Button
            color="inherit"
            className={classes.button}
            startIcon={<AlzuraSpriteIcon id='assignmentReturn' />}
            onClick={onOrderReturnStock}
            classes={{ iconSizeMedium: classes.svg }}
          >
            {t.translate('Return')}
          </Button>
        </Grid>
      )}
      {canBeShippedAgain && (
        <Grid item>
          <Button
            color="inherit"
            className={classes.button}
            startIcon={<AlzuraSpriteIcon id='assignmentReturn'/>}
            onClick={onOrderShipAgain}
            classes={{ iconSizeMedium: classes.svg }}
          >
            {t.translate('Ship again')}
          </Button>
        </Grid>
      )}
      {
        isOverdue
        && (order.statusCode !== OrderStatuses.CLOSED)
        && [SupplierHandlingTypes.DROPSHIPPING_AUTO_ORDER, SupplierHandlingTypes.TRANSIT].includes(handlingType)
        && (
          <Grid item>
            <Button
              color="inherit"
              startIcon={<GetAppIcon />}
              className={classes.button}
              onClick={updateStatus}
              classes={{ iconSizeMedium: classes.svg }}
              disabled={isUpdatingStatus}
            >
              {t.translate('Refresh Status')}
            </Button>
          </Grid>
      )}
      {((order.statusCode === OrderStatuses.QUEUE || order.statusCode === OrderStatuses.CLOSED) && canPerformOrderActions)
      && !order.orderedAtSupplier
      && !isOrderHasReturns
      && (
        <Grid item>
          <Button
            color="inherit"
            className={classes.button}
            startIcon={<CancelIcon />}
            onClick={onOrderCancel}
            classes={{ iconSizeMedium: classes.svg }}
          >
            {t.translate('Cancel')}
          </Button>
        </Grid>
      )}
    </>
  );
};

DesktopControls.propTypes = {
  canApproveOrder: PropTypes.bool.isRequired,
  onOrderApprove: PropTypes.func.isRequired,
  canEditOrder: PropTypes.bool.isRequired,
  isOrderHasReturns: PropTypes.bool,
  onOrderAdjust: PropTypes.func.isRequired,
  onOrderShipAgain: PropTypes.func.isRequired,
  canPerformOrderActions: PropTypes.bool.isRequired,
  onOrderCancel: PropTypes.func.isRequired,
  order: PropTypes.object,
};
