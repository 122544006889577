import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';
import { makeStyles } from 'tss-react/mui';
import TablePagination from '@mui/material/TablePagination';

const useStyles = makeStyles()({
  row: {
    '& .MuiTablePagination-spacer': {
      display: 'none',
    },
  },
});

export const LocalPagination = ({
  onChange,
  defaultRowsPerPage,
  direction,
  count,
  ...props
}) => {
  const t = useTranslator();
  const { classes, cx } = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [page, setPage] = useState(0);

  const labelDisplayedRows = ({ from, to, count }) => t.translate('{from}-{to} of {count}', {
    count: count === -1
      ? t.translate('more than {to}', { to })
      : count,
    from,
    to,
  });

  const changeRowsPerPage = rowsPerPage => {
    setRowsPerPage(rowsPerPage);
    onChange({ rowsPerPage, page });
  };
  const changePage = (e, page) => {
    onChange({ rowsPerPage, page: page });
    setPage(page);
  };

  return (
    <TablePagination
      labelDisplayedRows={labelDisplayedRows}
      labelRowsPerPage={t.translate('Rows per page') + ':'}
      rowsPerPage={rowsPerPage}
      component="div"
      count={count}
      page={page}
      onPageChange={changePage}
      onRowsPerPageChange={e => changeRowsPerPage(e.target.value)}
      {...props}
      className={cx(props.className, direction === 'row' && classes.row)}
    />
  );
};

LocalPagination.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultRowsPerPage: PropTypes.number.isRequired,
  direction: PropTypes.oneOf(['row', 'row-reverse']),
  count: PropTypes.number.isRequired,
};

LocalPagination.defaultProps = {
  direction: 'row',
};
