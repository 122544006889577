import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useStyles } from './style';
import { useTranslator } from 'i18n';
import { BusyButton, StockFormControl } from 'components';
import { StorageReassignAutocomplete } from 'autocompletes';
import { fetchStorage } from 'api/storages';
import { evaluateStock } from 'api/storage-products';
import { extractApiErrors } from 'utils/api';
import { customizeEvaluations } from 'utils/evaluateStock';
import toaster from '../../services/toaster';
import { StorageTypes } from '../../consts';

const makeId = postfix => `stock-evaluation-dialog--${postfix}`;

export const StockEvaluationDialog  = ({ position, onClose, isMobileView, onSave, ...props }) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const warehouseId = useSelector(({ account }) => account.warehouse.id);

  const [isLoading, setIsLoading] = useState(false);
  const [initialValues] = useState({
    reSellQuantity: 0,
    repatriationQuantity: 0,
    trashQuantity: 0,
    reSellStorageSearch: '',
    repatriationStorageSearch: '',
  });
  const [reSellStorage, setReSellStorage] = useState(null);
  const [repatriationStorage, setRepatriationStorage] = useState(null);

  const [remainingQuantity, setRemainingQuantity] = useState(0);

  const validationSchema = Yup
    .object()
    .shape({
      reSellStorageSearch: Yup.string(),
      repatriationStorageSearch: Yup.string(),
      reSellQuantity: Yup.number(),
      repatriationQuantity: Yup.number(),
      trashQuantity: Yup.number(),
    });

  const onSubmit = async (values, { setErrors, resetForm }) => {
    const evaluations = customizeEvaluations(reSellStorage, repatriationStorage, values);
    if (!evaluations.length) {
      toaster.error(t.translate('At least one position must have a evaluated quantity and a storage'));
      return;
    }

    try {
      setIsLoading(true);
      await evaluateStock({
        orderPositionReturnId: position.id,
        evaluations,
      });

      resetForm();
      onSave();
      onClose();
    } catch (err) {
      setErrors(extractApiErrors(err));
    } finally {
      setIsLoading(false);
    }
  };

  const {
    setFieldValue,
    handleSubmit,
    values,
    errors,
  } = useFormik({
    validateOnChange: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  const getTopBound = (field) => {
    const maxTopBound = position.returnedQuantity - position.evaluatedQuantity;

    switch (field) {
      case 'reSellQuantity': return maxTopBound - values.trashQuantity - values.repatriationQuantity;
      case 'repatriationQuantity': return maxTopBound - values.trashQuantity - values.reSellQuantity;
      case 'trashQuantity': return maxTopBound - values.reSellQuantity - values.repatriationQuantity;
      default: return;
    }
  };

  useEffect(() => {
    setRemainingQuantity(position.returnedQuantity - position.evaluatedQuantity - values.reSellQuantity -
      values.trashQuantity - values.repatriationQuantity);
  }, [values, setRemainingQuantity, position]);

  return (
    <Dialog {...props} scroll="body" fullScreen={isMobileView} classes={{ paperWidthSm: classes.root }} onClose={onClose}>
      <DialogTitle id="max-width-dialog-title">{t.translate('Stock Evaluation')}</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell/>
                <TableCell>{t.translate('Storage')}</TableCell>
                <TableCell align="center">{t.translate('Quantity')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={classes.returnRow}>
                <TableCell>{t.translate('Re-sell')}</TableCell>
                <TableCell>
                  <Grid container spacing={1}>
                    <StorageReassignAutocomplete
                      id={makeId('source-storage-number')}
                      error={errors.reSellStorageSearch}
                      onChange={(value) => {
                        setReSellStorage(null);
                        setFieldValue('reSellStorageSearch', value);
                      }}
                      type={StorageTypes.DEFAULT}
                      value={values.reSellStorageSearch}
                      warehouseId={warehouseId}
                      name="reSellStorageSearch"
                      onSelect={async (event, selectedOption) => {
                        if (selectedOption) {
                          const storage = await fetchStorage(selectedOption.value);
                          setReSellStorage(storage);
                          await setFieldValue('reSellStorageSearch', selectedOption.label);
                        }
                      }}
                    />
                  </Grid>
                </TableCell>
                <TableCell style={{ minWidth: 200 }}>
                  <StockFormControl
                    hideLabels={true}
                    actions={['add']}
                    stock={values.reSellQuantity}
                    name={`reSellQuantity`}
                    value={values.reSellQuantity}
                    topBound={getTopBound(`reSellQuantity`)}
                    onChange={e => setFieldValue(`reSellQuantity`, e)}
                  />
                </TableCell>
              </TableRow>
              <TableRow className={classes.emptyRow} />
              <TableRow className={classes.returnRow}>
                <TableCell>{t.translate('Return to original supplier')}</TableCell>
                <TableCell>
                  <Grid container spacing={1}>
                    <StorageReassignAutocomplete
                      id={makeId('source-storage-number')}
                      error={errors.repatriationStorageSearch}
                      onChange={(value) => {
                        setRepatriationStorage(null);
                        setFieldValue('repatriationStorageSearch', value);
                      }}
                      type={StorageTypes.TEMPORARY}
                      value={values.repatriationStorageSearch}
                      warehouseId={warehouseId}
                      name="repatriationStorageSearch"
                      onSelect={async (event, selectedOption) => {
                        if (selectedOption) {
                          const storage = await fetchStorage(selectedOption.value);
                          setRepatriationStorage(storage);
                          await setFieldValue('repatriationStorageSearch', selectedOption.label);
                        }
                      }}
                    />
                  </Grid>
                </TableCell>
                <TableCell style={{ minWidth: 200 }}>
                  <StockFormControl
                    hideLabels={true}
                    actions={['add']}
                    stock={values.repatriationQuantity}
                    name={`repatriationQuantity`}
                    value={values.repatriationQuantity}
                    topBound={getTopBound(`repatriationQuantity`)}
                    onChange={e => setFieldValue(`repatriationQuantity`, e)}
                  />
                </TableCell>
              </TableRow>
              <TableRow className={classes.emptyRow} />
              <TableRow className={classes.returnRow}>
                <TableCell>{t.translate('To trash')}</TableCell>
                <TableCell/>
                <TableCell style={{ minWidth: 200 }}>
                  <StockFormControl
                    hideLabels={true}
                    actions={['add']}
                    stock={values.trashQuantity}
                    name={`trashQuantity`}
                    value={values.trashQuantity}
                    topBound={getTopBound(`trashQuantity`)}
                    onChange={e => setFieldValue(`trashQuantity`, e)}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container justifyContent="flex-end" className={classes.remainingContainer}>
          {`${t.translate('Remaining')}: ${remainingQuantity}`}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} disabled={isLoading}>{t.translate('dialog-action:Cancel')}</Button>
        <BusyButton color="primary" submit onClick={handleSubmit} disabled={isLoading}>{t.translate('Save')}</BusyButton>
      </DialogActions>
    </Dialog>
  );
};
