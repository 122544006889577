import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import * as Yup from 'yup';
import every from 'lodash/every';

import { useTranslator } from 'i18n';
import {
  didUpdate,
} from 'hooks';

import { useFormik } from 'formik';
import { extractApiErrors } from 'utils/api';

import TableContainer from '@mui/material/TableContainer';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useStyles } from './style.jsx';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';

import {
  FormControlError,
  InputControl,
} from 'components';

import {
  cancelOrderPosition,
  cancelOrder,
} from 'api/orders';

import { cancelReason, OrderStatuses } from 'consts';
import toaster from 'services/toaster';

import { ConfirmingCancelComponent } from './ConfirmingCancelComponent/ConfirmingCancelComponent';
import { CancellingPositionItem } from './CancellingPositionItem/CancellingPositionItem';
import { CancellingOrderRow }  from './CancellingOrderRow/CancellingOrderRow';

const makeId = postfix => `cancelling-order-dialog--${postfix}`;
const makeName = (id, name) => `${id}.${name}`;

const findAllByKey = (obj, keyToFind) => {
  return Object.entries(obj)
    .reduce((acc, [key, value]) => (key === keyToFind)
      ? acc.concat(value)
      : (typeof value === 'object')
        ? acc.concat(findAllByKey(value, keyToFind))
        : acc
    , []);
};

export const CancellingOrderDialog = ({
  isMobileView,
  onClose,
  onSave,
  order,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const isOrderClosed = order.statusCode === OrderStatuses.CLOSED;

  const [confirmingCanceling, setConfirmingCanceling] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const onSubmit = async (values, { setErrors }) => {
    setIsProcessing(true);
    try {
      if (values.parentForm.allOrderCancel || isOrderClosed) {
        const payload = {
          ...(!isOrderClosed && { comment: values.parentForm.comment }),
          reason: values.parentForm.reason,
        };
        await cancelOrder(order.id, payload);
        toaster.success(t.translate('Order has been cancelled successfully.'));
      } else {
        const payload = Object.keys(values)
          .filter(key => key !== 'parentForm' && !!values[key].cancelledAmount)
          .map(key => {
            const {
              allPositionCanceled,
              cancelledAmount,
              comment,
              reason,
            } = values[key];

            return {
              allPositionCanceled,
              orderPositionId: key,
              cancelledAmount,
              comment,
              reason,
            };
          });

        await cancelOrderPosition(order.id, payload);
        toaster.success(t.translate('Order positions has been cancelled successfully.'));
      }

      onSave();
      onClose();
    } catch (err) {
      setErrors(extractApiErrors(err));
    }
    finally {
      setIsProcessing(false);
    }
  };

  const validationChildForms = order.positions.reduce((acc, { id }) => ({
    ...acc,
    [id]: Yup
      .object()
      .shape({
        allPositionCanceled: Yup.boolean(),
        cancelledAmount: Yup.number(),
        reason: Yup
          .number()
          .when(['allOrderCancel', 'cancelledAmount'], {
            is: (allOrderCancel, cancelledAmount) => !!cancelledAmount && !allOrderCancel,
            then: Yup.number().notOneOf([0], t.translate('Please select a reason.')),
          }),
        comment: Yup
          .string()
          .when(['allOrderCancel', 'cancelledAmount'], {
            is: (allOrderCancel, cancelledAmount) => !!cancelledAmount && !allOrderCancel,
            then: Yup.string().required('Please enter a comment.'),
          }),
      }),
  }), {});

  const validationSchema = Yup
    .object()
    .shape({
      parentForm: Yup
        .object()
        .shape({
          reason: Yup
            .number()
            .when('allOrderCancel', {
              is: true,
              then: Yup.number().notOneOf([0], t.translate('Please select a reason.')),
            }),
          comment: Yup
            .string()
            .when('allOrderCancel', {
              is: true,
              then: Yup
                .string()
                .trim()
                .required(t.translate('Please enter a comment.')),
            }),
        }),
      ...validationChildForms,
  });

  const closedOrderValidationSchema = Yup.object().shape({
      parentForm: Yup.object().shape({
        reason: Yup.number().notOneOf([0], t.translate('Please select a reason.')),
      }),
    });

  const childForms = order.positions.reduce((acc, { id }) => ({
    ...acc,
    [id]: {
      allPositionCanceled: false,
      allOrderCancel: false,
      cancelledAmount: 0,
      comment: '',
      reason: 0,
  } }), {});

  const initialValues = {
    parentForm: {
      allOrderCancel: false,
      comment: '',
      reason: 0,
    },
    ...childForms,
  };

  const initialStatus = {
    disabledApply: true,
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnMount: !isOrderClosed,
    validateOnBlur: false,
    validationSchema: isOrderClosed ? closedOrderValidationSchema : validationSchema,
    initialValues,
    initialStatus,
    onSubmit,
  });

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    setStatus,
    resetForm,
    values,
    errors,
    status,
  } = formik;

  didUpdate(() => {
    if (props.open) {
      resetForm();
      setConfirmingCanceling(false);
    }
  }, [props.open]);

  useEffect(() => {
    setFieldValue(`parentForm.allOrderCancel`, every(findAllByKey(values, 'allPositionCanceled')));
    setStatus({ disabledApply: isOrderClosed ? false : !!!findAllByKey(values, 'cancelledAmount').filter(Boolean).length });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const cancelAllPosition = () => {
    setFieldValue(`parentForm.allOrderCancel`, true);
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        scroll="paper"
        {...props}
        className={classes.root}
        fullScreen={isMobileView}
        onClose={onClose}
      >
        {confirmingCanceling ? (
          <ConfirmingCancelComponent
            isMobileView={isMobileView}
            onAccept={handleSubmit}
            onClose={() => setConfirmingCanceling(false)}
            values={values}
            order={order}
          />
        ) : (
          <>
            <DialogTitle className={classes.title}>
              <Grid container justifyContent="space-between">
                <Grid item>{t.translate('Cancel order')}: {order.number}</Grid>
                {!isMobileView && !isOrderClosed && (
                  <>
                    <Grid item>
                      {values.parentForm.allOrderCancel ? (
                        <Button
                          variant="outlined"
                          onClick={resetForm}
                        >{t.translate('Reset')}
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          onClick={cancelAllPosition}
                        >{t.translate('Cancel all')}
                        </Button>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogTitle>
            <DialogContent >
              {isMobileView ? (
                <>
                  {!isOrderClosed && (
                    <>
                      <Grid item className={classes.mobileButton}>
                        {values.parentForm.allOrderCancel ? (
                          <Button
                            variant="outlined"
                            onClick={resetForm}
                          >{t.translate('Reset')}
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            onClick={cancelAllPosition}
                          >{t.translate('Cancel all')}
                          </Button>
                        )}
                      </Grid>
                      {order.positions.map((position, index) => (
                        <CancellingPositionItem
                        key={index}
                        position={position}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        values={values}
                        errors={errors}
                        setCancelAllQuantity={values.parentForm.allOrderCancel}
                        />
                        ))
                      }
                    </>
                  )}
                  {(values.parentForm.allOrderCancel || isOrderClosed) && (
                    <Grid container className={classes.expandedContainerItem} direction="column">
                      <Grid item className={classes.reasonItem}>
                        <FormControl fullWidth error={!!errors.parentForm?.reason}>
                          <InputLabel shrink htmlFor={makeId('reason')}>{t.translate('Reason')}</InputLabel>
                          <Select
                            id={makeId('reason')}
                            name={makeName('parentForm', 'comment')}
                            value={values.parentForm.reason}
                            onChange={(e) => setFieldValue(`parentForm.reason`, e.target.value)}
                          >
                            <MenuItem value={0}>{t.translate('Select')}</MenuItem>
                            {cancelReason.map(({ value, text }) => (
                              <MenuItem key={value} value={value}>{t.translate(text)}</MenuItem>
                            ))}
                          </Select>
                          {!!errors.parentForm?.reason && <FormControlError error={errors.parentForm?.reason} />}
                        </FormControl>
                      </Grid>
                      {!isOrderClosed && (
                        <Grid item>
                          <InputControl
                            id={makeId('comment')}
                            name={makeName('parentForm', 'comment')}
                            error={errors.parentForm?.comment}
                            label={t.translate('Comment')}
                            value={values.parentForm.comment}
                            placeholder={t.translate('Annotations')}
                            onChange={handleChange}
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}
                </>
              ) : (
                <>
                {!isOrderClosed && (
                  <TableContainer>
                    <Table className={classes.root}>
                      <TableHead>
                        <TableRow>
                          <TableCell name="productId">{t.translate('Product-ID')}</TableCell>
                          <TableCell name="productName">{t.translate('Product name')}</TableCell>
                          <TableCell name="ean">EAN</TableCell>
                          <TableCell name="productEan" align="center">{t.translate('Ordered')}</TableCell>
                          <TableCell name="quantity" align="center">{t.translate('Cancel')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {order.positions.map((position, index) => (
                            <React.Fragment key={index}>
                              <CancellingOrderRow
                                position={position}
                                setFieldValue={setFieldValue}
                                values={values}
                                errors={errors}
                                handleChange={handleChange}
                                setCancelAllQuantity={values.parentForm.allOrderCancel}
                              />
                              <TableRow className={classes.emptyRow} />
                            </React.Fragment>
                          ))
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {(values.parentForm.allOrderCancel || isOrderClosed) && (
                  <>
                    <Grid item className={classes.commentItem}>
                      <FormControl fullWidth error={!!errors.parentForm?.reason}>
                        <InputLabel shrink htmlFor={makeId('reason')}>{t.translate('Reason')}</InputLabel>
                        <Select
                          id={makeId('reason')}
                          name={makeName('parentForm', 'comment')}
                          value={values.parentForm.reason}
                          onChange={(e) => setFieldValue(`parentForm.reason`, e.target.value)}
                        >
                          <MenuItem value={0}>{t.translate('Select')}</MenuItem>
                          {cancelReason.map(({ value, text }) => (
                            <MenuItem key={value} value={value}>{t.translate(text)}</MenuItem>
                          ))}
                        </Select>
                        {!!errors.parentForm?.reason && <FormControlError error={errors.parentForm?.reason} />}
                      </FormControl>
                    </Grid>
                    {!isOrderClosed && (
                      <Grid item className={classes.commentItem}>
                        <InputControl
                          id={makeId('comment')}
                          name={makeName('parentForm', 'comment')}
                          error={errors.parentForm?.comment}
                          label={t.translate('Comment')}
                          value={values.parentForm.comment}
                          placeholder={t.translate('Annotations')}
                          onChange={handleChange}
                        />
                      </Grid>
                    )}
                  </>
                )}
                </>
            )}
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={onClose} disabled={isProcessing}>{t.translate('Discard')}</Button>
              <Button color="primary" onClick={() => setConfirmingCanceling(true)} disabled={status.disabledApply || isProcessing}>{t.translate('Apply')}</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

CancellingOrderDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  order: PropTypes.shape({
    number: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
};
