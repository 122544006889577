import React from 'react';
import PropTypes from 'prop-types';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { makeStyles } from 'tss-react/mui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import MenuList from '@mui/material/MenuList';
import List from '@mui/material/List';

const useStyles = makeStyles()(theme => ({
  root: {
    boxShadow: 'none',
    margin: '0 !important',
    '& .MuiAccordionSummary-root': {
      background: theme.palette.grey[50],
      minHeight: `${theme.spacing(6)} !important`,
      padding: theme.spacing(0, 2),
    },
    '& .MuiAccordionSummary-content': {
      ...theme.typography.body1,
      marginBottom: `${theme.spacing(1.5)} !important`,
      marginTop: `${theme.spacing(1.5)} !important`,
    },
    '& .MuiAccordionSummary-expandIcon': {
      color: theme.palette.text.primary,
    },
    '& .MuiAccordionDetails-root': {
      maxHeight: theme.spacing(64),
      overflow: 'hidden',
      overflowY: 'auto',
      padding: 0,
    },
    '& .MuiList-root': {
      padding: 0,
      width: '100%',
    },
  },
}));

export const ExpansionMenu = ({
  children,
  title,
  customVariant,
}) => {
  const { classes } = useStyles();

  return (
    <Accordion className={classes.root} defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{title}</AccordionSummary>
      <AccordionDetails>
        {customVariant !== 'list' ? (
          <MenuList>{children}</MenuList>
        ): (
          <List>{children}</List>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

ExpansionMenu.propTypes = {
  title: PropTypes.string.isRequired,
  customVariant: PropTypes.string,
};
