import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Picture } from 'components';
import { styleMobileTableItem } from 'theme/mixins/table';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  featureLabel: {
    fontWeight: 500,
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  photo: {
    flexShrink: 0,
    height: theme.spacing(15),
    width: theme.spacing(15),
  },
}));

const defaultAdditionalImage = (additionalImages = []) => (
  additionalImages?.find(image => image.isDefault)
);

export const OrderProductDescriptionDesktop = ({
  product,
  netPrice,
}) => {
  const { classes } = useStyles();
  const t = useTranslator();

  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={2} container direction="column">
        <Picture
          className={classes.photo}
          image={defaultAdditionalImage(product.additionalImages) || product.photo || product.imageUrl}
        />
        <Grid item container direction="column" className={classes.margin}>
          <Typography variant="body2" className={classes.featureLabel}>{t.translate('Product ID')}</Typography>
          <Typography variant="body2">{product.id}</Typography>
        </Grid>
        <Grid item container direction="column" className={classes.margin}>
          <Typography variant="body2" className={classes.featureLabel}>{t.translate('Product EAN code')}</Typography>
          <Typography variant="body2">{product.ean}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={9} container direction="column">
        <Grid item container>
          <Grid item container direction="column">
            <Typography variant="body2" className={classes.featureLabel}>{product.details[0]?.name}</Typography>
            <Typography variant="body2">{product.manufacturerName}</Typography>
            <Typography variant="body2">{product.type ? `${product.type.code} — ${t.translate(`productType:${product.type.code}`)}` : '—'}</Typography>
          </Grid>
          <Grid item container direction="column" alignItems="flex-end">
            <Typography variant="body2" className={classes.featureLabel}>{t.translate('Net single price')}</Typography>
            <Typography variant="body2">{t.formatPrice(Number(netPrice))} &euro;</Typography>
          </Grid>
        </Grid>
        <Grid item container direction="column" className={classes.margin}>
          <Typography variant="body2" className={classes.featureLabel}>{t.translate('Description')}</Typography>
          <Typography variant="body2">{product.details[0]?.description}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

OrderProductDescriptionDesktop.propTypes = {
  product: PropTypes.shape({
    additionalImages: PropTypes.array,
    photo: PropTypes.string,
    imageUrl: PropTypes.string,
    ean: PropTypes.string,
    name: PropTypes.string,
    manufacturerName: PropTypes.string,
    type: PropTypes.shape({
      code: PropTypes.string,
    }),
    id: PropTypes.number.isRequired,
  }).isRequired,
  netPrice: PropTypes.string.isRequired,
};
