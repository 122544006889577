import { combineReducers } from 'redux';
import replenishmentSuggestions from './replenishment-suggestions';
import outdatedProducts from './outdated-products';
import changesHistory from './changes-history';
import specialCases from './special-cases';
import pickupSheets from './pickup-sheets';
import warehouses from './warehouses';
import retailers from './retailers';
import suppliers from './suppliers';
import dashboard from './dashboard';
import customers from './customers';
import purchases from './purchases';
import companies from './companies';
import storages from './storages';
import products from './products';
import reports from './reports';
import account from './account';
import orders from './orders';
import rawOrders from './raw-orders';
import users from './users';
import i18n from './i18n';
import app from './app';

export default combineReducers({
  replenishmentSuggestions,
  outdatedProducts,
  changesHistory,
  specialCases,
  pickupSheets,
  warehouses,
  suppliers,
  companies,
  dashboard,
  customers,
  retailers,
  purchases,
  storages,
  products,
  reports,
  account,
  orders,
  rawOrders,
  users,
  i18n,
  app,
});
