import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CompanyDetailsView } from 'views/companies/details';
import { fetchCompany } from 'services/companies';
import { setDetailsCompany } from 'store/actions/companies';
import { didMount } from '../../../../../hooks';
import { isLoading } from '../../../../../store/selectors/app';
import socket, { SOCKET_EVENTS } from '../../../../../bootstrap/socket';
import countryApi from '../../../../../api/country';
import { selectAuthenticatedUser } from '../../../../../store/selectors/account';
import {
  AppHeaderPrimaryToolbar,
  AppHeaderBackButton,
  AppHeaderTitle,
  AppHeader,
  AppHeaderRightBox,
  CircularLoader,
} from 'components';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from 'tss-react/mui';
import Chip from '@mui/material/Chip';
import { useTranslator } from 'i18n';

const useStyles = makeStyles()((theme) => ({
  button: {
    color: 'inherit',
    height: 'inherit',
    textTransform: 'none',
    minWidth: theme.spacing(4),
    marginRight: theme.spacing(2),
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  editedByInfo: {
    color: 'inherit',
    fontSize: '0.875rem',
    fontWeight: 'normal',

    '&.MuiChip-root': {
      border: 'none',
    },
  },
  tooltip: {
    padding: theme.spacing(2),
  },
}));

setDetailsCompany(null);

export const CompanyDetailsPage = ({ routeTitle }) => {
  const params = useParams();
  const { classes } = useStyles();
  const company = useSelector(({ companies }) => companies.details.company);
  const dispatch = useDispatch();
  const t = useTranslator();
  const history = useHistory();

  const [countries, setCountries] = useState([]);

  const [onSubmit, setOnSubmit] = useState();
  const [isEditMode, setIsEditMode] = useState(!Boolean(params.companyId));
  const [isFieldsChanged, setIsFieldsChanged] = useState(false);

  const callFetchingCompany = useCallback((id) => dispatch(fetchCompany(id)), [dispatch]);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const isEditedByAnotherUser = company?.editedByUserId ? company.editedByUserId !== authenticatedUser?.id : false;

  didMount(() => {
    if (params.companyId) {
      callFetchingCompany(params.companyId);
    }

    countryApi.fetchManyCountries()
      .then(({ data }) => {
        setCountries(data);
      });

    socket.on(SOCKET_EVENTS.COMPANIES.EDITED_BY, async ({ entityId }) => {
      if (entityId === Number(params.companyId)) callFetchingCompany(entityId);
    });
  });

  useEffect(() => {
    return () => {
      dispatch(setDetailsCompany(null));
      socket.emit(SOCKET_EVENTS.COMPANIES.EDITED_BY, { entityId: Number(params.companyId), isEdited: false });
      socket.off(SOCKET_EVENTS.COMPANIES.EDITED_BY);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (company?.id) {
      socket.emit(SOCKET_EVENTS.COMPANIES.EDITED_BY, { entityId: company.id, isEdited: isEditMode });
    }
  }, [isEditMode, company?.id]);

  const isLoadingCompanies = useSelector(isLoading('companies.details.companies'));
  if (isLoadingCompanies || !countries.length) return <CircularLoader />;
  return (
    <>
      <AppHeader>
        <AppHeaderPrimaryToolbar>
          <AppHeaderBackButton edge="start" onClick={() => history.push('/settings/companies/overview')} />
          <AppHeaderTitle>
            {t.translate(routeTitle || 'Company details')}
          </AppHeaderTitle>
          <AppHeaderRightBox alignItems="center">
            {isEditedByAnotherUser && (
              <Chip
                className={classes.editedByInfo}
                variant="outlined"
                label={`${t.translate('This company is currently being edited by')} ${company.editedByUser.name}`}
              />
            )}
            {!isEditedByAnotherUser && (
              <Button
                className={classes.button}
                disabled={isEditMode && !isFieldsChanged}
                startIcon={isEditMode ? <SaveIcon /> : <EditIcon />}
                onClick={() => {
                  if (!isEditMode) {
                    setIsEditMode(true);
                  }
                  else if (onSubmit) {
                    onSubmit();
                  }
                }}
              >
                {t.translate(isEditMode ? 'Save' : 'Edit')}
              </Button>
            )}
          </AppHeaderRightBox>
        </AppHeaderPrimaryToolbar>
      </AppHeader>

      <CompanyDetailsView
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        setOnSubmit={setOnSubmit}
        setIsFieldsChanged={setIsFieldsChanged}
        company={company}
        countries={countries}
      />
    </>
  );
};
