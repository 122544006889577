import React from 'react';
import { Grid, TableBody, TableContainer, TableHead, Typography, Select, MenuItem } from '@mui/material';
import { useStyles } from '../styles';
import { CheckboxControl, InputControl, Table, TableCell, TableRow } from 'components';
import { useTranslator } from 'i18n';
import { PageWrapper } from '../../../suppliers/settings/PageWrapper/PageWrapper';
import { Hint } from '../../../suppliers/settings/Hint/Hint';

const makeId = postfix => `companies-document-numbers-settings--${postfix}`;

const formatYearOptions = ['NO', 'SHORT', 'LONG'];

export const DocumentNumbersSettings = ({ isEditMode, values, handleChange, errors, setFieldValue, companyId }) => {
  const t = useTranslator();
  const { classes } = useStyles();


  const getPreview = (documentSetting) => {
    const { prefix, yearFormat, separator, numberLength, counter } = documentSetting;

    const year = yearFormat === 'LONG'
      ? new Date().getFullYear()
      : (yearFormat === 'SHORT' ? new Date().getFullYear().toString().substr(-2) : ' ');

    const formattedCounter = counter.toString().padStart(numberLength, '0');

    return `${prefix || ''}${separator || ''}${year}${separator || ''}${formattedCounter}`;
  };

  const toggleResetCounterAnnually = (documentSettings) => {
    documentSettings.forEach((documentSetting, index) => {
      if (documentSetting) {
        setFieldValue(`documentSettings[${index}].isResetCounterAnnually`, !documentSettings[index].isResetCounterAnnually);
      }
    });
  };

  return (
    <PageWrapper>
      <Grid item xl={8} lg={10} xs={12} className={classes.containerWrap}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={10} className={classes.containerWrap}>
            <Grid container alignItems="center" className={classes.marginBottom}>
              <Typography variant="h6">{t.translate('Document numbers')}</Typography>
              <Hint className={classes.helpTextIcon} text={t.translate('With these settings, unique numbers for each document will be created which will also be used in accounting and customer / partner communication.')} />
            </Grid>
            <Grid item>
              <CheckboxControl
                id={makeId('isResetCounterAnnually')}
                name="isResetCounterAnnually"
                error={errors.documentSettings?.isResetCounterAnnually}
                label={t.translate('Reset counter to 1 upon year change')}
                checked={!!values.documentSettings[0]?.isResetCounterAnnually}
                onChange={() => toggleResetCounterAnnually(values.documentSettings)}
                disabled={!isEditMode}
              />
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell name="documentType">{t.translate('Document type')}</TableCell>
                      <TableCell name="prefix">{t.translate('Prefix')}</TableCell>
                      <TableCell name="includeYear">{t.translate('Include year')}</TableCell>
                      <TableCell name="separator">{t.translate('Separator')}</TableCell>
                      <TableCell name="numberLength">{t.translate('Number length')}</TableCell>
                      <TableCell name="counter">{t.translate('Counter')}</TableCell>
                      <TableCell name="preview">{t.translate('Preview')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.documentSettings.map((documentSetting, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {t.translate(`${documentSetting.voucherType}`)}
                        </TableCell>
                        <TableCell>
                          <InputControl
                            id={makeId('documentSetting.prefix')}
                            name={`documentSettings[${index}].prefix`}
                            error={errors.documentSetting?.prefix}
                            value={values.documentSettings[index].prefix}
                            onChange={handleChange}
                            disabled={!isEditMode}
                            className={classes.cellInput}
                          />
                        </TableCell>
                        <TableCell>
                          <Select
                            id={makeId('documentSetting.yearFormat')}
                            name={`documentSettings[${index}].yearFormat`}
                            error={errors.documentSetting?.yearFormat}
                            value={values.documentSettings[index].yearFormat}
                            onChange={handleChange}
                            disabled={!isEditMode}
                            className={classes.cellInput}
                          >
                            {formatYearOptions.map((option) => (
                              <MenuItem key={option} value={option}>{`${t.translate(option.toLowerCase())}`}</MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell align="center">
                          <InputControl
                            id={makeId('documentSetting.separator')}
                            name={`documentSettings[${index}].separator`}
                            error={errors.documentSetting?.separator}
                            value={values.documentSettings[index].separator}
                            onChange={handleChange}
                            disabled={!isEditMode}
                            className={classes.cellInput}
                          />
                        </TableCell>
                        <TableCell>
                          <InputControl
                            type="number"
                            id={makeId('documentSetting.numberLength')}
                            name={`documentSettings[${index}].numberLength`}
                            error={errors.documentSetting?.numberLength}
                            value={values.documentSettings[index].numberLength}
                            onChange={handleChange}
                            disabled={!isEditMode}
                            className={classes.cellInput}
                          />
                        </TableCell>
                        <TableCell>
                          <InputControl
                            type="number"
                            id={makeId('documentSetting.counter')}
                            name={`documentSettings[${index}].counter`}
                            error={errors.documentSetting?.counter}
                            value={values.documentSettings[index].counter}
                            onChange={handleChange}
                            disabled={!isEditMode || !!companyId}
                            className={classes.cellInput}
                          />
                        </TableCell>
                        <TableCell>
                          {getPreview(values.documentSettings[index])}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};
