import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import {
  useFilters,
  useRoute,
} from 'hooks';

import { makeStyles } from 'tss-react/mui';
import {
  AlzuraSpriteIcon,
  TextFilterCell,
  ActionButton,
  TableCell,
  TableRow,
  ListFilterCell,
} from 'components';

const useStyles = makeStyles()(theme => ({
  alignRight: {
    textAlign: 'right',
  },
  row: {
    backgroundColor: theme.palette.backgrounds.secondary,
    '& .MuiTableCell-root': {
      top: theme.spacing(8.125),
    },
    '& input': {
      paddingLeft: theme.spacing(1),
      backgroundColor: theme.palette.backgrounds.grey[250],
    },
  },
  idCell: {
    '&.MuiTableCell-root.MuiTableCell-head': {
      paddingLeft: theme.spacing(1.5),
    },
  },
  button: {
    color: theme.palette.primary.l900,
  },
}));

export const FiltersRow = ({
  productTypes,
  onFiltersEnable,
  visible,
  ...props
}) => {
  const route = useRoute();
  const t = useTranslator();
  const { classes } = useStyles();

  const [isActiveFilter, setIsActiveFilter] = useState(false);

  const filters = useFilters({
    typeId: v => v || '',
    alzuraId: v => v || '',
    manufacturerNumber: v => v || '',
    manufacturerName: v => v || '',
    reserved: v => v || '',
    stock: v => v || '',
    name: v => v || '',
    ean: v => v || '',
    id: v => v || '',
  }, {
    flags: ['activeOnMarketplaceDe'],
  });

  useEffect(() => {
    if (filters.isAvailable()) {
      onFiltersEnable();
    }
  }, [filters, onFiltersEnable]);

  useEffect(() => {
    setIsActiveFilter(Object.keys(route.search).find(key => Object.keys(filters.props).includes(key)));
  }, [route.search, filters.props]);

  return visible && (
    <TableRow {...props} className={classes.row}>
      <TextFilterCell
        className={classes.idCell}
        {...filters.props.id}
      />
      <ListFilterCell
        {...filters.props.typeId}
        options={
          [{
            value: '',
            text: '-',
          }]
            .concat(
              (productTypes || [])
              .map(({ code, id }) => ({
                value: id,
                text: code,
              }))
            )
        }
      />
      <TextFilterCell {...filters.props.alzuraId} />
      <TextFilterCell {...filters.props.manufacturerNumber} />
      <TextFilterCell {...filters.props.manufacturerName} />
      <TextFilterCell {...filters.props.name} />
      <TextFilterCell {...filters.props.ean} />
      <TextFilterCell align="right" className={classes.alignRight} {...filters.props.stock} />
      <TableCell align="right">
        <ActionButton
          tooltip={t.translate('Reset filters')}
          icon={<AlzuraSpriteIcon id="reset" />}
          onClick={filters.reset}
          className={classes.button}
          disabled={!isActiveFilter}
        />
      </TableCell>
    </TableRow>
  );
};

FiltersRow.propTypes = {
  onFiltersEnable: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};
