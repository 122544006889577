import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './NextPackageItem.jsx';
import { useTranslator } from 'i18n';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { Picture } from 'components';

const defaultAdditionalImage = (additionalImages = []) => (
  additionalImages?.find(image => image.isDefault)
);

const PickupShetProductsHeaderPictures = ({ pickupSheetProducts, classes }) => {
  return pickupSheetProducts.map((pickupSheetProduct, index) => (
    <Picture
      key={index}
      className={classes.photoHeader}
      image={defaultAdditionalImage(pickupSheetProduct.product.additionalImages) || pickupSheetProduct.product.photo || pickupSheetProduct.product.imageUrl}
    />
  ));
};

export const NextPackageItem = ({
  onPackPickupSheet,
  onProductDecreaseScanned,
  canEditPickupSheet,
  canPackPickupSheet,
  pickupSheet,
}) => {
  const { classes } = useStyles();
  const t = useTranslator();
  const [expanded, setExpanded] = useState(false);
  const filteredPickupSheetProducts = pickupSheet.pickupSheetProducts?.filter(pickupSheetProduct => pickupSheetProduct.markedQuantity !== pickupSheetProduct.packedQuantity);

  return (
    <Grid item className={classes.item}>
      <Accordion className={classes.accordion} expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item container alignItems="center">
            <Typography variant="body2" className={classes.headerLabel}>{t.translate('Package')}</Typography>
            {!expanded && (
              <PickupShetProductsHeaderPictures pickupSheetProducts={filteredPickupSheetProducts} classes={classes} />
            )}
          </Grid>
        </AccordionSummary>
        {filteredPickupSheetProducts.map((pickupSheetProduct, index) => (
          <React.Fragment key={index}>
            <AccordionDetails>
              <Grid item container direction="column">
                <Grid container alignItems="center">
                  <Picture
                    className={classes.photo}
                    image={defaultAdditionalImage(pickupSheetProduct.product.additionalImages) || pickupSheetProduct.product.photo || pickupSheetProduct.product.imageUrl}
                  />
                  <Grid container direction="column" className={classes.packageProductLabel}>
                    <Typography variant="body2" className={classes.bold}>{pickupSheetProduct.product.details[0]?.name || '—'}</Typography>
                    <Box>
                      <Typography variant="body2" component="span" className={classes.bold}>EAN:&nbsp;</Typography>
                      <Typography variant="body2" component="span" className={classes.bold}>{pickupSheetProduct.product.ean || '—'}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" component="span" className={classes.bold}>Supplier ID:&nbsp;</Typography>
                      <Typography variant="body2" component="span" className={classes.bold}>{pickupSheetProduct.supplierId || '—'}</Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Typography variant="body2" className={classes.bold}>{t.translate('Quantity')}</Typography>
                  {canEditPickupSheet
                    && (pickupSheetProduct.markedQuantity - pickupSheetProduct.packedQuantity) > 0
                    ? (
                      <Tooltip title={t.translate('Remove scanned product from package.')}>
                        <Button
                          className={classes.removeButton}
                          endIcon={<RemoveCircleIcon />}
                          onClick={() => onProductDecreaseScanned(pickupSheetProduct.id)}
                        >
                          {pickupSheetProduct.markedQuantity - pickupSheetProduct.packedQuantity}
                        </Button>
                      </Tooltip>
                    ) : (
                      pickupSheetProduct.markedQuantity
                      && (pickupSheetProduct.packedQuantity || pickupSheetProduct.packedQuantity === 0)
                      && pickupSheetProduct.markedQuantity - pickupSheetProduct.packedQuantity
                    )}
                </Grid>

                <Grid container justifyContent="space-between">
                  <Typography variant="body2" className={classes.bold}>{t.translate('Weight')}</Typography>
                  {`${(pickupSheetProduct.weight * (pickupSheetProduct.markedQuantity - pickupSheetProduct.packedQuantity)).toFixed(2) || 0} kg`}
                </Grid>
              </Grid>
            </AccordionDetails>
            <Divider />
          </React.Fragment>
        ))}
      </Accordion>
      {canPackPickupSheet && (
        <Paper className={classes.paper}>
          <Grid container direction="column">
            {!filteredPickupSheetProducts.length
              ? <Typography variant="body2">{t.translate('Scan a product to add it to the package')}</Typography>
              : (
                <Grid item align="right">
                  <Button
                    aria-label={t.translate(pickupSheet.pickupSheetPackages?.length ? 'Finish package' : 'Next package')}
                    className={classes.button}
                    onClick={onPackPickupSheet}
                  >
                    {t.translate(pickupSheet.pickupSheetPackages?.length ? 'Finish package' : 'Next package')}
                  </Button>
                </Grid>
              )}
          </Grid>
        </Paper>
      )}
    </Grid>
  );
};

NextPackageItem.propTypes = {
  canEditPickupSheet: PropTypes.bool.isRequired,
  canPackPickupSheet: PropTypes.bool.isRequired,
  onPackPickupSheet: PropTypes.func.isRequired,
  onProductDecreaseScanned: PropTypes.func.isRequired,
  pickupSheet: PropTypes.object.isRequired,
};
