import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { styleMobileTableItem } from 'theme/mixins/table';

import {
  CardFeature,
  BooleanIcon,
  PasswordText,
  ItemHeadline,
} from 'components';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  paperSmall: {
    height: '100%',
    padding: theme.spacing(1, 0),
  },
  paper: {
    padding: theme.spacing(1, 0),
  },
  details: {
    padding: theme.spacing(0.5, 2),
    '& button': {
      padding: 0,
      marginLeft: theme.spacing(2),
      color: theme.palette.primary.main,
    },
  },
  tokenValue: {
    fontWeight: 500,
    wordBreak: 'break-all',
  },
  iconValue: {
    marginTop: theme.spacing(-.25),
    marginBottom: theme.spacing(-1),
  },
  featureLabel: {
    fontWeight: 500,
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  password: {
    alignItems: 'center',
    display: 'flex',
    '& button': {
      padding: 0,
      marginLeft: theme.spacing(2),
      color: theme.palette.primary.main,
    },
  },
}));

export const RetailerCard = ({ retailer }) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <Grid container spacing={3} style={{ flexWrap: 'wrap' }}>
      <Grid item xs={12} md={6} className={classes.root}>
        <ItemHeadline text={t.translate('Retailer Status & Details')} />
        <Paper className={classes.paperSmall}>
          <Grid container direction="column">
            <CardFeature
              label={t.translate('Active')}
              value={<BooleanIcon truthy={retailer.active} />}
              classes={{ value: classes.iconValue, label: classes.featureLabel }}
              omitColon
              justifyContent="space-between"
              className={classes.details}
            />
            <CardFeature
              label={t.translate('Account number')}
              value={retailer.accountNumber}
              omitColon
              justifyContent="space-between"
              className={classes.details}
              classes={{ label: classes.featureLabel }}
            />
            <CardFeature
              label={t.translate('Name')}
              value={retailer.name}
              omitColon
              justifyContent="space-between"
              className={classes.details}
              classes={{ label: classes.featureLabel }}
            />
            <CardFeature
              label={t.translate('Country code')}
              value={retailer.country?.toUpperCase()}
              omitColon
              justifyContent="space-between"
              className={classes.details}
              classes={{ label: classes.featureLabel }}
            />
            <Divider className={classes.divider} />

            <Grid className={classes.details} justifyContent="space-between" item container>
              <Grid item>
                <Typography variant="body2" className={classes.featureLabel}>{t.translate('Address')}</Typography>
              </Grid>
              <Grid item align="right">
                {retailer.address?.name && (
                  <Typography variant="body2">
                    {retailer.address?.name}&nbsp;{retailer.address?.additionalName || ''}
                  </Typography>
                )}
                <Typography variant="body2">
                  {retailer.address?.street}&nbsp;{retailer.address?.house}
                </Typography>
                <Typography variant="body2">
                  {retailer.address?.zip}&nbsp;{retailer.address?.city}
                </Typography>
                <Typography variant="body2">{retailer.address?.country}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <ItemHeadline text={t.translate('Additional Retailer Details')} />
        <Paper className={classes.paper}>
          <Grid container direction="column">
            <Grid item container alignItems="flex-start" justifyContent="space-between" className={classes.details}>
              <Grid>
                <Typography variant="body2" className={classes.featureLabel}>{t.translate('API B2B Token')}&nbsp;</Typography>
              </Grid>
              <PasswordText className={classes.tokenValue}>
                {retailer.b2bToken || '—'}
              </PasswordText>
            </Grid>
            <Grid item container alignItems="flex-start" justifyContent="space-between" className={classes.details}>
              <Grid>
                <Typography variant="body2" className={classes.featureLabel}>{t.translate('AutoOrder Token')}&nbsp;</Typography>
              </Grid>
              <PasswordText className={classes.tokenValue}>
                {retailer.autoOrderToken || '—'}
              </PasswordText>
            </Grid>
            <Grid item container alignItems="center" justifyContent="space-between" className={classes.details}>
              <Grid>
                <Typography variant="body2" className={classes.featureLabel}>{t.translate('Password')}&nbsp;</Typography>
              </Grid>
              <PasswordText gridProps={{ className: classes.password }}>
                {retailer.password || '—'}
              </PasswordText>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

RetailerCard.propTypes = {
  retailer: PropTypes.shape({
    address: PropTypes.shape({
      country: PropTypes.string.isRequired,
      street: PropTypes.string.isRequired,
      house: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
      name: PropTypes.string,
      additionalName: PropTypes.string,
    }),
    b2bToken: PropTypes.string,
    autoOrderToken: PropTypes.string,
    country: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
};
