import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import { useTranslator } from 'i18n';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import {
  BusyButton,
  PriceControl,
} from 'components';

import { modifyOrder } from 'api/orders';
import { extractApiErrors } from 'utils/api';
import toaster from 'services/toaster';

import TableContainer from '@mui/material/TableContainer';
import { useStyles } from './AdjustOrderDialog.jsx';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';

import { AdjustPositionItem } from './AdjustPositionItem/AdjustPositionItem';
import { AdjustPositionRow } from './AdjustPositionRow/AdjustPositionRow';
import { SupplierHandlingTypes } from 'consts';

const makeId = postfix => `adjust-order-dialog--${postfix}`;

export const AdjustOrderDialog = ({
  onClose,
  onSave,
  order,
  isMobileView,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const [initialValues, setInitialValues] = useState({
    positions: order.positions.reduce((acc, { id }) => ({
      ...acc,
      [id]: {
        addedAmount: 0,
    } }), {}),
    netHandlingFee: t.formatPrice(0),
    netShippingPrice: t.formatPrice(0),
    netAlternativeShippingAddressPrice: t.formatPrice(0),
    netPaymentPrice: t.formatPrice(0),
  });

  const onSubmit = async (values, { setErrors }) => {
    try {
      const payload = (({ ftp, ...values }) => ({
        ...values,
        netHandlingFee: t.parsePrice(values.netHandlingFee),
        netShippingPrice: t.parsePrice(values.netShippingPrice),
        netAlternativeShippingAddressPrice: t.parsePrice(values.netAlternativeShippingAddressPrice),
        netPaymentPrice: t.parsePrice(values.netPaymentPrice),
      }))(values);

      await modifyOrder(order.id, payload);
      toaster.success(t.translate('Order has been updated successfully.'));

      onSave();
      onClose();
    } catch (err) {
      setErrors(extractApiErrors(err));
    }
  };

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    resetForm,
    values,
    errors,
  } = useFormik({
    enableReinitialize: true,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    resetForm();
    if (order) {
      setInitialValues(initialValues => ({
        ...initialValues,
        netHandlingFee: t.formatPrice(Number(order.netHandlingFee)),
        netShippingPrice: t.formatPrice(Number(order.netShippingPrice)),
        netAlternativeShippingAddressPrice: t.formatPrice(Number(order.netAlternativeShippingAddressPrice)),
        netPaymentPrice: t.formatPrice(Number(order.netPaymentPrice)),
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <Dialog
      {...props}
      scroll="body"
      fullWidth
      fullScreen={isMobileView}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
      className={classes.root}
    >
      <DialogTitle id="max-width-dialog-title">{t.translate('Adjust order')}: {order.number}</DialogTitle>
      <DialogContent >
        {order.supplier.handlingType === SupplierHandlingTypes.DEFAULT &&  (
          <Grid item container direction='column'>
            {isMobileView ? (
              <>
                {order.positions.map((position, index) => (
                  <AdjustPositionItem
                    key={index}
                    position={position}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    values={values}
                    errors={errors}
                  />
                ))}
              </>
            ) : (
              <TableContainer>
                <Table className={classes.root}>
                  <TableHead>
                    <TableRow>
                      <TableCell name="productId">{t.translate('Product-ID')}</TableCell>
                      <TableCell name="productName">{t.translate('Product name')}</TableCell>
                      <TableCell name="ean">EAN</TableCell>
                      <TableCell name="productEan" align="center">{t.translate('Ordered')}</TableCell>
                      <TableCell name="quantity" align="center">{t.translate('Add stock')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order.positions.map((position, index) => (
                      <React.Fragment key={index}>
                        <AdjustPositionRow
                          position={position}
                          setFieldValue={setFieldValue}
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                        />
                        <TableRow className={classes.emptyRow} />
                      </React.Fragment>
                    ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              )}
          </Grid>
        )}

        <Grid container spacing={4} direction={isMobileView ? 'column' : 'row'}>
          <Grid item md={6} xs={12}>
            <PriceControl
              id={makeId('hanlding-fee')}
              name="netHandlingFee"
              error={errors.netHandlingFee}
              label={t.translate('Handling Fee')}
              value={values.netHandlingFee}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <PriceControl
              id={makeId('net-shipping-price')}
              name="netShippingPrice"
              error={errors.netShippingPrice}
              label={t.translate('Shipping costs')}
              value={values.netShippingPrice}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4} direction={isMobileView ? 'column' : 'row'}>
          <Grid item md={6} xs={12}>
            <PriceControl
              id={makeId('net-alternative-shipping-address-price')}
              name="netAlternativeShippingAddressPrice"
              error={errors.netAlternativeShippingAddressPrice}
              label={t.translate('Alternative shipping address price')}
              value={values.netAlternativeShippingAddressPrice}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <PriceControl
              id={makeId('net-payment-price')}
              name="netPaymentPrice"
              error={errors.netPaymentPrice}
              label={t.translate('Payment costs')}
              value={values.netPaymentPrice}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
        <BusyButton color="primary" submit onClick={handleSubmit}>{t.translate('Save')}</BusyButton>
      </DialogActions>
    </Dialog>
  );
};

AdjustOrderDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  order: PropTypes.shape({
    netHandlingFee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    netShippingPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    netAlternativeShippingAddressPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    netPaymentPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};
