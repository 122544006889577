import React, { useState } from 'react';

import { useTranslator } from 'i18n';
import { useRoute } from 'hooks/route';
import {
  AppHeaderSecondaryToolbar,
  AppHeaderPrimaryToolbar,
  AppHeaderMenuButton,
  AppHeaderRightBox,
  QrCodeScanButton,
  HandheldListener,
  AppHeaderTitle,
  AppHeader,
} from 'components';

import {
  PickupSheetsFilter,
  SearchInput,
} from '../../common';

import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';

export const Header = () => {
  const t = useTranslator();
  const route = useRoute();

  const [visibleSearchInput, setVisibleSearchInput] = useState(false);

  const openPickupSheet = pickupSheetId => route.push({
    pathname: `/pickup-sheets/${pickupSheetId}`,
    search: { list: route.search },
  });

  return (
    <AppHeader>
      <AppHeaderPrimaryToolbar>
        <AppHeaderMenuButton edge="start" />
        <AppHeaderTitle>{t.translate('Pickup sheets')}</AppHeaderTitle>
        <AppHeaderRightBox mr={-1.5}>
          <Grid container spacing={1}>
            <Grid item>
              <QrCodeScanButton onComplete={openPickupSheet} />
              <HandheldListener onScan={openPickupSheet} />
            </Grid>
            <Grid item>
              <PickupSheetsFilter />
            </Grid>
            <Grid item>
              <IconButton
                color="inherit"
                onClick={() => setVisibleSearchInput(!visibleSearchInput)}
                aria-label="search input"
                size="large">
                <SearchIcon />
              </IconButton>
            </Grid>
          </Grid>
        </AppHeaderRightBox>
      </AppHeaderPrimaryToolbar>
      {visibleSearchInput && (
        <AppHeaderSecondaryToolbar pl={1} pr={1}>
          <SearchInput fullWidth />
        </AppHeaderSecondaryToolbar>
      )}
    </AppHeader>
  );
};
