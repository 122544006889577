import { makeStyles } from 'tss-react/mui';
import { styleDefaultFormDialog } from 'theme/mixins/dialog';

export const useStyles = makeStyles()(theme => styleDefaultFormDialog(theme, {
  table: {
    borderCollapse: 'separate',
    '& .MuiDialog-paper': {
      maxWidth: theme.spacing(86),
    },
    '& th': {
      borderBottom: 'none',
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiDialogContent-root': {
        padding: theme.spacing(1),
      },
    },
  },
  returnRow: {
    borderRadius: theme.spacing(.5),
    '& td': {
      paddingRight: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.grey['250']}`,
      '&:first-of-type': {
        borderLeft: `1px solid ${theme.palette.grey['250']}`,
        borderTopLeftRadius: theme.spacing(.5),
        borderBottomLeftRadius: theme.spacing(.5),
      },
      '&:last-child': {
        borderRight: `1px solid ${theme.palette.grey['250']}`,
        borderTopRightRadius: theme.spacing(.5),
        borderBottomRightRadius: theme.spacing(.5),
      },
    },
  },
  emptyRow: {
    height: theme.spacing(1),
  },
  returnButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  totalFeesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  totalFeesItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginBottom: 10,
  },
}));
