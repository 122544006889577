import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Image from 'mui-image';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { makeSourceLink } from 'services/files';

export const Picture = ({ image, style, imageStyle, ...props }) => {
  const theme = useTheme();

  const link = image
    ? image.filePath
      ? makeSourceLink(image.filePath)
      : image instanceof File
        ? URL.createObjectURL(image)
        : image
    : '#';

  return (
    <Box {...props}>
      <Image
        showLoading={!image && <CircularProgress size={16}/>}
        src={link}
        bgColor={theme.palette.common.white}
        wrapperStyle={{
          borderRadius: theme.shape.borderRadius,
          overflow: 'hidden',
          ...style,
        }}
        style={{
          objectFit: 'contain',
          ...imageStyle,
        }}
      />
    </Box>
  );
};

Picture.propTypes = {
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(File),
    PropTypes.shape({
      filePath: PropTypes.string.isRequired,
    }),
  ]),
};
