import React from 'react';

import { useTranslator } from 'i18n';

import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { getStorageName } from 'utils/storages';

export const CollapsedRow = ({ products, onClick, selectedStorageProducts }) => {
  const t = useTranslator();

  return (
    <Paper style={{ width: '100%', margin: 10 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell/>
              <TableCell>{t.translate('Storage')}</TableCell>
              <TableCell>{t.translate('Supplier')}</TableCell>
              <TableCell>{t.translate('Reserved')}</TableCell>
              <TableCell>{t.translate('Quantity')}</TableCell>
              <TableCell>{t.translate('DOT')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow key={product.id}>
                <TableCell>
                  <Checkbox
                    checked={selectedStorageProducts.find(p => p.id === product.id)}
                    color="primary"
                    onChange={() => onClick(product)}
                  />
                </TableCell>
                <TableCell>{getStorageName(product.storage)}</TableCell>
                <TableCell>{product.supplier.name}</TableCell>
                <TableCell>{product.reserved}</TableCell>
                <TableCell>{product.stock}</TableCell>
                <TableCell>{t.formatManufacturingDate(product.manufacturingDate)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

CollapsedRow.propTypes = {};
