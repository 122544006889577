import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useTranslator } from 'i18n';

import Typography from '@mui/material/Typography';
import { useStyles } from './ConfirmingPositionItem.jsx';
import Grid from '@mui/material/Grid';

export const ConfirmingPositionItem = ({
  setCancelAllQuantity,
  cancelledAmount,
  handleChange,
  position,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <>
      <Grid container className={classes.root} direction="column">
        <Grid container className={clsx(classes.containerItem, classes.expanded)} direction="column">
          <Typography variant="body2" className={classes.bold}>{position.product.details[0]?.name || '—'}</Typography>
          <Grid container>
            <Typography variant="body2" className={classes.bold}>EAN:&nbsp;</Typography>
            <Typography variant="body2">{position.product.ean || '—'}</Typography>
          </Grid>

          <Grid container >
            <Grid container direction="column">
              <Grid container>
                <Typography variant="body2" className={classes.bold}>{t.translate('Product-ID')}:&nbsp;</Typography>
                <Typography variant="body2">{position.product.id}</Typography>
              </Grid>
              <Grid container>
                <Grid container justifyContent="space-between">
                  <Grid item container>
                    <Typography variant="body2" className={classes.bold}>{t.translate('Ordered')}:&nbsp;</Typography>
                    <Typography variant="body2">{position.quantity}</Typography>
                  </Grid>
                  <Grid item container justifyContent="flex-end">
                    <Typography variant="body2" className={classes.bold}>{t.translate('Cancel')}:&nbsp;</Typography>
                    <Typography variant="body2">{cancelledAmount || 0}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

ConfirmingPositionItem.propTypes = {
  cancelledAmount: PropTypes.number,
  position: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    product: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
};
