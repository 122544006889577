import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { useTranslator } from 'i18n';
import { useStyles } from './AppNavigation.jsx';
import { useScreen } from 'hooks';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';

import { AppVersion } from 'components';
import { MenuItem } from './MenuItem/MenuItem';
import { Info } from './Info/Info';
import { ToggleButton } from './ToggleButton/ToggleButton';

import { menuItems } from 'config/navigation';

export const AppNavigation = ({
  shouldShowMenuItem,
  onClose,
  isMobileView,
  visible,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const history = useHistory();
  const screen = useScreen();

  const onMenuItemClick = (path, action) => () => {
    path
      ? history.push(path)
      : action();
    if (isMobileView) {
      onClose();
    }
  };

  const isThumbnail = !isMobileView && !visible;
  const menuItemClasses = {
    root: classes.menuItem,
    icon: classes.menuItemIcon,
    text: classes.menuItemText,
  };

  return (
    <Drawer
      variant={isMobileView ? 'temporary' : 'permanent'}
      className={clsx(classes.drawer, !isMobileView && {
        [classes.thumbnail]: !visible,
        [classes.full]: visible,
      })}
      classes={{
        paper: clsx(classes.drawerPaper, !isMobileView && {
          [classes.thumbnail]: !visible,
          [classes.full]: visible,
        }),
      }}
      open={visible}
      onClose={onClose}
      onMouseDown={(e) => {
        if (e.button === 1) {
          e.preventDefault();
        }
      }}
    >
      <Info isThumbnail={isThumbnail} />
      <Divider className={classes.divider} />
      <List className={classes.menu}>
        {menuItems.map(
          ({ text, path, action, name, divider, customHandler, ...item }) => isThumbnail ? (
            shouldShowMenuItem(name) && (
              <React.Fragment key={name}>
                <Tooltip placement="right" title={t.translate(text)} classes={{ tooltipPlacementRight: classes.tooltipRight }}>
                  <MenuItem
                    {...item}
                    text={t.translate(text)}
                    classes={menuItemClasses}
                    selected={path && history.location.pathname.includes(path)}
                    onClick={customHandler || onMenuItemClick(path, action)}
                    onAuxClick={(e) => {
                      if (e.button === 1) window.open(path);
                    }}
                    isThumbnail={isThumbnail}
                  />
                </Tooltip>
                {divider && <Divider key={text} />}
              </React.Fragment>
            )
          ) : (
            shouldShowMenuItem(name) && (
              <React.Fragment key={name}>
                <MenuItem
                  {...item}
                  text={t.translate(text)}
                  classes={menuItemClasses}
                  selected={path && history.location.pathname.includes(path)}
                  onClick={customHandler || onMenuItemClick(path, action)}
                  onAuxClick={(e) => {
                    if (e.button === 1) window.open(path);
                  }}
                  isThumbnail={isThumbnail}
                />
                {divider && <Divider key={text} />}
              </React.Fragment>
            )
          )
        )}
      </List>
      <Box flexGrow={1} width={1} />
      {!screen.isMobile && (
        <Grid className={classes.navigationFooter}>
          {!isThumbnail && <AppVersion pb={1} width={304} />}
          <ToggleButton isThumbnail={isThumbnail} />
        </Grid>
      )}
      {!isThumbnail && screen.isMobile && <AppVersion pb={1} width={304}/>}
    </Drawer>
  );
};

AppNavigation.propTypes = {
  shouldShowMenuItem: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isMobileView: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
};
