import React, {
  useEffect,
  useState,
} from 'react';
import { useFilters } from 'hooks';
import { useTranslator } from 'i18n';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import {
  TextFilterFormControl,
  ListFilterFormControl,
  FlagFilterFormControl,
} from 'components';
import { styleBorderedFormDialog } from 'theme/mixins/dialog';
import { fetchProductTypes } from 'api/products';

const useStyles = makeStyles()(styleBorderedFormDialog);
const makeId = postfix => `filtering-dialog--${postfix}`;

export const FilteringDialog = ({
  onClose,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const [productTypes, setProductTypes] = useState([]);

  const filters = useFilters({
    activeOnMarketplaceDe: v => v === '1',
    manufacturerNumber: v => v || '',
    manufacturerName: v => v || '',
    superdealPrice: v => v || '',
    generalPrice: v => v || '',
    medianPrice: v => v || '',
    salesPrice: v => v || '',
    stock: v => v || '',
    name: v => v || '',
    ean: v => v || '',
    alzuraId: v => v || '',
    typeId: v => v || '',
    id: v => v || '',
  }, {
    manual: true,
    flags: ['activeOnMarketplaceDe'],
  });

  useEffect(() => {
    if (props.open && !productTypes.length) {
      (async () => {
        try {
          const productTypes = await fetchProductTypes();
          setProductTypes(
            productTypes.data.map(({ code, id }) => ({
              value: id,
              text: code,
            }))
          );
        } catch (err) {}
      })();
    }
  }, [props.open, productTypes.length]);

  const apply = () => {
    filters.apply();
    onClose();
  };

  return (
    <Dialog scroll="paper" {...props} className={classes.root} onClose={onClose}>
      <DialogTitle className={classes.title}>{t.translate('Filter products')}</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <TextFilterFormControl
              label={t.translate('Product ID')}
              id={makeId('id')}
              {...filters.props.id}
            />
          </Grid>
          <Grid item>
            <TextFilterFormControl
              label={t.translate('Manufacturer number')}
              id={makeId('manufacturer-number')}
              {...filters.props.manufacturerNumber}
            />
          </Grid>
          <Grid item>
            <TextFilterFormControl
              label={t.translate('Manufacturer name')}
              id={makeId('manufacturer-name')}
              {...filters.props.manufacturerName}
            />
          </Grid>
          <Grid item>
            <TextFilterFormControl
              label="Alzura ID"
              id={makeId('alzura-id')}
              {...filters.props.alzuraId}
            />
          </Grid>
          <Grid item>
            <TextFilterFormControl
              label={t.translate('Product name')}
              id={makeId('name')}
              {...filters.props.name}
            />
          </Grid>
          <Grid item>
            <TextFilterFormControl
              label={t.translate('Product EAN code')}
              id={makeId('ean')}
              {...filters.props.ean}
            />
          </Grid>
          <Grid item>
            <ListFilterFormControl
              emptyOptionText={t.translate('All')}
              displayEmpty
              options={productTypes}
              label={t.translate('Product type')}
              id={makeId('type-id')}
              {...filters.props.typeId}
            />
          </Grid>
          <Grid item>
            <TextFilterFormControl
              label={t.translate('Superdeal price')}
              id={makeId('superdeal-price')}
              {...filters.props.superdealPrice}
            />
          </Grid>
          <Grid item>
            <TextFilterFormControl
              label={t.translate('General price')}
              id={makeId('general-price')}
              {...filters.props.generalPrice}
            />
          </Grid>
          <Grid item>
            <TextFilterFormControl
              label={t.translate('Median price')}
              id={makeId('median-price')}
              {...filters.props.medianPrice}
            />
          </Grid>
          <Grid item>
            <TextFilterFormControl
              label={t.translate('Sales price')}
              id={makeId('sales-price')}
              {...filters.props.salesPrice}
            />
          </Grid>
          <Grid item>
            <FlagFilterFormControl
              label={t.translate('Active in marketplace DE')}
              id={makeId('active-on-marketplace-de')}
              {...filters.props.activeOnMarketplaceDe}
            />
          </Grid>
          <Grid item>
            <TextFilterFormControl
              label={t.translate('Stock')}
              id={makeId('stock')}
              {...filters.props.stock}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
        <Button color="primary" onClick={filters.reset}>{t.translate('Clear')}</Button>
        <Button color="primary" onClick={apply}>{t.translate('Apply')}</Button>
      </DialogActions>
    </Dialog>
  );
};
