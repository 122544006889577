import api from '.';
import { extractListParams } from 'utils/querying';

export const fetchAllCompanies = () => api.get('/companies');

export const fetchCompanies = params => api.get('/companies', { params: extractListParams(params) });

export const fetchCompany = companyId => api.get(`/companies/${companyId}`);

export const modifyCompany = (companyId, payload) => api.put(`/companies/${companyId}`, payload);

export const createCompany = payload => api.post('/companies', payload);

export const deleteCompany = companyId => api.delete(`/companies/${companyId}`);

export default {
  fetchAll: fetchAllCompanies,
  fetchMany: fetchCompanies,
  modifyOne: modifyCompany,
  createOne: createCompany,
  fetchOne: fetchCompany,
  deleteOne: deleteCompany,
};
