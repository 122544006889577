import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Grid from '@mui/material/Grid';

import { AlzuraSpriteIcon } from 'components';
import { PickupSheetStatuses } from 'consts';

const pusStatuses = {
  'open': 'assignmentCircle',
  'packed': 'assignmentSuccess',
  'labelled': 'assignmentRepeat',
  'label-fetched': 'assignmentRepeat',
};

const selectStatusIcon = (status) => {
  if (status === 'closed') {
    return <CheckCircleOutlineIcon/>;
  } else {
    return <AlzuraSpriteIcon id={pusStatuses[status]}/>;
  }
};

const useStyles = makeStyles()((theme) => ({
  grid: {
    alignItems: 'center',
    background: theme.palette.backgrounds.grey['255'],
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.primary.l65}`,
    justifyContent: 'space-around',
    height: theme.spacing(4),
    marginRight: theme.spacing(2),
    minWidth: '140px',
    padding: theme.spacing(0, 1),
    '& p': {
      fontSize: '0.875rem',
    },
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(5),
      minWidth: '90px',
    },
  },
  icon: {
    width: 'initial',
    '& p': {
      margin: theme.spacing(0, 1),
    },
  },
}));

export const StatusBar = ({ status, isMobile, ...props }) => {
  const t = useTranslator();
  const { classes } = useStyles();

  if (!status) {
    return null;
  }

  return (
    <Grid container className={classes.grid} {...props}>
      {!isMobile && <Typography variant="body1">{t.translate('Status')}</Typography>}
      <Grid container item alignItems="center" className={classes.icon}>
        <Typography variant="body1">{t.translate(PickupSheetStatuses[status])}</Typography>
        {selectStatusIcon(status)}
      </Grid>
    </Grid>
  );
};

StatusBar.propTypes = {
  status: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
};
