import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  inputItem: {
    alignItems: 'center',
    display: 'flex',
  },
  buttonItem: {
    '& .MuiIconButton-root': {
      color: theme.palette.common.white,
    },
  },
  buttonItemFullWidth: {
    '&.MuiGrid-item': {
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
}));
