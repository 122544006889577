import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useStyles } from './PurchaseProductsSelect.jsx';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';

import { ProductsTable } from './ProductsTable/ProductsTable';
import Button from '@mui/material/Button';
import { useDialog, useScreen } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectActionPermission } from 'store/selectors/account';
import { fetchProductTypes } from 'api/products';
import { fetchSuppliersForFilter } from 'services/suppliers';
import { ProductDialog } from 'dialogs';

export const PurchaseProductsSelect = ({
  selectedProductIds,
  onClose,
  onAddPurchasePosition,
  onRemovePurchasePosition,
  deliveredProductIds,
}) => {
  const screen = useScreen();
  const { classes } = useStyles();
  const t = useTranslator();
  const creatingProductDialog = useDialog();
  const dispatch = useDispatch();
  const [productTypes, setTypes] = useState([]);

  const canUpdateFromMarketplace = useSelector(selectActionPermission('products', 'updateFromMarketplace'));

  useEffect(() => {
    const getProductTypes = async () => {
      try {
        const types = await fetchProductTypes();
        if (types) {
          setTypes(types.data);
        }
      } catch (err) {}
    };
    dispatch(fetchSuppliersForFilter());
    getProductTypes();
  }, [dispatch]);

  return <>
    {creatingProductDialog.visible && (
      <ProductDialog
        {...creatingProductDialog.props}
        isMobileView={screen.isMobile}
        onSave={() => {}}
        canUpdateFromMarketplace={canUpdateFromMarketplace}
        productTypes={productTypes}
        isCreateFromPurchase={true}
      />
    )}

    <Grid item container direction="row" alignItems="center">
      <IconButton
        color="inherit"
        aria-label="go back"
        className={classes.backButton}
        onClick={() => onClose()}
        size="large">
        <ArrowBackIcon/>
      </IconButton>
      <Typography variant="body1" className={classes.bold}>{t.translate('Add positions')}</Typography>
    </Grid>

    <Grid container spacing={2} justifyContent={'flex-end'}>
      <Grid item>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => creatingProductDialog.open()}
        >
          {t.translate('Create product')}
        </Button>
      </Grid>
    </Grid>

    <ProductsTable
      selectedProductIds={selectedProductIds}
      deliveredProductIds={deliveredProductIds}
      onSelectProduct={onAddPurchasePosition}
      onDeselectProduct={onRemovePurchasePosition}
    />
  </>;
};

PurchaseProductsSelect.propTypes = {
  selectedProductIds: PropTypes.array,
  onClose: PropTypes.func,
  onAddPurchasePosition: PropTypes.func,
  onRemovePurchasePosition: PropTypes.func,
  supplierId: PropTypes.number,
};
