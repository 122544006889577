import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslator } from 'i18n';
import {
  useTableSortableHeadCell,
  useList,
} from 'hooks';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TableContainer from '@mui/material/TableContainer';
import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';

import { FiltersRow } from './FiltersRow/FiltersRow';
import {
  Pagination,
  TableCell,
  TableRow,
  Table,
} from 'components';
import { wrapTableRowClick } from 'utils/html';

import tables from 'config/tables';

const useStyles = makeStyles()(theme => ({
  table: {
    '& th:first-of-type, & td:first-of-type': {
      paddingLeft: theme.spacing(3),
    },
    '& th:last-child, & td:last-child': {
      paddingRight: theme.spacing(3),
    },
    '& .MuiTableSortLabel-root': {
      width: '100%',
    },
  },
  text: {
    wordBreak: 'break-all',
  },
  button: {
    color: theme.palette.primary.l900,
  },
}));

export const ProductsTable = ({ productTypes }) => {
  const history = useHistory();
  const { classes } = useStyles();
  const list = useList(tables.products.products);
  const t = useTranslator();

  const totalEntities = useSelector(({ products }) => products.products.meta.totalEntities);
  const products = useSelector(({ products }) => products.products.data);

  const SortableCell = useTableSortableHeadCell({
    changeSorting: list.changeSorting,
    sorting: list.sorting,
  });

  const openProduct = product => history.push(`/products/${product.id}`);

  return (
    <Paper>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <SortableCell name="id">Product ID</SortableCell>
              <SortableCell name="typeId">{t.translate('Product type')}</SortableCell>
              <SortableCell name="alzuraId">{t.translate('ALZURA-ID')}</SortableCell>
              <SortableCell name="manufacturerNumber">{t.translate('Manufacturer no.')}</SortableCell>
              <SortableCell name="manufacturerName">{t.translate('Manufacturer')}</SortableCell>
              <SortableCell name="name">{t.translate('Product name')}</SortableCell>
              <SortableCell name="ean">EAN</SortableCell>
              <SortableCell name="stock" align="right" width={100}>{t.translate('Total stock')}</SortableCell>
              <TableCell width={100} align="right">
                {t.translate('Actions')}
              </TableCell>
            </TableRow>
            <FiltersRow
              productTypes={productTypes}
              visible={true}
              onFiltersEnable={() => true}
            />
          </TableHead>
          <TableBody>
            {products.map((product, index) => (
              <TableRow key={`product-${index}`} hover onClick={wrapTableRowClick(() => openProduct(product))}>
                <TableCell>{product.id}</TableCell>
                <TableCell>{product.type?.code || '—'}</TableCell>
                <TableCell>{product.alzuraId || '—'}</TableCell>
                <TableCell>{product.manufacturerNumber || '—'}</TableCell>
                <TableCell>{product.manufacturerName || '—'}</TableCell>
                <TableCell>{product.details[0]?.name || '—'}</TableCell>
                <TableCell>{product.ean || '—'}</TableCell>
                <TableCell align="right">{product.stock}</TableCell>
                <TableCell align="right">
                  <IconButton
                    variant="contained"
                    onClick={() => openProduct(product)}
                    aria-label="go to product"
                    size="large">
                    <ChevronRightIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination {...list.paginationProps} count={totalEntities} />
    </Paper>
  );
};

ProductsTable.propTypes = {
  productTypes: PropTypes.array,
};
