import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { didUpdate } from 'hooks/lifecycle';
import { makeStyles } from 'tss-react/mui';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAutocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles()({
  input: {
    '& > .MuiAutocomplete-endAdornment': {
      display: 'flex',
      position: 'static',
    },
  },
});

export const Autocomplete = ({
  getOptionSelected,
  getOptionLabel,
  fetchOptions,
  findOption,
  onComplete,
  onSelect,
  onChange,
  value,
  disabled = false,
  getOptionsOnLoad = false,
  ...props
}) => {
  const { classes } = useStyles();

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);

  const fetchData = () => {
    if (!getOptionsOnLoad) {
      if (!value.length || !open) {
        if (loading) {
          setLoading(false);
        }
        return;
      }
    }

    let active = true;
    setLoading(true);

    (async () => {
      try {
        const options = await fetchOptions();
        if (active) {
          setOptions(options);
          onComplete && onComplete(options.find(findOption));
        }
      } finally {
        if (active) {
          setLoading(false);
        }
      }
    })();

    return () => {
      active = false;
    };
  };

  didUpdate(() => fetchData(), [value]);
  didUpdate(() => value.length && options.length ? undefined : fetchData(), [open]);

  const changeValue = ({ target: { value } }) => {
    onChange(value);
    if (!value.trim() && onComplete) {
      onComplete(null);
    }
  };

  return (
    <MuiAutocomplete
      inputValue={value}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={onSelect}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      disabled={disabled}
      renderInput={textFieldProps => (
        <TextField
          {...textFieldProps}
          {...props}
          value={value}
          onChange={changeValue}
          helperText={null}
          InputProps={{
            ...textFieldProps.InputProps,
            className: classes.input,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
                {textFieldProps.InputProps.endAdornment}
                {props.InputProps?.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

Autocomplete.propTypes = {
  getOptionSelected: PropTypes.func,
  getOptionLabel: PropTypes.func.isRequired,
  fetchOptions: PropTypes.func.isRequired,
  findOption: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  getOptionsOnLoad: PropTypes.bool,
};

Autocomplete.defaultProps = {
  getOptionSelected: (option, value) => option.id === value.id,
};
