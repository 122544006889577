import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useStyles } from './UserItem.jsx';
import { useTranslator } from 'i18n';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import {
  ActionButton,
  CardFeature,
  BooleanIcon,
} from 'components';

import { humanizeDefaultView } from 'services/account';
import { humanizeLocale } from 'services/i18n/locale';

export const UserItem = ({
  onUserOpen,
  onAccountOpen,
  user,
  account,
  disabled = false,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <Grid item>
      <Paper className={clsx(disabled && classes.disabled, classes.paper)}>
        <Grid container direction="column" spacing={1}>
          <Grid item container className={classes.headline}>
            <Grid item xs>
              <Typography variant="h6">#{user.id}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" className={classes.administratorLabel}>{t.translate('Administrator')}</Typography>
              <BooleanIcon truthy={user.isAdmin} />
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs>
              <Grid container direction="column" className={classes.summaryGrid}>
                <CardFeature
                  label={t.translate('Name')}
                  value={user.name}
                />
                <CardFeature
                  label={t.translate('Email')}
                  value={user.email}
                />
                <CardFeature
                  label={t.translate('Default view')}
                  value={humanizeDefaultView(user.defaultView)}
                />
                <CardFeature
                  label={t.translate('Language')}
                  value={humanizeLocale(user.locale, { t })}
                />
                <Grid item xs className={classes.datetimeItem}>
                  <Typography variant="body2">{t.formatDate(user.createdAt)} {t.formatTime(user.createdAt)}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.buttonsItem}>
              {user.id === account.id ? (
                <ActionButton
                  tooltipPlacement="left"
                  aria-label={t.translate('Open user')}
                  tooltip={t.translate('verb:Open')}
                  icon={<AccountCircleIcon />}
                  onClick={onAccountOpen}
                />
              ) : (
                <ActionButton
                  tooltipPlacement="left"
                  aria-label={t.translate('Open user')}
                  tooltip={t.translate('verb:Open')}
                  icon={<InfoIcon />}
                  onClick={() => onUserOpen(user)}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

UserItem.propTypes = {
  onUserOpen: PropTypes.func.isRequired,
  onAccountOpen: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  user: PropTypes.shape({
    defaultView: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isActive: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
