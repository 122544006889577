import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { useStyles } from './style';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

export const DetailingStorageProductOperationDialog = ({
  operation,
  onClose,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <Dialog {...props}>
      <DialogTitle className={classes.title}>{t.translate('Comment')}</DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="body2">{operation.comment}</Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

DetailingStorageProductOperationDialog.propTypes = {
  operation: PropTypes.shape({
    comment: PropTypes.string.isRequired,
  }).isRequired,
};
