import { WAREHOUSES } from 'store/types';
import { warehouses as is } from 'store/initialState';

const setOverviewWarehouses = (state, { data, meta }) => ({
  ...state,
  overview: {
    ...state.overview,
    data,
    meta: {
      totalEntities: meta.totalEntities,
    },
  },
});

const setDetailsWarehouseShippingPartnerAvailability = (state, { shippingPartnerId, isEnabled }) => ({
  ...state,
  details: {
    ...state.details,
    warehouse: {
      ...state.details.warehouse,
      shippingPartners: state.details.warehouse.shippingPartners.map(
        shippingPartner => shippingPartner.id === shippingPartnerId
          ? { ...shippingPartner, isEnabled }
          : shippingPartner
      ),
    },
  },
});

const setDetailsWarehouse = (state, warehouse) => ({
  ...state,
  details: {
    ...state.details,
    warehouse,
  },
});

export default (state = is, { type, payload }) => {
  switch (type) {
    case WAREHOUSES.OVERVIEW.SET_WAREHOUSES:
      return setOverviewWarehouses(state, payload);
    case WAREHOUSES.DETAILS.SET_WAREHOUSE_SHIPPING_PARTNER_AVAILABILITY:
      return setDetailsWarehouseShippingPartnerAvailability(state, payload);
    case WAREHOUSES.DETAILS.SET_WAREHOUSE:
      return setDetailsWarehouse(state, payload);
    default:
      return state;
  }
};
