import api from '.';

export const fetchReplenishmentSuggestions = ({
  productId,
  alzuraId,
  manufacturerName,
  manufacturerNumber,
  ean,
  name,
  status,
  handlingType,
  searchQuery,
}) => api.get('/replenishment-suggestions', {
  params: {
    ...(productId && { productId }),
    ...(alzuraId && { alzuraId }),
    ...(manufacturerName && { manufacturerName }),
    ...(manufacturerNumber && { manufacturerNumber }),
    ...(ean && { ean }),
    ...(name && { name }),
    ...(status && { status }),
    ...(handlingType && { handlingType }),
    ...(searchQuery && { searchQuery }),
  },
});

export const fetchPausedReplenishmentSuggestions = () => api.get('/replenishment-suggestions;paused');

export const createPurchaseFromReplenishmentSuggestions = payload => api.post('/replenishment-suggestions;create-purchases', payload);

export const closeReplenishmentSuggestions = payload => api.put('/replenishment-suggestions;close', payload);

export default {
  fetchMany: fetchReplenishmentSuggestions,
};
