import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import { StorageLocationItemMobile } from './StorageLocationItemMobile/StorageLocationItemMobile';
import { StorageLocationTableDesktop } from './StorageLocationTableDesktop/StorageLocationTableDesktop';

const useStyles = makeStyles()(theme => ({
  root: {
    maxWidth: 'none',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      margin: 0,
      maxHeight: 'none',
      maxWidth: 'none',
      height: '100%',
      width: '100%',
    },
    [theme.breakpoints.between('sm', 'xl')]: {
      width: '70vw',
    },
    width: '50vw',
  },
  locationItem: {
    marginBottom: theme.spacing(2),
  },
}));

export const ProductLocationDialog = ({
  onClose,
  title,
  isMobile,
  storageProducts,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <Dialog onClose={onClose} classes={{ paperWidthSm: classes.root }} {...props}>
      {title && <DialogTitle>{t.translate(title)}</DialogTitle>}
      <DialogContent>
        {isMobile ? storageProducts.map(({ storage, storageProduct }, idx) => (
          <Grid key={idx} item className={classes.locationItem}>
            {(idx % 2 !== 0)  && <Divider />}
            <StorageLocationItemMobile storage={storage} storageProduct={storageProduct}/>
          </Grid>
        )) : storageProducts.map(({ storage, storageProduct }, idx) => (
          <Grid key={idx} item className={classes.locationItem}>
            {(idx % 2 !== 0)  && <Divider />}
            <StorageLocationTableDesktop storage={storage} storageProduct={storageProduct}/>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>{t.translate('Close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

ProductLocationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  storageProducts: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
