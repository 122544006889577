import React from 'react';
import PropTypes from 'prop-types';
import { useRoute } from 'hooks/route';
import { useTranslator } from 'i18n';
import Link from '@mui/material/Link';
import { StorageProductOrSpecialCaseActionReasons } from 'consts';

export const StockOperationReason = ({
  operation: {
    addedStock,
    reason,
    specialCaseId,
  },
}) => {
  const t = useTranslator();
  const route = useRoute();

  if (reason === 'special-case') {
    return (
      <Link onClick={() => route.history.push(`/special-cases/${specialCaseId}`)}>
        {t.translate(StorageProductOrSpecialCaseActionReasons[reason]?.text)} #{specialCaseId}
      </Link>
    );
  } else if (reason === 'order') {
    return addedStock < 0
      ? t.translate('Order created')
      : t.translate('Order cancelled');
  } else {
    return t.translate(StorageProductOrSpecialCaseActionReasons[reason]?.text || reason);
  }
};

StockOperationReason.propTypes = {
  operation: PropTypes.shape({
    addedStock: PropTypes.number.isRequired,
    reason: PropTypes.oneOf(Object.keys(StorageProductOrSpecialCaseActionReasons)).isRequired,
    specialCaseId: PropTypes.number,
  }),
};
