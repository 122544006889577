import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslator } from 'i18n';
import { useDispatch, useSelector } from 'react-redux';

import { fetchReplenishmentSuggestions } from 'services/replenishment-suggestions';
import { didMount, useDialog, useList, useRoute, useScreen } from 'hooks';
import tables from 'config/tables';
import { useHistory } from 'react-router-dom';
import { Column } from 'layouts';
import { ReplenishmentSuggestionsTable } from 'views/purchases/overview/DesktopView/ReplenishmentSuggestionsTable/ReplenishmentSuggestionsTable';
import {
  ReplenishmentSuggestionDialog,
  CloseReplenishmentSuggestionDialog,
  PausedReplenishmentSuggestionsDialog,
  ProductDailyStockChangesDialog,
} from 'dialogs';
import { Fab } from 'components';
import CreateIcon from '@mui/icons-material/Create';
import CloseIcon from '@mui/icons-material/Close';
import { selectActionPermission } from 'store/selectors/account';
import { getClientScrollWidth } from 'utils/clientParams';

export const ReplenishmentSuggestions = () => {
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const t = useTranslator();
  const dispatch = useDispatch();
  const list = useList(tables.replenishmentSuggestions.replenishmentSuggestions);
  const screen = useScreen();
  const route = useRoute();
  const history = useHistory();
  const [isFirstRequest, setIsFirstRequest] = useState(true);
  const [scrollWidth] = useState(getClientScrollWidth());

  const canManageReplenishmentSuggestions = useSelector(selectActionPermission('purchases', 'manageSuggestions'));

  const fetchData = useCallback(() => {
    dispatch(fetchReplenishmentSuggestions(list.filters));
  }, [dispatch, list.filters]);

  const replenishmentSuggestionDialog = useDialog(null);
  const closeReplenishmentSuggestionDialog = useDialog(null);
  const stockOperationDialog = useDialog(null);
  const pausedReplenishmentSuggestionsDialog = useDialog();

  const onPausedSuggestionsClick = () => pausedReplenishmentSuggestionsDialog.open();
  const onOpenStockDialog = (productId, supplierId) => stockOperationDialog.open({ productId, supplierId });

  didMount(() => {
    if (!route.search.status) {
      history.push('/replenishment-suggestions?status[]=OPEN');
    }
    setIsFirstRequest(false);
  });

  useEffect(() => {
    if (!isFirstRequest) {
      fetchData();
    }
  }, [fetchData, isFirstRequest, list.filters]);

  return (
    <>
      <Helmet>
        <title>{t.translate('Replenishment Suggestions')} | {t.translate('app:title')}</title>
      </Helmet>

      {pausedReplenishmentSuggestionsDialog.visible && (
        <PausedReplenishmentSuggestionsDialog
          {...pausedReplenishmentSuggestionsDialog.props}
          scrollWidth={scrollWidth}
        />
      )}

      {stockOperationDialog.visible && (
        <ProductDailyStockChangesDialog
          {...stockOperationDialog.props}
          isMobile={screen.isMobile}
        />
      )}

      {replenishmentSuggestionDialog.visible && (
        <ReplenishmentSuggestionDialog
          {...replenishmentSuggestionDialog.props}
          isMobile={screen.isMobile}
          onSuccess={fetchData}
          selectedSuggestions={selectedSuggestions}
          setSelectedSuggestions={setSelectedSuggestions}
          scrollWidth={scrollWidth}
        />
      )}

      {closeReplenishmentSuggestionDialog.visible && (
        <CloseReplenishmentSuggestionDialog
          {...closeReplenishmentSuggestionDialog.props}
          isMobile={screen.isMobile}
          onSuccess={fetchData}
          selectedSuggestions={selectedSuggestions}
          setSelectedSuggestions={setSelectedSuggestions}
        />
      )}

      {canManageReplenishmentSuggestions && Boolean(selectedSuggestions.length) && (
        <>
          <Fab
            disabled={false}
            onClick={() => replenishmentSuggestionDialog.open()}
            aria-label="Create purchases"
            variant="extended"
          >
            <CreateIcon />
            {t.translate('Create purchases')}
          </Fab>
          <Fab
            style={{ right: '250px' }}
            disabled={false}
            onClick={() => closeReplenishmentSuggestionDialog.open()}
            aria-label="Close"
            variant="extended"
          >
            <CloseIcon />
            {t.translate('Close')}
          </Fab>
        </>

      )}
      <Column p={3}>
        <ReplenishmentSuggestionsTable
          list={list}
          selectedSuggestions={selectedSuggestions}
          setSelectedSuggestions={setSelectedSuggestions}
          onOpenStockDialog={onOpenStockDialog}
          onPausedSuggestionsClick={onPausedSuggestionsClick}
          scrollWidth={scrollWidth}
        />
      </Column>
    </>
  );
};
