import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  paper: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2, 2, 0),
  },
  pagination: {
    background: theme.palette.common.white,
    bottom: theme.spacing(8),
    left: 0,
    position: 'fixed',
    right: 0,
  },
  storageSummary: {
    margin: theme.spacing(0, -1),
    paddingLeft: `${theme.spacing(2)} !important`,
    paddingRight: `${theme.spacing(2)} !important`,
    width: `calc(100% + ${theme.spacing(2)})`,
  },
  dateContainer: {
    margin: theme.spacing(1, .5),
    padding: theme.spacing(1),
  },
}));
