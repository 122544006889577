import { APP } from 'store/types';
import { pack } from 'store/utils';

export const openNavigation = () => dispatch => dispatch(pack(APP.SET_NAVIGATION_VISIBILITY, true));

export const closeNavigation = () => dispatch => dispatch(pack(APP.SET_NAVIGATION_VISIBILITY, false));

export const toggleNavigation = () => dispatch => dispatch(pack(APP.TOGGLE_NAVIGATION_VISIBILITY));

export const writeHeaderHeight = height => dispatch => dispatch(pack(APP.WRITE_HEADER_HEIGHT, height));

export const setApiVersion = version => dispatch => dispatch(pack(APP.SET_API_VERSION, version));

export const startLoading = key => dispatch => dispatch(pack(APP.SET_LOADING, { key, value: true }));

export const stopLoading = key => dispatch => dispatch(pack(APP.SET_LOADING, { key, value: false }));
