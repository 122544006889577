import { makeStyles } from 'tss-react/mui';
import { styleFormDialog } from 'theme/mixins/dialog';

export const useStyles = makeStyles()(theme => styleFormDialog(theme, {
  demoLink: {
    paddingLeft: theme.spacing(0.5),
  },
  inputContainer: {
    display: 'flex',
    alignContent: 'center',

    '& label': {
      flex: 0.25,

      [theme.breakpoints.down('sm')]: {
        flex: 0.4,
      },
    },
  },
  fileName: {
    flex: 0.75,

    [theme.breakpoints.down('sm')]: {
      flex: 0.6,
    },

    '& .MuiOutlinedInput-root': {
      borderRadius: '4px 0 0 4px',
    },
  },
  selectFileInput: {
    display: 'none',
  },
  selectFileButton: {
    borderRadius: 0,
    width: 120,
    height: '100%',
  },
  actions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dropdownLabel: {
    marginRight: 10,
    width: 80,
  },
}));
