import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';
import { makeStyles } from 'tss-react/mui';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles()(theme => ({
  checkIcon: {
    width: theme.spacing(2),
    color: theme.palette.green.main,
    marginLeft: theme.spacing(.7),
  },
  cancelIcon: {
    width: theme.spacing(2),
    color: theme.palette.red.coral,
    marginLeft: theme.spacing(.7),
  },
  defaultIcon: {
    width: theme.spacing(2),
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(.7),
  },
}));

export const BooleanIcon = ({ truthy, isDefault, className }) => {
  const { classes, cx } = useStyles();
  const t = useTranslator();

  return (
    <Grid container alignItems="center">
      {truthy
        ? (
          <>
            <Typography variant="body2">{t.translate('Active')}</Typography>
            <CheckCircleIcon className={cx(classes.checkIcon, className && className)}/>
          </>
        ) : (
          <>
            <Typography variant="body2">{t.translate('Inactive')}</Typography>
            <CancelIcon className={isDefault ? cx(classes.defaultIcon, className && className) : cx(classes.cancelIcon, className && className)}/>
          </>
        )}
    </Grid>
  );
};

BooleanIcon.propTypes = {
  truthy: PropTypes.bool,
  isDefault: PropTypes.bool,
  className: PropTypes.string,
};
