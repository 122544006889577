import React, { useCallback, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import { useSelector, useDispatch } from 'react-redux';

import { useLoading, useDialog } from 'hooks';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';

import { HandheldListener } from 'components';
import {
  SelectingScanTypeDialog,
  EanCodeScanDialog,
  QrCodeScanDialog,
} from 'dialogs';

import { updateActivePickupSheetProduct, setActivePickupSheet } from 'store/actions/pickup-sheets';
import {
  packPickupSheetProduct,
  handelProductATPM,
} from 'api/pickup-sheet-products';
import productsApi from 'api/products';
import toaster from 'services/toaster';
import { QRNextStep } from 'consts';

import {
  beepSound,
} from 'utils/sounds';

const useStyles = makeStyles()(() => ({
  button: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    textTransform: 'initial',
  },
}));

export const ScanningButton = forwardRef(({
  isHandheldScannerDisabled,
  fetchData,
  title,
  menuItem = false,
  isMobile,
  onNextStepScan,
  packingDialog,
}, ref) => {
  const dispatch = useDispatch();
  const t = useTranslator();
  const { classes } = useStyles();

  const pickupSheet = useSelector(({ pickupSheets }) => pickupSheets.activePickupSheet);

  const selectingDialog = useDialog();
  const loading = useLoading();

  const [dialogTypeVisibility, setDialogTypeVisibility] = useState({
    ean: false,
    qr: false,
  });

  const pack = async (value, property, packingDialog, isHandheldScannerDisabled, pickupSheet) => {
    if (value === QRNextStep) return onNextStepScan(packingDialog, pickupSheet);

    if (isHandheldScannerDisabled) return;

    let pickupSheetProduct;

    switch (property) {
      case 'qr': {
        pickupSheetProduct = pickupSheet.pickupSheetProducts.find(psp => {
          return psp.product.id === parseInt(value) && psp.markedQuantity !== psp.quantity;
        });
        break;
      }
      case 'ean': {
        pickupSheetProduct = pickupSheet.pickupSheetProducts.find(psp => {
          return psp.product.ean === value && psp.markedQuantity !== psp.quantity;
        });
        break;
      }
      default: {
        pickupSheetProduct = pickupSheet.pickupSheetProducts.find(psp => {
          return (psp.product.id === parseInt(value) || psp.product.ean === value)
            && psp.markedQuantity !== psp.quantity;
        });
      }
    }

    // skip try catch, if 'value' is clearly not a productID
    if (value.length < 7) {
      try {
        const product = await productsApi.fetchOne(value);
        if (product?.type?.code === 'ATPM') {
          if (!pickupSheetProduct) {
            const payload = {
              pickupSheetId: pickupSheet.id,
              productId: product.id,
            };
            pickupSheetProduct = await handelProductATPM(payload);
            dispatch(setActivePickupSheet(null));
            fetchData();
          }
        }
      } catch (err) {}
    }

    if (!pickupSheetProduct) {
      return toaster.error({
        content: 'The product in the pickup sheet does not exist.',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        ...(isMobile && { style: { bottom: 20 } }),
      });
    }

    loading.lock(async pickupSheetProductId => {
      try {
        const pickupSheetProduct = await packPickupSheetProduct(pickupSheetProductId);
        dispatch(updateActivePickupSheetProduct(pickupSheetProduct));

        const { markedQuantity, quantity } = pickupSheetProduct;
        toaster[markedQuantity === quantity ? 'success' : 'info']({
          content: t.translate('Amount: {quantity} / Marked: {markedQuantity}', { markedQuantity, quantity }),
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          ...(isMobile && { style: { bottom: 20 } }),
        });
        beepSound();
      } catch (err) {}
    })(pickupSheetProduct.id);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callPacking = useCallback(throttle((value, type) => {
    return pack(value, type, packingDialog, isHandheldScannerDisabled, pickupSheet);
  }, 500, { trailing: false }), [packingDialog, isHandheldScannerDisabled, pickupSheet]);

  return (
    <>
      <SelectingScanTypeDialog
        {...selectingDialog.props}
        onScanSelect={type => setDialogTypeVisibility({ ...dialogTypeVisibility, [type]: true })}
      />

      <QrCodeScanDialog
        open={dialogTypeVisibility.qr}
        onClose={() => setDialogTypeVisibility({ ...dialogTypeVisibility, qr: false })}
        onDecode={value => {
          callPacking(value, 'qr');
        }}
      />
      <EanCodeScanDialog
        isMobile={isMobile}
        open={dialogTypeVisibility.ean}
        onClose={() => setDialogTypeVisibility({ ...dialogTypeVisibility, ean: false })}
        onDecode={value => {
          callPacking(value, 'ean');
        }}
      />

      <HandheldListener
        onScan={pack}
        waitMilliseconds={300}
        packingDialog={packingDialog}
        isHandheldScannerDisabled={isHandheldScannerDisabled}
        pickupSheet={pickupSheet}
      />

      {menuItem ? (
        <MenuItem onClick={() => selectingDialog.open()} ref={ref}>
          <ListItemIcon>
            {loading.progress
              ? <CircularProgress color="inherit" size={20} />
              : <PhotoCameraIcon />}
          </ListItemIcon>
          <Typography variant="inherit">{title || null}</Typography>
        </MenuItem>
      ) : (
        <Button
          className={classes.button}
          color="inherit"
          onClick={() => selectingDialog.open()}
          startIcon={loading.progress
            ? <CircularProgress color="inherit" size={20} />
            : <PhotoCameraIcon />}
        >{title || null}</Button>
      )}
    </>
  );
});

ScanningButton.propTypes = {
  isHandheldScannerDisabled: PropTypes.bool.isRequired,
  title: PropTypes.string,
  menuItem: PropTypes.bool,
  isMobile: PropTypes.bool,
};
