import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './PackageItem.jsx';
import { useTranslator } from 'i18n';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { withStyles } from 'tss-react/mui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { Picture } from 'components';

const defaultAdditionalImage = (additionalImages = []) => (
  additionalImages?.find(image => image.isDefault)
);

const StyledBadge = withStyles(Badge, theme => ({
  badge: {
    backgroundColor: theme.palette.common.white,
    border: `${theme.spacing(0.125)} solid ${theme.palette.common.black}`,
    bottom: 8,
    color: theme.palette.common.black,
    fontSize: theme.spacing(1.25),
    height: theme.spacing(1.75),
    minWidth: 'initial',
    right: 12,
    width: theme.spacing(1.75),
  },
}));

const PackageProductItem = ({ pickupSheetProducts, packageProduct, classes, t }) => {
  const pickupSheetProduct = pickupSheetProducts?.find(product => product.id === Number(packageProduct.pickupSheetProductId));
  if (pickupSheetProduct) {
    return (
      <Grid item container direction="column">
        <Grid container alignItems="center">
          <Picture
            className={classes.photo}
            image={defaultAdditionalImage(pickupSheetProduct.product.additionalImages) || pickupSheetProduct.product.photo || pickupSheetProduct.product.imageUrl}
          />
          <Grid container direction="column" className={classes.packageProductLabel}>
            <Typography variant="body2" className={classes.bold}>{pickupSheetProduct.product.details[0]?.name || '—'}</Typography>
            <Box>
              <Typography variant="body2" component="span" className={classes.bold}>EAN:&nbsp;</Typography>
              <Typography variant="body2" component="span" className={classes.bold}>{pickupSheetProduct.product.ean || '—'}</Typography>
            </Box>
            <Box>
              <Typography variant="body2" component="span" className={classes.bold}>Supplier ID:&nbsp;</Typography>
              <Typography variant="body2" component="span" className={classes.bold}>{pickupSheetProduct.supplierId || '—'}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Typography variant="body2" className={classes.bold}>{t.translate('Quantity')}</Typography>
          <Typography variant="body2">{packageProduct.quantity}</Typography>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};

const PickupShetProductsHeaderPictures = ({ packageProducts, pickupSheetProducts, classes }) => {
  return packageProducts.map((packageProduct, index) => {
    const pickupSheetProduct = pickupSheetProducts?.find(product => product.id === Number(packageProduct.pickupSheetProductId));
    if (pickupSheetProduct) {
      return (
        <StyledBadge
          key={index}
          color="default"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={packageProduct.quantity}
        >
          <Picture
            className={classes.photoHeader}
            image={defaultAdditionalImage(pickupSheetProduct.product.additionalImages) || pickupSheetProduct.product.photo || pickupSheetProduct.product.imageUrl}
          />
        </StyledBadge>
      );
    } else {
      return null;
    }
  });
};

export const PackageItem = ({
  pickupSheetPackage,
  pickupSheetProducts,
  packageNumber,
  lastItemPacked,
  canUnpackPickupSheet,
  onUnpackPackage,
}) => {
  const { classes } = useStyles({ lastItemPacked });
  const t = useTranslator();
  const [expanded, setExpanded] = useState(false);

  const getHeader = () => {
    if (packageNumber && pickupSheetPackage?.dpdReference) {
      return `${packageNumber} - ${pickupSheetPackage.dpdReference}`;
    }

    return packageNumber ?? '-';
  };

  return (
    <Grid item className={classes.item}>
      <Accordion className={classes.accordion} expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item container alignItems="center" style={{ flexWrap: 'wrap' }}>
            <Typography variant="body2" className={classes.headerLabel}>{getHeader()}</Typography>
            {!expanded && (
              <PickupShetProductsHeaderPictures packageProducts={pickupSheetPackage.packageProducts} pickupSheetProducts={pickupSheetProducts} classes={classes} />
            )}
          </Grid>
        </AccordionSummary>
        {pickupSheetPackage.packageProducts?.map((packageProduct, index) => (
          <React.Fragment key={index}>
            <AccordionDetails>
              <PackageProductItem pickupSheetProducts={pickupSheetProducts} packageProduct={packageProduct} classes={classes} t={t} />
            </AccordionDetails>
            <Divider />
          </React.Fragment>
        ))}
        {canUnpackPickupSheet && (
          <Grid container direction="row-reverse">
            <Button
              className={classes.button}
              aria-label="unpack package"
              variant="outlined"
              size="small"
              onClick={() => onUnpackPackage(pickupSheetPackage.id)}
            >
              {t.translate('Unpack')}
            </Button>
          </Grid>
        )}
      </Accordion>
    </Grid>
  );
};

PackageItem.propTypes = {
  packageNumber: PropTypes.string.isRequired,
  pickupSheetPackage: PropTypes.shape({
    packageProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  pickupSheetProducts: PropTypes.arrayOf(
    PropTypes.shape({
      packedQuantity: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
      product: PropTypes.shape({
        description: PropTypes.string,
        photo: PropTypes.object,
        name: PropTypes.string,
        ean: PropTypes.string,
        alzuraId: PropTypes.string,
        imageUrl: PropTypes.string,
        additionalImages: PropTypes.array,
      }).isRequired,
    }).isRequired
  ).isRequired,
  lastItemPacked: PropTypes.bool.isRequired,
};
