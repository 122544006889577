import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useTranslator } from 'i18n';
import {
  useTableSortableHeadCell,
  useList,
} from 'hooks';

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  ActionButton,
  BooleanIcon,
  Pagination,
} from 'components';

import { wrapTableRowClick } from 'utils/html';
import tables from 'config/tables';

const useStyles = makeStyles()(theme => ({
  actionButtons: {
    whiteSpace: 'nowrap',
  },
  button: {
    color: theme.palette.primary.l900,
  },
  cellPadding: {
    '& .MuiTableRow-root .MuiTableCell-root:first-of-type': {
      paddingLeft: theme.spacing(3),
    },
    '& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:last-child': {
      paddingRight: theme.spacing(2),
    },
  },
  noteBox: {
    maxHeight: '2rem',
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const SuppliersTable = ({
  suppliersMetadata,
  canDeleteSupplier,
  onSupplierDelete,
  suppliers,
}) => {
  const history = useHistory();
  const { classes } = useStyles();
  const list = useList(tables.suppliers.suppliers);
  const t = useTranslator();

  const SortableCell = useTableSortableHeadCell({
    changeSorting: list.changeSorting,
    sorting: list.sorting,
  });

  const openSupplier = supplier => history.push(`/edit-supplier/${supplier.id}`);

  return (
    <Paper>
      <TableContainer>
        <Table stickyHeader className={classes.cellPadding}>
          <TableHead>
            <TableRow>
              <SortableCell name="number">{t.translate('Number')}</SortableCell>
              <SortableCell name="name">{t.translate('Name')}</SortableCell>
              <SortableCell name="note">{t.translate('Note')}</SortableCell>
              <SortableCell name="handling type">{t.translate('Handling type')}</SortableCell>
              <SortableCell name="active">{t.translate('Active')}</SortableCell>
              <SortableCell name="createdAt">{t.translate('Created')}</SortableCell>
              <TableCell align="right">{t.translate('Actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {suppliers.map((supplier, index) => (
              <TableRow key={index} hover onClick={wrapTableRowClick(() => openSupplier(supplier))}>
                <TableCell>{supplier.number}</TableCell>
                <TableCell>{supplier.name}</TableCell>
                <TableCell className={classes.noteBox}>
                  <Typography variant="caption">
                    {supplier.note || '—'}
                  </Typography>
                </TableCell>
                <TableCell>{supplier.handlingType ? t.translate(`supplierHandlingType:${supplier.handlingType}`) : ''}</TableCell>
                <TableCell>
                  <BooleanIcon truthy={supplier.active} isDefault />
                </TableCell>
                <TableCell>{t.formatDate(supplier.createdAt)} {t.formatTime(supplier.createdAt)}</TableCell>
                <TableCell align="right" className={classes.actionButtons}>
                  {canDeleteSupplier && (
                    <ActionButton
                      aria-label={t.translate('Delete supplier')}
                      tooltip={t.translate('Delete')}
                      icon={<DeleteIcon />}
                      onClick={() => onSupplierDelete(supplier)}
                      className={classes.button}
                    />
                  )}
                  <ActionButton
                    aria-label={t.translate('Open supplier')}
                    tooltip={t.translate('verb:Open')}
                    icon={<ChevronRightIcon />}
                    onClick={() => openSupplier(supplier)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination {...list.paginationProps} count={suppliersMetadata.totalEntities} />
    </Paper>
  );
};

SuppliersTable.propTypes = {
  suppliersMetadata: PropTypes.shape({
    totalEntities: PropTypes.number.isRequired,
  }).isRequired,
  suppliers: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      number: PropTypes.string.isRequired,
      active: PropTypes.bool,
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
};
