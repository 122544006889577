import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import { useTranslator } from 'i18n';
import { useZxing } from 'react-zxing';
import jsQR from 'jsqr';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import toaster from 'services/toaster';

const constraints = {
  video: {
    facingMode: 'environment',
  },
  audio: false,
};

export const QrCodeScanDialog = ({
  onDecode,
  onClose,
  title,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const [legacyMode, setLegacyMode] = useState(!navigator.mediaDevices || typeof navigator.mediaDevices.getUserMedia !== 'function');
  const [deviceId, setDeviceId] = useState(null);
  const fileInput = useRef(null);

  const { ref } = useZxing({
    onDecodeResult(result) {
      scan(result.getText());
    },
    onError() {
      setLegacyMode(true);
    },
    paused: !deviceId,
    deviceId,
  });

  const scan = data => {
    if (data) {
      onDecode(data);
      onClose();
    }
  };

  useEffect(() => {
    if (!legacyMode) {
      navigator.mediaDevices.getUserMedia(constraints)
        .then(res => {
          const tracks = res.getTracks();
          setDeviceId(tracks[0].getSettings().deviceId);
          tracks.forEach(track => track.stop());
        })
        .catch(() => setLegacyMode(true));
    } else {
      fileInput?.current && fileInput.current.click();
    }
  }, [legacyMode]);

  const decodeQrFromImage = ({ target: { files } }) => {
    if (files && files.length) {
      const file = files[0];
      const url = URL.createObjectURL(file);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.src = url;
      img.addEventListener('load', () => {
        const height = img.height;
        const width = img.width;

        canvas.setAttribute('height', `${height}`);
        canvas.setAttribute('width', `${width}`);

        ctx.drawImage(img, 0, 0);
        const result = jsQR(ctx.getImageData(0, 0, width, height).data, width, height);

        if (result) {
          scan(result.data);
        } else {
          toaster.error('Qr was not recognized, please try again.');
          onClose();
        }
      });
    }
  };

  return (
    <>
      <Dialog scroll="body" {...props} className={classes.root} onClose={onClose}>
        <DialogTitle className={classes.title}>{title || t.translate('Scan QR code')}</DialogTitle>
        <DialogContent className={classes.content}>
          {legacyMode
            ? (
              <input
                hidden
                type="file"
                ref={fileInput}
                onChange={decodeQrFromImage}
              />
            )
            :  <video ref={ref} className={classes.reader}/>
          }
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

QrCodeScanDialog.propTypes = {
  onDecode: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};
