import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslator } from 'i18n';
import {
  useScreen,
  useDialog,
  didMount,
  useList,
} from 'hooks';

import { CreatingWarehouseInventoryDialog } from 'dialogs';

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import { Column } from 'layouts';

import { WarehouseInventoryReportsTable } from 'views/warehouse-inventory-reports/WarehouseInventoryReportsTable/WarehouseInventoryReportsTable';
import { MobileView } from 'views/warehouse-inventory-reports/MobileView/MobileView';

import { fetchWarehouseInventoryReports } from 'services/reports';
import { fetchWarehouses } from 'services/warehouses';

import {
  selectOverviewWarehouseInventoryReportsMetadata,
  selectOverviewWarehouseInventoryReports,
} from 'store/selectors/reports';

import tables from 'config/tables';

const useStyles = makeStyles()(theme => ({
  button: {
    borderRadius: theme.spacing(3),
    bottom: theme.spacing(5),
    position: 'fixed',
    right: theme.spacing(5),
    zIndex: theme.zIndex.modal - 1,
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(8),
      right: theme.spacing(1),
    },
  },
}));

export const WarehouseInventoryReportsOverview = () => {
  const list = useList(tables.statistics.reports);
  const location = useLocation();
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const screen = useScreen();
  const t = useTranslator();

  const creatingWarehouseInventoryDialog = useDialog();

  const warehouseInventoryReportsMetadata = useSelector(selectOverviewWarehouseInventoryReportsMetadata);
  const warehouseInventoryReports = useSelector(selectOverviewWarehouseInventoryReports);

  const fetchWarehouseInventoryData = useCallback(() => dispatch(fetchWarehouseInventoryReports(list.compile())), [dispatch, list]);

  didMount(() => {
    dispatch(fetchWarehouses({ orderBy: 'id', order: 'asc' }));
  });

  useEffect(() => {
    void fetchWarehouseInventoryData();
  },[fetchWarehouseInventoryData, location.search]);

  const warehouses = useSelector(({ warehouses }) => warehouses.overview.data);

  return (
    <>
      <Helmet>
        <title>{t.translate('Warehouse inventory')} | {t.translate('app:title')}</title>
      </Helmet>

      {creatingWarehouseInventoryDialog.visible && (<CreatingWarehouseInventoryDialog
        {...creatingWarehouseInventoryDialog.props}
        onSave={fetchWarehouseInventoryData}
      />)}

      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        startIcon={<AddIcon id='assignmentAdd'/>}
        onClick={() => creatingWarehouseInventoryDialog.open({ warehouses })}
      >
        {t.translate('Create warehouse inventory report')}
      </Button>

      {screen.isMobile ? (
        <MobileView
          warehouseInventoryReportsMetadata={warehouseInventoryReportsMetadata}
          warehouseInventoryReports={warehouseInventoryReports}
        />
      ) : (
        <Column p={3}>
          <WarehouseInventoryReportsTable
            warehouseInventoryReportsMetadata={warehouseInventoryReportsMetadata}
            warehouseInventoryReports={warehouseInventoryReports}
          />
        </Column>
      )}
    </>
  );
};
