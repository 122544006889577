import { makeStyles } from 'tss-react/mui';
import { styleFormDialog } from 'theme/mixins/dialog';

export const useStyles = makeStyles()(theme => styleFormDialog(theme, {
  root: {
    zIndex: `${theme.zIndex.modal + 1} !important`,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    '& button': {
      marginTop: theme.spacing(1),
    },
  },
  actions: {
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
    },
  },
  demoFile: {
    marginBottom: theme.spacing(1),
  },
}));
