import React from 'react';
import { makeStyles } from 'tss-react/mui';

import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles()(theme => ({
  root: {
    padding: 0,
    '& .MuiFormControlLabel-root': {
      margin: 0,
      padding: theme.spacing(.75, 2),
      width: '100%',
      '& .MuiTypography-root': {
        whiteSpace: 'normal',
      },
      '& .MuiCheckbox-root, & .MuiRadio-root': {
        marginLeft: theme.spacing(-1.125),
      },
    },
    '&:focus': {
      color: theme.palette.primary.main,
      background: theme.palette.backgrounds.main['50'],
      '& .MuiIconButton-label': {
        color: theme.palette.primary.main,
      },
    },
  },
}));

export const CheckableMenuItem = props => {
  const { classes } = useStyles();

  return (
    <MenuItem className={classes.root}>
      <FormControlLabel
        labelPlacement="end"
        control={<Checkbox color="primary" />}
        {...props}
      />
    </MenuItem>
  );
};
