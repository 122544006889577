import React from 'react';
import PropTypes from 'prop-types';
import TableSortLabel from '@mui/material/TableSortLabel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const SortLabel = ({
  onSortingChange,
  sorting,
  name,
  children,
  ...props
}) => {
  const changeSorting = orderBy => onSortingChange({
    orderBy,
    order: sorting.orderBy === orderBy
      ? sorting.order === 'desc' ? 'asc' : 'desc'
      : sorting.order,
  });

  return (
    <TableSortLabel
      {...props}
      active={sorting.orderBy === name}
      direction={sorting.order}
      IconComponent={ArrowDropDownIcon}
      onClick={() => changeSorting(name)}
    >
      {children}
    </TableSortLabel>
  );
};

SortLabel.propTypes = {
  onSortingChange: PropTypes.func.isRequired,
  sorting: PropTypes.shape({
    orderBy: PropTypes.string.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
};
