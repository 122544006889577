import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { PurchaseCard } from '../common';
import { Column } from 'layouts';

import { PurchasePositionItem } from './PurchasePositionItem/PurchasePositionItem';

import { makeStyles } from 'tss-react/mui';
import { useList } from '../../../../hooks';
import tables from '../../../../config/tables';
import { Pagination } from '../../../../components';

const useStyles = makeStyles()(theme => ({
  cardLabel: {
    color: theme.palette.text.itemHeadline,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  pagination: {
    background: theme.palette.common.white,
    bottom: theme.spacing(0),
    left: 0,
    position: 'fixed',
    right: 0,
  },
}));

export const MobileView = ({
  purchase,
  positions,
  isMobile,
  ...props
}) => {
  const { classes } = useStyles();
  const t = useTranslator();
  const list = useList(tables.purchases.positions);
  const { data, meta } = positions;

  if (purchase && positions) {
    return (
      <>
        <Column p={1} pb={15.5}>
          <PurchaseCard purchase={purchase} isMobile={isMobile}/>

          <Grid container direction="column" spacing={1}>
            <Typography className={classes.cardLabel} variant="body2">{t.translate('Purchase positions')}</Typography>
            {data.map(position => (
              <PurchasePositionItem
                key={position.id}
                position={position}
                {...props}
              />
            ))}
          </Grid>
        </Column>
        <Pagination
          {...list.paginationProps}
          count={meta.totalEntities}
          direction="row"
          className={classes.pagination}
        />
      </>

    );
  } else {
    return <Column p={3} pb={8} />;
  }
};

MobileView.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  purchase: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    warehouse: PropTypes.shape({
      name: PropTypes.string,
    }),
    supplier: PropTypes.shape({
      name: PropTypes.string,
    }),
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
    editedByUser: PropTypes.shape({
      name: PropTypes.string,
    }),
    reviewedByUser: PropTypes.shape({
      name: PropTypes.string,
    }),
    status: PropTypes.string,
  }),
};
