import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { Fab } from 'components';

import { download } from 'services/files';
import {
  createPickupSheet,
  printPickupSheet,
} from 'api/pickup-sheets';

export const CreatingFab = ({ isCreateMany = false, onCreate, label, style }) => {
  const t = useTranslator();
  const [loading, setLoading] = useState(false);

  const create = async () => {
    if (loading) {
      return;
    }
    setLoading(true);

    try {
      const { pickupSheetsIds } = await createPickupSheet(isCreateMany);
      onCreate();

      for (const pickupSheetId of pickupSheetsIds) {
        const pickupSheetDocument = await printPickupSheet(pickupSheetId);

        download(pickupSheetDocument);
      }
    } catch (err) {}
    setLoading(false);
  };

  return (
    <Fab onClick={create} aria-label={label} style={style} variant="extended">
      {loading
        ? (<>
            <CircularProgress color="inherit" size={24} />
            {t.translate('Loading')}
          </>
        ) : (<>
            <AssignmentOutlinedIcon />
            {t.translate(label)}
          </>
        )}
    </Fab>
  );
};

CreatingFab.propTypes = {
  onCreate: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  isCreateMany: PropTypes.bool,
};
