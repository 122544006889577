import React from 'react';
import { areEqual } from 'react-window';
import {
  ChevronRight as ChevronRightIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import {
  IconButton,
  TableRow,
  TableCell,
  Checkbox,
  Link,
} from '@mui/material';
import {
  ActionButton,
  BooleanIcon,
  ReplenishmentStatusBadge,
} from 'components';
import { ReplenishmentSuggestionsStatuses } from 'consts';

export const ReplenishmentSuggestionTableRow = React.memo(({ index, style, data }) => {
  const {
    supplierSuggestions,
    classes,
    cellProps,
    openPurchase,
    openProduct,
    onToggle,
    selectedSuggestions,
    onOpenStockDialog,
    canManageReplenishmentSuggestions,
    t,
  } = data;

  const suggestion = supplierSuggestions[index];

  const {
    id,
    product,
    purchasePosition,
    status,
    quantity,
    supplierId,
    isForecast,
  } = suggestion;

  const PurchaseCell = (
    <TableCell {...cellProps}>
      <IconButton
        variant="contained"
        aria-label="go to product"
        onClick={() => openPurchase(purchasePosition.purchaseId)}
      >
        <ChevronRightIcon />
      </IconButton>
    </TableCell>
  );

  const EmptyCell = (
    <TableCell {...cellProps} />
  );

  const CheckboxCell = (
    <TableCell {...cellProps}>
      <Checkbox
        checked={!!selectedSuggestions.find(s => s.id === id)}
        color="primary"
        onChange={() => onToggle(suggestion)}
        id={'replenishment-suggestion-checkbox'}
      />
    </TableCell>
  );

  return (
    <TableRow
      style={style}
      key={`product-replenishment-suggestion-${id}`}
      component="div"
      className={classes.row}
    >
      {purchasePosition
        ? PurchaseCell
        : status === ReplenishmentSuggestionsStatuses.CLOSED || !canManageReplenishmentSuggestions
          ? EmptyCell
          : CheckboxCell}
      <TableCell {...cellProps}>
        <Link className={classes.link} onClick={() => openProduct(product.id)}>{product.id}</Link>
      </TableCell>
      <TableCell {...cellProps}>{product.alzuraId}</TableCell>
      <TableCell {...cellProps}>{product.manufacturerNumber}</TableCell>
      <TableCell {...cellProps}>{product.manufacturerName}</TableCell>
      <TableCell {...cellProps}>{product.ean}</TableCell>
      <TableCell {...cellProps}>{product.details[0]?.name}</TableCell>
      <TableCell {...cellProps} style={{ justifyContent: 'end' }}>
        {t.formatPrice(purchasePosition?.purchasePrice || product.purchasePrices[0].purchasePrice || 0)} €
      </TableCell>
      <TableCell {...cellProps} style={{ justifyContent: 'end' }}>{quantity}</TableCell>
      <TableCell {...cellProps} style={{ justifyContent: 'center' }}><ReplenishmentStatusBadge status={status}/></TableCell>
      <TableCell {...cellProps} style={{ justifyContent: 'center' }}><BooleanIcon truthy={isForecast}/></TableCell>
      <TableCell {...cellProps} style={{ justifyContent: 'center' }}>
        <ActionButton
          tooltipPlacement="bottom"
          aria-label={'Stock info'}
          tooltip={'Stock info'}
          icon={<InfoIcon />}
          onClick={() => onOpenStockDialog(product.id, supplierId)}
        />
      </TableCell>
    </TableRow>);
}, areEqual);
