import { SPECIAL_CASES } from 'store/types';
import { specialCases as is } from 'store/initialState';

const setActiveSpecialCase = (state, specialCase) => ({
  ...state,
  activeSpecialCase: specialCase,
});

const setSpecialCases = (state, { data, meta }) => ({
  ...state,
  specialCases: {
    ...state.specialCases,
    data,
    meta: {
      totalEntities: meta.totalEntities,
    },
  },
});

const setHistory = (state, { data, meta }) => ({
  ...state,
  history: {
    data,
    meta: {
      totalEntities: meta.totalEntities,
    },
  },
});

const setUsers = (state, users) => ({
  ...state,
  specialCases: {
    ...state.specialCases,
    users,
  },
});

export default (state = is, { type, payload }) => {
  switch (type) {
    case SPECIAL_CASES.SET_ACTIVE_SPECIAL_CASE:
      return setActiveSpecialCase(state, payload);
    case SPECIAL_CASES.SET_SPECIAL_CASES:
      return setSpecialCases(state, payload);
    case SPECIAL_CASES.SET_HISTORY:
      return setHistory(state, payload);
    case SPECIAL_CASES.SET_USERS:
      return setUsers(state, payload);
    default:
      return state;
  }
};
