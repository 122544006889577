import io from 'socket.io-client';
import config from 'config';

const { REACT_APP_API_URL: apiUrl } = process.env;

const connectionUrl = apiUrl && apiUrl.startsWith('http') ? new URL(apiUrl).origin : new URL(window.location).origin;
const socket = io(connectionUrl, {
  transports: ['websocket'],
  query: {
    'at': localStorage.getItem(config.auth.tokens.access),
  },
});

export const SOCKET_EVENTS = {
  GENERAL: {
    CONNECT: 'connect',
    CONNECT_ERROR: 'connect_error',
    DISCONNECT: 'disconnect',
  },
  ORDERS: {
    UPDATE: 'ORDERS:UPDATE',
  },
  PRODUCTS: {
    EDITED_BY: 'PRODUCTS:EDITED_BY',
  },
  PURCHASES: {
    UPDATE: 'PURCHASES:UPDATE',
    EDITED_BY: 'PURCHASES:EDITED_BY',
  },
  SUPPLIERS: {
    EDITED_BY: 'SUPPLIERS:EDITED_BY',
  },
  COMPANIES: {
    EDITED_BY: 'COMPANIES:EDITED_BY',
  },
};

export default socket;
