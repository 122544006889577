import React from 'react';

import { useSelector } from 'react-redux';
import { useTranslator } from 'i18n';
import { useList } from 'hooks';
import {
  SortBarItem,
  SortBar as SortBarContainer,
} from 'components';

import { selectHeaderHeight } from 'store/selectors/app';
import tables from 'config/tables';

const sortingFields = {
  boxNumber: t => t.translate('Box number'),
  boxColor: t => t.translate('Box color'),
  building: t => t.translate('Building'),
  floor: t => t.translate('Floor'),
  shelf: t => t.translate('Shelf'),
  level: t => t.translate('Level'),
  room: t => t.translate('Room'),
  row: t => t.translate('Row'),
  id: t => t.translate('Storage ID'),
};

export const SortBar = () => {
  const t = useTranslator();
  const list = useList(tables.storages.storages);

  const headerHeight = useSelector(selectHeaderHeight);

  const SortItem = React.forwardRef(({ name }, ref) => (
    <SortBarItem
      ref={ref}
      name={name}
      sorting={list.sorting}
      onSortingChange={list.changeSorting}
    >
      {sortingFields[name](t)}
    </SortBarItem>
  ));

  return (
    <SortBarContainer
      left={0}
      position="fixed"
      right={0}
      top={headerHeight}
      order={list.sorting.order}
      currentText={
        list.sorting.orderBy && sortingFields[list.sorting.orderBy]
          ? sortingFields[list.sorting.orderBy](t)
          : sortingFields[tables.storage.storages.orderBy](t)
      }
    >
      <SortItem name="id" />
      <SortItem name="building" />
      <SortItem name="floor" />
      <SortItem name="room" />
      <SortItem name="row" />
      <SortItem name="shelf" />
      <SortItem name="level" />
      <SortItem name="boxNumber" />
      <SortItem name="boxColor" />
    </SortBarContainer>
  );
};
