import React from 'react';
import PropTypes from 'prop-types';

import { Column } from 'layouts';
import { OutdatedProductsTable } from './OutdatedProductsTable/OutdatedProductsTable';

export const OutdatedDesktopView = ({
  selectedStorageProducts,
  setSelectedStorageProducts,
}) => (
  <>
    <Column p={3}>
      <OutdatedProductsTable
        selectedStorageProducts={selectedStorageProducts}
        setSelectedStorageProducts={setSelectedStorageProducts}
      />
    </Column>
  </>
);

OutdatedDesktopView.propTypes = {
  productTypes: PropTypes.array,
};
