import React from 'react';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import { useTranslator } from 'i18n';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import capitalize from 'lodash/capitalize';

const useStyles = makeStyles()({
  root: {
    marginLeft: '.5cm',
    marginTop: 0,
    '& > .MuiGrid-item': {
      maxWidth: '10cm',
      maxHeight: '3cm',
    },
    '& .MuiGrid-item .MuiGrid-item': {
      display: 'flex',
      flexDirection: 'column',
      '& .MuiTypography-root': {
        lineHeight: '1.5',
        fontSize: '12px',
      },
    },
  },
});

export const StorageTicket = ({ storage, ...props }) => {
  const { classes, cx } = useStyles();
  const t = useTranslator();

  return (
    <Grid container spacing={2} {...props} className={cx(classes.root, props.className)}>
      <Grid item>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <QRCodeSVG
              bgColor="#FFFFFF"
              fgColor="#000000"
              level="Q"
              style={{ width: '2.1cm' }}
              value={storage.id.toString()}
            />
          </Grid>
          <Grid item align="center">
            <Typography variant="caption" style={{ fontSize: '9px' }}>ID: {storage.id}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="caption">{t.translate('Building')}: {storage.building}</Typography>
          </Grid>
          <Grid item xs>
            {storage.floor && <Typography variant="caption">{t.translate('Floor')}: {storage.floor}</Typography>}
            {storage.room && <Typography variant="caption">{t.translate('Room')}: {storage.room}</Typography>}
            {storage.row && <Typography variant="caption">{t.translate('Row')}: {storage.row}</Typography>}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column" spacing={1}>
          {(storage.shelf || storage.level) && (
            <Grid item xs>
              {storage.shelf && <Typography variant="caption">{t.translate('Shelf')}: {storage.shelf}</Typography>}
              {storage.level && <Typography variant="caption">{t.translate('Level')}: {storage.level}</Typography>}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column" spacing={1}>
          <Grid item xs>
            {storage.boxNumber && <Typography variant="caption">{t.translate('Box no')}: {storage.boxNumber}</Typography>}
            {storage.boxColor && <Typography variant="caption">{t.translate('Color')}: {t.translate(capitalize(storage.boxColor))}</Typography>}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

StorageTicket.propTypes = {
  storage: PropTypes.shape({
    boxNumber: PropTypes.string,
    boxColor: PropTypes.string,
    building: PropTypes.string.isRequired,
    floor: PropTypes.string,
    shelf: PropTypes.string,
    level: PropTypes.string,
    room: PropTypes.string,
    row: PropTypes.string,
    id: PropTypes.number.isRequired,
  }).isRequired,
};
