import api from '.';
import { extractListParams } from 'utils/querying';

export const fetchSuppliers = params => api.get('/suppliers', { params: extractListParams(params) });

export const fetchSuppliersForFilter = ({ searchQuery } = {}) => api.get('/suppliers;filter', searchQuery && { params: { searchQuery } });

export const createSupplier = payload => api.post('/suppliers', payload);

export const fetchSupplier = supplierId => api.get(`/suppliers/${supplierId}`);

export const modifySupplier = (supplierId, payload) => api.put(`/suppliers/${supplierId}`, payload);

export const fetchSupplierTypes = () => api.get('/suppliers/types');

export const deleteSupplier = supplierId => api.delete(`/suppliers/${supplierId}`);

export const fetchAlzuraSupplier = supplierNumber => api.get('/suppliers/alzura', {
  params: {
    supplierNumber,
  },
});

export const exportSupplier = supplierId => api.post(`/suppliers/${supplierId};export`);

export const exportSupplierProducts = supplierId => api.post(`/suppliers/${supplierId};export-products`);

export default {
  deleteOne: deleteSupplier,
  modifyOne: modifySupplier,
  fetchMany: fetchSuppliers,
  createOne: createSupplier,
  fetchOne: fetchSupplier,
  fetchManyForFilter: fetchSuppliersForFilter,
  fetchAlzuraSupplier,
  exportSupplier,
  exportSupplierProducts,
};
