import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[250]}`,
    cursor: 'pointer',
    padding: theme.spacing(2, 7, 1.5, 3),
    position: 'relative',
    '&:hover': {
      background: theme.palette.action.hover,
    },
  },
  activeRoot: {
    background: `${theme.palette.primary.l200} !important`,
    '& .MuiTypography-root, & .MuiSvgIcon-root': {
      color: theme.palette.common.white,
    },
  },
  chevron: {
    marginTop: -theme.spacing(1.5),
    position: 'absolute',
    right: theme.spacing(3),
    top: '50%',
  },
}));
