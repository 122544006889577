import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import Chip from '@mui/material/Chip';
import { useTranslator } from '../../i18n';

const useStyles = makeStyles()(theme => ({
  chip: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(.5),
    borderRadius: theme.spacing(0.5),
    height: '26px',
    '& .MuiChip-label': {
      color: theme.palette.common.white,
      paddingLeft: theme.spacing(.75),
    },
  },
  redChip: {
    backgroundColor: '#c62828',
  },
  orangeChip: {
    backgroundColor: '#ef6c00',
  },
  greyChip: {
    backgroundColor: '#9e9e9e',
  },
  greenChip: {
    backgroundColor: '#4caf50',
  },
  blueChip: {
    backgroundColor: '#03a9f4',
  },
}));

const statusColors = {
  draft: 'greyChip',
  'approval-needed': 'orangeChip',
  declined: 'redChip',
  open: 'blueChip',
  received: 'greenChip',
};

const labels = {
  draft: 'status:draft',
  'approval-needed': 'status:approval-needed',
  declined: 'status:declined',
  open: 'status:open',
  received: 'status:received',
};

const getStatusColor = (status) => statusColors[status];

export const PurchaseStatusBadge = ({ status, ...props }) => {
  const { classes, cx } = useStyles();
  const t = useTranslator();

  return (
    <Chip
      {...props}
      color={'default'}
      label={t.translate(labels[status])}
      className={cx(classes.chip, classes[getStatusColor(status)])}
    />
  );
};

PurchaseStatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};
