import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';

import { Filter } from '../../common';
import {
  AppHeaderSecondaryToolbar,
  AppHeaderPrimaryToolbar,
  AppHeaderMenuButton,
  AppHeaderRightBox,
  QuerySearchInput,
  AppHeaderTitle,
  AppHeader,
  MoreBox,
} from 'components';

import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import PublishIcon from '@mui/icons-material/Publish';
import GetAppIcon from '@mui/icons-material/GetApp';
import SearchIcon from '@mui/icons-material/Search';
import PrintIcon from '@mui/icons-material/Print';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { StorageTabs } from '../../StoragesTabs/StorageTabs';

const useStyles = makeStyles()((theme) => ({
  button: {
    color: theme.palette.text.secondary,
    height: 'inherit',
    textTransform: 'none',
    minWidth: theme.spacing(4),
    justifyContent: 'left',
    padding: `${theme.spacing(1.5)} ${theme.spacing(3)} !important`,
  },
}));

export const Header = ({
  onStorageReassign,
  onStoragesExport,
  onStoragesImport,
  canReassignStock,
  onStoragesPrint,
  onTabChange,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const [visibleSearchInput, setVisibleSearchInput] = useState(false);

  return (
    <AppHeader>
      <AppHeaderPrimaryToolbar>
        <AppHeaderMenuButton edge="start" />
        <AppHeaderTitle>{t.translate('Storages')}</AppHeaderTitle>
        <AppHeaderRightBox>
          <Grid container spacing={1}>
            <Grid item>
              <MoreBox isMobile={true}>
                <Grid container direction="column">
                  {canReassignStock && (
                    <Button
                      className={classes.button}
                      startIcon={<AssignmentReturnIcon />}
                      onClick={() => onStorageReassign()}
                    >
                      {t.translate('Reassign stock')}
                    </Button>
                  )}
                  <Button
                    className={classes.button}
                    startIcon={<PrintIcon />}
                    onClick={() => onStoragesPrint()}
                  >
                    {t.translate('Print storages')}
                  </Button>
                  <Button
                    className={classes.button}
                    startIcon={<PublishIcon />}
                    onClick={() => onStoragesImport()}
                  >
                    {t.translate('Import storages')}
                  </Button>
                  <Button
                    className={classes.button}
                    startIcon={<GetAppIcon />}
                    onClick={() => onStoragesExport()}
                  >
                    {t.translate('Export storages')}
                  </Button>
                </Grid>
              </MoreBox>
            </Grid>
            <Grid item>
              <Filter isMobileView={true}/>
            </Grid>
            <Grid item>
              <IconButton
                color="inherit"
                onClick={() => setVisibleSearchInput(!visibleSearchInput)}
                aria-label="search input"
                size="large">
                <SearchIcon />
              </IconButton>
            </Grid>
          </Grid>
        </AppHeaderRightBox>
      </AppHeaderPrimaryToolbar>
      {visibleSearchInput && (
        <AppHeaderSecondaryToolbar pl={1} pr={1}>
          <QuerySearchInput placeholder={t.translate('Search by storage ID, building or room')} />
        </AppHeaderSecondaryToolbar>
      )}
      <StorageTabs
        tabs={{ variant: 'fullWidth' }}
        onChange={onTabChange}
      />
    </AppHeader>
  );
};

Header.propTypes = {
  onSortButtonClick: PropTypes.func.isRequired,
  onStoragesExport: PropTypes.func.isRequired,
  onStoragesImport: PropTypes.func.isRequired,
  canExportStorage: PropTypes.bool.isRequired,
  onTabChange: PropTypes.func.isRequired,
};
