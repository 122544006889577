import api from '.';
import { extractListParams } from 'utils/querying';

export const fetchStorages = ({
  warehouseIds,
  startId,
  endId,
  excludeStock,
  type,
  ...params
}) => api.get('/storages', {
  params: {
    ...extractListParams(params),
    ...(warehouseIds && { warehouseIds }),
    ...(startId && { startId }),
    ...(endId && { endId }),
    ...(type && { type }),
    ...(excludeStock && { excludeStock }),
  },
});

export const searchStorages = (params) => api.get('/storages;search', { params });

export const fetchSuggestedTransitStorages = ({
  orderIds,
  ...params
}) => api.get('/storages;suggested-transit', {
  params: {
    orderIds,
    ...extractListParams(params),
  },
});

export const fetchSuggestedStorages = payload => api.post('/storages;suggested', payload);

export const createStorage = payload => api.post('/storages', payload);

export const fetchStorage = (storageId, options) => api.get(`/storages/${storageId}`, options);

export const modifyStorage = (storageId, payload) => api.put(`/storages/${storageId}`, payload);

export const deleteStorage = storageId => api.delete(`/storages/${storageId}`);

export const fetchBuildings = searchQuery => api.get('/storages/buildings', { params: { searchQuery } });

export const exportStorages = () => api.get('/storages;exported');

export const uploadStorages = (data) => api.post('/storages;imported', data, {
  headers: {
    ...api.defaults.headers.common,
    'Content-Type': 'multipart/form-data',
  },
});

export const printStorageTicket = payload => api.post('/storages;print-storage-ticket', payload);

export default {
  fetchBuildings,
  fetchMany: fetchStorages,
  createOne: createStorage,
  modifyOne: modifyStorage,
  deleteOne: deleteStorage,
  fetchOne: fetchStorage,
  fetchSuggestedTransitStorages,
  fetchSuggestedStorages,
  exportStorages,
  uploadStorages,
  printStorageTicket,
};
