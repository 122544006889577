import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';

import { toggleNavigation } from 'services/app';
import { ArrowFront } from 'icons';

export const useStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: theme.palette.navigation.main,
    borderRadius: 0,
    bottom: 0,
    color: theme.palette.common.white,
    position: 'sticky',
    right: 0,
    zIndex: 999,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  thumbnail: {
    width: '100%',
  },
  default: {
    alignSelf: 'flex-end',
    transform: 'rotate(180deg)',
    width: '20%',
  },
}));

export const ToggleButton = ({ isThumbnail, ...props }) => {
  const dispatch = useDispatch();
  const { classes, cx } = useStyles();

  const className = cx(classes.root, isThumbnail ? classes.thumbnail : classes.default);

  const handleClick = () => {
      dispatch(toggleNavigation());
  };

  return (
    <IconButton
      className={className}
      {...props}
      onClick={handleClick}
      aria-label="toggle app navigation"
      size="large">
      <ArrowFront/>
    </IconButton>
  );
};
