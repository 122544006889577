import React from 'react';

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useDialog, useForm } from 'hooks';
import { useTranslator } from 'i18n';
import { useStyles } from './style.jsx';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import { ResettingPasswordDialog } from 'dialogs';
import { Auth } from 'layouts';
import {
  PasswordInput,
  InputControl,
  BusyButton,
} from 'components';

import { updateAccount } from 'store/actions/account';
import { preventAction } from 'utils/html';
import { applyLocale } from 'services/i18n';
import { setSession } from 'services/authentication';
import { setUserDefaultProductGroups } from '../../services/local-storage';
import { logIn } from 'api/authentication';

import toaster from 'services/toaster';
import config from 'config';

const makeId = postfix => `logging-in--${postfix}`;

export const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { classes } = useStyles();
  const t = useTranslator();

  const resettingDialog = useDialog();

  const locale = useSelector(({ i18n }) => i18n.locale);

  const {
    catchHttpError,
    setFormValue,
    errors,
    model,
  } = useForm({
    password: '',
    email: '',
  });

  const submit = async () => {
    try {
      const { session, user } = await logIn({ ...model, locale });

      dispatch(updateAccount(user));
      dispatch(applyLocale(user.locale));
      setSession(session);
      setUserDefaultProductGroups(user.defaultProductGroups);

      toaster.success(t.translate('You are logged in as {name}', { name: user.name }));
      history.push(user.defaultView || config.auth.paths.authenticated);
    } catch (err) {
      catchHttpError(err);
    }
  };

  return (
    <>
      <ResettingPasswordDialog
        {...resettingDialog.props}
        onSent={resettingDialog.close}
      />
      <Auth title="Login">
        <form action="#">
          <Grid container direction="column" spacing={1}>
            <Grid item className={classes.emailItem}>
              <InputControl
                id={makeId('email')}
                error={errors.email}
                label={t.translate('Email')}
                value={model.email}
                onChange={setFormValue('email')}
              />
            </Grid>
            <Grid item className={classes.passwordItem}>
              <InputControl
                id={makeId('password')}
                error={errors.password}
                label={t.translate('Password')}
                value={model.password}
                InputComponent={PasswordInput}
                onChange={setFormValue('password')}
              />
            </Grid>
            <Grid item>
              <Link
                className={classes.forgotPasswordLink}
                variant="caption"
                onClick={preventAction(() => resettingDialog.open())}
              >
                {t.translate('Forgot password')}?
              </Link>
            </Grid>
            <Grid item className={classes.submitItem}>
              <BusyButton
                fullWidth
                submit
                color="secondary"
                variant="contained"
                onClick={submit}
              >
                {t.translate('Log In')}
              </BusyButton>
            </Grid>
          </Grid>
        </form>
      </Auth>
    </>
  );
};
