import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { useStyles } from './StorageItem.jsx';
import { Color, BooleanIcon } from 'components';

export const StorageItem = ({
  storage,
}) => {
  const { classes } = useStyles();
  const t = useTranslator();

  return (
    <Grid container direction="column" spacing={2} className={classes.root}>
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate('Warehouse')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{storage.warehouse.name}</Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate('Type')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate(`storageType:${storage.type}`)}</Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate('Exclude from available stock')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <BooleanIcon truthy={storage.excludeStock} />
        </Grid>
      </Grid>
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate('Building')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{storage.building}</Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate('Floor')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{storage.floor || '—'}</Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate('Room')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{storage.room}</Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate('Row')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{storage.row}</Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate('Shelf')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{storage.shelf}</Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" className={classes.storageItem}>
        <Grid item xs={6}>
          <Typography variant="body2">{t.translate('Level')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{storage.level || '—'}</Typography>
        </Grid>
      </Grid>
      {storage.boxNumber && (
        <Grid item container direction="row" className={classes.storageItem}>
          <Grid item xs={6}>
            <Typography variant="body2">{t.translate('Box number')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">{storage.boxNumber}</Typography>
          </Grid>
        </Grid>
      )}
      {storage.boxColor && (
        <Grid item container direction="row" className={classes.storageItem}>
          <Grid item xs={6}>
            <Typography variant="body2">{t.translate('Box color')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Color box color={storage.boxColor} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

StorageItem.propTypes = {
  storage: PropTypes.shape({
    boxNumber: PropTypes.string,
    boxColor: PropTypes.string,
    building: PropTypes.string.isRequired,
    floor: PropTypes.string,
    shelf: PropTypes.string,
    level: PropTypes.string,
    room: PropTypes.string,
    row: PropTypes.string,
    volume: PropTypes.number,
    length: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    excludeStock: PropTypes.bool,
    warehouse: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};
