import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';

import { Column } from 'layouts';
import { Header, CustomerCard } from '../common';
import { CircularLoader } from 'components';
import { OrderItem } from './OrderItem/OrderItem';

const useStyles = makeStyles()(theme => ({
  item: {
    backgroundColor: theme.palette.common.white,
  },
}));

export const MobileView = ({
  loading,
  customer,
  canExportCustomer,
  onExport,
  canEditCustomer,
  onCustomerEdit,
}) => {
  const { classes } = useStyles();

  return (
    <>
      <Header
        customer={customer}
        canExportCustomer={canExportCustomer}
        onExport={onExport}
        canEditCustomer={canEditCustomer}
        onCustomerEdit={onCustomerEdit}
      />
      <Column pb={8}>
        {loading ? (
          <CircularLoader />
        ) : (
          <Grid container direction="column">
            {customer && (
              <>
                <Grid item className={classes.item}>
                  <CustomerCard customer={customer} />
                </Grid>
                {customer.orders?.map(order => (
                  <Grid item key={order.id}>
                    <OrderItem order={order} />
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        )}
      </Column>
    </>
  );
};

MobileView.propTypes = {
  loading: PropTypes.bool.isRequired,
  customer: PropTypes.shape({
    address: PropTypes.shape({
      data: PropTypes.shape({
        city: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        street: PropTypes.string.isRequired,
        house: PropTypes.string,
        zip: PropTypes.string.isRequired,
      }),
    }),
    invoiceWithoutVat: PropTypes.bool.isRequired,
    number: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    company: PropTypes.string,
    email: PropTypes.string,
    ordersCount: PropTypes.number,
    ordersTotalValueNet: PropTypes.number,
    ordersTotalValueGross: PropTypes.number,
    id: PropTypes.number.isRequired,
  }),
  canExportCustomer: PropTypes.bool.isRequired,
  onExport: PropTypes.func.isRequired,
  canEditCustomer: PropTypes.bool.isRequired,
  onCustomerEdit: PropTypes.func.isRequired,
};
