import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './style.js';
import { useTranslator } from 'i18n';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import {
  ItemHeadline,
  PurchaseStatusBadge,
} from 'components';

export const PurchaseCard = ({ purchase, isMobile, comment }) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <Grid item className={classes.root}>
      <ItemHeadline
        className={classes.cardLabel}
        text={t.translate('Purchase details')}
      />
      <Paper className={classes.cardContainer}>
        <Grid container justifyContent="space-between" direction={isMobile ? 'column' : 'row' }>
          <Grid item xs={12} md={4} className={classes.cardItem}>
            <Grid container justifyContent="space-between">
              <Typography variant="body2">{t.translate('Warehouse')}</Typography>
              <Typography variant="body2">{purchase.warehouse.name || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2">{t.translate('Supplier')}</Typography>
              <Typography variant="body2">{purchase.supplier.name || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2">{t.translate('User')}</Typography>
              <Typography variant="body2">{purchase.user.name || '—'}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} className={classes.cardItem}>
            <Grid container justifyContent="space-between">
              <Typography variant="body2">{t.translate('Created')}</Typography>
              <Typography variant="body2">
                {t.formatDate(purchase.createdAt)}
                {t.formatTime(purchase.createdAt)}
              </Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2">{t.translate('Reviewed by')}</Typography>
              <Typography variant="body2">{purchase.reviewedByUser?.name || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2">{t.translate('Status')}</Typography>
              <PurchaseStatusBadge status={purchase.status}/>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} className={classes.cardItem} >
            <Typography variant="body2" className={classes.multilineValue}>{comment || '—'}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

PurchaseCard.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  purchase: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    warehouse: PropTypes.shape({
      name: PropTypes.string,
    }),
    supplier: PropTypes.shape({
      name: PropTypes.string,
    }),
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
    reviewedByUser: PropTypes.shape({
      name: PropTypes.string,
    }),
    status: PropTypes.string,
  }),
};
