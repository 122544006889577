import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useTranslator } from 'i18n';
import { styleMobileTableItem } from 'theme/mixins/table';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  deliveryAddress: {
    marginTop: theme.spacing(1),
  },
  details: {
    padding: theme.spacing(1.5, 2),
  },
  shippingPartner: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    padding: 0,
  },
  paper: {
    padding: 0,
  },
  featureLabel: {
    fontWeight: 500,
  },
}));

export const CustomerCard = ({
  pickupSheet,
}) => {
  const { classes } = useStyles();
  const history = useHistory();
  const t = useTranslator();

  const openCustomer = id => history.push(`/customers/${id}`);

  return (
    <>
      <Paper className={classes.paper}>
        <Grid className={classes.details} justifyContent="space-between" item container>
          <Grid item>
            <Typography variant="body2" className={classes.featureLabel}>{t.translate('Customer')}</Typography>
          </Grid>
          <Grid item align="right">
            <Link onClick={() => openCustomer(pickupSheet.customer.id)}>{pickupSheet.customer.number}</Link>
            <Typography variant="body2">{t.formatDate(pickupSheet.createdAt)} {t.formatTime(pickupSheet.createdAt)}</Typography>
            {pickupSheet.recipient && (
              <>
                <Typography variant="body2">{pickupSheet.recipient.additionalName}</Typography>
                <Typography variant="body2">{pickupSheet.recipient.name}</Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.deliveryAddress}>
        <Grid className={classes.details} justifyContent="space-between" item container>
          <Grid item>
            <Typography variant="body2" className={classes.featureLabel}>{t.translate('Shipping address')}</Typography>
          </Grid>
          <Grid item align="right">
            {pickupSheet.deliveryAddress && (
              <Grid item align="right">
                <Typography variant="body2">{pickupSheet.deliveryAddress?.name || '—'}&nbsp;{pickupSheet.deliveryAddress?.additionalName || ''}</Typography>
                <Typography variant="body2">{pickupSheet.deliveryAddress?.street || '—'}&nbsp;{pickupSheet.deliveryAddress?.house || ''}</Typography>
                <Typography variant="body2">{pickupSheet.deliveryAddress?.zip || '—'}&nbsp;{pickupSheet.deliveryAddress?.city || '—'}</Typography>
                <Typography variant="body2">{pickupSheet.deliveryAddress?.country || '—'}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
      {pickupSheet.shippingPartner && (
        <Paper className={classes.shippingPartner}>
          <Grid item className={classes.details}>
            <Grid container direction="column">
              <Grid container justifyContent="space-between">
                <Typography variant="body2" className={classes.featureLabel}>{t.translate('Shipping partner')}</Typography>
                <Typography variant="body2">{t.translate(`shippingPartner:${pickupSheet.shippingPartner}`)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

CustomerCard.propTypes = {
  pickupSheet: PropTypes.shape({
    packingLabelsDocument: PropTypes.object,
    recipient: PropTypes.shape({
      additionalName: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    deliveryAddress: PropTypes.shape({
      country: PropTypes.string.isRequired,
      street: PropTypes.string.isRequired,
      house: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
      name: PropTypes.string,
      additionalName: PropTypes.string,
    }),
    createdAt: PropTypes.string.isRequired,
    customer: PropTypes.shape({
      company: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    printed: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    shippingPartner: PropTypes.string,
  }).isRequired,
};
