import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  container: {
    padding: theme.spacing(2.5),
    paddingRight: theme.spacing(2),
    alignItems: 'center',
    backgroundColor: theme.palette.backgrounds.grey['240'],
    '& .MuiDivider-vertical': {
      height: theme.spacing(4),
      margin: theme.spacing(0.5),
    },
  },
  imageItem: {
    flexShrink: 0,
    height: theme.spacing(12),
    width: theme.spacing(12),
  },
  uploadItem: {
    display: 'flex',
    flexGrow: 1,
  },
  removeItem: {
    flexShrink: 0,
  },
  cameraItem: {
    flexShrink: 0,
  },
}));
