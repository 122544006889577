import { ViewSwitch } from 'components';
import React, { useEffect, useState } from 'react';
import {
  createRoutes,
  createTabsOptions,
  editRoutes,
  editTabsOptions,
} from 'pages/settings/subPages/companies/Details/config';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { useScreen } from 'hooks';
import { useTranslator } from 'i18n';
import companiesApi from 'api/companies';
import toaster from '../../../../services/toaster';
import { extractApiErrors } from '../../../../utils/api';
import { useFormik } from 'formik';
import { tabHasError } from '../../../../pages/suppliers/helpers';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { buildValidationSchema } from './validationSchema';
import { upload as uploadFile } from '../../../../api/files';

const VoucherTypes = ['INVOICE', 'INVOICE_CORRECTION', 'CREDIT_NOTE', 'CREDIT_NOTE_CORRECTION'];

export const CompanyDetailsView = ({ countries, company, setOnSubmit, isEditMode, setIsEditMode, setIsFieldsChanged }) => {
  const history = useHistory();
  const screen = useScreen();
  const t = useTranslator();
  const params = useParams();

  const [tabs, setTabs] = useState(params.companyId ? editTabsOptions(params.companyId) : createTabsOptions);
  const [routes] = useState(params.companyId ? editRoutes(params.companyId) : createRoutes);

  const activeTab = tabs.find(({ name }) => history.location.pathname.includes(name))?.name;

  const validationSchema = buildValidationSchema(countries, t);

  const onCloseCompanyCreatePage = () => history.push(`/settings/companies/overview`);

  const handleUpload = async (fileData, values) => {
    const formData = new FormData();
    formData.append('file', fileData);
    try {
      const logoFile = await uploadFile(formData);
      values.logo = logoFile;
      values.logoId = logoFile.id;
    } catch (err) {
      toaster.error(t.translate('Failed to upload file.'));
    }
  };

  const onSubmit = async (values, { setErrors }) => {
    if (values.uploadedFile && values.uploadedFile !== company?.details?.logo) await handleUpload(values.uploadedFile, values);

    try {
      if (company && params.companyId) {
        await companiesApi.modifyOne(company.id, values);
        toaster.success(t.translate('Company has been saved successfully.'));
        setIsEditMode(false);
      } else {
        await companiesApi.createOne(values);
        toaster.success(t.translate('New Company has been created successfully.'));
        onCloseCompanyCreatePage();
      }
    } catch (err) {
      setErrors(extractApiErrors(err));
    }
  };

  const {
    name = '',
    alternativeName = '',
    addressId = null,
    address = {
      id: company?.details?.address.id || null,
      country: company?.details?.address.country || '',
      street: company?.details?.address.street || '',
      city: company?.details?.address.city || '',
      zip: company?.details?.address.zip || '',
    },
    logoId = null,
    logo = company?.details?.logo || {
      id: '',
      filePath: '',
      originalFilename: '',
    },
    phoneLandline = '',
    phoneMobile = '',
    email = '',
    websiteUrl = '',
    documentOwner = '',
    documentSeatAndRegister = '',
    documentAdditionalDetails = '',
    bankName = '',
    bankAccountOwner = '',
    bankIBAN = '',
    bankBIC = '',
    DATEVNumberLength = '7',
    DATEVPrefix = '',
    documentSettings = VoucherTypes.map(voucherType => {
      const matchingSetting = company?.documentSettings.find(setting => setting.voucherType === voucherType);

      if (matchingSetting) {
        const { id, ...rest } = matchingSetting;
        return rest;
      }

      return {
        isResetCounterAnnually: false,
        voucherType,
        prefix: '',
        yearFormat: 'NO',
        separator: '',
        numberLength: 6,
        counter: 1,
      };
    }),
    VATSettings = countries.map(country => {
      const matchingSetting = company?.VATSettings.find(vat => vat.country.countryCode === country.countryCode);

      if (matchingSetting) {
        const { id, ...rest } = matchingSetting;
        return rest;
      }

      return {
        VATId: '',
        taxNumber: '',
        VAT: 0.19,
        countryId: country.id,
        country,
      };
    }),
  } = company?.details || { details: {} };

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    values,
    errors,
    dirty,
  } = useFormik({
    enableReinitialize: true,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    initialValues: {
      name,
      alternativeName,
      addressId,
      address,
      logoId,
      logo,
      phoneLandline,
      phoneMobile,
      email,
      websiteUrl,
      documentOwner,
      documentSeatAndRegister,
      documentAdditionalDetails,
      bankName,
      bankAccountOwner,
      bankIBAN,
      bankBIC,
      DATEVNumberLength,
      DATEVPrefix,
      documentSettings,
      VATSettings,
      uploadedFile: company?.details?.logo || null,
    },
    onSubmit,
  });

  let activeRoute = !screen.isMobile ? (
    routes.find(({ homePath }) => homePath === history.location.pathname)
    || routes[0]
  ) : routes.find(({ homePath }) => homePath === history.location.pathname);

  useEffect(() => setIsFieldsChanged(dirty), [dirty, setIsFieldsChanged]);

  useEffect(() => {
    if (!routes.find(({ homePath }) => history.location.pathname === homePath)) {
      history.replace(activeRoute.homePath);
    }
  }, [activeRoute, history, routes]);

  useEffect(() => {
    setOnSubmit(() => () => handleSubmit());
  }, [setOnSubmit, handleSubmit]);

  useEffect(() => {
    setTabs((tabs) => tabs.map((tab) => ({
      ...tab,
      Icon: tabHasError(tab, errors)
        ? <ErrorOutlineIcon style={{ color: '#f44336' }} />
        : undefined,
    })));
  }, [errors]);

  return (
    <>
      <ViewSwitch options={tabs} isMobile={screen.isMobile} activeOption={activeTab} />
      <Switch>
        {routes.map(({ homePath, Page }) => (
          <Route key={homePath} exact path={homePath}>
            <Page
              isEditMode={isEditMode}
              values={values}
              errors={errors}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              companyId={params.companyId}
              countries={countries}
            />
          </Route>
        ))}
      </Switch>
    </>
  );
};
