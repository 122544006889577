import React from 'react';

import { useSelector } from 'react-redux';
import { useTranslator } from 'i18n';
import { useStyles } from './Permissions.jsx';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { BooleanChip } from 'components';
import { createPermissionsListData } from 'services/account';

export const Permissions = () => {
  const t = useTranslator();
  const { classes } = useStyles();

  const user = useSelector(({ users }) => users.details.user);
  const permissions = !user.isAdmin && createPermissionsListData(user.permissions);

  if (user.isAdmin) {
    return (
      <Paper className={classes.paper}>
        <Typography>{t.translate('The user is an administrator.')}</Typography>
      </Paper>
    );
  } else {
    return (
      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{t.translate('Permissions')}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Grid container direction="column" spacing={1}>
            {permissions.map(section => (
              <Grid item container direction="column" key={section.key} className={classes.sectionGrid}>
                <Grid item>
                  <Typography variant="body2">{t.translate(section.name)}</Typography>
                </Grid>
                <Grid item container spacing={1} className={classes.actionsGrid}>
                  {section.actions.map(action => (
                    <Grid item key={action.key}>
                      <BooleanChip
                        label={t.translate(`permission:${action.key}`)}
                        value={action.value}
                        disabled={action.key !== 'view' && !user.permissions[section.key].view}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
};
