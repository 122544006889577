import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { useList } from 'hooks';

import {
  AppHeaderSecondaryToolbar,
  AppHeaderPrimaryToolbar,
  AppHeaderMenuButton,
  AppHeaderRightBox,
  QuerySearchInput,
  SuppliersFilter,
  AppHeaderTitle,
  AppHeader,
} from 'components';

import { OrdersTabs } from '../common';

import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import GetAppIcon from '@mui/icons-material/GetApp';
import SearchIcon from '@mui/icons-material/Search';

import tables from 'config/tables';

const useStyles = makeStyles()(theme => ({
  [theme.breakpoints.down('sm')]: {
    buttonContainer: {
      '& .MuiIconButton-root': {
        borderRadius: theme.spacing(.5),
        padding: theme.spacing(1),
      },
    },
  },
  button: {
    textTransform: 'none',
  },
}));

export const Header = ({ isMobile, onTabChange, canFetchOrders, fetchLatestOrders }) => {
  const { classes } = useStyles();
  const t = useTranslator();
  const list = useList(tables.orders.orders);

  const [visibleSearchInput, setVisibleSearchInput] = useState(false);

  return (
    <AppHeader>
      <AppHeaderPrimaryToolbar>
        {isMobile && <AppHeaderMenuButton edge="start" />}
        <AppHeaderTitle>{t.translate('Orders')}</AppHeaderTitle>
        <AppHeaderRightBox>
          <Grid container spacing={1} alignItems="center" className={classes.buttonContainer} >
            {canFetchOrders && (
              <Grid item>
                {isMobile ? (
                  <IconButton
                    color="inherit"
                    onClick={fetchLatestOrders}
                    aria-label="Fetch orders"
                    size="large">
                    <GetAppIcon />
                  </IconButton>
                ) : (
                  <Button
                    className={classes.button}
                    startIcon={<GetAppIcon />}
                    onClick={fetchLatestOrders}
                    color="inherit"
                  >
                    {t.translate('Fetch orders')}
                  </Button>
                )}
              </Grid>
            )}
            <Grid item>
              <SuppliersFilter tables={list} isFilterByOverdue={true} type="supplierNumber" />
            </Grid>
            <Grid item>
              {isMobile ? (
                <IconButton
                  color="inherit"
                  onClick={() => setVisibleSearchInput(!visibleSearchInput)}
                  aria-label="search input"
                  size="large">
                  <SearchIcon />
                </IconButton>
              ) : (
                <Tooltip placement="bottom" title={t.translate('Search by order no, customer no, name, company or EAN')} >
                  <Grid>
                    <QuerySearchInput />
                  </Grid>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </AppHeaderRightBox>
      </AppHeaderPrimaryToolbar>
      {visibleSearchInput && (
        <AppHeaderSecondaryToolbar pl={1} pr={1}>
          <QuerySearchInput fullWidth placeholder={t.translate('Search by order no, customer no, name, company or EAN')}/>
        </AppHeaderSecondaryToolbar>
      )}
      <OrdersTabs onChange={onTabChange} />
    </AppHeader>
  );
};

Header.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  canFetchOrders: PropTypes.bool.isRequired,
  fetchLatestOrders: PropTypes.func.isRequired,
};
