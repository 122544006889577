import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import numeral from 'numeral';

import { useTranslator } from 'i18n';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import CancelIcon from '@mui/icons-material/Cancel';
import Divider from '@mui/material/Divider';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import { useStyles } from './OrderItem.jsx';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import {
  ActionButton,
} from 'components';
import { ApprovingButton } from '../../../common';
import { OrderStatuses } from 'consts';

export const OrderItem = ({
  onApprove,
  onCancel,
  order,
  canPerformOrderActions,
  canApprove,
  ...props
}) => {
  const history = useHistory();
  const t = useTranslator();
  const { classes } = useStyles();

  const openPickupSheet = id => history.push(`/pickup-sheets/${id}`);
  const openOrder = order => history.push(`/orders/${order.id}`);
  const openCustomer = id => history.push(`/customers/${id}`);

  return (
    <Paper className={classes.root}>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item className={classes.contentItem}>
            <Typography variant="body2" className={classes.bold}>{t.formatDate(order.date)}&nbsp;{t.formatTime(order.date)}</Typography>
            <Grid item container direction="row" >
              <Typography variant="body2" className={classes.bold}>{t.translate('Order no')}:&nbsp;</Typography>
              <Typography variant="body2" >{order.number}</Typography>
            </Grid>
            <Grid item container direction="row" >
              <Typography variant="body2" className={classes.bold}>{t.translate('Country')}:&nbsp;</Typography>
              <Typography variant="body2" className={classes.country}>{order.country}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item className={classes.contentItem}>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Customer company')}</Typography>
              <Typography variant="body2">{order.customer.company || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Customer name')}</Typography>
              <Typography variant="body2">{order.customer.name || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Supplier number')}</Typography>
              <Typography variant="body2">{order.supplier.number || '—'}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Supplier company')}</Typography>
              <Typography variant="body2">{order.supplier.name || '—'}</Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider className={classes.divider}/>
      </Accordion>
      <Grid container direction="column" className={classes.paper}>
        <Grid item className={classes.contentItem}>
          <Grid container justifyContent="space-between">
            <Typography variant="body2" className={classes.bold}>{t.translate('Customer no')}</Typography>
            <Typography variant="body2">
              <Link onClick={() => openCustomer(order.customer.id)}>{order.customer.number}</Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.contentItem}>
          <Grid container justifyContent="space-between">
            <Typography variant="body2" className={classes.bold}>{t.translate('Status')}</Typography>
            <Typography variant="body2">{order.status}</Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.contentItem}>
          <Grid container justifyContent="space-between">
            <Typography variant="body2" className={classes.bold}>{t.translate('Order Total (net)')}</Typography>
            <Typography variant="body2">{numeral(Number(order.netTotalPrice)).format('0,0.00')} &euro;</Typography>
          </Grid>
        </Grid>
        {order.pickupSheetId && (
          <Grid item className={classes.contentItem}>
            <Grid container justifyContent="space-between">
              <Typography variant="body2" className={classes.bold}>{t.translate('Picklist')}</Typography>
              <Typography variant="body2">
                <Link onClick={() => openPickupSheet(order.pickupSheetId)}>#{order.pickupSheetId}</Link>
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid item container className={classes.buttons} justifyContent="space-between" >
          <Button
            aria-label={t.translate('Open customer')}
            className={classes.button}
            startIcon={<InfoIcon />}
            onClick={() => openOrder(order)}
          >
            {t.translate('Details')}
          </Button>
          <Grid item>
            {canApprove && order.inReview && (
              <ApprovingButton
                order={order}
                onApprove={onApprove}
                className={classes.actionButton}
              />
            )}
            {(canPerformOrderActions && (order.statusCode === OrderStatuses.QUEUE || order.statusCode === OrderStatuses.CLOSED)) && !order.orderedAtSupplier && (
              <ActionButton
                aria-label={t.translate('Cancel the order')}
                tooltip={t.translate('Cancel')}
                icon={<CancelIcon />}
                onClick={() => onCancel(order)}
                className={classes.actionButton}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

OrderItem.propTypes = {
  onApprove: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  order: PropTypes.shape({
    statusCode: PropTypes.number,
    inReview: PropTypes.bool.isRequired,
    orderedAtSupplier: PropTypes.bool.isRequired,
    customer: PropTypes.shape({
      company: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    supplier: PropTypes.shape({
      id: PropTypes.number.isRequired,
      number: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    number: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    pickupSheetId: PropTypes.number,
    netTotalPrice: PropTypes.number.isRequired,
  }).isRequired,
  canPerformOrderActions: PropTypes.bool.isRequired,
  canApprove: PropTypes.bool.isRequired,
};

export default OrderItem;
