import { makeStyles } from 'tss-react/mui';

const stockButtonSizeSpacing = 4.5;

export const useStyles = makeStyles()(theme => ({
  stockSummary: {
    flexGrow: 1,
  },
  stockButton: {
    height: theme.spacing(stockButtonSizeSpacing),
    minHeight: theme.spacing(stockButtonSizeSpacing),
    minWidth: theme.spacing(stockButtonSizeSpacing),
    width: theme.spacing(stockButtonSizeSpacing),
    '& .MuiSvgIcon-root': {
      height: 12,
      width: 12,
    },
  },
  stockInput: {
    margin: theme.spacing(0, 1),
    width: 40,
    '& input': {
      textAlign: 'center',
    },
  },
}));
