import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useList } from 'hooks';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { Column } from 'layouts';
import {
  CircularLoader,
  Pagination,
} from 'components';

import { SupplierItem } from './SupplierItem/SupplierItem';

import tables from 'config/tables';

export const MobileView = ({
  isSuppliersLoading,
  suppliersMetadata,
  canDeleteSupplier,
  onSupplierDelete,
  suppliers,
}) => {
  const history = useHistory();
  const list = useList(tables.suppliers.suppliers);

  const openSupplier = supplier => history.push(`/edit-supplier/${supplier.id}`);

  return (
    <Column p={1} pb={8.5} pt={2}>
      {isSuppliersLoading ? (
        <CircularLoader />
      ) : (
        <Grid container direction="column">
          {suppliers.map(supplier => (
            <SupplierItem
              key={supplier.id}
              supplier={supplier}
              canDeleteSupplier={canDeleteSupplier}
              onSupplierDelete={onSupplierDelete}
              onSupplierOpen={openSupplier}
            />
          ))}
          <Paper>
            <Pagination
              {...list.paginationProps}
              count={suppliersMetadata.totalEntities}
              direction="row"
            />
          </Paper>
        </Grid>
      )}
    </Column>
  );
};

MobileView.propTypes = {
  isSuppliersLoading: PropTypes.bool.isRequired,
  suppliersMetadata: PropTypes.shape({
    totalEntities: PropTypes.number.isRequired,
  }).isRequired,
  suppliers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
};
