import React from 'react';
import { Grid, Typography, Select, MenuItem } from '@mui/material';
import { CheckboxControl, InputControl } from 'components';
import { useTranslator } from 'i18n';
import { Languages } from 'consts';
import { useStyles } from '../styles';
import { PageWrapper } from '../PageWrapper/PageWrapper';

const makeId = postfix => `supplier-settings-address--${postfix}`;

export const AddressSettings = ({ isEditMode, values, errors, handleChange }) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <PageWrapper>
      <Typography variant="body1" className={classes.marginBottom}>
        {t.translate('The following data will be used to create invoices and credit notes from ALZURA to the partner')}
      </Typography>
      <Grid
        container
        item
        xl={9}
        xs={12}
        direction="row"
        className={classes.containerWrap}
        spacing={4}
      >
        <Grid item md={4} xs={12}>
          <InputControl
            disabled={!isEditMode}
            id={makeId('invoiceAddress-name')}
            name="invoiceAddress.name"
            error={errors.invoiceAddress?.name}
            label={t.translate('Company')}
            value={values.invoiceAddress.name}
            onChange={handleChange}
            className={classes.marginBottom}
          />
          <InputControl
            disabled={!isEditMode}
            id={makeId('invoiceAddress-additionalName')}
            name="invoiceAddress.additionalName"
            error={errors.invoiceAddress?.additionalName}
            label={t.translate('Addition')}
            value={values.invoiceAddress.additionalName || ''}
            onChange={handleChange}
            className={classes.marginBottom}
          />
          <Grid item container spacing={1}>
            <Grid item xs={8} className={classes.marginBottom}>
              <InputControl
                disabled={!isEditMode}
                id={makeId('invoiceAddress-street')}
                name="invoiceAddress.street"
                error={errors.invoiceAddress?.street}
                label={t.translate('Street')}
                value={values.invoiceAddress.street}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <InputControl
                disabled={!isEditMode}
                id={makeId('invoiceAddress-house')}
                name="invoiceAddress.house"
                error={errors.invoiceAddress?.house}
                label={t.translate('Number')}
                value={values.invoiceAddress.house}
                onChange={handleChange}
                className={classes.marginBottom}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={4} className={classes.marginBottom}>
              <InputControl
                disabled={!isEditMode}
                id={makeId('invoiceAddress-zip')}
                name="invoiceAddress.zip"
                error={errors.invoiceAddress?.zip}
                label={t.translate('Zip-code')}
                value={values.invoiceAddress.zip}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={8} className={classes.marginBottom}>
              <InputControl
                disabled={!isEditMode}
                id={makeId('invoiceAddress-city')}
                name="invoiceAddress.city"
                error={errors.invoiceAddress?.city}
                label={t.translate('City')}
                value={values.invoiceAddress.city}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid className={classes.marginBottom}>
            <InputControl
              disabled={!isEditMode}
              id={makeId('invoiceAddress-country')}
              name="invoiceAddress.country"
              error={errors.invoiceAddress?.country}
              label={t.translate('Country')}
              value={values.invoiceAddress.country}
              onChange={handleChange}
            />
          </Grid>
          <Grid className={classes.marginBottom}>
            <InputControl
              disabled={!isEditMode}
              id={makeId('invoiceEmail')}
              name="invoiceInfo.email"
              error={errors.invoiceInfo?.email}
              label={t.translate('Mail')}
              value={values.invoiceInfo.email}
              onChange={handleChange}
            />
          </Grid>
          <InputControl
            id={makeId('invoice-language')}
            error={errors.invoiceInfo?.language}
            label={t.translate('Invoice language')}
            InputComponent={() => (<Select
              disabled={!isEditMode}
              className={classes.marginBottom}
              value={values.invoiceInfo.language}
              name="invoiceInfo.language"
              onChange={handleChange}
            >
              {Languages.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {t.translate(`country:${item}`)}
                </MenuItem>
              ))}
            </Select>)}
          />
          <CheckboxControl
            disabled={!isEditMode}
            id={makeId('isUseRepatriationAddress')}
            name="isUseRepatriationAddress"
            error={errors.isUseRepatriationAddress}
            label={t.translate('Differing repatriation address')}
            checked={!!values.isUseRepatriationAddress}
            onChange={handleChange}
          />
          {values.isUseRepatriationAddress && (
            <>
              <InputControl
                disabled={!isEditMode}
                id={makeId('repatriation-name')}
                name="repatriationAddress.name"
                error={errors.repatriationAddress?.name}
                label={t.translate('Company')}
                value={values.repatriationAddress.name}
                onChange={handleChange}
                className={classes.marginBottom}
              />
              <InputControl
                disabled={!isEditMode}
                id={makeId('repatriation-additionalName')}
                name="repatriationAddress.additionalName"
                error={errors.repatriationAddress?.additionalName}
                label={t.translate('Addition')}
                value={values.repatriationAddress.additionalName}
                onChange={handleChange}
                className={classes.marginBottom}
              />
              <Grid item container spacing={1}>
                <Grid item xs={8}>
                  <InputControl
                    disabled={!isEditMode}
                    id={makeId('repatriation-street')}
                    name="repatriationAddress.street"
                    error={errors.repatriationAddress?.street}
                    label={t.translate('Street')}
                    value={values.repatriationAddress.street}
                    onChange={handleChange}
                    className={classes.marginBottom}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputControl
                    disabled={!isEditMode}
                    id={makeId('repatriation-house')}
                    name="repatriationAddress.house"
                    error={errors.repatriationAddress?.house}
                    label={t.translate('Number')}
                    value={values.repatriationAddress.house}
                    onChange={handleChange}
                    className={classes.marginBottom}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={1}>
                <Grid item xs={4}>
                  <InputControl
                    disabled={!isEditMode}
                    id={makeId('repatriation-zip')}
                    name="repatriationAddress.zip"
                    error={errors.repatriationAddress?.zip}
                    label={t.translate('Zip-code')}
                    value={values.repatriationAddress.zip}
                    onChange={handleChange}
                    className={classes.marginBottom}
                  />
                </Grid>
                <Grid item xs={8}>
                  <InputControl
                    disabled={!isEditMode}
                    id={makeId('repatriation-city')}
                    name="repatriationAddress.city"
                    error={errors.repatriationAddress?.city}
                    label={t.translate('City')}
                    value={values.repatriationAddress.city}
                    onChange={handleChange}
                    className={classes.marginBottom}
                  />
                </Grid>
              </Grid>
              <InputControl
                disabled={!isEditMode}
                id={makeId('repatriation-country')}
                name="repatriationAddress.country"
                error={errors.repatriationAddress?.country}
                label={t.translate('Country')}
                value={values.repatriationAddress.country}
                onChange={handleChange}
                className={classes.marginBottom}
              />
            </>
          )}
        </Grid>
        <Grid item md={4} xs={12}>
          <InputControl
            disabled={!isEditMode}
            id={makeId('invoiceInfo-vatId')}
            name="invoiceInfo.vatId"
            error={errors.invoiceInfo?.vatId}
            label={t.translate('Vat ID')}
            value={values.invoiceInfo.vatId}
            onChange={handleChange}
            className={classes.marginBottom}
          />
          <InputControl
            disabled={!isEditMode}
            id={makeId('invoiceInfo-vatNumber')}
            name="invoiceInfo.vatNumber"
            error={errors.invoiceInfo?.vatNumber}
            label={t.translate('Vat Number')}
            value={values.invoiceInfo.vatNumber}
            onChange={handleChange}
            className={classes.marginBottom}
          />
          <InputControl
            disabled={!isEditMode}
            id={makeId('invoiceInfo-debitorNumber')}
            name="invoiceInfo.debitorNumber"
            error={errors.invoiceInfo?.debitorNumber}
            label={t.translate('Debitor number')}
            value={values.invoiceInfo.debitorNumber}
            onChange={handleChange}
            className={classes.marginBottom}
          />
          <InputControl
            disabled={!isEditMode}
            id={makeId('invoiceInfo-creditorNumber')}
            name="invoiceInfo.creditorNumber"
            error={errors.invoiceInfo?.creditorNumber}
            label={t.translate('Creditor number')}
            value={values.invoiceInfo.creditorNumber}
            onChange={handleChange}
            className={classes.marginBottom}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <InputControl
            disabled={!isEditMode}
            id={makeId('invoiceInfo-bankName')}
            name="invoiceInfo.bankName"
            error={errors.invoiceInfo?.bankName}
            label={t.translate('Bank Name')}
            value={values.invoiceInfo.bankName}
            onChange={handleChange}
            className={classes.marginBottom}
          />
          <InputControl
            disabled={!isEditMode}
            id={makeId('invoiceInfo-accountHolder')}
            name="invoiceInfo.accountHolder"
            error={errors.invoiceInfo?.accountHolder}
            label={t.translate('Account holder')}
            value={values.invoiceInfo.accountHolder}
            onChange={handleChange}
            className={classes.marginBottom}
          />
          <InputControl
            disabled={!isEditMode}
            id={makeId('invoiceInfo-iban')}
            name="invoiceInfo.iban"
            error={errors.invoiceInfo?.iban}
            label={t.translate('IBAN')}
            value={values.invoiceInfo.iban}
            onChange={handleChange}
            className={classes.marginBottom}
          />
          <InputControl
            disabled={!isEditMode}
            id={makeId('invoiceInfo-swift')}
            name="invoiceInfo.swift"
            error={errors.invoiceInfo?.swift}
            label={t.translate('SWIFT/BIC')}
            value={values.invoiceInfo.swift}
            onChange={handleChange}
            className={classes.marginBottom}
          />
          <InputControl
            disabled={!isEditMode}
            id={makeId('invoiceInfo-sepaMandate')}
            name="invoiceInfo.sepaMandate"
            error={errors.invoiceInfo?.sepaMandate}
            label={t.translate('SEPA mandate')}
            value={values.invoiceInfo.sepaMandate}
            onChange={handleChange}
            className={classes.marginBottom}
          />
          <InputControl
            disabled={!isEditMode}
            id={makeId('invoiceInfo-sepaMandateDate')}
            name="invoiceInfo.sepaMandateDate"
            error={errors.invoiceInfo?.sepaMandateDate}
            label={t.translate('SEPA mandate date')}
            value={values.invoiceInfo.sepaMandateDate}
            onChange={handleChange}
            className={classes.marginBottom}
          />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};
