import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import InfoIcon from '@mui/icons-material/Info';

import { CardFeature, BooleanIcon } from 'components';
import { styleMobileTableItem } from 'theme/mixins/table';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  button: {
    color: theme.palette.primary.main,
    backgroundColor: '#f1f5ff',
    textTransform: 'none',
  },
  details: {
    padding: theme.spacing(0.5, 2),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  hint: {
    maxWidth: 320,
  },
  paper: {
    padding: theme.spacing(1, 0),
  },
  paperHint: {
    padding: theme.spacing(1),
  },
  popover: {
    pointerEvents: 'none',
  },
  featureLabel: {
    fontWeight: 500,
  },
  invoiceFeatureLabel: {
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
}));

export const CustomerCard = ({ customer }) => {
  const { classes } = useStyles();
  const t = useTranslator();

  const [anchorEl, setAnchorEl] = useState({ invoiceWithoutVat: null });

  const handlePopoverOpen = (name, event) => {
    setAnchorEl(prevState => ({
      ...prevState,
      [name]: event.currentTarget,
    }));
  };

  const handlePopoverClose = (name) => {
    setAnchorEl(prevState => ({
      ...prevState,
      [name]: null,
    }));
  };

  return (
    <Paper className={classes.paper}>
      <Grid container direction="column">
        <CardFeature
          label={t.translate('Customer number')}
          value={customer.number}
          omitColon
          justifyContent="space-between"
          className={classes.details}
          classes={{ label: classes.featureLabel }}
        />
        <Grid className={classes.details} justifyContent="space-between" item container>
          <Grid item>
            <Typography variant="body2" className={classes.featureLabel}>{t.translate('Contact person')}</Typography>
          </Grid>
          <Grid item align="right">
            <Typography variant="body2">{customer.company}</Typography>
            <Typography variant="body2">{customer.name}</Typography>
          </Grid>
        </Grid>
        <Divider className={classes.divider}/>

        <Grid className={classes.details} justifyContent="space-between" item container alignItems="center">
          <Grid item>
            <Typography variant="body2" className={classes.invoiceFeatureLabel}>{t.translate('Invoice without VAT')}</Typography>
          </Grid>
          <Grid item container justifyContent="flex-end" alignItems="center">
            <BooleanIcon truthy={customer.invoiceWithoutVat} />
            <IconButton
              aria-label="hint"
              aria-owns={Boolean(anchorEl.invoiceWithoutVat) ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={e => handlePopoverOpen('invoiceWithoutVat', e)}
              onMouseLeave={() => handlePopoverClose('invoiceWithoutVat')}
              onClick={e => handlePopoverOpen('invoiceWithoutVat', e)}
              size="large">
                <InfoIcon />
              </IconButton>
              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{ paper: classes.paperHint }}
                open={Boolean(anchorEl.invoiceWithoutVat)}
                anchorEl={anchorEl.invoiceWithoutVat}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => handlePopoverClose('invoiceWithoutVat')}
                disableRestoreFocus
              >
                <Typography variant="body2" className={classes.hint}>
                  {t.translate('customers:invoiceWithoutVatHint')}
                </Typography>
              </Popover>
          </Grid>
        </Grid>
        <Divider className={classes.divider}/>

        <Grid className={classes.details} justifyContent="space-between" item container>
          <Grid item>
            <Typography variant="body2" className={classes.featureLabel}>{t.translate('Address')}</Typography>
          </Grid>
          <Grid item align="right">
            <Typography variant="body2">{customer.address.data.street}</Typography>
            <Typography variant="body2">
              {customer.address.data.zip}&nbsp;{customer.address.data.city}
            </Typography>
            <Typography variant="body2">{customer.address.data.country}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

CustomerCard.propTypes = {
  customer: PropTypes.shape({
    address: PropTypes.shape({
      data: PropTypes.shape({
        city: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        street: PropTypes.string.isRequired,
        house: PropTypes.string,
        zip: PropTypes.string.isRequired,
      }),
    }),
    number: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    company: PropTypes.string,
    email: PropTypes.string,
    invoiceWithoutVat: PropTypes.bool.isRequired,
    ordersCount: PropTypes.number,
    ordersTotalValueNet: PropTypes.number,
    ordersTotalValueGross: PropTypes.number,
    id: PropTypes.number.isRequired,
  }),
};
