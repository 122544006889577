import storageProductsApi from 'api/storage-products';
import { fetchStockOperations } from 'api/stock-operations';
import productsApi from 'api/products';
import {
  setStorageProduct,
  setProductPrices,
  setStorages,
  setProducts,
  setProduct,
  setHistory,
} from 'store/actions/products';

export const fetchStorageProducts = (storageId, productId, manufacturingDate, params) => async dispatch => {
  try {
    const storageProducts = await storageProductsApi.fetchMany({ storageId, productId, manufacturingDate, ...params });
    const totalStock = storageProducts?.data?.reduce((stock, product) => stock += product.stock, 0);
    dispatch(setStorageProduct({ ...storageProducts.data[0], totalStock: totalStock || storageProducts.data[0]?.totalStock }));
  } catch (err) {}
};

export const fetchProducts = params => async dispatch => {
  try {
    const products = await productsApi.fetchMany(params);
    dispatch(setProducts(products));
  } catch (err) {}
};

export const fetchProduct = productId => async dispatch => {
  try {
    const product = await productsApi.fetchOne(productId);
    dispatch(setProduct(product));
  } catch (err) {}
};

export const fetchHistory = (storageId, productId, manufacturingDate, params) => async dispatch => {
  try {
    const history = await fetchStockOperations({ storageId, productId, manufacturingDate, ...params });
    dispatch(setHistory(history));
  } catch (err) {}
};

export const fetchProductLocations = (productId, params) => async dispatch => {
  try {
    const storages = await storageProductsApi.fetchStorageProductsLocations({ productId, ...params });
    dispatch(setStorages(storages));
  } catch (err) {}
};

export const fetchProductPrices = (productId, params) => async dispatch => {
  try {
    const productPrices = await productsApi.fetchProductPrices(productId, params);
    dispatch(setProductPrices(productPrices));
  } catch (err) {}
};
