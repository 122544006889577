import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import {
  AppHeaderPrimaryToolbar,
  AppHeaderRightBox,
  QuerySearchInput,
  AppHeaderTitle,
  AppHeader,
  MoreBox,
} from 'components';

import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import { makeStyles } from 'tss-react/mui';
import PublishIcon from '@mui/icons-material/Publish';
import GetAppIcon from '@mui/icons-material/GetApp';
import PrintIcon from '@mui/icons-material/Print';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { Filter } from '../../Filter/Filter';
import { StorageTabs } from '../../StoragesTabs/StorageTabs';

const useStyles = makeStyles()((theme) => ({
  button: {
    color: theme.palette.text.secondary,
    height: 'inherit',
    textTransform: 'none',
    minWidth: theme.spacing(4),
    justifyContent: 'left',
    padding: theme.spacing(1.5, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 1),
    },
  },
}));

export const Header = ({
  canReassignStock,
  onStorageReassign,
  onStoragesPrint,
  onStoragesImport,
  onStoragesExport,
  onTabChange,
}) => {
  const { classes } = useStyles();
  const t = useTranslator();

  return (
    <AppHeader>
      <AppHeaderPrimaryToolbar>
        <AppHeaderTitle>{t.translate('Storages')}</AppHeaderTitle>
        <AppHeaderRightBox>
          <Grid container spacing={1}>
              <Grid item>
                <MoreBox>
                  <Grid container direction="column">
                    {canReassignStock && (
                      <Button
                        className={classes.button}
                        startIcon={<AssignmentReturnIcon />}
                        onClick={() => onStorageReassign()}
                      >
                        {t.translate('Reassign stock')}
                      </Button>
                    )}
                    <Button
                      className={classes.button}
                      startIcon={<PrintIcon />}
                      onClick={() => onStoragesPrint()}
                    >
                      {t.translate('Print storages')}
                    </Button>
                    <Button
                      className={classes.button}
                      startIcon={<PublishIcon />}
                      onClick={() => onStoragesImport()}
                    >
                      {t.translate('Import storages')}
                    </Button>
                    <Button
                      className={classes.button}
                      startIcon={<GetAppIcon />}
                      onClick={() => onStoragesExport()}
                    >
                      {t.translate('Export storages')}
                    </Button>
                  </Grid>
                </MoreBox>
              </Grid>
              <Grid item>
                <Filter isMobileView={false}/>
              </Grid>
              <Grid item>
                <QuerySearchInput placeholder={t.translate('Search by storage ID, building or room')} />
              </Grid>
          </Grid>
        </AppHeaderRightBox>
      </AppHeaderPrimaryToolbar>
      <StorageTabs onChange={onTabChange} />
    </AppHeader>
  );
};

Header.propTypes = {
  canReassignStock: PropTypes.bool.isRequired,
  onStorageReassign: PropTypes.func.isRequired,
  onStoragesPrint: PropTypes.func.isRequired,
  onStoragesExport: PropTypes.func.isRequired,
  onStoragesImport: PropTypes.func.isRequired,
  onTabChange: PropTypes.func.isRequired,
};
