import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import toaster from 'services/toaster';
import { useTranslator } from 'i18n';

import {
  FormControlLabel,
  TableContainer,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  TableHead,
  TableCell,
  TableBody,
  MenuItem,
  Checkbox,
  TableRow,
  Select,
  Button,
  Dialog,
  Table,
  Input,
  Grid,
} from '@mui/material';

import {
  StockFormControl,
  FormControlError,
  InputControl,
  BusyButton,
} from 'components';

import { useStyles } from './style';
import { customizeReturnPositions } from 'utils/returnOrder';
import { extractApiErrors } from 'utils/api';
import { returnOrder } from 'api/orders';
import { fetchCountryFees } from 'api/country-fees';
import {
  returnReason,
  returnShipment,
  SupplierReturnFeeTypes,
  SupplierHandlingTypes,
  ReturnShipment,
} from '../../consts';

const makeId = postfix => `return-stock-dialog--${postfix}`;

const isSupplierFeeType = (type, supplierType) => {
  return [SupplierReturnFeeTypes.BOTH, type].includes(supplierType);
};

export const ReturnStockDialog = ({
  onClose,
  onSave,
  order,
  isMobileView,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const t = useTranslator();
  const { classes } = useStyles();
  const [isReturnAll, setIsReturnAll] = useState(false);
  const [generalFeesValues, setGeneralFeesValues] = useState({
    fixedFee: 0,
    variableFee: 0,
  });
  const { returnFeeType } = order.supplier;

  const childForms = order.positions.reduce((acc, { id }) => ({
    ...acc,
    [id]: {
      stock: 0,
      reason: '',
    } }), {});

  const initialValues = {
    comment: '',
    isBillFeesToCustomer: true,
    returnShipment: '',
    fixedFee: 0,
    variableFee: 0,
    additionalFee: 0,
    totalFee: 0,
    trackingCount: order.tracking.length,
    isDefaultSupplierType: order.supplier.handlingType === SupplierHandlingTypes.DEFAULT,
    returnDescription: '',
    handlingDescription: '',
    returnPositions: { ...childForms },
    isWithoutStock: false,
  };

  const validationSchema = Yup
    .object()
    .shape({
      isBillFeesToCustomer: Yup.boolean(),
      isDefaultSupplierType: Yup.boolean(),
      trackingCount: Yup
        .number()
        .when(['isDefaultSupplierType'], {
          is: false,
          then: Yup.number().positive().integer().label(t.translate('Packages count')).typeError(t.translate('Packages count must be a positive number')),
        }),
      returnShipment: Yup
        .string()
        .when(['isBillFeesToCustomer'], {
          is: true,
          then: Yup.string().required(t.translate('Please specify a return shipment fees.')),
        }),
      fixedFee: Yup
        .number()
        .when(['isBillFeesToCustomer'], {
          is: true,
          then: Yup.number().min(0).label(t.translate('Fixed fee')).typeError(t.translate('Fixed fee must be a number')),
        }),
      variableFee: Yup
        .number()
        .when(['isBillFeesToCustomer'], {
          is: true,
          then: Yup.number().min(0).label(t.translate('Variable fee')).typeError(t.translate('Variable fee must be a number')),
        }),
      additionalFee: Yup
        .number()
        .when(['isBillFeesToCustomer'], {
          is: true,
          then: Yup.number().min(0).label(t.translate('Additional fee')).typeError(t.translate('Additional fee must be a number')),
        }),
      comment: Yup
        .string()
        .required(t.translate('Please specify a comment.')),
    });

  const onSubmit = async (values, { setErrors }) => {
    try {
      setIsLoading(true);
      const returnPositions = customizeReturnPositions(values.returnPositions);
      if (!returnPositions.length) {
        toaster.error(t.translate('At least one position must have a return quantity and a reason'));
        return;
      }
      await returnOrder(order.id, {
        ...values,
        trackingCount: !values.isDefaultSupplierType ? values.trackingCount : null,
        returnShipment: values.isBillFeesToCustomer ? values.returnShipment : null,
        orderId: order.id,
        returnPositions,
        variableFee: isSupplierFeeType(SupplierReturnFeeTypes.PERCENT, returnFeeType) ? Number(values.variableFee).toFixed(2) : null,
        fixedFee: isSupplierFeeType(SupplierReturnFeeTypes.FIXED, returnFeeType) ? Number(values.fixedFee).toFixed(2) : null,
        additionalFee: Number(values.additionalFee) > 0 ? Number(values.additionalFee).toFixed(2) : null,
      });

      toaster.success(t.translate('Products have been returned successfully.'));
      onSave();
      handleClose();
    } catch (err) {
      setErrors(extractApiErrors(err));
    } finally {
      setIsLoading(false);
    }
  };

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    values,
    errors,
    resetForm,
  } = useFormik({
    validateOnChange: false,
    validationSchema,
    initialValues,
    onSubmit,
  });


  useEffect(() => {
    fetchCountryFees().then( res => {
      const { fixedReturnFee, variableReturnFee, returnDescription, handlingDescription } = res.data.find( country => country.countryCode === order.country);
      setGeneralFeesValues({ fixedFee: Number(fixedReturnFee), variableFee: Number(variableReturnFee) });
      setFieldValue('returnDescription', returnDescription);
      setFieldValue('handlingDescription', handlingDescription);
    });
  }, [setFieldValue, order]);

  useEffect(() => {
    if (isSupplierFeeType(SupplierReturnFeeTypes.PERCENT, returnFeeType)) {
      const totalVariableFee = order.positions
        .filter(pos => values.returnPositions[pos.id].stock > 0)
        .reduce((acc, pos) => acc + (Number(pos.netPrice) * Number(generalFeesValues.variableFee) / 100) * values.returnPositions[pos.id].stock, 0);
      setFieldValue('variableFee', (totalVariableFee).toFixed(2));
    }
    if (isSupplierFeeType(SupplierReturnFeeTypes.FIXED, returnFeeType)) {
      if (order) {
        setFieldValue('fixedFee', Number(generalFeesValues.fixedFee).toFixed(2));
      }
    }
  }, [
    generalFeesValues,
    setFieldValue,
    returnFeeType,
    values.returnPositions,
    order,
  ]);

  useEffect(() => {
    setFieldValue('totalFee', Number(values.additionalFee) + Number(values.fixedFee) + Number(values.variableFee));
  }, [values.additionalFee, values.fixedFee, values.variableFee, setFieldValue]);

  const handleClose = () => {
    resetForm();
    onClose();
    setIsReturnAll(false);
  };

  const handleReturnAll = () => {
    if (!isReturnAll) {
      order.positions.forEach(pos => {
        setFieldValue(`returnPositions[${pos.id}].stock`, pos.quantity - getReturnedQuantity(pos));
      });
    } else {
      order.positions.forEach(pos => {
        setFieldValue(`returnPositions[${pos.id}].stock`, 0);
      });
    }
    setIsReturnAll(!isReturnAll);
  };

  const getReturnedQuantity = (position) => {
    return position.orderPositionReturns.reduce((acc, pos) => acc + pos.quantity, 0);
  };

  return (
    <Dialog {...props} scroll="body" fullScreen={isMobileView} classes={{ paperScrollBody: classes.root }} onClose={handleClose}>
      <DialogTitle id="max-width-dialog-title">{t.translate('Return products')}</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell name="productId" style={{ minWidth: 100 }}>{t.translate('Product-ID')}</TableCell>
                    <TableCell name="productName">{t.translate('Product name')}</TableCell>
                    <TableCell name="ean">EAN</TableCell>
                    <TableCell name="quantity">{t.translate('Quantity')}</TableCell>
                    <TableCell name="return" align="center">{t.translate('Return')}</TableCell>
                    <TableCell name="reason">{t.translate('Reason')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.positions.map((position, index) => (
                    <React.Fragment key={index}>
                      <TableRow key={index} className={classes.returnRow}>
                        <TableCell>{position.product.id}</TableCell>
                        <TableCell>{position.product.details[0]?.name || '—'}</TableCell>
                        <TableCell>{position.product.ean || '—'}</TableCell>
                        <TableCell style={{ minWidth: 150 }}>
                          <span style={{ marginRight: 10 }}>{position.quantity}</span>
                          <span style={{ fontWeight: 'bold' }}>{`${t.translate('Returned')}: `}</span>
                          {getReturnedQuantity(position)}
                        </TableCell>
                        <TableCell style={{ minWidth: 200 }}>
                          <StockFormControl
                            hideLabels={true}
                            actions={['add']}
                            stock={getReturnedQuantity(position)}
                            name={`returnPositions[${position.id}].stock`}
                            value={values.returnPositions[position.id].stock}
                            topBound={position.quantity - getReturnedQuantity(position)}
                            onChange={e => setFieldValue(`returnPositions[${position.id}].stock`, e)}
                          />
                        </TableCell>
                        <TableCell>
                          <FormControl style={{ minWidth: 150 }} id={makeId('reason-form')}>
                            <Select
                              id={makeId('reason')}
                              name={`returnPositions[${position.id}].reason`}
                              value={values.returnPositions[position.id].reason}
                              onChange={(e) => setFieldValue(`returnPositions[${position.id}].reason`, e.target.value)}
                            >
                              {returnReason.map(({ value, text }) => (
                                <MenuItem key={value} value={text}>{t.translate(text)}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.emptyRow} />
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {(order.returnStatus !== 'returned') && (
            <Grid item className={classes.returnButton}>
              <Button
                variant="outlined"
                onClick={handleReturnAll}
              >{t.translate(isReturnAll ? 'Reset' : 'Return all')}
              </Button>
            </Grid>
          )}
          {!values.isDefaultSupplierType &&
            <Grid item>
              <FormControl fullWidth error={!!errors.trackingCount} style={{ marginBottom: 10 }}>
                <InputLabel shrink htmlFor={'tracking-count'}>{t.translate('Packages count')}</InputLabel>
                <Input
                  type="number"
                  id={'tracking-count'}
                  name={'trackingCount'}
                  value={values.trackingCount}
                  onChange={handleChange}
                />
                {!!errors.trackingCount && <FormControlError error={errors.trackingCount} />}
              </FormControl>
            </Grid>
          }
          <Grid item>
            <FormControlLabel
                control={
                  <Checkbox
                      checked={values.isWithoutStock}
                      color="primary"
                      name={'isWithoutStock'}
                      onChange={(e) => {
                        setFieldValue('returnShipment', ReturnShipment.HANDLED_BY_CUSTOMER);
                        handleChange(e);
                      }}
                  />
                }
                label={t.translate('Products will not be returned')}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.isBillFeesToCustomer}
                  color="primary"
                  name={'isBillFeesToCustomer'}
                  onChange={handleChange}
                />
              }
              label={t.translate('Bill fees to customer')}
            />
          </Grid>
          {values.isBillFeesToCustomer &&
            <Grid item container spacing={1} className={classes.reasonItem}>
              {isSupplierFeeType(SupplierReturnFeeTypes.FIXED, returnFeeType) &&
                <Grid item xs={2}>
                  <FormControl fullWidth error={!!errors.fixedFee} style={{ marginBottom: 10 }}>
                    <InputLabel shrink htmlFor={'fixed-fee-input'}>{t.translate('Fixed fee')} &euro;</InputLabel>
                    <Input
                      type="number"
                      id={'fixed-fee-input'}
                      name={'fixedFee'}
                      value={values.fixedFee}
                      onChange={handleChange}
                    />
                    {!!errors.fixedFee && <FormControlError error={errors.fixedFee} />}
                  </FormControl>
                </Grid>
              }
              {isSupplierFeeType(SupplierReturnFeeTypes.PERCENT, returnFeeType) &&
                <Grid item xs={2}>
                  <FormControl fullWidth error={!!errors.variableFee} style={{ marginBottom: 10 }}>
                    <InputLabel shrink htmlFor={'variable-fee-input'}>{t.translate('Variable fee')} &euro;</InputLabel>
                    <Input
                      type="number"
                      id={'variable-fee-input'}
                      name={'variableFee'}
                      value={values.variableFee}
                      onChange={handleChange}
                    />
                    {!!errors.variableFee && <FormControlError error={errors.variableFee} />}
                  </FormControl>
                </Grid>
              }
              <Grid item xs={2}>
                <FormControl fullWidth error={!!errors.additionalFee} style={{ marginBottom: 10 }}>
                  <InputLabel shrink htmlFor={'additional-fee-input'}>{t.translate('Additional fee')} &euro;</InputLabel>
                  <Input
                    type="number"
                    id={'additional-fee-input'}
                    name={'additionalFee'}
                    value={values.additionalFee}
                    onChange={handleChange}
                  />
                  {!!errors.additionalFee && <FormControlError error={errors.additionalFee} />}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth error={!!errors.returnShipment} id={makeId('return-shipment-form')}>
                  <InputLabel shrink htmlFor={makeId('return-shipment')}>{t.translate('Return shipment')}</InputLabel>
                  <Select
                    id={makeId('return-shipment')}
                    name={'returnShipment'}
                    disabled={values.isWithoutStock}
                    value={values.returnShipment}
                    onChange={(e) => setFieldValue(`returnShipment`, e.target.value)}
                  >
                    {returnShipment.map(({ value, text }) => (
                      <MenuItem key={value} value={value}>{t.translate(text)}</MenuItem>
                    ))}
                  </Select>
                  {!!errors.returnShipment && <FormControlError error={errors.returnShipment} id={makeId('return-shipment-error')}/>}
                </FormControl>
              </Grid>
              <Grid item className={classes.totalFeesContainer} xs={3}>
                <div style={{ fontWeight: 'bold' }} className={classes.totalFeesItem}>{t.translate('Total fees for this return')}</div>
                <div className={classes.totalFeesItem}>
                  {t.formatPrice(values.totalFee + (values.returnShipment === ReturnShipment.HANDLED_BY_ALZURA ? Number(values.fixedFee) : 0))}&nbsp;&euro;
                </div>
              </Grid>
            </Grid>
          }
          <Grid item>
            <InputControl
              id={makeId('comment')}
              error={errors.comment}
              label={t.translate('Comment')}
              value={values.comment}
              name="comment"
              placeholder={t.translate('Annotations')}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="primary" onClick={handleClose} disabled={isLoading}>{t.translate('dialog-action:Cancel')}</Button>
        <BusyButton color="primary" submit onClick={handleSubmit} disabled={isLoading}>{t.translate('Save')}</BusyButton>
      </DialogActions>
    </Dialog>
  );
};

ReturnStockDialog.propTypes = {
  onSpecialCaseSave: PropTypes.func,
  onStorageSave: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  orderPosition: PropTypes.shape({
    productId: PropTypes.number.isRequired,
    alzuraId: PropTypes.string,
  }),
  order: PropTypes.object.isRequired,
  orderCode: PropTypes.string,
};
