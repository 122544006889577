import React from 'react';
import PropTypes from 'prop-types';
import { useList } from 'hooks/list';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';

import { WarehouseInventoryReportsItem } from './WarehouseInventoryReportsItem/WarehouseInventoryReportsItem';
import { Pagination } from 'components';
import { Column } from 'layouts';

import tables from 'config/tables';

const useStyles = makeStyles()(theme => ({
  pagination: {
    background: theme.palette.common.white,
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
  },
}));

export const MobileView = ({
  warehouseInventoryReportsMetadata,
  warehouseInventoryReports,
}) => {
  const { classes } = useStyles();
  const list = useList(tables.statistics.reports);

  return (
    <>
      <Column p={1} pb={7.5}>
        <Grid container direction="column" spacing={1}>
          {warehouseInventoryReports.map(warehouseInventoryReport => (
            <WarehouseInventoryReportsItem
              key={warehouseInventoryReport.id}
              warehouseInventoryReport={warehouseInventoryReport}
            />
          ))}
        </Grid>
      </Column>
      <Pagination
        {...list.paginationProps}
        count={warehouseInventoryReportsMetadata.totalEntities}
        direction="row"
        className={classes.pagination}
      />
    </>
  );
};

MobileView.propTypes = {
  warehouseInventoryReportsMetadata: PropTypes.shape({
    totalEntities: PropTypes.number.isRequired,
  }).isRequired,
  warehouseInventoryReports: PropTypes.array.isRequired,
};
