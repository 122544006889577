import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { Color, BooleanIcon } from 'components';

const useStyles = makeStyles()(theme => ({
  root: {
    margin: theme.spacing(.5),
    padding: theme.spacing(1),
  },
  button: {
    color: theme.palette.primary.l900,
  },
}));

export const StorageSummary = ({ storage, ...props }) => {
  const { classes } = useStyles();
  const t = useTranslator();

  return (
    <Paper className={classes.root}>
      <Grid {...props} container direction="column" >
        <Grid item container justifyContent="space-between">
          <Grid item >
            <Typography variant="body2">{t.translate('Building')}</Typography>
          </Grid>
          <Grid>
            <Typography variant="body2">{storage.building}</Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" className={classes.storageItem}>
          <Grid item xs={6}>
            <Typography variant="body2">{t.translate('Type')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">{t.translate(`storageType:${storage.type}`)}</Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" className={classes.storageItem}>
          <Grid item xs={6}>
            <Typography variant="body2">{t.translate('Exclude from available stock')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <BooleanIcon truthy={storage.excludeStock} />
          </Grid>
        </Grid>
        <Grid item container justifyContent="space-between">
          <Grid item >
            <Typography variant="body2">{t.translate('Floor')}</Typography>
          </Grid>
          <Grid>
            <Typography variant="body2">{storage.floor || '—'}</Typography>
          </Grid>
        </Grid>
        <Grid item container justifyContent="space-between">
          <Grid item >
            <Typography variant="body2">{t.translate('Room')}</Typography>
          </Grid>
          <Grid>
            <Typography variant="body2">{storage.room || '—'}</Typography>
          </Grid>
        </Grid>
        <Grid item container justifyContent="space-between">
          <Grid item >
            <Typography variant="body2">{t.translate('Row')}</Typography>
          </Grid>
          <Grid>
            <Typography variant="body2">{storage.row || '—'}</Typography>
          </Grid>
        </Grid>
        <Grid item container justifyContent="space-between">
          <Grid item >
            <Typography variant="body2">{t.translate('Shelf')}</Typography>
          </Grid>
          <Grid>
            <Typography variant="body2">{storage.shelf || '—'}</Typography>
          </Grid>
        </Grid>
        <Grid item container justifyContent="space-between">
          <Grid item >
            <Typography variant="body2">{t.translate('Level')}</Typography>
          </Grid>
          <Grid>
            <Typography variant="body2">{storage.level || '—'}</Typography>
          </Grid>
        </Grid>
        <Grid item container justifyContent="space-between">
          <Grid item >
            <Typography variant="body2">{t.translate('Box no')}</Typography>
          </Grid>
          <Grid>
            <Typography variant="body2">{storage.boxNumber || '—'}</Typography>
          </Grid>
        </Grid>
        <Grid item container justifyContent="space-between">
          <Grid item >
            <Typography variant="body2">{t.translate('Box color')}</Typography>
          </Grid>
          <Grid>
            <Color box color={storage.boxColor || 'black'} />
          </Grid>
        </Grid>
        <Grid item container justifyContent="space-between">
          <Grid item >
            <Typography variant="body2">{t.translate('Total stock')}</Typography>
          </Grid>
          <Grid>
            total stock
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

StorageSummary.propTypes = {
  storage: PropTypes.shape({
    boxNumber: PropTypes.string,
    boxColor: PropTypes.string,
    building: PropTypes.string.isRequired,
    floor: PropTypes.string,
    shelf: PropTypes.string,
    level: PropTypes.string,
    room: PropTypes.string,
    row: PropTypes.string,
    excludeStock: PropTypes.bool,
    type: PropTypes.string.isRequired,
  }).isRequired,
};
