import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { OrderProductDescriptionMobile } from './OrderProductDescriptionMobile/OrderProductDescriptionMobile';
import { OrderProductDescriptionDesktop } from './OrderProductDescriptionDesktop/OrderProductDescriptionDesktop';

const useStyles = makeStyles()(theme => ({
  root: {
    maxWidth: 'none',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      margin: 0,
      maxHeight: 'none',
      maxWidth: 'none',
      height: '100%',
      width: '100%',
    },
    [theme.breakpoints.between('sm', 'xl')]: {
      width: '70vw',
    },
    width: '50vw',
  },
}));

export const OrderProductDescriptionDialog = ({
  onClose,
  position,
  title,
  isMobile,
  ...props
}) => {
  const t = useTranslator();
  const history = useHistory();
  const { classes } = useStyles();

  const openProduct = id => history.push(`/products/${id}`);

  return (
    <Dialog onClose={onClose} classes={{ paperWidthSm: classes.root }} {...props}>
      {title && <DialogTitle>{t.translate(title)}</DialogTitle>}
      <DialogContent>
        {isMobile ? (
          <OrderProductDescriptionMobile { ...position }/>
        ) : (
          <OrderProductDescriptionDesktop { ...position }/>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => openProduct(position.product?.id)}>{t.translate('View product')}</Button>
        <Button color="primary" onClick={onClose}>{t.translate('Close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

OrderProductDescriptionDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  position: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
