import React from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';

import { useTranslator } from 'i18n';
import {
  useTableSortableHeadCell,
  useList,
} from 'hooks';

import TableContainer from '@mui/material/TableContainer';
import { makeStyles } from 'tss-react/mui';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import GetAppIcon from '@mui/icons-material/GetApp';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Link from '@mui/material/Link';

import {
  ActionButton,
  Pagination,
} from 'components';

import { makeSourceLink } from 'services/files';
import tables from 'config/tables';

const useStyles = makeStyles()(theme => ({
  textAlign: {
    // '& .MuiTableRow-root .MuiTableCell-root:first-of-type': {
    //   // textAlign: 'center',
    //   padding: theme.spacing(2),
    // },
    '& .MuiTableRow-root .MuiTableCell-root': {
      // textAlign: 'center',
      padding: theme.spacing(2),
    },
  },
  warehouseContainer: {
    backgroundColor: theme.palette.grey[100],
  },
  button: {
    color: theme.palette.primary.l900,
  },
}));

export const WarehouseInventoryReportsTable = ({
  warehouseInventoryReportsMetadata,
  warehouseInventoryReports,
}) => {
  const list = useList(tables.statistics.reports);
  const { classes } = useStyles();
  const t = useTranslator();

  const SortableCell = useTableSortableHeadCell({
    changeSorting: list.changeSorting,
    sorting: list.sorting,
  });

  const warehouseInventoryReportsGroups = groupBy(warehouseInventoryReports, 'warehouseId');

  return (
    <>
    <Paper>
      <TableContainer>
        <Table className={classes.textAlign}>
          <TableHead>
            <TableRow>
              <SortableCell name="createdAt">{t.translate('Time')}</SortableCell>
              <SortableCell name="userName">{t.translate('User')}</SortableCell>
              <TableCell>{t.translate('Report type')}</TableCell>
              <TableCell align="right">{t.translate('Actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(warehouseInventoryReportsGroups).map(([warehouseId, warehouseInventoryReports]) => (
              <React.Fragment key={warehouseId}>
                <TableRow className={classes.warehouseContainer}>
                  <TableCell colSpan={6} align="center">
                    <strong>Warehouse #{warehouseId}</strong>
                  </TableCell>
                </TableRow>
                {warehouseInventoryReports.map((inventoryReport, index) => (
                  <TableRow key={index} hover>
                    <TableCell>{`${t.formatDate(inventoryReport.createdAt)} ${t.formatTime(inventoryReport.createdAt)}`}</TableCell>
                    <TableCell>{inventoryReport.user?.name}</TableCell>
                    <TableCell>{t.translate('Warehouse inventory')}</TableCell>
                    <TableCell align="right">
                      <Link href={makeSourceLink(inventoryReport.document.filePath)}>
                        <ActionButton
                          aria-label={t.translate('Download the statistics report')}
                          tooltip={t.translate('Download')}
                          icon={<GetAppIcon />}
                          className={classes.button}
                        />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination {...list.paginationProps} count={warehouseInventoryReportsMetadata.totalEntities} />
      </Paper>
    </>
  );
};

WarehouseInventoryReportsTable.propTypes = {
  warehouseInventoryReportsMetadata: PropTypes.shape({
    totalEntities: PropTypes.number.isRequired,
  }).isRequired,
  warehouseInventoryReports: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string.isRequired,
      document: PropTypes.shape({
        filePath: PropTypes.string.isRequired,
      }).isRequired,
      warehouseId: PropTypes.number.isRequired,
      user: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
};
