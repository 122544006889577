import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export const HandheldListener = ({
  onScan,
  waitMilliseconds,
  packingDialog = null,
  pickupSheet = null,
  isHandheldScannerDisabled = null,
}) => {
  useEffect(() => {
    let chars = [];
    let timeout = null;
    const handle = ({ which, keyCode }) => {
      const key = which || keyCode;

      if (
        (key >= 48 && key <= 57) // 0-9
        || (key >= 65 && key <= 90) // a-Z
        || key === 32 // space
      ) {
        chars.push(String.fromCharCode(key));
      }

      clearTimeout(timeout);

      timeout = setTimeout(() => {
        onScan(chars.join(''), null, packingDialog, isHandheldScannerDisabled, pickupSheet);
        chars = [];
      }, waitMilliseconds);
    };

    clearTimeout(timeout);

    window.addEventListener('keydown', handle);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('keydown', handle);
    };
  }, [packingDialog, isHandheldScannerDisabled, pickupSheet, onScan, waitMilliseconds]);
  return <></>;
};

HandheldListener.propTypes = {
  onScan: PropTypes.func.isRequired,
  waitMilliseconds: PropTypes.number,
};

HandheldListener.defaultProps = {
  waitMilliseconds: 500,
};
