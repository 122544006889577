import { RAW_ORDERS } from 'store/types';
import { rawOrders as initialState } from 'store/initialState';

const setRawOrders = (state, { data, meta }) => {
  return ({
    ...state,
    data,
    meta: {
      totalEntities: meta.totalEntities,
    },
  });
};

const setIsLoading = (state, payload) => ({
  ...state,
  isLoading: payload,
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case RAW_ORDERS.SET_IS_LOADING:
      return setIsLoading(state, payload);
    case RAW_ORDERS.SET_RAW_ORDERS:
      return setRawOrders(state, payload);
    default:
      return state;
  }
};
