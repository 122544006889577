import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import { useTranslator } from 'i18n';
import { didMount, useScreen } from 'hooks';
import { Column } from 'layouts';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';

import marketplacesApi from 'api/marketplaces';
import { modifyMarketplaceCountry } from 'api/marketplace-countries';
import toaster from 'services/toaster';
import { extractApiErrors } from 'utils/api';
import { Hint } from 'views/suppliers/settings/Hint/Hint';
import { BusyButton, InputControl, PasswordInput } from 'components';

const useStyles = makeStyles()((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[250]}`,
    cursor: 'pointer',
    padding: theme.spacing(2, 7, 1.5, 3),
    position: 'relative',
    '&:hover': {
      background: theme.palette.action.hover,
    },
  },
  activeRoot: {
    background: `${theme.palette.primary.l200} !important`,
    '& .MuiTypography-root, & .MuiSvgIcon-root': {
      color: theme.palette.common.white,
    },
  },
  chevron: {
    marginTop: -theme.spacing(1.5),
    position: 'absolute',
    right: theme.spacing(3),
    top: '35%',
  },
  containerWrap: {
    flexWrap: 'wrap',
    flexBasis: 'auto',
    alignItems: 'flex-start',
  },
  marginBottom: {
    marginBottom: 20,
  },
  helpTextIcon: {
    width: '24px',
    cursor: 'pointer',
    margin: theme.spacing(0, 1, 0),
  },
}));

const makeId = postfix => `marketplace-settings--${postfix}`;

export const Marketplaces = () => {
  const { classes } = useStyles();
  const screen = useScreen();
  const t = useTranslator();

  const [marketplaces, setMarketplaces] = useState([]);

  const fetchMarketplaces = async () => {
    try {
      const { data: marketplaces } = await marketplacesApi.fetchMarketplaces();
      setMarketplaces(marketplaces);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  didMount(() => {
    fetchMarketplaces();
  });

  const [activeCountryCode, setActiveCountryCode] = useState('de');

  const activeMarketplaceCountry = marketplaces.find(marketplace => marketplace.country.countryCode === activeCountryCode);

  const [initialValues, setInitialValues] = useState({
    zipCodes: '',
    surcharges: [],
    isActive: false,
    supplierAccountNumber: '',
    supplierToken: '',
  });

  const validationSchema = Yup
    .object()
    .shape({
      isActive: Yup.boolean().required(),
      supplierAccountNumber: Yup.string().nullable(),
      supplierToken: Yup.string().nullable(),
    });


  const onSubmit = async (values, { setErrors }) => {
    try {
      await modifyMarketplaceCountry(activeMarketplaceCountry.id, values);
      toaster.success(t.translate('Marketplace has been saved successfully.'));
      await fetchMarketplaces();
    } catch (err) {
      setErrors(extractApiErrors(err));
    }
  };

  useEffect(() => {
    if (activeCountryCode && activeMarketplaceCountry) {
      setInitialValues({
        zipCodes: activeMarketplaceCountry.zipCodes,
        surcharges: activeMarketplaceCountry.surcharges,
        isActive: activeMarketplaceCountry.isActive,
        supplierAccountNumber: activeMarketplaceCountry.supplierAccountNumber,
        supplierToken: activeMarketplaceCountry.supplierToken,
      });
    }
  }, [activeCountryCode, activeMarketplaceCountry]);

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    enableReinitialize: true,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  return (
    <>
      {!!marketplaces.length && (
        <Column shrink={!screen.isMobile} filled>
          <Box display="flex" flexDirection="column">
            {marketplaces.map((marketplace, index) => (
              <Grid
                key={index}
                container
                direction="column"
                className={clsx(classes.root, marketplace.country.countryCode === activeCountryCode && classes.activeRoot)}
                onClick={() => setActiveCountryCode(marketplace.country.countryCode)}
              >
                <Grid item>
                  <Typography variant="h6">{t.translate(`country:${marketplace.country.countryCode}`)}</Typography>
                </Grid>
                <ChevronRightIcon className={classes.chevron} />
              </Grid>
            ))}
          </Box>
        </Column>
      )}
      {!!activeMarketplaceCountry && (
        <Column filled p={3} pt={2.5}>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <FormControl error={!!errors.isActive}>
                <FormControlLabel
                  label={t.translate('Sell in this marketplace')}
                  control={
                    <Checkbox
                      id={makeId('isActive')}
                      color="primary"
                      name="isActive"
                      checked={values.isActive}
                      onChange={handleChange}
                    />
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} className={classes.containerWrap}>
              <Grid container alignItems="center" className={classes.marginBottom}>
                <Typography variant="h6">{t.translate('Supplier account')}</Typography>
                <Hint className={classes.helpTextIcon} text={t.translate('This account will be used to update prices.')} />
              </Grid>
              <Grid item xs={3} className={classes.marginBottom}>
                <InputControl
                  id={makeId('supplierAccountNumber')}
                  name="supplierAccountNumber"
                  error={errors.supplierAccountNumber}
                  label={t.translate('Account number')}
                  value={values.supplierAccountNumber ?? ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={3} className={classes.marginBottom}>
                <InputControl
                  id={makeId('supplierToken')}
                  name="supplierToken"
                  error={errors.supplierToken}
                  label={t.translate('Token')}
                  value={values.supplierToken ?? ''}
                  InputComponent={PasswordInput}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid item className={classes.actionsItem}>
              <BusyButton
                submit
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
              >
                {t.translate('Change data')}
              </BusyButton>
            </Grid>
          </Grid>
        </Column>
      )}
    </>
  );
};
