import React from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useList } from 'hooks/list';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';

import { Pagination } from 'components';
import { UserItem } from './UserItem/UserItem';
import { Column } from 'layouts';
import tables from 'config/tables';
import { selectAuthenticatedUser } from 'store/selectors/account';
import { usersDataWithMeta } from 'store/selectors/users';

const useStyles = makeStyles()(theme => ({
  pagination: {
    background: theme.palette.common.white,
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
  },
}));

export const MobileView = () => {
  const history = useHistory();
  const { classes } = useStyles();
  const list = useList(tables.users.users);

  const { data: users, meta } = useSelector(usersDataWithMeta);
  const account = useSelector(selectAuthenticatedUser);

  const openUser = user => history.push(`/settings/users/${user.id}`);
  const openAccount = () => history.push('/settings/account');

  return (
    <>
      <Column p={1} pb={7.5}>
        <Grid container direction="column" spacing={1}>
          {users.map(user => (
            <UserItem
              key={user.id}
              user={user}
              account={account}
              onUserOpen={openUser}
              onAccountOpen={openAccount}
            />
          ))}
        </Grid>
      </Column>

      <Pagination
        {...list.paginationProps}
        count={meta.totalEntities}
        direction="row"
        className={classes.pagination}
      />
    </>
  );
};

MobileView.propTypes = {};
