import { createSelector } from 'reselect';

export const activeCustomer = state => state.customers.activeCustomer;

export const activeCustomerLoading = state => state.customers.activeCustomerLoading;

export const customersLoading = state => state.customers.customers.customersLoading;

export const customersData = state => state.customers.customers.data;

export const customersMeta = state => state.customers.customers.meta;

export const customersDataWithMeta = createSelector([
  customersData,
  customersMeta,
], (data, meta) => ({
  data,
  meta,
}));
