import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useTranslator } from 'i18n';
import { useScreen } from 'hooks';

import { useStyles } from './style';

import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { FilterIcon } from 'icons';

export const TuneBox = ({
  onOpen,
  PopoverProps,
  children,
  isMobile,
  filterUsed,
  ...props
}) => {
  const t = useTranslator();
  const screen = useScreen();
  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const close = () => setAnchorEl(null);
  const open = e => {
    setAnchorEl(e.currentTarget);
    onOpen();
  };

  return <>
    {screen.isMobile ? (
      <IconButton
        variant="contained"
        color="inherit"
        onClick={open}
        aria-label="toggle table tune box"
        className={(filterUsed && classes.filterUsed) || ''}
        size="large">
        <FilterIcon />
      </IconButton>
    ) : (
      <Button
        color="inherit"
        onClick={open}
        aria-label="toggle table tune box"
        startIcon={<FilterIcon />}
        style={{ textTransform: 'none' }}
        className={(filterUsed && classes.filterUsed) || ''}
      >
        {!screen.isMobile && t.translate('Filter')}
      </Button>
    )}
    <Popover
      keepMounted
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      {...PopoverProps}
      className={clsx(PopoverProps.className, classes.popover)}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={close}
    >
      <ArrowDropUpIcon className={classes.arrow} />
      <Box {...props} className={clsx(props.className, classes.box)}>{children}</Box>
    </Popover>
  </>;
};

TuneBox.propTypes = {
  PopoverProps: PropTypes.object,
  onOpen: PropTypes.func,
  filterUsed: PropTypes.bool,
};

TuneBox.defaultProps = {
  PopoverProps: {},
  onOpen: () => {},
};
