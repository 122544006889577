import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';
import { useRoute } from 'hooks';

import { makeStyles } from 'tss-react/mui';
import { withStyles } from 'tss-react/mui';
import Badge from '@mui/material/Badge';

import { Tabs } from 'components';
import { OrderStatusFilter, STUCK_ORDERS } from '../../../../consts';
import { useSelector } from 'react-redux';
import { selectActionPermission } from '../../../../store/selectors/account';

const StyledBadge = withStyles(Badge, theme => ({
  badge: {
    right: -20,
    top: 10,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.l900,
  },
}));

const useStyles = makeStyles()(theme => ({
  appBar: {
    height: theme.spacing(7),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(6),
    },
    width: '100%',
    boxShadow: 'none',
  },
  tab: {
    height: theme.spacing(7),
    paddingLeft: theme.spacing(4.125),
    paddingRight: theme.spacing(4.125),
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(6),
    },
    minWidth: 160,
  },
}));

const generateTab = (name, t) => ({
  name,
  label: (
    <StyledBadge overlap="rectangular">
      {t.translate(`status:${name}`)}
    </StyledBadge>
  ),
});

export const OrdersTabs = React.memo((props) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const route = useRoute();

  const canProcessStuckOrders = useSelector(selectActionPermission('orders', 'processStuckOrders'));

  const tabs = [
    ...Object.values(OrderStatusFilter).map(status => generateTab(status, t)),
    ...(canProcessStuckOrders ? [generateTab(STUCK_ORDERS, t)] : []),
  ];

  return (
    <Tabs
      initialValue={route.search.status || 'open'}
      items={tabs}
      appBar={{ className: classes.appBar, color: 'default' }}
      tabs={{ variant: 'scrollable' }}
      tab={{ className: classes.tab }}
      {...props}
    />
  );
});

OrdersTabs.propTypes = {
  onChange: PropTypes.func.isRequired,
};
