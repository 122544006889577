import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import {
  QrCodeScanButton,
  FormControlError,
} from 'components';

export const QrCodeControl = ({
  onScan,
  loading,
  error,
  label,
  ...props
}) => {
  const disableAutoSubmission = e => {
    if (e.which === 13) {
      e.preventDefault();
    }
  };

  return (
    <FormControl fullWidth error={!!error}>
      <InputLabel shrink htmlFor={props.id}>{label}</InputLabel>
      <Input
        {...props}
        onKeyDown={disableAutoSubmission}
        endAdornment={(
          <>
            {loading && <CircularProgress color="inherit" size={20} />}
            {props.endAdornment}
            <QrCodeScanButton onComplete={onScan || props.onChange} />
          </>
        )}
      />
      {error && <FormControlError error={error} />}
    </FormControl>
  );
};

QrCodeControl.propTypes = {
  onChange: PropTypes.func.isRequired,
  onScan: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.any,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
