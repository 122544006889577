import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardLabel: {
    color: theme.palette.text.itemHeadline,
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(2),
    },
  },
  cardContainer: {
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      '& .MuiGrid-item:not(:first-of-type, :last-child)': {
        borderLeft: `1px solid ${theme.palette.grey[215]}`,
        borderRight: `1px solid ${theme.palette.grey[215]}`,
      },
    },
    [theme.breakpoints.down('lg')]: {
      '& .MuiGrid-item:not(:first-of-type, :last-child)': {
        borderTop: `1px solid ${theme.palette.grey[215]}`,
        borderBottom: `1px solid ${theme.palette.grey[215]}`,
      },
    },
  },
  cardItem: {
    padding: theme.spacing(2),
    '& > div > p:first-of-type': {
      fontWeight: 500,
    },
  },
  multilineValue: {
    whiteSpace: 'pre-line',
  },
}));
