import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';
import {
  useTableSortableHeadCell,
  useList,
} from 'hooks';

import TableContainer from '@mui/material/TableContainer';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';

import {
  Pagination,
  Color,
} from 'components';

import tables from 'config/tables';

const useStyles = makeStyles()(theme => ({
  cardLabel: {
    color: theme.palette.text.itemHeadline,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  tableContainer: {
    '& tr th': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    '& th:first-of-type, & td:first-of-type': {
      paddingLeft: theme.spacing(2),
    },
    '& th:last-child, & td:last-child': {
      paddingRight: theme.spacing(2),
    },
  },
}));

export const ProductsTable = ({ storageProducts }) => {
  const list = useList(tables.storages.storages);
  const { classes } = useStyles();
  const t = useTranslator();

  const { data, meta } = storageProducts;

  const SortableCell = useTableSortableHeadCell({
    changeSorting: list.changeSorting,
    sorting: list.sorting,
  });

  return (
    <>
      <Typography className={classes.cardLabel} variant="body2">{t.translate('Products in box')}</Typography>
      <Paper className={classes.tableContainer}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <SortableCell name="productId">{t.translate('Product-ID')}</SortableCell>
                <SortableCell name="alzuraId">ALZURA-ID</SortableCell>
                <SortableCell name="name">{t.translate('Name')}</SortableCell>
                <SortableCell name="ean">EAN</SortableCell>
                <SortableCell name="stock">{t.translate('Stock')}</SortableCell>
                <SortableCell name="color">{t.translate('Color')}</SortableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((storageProduct, index) => (
                <TableRow key={index}>
                  <TableCell>{storageProduct.product.id}</TableCell>
                  <TableCell>{storageProduct.product.alzuraId || '—'}</TableCell>
                  <TableCell>{storageProduct.product.details[0]?.name || '—'}</TableCell>
                  <TableCell>{storageProduct.product.ean || '—'}</TableCell>
                  <TableCell>{storageProduct.stock || '—'}</TableCell>
                  <TableCell><Color box color={storageProduct.color || 'black'} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination {...list.paginationProps} count={meta.totalEntities} />
      </Paper>
    </>
  );
};

ProductsTable.propTypes = {
  storageProducts: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      storageProduct: PropTypes.shape({
        stock: PropTypes.number,
        color: PropTypes.string,
        product: {
          id: PropTypes.number.isRequired,
          alzuraId: PropTypes.number,
          ean: PropTypes.string,
          description: PropTypes.string,
        },
      }),
    }).isRequired
    ).isRequired,
    meta: PropTypes.shape({
      totalEntities: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};
