import { ORDERS } from 'store/types';
import { orders as is } from 'store/initialState';

const setActiveOrder = (state, order) => ({
  ...state,
  activeOrder: order,
});

const setOrders = (state, { data, meta }) => ({
  ...state,
  data,
  meta: {
    totalEntities: meta.totalEntities,
    lastRetrieved: meta.lastRetrieved,
  },
});

const setLoadingOrders = (state, payload) => ({
  ...state,
  loadingOrders: payload,
});

export default (state = is, { type, payload }) => {
  switch (type) {
    case ORDERS.SET_ACTIVE_ORDER:
      return setActiveOrder(state, payload);
    case ORDERS.SET_ORDERS:
      return setOrders(state, payload);
    case ORDERS.SET_LOADING_ORDERS:
      return setLoadingOrders(state, payload);
    default:
      return state;
  }
};
