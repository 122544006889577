import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';

import { wrapTableRowClick } from 'utils/html';

const useStyles = makeStyles()(theme => ({
  arrowUp: {
    color: theme.palette.primary.l900,
    backgroundColor: 'none',
    zIndex: 99,
  },
  arrowUpBackGround: {
    backgroundColor: theme.palette.backgrounds.secondary,
    width: theme.spacing(4.5),
    height: theme.spacing(6),
    position: 'absolute',
    bottom: theme.spacing(-2.25),
    borderLeft: `1px solid ${theme.palette.backgrounds.grey[225]}`,
    borderRight: `1px solid ${theme.palette.backgrounds.grey[225]}`,
    borderTop: `1px solid ${theme.palette.backgrounds.grey[225]}`,
    borderRadius: theme.spacing(.5, .5, 0, 0),
  },
  arrowUpVoucher: {
    height: theme.spacing(5),
    bottom: theme.spacing(-1.25),
  },
  cardContainer: {
    backgroundColor: theme.palette.backgrounds.secondary,
  },
}));

export const CollapseTableRow = ({
  colSpan,
  collapsedRow,
  children,
  hover,
  onRowClick,
  isOpenCollapsedRow = false,
  isVoucherHistory = false,
}) => {
  const { classes } = useStyles();

  const [open, setOpen] = useState(isOpenCollapsedRow);

  useEffect(() => {
    setOpen(isOpenCollapsedRow);
  }, [isOpenCollapsedRow]);

  return (
    <>
      <TableRow hover={hover} onClick={onRowClick && wrapTableRowClick(() => onRowClick())}>
        <TableCell width={50}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open
              ? <>
                  <KeyboardArrowUpIcon className={classes.arrowUp}/>
                  <Grid className={`${classes.arrowUpBackGround} ${isVoucherHistory ? classes.arrowUpVoucher : ''}`}/>
                </>
              : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      <TableRow>
        <TableCell colSpan={colSpan} style={{ padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container justifyContent="space-between" className={classes.cardContainer}>
              {collapsedRow}
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

CollapseTableRow.propTypes = {
  colSpan: PropTypes.number.isRequired,
  collapsedRow: PropTypes.element.isRequired,
  hover: PropTypes.bool,
  onRowClick: PropTypes.func,
};
