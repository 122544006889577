import React from 'react';
import PropTypes from 'prop-types';

import { OrdersTable } from './OrdersTable/OrdersTable';
import { Column } from 'layouts';

export const DesktopView = ({
  onStockEvaluation,
  onMarkReturnWithoutStock,
  onOrderCancel,
  onApprove,
}) => (
  <Column p={3} fixed style={{ top: '130px' }}>
    <OrdersTable
      onApprove={onApprove}
      onCancel={onOrderCancel}
      onStockEvaluation={onStockEvaluation}
      onMarkReturnWithoutStock={onMarkReturnWithoutStock}
    />
  </Column>
);

DesktopView.propTypes = {
  onOrderCancel: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
};
