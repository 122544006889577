import React from 'react';
import PropTypes from 'prop-types';
import {
  QrCodeScanButton,
  Autocomplete, FormControlError,
} from 'components';
import { searchStorages } from 'api/storages';
import InputLabel from '@mui/material/InputLabel';
import { FormControl } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  clearNone: {
    '& .MuiAutocomplete-clearIndicator': {
      display: 'none',
    },
  },
});

export const StorageReassignAutocomplete = ({
  warehouseId,
  onChange,
  value,
  error,
  label,
  type,
  ...props
}) => {
  const { classes } = useStyles();

  const findOption = v => {
    return v.id.toString() === value.trim();
  };

  const fetchOptions = async () => {
    const { data: storages } = await searchStorages({
      searchQuery: value,
      warehouseId,
      ...(type && { type }),
    });

    return storages;
  };

  return (
    <FormControl error={!!error} fullWidth>
      <InputLabel shrink htmlFor={props.id}>{label}</InputLabel>
      <Autocomplete
        className={classes.clearNone}
        style={{ marginTop: 16 }}
        fullWidth
        error={!!error}
        getOptionLabel={option => option.label}
        fetchOptions={fetchOptions}
        findOption={findOption}
        value={value}
        {...props}
        onChange={onChange}
        InputProps={{
          endAdornment: <QrCodeScanButton onComplete={onChange} />,
        }}
      />
      {error && <FormControlError error={error} />}
    </FormControl>
  );
};

StorageReassignAutocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
