import React from 'react';

import { StatisticsReportsOverview, WarehouseInventoryReportsOverview } from './subPages';
import { SvgIcon } from '@mui/material';
import sprite from 'static/images/alzrua-trade-icon-set.svg';

export const routes = [{
  homePath: '/reports/statistic-reports',
  subPaths: [],
  Page: () => <StatisticsReportsOverview />,
  name: 'statistic-reports',
  label: 'Statistic reports',
}, {
  homePath: '/reports/warehouse-inventory-reports',
  subPaths: [],
  Page: () => <WarehouseInventoryReportsOverview />,
  name: 'warehouse-inventory',
  label: 'Warehouse inventory reports',
}];

export const tabsOptions = [{
    label: 'Statistic reports',
    Icon: (
      <SvgIcon width="24" height="24">
        <use href={sprite + `#assignmentReady`} />
      </SvgIcon>
    ),
    name: '/reports/statistic-reports',
    view: 'statistic-reports',
  }, {
    label: 'Warehouse inventory reports',
    Icon: (
      <SvgIcon width="24" height="24">
        <use href={sprite + `#domain`} />
      </SvgIcon>
    ),
    name: '/reports/warehouse-inventory-reports',
    view: 'warehouse-inventory',
  },
];
