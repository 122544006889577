import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';
import numeral from 'numeral';

import TableContainer from '@mui/material/TableContainer';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Grid from '@mui/material/Grid';
import { useStyles } from '../Tables';

import {
  TableCell,
  TableRow,
  Table,
} from 'components';

export const TotalTable = ({ orders }) => {
  const { classes } = useStyles();
  const t = useTranslator();

  const createRowCreator = t => (label, values, key) => ({ values, label: t.translate(label), key });

  const createRow = createRowCreator(t);
  const rows = [
    createRow('Today', orders, 'daily'),
    createRow('Week', orders, 'weekly'),
    createRow('Month', orders, 'monthly'),
    createRow('Year', orders, 'yearly'),
  ];

  return (
    <TableContainer>
      <Table stickyHeader className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell fixed width={130} name="orders">
              <Grid className={classes.titleFixedColumn}>
                <AssessmentIcon />
                <Typography>{t.translate('Total')}</Typography>
              </Grid>
            </TableCell>
            <TableCell width={100} className={classes.headerCell} name="marginPercents" align="right">
              {t.translate('Margin')}
            </TableCell>
            <TableCell width={100} className={classes.headerCell} name="sumNetValue" align="right">
              {t.translate('Order Value')}
            </TableCell>
            <TableCell width={120} className={classes.headerCell} name="sumNetPurchasePrice" align="right">
              {t.translate('Purchase Value')}
            </TableCell>
            <TableCell width={100} className={classes.headerCell} name="profit" align="right">
              {t.translate('Profit')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((item, index) => (
            <TableRow key={index} className={classes.tableRow}>
              <TableCell className={classes.fixedCell}>
                <Typography>
                  {item.label}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell} align="right" >
                {item.values[item.key]?.marginPercents ? `${numeral(item.values[item.key]?.marginPercents).format('0,0.00')} %` : '—'}
              </TableCell>
              <TableCell className={classes.cell} align="right" >
                {item.values[item.key]?.sumNetValue ? `${numeral(item.values[item.key]?.sumNetValue).format('0,0.00')} €` : '—'}
              </TableCell>
              <TableCell className={classes.cell} align="right" >
                {item.values[item.key]?.sumNetPurchasePrice ? `${numeral(item.values[item.key]?.sumNetPurchasePrice).format('0,0.00')} €` : '—'}
              </TableCell>
              <TableCell className={classes.cell} align="right" >
                {item.values[item.key]?.profit ? `${numeral(item.values[item.key]?.profit).format('0,0.00')} €` : '—'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TotalTable.propTypes = {
  yearly: PropTypes.shape({
    label: PropTypes.string.isRequired,
    marginPercents: PropTypes.string,
    sumNetValue: PropTypes.string,
    sumNetPurchasePrice: PropTypes.string,
    profit: PropTypes.string,
  }),
  monthly: PropTypes.shape({
    label: PropTypes.string.isRequired,
    marginPercents: PropTypes.string,
    sumNetValue: PropTypes.string,
    sumNetPurchasePrice: PropTypes.string,
    profit: PropTypes.string,
  }),
  weekly: PropTypes.shape({
    label: PropTypes.string.isRequired,
    marginPercents: PropTypes.string,
    sumNetValue: PropTypes.string,
    sumNetPurchasePrice: PropTypes.string,
    profit: PropTypes.string,
  }),
  daily: PropTypes.shape({
    label: PropTypes.string.isRequired,
    marginPercents: PropTypes.string,
    sumNetValue: PropTypes.string,
    sumNetPurchasePrice: PropTypes.string,
    profit: PropTypes.string,
  }),
};
