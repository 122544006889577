import React from 'react';
import { useTranslator } from 'i18n';
import { makeStyles } from 'tss-react/mui';
import {
  AlzuraSpriteIcon,
  TextFilterCell,
  ActionButton,
  TableCell,
  TableRow,
} from 'components';
import * as PropTypes from 'prop-types';

const useStyles = makeStyles()(theme => ({
  alignRight: {
    textAlign: 'right',
  },
  row: {
    backgroundColor: theme.palette.backgrounds.secondary,
    '& .MuiTableCell-root': {
      top: theme.spacing(8.125),
    },
    '& input': {
      paddingLeft: theme.spacing(1),
      backgroundColor: theme.palette.backgrounds.grey[250],
    },
  },
  idCell: {
    '&.MuiTableCell-root.MuiTableCell-head': {
      paddingLeft: theme.spacing(1.5),
    },
  },
  button: {
    color: theme.palette.primary.l900,
  },
}));

export const FiltersRow = ({
  filters,
  setFilters,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const filtersProps = {};

  const defaults = {
    id: '',
    manufacturerNumber: '',
    manufacturerName: '',
    name: '',
    ean: '',
  };

  Object.keys(defaults).forEach((colName) => {
    filtersProps[colName] = {
      onChange: (value) => {
        setFilters((prevState) => ({ ...prevState, [colName]: value }));
      },
      value: filters[colName] || defaults[colName],
    };
  });

  const isActiveFilter = Boolean(Object.values(filters).find((v) => v));

  const handleReset = () => {
    setFilters({ ...defaults });
  };

  return (
    <TableRow {...props} className={classes.row}>
      <TableCell />
      <TextFilterCell
        className={classes.idCell}
        {...filtersProps.id}
      />
      <TextFilterCell {...filtersProps.manufacturerNumber} />
      <TextFilterCell {...filtersProps.manufacturerName} />
      <TextFilterCell {...filtersProps.name} />
      <TextFilterCell {...filtersProps.ean} />
      <TableCell align="right">
        <ActionButton
          tooltip={t.translate('Reset filters')}
          icon={<AlzuraSpriteIcon id="reset" />}
          onClick={handleReset}
          className={classes.button}
          disabled={!isActiveFilter}
        />
      </TableCell>
    </TableRow>
  );
};

FiltersRow.propTypes = {
  filters: {
    id: PropTypes.string.isRequired,
    manufacturerNumber: PropTypes.string.isRequired,
    manufacturerName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    ean: PropTypes.string.isRequired,
  },
  setFilters: PropTypes.func.isRequired,
};
