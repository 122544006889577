import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';
import { useHistory } from 'react-router-dom';

import TableContainer from '@mui/material/TableContainer';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import { useStyles } from './StorageSuppliersDialog.jsx';

export const StorageSuppliersDialog = ({
  suppliers,
  onClose,
  isMobileView,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const history = useHistory();

  const openSupplier = id => history.push(`/edit-supplier/${id}`);

  return (
    <Dialog
      {...props}
      scroll="body"
      fullWidth
      fullScreen={isMobileView}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">{t.translate('Suppliers in Storage')} </DialogTitle>
      <DialogContent>
        <Grid container >
          <TableContainer>
            <Table className={classes.cellPadding}>
              <TableHead>
                <TableRow>
                  <TableCell name="number">{t.translate('Supplier number')}</TableCell>
                  <TableCell name="name">{t.translate('Supplier name')}</TableCell>
                  <TableCell name="stock" align="right">{t.translate('Total stock')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {suppliers?.map(({ totalStock, supplier }) => (
                  <TableRow>
                    <TableCell>
                      <Link onClick={() => openSupplier(supplier.id)}>{supplier.number}</Link>
                    </TableCell>
                    <TableCell>{supplier.name}</TableCell>
                    <TableCell align="right">{totalStock}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

StorageSuppliersDialog.propTypes = {
  supplier: PropTypes.shape({
    number: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    stock: PropTypes.number.isRequired,
  }),
  onClose: PropTypes.func.isRequired,
};
