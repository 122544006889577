import React from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  Grid,
  Paper,
} from '@mui/material';

const useStyles = makeStyles()(() => ({
  grid: {
    margin: 20,
  },
  paper: {
    padding: 20,
  },
}));

export const PageWrapper = ({
  children,
}) => {
  const { classes } = useStyles();
  return (
    <Grid container direction="column" className={classes.grid}>
      <Paper className={classes.paper}>
        {children}
      </Paper>
    </Grid>
  );
};
