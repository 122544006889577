import React, { useEffect, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useTranslator } from 'i18n';
import { useList } from 'hooks';

import {
  DesktopView,
  Header,
} from 'views/products/history';

import { fetchStorageProducts, fetchHistory } from 'services/products';
import tables from 'config/tables';

export const ProductHistory = memo(() => {
  const dispatch = useDispatch();
  const { storageId, productId, manufacturingDate } = useParams();
  const list = useList(tables.products.history);
  const t = useTranslator();
  const listParams = list.compile();

  useEffect(() => {
    dispatch(fetchStorageProducts(storageId, productId, manufacturingDate));
  }, [dispatch, storageId, productId, manufacturingDate]);

  useEffect(() => {
    dispatch(fetchHistory(storageId, productId, manufacturingDate, listParams));
  }, [dispatch, storageId, productId, manufacturingDate, listParams]);

  return (
    <>
      <Helmet>
        <title>{t.translate('Products')} | {t.translate('app:title')}</title>
      </Helmet>

      <Header />

      <DesktopView />
    </>
  );
});
