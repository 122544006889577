import statisticsApi from 'api/statistics';
import {
  startLoading,
  stopLoading,
} from 'store/actions/app';
import { setStatistics, setProductFunnel } from 'store/actions/dashboard';

export const fetchStatistics = () => async dispatch => {
  try {
    dispatch(startLoading('dashboard.statistics'));
    const statistics = await statisticsApi.fetchAll();
    const productFunnel = await statisticsApi.fetchProductFunnel();
    dispatch(setStatistics(statistics));
    dispatch(setProductFunnel(productFunnel));
    dispatch(stopLoading('dashboard.statistics'));
  } catch (err) {
    dispatch(stopLoading('dashboard.statistics'));
  }
};
