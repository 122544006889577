import { REPORTS } from 'store/types';
import { reports as is } from 'store/initialState';

const setOverviewStatisticsReports = (state, { data, meta }) => ({
  ...state,
  overview: {
    ...state.overview,
    statisticsReports: {
      data,
      meta: {
        ...state.overview.statisticsReports.meta,
        ...meta,
      },
    },
  },
});

const setOverviewWarehouseInventoryReports = (state, { data, meta }) => ({
  ...state,
  overview: {
    ...state.overview,
    warehouseInventoryReports: {
      data,
      meta: {
        ...state.overview.warehouseInventoryReports.meta,
        ...meta,
      },
    },
  },
});

export default (state = is, { type, payload }) => {
  switch (type) {
    case REPORTS.OVERVIEW.SET_STATISTICS_REPORTS:
      return setOverviewStatisticsReports(state, payload);
    case REPORTS.OVERVIEW.SET_WAREHOUSE_INVENTORY_REPORTS:
      return setOverviewWarehouseInventoryReports(state, payload);
    default:
      return state;
  }
};
