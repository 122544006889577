import React from 'react';
import PropTypes from 'prop-types';
import printJs from 'print-js';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { ScanningButton } from '../ScanningButton/ScanningButton';
import { makeSourceLink } from 'services/files';

const useStyles = makeStyles()({
  button: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    textTransform: 'initial',
    whiteSpace: 'nowrap',
  },
});

export const DesktopControls = ({
  isHandheldScannerDisabled,
  onPickupSheetDelete,
  onPickupSheetPrint,
  onNextStepScan,
  canDeletePickupSheet,
  canPrintPickupSheet,
  canScanPickupSheet,
  pickupSheet,
  fetchData,
  packingDialog,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <>
      {canScanPickupSheet && (
        pickupSheet?.status === 'open'
        || pickupSheet?.status === 'packed'
        || pickupSheet?.status === 'labelled'
      ) && (
        <Grid item>
          <ScanningButton
            isHandheldScannerDisabled={isHandheldScannerDisabled}
            fetchData={fetchData}
            onNextStepScan={onNextStepScan}
            title={t.translate('Scan')}
            packingDialog={packingDialog}
          />
        </Grid>
      )}
      {canPrintPickupSheet && (
        <Grid item>
          <Button
            color="inherit"
            className={classes.button}
            startIcon={<PrintIcon />}
            onClick={onPickupSheetPrint}
          >
            {t.translate('Print')}
          </Button>
        </Grid>
      )}
      {canDeletePickupSheet && pickupSheet?.status === 'open' && (
        <Grid item>
          <Button
            color="inherit"
            className={classes.button}
            startIcon={<DeleteIcon />}
            onClick={onPickupSheetDelete}
          >
            {t.translate('Delete')}
          </Button>
        </Grid>
      )}
      {pickupSheet?.packingLabelsDocument && (
        <Grid item>
          <Button
            color="inherit"
            className={classes.button}
            startIcon={<InsertDriveFileIcon />}
            onClick={() => printJs(makeSourceLink(pickupSheet.packingLabelsDocument.filePath))}
          >
            {t.translate('Packing label')}
          </Button>
        </Grid>
      )}
    </>
  );
};

DesktopControls.propTypes = {
  isHandheldScannerDisabled: PropTypes.bool.isRequired,
  onPickupSheetDelete: PropTypes.func.isRequired,
  onPickupSheetPrint: PropTypes.func.isRequired,
  canDeletePickupSheet: PropTypes.bool.isRequired,
  canPrintPickupSheet: PropTypes.bool.isRequired,
  canScanPickupSheet: PropTypes.bool.isRequired,
  pickupSheet: PropTypes.object,
};
