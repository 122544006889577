import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { makeStyles } from 'tss-react/mui';
import Chip from '@mui/material/Chip';
import { useTranslator } from '../../i18n';
import { OrderStatuses } from '../../consts';

const useStyles = makeStyles()(theme => ({
  chip: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(.5),
    borderRadius: theme.spacing(0.5),
    height: '26px',
    '& .MuiChip-label': {
      color: theme.palette.common.white,
      paddingLeft: theme.spacing(.75),
    },
  },
}));

const statusColors = {
  [OrderStatuses.QUEUE]: '#3498DB',
  [OrderStatuses.PROCESSING]: '#34219E',
  orderedAtSupplier: '#3498DB',
  inReview: '#E74C3C',
  overdue: '#ff9800',
  returned: '#bd59d7',
  [OrderStatuses.COMPLETED]: '#2ECC71',
  [OrderStatuses.DENIED]: '#2C3E50',
  [OrderStatuses.SOLD_OUT]: '#2C3E50',
  [OrderStatuses.PARTIALLY_CANCELLED]: '#2C3E50',
  [OrderStatuses.CANCELLED_BY_SELLER]: '#2C3E50',
  [OrderStatuses.CANCELLED_BY_BUYER]: '#2C3E50',
  [OrderStatuses.CLOSED]: '#2ECC71',
  [OrderStatuses.DAMAGED_OR_LOST]: '#2C3E50',
};

const labels = {
  [OrderStatuses.QUEUE]: 'status:queue',
  [OrderStatuses.PROCESSING]: 'status:processing',
  orderedAtSupplier: 'status:ordered-at-supplier',
  inReview: 'status:in-review',
  overdue: 'status:overdue',
  [OrderStatuses.COMPLETED]: 'status:completed',
  [OrderStatuses.DENIED]: 'status:denied',
  [OrderStatuses.SOLD_OUT]: 'status:sold-out',
  [OrderStatuses.PARTIALLY_CANCELLED]: 'status:partially-cancelled',
  [OrderStatuses.CANCELLED_BY_SELLER]: 'status:cancelled-by-seller',
  [OrderStatuses.CANCELLED_BY_BUYER]: 'status:cancelled-by-buyer',
  [OrderStatuses.CLOSED]: 'status:closed',
  [OrderStatuses.DAMAGED_OR_LOST]: 'status:damaged-or-lost',
  returned: 'status:returned',
};

const getStatusInfo = (({ statusCode, inReview, orderedAtSupplier, positions, hasReturns }) => {
  const today = dayjs.utc().startOf('day');
  const isOlderOrder = positions.some(position => dayjs.utc(position.estimatedDelivery) < today);

  const key = hasReturns
    ? 'returned'
    : inReview
      ? 'inReview'
      : orderedAtSupplier
        ? isOlderOrder ? 'overdue' : 'orderedAtSupplier'
        : statusCode;

  return {
    label: labels[key],
    statusColor: statusColors[key],
  };
});

export const OrderStatusBadge = ({ order, ...props }) => {
  const { classes } = useStyles();
  const t = useTranslator();
  const { label, statusColor } = getStatusInfo(order);

  return (
    <Chip
      {...props}
      color={'default'}
      label={t.translate(label)}
      className={clsx(classes.chip, props.className)}
      style={{ backgroundColor: statusColor }}
    />
  );
};

OrderStatusBadge.propTypes = {
  order: PropTypes.object.isRequired,
};
