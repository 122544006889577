import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { useTranslator } from 'i18n';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import Collapse from '@mui/material/Collapse';
import PrintIcon from '@mui/icons-material/Print';
import Divider from '@mui/material/Divider';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import {
  ActionButton,
  CardFeature,
  Color,
  BooleanIcon,
} from 'components';
import { styleMobileTableItem } from 'theme/mixins/table';

const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  paper: {
    padding: 0,
  },
  volumeValue: {
    marginTop: theme.spacing(-.5),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  headline: {
    background: theme.palette.primary.l10,
    padding: theme.spacing(1, 2),
  },
  cardContainer: {
    padding: theme.spacing(1, 2),
  },
  featureLabel: {
    fontWeight: 500,
  },
  buttonsContainer: {
    margin: theme.spacing(1, 0),
    marginTop: theme.spacing(2),
  },
  button: {
    backgroundColor: theme.palette.primary.l10,
    color: theme.palette.primary.main,
    fontSize: '0.75rem',
    textTransform: 'none',
  },
  iconButton: {
    color: theme.palette.primary.l900,
  },
}));

export const StorageItem = ({
  onStorageDelete,
  onStoragePrint,
  onStorageEdit,
  canDeleteStorage,
  canEditStorage,
  storage,
}) => {
  const history = useHistory();
  const t = useTranslator();
  const { classes } = useStyles();

  const [open, setOpen] = useState(false);

  const openStorage = storage => history.push(`/storages/${storage.id}`);

  return (
    <Grid item>
      <Paper className={classes.paper}>
        <Grid container direction="column">
          <Grid container justifyContent="space-between" className={classes.headline}>
            <Grid item >
              <Typography variant="h6">ID: {storage.id}</Typography>
            </Grid>
            <Grid item >
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open
                  ? <KeyboardArrowDownIcon />
                  : <KeyboardArrowRightIcon />}
              </IconButton>
            </Grid>
          </Grid>
          <Grid item container direction="column" className={classes.cardContainer}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Grid item xs p={2} >
                <Grid container direction="column">
                  <CardFeature
                    label={t.translate('Warehouse')}
                    value={storage.warehouse.name}
                    justifyContent="space-between"
                    classes={{ label: classes.featureLabel }}
                  />
                  <Grid justifyContent="space-between" item container>
                    <Grid item>
                      <Typography variant="body2" className={classes.featureLabel}>{t.translate('Type')}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">{t.translate(`storageType:${storage.type}`)}</Typography>
                    </Grid>
                  </Grid>
                  <Grid justifyContent="space-between" item container>
                    <Grid item>
                      <Typography variant="body2" className={classes.featureLabel}>{t.translate('Exclude from available stock')}</Typography>
                    </Grid>
                    <Grid item>
                      <BooleanIcon truthy={storage.excludeStock} />
                    </Grid>
                  </Grid>
                  <CardFeature
                    label={t.translate('Building')}
                    value={storage.building}
                    justifyContent="space-between"
                    classes={{ label: classes.featureLabel }}
                  />
                  {storage.room && (
                    <CardFeature
                      label={t.translate('Floor')}
                      value={storage.room}
                      justifyContent="space-between"
                      classes={{ label: classes.featureLabel }}
                    />
                  )}
                  {storage.row && (
                    <CardFeature
                      label={t.translate('Row')}
                      value={storage.row}
                      justifyContent="space-between"
                      classes={{ label: classes.featureLabel }}
                    />
                  )}
                  {storage.shelf && (
                    <CardFeature
                      label={t.translate('Shelf')}
                      value={storage.shelf}
                      justifyContent="space-between"
                      classes={{ label: classes.featureLabel }}
                    />
                  )}
                  {storage.level && (
                    <CardFeature
                      label={t.translate('Level')}
                      value={storage.level}
                      justifyContent="space-between"
                      classes={{ label: classes.featureLabel }}
                    />
                  )}
                  {storage.boxNumber && (
                    <CardFeature
                      label={t.translate('Box number')}
                      value={storage.boxNumber}
                      justifyContent="space-between"
                      classes={{ label: classes.featureLabel }}
                    />
                  )}
                  <CardFeature
                    label={t.translate('Box color')}
                    value={storage.boxColor ? <Color box color={storage.boxColor} /> : <Color box color={'black'} />}
                    justifyContent="space-between"
                    classes={{ label: classes.featureLabel }}
                  />
                  {storage.width && (
                    <CardFeature
                      label={t.translate('Width')}
                      value={storage.width !== null ? `${numeral(storage.width).format('0.00')} cm` : '—'}
                      justifyContent="space-between"
                      classes={{ label: classes.featureLabel }}
                    />
                  )}
                  {storage.height && (
                    <CardFeature
                      label={t.translate('Height')}
                      value={storage.height !== null ? `${numeral(storage.height).format('0.00')} cm` : '—'}
                      justifyContent="space-between"
                      classes={{ label: classes.featureLabel }}
                    />
                  )}
                  {storage.length && (
                    <CardFeature
                      label={t.translate('Length')}
                      value={storage.length !== null ? `${numeral(storage.length).format('0.00')} cm` : '—'}
                      justifyContent="space-between"
                      classes={{ label: classes.featureLabel }}
                    />
                  )}
                  <Divider className={classes.divider}/>
                </Grid>
              </Grid>
            </Collapse>
            {storage.room && (
              <CardFeature
                label={t.translate('Room')}
                value={storage.room}
                justifyContent="space-between"
                classes={{ label: classes.featureLabel }}
              />
            )}
            {storage.volume && (
              <CardFeature
                label={t.translate('Volume')}
                value={storage.volume !== null ? <>{numeral(storage.volume).format('0.000000')} m<sup>3</sup></> : '—'}
                justifyContent="space-between"
                classes={{ label: classes.featureLabel }}
              />
            )}
            <Grid container justifyContent="space-between" className={classes.buttonsContainer}>
                <Button
                  className={classes.button}
                  startIcon={<InfoIcon />}
                  onClick={() => openStorage(storage)}
                >
                  {t.translate('Details')}
                </Button>
              <Grid item>
                <ActionButton
                  aria-label={t.translate('Print storage')}
                  tooltip={t.translate('Print storage')}
                  className={classes.iconButton}
                  icon={<PrintIcon />}
                  onClick={() => {
                    onStoragePrint(storage);
                  }}
                />
                {canEditStorage && (
                  <ActionButton
                    aria-label={t.translate('Edit storage')}
                    tooltip={t.translate('Edit storage')}
                    className={classes.iconButton}
                    icon={<EditIcon />}
                    onClick={() => {
                      onStorageEdit(storage);
                    }}
                  />
                )}
                {canDeleteStorage && (
                  <ActionButton
                    aria-label={t.translate('Delete storage')}
                    tooltip={t.translate('Delete storage')}
                    className={classes.iconButton}
                    icon={<DeleteIcon />}
                    onClick={() => {
                      onStorageDelete(storage);
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

StorageItem.propTypes = {
  onStorageDelete: PropTypes.func.isRequired,
  onStoragePrint: PropTypes.func.isRequired,
  onStorageEdit: PropTypes.func.isRequired,
  canDeleteStorage: PropTypes.bool.isRequired,
  canEditStorage: PropTypes.bool.isRequired,
  storage: PropTypes.shape({
    warehouse: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    type: PropTypes.string.isRequired,
    excludeStock: PropTypes.bool,
    boxNumber: PropTypes.string,
    boxColor: PropTypes.string,
    building: PropTypes.string.isRequired,
    floor: PropTypes.string,
    shelf: PropTypes.string,
    level: PropTypes.string,
    room: PropTypes.string,
    row: PropTypes.string,
    volume: PropTypes.number,
    length: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    id: PropTypes.number.isRequired,
  }).isRequired,
};
