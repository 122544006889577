import { useReducer, useMemo } from 'react';
import { pack } from 'store/utils';
import axios from 'axios';

const initialState = {
  cancelSources: {},
  counter: 0,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'set-cancel-source':
      return {
        ...state,
        cancelSources: {
          ...state.cancelSources,
          [payload.key]: payload.cancelSource,
        },
      };
    case 'increment-counter':
      return {
        ...state,
        counter: state.counter + 1,
      };
    default:
      return state;
  };
};

const setCancelSource = (key, cancelSource) => dispatch => {
  dispatch(pack('set-cancel-source', { key, cancelSource }));
  dispatch(pack('increment-counter'));
};

export const useCancelSource = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return useMemo(() => {
    const issueToken = (key = -1) => {
      if (state.cancelSources[key]) {
        state.cancelSources[key].cancel('Repeated request.');
      }
      const source = axios.CancelToken.source();
      setCancelSource(key, source)(dispatch);
      return source.token;
    };

    const cancel = (key = -1) => reason => {
      if (state.cancelSources[key]) {
        state.cancelSources[key].cancel(reason);
        setCancelSource(key, null)(dispatch);
      }
    };

    const clear = (key = -1) => err => {
      if (!err || !axios.isCancel(err)) {
        setCancelSource(key, null)(dispatch);
      }
    };

    return {
      issueToken,
      cancel,
      clear,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, state.counter]);
};
