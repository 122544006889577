import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';

const useStyles = makeStyles()({
  root: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
});

export const Scroll = ({ children, ...props }) => {
  const { classes } = useStyles();
  return <Box {...props} className={classes.root}>{children}</Box>;
};
