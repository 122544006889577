import api from '.';
import {
  machinizePagination,
  extractSorting,
} from '../utils/querying';

export const fetchMany = ({
  searchQuery,
  meta,
  ...params
}) => api.get('/customers', {
  params: {
    ...machinizePagination(params),
    ...extractSorting(params),
    ...(searchQuery ? { searchQuery } : {}),
    ...(meta ? { meta } : {}),
  },
});

export const fetchOne = ({
  searchQuery,
  meta,
  id,
  ...params
}) => api.get(`/customers/${id}`, {
  params: {
    ...machinizePagination(params),
    ...extractSorting(params),
    ...(searchQuery ? { searchQuery } : {}),
    ...(meta ? { meta } : {}),
  },
});

export const exportOne = customerId => api.get(`/customers/${customerId};exported`);

export const modifyCustomer = (customerId, payload) => api.put(`/customers/${customerId}`, payload);

export default {
  fetchMany,
  fetchOne,
  exportOne,
  modifyOne: modifyCustomer,
};
