import { CUSTOMERS } from '../types';
import { pack } from '../utils';

export const setActiveCustomer = customer => dispatch => dispatch(pack(CUSTOMERS.SET_ACTIVE_CUSTOMER, customer));

export const setActiveCustomerLoading = payload => dispatch => dispatch(pack(CUSTOMERS.SET_ACTIVE_CUSTOMER_LOADING, payload));

export const setCustomers = customers => dispatch => dispatch(pack(CUSTOMERS.SET_CUSTOMERS, customers));

export const setCustomersLoading = payload => dispatch => dispatch(pack(CUSTOMERS.SET_CUSTOMERS_LOADING, payload));
