import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';

import TablePagination from 'overrides/@mui/material/TablePagination/TablePagination';
import { makeStyles } from 'tss-react/mui';

import {
  didUpdate,
} from 'hooks';

const useStyles = makeStyles()({
  row: {
    '& .MuiTablePagination-spacer': {
      display: 'none',
    },
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  selectRoot: {
    marginRight: 8,
  },
});

export const Pagination = ({
  onRowsPerPageChange,
  onPageChange,
  direction,
  count,
  page,
  ...props
}) => {
  const t = useTranslator();
  const { classes, cx } = useStyles();

  didUpdate(() => {
    onPageChange(null, 0);
  }, [count]);

  const labelDisplayedRows = ({ from, to, count }) => t.translate('{from}-{to} of {count}', {
    count: count === -1
      ? t.translate('more than {to}', { to })
      : count,
    from,
    to,
  });

  const changeRowsPerPage = ({ target: { value } }) => {
    onRowsPerPageChange(value);
    localStorage.setItem('pagination.rows_per_page', value);
  };

  return (
    <TablePagination
      labelDisplayedRows={labelDisplayedRows}
      labelRowsPerPage={t.translate('Rows per page') + ':'}
      component="div"
      count={count}
      page={count ? page : 0}
      onRowsPerPageChange={changeRowsPerPage}
      onPageChange={onPageChange}
      {...props}
      className={cx(props.className, direction === 'row' && classes.row)}
      classes={{
        root: classes.root,
        selectRoot: classes.selectRoot,
      }}
    />
  );
};

Pagination.propTypes = {
  onRowsPerPageChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  direction: PropTypes.oneOf(['row', 'row-reverse']),
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
};
