import { makeStyles } from 'tss-react/mui';
import { styleMobileTableItem } from 'theme/mixins/table';

export const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  item: {
    background: theme.palette.common.white,
    marginTop: theme.spacing(1),
    '&:last-child': {
      marginBottom: theme.spacing(3),
    },
  },
  accordion: {
    background: theme.palette.backgrounds.main['50'],
    boxShadow: 'none',
    '& .MuiCollapse-root': {
      background: theme.palette.common.white,
    },
  },
  contentItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  photo: {
    flexShrink: 0,
    height: '35px',
    margin: theme.spacing(2, 0, 1.5),
    marginRight: theme.spacing(1),
    width: '35px',
  },
  button: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.l10,
    textTransform: 'none',
  },
  storageInfo: {
    margin: theme.spacing(2, 0),
  },
  bold: {
    fontWeight: 500,
  },
  doneIcon: {
    color: theme.palette.green.main,
  },
}));
