import React from 'react';
import { useTranslator } from 'i18n';
import { useLoading } from 'hooks';

import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';
import { Fab } from 'components';

export const ClosingButton = ({ onClick }) => {
  const t = useTranslator();
  const loading = useLoading();

  return (
    <Fab
      variant="extended"
      onClick={onClick}
      aria-labelledby={t.translate('Finish the pickup sheet')}
    >
      {loading.progress
        ? <CircularProgress color="inherit" size={24} />
        : <SendIcon />}
      {t.translate('Finish')}
    </Fab>
  );
};
