import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, Switch, Route, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslator } from 'i18n';
import { useScreen } from 'hooks';

import { Box } from '@mui/material';

import { Header } from 'views/settings';
import { ViewSwitch } from 'components';
import { routes, tabsOptions } from './config';
import {
  isAuthenticatedUserAdministrator,
  selectPermissions,
} from 'store/selectors/account';

const setPageTitle = (pathname) => {
  if (pathname.includes('users')) {
    return 'Users administration';
  } else if (pathname.includes('account')) {
    return 'My account';
  } else if (pathname.includes('settings')) {
    return 'Settings';
  } else {
    return 'app:title';
  }
};

export const Settings = () => {
  const history = useHistory();
  const screen = useScreen();
  const t = useTranslator();
  const isAdmin = useSelector(isAuthenticatedUserAdministrator);
  const permissions = useSelector(selectPermissions);

  const matchWithDetails = useRouteMatch(routes.filter(route => route.details).map(route => route.homePath));

  const shouldShowMenuItem = name => isAdmin || permissions[name] === undefined || permissions[name].view;
  const allowedRoutes = routes.filter(({ name }) => isAdmin || permissions[name] === undefined || permissions[name].view);
  let activeRoute = !screen.isMobile ? (
    allowedRoutes.find(({ homePath, subPaths }) => homePath === history.location.pathname || subPaths.includes(history.location.pathname) || homePath === matchWithDetails?.path)
    || allowedRoutes[0]
  ) : allowedRoutes.find(({ homePath, subPaths }) => homePath === history.location.pathname || subPaths.includes(history.location.pathname) || homePath === matchWithDetails?.path);

  const { homePath } = activeRoute || { homePath: '' };

  const hasMenu = useRouteMatch(homePath)?.isExact;

  const allowedOptions = tabsOptions.filter(option => {
    const isCompaniesPermissionDefined = permissions.settings?.companies !== undefined;
    return !(isCompaniesPermissionDefined && (option.view === 'companies' || option.view === 'marketplaces')) && shouldShowMenuItem(option.view);
  });
  const activeOption = allowedOptions.find(({ name }) => history.location.pathname.includes(name))?.name;

  const isCompaniesLayout = matchWithDetails?.path.includes('company');

  if (isCompaniesLayout) {
    return (
      <>
        <Helmet>
          <title>{t.translate(setPageTitle(history.location.pathname))}</title>
        </Helmet>

        <Switch>
          {Object.entries(allowedRoutes).map(([key, { name, homePath, subPaths, Page }]) => (
            shouldShowMenuItem(name) && (
              <Route key={key} exact path={[homePath, ...subPaths]}>
                <Page />
              </Route>
            )
          ))}
        </Switch>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t.translate(setPageTitle(history.location.pathname))}</title>
      </Helmet>

      <Header
        isMobileView={screen.isMobile}
        routeTitle={activeRoute?.label}
        hasMenu={hasMenu}
        options={allowedOptions}
        activeOption={activeOption}
        activeRouteHome={homePath}
        userDetailsOpened={matchWithDetails}
      />

      <ViewSwitch options={allowedOptions} isMobile={screen.isMobile} activeOption={activeOption} />

      <Box display="flex" flexGrow={1} width={1}>
        <Switch>
          {Object.entries(allowedRoutes).map(([key, { name, homePath, subPaths, Page }]) => (
            shouldShowMenuItem(name) && (
              <Route key={key} exact path={[homePath, ...subPaths]}>
                <Page/>
              </Route>
            )
          ))}
        </Switch>
      </Box>
    </>
  );
};
