import config from 'config';
import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import { TrackingLink } from  'components';
import { selectPositionStatus } from '../../../utils';

const useStyles = makeStyles()(theme => ({
  fontBold: {
    fontWeight: 500,
    marginBottom: theme.spacing(.5),
  },
  cardItem: {
    padding: theme.spacing(2),
  },
}));

export const CollapsedRow = ({ position, order }) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const fahrzeugAttirbute = position.attributes?.find(attribute => attribute.generic_key === 'fahrzeug');
  const vehicleAttirbute = position.attributes?.find(attribute => attribute.generic_key === 'vehicleinformation');

  return (
    <>
      <Grid container direction="column" item className={classes.cardItem} >
        {order.supplier.externalSupplier && (
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography className={classes.fontBold} variant="body2">{t.translate('Supplier number')}</Typography>
            </Grid>
            <Grid item>
              <Link
                href={`${config.tyre24BackOfficeUrl}/customerManagement.php?action=editcustomer&wholesaler=1&id=${order.supplier.externalSupplier}`}
                underline="hover"
                target="_blank"
                rel="noopener"
              >
                {order.supplier.externalSupplier || '—'}
              </Link>
            </Grid>
          </Grid>
        )}
        {position.estimatedDelivery && (
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography className={classes.fontBold} variant="body2">{t.translate('Estimated delivery')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{position.estimatedDelivery ? t.formatDate(position.estimatedDelivery) : '—'}</Typography>
            </Grid>
          </Grid>
        )}
        {(fahrzeugAttirbute || vehicleAttirbute) && (
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography className={classes.fontBold} variant="body2">{t.translate(fahrzeugAttirbute?.key || vehicleAttirbute?.key)}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{(fahrzeugAttirbute?.value || vehicleAttirbute?.value) || '—'}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid container item direction="column" className={classes.cardItem}>
        {position.tracking && (
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography className={classes.fontBold} variant="body2">{t.translate('Tracking')}</Typography>
            </Grid>
            <Grid item>
              <TrackingLink tracking={position.tracking || []} />
            </Grid>
          </Grid>
        )}
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography className={classes.fontBold} variant="body2">{t.translate('Status')}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{t.translate(selectPositionStatus(position.externalOrderStatus, order.supplier.handlingType, order.inReview).text) || '—'}</Typography>
          </Grid>
        </Grid>
        {position.reason && (<Grid container justifyContent="space-between">
            <Grid item>
              <Typography className={classes.fontBold} variant="body2">{t.translate('Reason')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{position.reason || '—'}</Typography>
            </Grid>
          </Grid>)}
      </Grid>
    </>
  );
};

CollapsedRow.propTypes = {
  position: PropTypes.shape({
    externalOrderStatus: PropTypes.number,
    tracking: PropTypes.array,
    estimatedDelivery: PropTypes.string,
    attributes: PropTypes.array.isRequired,
  }).isRequired,
  order: PropTypes.shape({
    inReview: PropTypes.bool.isRequired,
    supplier: PropTypes.shape({
      externalSupplier: PropTypes.string,
      handlingType: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
