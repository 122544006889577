import React from 'react';
import PropTypes from 'prop-types';
import MuiTableCell from '@mui/material/TableCell';
import clsx from 'clsx';

export const TableCell = ({
  width,
  right,
  fixed,
  left,
  children,
  ...props
}) => {
  const styles = {
    ...(width && {
      minWidth: `${width}px`,
      maxWidth: `${width}px`,
    }),
    ...(right !== undefined && { right: `${right}px` }),
    ...(left !== undefined && { left: `${left}px` }),
  };

  const className = clsx(props.className, fixed && 'TableCell-fixed');

  return (
    <MuiTableCell
      {...props}
      {...(className && { className })}
      style={styles}
    >
      {children}
    </MuiTableCell>
  );
};

TableCell.propTypes = {
  width: PropTypes.number,
  right: PropTypes.number,
  fixed: PropTypes.oneOfType([
    PropTypes.oneOf(['left', 'right']),
    PropTypes.bool,
  ]),
  left: PropTypes.number,
};
