import { STORAGES } from 'store/types';
import { storages as is } from 'store/initialState';

const setOverviewWarehouses = (state, warehouses) => ({
  ...state,
  overview: {
    ...state.overview,
    warehouses,
  },
});

const setOverviewStorages = (state, { data, meta }) => ({
  ...state,
  overview: {
    ...state.overview,
    storages: {
      data,
      meta: {
        ...state.overview.storages.meta,
        ...meta,
      },
    },
  },
});

const setStorage = (state, storage) => ({
  ...state,
  details: {
    ...state.details,
    storage,
  },
});

const setStorageProducts = (state, { data, meta }) => ({
  ...state,
  details: {
    ...state.details,
    storageProducts: {
      data,
      meta: {
        totalEntities: meta.totalEntities,
      },
    },
  },
});

const clearStorageProducts = state => ({ ...state, details: { ...state.details, storageProducts: is.details.storageProducts } });

export default (state = is, { type, payload }) => {
  switch (type) {
    case STORAGES.OVERVIEW.SET_WAREHOUSES:
      return setOverviewWarehouses(state, payload);
    case STORAGES.OVERVIEW.SET_STORAGES:
      return setOverviewStorages(state, payload);
    case STORAGES.DETAILS.SET_STORAGE:
      return setStorage(state, payload);
    case STORAGES.DETAILS.SET_STORAGE_PRODUCTS:
      return setStorageProducts(state, payload);
    case STORAGES.DETAILS.CLEAR_STORAGE_PRODUCTS:
      return clearStorageProducts(state);
    default:
      return state;
  }
};
