import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { ScanningButton } from '../ScanningButton/ScanningButton';
import { download } from 'services/files';

const useStyles = makeStyles()({
  iconButton: {
    padding: 0,
  },
});

export const MobileControls = ({
  isHandheldScannerDisabled,
  onPickupSheetDelete,
  onPickupSheetPrint,
  canDeletePickupSheet,
  canPrintPickupSheet,
  canScanPickupSheet,
  pickupSheet,
  fetchData,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (action) => {
    setAnchorEl(null);
    if (action) {
      action();
    }
  };

  return <>
    <IconButton
      color="inherit"
      aria-label="more"
      aria-controls="long-menu"
      aria-haspopup="true"
      onClick={handleClick}
      className={classes.iconButton}
      size="large">
      <MoreVertIcon />
    </IconButton>
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => handleClose()}
      PaperProps={{
        style: {
          width: '20ch',
        },
      }}
    >
      {canScanPickupSheet && pickupSheet?.status === 'open' && (
        <ScanningButton isHandheldScannerDisabled={isHandheldScannerDisabled} fetchData={fetchData} title={t.translate('Scan')} menuItem isMobile />
      )}
      {canPrintPickupSheet && (
        <MenuItem onClick={() => handleClose(onPickupSheetPrint)}>
          <ListItemIcon>
            <PrintIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t.translate('Print')}</Typography>
        </MenuItem>
      )}
      {canDeletePickupSheet && pickupSheet?.status === 'open' && (
        <MenuItem onClick={() => handleClose(onPickupSheetDelete)}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t.translate('Delete')}</Typography>
        </MenuItem>
      )}
      {pickupSheet?.packingLabelsDocument && (
        <MenuItem onClick={() => handleClose(download(pickupSheet.packingLabelsDocument))}>
          <ListItemIcon>
            <InsertDriveFileIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t.translate('Packing label')}</Typography>
        </MenuItem>
      )}
    </Menu>
  </>;
};

MobileControls.propTypes = {
  isHandheldScannerDisabled: PropTypes.bool.isRequired,
  onPickupSheetDelete: PropTypes.func.isRequired,
  onPickupSheetPrint: PropTypes.func.isRequired,
  canDeletePickupSheet: PropTypes.bool.isRequired,
  canPrintPickupSheet: PropTypes.bool.isRequired,
  canScanPickupSheet: PropTypes.bool.isRequired,
  pickupSheet: PropTypes.object,
};
