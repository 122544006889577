import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PublishIcon from '@mui/icons-material/Publish';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';

const useStyles = makeStyles()(() => ({
  button: {
    overflow: 'hidden',
  },
  input: {
    bottom: 0,
    cursor: 'pointer',
    opacity: 0,
    position: 'absolute',
    top: 0,
    visibility: ({ isInputHidden }) => isInputHidden ? 'hidden' : 'visible',
    width: '100%',
  },
}));

export const FileUpload = ({
  onUploaded = () => {},
  onUpLoading = () => {},
  onError = () => {},
  disabled,
  children,
  Icon,
  noIcon,
  inputProps,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleUpload = e => {
    try {
      setLoading(true);
      onUpLoading();
      const data = new FormData();
      data.set('file', e.target.files[0]);
      onUploaded(data, () => setIsError(true));
      e.target.value = null;
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setIsError(true);
      onError(err);
    }
  };

  const { classes, cx } = useStyles({
    isInputHidden: loading,
  });

  return (
    <Button
      {...props}
      className={cx(props.className, classes.button)}
      startIcon={
        !loading && !isError
          ? !noIcon && (Icon || <PublishIcon />)
          : loading
            ? <CircularProgress size={24} />
            : isError
              ? <ClearIcon />
              : <DoneIcon />
      }
      disabled={disabled || loading}
      onClick={() => setIsError(false)}
    >
      <input
        { ...inputProps }
        className={classes.input}
        type="file"
        name="file"
        onChange={handleUpload}
      />
        {children}
    </Button>
  );
};

FileUpload.propTypes = {
  onUploaded: PropTypes.func,
  onUpLoading: PropTypes.func,
  onError: PropTypes.func,
  disabled: PropTypes.bool,
  noIcon: PropTypes.bool,
  inputProps: PropTypes.object,
};
