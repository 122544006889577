import React from 'react';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslator } from 'i18n';
import withAuthentication from 'hocs/authentication';

const Component = ({ children }) => {
  const t = useTranslator();

  return (
    <>
      <Helmet>
        <title>{t.translate('app:title')}</title>
      </Helmet>
      <Box display="flex" justifyContent="center" width={1}>{children}</Box>
    </>
  );
};

export const Base = withAuthentication(Component);
