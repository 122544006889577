import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import {
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Table,
} from '@mui/material';

import { useTranslator } from 'i18n';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  fontBold: {
    fontWeight: 500,
  },
  cardItem: {
    padding: theme.spacing(2),
  },
}));

export const CollapsedRow = ({ positions }) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <TableContainer>
      <Table className={classes.cardItem}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.fontBold} colSpan={2}>{`${t.translate('Type')}`}</TableCell>
            <TableCell className={classes.fontBold} colSpan={2}>{`${t.translate('Fee')}`}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {positions.map((position) => (
            <TableRow key={position.id}>
              <TableCell colSpan={2}>{position.articleNo}</TableCell>
              <TableCell colSpan={2}>{numeral(Number(position.price) * Number(position.quantity)).format('0,0.00')} &euro;</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CollapsedRow.propTypes = {
  positions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      pricePer: PropTypes.number,
      type: PropTypes.string,
    })
  ).isRequired,
};
