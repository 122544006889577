import api from '.';
import { extractListParams } from 'utils/querying';

export const fetchStockOperations = ({
  storageId,
  productId,
  userName,
  supplierId,
  storageProductId,
  specialCaseId,
  manufacturingDate,
  ...params
}) => api.get('/stock-operations', {
  params: {
    ...extractListParams(params),
    ...(storageId && { storageId }),
    ...(productId && { productId }),
    ...(supplierId && { supplierId }),
    ...(userName && { userName }),
    ...(storageProductId && { storageProductId }),
    ...(specialCaseId && { specialCaseId }),
    ...(manufacturingDate && { manufacturingDate }),
  },
});

export const fetchDaily = ({
  productId,
  supplierId,
  ...params
}) => api.get('/stock-daily-changes', {
  params: {
    ...extractListParams(params),
    ...(productId && { productId }),
    ...(supplierId && { supplierId }),
  },
});

export default {
  fetchMany: fetchStockOperations,
  fetchDaily: fetchDaily,
};
