import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import { didUpdate } from 'hooks';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {
  InputControl,
  BusyButton,
} from 'components';

import { packPickupSheet } from 'api/pickup-sheets';
import { styleFormDialog } from 'theme/mixins/dialog';
import numeral from 'numeral';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { extractApiErrors } from 'utils/api';

import { Decimal } from 'decimal.js';

const useStyles = makeStyles()(styleFormDialog);
const makeId = postfix => `packing-pickup-sheet-dialog--${postfix}`;

export const PackingPickupSheetDialog = forwardRef(({
  onClose,
  onSave,
  pickupSheet,
  ...props
}, ref) => {
  const t = useTranslator();
  const { classes } = useStyles();

  useImperativeHandle(ref, () => ({
    values,
    onSubmit,
    setErrors,
  }));

  const validationSchema = Yup
    .object()
    .shape({
      weight: Yup
        .number()
        .transform((value, originalValue) => t.parsePrice(originalValue))
        .required(t.translate('Please specify package weight.'))
        .positive(t.translate('Weight is incorrect.'))
        .lessThan(pickupSheet.maximumPackageWeight, t.translate('The weight exceeds the maximum - {weight} kg.', {
          weight: numeral(pickupSheet.maximumPackageWeight).format('0,0.[00]'),
        })),
    });

  const initialValues = {
    weight: '',
  };

  const onSubmit = async (values, { setErrors }) => {
    try {
      await packPickupSheet(pickupSheet.id, {
        weight: t.parsePrice(values.weight),
      });

      onSave();
      onClose();
    } catch (err) {
      setErrors(extractApiErrors(err));
    }
  };

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    resetForm,
    values,
    errors,
    setErrors,
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: true,
    validationSchema,
    initialValues,
    onSubmit,
  });

  didUpdate(() => {
    if (props.open) {
      resetForm();

      const { pickupSheetProducts } = pickupSheet;
      const totalWeight = pickupSheetProducts.reduce((sum, { markedQuantity, packedQuantity, weight }) => {
        const tempWeight = new Decimal(weight * ( markedQuantity - packedQuantity ));
        return Decimal.sum(sum, tempWeight);
      }, 0);
      setFieldValue('weight', t.formatPrice(totalWeight));
    }
  }, [props.open]);

  return (
    <Dialog scroll="body" {...props} className={classes.root} onClose={onClose}>
      <form action="#">
        <DialogTitle className={classes.title}>{t.translate('Pack products')}</DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <InputControl
                id={makeId('weight')}
                name="weight"
                error={errors.weight}
                label={`${t.translate('Package weight')}, ${t.translate('kg')}`}
                value={values.weight}
                onChange={handleChange}
                inputRef={input => input && input.focus()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
          <BusyButton submit onClick={handleSubmit}>{t.translate('Save')}</BusyButton>
        </DialogActions>
      </form>
    </Dialog>
  );
});

PackingPickupSheetDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  pickupSheet: PropTypes.shape({
    maximumPackageWeight: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
};
