import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { TableCell } from '..';

export const ListFilterCell = ({
  onToggle,
  onChange,
  emptyOptionText,
  displayEmpty,
  options,
  enabled,
  value,
  ...props
}) => (
  <TableCell {...props}>
    <Grid container>
      <Grid item>
        <Select
          displayEmpty={displayEmpty}
          value={value}
          onChange={({ target: { value } }) => onChange(value)}
        >
          {displayEmpty && <MenuItem value="">{emptyOptionText}</MenuItem>}
          {options.map(option => (
            <MenuItem key={'val_' + option.value} value={option.value}>{option.text}</MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  </TableCell>
);

ListFilterCell.propTypes = {
  onToggle: PropTypes.func.isRequired,
  emptyOptionText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      text: PropTypes.string,
    })
  ).isRequired,
  enabled: PropTypes.bool,
};
