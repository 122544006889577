import api from '.';
import { extractListParams } from 'utils/querying';

export const fetchChangesHistory = ({
  userName,
  transactionId,
  modelName,
  primaryId,
  reference,
  createdAt,
  ...params
}) => api.get('/changes-history', {
  params: {
    ...extractListParams(params),
    ...(userName && { userName }),
    ...(transactionId && { transactionId }),
    ...(modelName && { modelName }),
    ...(primaryId && { primaryId }),
    ...(reference && { reference }),
    ...(createdAt && { createdAt }),
  },
});

export default {
  fetchMany: fetchChangesHistory,
};
