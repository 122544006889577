import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { OrderReturnsTable } from '../OrderReturnsTable/OrderReturnsTable';

const useStyles = makeStyles()(theme => ({
  fontBold: {
    fontWeight: 500,
    marginBottom: theme.spacing(.5),
  },
  cardItem: {
    padding: theme.spacing(2),
  },
  container: {
    flexWrap: 'wrap',
  },
}));

export const CollapsedRow = ({ order, onStockEvaluation, onMarkReturnWithoutStock }) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const history = useHistory();

  const openPickupSheet = id => history.push(`/pickup-sheets/${id}`);

  const getOrderReturnReason = (statusHistory) => {
    const returnedProductsWithReason = statusHistory.filter(({ status, params }) => status.startsWith('Order has been returned') && params?.reason);

    if (returnedProductsWithReason.length) {
      return returnedProductsWithReason.pop().params.reason;
    } else {
      const lastStatus = statusHistory.reverse().find(({ status }) => status.startsWith('Order has been returned'));
      if (lastStatus) {
        return lastStatus.status.split(':')[1]?.trim().replace(/\.$/, '');
      }
    }
  };

  const hasReturnsWithStock = order.hasReturns && order.positions.some(position => {
    return position.orderPositionReturns.some(positionReturn => !positionReturn.orderReturn.isWithoutStock);
  });

  return (
    <Grid container className={classes.container}>
      <Grid container item xs={12}>
        <Grid container item direction="column" className={classes.cardItem}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography className={classes.fontBold} variant="body2">{t.translate('Supplier number')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{order.supplier.number || '—'}</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography className={classes.fontBold} variant="body2">{t.translate('Supplier company')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{order.supplier.name || '—'}</Typography>
            </Grid>
          </Grid>
          {order.pickupSheetId && (
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography className={classes.fontBold} variant="body2">{t.translate('Picklist')}</Typography>
              </Grid>
              <Grid item>
                <Link onClick={() => openPickupSheet(order.pickupSheetId)}>#{order.pickupSheetId}</Link>
              </Grid>
            </Grid>
          )}
          {!!order.statusHistory.length && Boolean(order.hasReturns) && (
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography className={classes.fontBold} variant="body2">{t.translate('Return reason')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{t.translate(getOrderReturnReason(order.statusHistory)) || '—'}</Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Divider orientation="vertical" flexItem />
        {hasReturnsWithStock && (
          <Grid container item xs={12}>
            <OrderReturnsTable order={order} onStockEvaluation={onStockEvaluation} onMarkReturnWithoutStock={onMarkReturnWithoutStock}/>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

CollapsedRow.propTypes = {
  order: PropTypes.shape({
    customer: PropTypes.shape({
      number: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }).isRequired,
    supplier: PropTypes.shape({
      id: PropTypes.number.isRequired,
      number: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.string,
    pickupSheetId: PropTypes.number,
    hasReturns: PropTypes.number,
  }).isRequired,
};
