import merge from 'lodash/merge';

export const styleFormDialog = (theme, styles = {}) => merge({
  root: {
    '& .MuiDialog-paper': {
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        maxWidth: `calc(100% - ${theme.spacing(2)})`,
      },
    },
  },
  title: {
    paddingBottom: `${theme.spacing(.5)} !important`,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1.5),
    },
  },
  content: {
    '&.MuiDialogContent-root': {
      paddingBottom: 0,
      width: 400,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: 312,
      },
    },
  },
  summary: {
    marginTop: theme.spacing(-1),
    paddingTop: '0 !important',
  },
  actions: {
    padding: theme.spacing(1, 2, 2),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(.5),
    },
  },
}, styles);

export const styleBorderedFormDialog = (theme, styles = {}) => merge(styleFormDialog(theme, {
  root: {
    '& .MuiDialog-paper': {
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(10.5),
        marginTop: theme.spacing(7.5),
        maxHeight: `calc(100% - ${theme.spacing(18)})`,
      },
    },
  },
  title: {
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  actions: {
    borderTopColor: theme.palette.divider,
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  summary: {
    marginTop: 0,
  },
}), styles);

export const styleFullScreenFormDialog = (theme, styles = {}) => merge({
  content: {
    '&.MuiDialogContent-root': {
      padding: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
  },
}, styles);

export const styleDefaultFormDialog = (theme, styles = {}) => merge({
  root: {
    maxWidth: 'none',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      margin: 0,
      maxHeight: 'none',
      maxWidth: 'none',
      height: '100%',
      width: '100%',
    },
    [theme.breakpoints.between('sm', 'xl')]: {
      width: '70vw',
    },
    width: '50vw',
  },
  content: {
    '&.MuiDialogContent-root': {
      padding: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
  },
}, styles);
