import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useTranslator } from 'i18n';
import { makeStyles } from 'tss-react/mui';

import { Tabs } from '../Tabs/Tabs';

const useStyles = makeStyles()(theme => ({
  appBar: {
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 10px 10px 0px rgb(0,0,0,0.12)',
    height: 'initial',
    zIndex: 1100,
  },
  tab: {
    height: 'initial',
    minHeight: 'initial',
    [theme.breakpoints.down('sm')]: {
      height: 'initial',
      minHeight: 'initial',
    },
  },
  wrapper: {
    flexDirection: 'row',
    '& > .MuiSvgIcon-root:first-of-type': {
      marginBottom: 0,
      marginRight: theme.spacing(1),
    },
  },
  scroller: {
    '&.MuiTabs-fixed::before': {
      background: `${theme.palette.primary.main} !important`,
    },
  },
  indicator: {
    backgroundColor: theme.palette.primary.l50,
  },
}));

export const ViewSwitch = ({
  options = [],
  isMobile,
  activeOption,
}) => {
  const { classes } = useStyles();
  const t = useTranslator();
  const history = useHistory();
  const change = (path) => {
    history.push(path);
  };

  const localizedOptions = options.map(option => {
    option.label = t.translate(option.label);
    return option;
  });

  return (
    <Tabs
      onChange={change}
      initialValue={activeOption || options[0].name}
      currentValue={activeOption}
      items={localizedOptions}
      appBar={{ className: classes.appBar, color: 'primary' }}
      tabs={{ variant: 'fullWidth', textColor: 'inherit', classes: { scroller: classes.scroller, indicator: classes.indicator } }}
      tab={{ className: classes.tab, classes: { iconWrapper: classes.wrapper } }}
      hideLabel={isMobile}
    />
  );
};

ViewSwitch.propTypes = {
  isMobile: PropTypes.bool,
  activeOption: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      Icon: PropTypes.object,
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      view: PropTypes.string.isRequired,
    })
  ).isRequired,
};
