import { PRODUCTS } from 'store/types';
import { products as is } from 'store/initialState';

const setStorageProduct = (state, storageProduct) => ({
  ...state,
  history: {
    ...state.history,
    storageProduct,
  },
});

const setStorages = (state, { data, meta }) => ({
  ...state,
  storages: {
    ...state.storages,
    data,
    meta: {
      totalEntities: meta.totalEntities,
    },
  },
});

const setProducts = (state, { data, meta }) => ({
  ...state,
  products: {
    ...state.products,
    data,
    meta: {
      totalEntities: meta.totalEntities,
    },
  },
});

const setHistory = (state, { suppliers, data, meta }) => ({
  ...state,
  history: {
    ...state.history,
    suppliers,
    data,
    meta: {
      totalEntities: meta.totalEntities,
    },
  },
});

const setProduct = (state, product) => ({
  ...state,
  storages: {
    ...state.storages,
    product,
  },
});

const setProductPrices = (state, prices) => ({
  ...state,
  prices,
});

const clearStorages = state => ({ ...state, storages: is.storages });
const clearHistory = state => ({ ...state, history: is.history });
const clearPrices = state => ({ ...state, prices: is.prices });

const setSuppliers = (state, suppliers) => ({
  ...state,
  products: {
    ...state.products,
    suppliers,
  },
});

export default (state = is, { type, payload }) => {
  switch (type) {
    case PRODUCTS.SET_STORAGE_PRODUCT:
      return setStorageProduct(state, payload);
    case PRODUCTS.SET_STORAGES:
      return setStorages(state, payload);
    case PRODUCTS.SET_PRODUCTS:
      return setProducts(state, payload);
    case PRODUCTS.SET_HISTORY:
      return setHistory(state, payload);
    case PRODUCTS.SET_PRODUCT:
      return setProduct(state, payload);
    case PRODUCTS.SET_PRODUCT_PRICES:
      return setProductPrices(state, payload);
    case PRODUCTS.CLEAR_PRICES:
      return clearPrices(state);
    case PRODUCTS.CLEAR_STORAGES:
      return clearStorages(state);
    case PRODUCTS.CLEAR_HISTORY:
      return clearHistory(state);
    case PRODUCTS.SET_SUPPLIERS:
      return setSuppliers(state, payload);
    default:
      return state;
  }
};
