import React from 'react';
import PropTypes from 'prop-types';
import { useAutocomplete } from 'hooks';
import { Autocomplete } from 'components';
import { fetchBuildings } from 'api/storages';

export const BuildingAutocomplete = ({
  value,
  ...props
}) => {
  const findOption = v => v.building === value.trim();

  const fetchOptions = async () => {
    const buildings = await fetchBuildings(value);
    return buildings.map(building => ({ building, id: building }));
  };

  return (
    <Autocomplete
      getOptionLabel={({ building }) => building}
      fetchOptions={fetchOptions}
      findOption={findOption}
      value={value}
      {...props}
    />
  );
};

BuildingAutocomplete.propTypes = {
  value: PropTypes.string.isRequired,
};

export const useBuildingAutocomplete = options => useAutocomplete({
  optionPropertyName: 'building',
  modelPropertyName: 'building',
  formikHandle: true,
  ...options,
});
