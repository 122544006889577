import React, { useState } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { useHistory } from 'react-router-dom';

import { useTranslator } from 'i18n';
import { wrapTableRowClick } from 'utils/html';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import DeleteIcon from '@mui/icons-material/Delete';
import Collapse from '@mui/material/Collapse';
import TableRow from '@mui/material/TableRow';
import PrintIcon from '@mui/icons-material/Print';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';

import {
  ActionButton,
  Color,
  BooleanIcon,
} from 'components';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles()(theme => ({
  fontBold: {
    fontWeight: 500,
  },
  arrowUp: {
    backgroundColor: 'none',
    zIndex: 99,
  },
  arrowUpBackGround: {
    backgroundColor: theme.palette.backgrounds.secondary,
    width: theme.spacing(3),
    height: theme.spacing(6),
    position: 'absolute',
    bottom: theme.spacing(-2.25),
    borderLeft: `1px solid ${theme.palette.backgrounds.grey[225]}`,
    borderRight: `1px solid ${theme.palette.backgrounds.grey[225]}`,
    borderTop: `1px solid ${theme.palette.backgrounds.grey[225]}`,
    borderRadius: theme.spacing(.5, .5, 0, 0),
  },
  button: {
    color: theme.palette.primary.l900,
  },
  cardContainer: {
    backgroundColor: theme.palette.backgrounds.secondary,
  },
  cardItem: {
    padding: theme.spacing(2),
  },
}));

export const StorageRow = ({
  onStorageDelete,
  onStoragePrint,
  onStorageEdit,
  canDeleteStorage,
  canEditStorage,
  storage,
}) => {
  const history = useHistory();
  const t = useTranslator();
  const { classes } = useStyles();

  const [open, setOpen] = useState(false);

  const openStorage = storage => history.push(`/storages/${storage.id}`);

  return (
    <>
      <TableRow key={storage.id} hover onClick={wrapTableRowClick(() => openStorage(storage))}>
        <TableCell >
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open
              ? <>
                  <KeyboardArrowUpIcon className={classes.arrowUp}/>
                  <Grid className={classes.arrowUpBackGround}></Grid>
                </>
              : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{storage.id}</TableCell>
        <TableCell>{storage.warehouse.name}</TableCell>
        <TableCell>{storage.building}</TableCell>
        <TableCell>{storage.floor || '—'}</TableCell>
        <TableCell>{storage.room || '—'}</TableCell>
        <TableCell>{storage.volume !== null ? numeral(storage.volume).format('0.000000') : '—'}</TableCell>
        <TableCell align="right">
          <ActionButton
            className={classes.button}
            aria-label={t.translate('Print storage')}
            tooltip={t.translate('Print storage')}
            icon={<PrintIcon />}
            onClick={() => onStoragePrint(storage)}
          />
          {canEditStorage && (
            <ActionButton
              className={classes.button}
              aria-label={t.translate('Edit storage')}
              tooltip={t.translate('Edit storage')}
              icon={<EditIcon />}
              onClick={() => onStorageEdit(storage)}
            />
          )}
          {canDeleteStorage && (
            <ActionButton
              className={classes.button}
              aria-label={t.translate('Delete storage')}
              tooltip={t.translate('Delete storage')}
              icon={<DeleteIcon />}
              onClick={() => onStorageDelete(storage)}
            />
          )}
          <ActionButton
            aria-label={t.translate('Open storage')}
            tooltip={t.translate('Open storage')}
            icon={<ChevronRightIcon />}
            onClick={() => openStorage(storage)}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container justifyContent="space-between" className={classes.cardContainer}>
              <Grid container direction="column" item className={classes.cardItem} >
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography className={classes.fontBold} variant="body2">{t.translate('Type')}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{t.translate(`storageType:${storage.type}`)}</Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography className={classes.fontBold} variant="body2">{t.translate('Exclude from available stock')}</Typography>
                  </Grid>
                  <Grid item>
                    <BooleanIcon truthy={storage.excludeStock} />
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography className={classes.fontBold} variant="body2">{t.translate('Row')}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{storage.row || '—'}</Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography className={classes.fontBold} variant="body2">{t.translate('Shelf')}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{storage.shelf || '—'}</Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography className={classes.fontBold} variant="body2">{t.translate('Level')}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{storage.level || '—'}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid container item direction="column" className={classes.cardItem}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography className={classes.fontBold} variant="body2">{t.translate('Box number')}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{storage.boxNumber || '—'}</Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography className={classes.fontBold} variant="body2">{t.translate('Box color')}</Typography>
                  </Grid>
                  <Grid item>
                    <Color box color={storage.boxColor || 'black'} />
                  </Grid>
                </Grid>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid container item className={classes.cardItem} direction="column" >
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography className={classes.fontBold} variant="body2">{t.translate('Width')}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{storage.width !== null ? numeral(storage.width).format('0.00') : '—'}</Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography className={classes.fontBold} variant="body2">{t.translate('Height')}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{storage.height !== null ? numeral(storage.height).format('0.00') : '—'}</Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography className={classes.fontBold} variant="body2">{t.translate('Length')}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{storage.length !== null ? numeral(storage.length).format('0.00') : '—'}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

StorageRow.propTypes = {
  onStorageDelete: PropTypes.func.isRequired,
  onStoragePrint: PropTypes.func.isRequired,
  onStorageEdit: PropTypes.func.isRequired,
  canDeleteStorage: PropTypes.bool.isRequired,
  canEditStorage: PropTypes.bool.isRequired,
};
