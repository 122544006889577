import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const useStyles = makeStyles()(theme => ({
  controlGrid: {
    marginTop: theme.spacing(2),
  },
}));

export const ListFilterFormControl = ({
  onToggle,
  onChange,
  emptyOptionText,
  options,
  enabled,
  value,
  label,
  ...props
}) => {
  const { classes } = useStyles();

  return (
    <FormControl fullWidth>
      <InputLabel shrink htmlFor={props.id}>{label}</InputLabel>
      <Grid container className={classes.controlGrid}>
        <Grid item>
          <Checkbox
            color="primary"
            checked={enabled}
            onChange={({ target: { checked } }) => onToggle(checked)}
          />
        </Grid>
        <Grid item>
          <Select
            {...props}
            value={value}
            onChange={({ target: { value } }) => onChange(value)}
          >
            {props.displayEmpty && <MenuItem value="">{emptyOptionText}</MenuItem>}
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </FormControl>
  );
};

ListFilterFormControl.propTypes = {
  onToggle: PropTypes.func.isRequired,
  emptyOptionText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  enabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
};
