import React, { useEffect } from 'react';
import { useHistory, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslator } from 'i18n';
import { useScreen } from 'hooks';

import { Box } from '@mui/material';

import { MenuItem } from 'components';
import { Column } from 'layouts';

import { generalSettingsViews } from '../../config';
import {
  isAuthenticatedUserAdministrator,
  selectSectionPermissions,
} from 'store/selectors/account';

export const GeneralSettings = () => {
  const history = useHistory();
  const screen = useScreen();
  const t = useTranslator();

  const isAdmin = useSelector(isAuthenticatedUserAdministrator);
  const permissions = useSelector(selectSectionPermissions('settings'));
  const allowedRoutes = Object.values(generalSettingsViews).filter(({ name }) => isAdmin || permissions[name]);

  let activeRoute = !screen.isMobile ? (
    allowedRoutes.find(({ path }) => path === history.location.pathname)
    || allowedRoutes[0]
  ) : allowedRoutes.find(({ path }) => path === history.location.pathname);

  useEffect(()=> {
    if (history.location.pathname !== activeRoute?.path && !screen.isMobile) {
      history.replace(activeRoute.path);
    }
  }, [activeRoute, history, screen.isMobile]);

  const isMenuRoute = !activeRoute;
  const isMenuView = screen.isMobile && isMenuRoute;
  const hasMenu = !screen.isMobile || isMenuRoute;

  if (!screen.isMobile && isMenuRoute) {
    activeRoute = null;
  }

  return (
    <>
      {hasMenu && (
        <Column shrink={!screen.isMobile} filled>
          <Box display="flex" flexDirection="column">
            {Object.entries(allowedRoutes).map(([key, item]) => (
              <MenuItem
                key={key}
                active={item.path === activeRoute?.path}
                chevron
                title={t.translate(item.title)}
                description={t.translate(item.description)}
                onClick={() => history.replace(item.path)}
              />
            ))}
          </Box>
        </Column>
      )}
      {!isMenuView && (
        <Column filled p={3} pt={2.5}>
          <Switch>
            {Object.entries(allowedRoutes).map(([key, { path, Component }]) => (
              <Route key={key} exact path={path}>
                <Component />
              </Route>
            ))}
          </Switch>
        </Column>
      )}
    </>
  );
};
