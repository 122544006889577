import React from 'react';
import { useTranslator } from 'i18n';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const AppHeaderTitle = ({ children, ...props }) => {
  const t = useTranslator();

  return (
    <Box flexGrow={1} ml={2} {...props}>
      <Typography variant="h6" noWrap>
        {children || t.translate('app:title')}
      </Typography>
    </Box>
  );
};
