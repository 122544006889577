import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslator } from '../../../i18n';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';

import { fetchProductGroups, createProductGroup, deleteProductGroup, modifyProductGroup } from '../../../api/product-groups';
import toaster from 'services/toaster';
import { ProductGroupBadge } from '../../../components/ProductGroupBadge/ProductGroupBadge';
import { cleanUserDefaultProductGroups } from '../../../services/local-storage';
import { ConfirmingDialog } from '../../../dialogs';
import { useDialog } from '../../../hooks';

const useStyles = makeStyles()(theme => ({
  actionsItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  tagsContainer: {
    marginTop: 30,
    display: 'flex',
    flexWrap: 'wrap',
  },
  productGroupInput: {
    marginTop: 20,
  },
}));

export const ProductGroups = () => {
  const [productGroups, setProductGroups] = useState([]);
  const [productNameInput, setProductNameInput] = useState('');
  const [deletedGroup, setDeletedGroup] = useState(null);
  const t = useTranslator();
  const { classes } = useStyles();
  const confirmingDialog = useDialog();

  useEffect(() => {
    fetchProductGroups().then((groups) => setProductGroups(groups.data));
  }, []);

  const handleRename = async (id, name) => {
    try {
      await modifyProductGroup(id, name.trim());
      await handleUpdateProductGroups();
      toaster.success(t.translate('Product group has been updated successfully'));
    } catch (err) {
      toaster.error(err.message ?? Object.values(err.collection)[0][0]);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteProductGroup(id);
      await handleUpdateProductGroups();
      cleanUserDefaultProductGroups();
      setDeletedGroup(null);
      toaster.success(t.translate('Product group has been deleted'));
    } catch (err) {
      toaster.error(err.message ?? Object.values(err.collection)[0][0]);
    }
  };

  const handleCreateProductGroup = async () => {
    try {
      await createProductGroup(productNameInput.trim());
      await handleUpdateProductGroups();
      setProductNameInput('');
      toaster.success(t.translate('Product group has been created successfully'));
    } catch (err) {
      toaster.error(err.message ?? Object.values(err.collection)[0][0]);
    }
  };

  const handleUpdateProductGroups = async () => {
    const groups = await fetchProductGroups();
    setProductGroups(groups.data);
  };

  return <Grid container direction="column">
    <ConfirmingDialog
      {...confirmingDialog.props}
      onAccept={async () => {
        await handleDelete(deletedGroup);
        setDeletedGroup(null);
      }}
      onClose={confirmingDialog.close}
      title={t.translate('Delete group')}
      acceptButtonText={t.translate(`Yes, I'm sure`)}
      declineButtonText={t.translate('No, cancel')}
    >
      {t.translate('Are you sure you want to delete this group?')}
    </ConfirmingDialog>
    <Grid item>
      <Typography variant="h6">
        {t.translate('Product groups')}
      </Typography>
    </Grid>
    <Grid item className={classes.tagsContainer}>
      {productGroups.map((group) => {
        return <ProductGroupBadge
          id={group.id}
          key={`tag-${group.id}`}
          groupName={group.name}
          onRename={handleRename}
          onDelete={() => {
            confirmingDialog.open();
            setDeletedGroup(group.id);
          }}
        />;
      })}
    </Grid>
    <>
      <InputLabel shrink htmlFor={'product-group-input'} className={classes.productGroupInput}>{t.translate('New product group name')}</InputLabel>
      <Input
        id={'product-group-input'}
        data-testid={'product-group-input-testid'}
        value={productNameInput}
        onChange={(e) => setProductNameInput(e.target.value)}
      />
    </>
    <Grid item className={classes.actionsItem}>
      <Button
        data-testid={'create-button-testid'}
        type="submit"
        color="secondary"
        variant="contained"
        onClick={handleCreateProductGroup}
      >
        {t.translate('Create')}
      </Button>
    </Grid>
  </Grid>;
};
