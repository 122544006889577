import { SUPPLIERS } from 'store/types';
import { suppliers as is } from 'store/initialState';

const setOverviewSuppliers = (state, { data, meta }) => ({
  ...state,
  overview: {
    ...state.overview,
    suppliers: {
      data,
      meta: {
        ...state.overview.meta,
        ...meta,
      },
    },
  },
});

const setDetailsSupplier = (state, supplier) => ({
  ...state,
  details: {
    ...state.details,
    supplier,
  },
});

export default (state = is, { type, payload }) => {
  switch (type) {
    case SUPPLIERS.OVERVIEW.SET_SUPPLIERS:
      return setOverviewSuppliers(state, payload);
    case SUPPLIERS.DETAILS.SET_SUPPLIER:
      return setDetailsSupplier(state, payload);
    default:
      return state;
  }
};
