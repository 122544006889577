import { ACCOUNT } from 'store/types';
import { account as is } from 'store/initialState';

export const update = (state, data) => ({ ...state, ...data });

export const setDefaultView = (state, defaultView) => ({ ...state, defaultView });
export const setDefaultProductGroups = (state, defaultProductGroups) => ({ ...state, defaultProductGroups });
export const setWarehouse = (state, warehouse) => ({ ...state, warehouse });
export const setLocale = (state, locale) => ({ ...state, locale });

export default (state = is, { type, payload }) => {
  switch (type) {
    case ACCOUNT.SET_DEFAULT_VIEW:
      return setDefaultView(state, payload);
    case ACCOUNT.SET_DEFAULT_PRODUCT_GROUPS:
      return setDefaultProductGroups(state, payload);
    case ACCOUNT.SET_WAREHOUSE:
      return setWarehouse(state, payload);
    case ACCOUNT.SET_LOCALE:
      return setLocale(state, payload);
    case ACCOUNT.UPDATE:
      return update(state, payload);
    default:
      return state;
  }
};
