import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  Select,
  MenuItem,
  DialogContentText,
} from '@mui/material';

import { useTranslator } from 'i18n';
import { useStyles } from './ExportProductsDialog.jsx';
import { fetchSuppliers } from 'api/suppliers';

export const ExportProductsDialog = ({
  onProductsExport,
  onClose,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const [supplier, setSupplier] = useState({});
  const [supplierList, setSupplierList] = useState([]);

  const onExportClick = () => {
    onProductsExport(supplier.id);
  };

  useEffect( () => {
    fetchSuppliers({ offset: 0, limit: - 1 }).then(({ data }) => {
      setSupplierList(data);
    });
  }, []);

  useEffect(() => {
    if (supplierList.length) setSupplier(supplierList[0]);
  }, [supplierList]);

  return (
    <>
      <Dialog scroll="body" {...props} className={classes.root} id='export-products-dialog'>
        <DialogTitle className={classes.title}>
          <Grid container alignItems={'center'}>
            <Grid item className={classes.dropdownLabel}>{t.translate('Export supplier\'s products')}</Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText>
            {t.translate('The exported file will be sent to your email upon completion. It can be used to mass edit products of the supplier.')}
          </DialogContentText>
          <Grid container alignItems={'center'}>
            <Grid item className={classes.dropdownLabel}>{t.translate('Supplier')}</Grid>
            <Grid item>
              <Select
                id="supplier-dropdown"
                value={supplier}
                className={classes.dropdown}
                onChange={(e) => {
                  setSupplier(e.target.value);
                }}
              >
                {supplierList.map((supplier, index) => (
                  <MenuItem key={`${supplier.number}-${index}`} value={supplier}>
                    {`${supplier.number} - ${supplier.name}`}
                  </MenuItem>))
                }
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button color="primary" onClick={onExportClick}>{t.translate('Export')}</Button>
          <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ExportProductsDialog.propTypes = {
  onProductsExport: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
