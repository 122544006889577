import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useTranslator } from 'i18n';
import {
  useTableSortableHeadCell,
  useList,
} from 'hooks';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {
  ActionButton,
  BooleanIcon,
  Pagination,
  CustomTableRow,
} from 'components';

import { wrapTableRowClick } from 'utils/html';
import tables from 'config/tables';

export const RetailersTable = ({ retailers, retailersMetadata }) => {
  const history = useHistory();
  const list = useList(tables.retailers.retailers);
  const t = useTranslator();

  const SortableCell = useTableSortableHeadCell({
    changeSorting: list.changeSorting,
    sorting: list.sorting,
  });

  const openRetailer = retailer => history.push(`/settings/retailers/${retailer.id}`);

  return (
    <>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <SortableCell name="id">ID</SortableCell>
              <SortableCell name="accountNumber">{t.translate('Account number')}</SortableCell>
              <SortableCell name="name">{t.translate('Name')}</SortableCell>
              <SortableCell name="active">{t.translate('Active')}</SortableCell>
              <SortableCell name="createdAt">{t.translate('Created')}</SortableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {retailers.map((retailer, index) => (
              <CustomTableRow key={index} hover onClick={wrapTableRowClick(() => openRetailer(retailer))} >
                <TableCell>{retailer.id}</TableCell>
                <TableCell>{retailer.accountNumber}</TableCell>
                <TableCell>{retailer.name}</TableCell>
                <TableCell><BooleanIcon truthy={retailer.active} /></TableCell>
                <TableCell>{t.formatDate(retailer.createdAt)} {t.formatTime(retailer.createdAt)}</TableCell>
                <TableCell align="right">
                  <ActionButton
                    aria-label={t.translate('Open retailer')}
                    tooltip={t.translate('verb:Open')}
                    icon={<ChevronRightIcon />}
                    onClick={() => openRetailer(retailer)}
                  />
                </TableCell>
              </CustomTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination {...list.paginationProps} count={retailersMetadata.totalEntities} />
    </>
  );
};

RetailersTable.propTypes = {
  retailers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      accountNumber: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
      createdAt: PropTypes.string.isRequired,
    })
  ).isRequired,
  retailersMetadata: PropTypes.shape({
    totalEntities: PropTypes.number.isRequired,
  }).isRequired,
};
