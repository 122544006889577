import React, { useCallback, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useStyles } from '../styles';
import { ChangesHistoryTable } from '../../../changes-history/DesktopView/ChangesHistoryTable/ChangesHistoryTable';
import { fetchChangesHistory } from '../../../../services/changes-history';
import { useList } from '../../../../hooks';
import { useDispatch } from 'react-redux';
import tables from '../../../../config/tables';

export const ChangesHistory = ({ supplier }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const list = useList(tables.changesHistory.changesHistory);

  const fetchData = useCallback(() => dispatch(fetchChangesHistory({
    ...list.compile(),
    reference: `supplier:${supplier.id}`,
  })), [dispatch, list, supplier.id]);

  useEffect(() => void fetchData(), [fetchData]);

  return (
    <Grid container item spacing={2} style={{ margin: 10 }} className={classes.containerWrap}>
      <Grid item xs={12}>
        <ChangesHistoryTable isDetailsTable={true}/>
      </Grid>
    </Grid>
  );
};
