import React from 'react';
import PropTypes from 'prop-types';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export const SortBarItem = React.forwardRef(({
  onSortingChange,
  sorting,
  name,
  children,
  ...props
}, ref) => {
  const changeSorting = orderBy => onSortingChange({
    orderBy,
    order: sorting.orderBy === orderBy
      ? sorting.order === 'desc' ? 'asc' : 'desc'
      : sorting.order,
  });

  return (
    <MenuItem
      {...props}
      ref={ref}
      onClick={() => changeSorting(name)}
    >
      <ListItemText>{children}</ListItemText>
      {name === sorting.orderBy && (
        <ListItemIcon>
          {sorting.order === 'desc'
            ? <ArrowDownwardIcon />
            : <ArrowUpwardIcon />}
        </ListItemIcon>
      )}
    </MenuItem>
  );
});

SortBarItem.propTypes = {
  onSortingChange: PropTypes.func.isRequired,
  sorting: PropTypes.shape({
    orderBy: PropTypes.string.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
};
