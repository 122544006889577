import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslator } from 'i18n';
import {
  useTableSortableHeadCell,
  useList,
} from 'hooks';

import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TableContainer from '@mui/material/TableContainer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import {
  ActionButton,
  Pagination,
  Color,
  BooleanIcon,
  DotChip,
} from 'components';

import { selectActionPermission } from 'store/selectors/account';
import { wrapTableRowClick } from 'utils/html';
import tables from 'config/tables';
import { styleTableRow } from 'theme/mixins/table';
import { simpleFormatDate } from 'utils/dates';

const useStyles = makeStyles()(theme => styleTableRow(theme, {
  cardLabel: {
    color: theme.palette.text.itemHeadline,
    padding: theme.spacing(1),
  },
  cardContainer: {
    flexGrow: 1,
  },
  cardItem: {
    padding: theme.spacing(2),
    '& > div:first-of-type" > p': {
      fontWeight: 500,
    },
  },
  button: {
    color: theme.palette.primary.main,
    backgroundColor: '#f1f5ff',
    textTransform: 'none',
  },
  productTotals: {
    width: 'auto',
  },
  table: {
    '& th:last-child, & td:last-child': {
      paddingRight: theme.spacing(3),
    },
  },
}));

export const StoragesTable = ({
  onStorageReassignStock,
  onStorageProductEdit,
  product,
}) => {
  const history = useHistory();
  const list = useList(tables.products.products);
  const t = useTranslator();
  const { classes } = useStyles();

  const canCorrectStock = useSelector(selectActionPermission('products', 'correct'));
  const canReassignStock = useSelector(selectActionPermission('products', 'reassignStock'));
  const storageProducts = useSelector(({ products }) => products.storages.data);
  const totalEntities = useSelector(({ products }) => products.storages.meta.totalEntities);

  const SortableCell = useTableSortableHeadCell({
    changeSorting: list.changeSorting,
    sorting: list.sorting,
  });

  const openHistory = storageProduct => history
    .push(`/products/history/${storageProduct.storage.id}&${product.id}&${simpleFormatDate(storageProduct.manufacturingDate)}`);

  return (
    <Grid item className={classes.root} xs={12}>
      <Grid container justifyContent="space-between">
        <Typography className={classes.cardLabel} variant="body2">{t.translate('Storage locations')}</Typography>
        <Grid container className={classes.productTotals}>
          <Typography className={classes.cardLabel} variant="body2">{t.translate('Reserved Stock')}: {product.reserved}</Typography>
          <Typography className={classes.cardLabel} variant="body2">{t.translate('Total stock')}: {product.stock}</Typography>
        </Grid>
      </Grid>
      <Paper>
          <TableContainer >
            <Table className={classes.table}>
              <TableHead>
                <TableRow style={{ textAlign: 'center' }}>
                  <SortableCell name="storageId">{t.translate('Storage')}</SortableCell>
                  <TableCell name="excludeStock" width={240}>{t.translate('Exclude from available stock')}</TableCell>
                  <SortableCell name="totalStock">{t.translate('Stock')}</SortableCell>
                  <TableCell name="dot">{t.translate('DOT')}</TableCell>
                  <SortableCell name="building">{t.translate('Building')}</SortableCell>
                  <SortableCell name="floor">{t.translate('Floor')}</SortableCell>
                  <SortableCell name="room">{t.translate('Room')}</SortableCell>
                  <SortableCell name="row">{t.translate('Row')}</SortableCell>
                  <SortableCell name="shelf">{t.translate('Shelf')}</SortableCell>
                  <SortableCell name="level">{t.translate('Level')}</SortableCell>
                  <SortableCell name="boxNumber">{t.translate('Box no')}</SortableCell>
                  <TableCell>{t.translate('Box color')}</TableCell>
                  <TableCell width={180} align="center">{t.translate('Actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {storageProducts.map((storageProduct, index) => (
                  <TableRow key={index} hover onClick={wrapTableRowClick(() => openHistory(storageProduct))} style={{ textAlign: 'center' }}>
                    <TableCell>{storageProduct.storage.id}</TableCell>
                    <TableCell><BooleanIcon truthy={storageProduct.storage.excludeStock}/></TableCell>
                    <TableCell>{storageProduct.totalStock}</TableCell>
                    <TableCell><DotChip manufacturingDate={storageProduct.manufacturingDate}/></TableCell>
                    <TableCell>{storageProduct.storage.building}</TableCell>
                    <TableCell>{storageProduct.storage.floor || '—'}</TableCell>
                    <TableCell>{storageProduct.storage.room || '—'}</TableCell>
                    <TableCell>{storageProduct.storage.row || '—'}</TableCell>
                    <TableCell>{storageProduct.storage.shelf || '—'}</TableCell>
                    <TableCell>{storageProduct.storage.level || '—'}</TableCell>
                    <TableCell>{storageProduct.storage.boxNumber || '—'}</TableCell>
                    <TableCell>{storageProduct.storage.boxColor ? <Color box color={storageProduct.storage.boxColor} /> : '—'}</TableCell>
                    <TableCell align="right">
                      {canReassignStock && !!Number(storageProduct.totalStock) && (
                        <ActionButton
                          tooltipPlacement="bottom"
                          aria-label={t.translate('Reassign storage product')}
                          tooltip={t.translate('Reassign to another storage location')}
                          icon={<AssignmentReturnIcon />}
                          onClick={() => onStorageReassignStock(storageProduct)}
                        />
                      )}
                      {canCorrectStock && (
                        <ActionButton
                          tooltipPlacement="bottom"
                          aria-label={t.translate('Edit stock')}
                          tooltip={t.translate('Edit stock')}
                          icon={<EditIcon />}
                          onClick={() => onStorageProductEdit(storageProduct)}
                        />
                      )}
                      <IconButton
                        variant="contained"
                        onClick={() => openHistory(storageProduct)}
                        aria-label="detail storage product"
                        size="large">
                        <ChevronRightIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination {...list.paginationProps} count={totalEntities} />
      </Paper>
    </Grid>
  );
};

StoragesTable.propTypes = {
  onStorageReassignStock: PropTypes.func.isRequired,
  onStorageProductEdit: PropTypes.func.isRequired,
  product: PropTypes.shape({
  }),
};
