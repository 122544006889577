import React from 'react';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslator } from 'i18n';
import {
  useTableSortableHeadCell,
  useList,
} from 'hooks';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TableContainer from '@mui/material/TableContainer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import { Grid } from '@mui/material';

import {
  ActionButton,
  PurchaseStatusBadge,
  Pagination,
  TableCell,
  TableRow,
  Table,
  CircularLoader,
  BadgeFilter,
} from 'components';

import tables from 'config/tables';
import { wrapTableRowClick } from 'utils/html';
import { PurchaseStatuses } from 'consts';

const useStyles = makeStyles()(theme => ({
  paper: {
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      margin: 0,
      maxHeight: 'none',
      maxWidth: 'none',
      height: '100%',
      width: '100%',
    },
  },
  filtersContainer: {
    margin: theme.spacing(2, 3, 2),
  },
  table: {
    '& th:first-of-type, & td:first-of-type': {
      paddingLeft: theme.spacing(3),
    },
    '& th:last-child, & td:last-child': {
      paddingRight: theme.spacing(3),
    },
  },
  text: {
    wordBreak: 'break-all',
  },
  button: {
    color: theme.palette.primary.l900,
  },
}));

export const PurchaseTable = ({
  canEditPurchasePermission,
  authenticatedUser,
  hasSupervisorPermission,
  confirmApproveDialog,
  confirmDeclineDialog,
  confirmDeleteDialog,
}) => {
  const history = useHistory();
  const { classes } = useStyles();
  const list = useList(tables.products.products);
  const t = useTranslator();

  const totalEntities = useSelector(({ purchases }) => purchases.overview.purchases.meta.totalEntities);
  const purchases = useSelector(({ purchases }) => purchases.overview.purchases.data);
  const openSupplier = id => history.push(`/edit-supplier/${id}`);
  const isLoading = useSelector(({ purchases }) => purchases.overview.isLoadingPurchases);

  const statuses = Object.values(PurchaseStatuses);

  const SortableCell = useTableSortableHeadCell({
    changeSorting: list.changeSorting,
    sorting: list.sorting,
  });

  const openPurchase = purchase => history.push(`/purchases/${purchase.id}`);

  const getStockBookedInfo = purchase => {
    const { quantity, deliveredQuantity } = purchase;

    return `${deliveredQuantity}/${quantity} (${(Number(deliveredQuantity * 100 / quantity) || 0).toFixed()}%)`;
  };

  if (isLoading) return <CircularLoader />;

  return (
    <Paper className={classes.paper}>
      <Grid container className={classes.filtersContainer} alignItems="center" justifyContent="space-between">
        <Grid item xs={10}>
          <Grid container alignItems="center">
            <BadgeFilter
              filteredBy={'status'}
              list={list}
              filteredByValues={statuses}
              title={t.translate('Status')}
              prefixForTranslator={'status:'}
              isTransparentBg={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <SortableCell name="number">{t.translate('Number')}</SortableCell>
              <SortableCell name="createdAt">{t.translate('Created')}</SortableCell>
              <SortableCell name="supplierNumber" align="right">{t.translate('Supplier number')}</SortableCell>
              <TableCell name="totalNet" align="right">{t.translate('Total net')}</TableCell>
              <SortableCell name="status">{t.translate('Status')}</SortableCell>
              <TableCell>{t.translate('Stock booked')}</TableCell>
              <SortableCell name="userName">{t.translate('User')}</SortableCell>
              <TableCell align="right">{t.translate('Actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {purchases.map((purchase, index) => {
              const isEveryPositionNotDelivered = purchase.positions.every(({ deliveredQuantity }) => deliveredQuantity === 0);
              const canDeletePurchase =
                (!purchase?.editedByUserId || purchase.editedByUserId === authenticatedUser.id)
                && canEditPurchasePermission
                && (
                  [PurchaseStatuses.draft].includes(purchase?.status)
                  || (purchase?.status !== PurchaseStatuses.received && hasSupervisorPermission && isEveryPositionNotDelivered)
                );

              const canApproveAndDeclinePurchase = purchase?.status === PurchaseStatuses.approvalNeeded && hasSupervisorPermission;

              return (
                <TableRow key={index} hover onClick={wrapTableRowClick(() => openPurchase(purchase))} >
                  <TableCell>{purchase.number}</TableCell>
                  <TableCell>{t.formatDate(purchase.createdAt)}&nbsp;{t.formatTime(purchase.createdAt)}</TableCell>
                  <TableCell align="right">
                    <Link onClick={() => openSupplier(purchase.supplier.id)}>{purchase.supplier.number}</Link>
                  </TableCell>
                  <TableCell align="right">
                    {t.formatPrice(purchase.totalPriceNet)}&nbsp;&euro;
                  </TableCell>
                  <TableCell>
                    <PurchaseStatusBadge status={purchase.status}/>
                  </TableCell>
                  <TableCell>
                    {getStockBookedInfo(purchase)}
                  </TableCell>
                  <TableCell>{purchase.user.name}</TableCell>
                  <TableCell align="right">
                    {canApproveAndDeclinePurchase && (
                      <ActionButton
                        className={classes.button}
                        aria-label={t.translate('Approve purchase')}
                        tooltip={t.translate('Approve purchase')}
                        icon={<CheckCircleIcon />}
                        onClick={() => confirmApproveDialog.open({ purchase })}
                      />
                    )}
                    {canApproveAndDeclinePurchase && (
                      <ActionButton
                        className={classes.button}
                        aria-label={t.translate('Cancel purchase')}
                        tooltip={t.translate('Cancel purchase')}
                        icon={<CancelIcon />}
                        onClick={() => confirmDeclineDialog.open({ purchase })}
                      />
                    )}
                    {canDeletePurchase && (
                      <ActionButton
                        className={classes.button}
                        aria-label={t.translate('Delete purchase')}
                        tooltip={t.translate('Delete purchase')}
                        icon={<DeleteIcon />}
                        onClick={() => confirmDeleteDialog.open({ purchase })}
                      />
                    )}
                    <IconButton
                      variant="contained"
                      onClick={() => openPurchase(purchase)}
                      aria-label="go to purchase"
                      size="large">
                      <ChevronRightIcon/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination {...list.paginationProps} count={totalEntities}/>
    </Paper>
  );
};

PurchaseTable.propTypes = {};
