import { makeStyles } from 'tss-react/mui';
import { styleMobileTableItem } from 'theme/mixins/table';

export const useStyles = makeStyles()(theme => styleMobileTableItem(theme, {
  accordion: {
    background: theme.palette.backgrounds.main['50'],
    boxShadow: 'none',
    '& .MuiCollapse-root': {
      background: theme.palette.common.white,
      paddingTop: theme.spacing(1),
    },
  },
  bold: {
    fontWeight: 500,
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  button: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.l10,
    fontSize: '0.75rem',
    textTransform: 'none',
  },
  contentItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  item: {
    background: theme.palette.common.white,
    marginBottom: theme.spacing(2),
  },
}));
