import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from 'tss-react/mui';
import LanguageIcon from '@mui/icons-material/Language';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import { applyLocale } from 'services/i18n';
import config from 'config';

const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

const useStyles = makeStyles()(theme => ({
  button: {
    color: theme.palette.primary.l500,
    '&:hover': {
      backgroundColor: hex2rgba(theme.palette.primary.l500, theme.palette.action.hoverOpacity),
    },
  },
}));

export const LocaleSwitcher = props => {
  const dispatch = useDispatch();
  const { classes, cx } = useStyles();

  const locale = useSelector(({ i18n }) => i18n.locale);

  const changeLocale = (locale, popupState) => () => {
    popupState.close();
    dispatch(applyLocale(locale));
  };

  return (
    <PopupState variant="popover">
      {popupState => (
        <>
          <Button
            color="primary"
            className={cx(props.className, classes.button)}
            startIcon={<LanguageIcon />}
            {...bindTrigger(popupState)}
          >
            {config.i18n.locales[locale]}
          </Button>
          <Menu {...bindMenu(popupState)}>
            {Object.entries(config.i18n.locales).map(([code, name]) => (
              <MenuItem
                key={code}
                onClick={changeLocale(code, popupState)}
              >
                {name}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </PopupState>
  );
};
