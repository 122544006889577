import { Main, Base } from './layouts';
import {
  PickupSheetsOverview,
  PickupSheetDetails,

  WarehousesOverview,
  WarehouseDetails,

  SuppliersOverview,
  SupplierPage,

  CustomersOverview,
  CustomerDetails,

  ProductsOverview,
  ProductHistory,
  ProductDetails,

  InboundStock,

  PurchaseDetails,
  PurchasesOverview,

  OrdersOverview,
  OrderDetails,

  Reports,
  Dashboard,

  StoragesOverview,
  StorageDetails,

  Settings,
  ChangesHistory,

  PasswordRecovery,
  Forbidden,
  Login,
} from './pages';

const makeMain = (roles, Page) => ({ roles: Array.isArray(roles) ? roles : [roles], Page, Layout: Main });
const makeBase = (role, Page) => ({ roles: [role], Page, Layout: Base });

export default {
  '/pickup-sheets/:pickupSheetId': makeMain('pickupSheets', PickupSheetDetails),
  '/pickup-sheets': makeMain('pickupSheets', PickupSheetsOverview),

  '/warehouses/:warehouseId': makeMain('warehouses', WarehouseDetails),
  '/warehouses': makeMain('warehouses', WarehousesOverview),

  '/edit-supplier/:supplierId': makeMain('suppliers', SupplierPage),
  '/new-supplier': makeMain('suppliers', SupplierPage),
  '/suppliers': makeMain('suppliers', SuppliersOverview),

  '/customers/:customerId': makeMain('customers', CustomerDetails),
  '/customers': makeMain('customers', CustomersOverview),

  '/products/history/:storageId&:productId&:manufacturingDate?': makeMain('products', ProductHistory),
  '/products/:productId': makeMain('products', ProductDetails),
  '/products': makeMain('products', ProductsOverview),

  '/outdated-products': makeMain('products', ProductsOverview),

  '/inbound-stock': makeMain('inboundStocks', InboundStock),

  '/purchases/:purchaseId': makeMain('purchases', PurchaseDetails),
  '/purchases': makeMain('purchases', PurchasesOverview),

  '/replenishment-suggestions': makeMain('purchases', PurchasesOverview),

  '/orders/:orderId': makeMain('orders', OrderDetails),
  '/orders': makeMain('orders', OrdersOverview),

  '/reports': makeMain(['statisticsReports', 'warehouseInventoryReports'], Reports),

  '/dashboard': makeMain('dashboard', Dashboard),

  '/storages/:storageId': makeMain('storages', StorageDetails),
  '/storages': makeMain('storages', StoragesOverview),
  '/settings': makeMain('account', Settings),

  '/changes-history': makeMain('changesHistory', ChangesHistory),

  '/password-recovery': makeBase('passwordRecovery', PasswordRecovery),
  '/forbidden': makeMain('forbidden', Forbidden),
  '/': makeBase('login', Login),
};
