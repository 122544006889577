import { useEffect, useState, useRef } from 'react';

export const useLoading = () => {
  const [progress, setProgress] = useState(false);
  const unmounted = useRef(false);
  const locked = useRef(false);

  useEffect(() => () => unmounted.current = true, []);

  const lock = fn => async (...args) => {
    if (locked.current) {
      return;
    }
    locked.current = true;

    setProgress(true);
    const result = await fn(...args);
    if (!unmounted.current) {
      setProgress(false);
    }

    locked.current = false;
    return result;
  };

  return {
    setProgress,
    progress,
    lock,
  };
};
