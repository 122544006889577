import React from 'react';

import {
  SalesPriceCalculation,
  LowStockUpdate,
  ShippingPartners,
  ProductGroups,
  ReturnFeesConfiguration,
  StorageFeesConfiguration,
  ReplenishmentSuggestionsConfiguration,
  StockReservationConfiguration,
} from 'views/generalSettings';

import {
  UsersOverview,
  UserDetails,
  Account,
  GeneralSettings,
  RetailersOverview,
  RetailerDetails,
  CompaniesOverview,
  Marketplaces,
  CompanyDetailsPage,
} from './subPages';
import { SvgIcon } from '@mui/material';

import sprite from 'static/images/alzrua-trade-icon-set.svg';

export const routes = [{
  homePath: '/settings/account',
  subPaths: [
    '/settings',
    '/settings/account/data',
    '/settings/account/password',
    '/settings/account/language',
    '/settings/account/default-view',
    '/settings/account/permissions',
  ],
  Page: () => <Account />,
  name: 'account',
  label: 'My account',
}, {
  homePath: '/settings/general',
  subPaths: [
    '/settings/general/sales-price',
    '/settings/general/alzura',
    '/settings/general/low-stock-update',
    '/settings/general/shipping-partners',
    '/settings/general/datev',
    '/settings/general/product-groups',
    '/settings/general/return-fees',
    '/settings/general/storage-fees',
    '/settings/general/country-vat',
    '/settings/general/replenishment-suggestions',
    '/settings/general/stock-reservation',
  ],
  Page: () => <GeneralSettings />,
  name: 'settings',
  label: 'General',
}, {
  homePath: '/settings/users',
  subPaths: [],
  Page: () => <UsersOverview />,
  name: 'users',
  label: 'Users',
}, {
  homePath: '/settings/users/:userId',
  subPaths: [],
  Page: () => <UserDetails/>,
  name: 'users',
  label: 'Users',
  details: true,
}, {
  homePath: '/settings/companies/overview',
  subPaths: [],
  Page: () => <CompaniesOverview/>,
  name: 'companies',
  label: 'Companies',
}, {
  homePath: '/settings/companies/new-company',
  subPaths: [
    '/settings/companies/new-company/details',
    '/settings/companies/new-company/bank-account',
    '/settings/companies/new-company/vat',
    '/settings/companies/new-company/datev',
    '/settings/companies/new-company/document-numbers',
  ],
  Page: (props) => <CompanyDetailsPage {...props}/>,
  name: 'companies',
  label: 'Companies',
  details: true,
}, {
  homePath: '/settings/companies/edit-company/:companyId',
  subPaths: [
    '/settings/companies/edit-company/:companyId/details',
    '/settings/companies/edit-company/:companyId/bank-account',
    '/settings/companies/edit-company/:companyId/vat',
    '/settings/companies/edit-company/:companyId/datev',
    '/settings/companies/edit-company/:companyId/document-numbers',
    '/settings/companies/edit-company/:companyId/changes-history',
  ],
  Page: (props) => <CompanyDetailsPage {...props}/>,
  name: 'companies',
  label: 'Companies',
  details: true,
}, {
  homePath: '/settings/retailers',
  subPaths: [],
  Page: () => <RetailersOverview />,
  name: 'retailers',
  label: 'Retailer accounts',
}, {
  homePath: '/settings/retailers/:retailerId',
  subPaths: [],
  Page: () => <RetailerDetails />,
  name: 'retailers',
  label: 'Retailer accounts',
  details: true,
}, {
  homePath: '/settings/marketplaces',
  subPaths: [],
  Page: (props) => <Marketplaces {...props}/>,
  name: 'marketplaces',
  label: 'Marketplaces',
}];

export const generalSettingsViews = {
  salesPrice: {
    Component: () => <SalesPriceCalculation />,
    description: 'Configure sales price calculation',
    title: 'Sales Price Calculation',
    path: '/settings/general/sales-price',
    name: 'salesPrice',
  },
  lowStockUpdateConfig: {
    Component: () => <LowStockUpdate />,
    description: 'Set low stock update',
    title: 'Low stock update',
    path: '/settings/general/low-stock-update',
    name: 'lowStockUpdate',
  },
  shippingPartnersConfig: {
    Component: () => <ShippingPartners />,
    description: 'Set shipping surcharges',
    title: 'Shipping partners',
    path: '/settings/general/shipping-partners',
    name: 'shippingPartners',
  },
  productGroupsConfig: {
    Component: () => <ProductGroups />,
    description: 'Set product groups',
    title: 'Product groups',
    path: '/settings/general/product-groups',
    name: 'productGroups',
  },
  returnFeesConfig: {
    Component: () => <ReturnFeesConfiguration />,
    description: 'Configure return fees',
    title: 'Return fees',
    path: '/settings/general/return-fees',
    name: 'returnFees',
  },
  storageFeesConfig: {
    Component: () => <StorageFeesConfiguration />,
    description: 'Configure storage fees',
    title: 'Storage fees',
    path: '/settings/general/storage-fees',
    name: 'storageFees',
  },
  replenishmentSuggestionsConfig: {
    Component: () => <ReplenishmentSuggestionsConfiguration />,
    description: 'Configure replenishment time',
    title: 'Replenishment Suggestions',
    path: '/settings/general/replenishment-suggestions',
    name: 'replenishmentSuggestions',
  },
  productsReservationConfig: {
    Component: () => <StockReservationConfiguration />,
    description: 'Configure stock reservation behavior',
    title: 'Stock reservation',
    path: '/settings/general/stock-reservation',
    name: 'stockReservation',
  },
};

export const tabsOptions = [{
    label: 'My account',
    Icon: (
      <SvgIcon width="24" height="24">
        <use href={sprite + `#accountCircle`} />
      </SvgIcon>
    ),
    name: '/settings/account',
    view: 'account',
  }, {
    label: 'General',
    Icon: (
      <SvgIcon width="24" height="24">
        <use href={sprite + `#settings`} />
      </SvgIcon>
    ),
    name: '/settings/general',
    view: 'settings',
  }, {
    label: 'Companies',
    Icon: (
      <SvgIcon width="24" height="24">
        <use href={sprite + `#company`} />
      </SvgIcon>
    ),
    name: '/settings/companies/overview',
    view: 'companies',
  }, {
    label: 'Marketplaces',
    Icon: (
      <SvgIcon width="24" height="24">
        <use href={sprite + `#marketplaces`} />
      </SvgIcon>
    ),
    name: '/settings/marketplaces',
    view: 'marketplaces',
  }, {
    label: 'Users',
    Icon: (
      <SvgIcon width="24" height="24">
        <use href={sprite + `#supervisorAccount`} />
      </SvgIcon>
    ),
    name: '/settings/users',
    view: 'users',
  }, {
    label: 'Retailer accounts',
    Icon: (
      <SvgIcon width="24" height="24">
        <use href={sprite + `#supervisorAccount`} />
      </SvgIcon>
    ),
    name: '/settings/retailers',
    view: 'retailers',
  },
];
