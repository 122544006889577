import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiIconButton-root': {
      color: theme.palette.primary.main,
    },
  },
  cardLabel: {
    color: theme.palette.text.itemHeadline,
    padding: theme.spacing(1),
  },
  cardContainer: {
    flexGrow: 1,
  },
  cardItem: {
    width: '100%',
    padding: theme.spacing(2),
    '& > div > p:first-of-type': {
      fontWeight: 500,
    },
  },
  button: {
    color: theme.palette.primary.main,
    backgroundColor: '#f1f5ff',
    textTransform: 'none',
  },
  descriptionContainer: {
    marginRight: theme.spacing(2),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    webkitBoxOrient: 'vertical',
    webkitLineClamp: 2,
    height: '3em',
    wordBreak: 'break-all',
    [theme.breakpoints.down('sm')]: {
      webkitLineClamp: 3,
      height: '4.5em',
    },
  },
  container: {
    flexWrap: 'wrap',
  },
  photoItem: {
    display: 'flex',
  },
  photo: {
    flexShrink: 0,
    height: theme.spacing(15),
    width: theme.spacing(15),
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(8),
      width: theme.spacing(8),
    },
  },
  booleanItem: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  buttons: {
    margin: theme.spacing(-1.5, -1.5, 0, 0),
  },
  name: {
    fontWeight: 'bold',
  },
  productValue: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  priceAlign: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'initial',
    },
  },
  disabledWrap: {
    whiteSpace: 'nowrap',
  },
  xsJustify: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'initial',
    },
  },
  centerValue: {
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'initial',
    },
  },
  carousel: {
    overflow: 'initial',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: 0,
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    '&>div:first-of-type': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '4px',
      borderColor: theme.palette.grey['300'],
      '&>div': {
        position: 'relative',
      },
    },
  },
  badgeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));
