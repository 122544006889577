import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './SearchInput.jsx';
import { useTranslator } from 'i18n';
import Grid from '@mui/material/Grid';
import {
  QuerySearchInput,
} from 'components';

export const SearchInput = ({ fullWidth }) => {
  const { classes } = useStyles();
  const t = useTranslator();

  return (
    <Grid container spacing={1}>
      <Grid item xs className={classes.inputItem}>
        <QuerySearchInput fullWidth={fullWidth} placeholder={t.translate('Search by pickup sheet ID')} />
      </Grid>
    </Grid>
  );
};

SearchInput.propTypes = {
  fullWidth: PropTypes.bool,
};
