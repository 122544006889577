import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';

const useStyles = makeStyles()(theme => ({
  box: {
    boxSizing: 'border-box',
  },
  border: {
    borderRightColor: theme.palette.grey['250'],
    borderRightStyle: 'solid',
    borderRightWidth: 1,
  },
}));

export const Column = ({
  shrink,
  filled,
  fixed,
  children,
  ...rest
}) => {
  const { classes, cx } = useStyles();

  const headerHeight = useSelector(({ app }) => app.header.height);

  const width = shrink ? 360 : 1;

  const props = {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: shrink ? 0 : 1,
    flexGrow: shrink ? 0 : 1,
    width,
    ...(fixed ? {
      bottom: 0,
      position: 'absolute',
      top: headerHeight,
      zIndex: 100,
    } : {}),
    ...(filled ? {
      bgcolor: 'common.white',
      ...(shrink ? {
        className: classes.border,
      } : {}),
    } : {
      bgcolor: 'primary.l50',
    }),
    ...rest,
  };

  return <>
    {fixed && width > 1 && <Box flexShrink={0} width={width} />}
    <Box {...props} className={cx(props.className, classes.box)}>{children}</Box>
  </>;
};

Column.propTypes = {
  shrink: PropTypes.bool,
  filled: PropTypes.bool,
  fixed: PropTypes.bool,
};
