import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

import {
  AppHeaderPrimaryToolbar,
  AppHeaderBackButton,
  AppHeaderRightBox,
  QuerySearchInput,
  AppHeaderTitle,
  AppHeader,
  MoreBox,
} from 'components';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles()((theme) => ({
  button: {
    color: 'inherit',
    height: 'inherit',
    textTransform: 'none',
    minWidth: theme.spacing(4),
    padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(1)} ${theme.spacing(1)} !important`,
    },
  },
  moreMenuButton: {
    justifyContent: 'left',
    padding: `${theme.spacing(1.5)} ${theme.spacing(3)} !important`,
  },
  editedByInfo: {
    color: 'inherit',
    fontSize: '0.875rem',
    fontWeight: 'normal',

    '&.MuiChip-root': {
      border: 'none',
    },
  },
  tooltip: {
    padding: theme.spacing(2),
  },
}));

export const Header = ({
  onProductPrintWithBarcode,
  isEditedByAnotherUser,
  canEditProduct,
  onProductEdit,
  onProductPrint,
  isMobile,
}) => {
  const history = useHistory();
  const { classes, cx } = useStyles();
  const t = useTranslator();

  const product = useSelector(({ products }) => products.storages.product);

  return (
    <AppHeader>
      <AppHeaderPrimaryToolbar>
        <AppHeaderBackButton edge="start" onClick={() => history.goBack()} />
        <AppHeaderTitle>{t.translate('Product')}: {product?.id}</AppHeaderTitle>
        <AppHeaderRightBox alignItems="center">
          {isEditedByAnotherUser && (
            isMobile
              ? <Tooltip
                  className={classes.tooltip}
                  title={`${t.translate('This product is currently being edited by')} ${product.editedByUser.name}`}
                >
                  <InfoIcon />
                </Tooltip>
              : <Chip
                  className={classes.editedByInfo}
                  variant="outlined"
                  label={`${t.translate('This product is currently being edited by')} ${product.editedByUser.name}`}
                />
          )}
          {canEditProduct && (
            <Button
              className={classes.button}
              startIcon={!isMobile && <EditIcon />}
              onClick={() => onProductEdit(product)}
            >
              {!isMobile ? t.translate('Edit') : <EditIcon />}
            </Button>
          )}

          {isMobile ? (
            <MoreBox isMobile={true}>
              <Grid container direction="column" className={classes.buttonContainer}>
                <Button
                  className={cx(classes.button, classes.moreMenuButton)}
                  startIcon={<PrintIcon />}
                  onClick={() => onProductPrint(product)}
                >
                  {t.translate('Print QR code')}
                </Button>
                <Button
                  className={cx(classes.button, classes.moreMenuButton)}
                  startIcon={<PrintIcon />}
                  onClick={() => onProductPrintWithBarcode(product)}
                >
                  {t.translate('Print barcode')}
                </Button>
              </Grid>
            </MoreBox>
          ) : (
            <React.Fragment>
              <Button
                className={classes.button}
                startIcon={<PrintIcon />}
                onClick={() => onProductPrint(product)}
              >
                {t.translate('Print QR code')}
              </Button>
              <Button
                className={classes.button}
                startIcon={<PrintIcon />}
                onClick={() => onProductPrintWithBarcode(product)}
              >
                {t.translate('Print barcode')}
              </Button>
            </React.Fragment>
          )}

          {isMobile ? (
            <Button
              className={classes.button}
              startIcon={<SearchIcon />}
            />
          ) : (
            <QuerySearchInput placeholder={t.translate('Search by storage ID or Alzura product ID')} />
          )}
        </AppHeaderRightBox>
      </AppHeaderPrimaryToolbar>
    </AppHeader>
  );
};
