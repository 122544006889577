import React, { useEffect } from 'react';

import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { BusyButton } from 'components';

import { selectAuthenticatedUserLocale } from 'store/selectors/account';
import { modifyAuthenticatedUser } from 'api/users';
import { applyLocale } from 'services/i18n';
import { setLocale } from 'store/actions/account';

import toaster from 'services/toaster';
import config from 'config';

const useStyles = makeStyles()(theme => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  actionsItem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export const Language = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const t = useTranslator();

  const locale = useSelector(selectAuthenticatedUserLocale);

  const initialValues = { locale: '' };

  const onSubmit = async values => {
    try {
      await modifyAuthenticatedUser(values);

      dispatch(setLocale(values.locale));
      dispatch(applyLocale(values.locale));

      toaster.success(t.translate('Language has been changed successfully.'));
    } catch (err) {}
  };

  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
  } = useFormik({
    initialValues,
    onSubmit,
  });

  useEffect(() => void setValues({ locale }), [setValues, locale]);

  return (
    <form action="#">
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Typography variant="h6">{t.translate('title: Change application language')}</Typography>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="account-settings--locale">{t.translate('Language')}</InputLabel>
            <Select
              id="account-settings--locale"
              name="locale"
              value={values.locale}
              className={classes.selectEmpty}
              displayEmpty
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>{t.translate('Detect automatically')}</em>
              </MenuItem>
              {Object.entries(config.i18n.locales).map(([key, value]) => (
                <MenuItem key={key} value={key}>{value}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item className={classes.actionsItem}>
          <BusyButton
            color="secondary"
            submit
            variant="contained"
            onClick={handleSubmit}
          >
            {t.translate('Change language')}
          </BusyButton>
        </Grid>
      </Grid>
    </form>
  );
};
