import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    borderCollapse: 'separate',
    '& .MuiDialog-paper': {
      maxWidth: theme.spacing(85),
    },
    '& th': {
      borderBottom: 'none',
    },
  },
  title: {
    padding: theme.spacing(3),
    paddingBottom: 0,
  },
  backButton: {
    padding: 0,
    color: theme.palette.text.secondary,
  },
  subTitle: {
    marginTop: theme.spacing(1.25),
    color: theme.palette.text.secondary,
  },
  emptyRow: {
    height: theme.spacing(1),
  },
  commentItem: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  confirmingTable: {
    borderRadius: theme.spacing(.5),
    '& td': {
      padding: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.grey['250']}`,
      '&:first-child': {
        minWidth: theme.spacing(12),
        borderLeft: `1px solid ${theme.palette.grey['250']}`,
        borderTopLeftRadius: theme.spacing(.5),
        borderBottomLeftRadius: theme.spacing(.5),
      },
      '&:last-child': {
        borderRight: `1px solid ${theme.palette.grey['250']}`,
        borderTopRightRadius: theme.spacing(.5),
        borderBottomRightRadius: theme.spacing(.5),
      },
    },
  },
}));
