import React from 'react';

import { useSelector } from 'react-redux';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { BooleanChip } from 'components';

import { createPermissionsListData } from 'services/account';
import { selectPermissions } from 'store/selectors/account';

const useStyles = makeStyles()(theme => ({
  root: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    width: `calc(100% + ${theme.spacing(4)})`,
  },
  sectionGrid: {
    borderBottomColor: theme.palette.grey['250'],
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    '&.MuiGrid-item': {
      padding: theme.spacing(1, 3, 1.5),
    },
  },
  actionsGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(.5),
  },
}));

export const MobileView = () => {
  const { classes } = useStyles();
  const t = useTranslator();

  const authenticatedUserPermissions = useSelector(selectPermissions);
  const permissions = createPermissionsListData(authenticatedUserPermissions);

  return (
    <Grid container direction="column" spacing={1} className={classes.root}>
      {permissions.map(section => (
        <Grid item container direction="column" key={section.key} className={classes.sectionGrid}>
          <Grid item>
            <Typography variant="body2">{t.translate(section.name)}</Typography>
          </Grid>
          <Grid item container spacing={1} className={classes.actionsGrid}>
            {section.actions.map(action => (
              <Grid item key={action.key}>
                <BooleanChip
                  label={t.translate(`permission:${action.key}`)}
                  value={action.value}
                  disabled={action.key !== 'view' && !authenticatedUserPermissions[section.key].view}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
