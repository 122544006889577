import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useDialog, useScreen } from 'hooks';
import { useTranslator } from 'i18n';

import EditIcon from '@mui/icons-material/Edit';

import { Fab } from 'components';
import { RetailerDialog } from 'dialogs';
import {
  DesktopView,
  MobileView,
} from 'views/retailers/details';

import { selectActionPermission } from 'store/selectors/account';
import { selectDetailsRetailer } from 'store/selectors/retailers';
import { setDetailsRetailer } from 'store/actions/retailers';
import { fetchRetailer } from 'services/retailers';
import { isLoading } from 'store/selectors/app';

export const RetailerDetails = () => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const params = useParams();
  const t = useTranslator();

  const isRetailerLoading = useSelector(isLoading('retailers.details.retailer'));
  const canEditRetailer = useSelector(selectActionPermission('retailers', 'edit'));
  const retailer = useSelector(selectDetailsRetailer);

  const editingDialog = useDialog();

  const callFetchingRetailer = useCallback((id) => dispatch(fetchRetailer(id)), [dispatch]);

  useEffect(() => {
    if (Number(params.retailerId) !== retailer?.id) {
      callFetchingRetailer(params.retailerId);
    }
  }, [callFetchingRetailer, params.retailerId, retailer]);

  useEffect(() => () => dispatch(setDetailsRetailer(null)), [dispatch]);

  return (
    <>

      {canEditRetailer && (
        <Fab onClick={() => editingDialog.open()} aria-label="Edit retailer" variant="extended">
          <EditIcon />
          {t.translate('Edit retailer')}
        </Fab>
      )}

      {retailer && editingDialog.visible && (
        <RetailerDialog
          {...editingDialog.props}
          onSave={() => callFetchingRetailer(retailer.id)}
          isMobileView={screen.isMobile}
          retailer={retailer}
        />
      )}

      {screen.isMobile ? (
        <MobileView
          retailer={retailer}
          isLoading={isRetailerLoading}
        />
      ) : (
        <DesktopView
          retailer={retailer}
          isLoading={isRetailerLoading}
        />
      )}
    </>
  );
};
