import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  label: {
    color: 'rgba(0, 0, 0, 0.6)',
    margin: '5px 0',
  },
  containerWrap: {
    flexWrap: 'wrap',
    flexBasis: 'auto',
    alignItems: 'flex-start',
  },
  containerItem: {
    paddingBottom: theme.spacing(3),
  },
  marginBottom: {
    marginBottom: 20,
  },
  checkboxContainer: {
    '& .MuiFormControlLabel-root': {
      display: 'block',
      marginLeft: 0,
      marginRight: 0,
    },
    '& .MuiFormControl-root': {
      marginTop: 0,
    },
  },
  noMargin: {
    marginTop: '0 !important',
  },
  containerLabel: {
    fontWeight: 500,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  floatingActionButton: {
    padding: 0,
  },
  addressLabel: {
    color: 'rgba(0, 0, 0, 0.6)',
    margin: '5px 0',
  },
  errorLabel: {
    textDecoration: 'underline',
    color: 'red',
  },
  photo: {
    flexShrink: 0,
    height: '180px',
    margin: theme.spacing(2, 0, 1.5),
    width: '180px',
  },
  warningText: {
    color: theme.palette.warning.light,
  },
  cellInput: {
    margin: `${theme.spacing(0)} !important`,
  },
  helpTextIcon: {
    width: '24px',
    cursor: 'pointer',
    margin: theme.spacing(0, 1, 0),
  },
}));
