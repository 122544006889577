import { createSelector } from 'reselect';

export const selectRawOrdersLoading = state => state.rawOrders.isLoading;

export const selectRawOrdersData = state => state.rawOrders.data;

export const selectRawOrdersMeta = state => state.rawOrders.meta;

export const selectRawOrdersDataWithMeta = createSelector([
  selectRawOrdersData,
  selectRawOrdersMeta,
], (data, meta) => ({
  data,
  meta,
}));


