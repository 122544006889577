import purchasesApi from 'api/purchases';
import {
  setPurchases,
  setPurchase,
  setPurchasePositions,
  setIsLoadingPurchases,
} from 'store/actions/purchases';

export const fetchPurchases = params => async dispatch => {
  try {
    dispatch(setIsLoadingPurchases(true));
    const purchases = await purchasesApi.fetchMany(params);
    dispatch(setPurchases(purchases));
    dispatch(setIsLoadingPurchases(false));
  } catch (err) {
    dispatch(setIsLoadingPurchases(false));
  }
};

export const fetchPurchase = purchaseId => async dispatch => {
  try {
    const purchase = await purchasesApi.fetchOne(purchaseId);
    dispatch(setPurchase(purchase));
  } catch (err) {}
};

export const fetchPurchasePositions = (purchaseId, params) => async dispatch => {
  try {
    const purchasePositions = await purchasesApi.fetchPurchasePositions({ purchaseId, ...params });
    dispatch(setPurchasePositions(purchasePositions));
  } catch (err) {}
};

