import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import debounce from 'lodash/debounce';

import { useTranslator } from 'i18n';

import { LocalizationProvider , DatePicker  } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TableContainer from '@mui/material/TableContainer';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { useStyles } from './ProductPricesDialog.jsx';

import { useFormik } from 'formik';

import {
  CheckboxControl,
  PriceControl,
  BusyButton,
  ActionButton,
  InputControl,
} from 'components';

import {
  calculateSalesPrice,
  fetchB2BProducts,
  modifyProductPrices,
  modifyProductReplenishmentSuggestionsOptions,
} from 'api/products';
import { extractApiErrors } from 'utils/api';
import toaster from 'services/toaster';
import socket, { SOCKET_EVENTS } from '../../bootstrap/socket';

const makeId = postfix => `product-price-dialog--${postfix}`;

const getCountrySalesPrices = ({ marketplaces, prices, supplierId }, { t }) => {
  const activeMarketplaces = marketplaces.filter(tab => tab.isActive);

  return activeMarketplaces.reduce((acc, marketplace) => {
    const marketPrice = prices.marketPrices[prices.marketPrices.findIndex((marketPrice) => marketPrice.marketplaceCountryId === marketplace.id )];
    const salesPrice = prices.salesPrices[prices.salesPrices.findIndex((salesPrice) => salesPrice.marketplaceCountryId === marketplace.id && salesPrice.supplier.id === supplierId)];

    return salesPrice ? ([
      ...acc,
      {
        id: marketplace.id,
        countryCode: marketplace.country.countryCode,
        marketplaceCountryId: marketplace.id,
        isProductActiveInMarketplace: Boolean(salesPrice?.isProductActiveInMarketplace) || false,
        stock: salesPrice.stock || '—',
        lastSubmittedPrice: marketPrice?.lastSubmittedPrice ? t.formatPrice(marketPrice.lastSubmittedPrice) : '—',
        cheapestMarketPrice: marketPrice?.cheapestMarketPrice || '—',
        cheapestMarketPrice2: marketPrice?.cheapestMarketPrice2 || '—',
        cheapestMarketPrice3: marketPrice?.cheapestMarketPrice3 || '—',
        isAutoCalculateSalesPrice: (Boolean(salesPrice?.isAutoCalculateSalesPrice) && Boolean(salesPrice?.supplier.isAutoCalculateSalesPrices)) || false,
        salesPrice: salesPrice?.salesPrice ? t.formatPrice(salesPrice?.salesPrice) : '',
        isSuperdealActive: Boolean(salesPrice?.isSuperdealActive),
        superdealPrice: salesPrice?.superdealPrice ? t.formatPrice(salesPrice?.superdealPrice) : '',
        superdealPromotionPrice: salesPrice?.superdealPromotionPrice ? t.formatPrice(salesPrice?.superdealPromotionPrice) : '',
        superdealStartDate: salesPrice?.superdealStartDate ? dayjs(salesPrice.superdealStartDate) : null,
        superdealEndDate: salesPrice?.superdealStartDate ? dayjs(salesPrice.superdealEndDate) : null,
        supplierIsAutoCalculateSalesPrice: salesPrice?.supplier.isAutoCalculateSalesPrices,
      },
    ]) : [];

  }, []);
};

export const ProductPricesDialog = ({
  onClose,
  onSave,
  marketplaces,
  product,
  prices,
  supplierItem,
  isMobileView,
  canUpdateFromMarketplace,
  canEditAutoSalesPrice,
  productTypes,
  replenishmentSuggestionsOption,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const [activeMarketplaces, setActiveMarketplaces] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const productPurchasePrice = prices.purchasePrices.find(price => price.supplierId === supplierItem.supplierId);
  const productSuperdealInfo = prices.superdealInfos.find(info => info.supplierId === supplierItem.supplierId);

  const initialValues = {
    purchasePrice: t.formatPrice(productPurchasePrice?.purchasePrice ?? 0),
    medianPurchasePrice: t.formatPrice(productPurchasePrice?.currentMedianPurchasePrice ?? 0),
    externalItemNumber: productPurchasePrice?.externalItemNumber ?? '',
    superdealMaxOrderAmount: productSuperdealInfo?.superdealMaxOrderAmount ?? '',
    countrySalesPrices: getCountrySalesPrices({ marketplaces, prices, supplierId: supplierItem.supplierId }, { t }),
    isReplenishmentSuggestionsEnabled: replenishmentSuggestionsOption?.isReplenishmentSuggestionsEnabled ?? false,
  };

  const validationSchema = Yup
    .object()
    .shape({
      externalItemNumber: Yup.string().label('Supplier item number'),
      purchasePrice: Yup.string().required().label('Purchase price'),
      countrySalesPrices: Yup.array().of(
        Yup.object().shape({
          salesPrice: Yup.string().label('Sales price').when('isProductActiveInMarketplace', {
            is: true,
            then: Yup.string().required(),
          }),
          isSuperdealActive: Yup.boolean(),
          superdealPrice: Yup.string().when('isSuperdealActive', {
            is: true,
            then: Yup.string().required(),
          }),
          superdealPromotionPrice: Yup.string().when('isSuperdealActive', {
            is: true,
            then: Yup.string().required(),
          }),
          superdealStartDate: Yup.date().nullable().when('isSuperdealActive', {
            is: true,
            then: Yup.date().max(Yup.ref('superdealEndDate')).required(),
          }),
          superdealEndDate: Yup.date().nullable().when('isSuperdealActive', {
            is: true,
            then: Yup.date().required(),
          }),
        })
      ),
      isReplenishmentSuggestionsEnabled: Yup.boolean().required(),
    });

  const getPrice = value => t.parsePrice(value) ?? undefined;

  useEffect(() => {
    setActiveMarketplaces(marketplaces.filter(tab => tab.isActive));
  }, [marketplaces]);

  const onSubmit = async (values, { setErrors }) => {
    try {
      if (values.purchasePrice <= 0) {
        toaster.error(t.translate('Purchase price must be positive.'));
      }

      const payload = {
        supplierId: supplierItem.supplier.id,
        purchasePrice: t.parsePrice(values.purchasePrice),
        externalItemNumber: values.externalItemNumber,
        superdealMaxOrderAmount: values.superdealMaxOrderAmount ? values.superdealMaxOrderAmount : null,
        salesPrices: values.countrySalesPrices.map((p) => ({
          marketplaceCountryId: p.marketplaceCountryId,
          salesPrice: getPrice(p.salesPrice),
          isSuperdealActive: p.isSuperdealActive,
          superdealStartDate: p.superdealStartDate ? dayjs(p.superdealStartDate).format('YYYY-MM-DD HH:mm:ss') : undefined,
          superdealEndDate: p.superdealEndDate ? dayjs(p.superdealEndDate).format('YYYY-MM-DD HH:mm:ss') : undefined,
          superdealPrice: getPrice(p.superdealPrice),
          superdealPromotionPrice: getPrice(p.superdealPromotionPrice),
          isAutoCalculateSalesPrice: p.isAutoCalculateSalesPrice,
          isProductActiveInMarketplace: p.isProductActiveInMarketplace,
          lastSubmittedPrice: getPrice(p.lastSubmittedPrice),
          cheapestMarketPrice: getPrice(p.cheapestMarketPrice),
          cheapestMarketPrice2: getPrice(p.cheapestMarketPrice2),
          cheapestMarketPrice3: getPrice(p.cheapestMarketPrice3),
        })),
      };

      await Promise.all([
        modifyProductPrices(product.id, payload),
        modifyProductReplenishmentSuggestionsOptions(product.id, {
          supplierId: supplierItem.supplier.id,
          isReplenishmentSuggestionsEnabled: values.isReplenishmentSuggestionsEnabled,
        }),
      ]);
      toaster.success(t.translate('Product prices has been updated successfully.'));

      onSave();
      onClose();
    } catch (err) {
      toaster.error(Object.values(err.collection)[0][0]);
      setErrors(extractApiErrors(err));
    }
  };

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    enableReinitialize: true,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    if (initialValues.purchasePrice > 0) {
      updateSalesPrices(values);
    }

    if (product?.id) {
      socket.emit(SOCKET_EVENTS.PRODUCTS.EDITED_BY, { entityId: product.id, isEdited: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseDialog = async () => {
    if (product?.id) {
      socket.emit(SOCKET_EVENTS.PRODUCTS.EDITED_BY, { entityId: product.id, isEdited: false });
    }

    onClose();
  };

  const updateSalesPrices = async (values, purchasePrice) => {
    const entries = Array.from(values.countrySalesPrices.entries());

    await Promise.all(entries.map(([index, countrySalesPrice]) => {
      if (countrySalesPrice.isAutoCalculateSalesPrice && countrySalesPrice.supplierIsAutoCalculateSalesPrice) {
        return updateSalesPrice(index, purchasePrice);
      }

      return Promise.resolve();
    }));
  };

  const updateSalesPricesDebounced = useRef(debounce(updateSalesPrices, 500));

  const updateMarketplacePricesFromApi = async (index, countryCode) => {
    try {
      const { ean, manufacturerNumber, manufacturerId } = product;
      const b2bProducts = await fetchB2BProducts({ ean, manufacturerNumber, countryCode, manufacturerId });
      if (b2bProducts.length === 1) {
        const { lastSubmittedPrice, cheapestMarketPrice, cheapestMarketPrice2, cheapestMarketPrice3 } = b2bProducts[0];
        await setFieldValue(`countrySalesPrices[${index}].lastSubmittedPrice`, lastSubmittedPrice);
        await setFieldValue(`countrySalesPrices[${index}].cheapestMarketPrice`, cheapestMarketPrice);
        await setFieldValue(`countrySalesPrices[${index}].cheapestMarketPrice2`, cheapestMarketPrice2);
        await setFieldValue(`countrySalesPrices[${index}].cheapestMarketPrice3`, cheapestMarketPrice3);
      } else {
        toaster.error(t.translate('The result is ambiguous.'));
      }
    } catch (err) {
      toaster.error(t.translate('Unable to receive product details from marketplace.'));
    }
  };

  const updateSalesPrice = async (index, purchasePrice) => {
    const {
      lastSubmittedPrice,
      cheapestMarketPrice,
      cheapestMarketPrice2,
      cheapestMarketPrice3,
      lastArticleIdentify,
      salesPrice,
      marketplaceCountryId,
    } = values.countrySalesPrices[index];

    try {
      const response = await calculateSalesPrice({
        marketplaceCountryId,
        supplierId: supplierItem.supplierId,
        ean: product.ean,
        manufacturerNumber: product.manufacturerNumber,
        purchasePrice: getPrice(purchasePrice || values.purchasePrice),
        lastSubmittedPrice: getPrice(lastSubmittedPrice),
        cheapestMarketPrice: getPrice(cheapestMarketPrice),
        cheapestMarketPrice2: getPrice(cheapestMarketPrice2),
        cheapestMarketPrice3: getPrice(cheapestMarketPrice3),
        medianPurchasePrice: getPrice(values.medianPurchasePrice),
        salesPrice: getPrice(salesPrice),
        lastArticleIdentify,
        isAutoCalculateSalesPrice: true,
      });
      await setFieldValue(`countrySalesPrices[${index}].salesPrice`, t.formatPrice(response.salesPrice));
    } catch (err) {
      toaster.error(t.translate('Unable to recalculate sales price'));
    }
  };

  const getErrorProp = (value) => {
    return value ? {
      slotProps: {
        textField: {
          error: true,
        },
      },
    } : {};
  };

  return (
    <Dialog
      {...props}
      scroll="body"
      fullWidth
      maxWidth="xl"
      fullScreen={isMobileView}
      onClose={onCloseDialog}
      aria-labelledby="max-width-dialog-title"
      className={classes.root}
    >
      <DialogTitle id="max-width-dialog-title">{t.translate('Product')}&nbsp;{product.id} — {supplierItem?.supplier?.name || '—'},&nbsp;{supplierItem?.supplier?.number || '—'} </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item style={{ marginRight: 10 }}>
            <PriceControl
              id={makeId('median-purchase-price')}
              name="medianPurchasePrice"
              label={t.translate('Median purchase price')}
              placeholder={'—'}
              value={values.medianPurchasePrice}
              disabled={true}
            />
          </Grid>
          <Grid item style={{ marginRight: 10 }}>
            <PriceControl
              id={makeId('purchase-price')}
              name="purchasePrice"
              label={t.translate('Purchase price')}
              placeholder={'—'}
              value={values.purchasePrice}
              error={errors.purchasePrice}
              onChange={e => {
                handleChange(e);
                updateSalesPricesDebounced.current(values, e.target.value);
              }}
              disabled={isLoading}
            />
          </Grid>
          <Grid item style={{ marginRight: 10 }}>
            <InputControl
              id={makeId('external-item-number')}
              name="externalItemNumber"
              error={errors.externalItemNumber}
              label={t.translate('Supplier item number')}
              value={values.externalItemNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item style={{ marginRight: 10 }}>
            <InputControl
              id={makeId('superdeal-max-order-amount')}
              name="superdealMaxOrderAmount"
              type="number"
              error={errors.superdealMaxOrderAmount}
              label={t.translate('Superdeal max order amount')}
              value={values.superdealMaxOrderAmount}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid>
          <CheckboxControl
            className={classes.checkboxContainer}
            id={makeId(`is-replenishment-suggestions-enabled`)}
            name={`isReplenishmentSuggestionsEnabled`}
            checked={Boolean(values.isReplenishmentSuggestionsEnabled)}
            onChange={handleChange}
            label={t.translate('Automatic warehouse replenishment')}
          />
        </Grid>

        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t.translate('Country')}</TableCell>
                <TableCell>{t.translate('Active')}</TableCell>
                <TableCell align="center">{t.translate('Stock')}</TableCell>
                <TableCell align="center">{t.translate('Last submitted')}</TableCell>
                <TableCell align="center">{t.translate('Cheapest')}</TableCell>
                <TableCell align="center">{t.translate('2nd Cheapest')}</TableCell>
                <TableCell align="center">{t.translate('3rd Cheapest')}</TableCell>
                <TableCell align="center">{t.translate('Calculate sales price')}</TableCell>
                <TableCell align="center">{t.translate('Sales price')}</TableCell>
                <TableCell align="center">{t.translate('Superdeal active')}</TableCell>
                <TableCell align="center">{t.translate('Superdeal price')}</TableCell>
                <TableCell align="center">{t.translate('Superdeal promotion price')}</TableCell>
                <TableCell align="center">{t.translate('Superdeal start')}</TableCell>
                <TableCell align="center">{t.translate('Superdeal end')}</TableCell>
                <TableCell align="center">{t.translate('Update')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activeMarketplaces && values.countrySalesPrices.map((countrySalesPrice, index) => (
                <TableRow key={`position-${index}`}>
                  <TableCell>{t.translate(`country:${countrySalesPrice.countryCode}`)}</TableCell>
                  <TableCell className={classes.checkboxContainer}>
                    <CheckboxControl
                      className={classes.checkboxContainer}
                      id={makeId(`countrySalesPrices[${index}]-isProductActiveInMarketplace`)}
                      name={`countrySalesPrices[${index}].isProductActiveInMarketplace`}
                      checked={Boolean(countrySalesPrice.isProductActiveInMarketplace)}
                      onChange={handleChange}
                      disabled={isLoading}
                    />
                  </TableCell>
                  <TableCell align="center">{countrySalesPrice.stock || '—'}</TableCell>
                  <TableCell align="center">
                    {<>{t.formatPrice(countrySalesPrice.lastSubmittedPrice)} &euro;</> || '—'}
                  </TableCell>
                  <TableCell align="center">
                    {<>{t.formatPrice(countrySalesPrice.cheapestMarketPrice)} &euro;</> || '—'}
                  </TableCell>
                  <TableCell align="center">
                    {<>{t.formatPrice(countrySalesPrice.cheapestMarketPrice2)} &euro;</>|| '—'}
                  </TableCell>
                  <TableCell align="center">
                    {<>{t.formatPrice(countrySalesPrice.cheapestMarketPrice3)} &euro;</>|| '—'}
                  </TableCell>
                  <TableCell className={classes.checkboxContainer} align="center">
                    <CheckboxControl
                      id={makeId(`countrySalesPrices[${index}]-isAutoCalculateSalesPrice`)}
                      name={`countrySalesPrices[${index}].isAutoCalculateSalesPrice`}
                      checked={Boolean(countrySalesPrice.isAutoCalculateSalesPrice)}
                      disabled={isLoading || !countrySalesPrice.supplierIsAutoCalculateSalesPrice}
                      onChange={async (event) => {
                        handleChange(event);
                        if (event.target.checked) {
                          await updateSalesPrice(index);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell className={classes.priceContainer} align="center">
                    <PriceControl
                      id={makeId('sales-price', index)}
                      classNameError={'noErrorText'}
                      name={`countrySalesPrices[${index}].salesPrice`}
                      value={countrySalesPrice.salesPrice}
                      placeholder={'—'}
                      onChange={handleChange}
                      disabled={isLoading || Boolean(countrySalesPrice.isAutoCalculateSalesPrice)}
                      error={!!errors.countrySalesPrices && errors.countrySalesPrices[index]?.salesPrice}
                    />
                  </TableCell>
                  <TableCell className={classes.checkboxContainer} align="center">
                    <CheckboxControl
                      id={makeId('isSuperdealActive', index)}
                      name={`countrySalesPrices[${index}].isSuperdealActive`}
                      checked={Boolean(countrySalesPrice.isSuperdealActive)}
                      onChange={handleChange}
                      disabled={isLoading}
                    />
                  </TableCell>
                  <TableCell className={classes.priceContainer} align="center">
                    <PriceControl
                      id={makeId('sales-price', index)}
                      name={`countrySalesPrices[${index}].superdealPrice`}
                      classNameError={'noErrorText'}
                      error={!!errors.countrySalesPrices && errors.countrySalesPrices[index]?.superdealPrice}
                      value={countrySalesPrice.superdealPrice}
                      placeholder={'—'}
                      onChange={handleChange}
                      disabled={isLoading || !countrySalesPrice.isSuperdealActive}
                    />
                  </TableCell>
                  <TableCell className={classes.priceContainer} align="center">
                    <PriceControl
                      id={makeId('superdeal-promotion-price', index)}
                      name={`countrySalesPrices[${index}].superdealPromotionPrice`}
                      classNameError={'noErrorText'}
                      error={!!errors.countrySalesPrices && errors.countrySalesPrices[index]?.superdealPromotionPrice}
                      value={countrySalesPrice.superdealPromotionPrice}
                      placeholder={'—'}
                      onChange={handleChange}
                      disabled={isLoading || !countrySalesPrice.isSuperdealActive}
                    />
                  </TableCell>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={t.locale}>
                    <TableCell className={classes.dateContainer} align="center">
                      <DatePicker
                        style={{ marginTop: 0 }}
                        views={['year', 'month', 'day']}
                        id={makeId('superdeal-start-date')}
                        name={`countrySalesPrices[${index}].superdealStartDate`}
                        value={countrySalesPrice.superdealStartDate}
                        onChange={e => setFieldValue(`countrySalesPrices[${index}].superdealStartDate`, e)}
                        disabled={isLoading || !countrySalesPrice.isSuperdealActive}
                        {...getErrorProp(!!errors.countrySalesPrices && !!errors.countrySalesPrices[index]?.superdealStartDate)}
                      />
                    </TableCell>
                    <TableCell className={classes.dateContainer} align="center">
                      <DatePicker
                        views={['year', 'month', 'day']}
                        id={makeId('superdeal-end-date')}
                        name={`countrySalesPrices[${index}].superdealEndDate`}
                        value={countrySalesPrice.superdealEndDate}
                        onChange={e => setFieldValue(`countrySalesPrices[${index}].superdealEndDate`, e)}
                        disabled={isLoading || !countrySalesPrice.isSuperdealActive}
                        {...getErrorProp(!!errors.countrySalesPrices && !!errors.countrySalesPrices[index]?.superdealEndDate)}
                      />
                    </TableCell>
                  </LocalizationProvider>
                  <TableCell align="center">
                    <ActionButton
                      disabled={isLoading}
                      tooltipPlacement="bottom"
                      aria-label={t.translate('Update from marketplace')}
                      tooltip={t.translate('Update from marketplace')}
                      icon={<CloudDownloadIcon />}
                      onClick={async () => {
                        setIsLoading(true);
                        try {
                          await updateMarketplacePricesFromApi(index, countrySalesPrice.countryCode);
                          const { isAutoCalculateSalesPrice, supplierIsAutoCalculateSalesPrice } = values.countrySalesPrices[index];
                          if (isAutoCalculateSalesPrice && supplierIsAutoCalculateSalesPrice) {
                            await updateSalesPrice(index);
                          }
                        } finally {
                          setIsLoading(false);
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <DialogActions className={classes.dialogActions}>
          <Button color="primary" onClick={onCloseDialog} disabled={isLoading}>{t.translate('dialog-action:Cancel')}</Button>
          <BusyButton color="primary" submit onClick={handleSubmit} disabled={isLoading}>{t.translate('Save')}</BusyButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

ProductPricesDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  marketplaces: PropTypes.array.isRequired,
  product: PropTypes.shape({
    superdealMaxOrderAmount: PropTypes.number,
    externalItemNumber: PropTypes.string,
    manufacturerNumber: PropTypes.string,
    manufacturerName: PropTypes.string,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    fromApi: PropTypes.bool.isRequired,
    photo: PropTypes.object,
    type: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    name: PropTypes.string,
    ean: PropTypes.string,
    alzuraId: PropTypes.string,
    id: PropTypes.number.isRequired,
  }),
  prices: PropTypes.object.isRequired,
  canEditAutoSalesPrice: PropTypes.bool,
  canUpdateFromMarketplace: PropTypes.bool,
  productTypes: PropTypes.array,
};
