import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';

import { useTranslator } from 'i18n';

import {
  AppHeaderPrimaryToolbar,
  AppHeaderMenuButton,
  AppHeaderTitle,
  AppHeader,
} from 'components';

export const Header = ({ isMobile }) => {
  const t = useTranslator();

  return (
    <AppHeader>
      <AppHeaderPrimaryToolbar>
        {isMobile && <AppHeaderMenuButton edge="start" />}
        <AppHeaderTitle>{startCase(t.translate('Stock booking'))}</AppHeaderTitle>
      </AppHeaderPrimaryToolbar>
    </AppHeader>
  );
};

Header.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};
