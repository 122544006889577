import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth:'672px',
      },
    },
    '& .MuiFormControl-root': {
      marginTop: theme.spacing(2),
    },
  },
  checkboxContainer: {
    '& .MuiFormControlLabel-root': {
      display: 'block',
      marginLeft: 0,
      marginRight: 0,
    },
    '& .MuiFormControl-root': {
      marginTop: 0,
    },
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  priceContainer: {
    '& .MuiFormControl-root': {
      marginTop: 0,
    },
    '& .MuiInput-formControl': {
      marginTop: 0,
    },
  },
  dateContainer: {
    '& .MuiFormControl-root': {
      marginTop: 0,
    },
    '& .MuiInput-formControl': {
      marginTop: 0,
    },
    '& .MuiInputLabel-formControl': {
      transform: 'translate(0, 10px) scale(1)',
    },
  },
  dialogActions: {
    padding: theme.spacing(2),
  },
}));
