import api from '.';
import { extractListParams } from 'utils/querying';

export const fetchRetailers = params => api.get('/retailers', { params: extractListParams(params) });

export const createRetailer = payload => api.post('/retailers', payload);

export const fetchRetailer = retailerId => api.get(`/retailers/${retailerId}`);

export const modifyRetailer = (retailerId, payload) => api.put(`/retailers/${retailerId}`, payload);

export const deleteRetailer = retailerId => api.delete(`/retailers/${retailerId}`);

export default {
  deleteOne: deleteRetailer,
  modifyOne: modifyRetailer,
  fetchMany: fetchRetailers,
  createOne: createRetailer,
  fetchOne: fetchRetailer,
};
