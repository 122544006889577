import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslator } from 'i18n';
import {
  useScreen,
  useDialog,
  useList,
} from 'hooks';

import { ConfirmingDialog } from 'dialogs';
import {
  PrintingFab,
  DesktopView,
  MobileView,
} from 'views/pickup-sheets/overview';

import { printPickupSheetDocument } from 'services/pickup-sheets';
import { fetchPickupSheets } from 'services/pickup-sheets';
import { deletePickupSheet } from 'api/pickup-sheets';
import {
  selectAuthenticatedUserWarehouse,
  selectActionPermission,
} from 'store/selectors/account';

import toaster from 'services/toaster';
import tables from 'config/tables';

export const PickupSheetsOverview = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const screen = useScreen();
  const list = useList(tables.pickupSheets.pickupSheets);
  const t = useTranslator();

  const canDeletePickupSheet = useSelector(selectActionPermission('pickupSheets', 'delete'));
  const canPrintPickupSheet = useSelector(selectActionPermission('pickupSheets', 'print'));
  const warehouse = useSelector(selectAuthenticatedUserWarehouse);

  const confirmingDialog = useDialog(null, { pickupSheet: null });

  const fetchData = useCallback(() => void dispatch(fetchPickupSheets(list.compile())), [dispatch, list]);

  useEffect(() => void fetchData(), [fetchData, location.search, warehouse]);

  const removePickupSheet = async () => {
    try {
      await deletePickupSheet(confirmingDialog.options.pickupSheet.id);
      toaster.success(t.translate('Pickup sheet has been deleted successfully.'));
      fetchData();
    } catch (err) {}
  };

  return (
    <>
      <Helmet>
        <title>{t.translate('Pickup sheets')} | {t.translate('app:title')}</title>
      </Helmet>

      {canPrintPickupSheet && <PrintingFab />}

      {canDeletePickupSheet && (
        <ConfirmingDialog
          {...confirmingDialog.props}
          title={t.translate('Delete pickup sheet')}
          acceptButtonText={t.translate(`Yes, I'm sure`)}
          declineButtonText={t.translate('No, cancel')}
          onAccept={removePickupSheet}
        >
          {t.translate('Are you sure you want to delete the pickup sheet?')}
        </ConfirmingDialog>
      )}

      {screen.isMobile ? (
        <MobileView
          onPickupSheetDelete={pickupSheet => confirmingDialog.open(null, { pickupSheet })}
          onPickupSheetPrint={printPickupSheetDocument}
        />
      ) : (
        <DesktopView
          onPickupSheetDelete={pickupSheet => confirmingDialog.open(null, { pickupSheet })}
          onPickupSheetPrint={printPickupSheetDocument}
        />
      )}
    </>
  );
};
