import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslator } from 'i18n';

import {
  AppHeaderPrimaryToolbar,
  AppHeaderBackButton,
  AppHeaderTitle,
  AppHeaderRightBox,
  AppHeader,
} from 'components';

import { MobileControls } from './MobileControls/MobileControls';
import { DesktopControls } from './DesktopControls/DesktopControls';

export const Header = ({
  order,
  isMobile,
  ...props
}) => {
  const history = useHistory();
  const t = useTranslator();
  const [isOrderHasReturns, setIsOrderHasReturns] = useState(false);

  useEffect(() => {
    setIsOrderHasReturns(order?.returns?.length !== 0);
  }, [order]);

  return (
    <AppHeader>
      <AppHeaderPrimaryToolbar>
        <AppHeaderBackButton edge="start" onClick={() => history.goBack()}/>
        <AppHeaderTitle>{!isMobile ? `${t.translate('Order')}: ${order?.number}` : order?.number}</AppHeaderTitle>
        {order && (
          <>
            <AppHeaderRightBox alignItems="center">
              {isMobile ? (
                <MobileControls {...props} order={order} isOrderHasReturns={isOrderHasReturns} />
              ) : (
                <DesktopControls {...props} order={order} isOrderHasReturns={isOrderHasReturns} />
              )}
            </AppHeaderRightBox>
          </>
        )}
      </AppHeaderPrimaryToolbar>
    </AppHeader>
  );
};
