export const REPORTS = {
  OVERVIEW: {
    SET_STATISTICS_REPORTS: 'REPORTS:overview:set-statistics-reports',
    SET_WAREHOUSE_INVENTORY_REPORTS: 'REPORTS:overview:set-warehouse-inventory-reports',
  },
};

export const SPECIAL_CASES = {
  SET_ACTIVE_SPECIAL_CASE: 'SPECIAL-CASES:set-active-special-case',
  SET_SPECIAL_CASES: 'SPECIAL-CASES:set-special-cases',
  SET_HISTORY: 'SPECIAL-CASES:set-history',
  SET_USERS: 'SPECIAL-CASES:set-users',
};

export const PICKUP_SHEETS = {
  UPDATE_ACTIVE_PICKUP_SHEET_PRODUCT: 'PICKUP-SHEETS:update-active-pickup-sheet-product',
  UPDATE_ACTIVE_PICKUP_SHEET: 'PICKUP-SHEETS:update-active-pickup-sheet',
  SET_ACTIVE_PICKUP_SHEET: 'PICKUP-SHEETS:set-active-pickup-sheets',
  SET_PICKUP_SHEETS: 'PICKUP-SHEETS:set-pickup-sheets',
};

export const WAREHOUSES = {
  OVERVIEW: {
    SET_WAREHOUSES: 'WAREHOUSES:overview:set-warehouses',
  },
  DETAILS: {
    SET_WAREHOUSE_SHIPPING_PARTNER_AVAILABILITY: 'WAREHOUSES:details:set-warehouse-shipping-partner-availability',
    SET_WAREHOUSE: 'WAREHOUSES:details:set-warehouse',
  },
};

export const SUPPLIERS = {
  OVERVIEW: {
    SET_SUPPLIERS: 'SUPPLIERS:overview:set-suppliers',
  },
  DETAILS: {
    SET_SUPPLIER: 'SUPPLIERS:details:set-supplier',
  },
};

export const DASHBOARD = {
  SET_STATISTICS: 'DASHBOARD.set-statistics',
  SET_PRODUCT_FUNNEL: 'DASHBOARD.set-product-funnel',
};

export const CUSTOMERS = {
  SET_ACTIVE_CUSTOMER_LOADING: 'CUSTOMERS:set-active-customer-loading',
  SET_CUSTOMERS_LOADING: 'CUSTOMERS:set-customers-loading',
  SET_ACTIVE_CUSTOMER: 'CUSTOMERS:set-active-customer',
  SET_CUSTOMERS: 'CUSTOMERS:set-customers',
};

export const STORAGES = {
  OVERVIEW: {
    SET_WAREHOUSES: 'STORAGES:overview:set-warehouses',
    SET_STORAGES: 'STORAGES:overview:set-storages',
  },
  DETAILS: {
    SET_STORAGE: 'STORAGES:details:set-storage',
    SET_STORAGE_PRODUCTS: 'STORAGES:details:set-storage-products',
    CLEAR_STORAGE_PRODUCTS: 'STORAGES:details:clear-storage-products',
  },
};

export const PRODUCTS = {
  SET_STORAGE_PRODUCT: 'PRODUCTS:history.set-storage-product',
  SET_STORAGES: 'PRODUCTS:set-storages',
  SET_PRODUCTS: 'PRODUCTS:set-products',
  SET_HISTORY: 'PRODUCTS:set-history',
  SET_PRODUCT: 'PRODUCTS:storages.set-product',
  CLEAR_STORAGES: 'PRODUCTS:clear-storages',
  CLEAR_HISTORY: 'PRODUCTS:clear-history',
  SET_SUPPLIERS: 'PRODUCTS:set-suppliers',
  SET_PRODUCT_PRICES: 'PRODUCTS:set-product-prices',
  CLEAR_PRICES: 'PRODUCTS:clear-prices',
};

export const PURCHASES = {
  OVERVIEW: {
    SET_PURCHASES: 'PURCHASES:overview:set-purchases',
    SET_IS_LOADING_PURCHASES: 'PURCHASES:overview:set-is-loading-purchases',
  },
  DETAILS: {
    SET_PURCHASE: 'PURCHASES:details:set-purchase',
    SET_PURCHASE_POSITIONS: 'PURCHASES:details:set-purchase-positions',
  },
};

export const REPLENISHMENT_SUGGESTIONS = {
  OVERVIEW: {
    SET_REPLENISHMENT_SUGGESTIONS: 'REPLENISHMENT_SUGGESTIONS:overview:set-replenishment-suggestions',
  },
};

export const OUTDATED_PRODUCTS = {
  OVERVIEW: {
    SET_OUTDATED_PRODUCTS: 'OUTDATED_PRODUCTS:overview:set-outdated-products',
  },
};

export const ACCOUNT = {
  SET_DEFAULT_VIEW: 'ACCOUNT:set-default-view',
  SET_DEFAULT_PRODUCT_GROUPS: 'ACCOUNT:set-default-product-groups',
  SET_WAREHOUSE: 'ACCOUNT.set-warehouse',
  SET_LOCALE: 'ACCOUNT:set-locale',
  UPDATE: 'ACCOUNT:update',
};

export const ORDERS = {
  SET_ACTIVE_ORDER: 'ORDERS:set-active-order',
  SET_STATUSES: 'ORDERS:set-statuses',
  SET_ORDERS: 'ORDERS:set-orders',
  SET_LOADING_ORDERS: 'ORDERS:set-loading-orders',
};

export const RAW_ORDERS = {
  SET_RAW_ORDERS: 'RAW_ORDERS:set-raw-orders',
  SET_IS_LOADING: 'RAW_ORDERS:set-is-loading',
};

export const CHANGES_HISTORY = {
  SET_CHANGES_HISTORY: 'CHANGES_HISTORY:set-changes-history',
};

export const USERS = {
  OVERVIEW: {
    SET_USERS: 'USERS:overview:set-users',
  },
  DETAILS: {
    SET_USER_WAREHOUSE_AVAILABILITY: 'USERS:details:set-user-warehouse-availability',
    SET_WAREHOUSES: 'USERS:details:set-warehouses',
    SET_USER: 'USERS:details:set-user',
  },
};

export const COMPANIES = {
  OVERVIEW: {
    SET_COMPANIES: 'COMPANIES:overview:set-companies',
  },
  DETAILS: {
    SET_COMPANY: 'COMPANIES:details:set-company',
  },
};

export const I18N = {
  SET_MESSAGES: 'I18N:set-messages',
  SET_LOCALE: 'I18N:set-locale',
};

export const APP = {
  TOGGLE_NAVIGATION_VISIBILITY: 'APP:toggle-navigation-visibility',
  SET_NAVIGATION_VISIBILITY: 'APP:set-navigation-visibility',
  WRITE_HEADER_HEIGHT: 'APP:write-header-height',
  SET_API_VERSION: 'APP:api-version',
  SET_LOADING: 'APP.set-loading',
};

export const RETAILERS = {
  OVERVIEW: {
    SET_RETAILERS: 'RETAILERS:overview:set-retailers',
  },
  DETAILS: {
    SET_RETAILER: 'RETAILERS:details:set-retailer',
  },
};
