import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from 'i18n';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FormControlError } from 'components';

export const ProductTypeControl = ({
  onChange,
  product,
  value,
  types,
  error,
  ...props
}) => {
  const t = useTranslator();

  const change = ({ target: { value } }) => onChange(value ? Number(value) : null);

  return (
    <FormControl fullWidth error={!!error}>
      <InputLabel shrink htmlFor={props.id}>{t.translate('Product type')}</InputLabel>
      <Select {...props} value={value} onChange={change}>
        <MenuItem value={0}>{t.translate('No type')}</MenuItem>
        {product?.type?.id === value && !types.some(({ id }) => id === value) && (
          <MenuItem key={value} value={value} disabled>
            {product.type.code} — {t.translate(`productType:${product.type.code}`)}
          </MenuItem>
        )}
        {types.map(({ code, id }) => (
          <MenuItem key={id} value={id}>{code} — {t.translate(`productType:${code}`)}</MenuItem>
        ))}
      </Select>
      {error && <FormControlError error={error} />}
    </FormControl>
  );
};

const ProductTypePropType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
});

ProductTypeControl.propTypes = {
  onChange: PropTypes.func.isRequired,
  product: PropTypes.shape({
    type: ProductTypePropType,
  }),
  value: PropTypes.number.isRequired,
  types: PropTypes.arrayOf(ProductTypePropType).isRequired,
  error: PropTypes.any,
};
