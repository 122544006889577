import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: theme.palette.backgrounds.grey[240],
    margin: theme.spacing(2, 0, 1),
    width: '100%',
  },
  storageItem: {
    borderBottom: `1px solid ${theme.palette.grey[250]}`,
    '&.MuiGrid-item': {
      padding: theme.spacing(1, 2),
    },
  },
}));
