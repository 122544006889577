import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { makeStyles } from 'tss-react/mui';
import {
  Grid,
  Input,
  Button,
  Popover,
  TableHead,
  TableBody,
  Typography,
  IconButton,
  FormControl,
  TableContainer,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { FormControlError, Table, TableCell, TableRow } from '../../../components';
import { fetchCountryFees, modifyCountryFees } from '../../../api/country-fees';
import { useTranslator } from '../../../i18n';
import toaster from '../../../services/toaster';

const useStyles = makeStyles()(theme => ({
  actionsItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  hint: {
    maxWidth: 320,
  },
  cell: {
    verticalAlign: 'top',
    padding: theme.spacing(2, 1),
  },
}));

export const ReturnFeesConfiguration = () => {
  const [initialValues, setInitialValues] = useState({
    countryFees: [],
  });

  const t = useTranslator();
  const { classes } = useStyles();

  useEffect(() => {
    fetchCountryFees().then( res => {
      setInitialValues({
        countryFees: res.data,
      });
    });
  }, []);

  const validationSchema = Yup
    .object()
    .shape({
      countryFees: Yup.array().of(
        Yup.object().shape({
          fixedReturnFee: Yup.number({ min: 0 }).label(t.translate('Fixed fee')).typeError(t.translate('Fixed fee must be a number')),
          variableReturnFee: Yup.number({ min: 0 }).label(t.translate('Variable fee')).typeError(t.translate('Variable fee must be a number')),
        })
      ),
    })
  ;

  const onSubmit = async () => {
    try {
      await modifyCountryFees({ countryFees: values.countryFees });
      toaster.success(t.translate('Return fees have been updated successfully'));
    } catch (err) {
      toaster.error(Object.values(err.collection)[0][0]);
    }
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    enableReinitialize: true,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  const [anchorEl, setAnchorEl] = useState({ infoFixed: null, infoVariable: null });

  const handlePopoverOpen = (name, event) => {
    event.persist();
    setAnchorEl(prevState => ({
      ...prevState,
      [name]: event.currentTarget,
    }));
  };

  const handlePopoverClose = (name) => {
    setAnchorEl(prevState => ({
      ...prevState,
      [name]: null,
    }));
  };

  return <>
    <Grid item>
      <Typography variant="h6">
        {t.translate('Return fees')}
      </Typography>
    </Grid>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell name="country" className={classes.countryCell}>
              {t.translate('Country')}
            </TableCell>
            <TableCell name="fixed">
              <Grid item>
                {t.translate('Fee (fixed) per package')}
                <IconButton
                  aria-label="hint"
                  aria-owns={Boolean(anchorEl.infoFixed) ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={e => handlePopoverOpen('infoFixed', e)}
                  onMouseLeave={() => handlePopoverClose('infoFixed')}
                  onClick={e => handlePopoverOpen('infoFixed', e)}
                  size="large">
                  <InfoIcon />
                </IconButton>
                <Popover
                  id="mouse-over-popover"
                  className={classes.popover}
                  classes={{ paper: classes.paper }}
                  open={Boolean(anchorEl.infoFixed)}
                  anchorEl={anchorEl.infoFixed}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  onClose={() => handlePopoverClose('infoFixed')}
                  disableRestoreFocus
                >
                  <Typography variant="body2" className={classes.hint}>
                    {t.translate('fixedReturnFee:info')}
                  </Typography>
                </Popover>
              </Grid>
            </TableCell>
            <TableCell name="variable">
              <Grid item>
                {t.translate('Fee (%) on ordered value')}
                <IconButton
                  aria-label="hint"
                  aria-owns={Boolean(anchorEl.infoVariable) ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={e => handlePopoverOpen('infoVariable', e)}
                  onMouseLeave={() => handlePopoverClose('infoVariable')}
                  onClick={e => handlePopoverOpen('infoVariable', e)}
                  size="large">
                  <InfoIcon />
                </IconButton>
                <Popover
                  id="mouse-over-popover"
                  className={classes.popover}
                  classes={{ paper: classes.paper }}
                  open={Boolean(anchorEl.infoVariable)}
                  anchorEl={anchorEl.infoVariable}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  onClose={() => handlePopoverClose('infoVariable')}
                  disableRestoreFocus
                >
                  <Typography variant="body2" className={classes.hint}>
                    {t.translate('variableReturnFee:info')}
                  </Typography>
                </Popover>
              </Grid>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.countryFees.map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                <Typography>
                  {t.translate(`country:${item.countryCode}`)}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <FormControl
                  error={!!errors?.countryFees && !!errors.countryFees[index]?.fixedReturnFee}
                >
                  <Input
                    type="number"
                    id={'fixed-return-fee-input'}
                    name={`countryFees[${index}].fixedReturnFee`}
                    value={item.fixedReturnFee}
                    onChange={handleChange}
                  />
                  {!!errors?.countryFees && !!errors.countryFees[index]?.fixedReturnFee &&
                    <FormControlError error={errors.countryFees[index].fixedReturnFee}/>
                  }
                </FormControl>
              </TableCell>
              <TableCell className={classes.cell}>
                <FormControl error={!!errors?.countryFees && !!errors.countryFees[index]?.variableReturnFee}>
                  <Input
                    type="number"
                    id={'variable-return-fee-input'}
                    name={`countryFees[${index}].variableReturnFee`}
                    value={item.variableReturnFee}
                    onChange={handleChange}
                  />
                  {!!errors?.countryFees && !!errors.countryFees[index]?.variableReturnFee &&
                    <FormControlError error={errors.countryFees[index].variableReturnFee}/>
                  }
                </FormControl>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid item className={classes.actionsItem}>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          onClick={handleSubmit}
        >
          {t.translate('Save')}
        </Button>
      </Grid>
    </TableContainer>
  </>;
};
