import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from '../../../../../i18n';

import { useStyles } from './OrderItem.jsx';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

import { OrderDetails } from '../OrderDetails/OrderDetails';

export const OrderItem = ({ order }) => {
  const { classes } = useStyles();
  const t = useTranslator();

  return (
    <Accordion className={classes.root}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container direction="row">
          <Grid item xs={8}>
            <Typography>{t.translate('Order no')}: {order.number}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.detailsPanel}>
        <OrderDetails
          className={classes.orderDetails}
          order={order}
        />
      </AccordionDetails>
    </Accordion>
  );
};

OrderItem.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string.isRequired,
  }).isRequired,
};
