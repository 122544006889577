import suppliersApi from 'api/suppliers';
import {
  startLoading,
  stopLoading,
} from 'store/actions/app';
import {
  setOverviewSuppliers,
  setDetailsSupplier,
} from 'store/actions/suppliers';

export const fetchSuppliers = params => async dispatch => {
  try {
    dispatch(startLoading('suppliers.overview.suppliers'));
    const suppliers = await suppliersApi.fetchMany(params);
    dispatch(setOverviewSuppliers(suppliers));
    dispatch(stopLoading('suppliers.overview.suppliers'));
  } catch (err) {
    dispatch(stopLoading('suppliers.overview.suppliers'));
  }
};

export const fetchSuppliersForFilter = () => async dispatch => {
  try {
    dispatch(startLoading('suppliers.overview.suppliers'));
    const suppliers = await suppliersApi.fetchManyForFilter();
    dispatch(setOverviewSuppliers(suppliers));
    dispatch(stopLoading('suppliers.overview.suppliers'));
  } catch (err) {
    dispatch(stopLoading('suppliers.overview.suppliers'));
  }
};

export const fetchSupplier = supplierId => async dispatch => {
  try {
    dispatch(startLoading('suppliers.details.supplier'));
    const supplier = await suppliersApi.fetchOne(supplierId);
    dispatch(setDetailsSupplier(supplier));
    dispatch(stopLoading('suppliers.details.supplier'));
  } catch (err) {
    dispatch(stopLoading('suppliers.details.supplier'));
  }
};
