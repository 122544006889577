export const tabHasError = (tab, errors) => {
  if (!errors) return false;

  return (tab.label === 'Address' && errors.invoiceAddress)
    || (tab.label === 'General' && (errors.name || errors.number || errors.contactInfos))
    || (tab.label === 'Account' && (errors.affiliateId || errors.companyId))
    || (tab.label === 'Details' && (errors.name || errors.address?.country || errors.email || errors.websiteUrl))
    || (tab.label === 'DATEV Settings' && (errors.DATEVNumberLength))
    || (tab.label === 'VAT Settings' && (errors.VATSettings))
    || (tab.label === 'Document numbers' && (errors.documentSettings));
};
