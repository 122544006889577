import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
  useWindowSize,
  useScreen,
  didUpdate,
} from 'hooks';

import { makeStyles } from 'tss-react/mui';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';

import { writeHeaderHeight } from 'store/actions/app';
import clsx from 'clsx';

export const useStyles = makeStyles()(theme => ({
  root: {
    boxShadow: theme.props.shadows[0],
  },
  subdued: {
    paddingLeft: theme.spacing(9),
    transition: theme.transitions.create('padding-left', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  reducedSubdued: {
    paddingLeft: theme.spacing(38),
    transition: theme.transitions.create('padding-left', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
}));

export const AppHeader = ({ children }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const screen = useScreen();

  const appBarRef = React.useRef(null);
  const [isRefSet, setIsRefSet] = useState(false);
  const [width] = useWindowSize();

  const isNavigationVisible = useSelector(({ app }) => app.navigation.visible);
  const headerHeight = useSelector(({ app }) => app.header.height);

  didUpdate(() => {
    dispatch(writeHeaderHeight(appBarRef.current.clientHeight));
  }, [isRefSet, width, children]);

  const setRef = ref => {
    const isRefSet = appBarRef.current === null && ref;
    appBarRef.current = ref;
    if (isRefSet) {
      setIsRefSet(true);
    }
  };

  const className = clsx(classes.root, !screen.isMobile && {
    [classes.reducedSubdued]: isNavigationVisible,
    [classes.subdued]: !isNavigationVisible,
  });

  return (
    <>
      <Box display="flex" flexShrink={0} height={headerHeight} width={1} />
      <AppBar ref={setRef} className={className}>
        {children}
      </AppBar>
    </>
  );
};
