import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const CardFeature = ({
  classes,
  value,
  label,
  omitColon,
  ...props
}) => (
  <Grid {...props} item container>
    <Grid item>
      {omitColon
        ? <Typography variant="body2" className={classes?.label}>{label}&nbsp;</Typography>
        : <Typography variant="body2" className={classes?.label}>{label}:&nbsp;</Typography>
      }
    </Grid>
    <Grid item>
      <Typography
        className={classes?.value}
        variant="body2"
      >
        {value}
      </Typography>
    </Grid>
  </Grid>
);

CardFeature.propTypes = {
  classes: PropTypes.shape({
    value: PropTypes.string,
  }),
  value: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  omitColon: PropTypes.bool,
};
