import React, { memo, useEffect, useMemo } from 'react';
import { FixedSizeList, areEqual } from 'react-window';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { fetchSuggestedStorages } from 'api/storages';
import { useHistory } from 'react-router-dom';

import TableContainer from '@mui/material/TableContainer';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import InfoIcon from '@mui/icons-material/Info';

import {
  StockFormControl,
  CircularLoader,
  OrderStatusBadge,
  ActionButton,
} from 'components';

import clsx from 'clsx';

import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import { convertDotStringToDate, simpleFormatDate } from 'utils/dates';
import { TyreTypes } from 'consts';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'block',
    flex: 1,
  },
  table: {
    height: '100%',
    width: '100%',
  },
  cell: {
    display: 'flex',
    flexBasis: '15%',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    border: 'none',
    flexShrink: 0,
    minWidth: 0,
    overflow: 'hidden',

    '&:first-of-type': {
      justifyContent: 'space-between',
      flexBasis: '5%',
    },

    '&:nth-of-type(2)': {
      flexBasis: '5%',
    },

    '&:nth-of-type(6)': {
      flexBasis: '20%',
    },

    '&:nth-of-type(7)': {
      flexBasis: '10%',
    },

    '&:last-child': {
      flexBasis: '5%',
    },
  },
  numberRow: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    backgroundColor: theme.palette.backgrounds.grey[240],
  },
  orderHeader: {
    width: '100%',
    fontWeight: 500,
  },
  orderNumber: {
    fontSize: theme.spacing(2),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    boxSizing: 'border-box',
    minWidth: '100%',
    width: '100%',
    justifyContent: 'space-between',
    boxShadow: '0 -1px 0 rgb(224, 224, 224)',
  },
  disable: {
    display: 'none',
  },
  storageRow: {
    backgroundColor: theme.palette.backgrounds.secondary,
  },
  orderStatusBadge: {
    position: 'absolute',
    right: '11px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  returnBadge: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(.5),
    borderRadius: theme.spacing(0.5),
    height: '26px',
    backgroundColor: '#2C3E50',
    '& .MuiChip-label': {
      color: theme.palette.common.white,
      paddingLeft: theme.spacing(.75),
    },
  },
  volumeBadge: {
    backgroundColor: '#E74C3C',
  },
  hidden: {
    visibility: 'hidden',
    pointerEvents: 'none',
  },
}));

const makeName = (id, name) => `${id}.${name}`;

const isNonTransitPositions = selectedPositions => {
  return selectedPositions[0]?.isPurchase
    || selectedPositions[0]?.isCancelledOrder
    || selectedPositions[0]?.isReturnedOrder;
};

const getDimensionData = (product) => {
  const dimensions = product?.dimensions?.[0];

  if (!dimensions) {
    return false;
  }

  return (
    <div>
      <div>{dimensions.width} cm x {dimensions.length} cm x {dimensions.height} cm</div>
      <div>{dimensions.weight} kg | {dimensions.volume / 1e6} m<sup>3</sup></div>
    </div>
  );
};

const Row = memo(({
  index,
  style,
  data: {
    order,
    positions,
    classes,
    setFieldValue,
    setIsCanMakePickupSheet,
    values,
    suggestedPurchaseStorages,
    onToggle,
    onStorageInfoClick,
    storageNumber,
    getSuggestedStorages,
    t,
  },
}) => {
  const position = positions[index];
  const ordersFormsPosition = values.ordersForms[order.id]?.[position.id];
  const storageMap = order.isPurchase
    ? suggestedPurchaseStorages.purchasePositionStorageMap
    : suggestedPurchaseStorages.orderPositionStorageMap;

  return (
    <TableRow
      style={style}
      key={position.id}
      component="div"
      className={classes.row}
    >
      <TableCell className={classes.cell} component="div">
        <Checkbox
          checked={position.checked || false}
          color="primary"
          onChange={({ target: { checked } }) => onToggle(checked, order, position, index)}
        />
      </TableCell>
      <TableCell className={classes.cell} component="div">{position.deliveredQuantity ? position.quantity - position.deliveredQuantity : position.quantity}</TableCell>
      <TableCell className={classes.cell} component="div">{position.product?.ean}</TableCell>
      <TableCell className={classes.cell} component="div">{position.product?.manufacturerNumber}</TableCell>
      <TableCell className={classes.cell} component="div">
        {
          getDimensionData(position.product)
          || <Chip className={clsx(classes.returnBadge, classes.volumeBadge)} label={t.translate('Volume scan required')}/>
        }
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.stockFormContainer)} component="div">
        {values.ordersForms[order.id] && (
          <StockFormControl
            hideLabels
            name={makeName(position.id, 'cancelledAmount')}
            actions={['add']}
            stock={0}
            disabled={!position.checked}
            value={ordersFormsPosition?.stock}
            topBound={position.deliveredQuantity ? position.quantity - position.deliveredQuantity : position.quantity}
            onChange={async value => {
              setFieldValue(`ordersForms.${order.id}.${position.id}.stock`, value);
              setFieldValue(`ordersForms.${order.id}.${position.id}.filledPosition`, value >= position.quantity);
              setIsCanMakePickupSheet(order, position, value);
            }}
          />
        )}
      </TableCell>
      <TableCell className={classes.cell} component="div">
        <FormControl
          error={(position.checked && TyreTypes.includes(ordersFormsPosition?.productType) && !ordersFormsPosition?.manufacturingDate) || ordersFormsPosition?.error}>
          <Input
            disabled={!position.checked || !TyreTypes.includes(ordersFormsPosition.productType)}
            placeholder={'week/year'}
            value={ordersFormsPosition?.manufacturingDate || ''}
            onChange={({ target: { value } }) => {
              let input = value.replace(/\D/g, '');
              if (input.length > 4) input = input.slice(0, 4);
              if (input.length > 2) input = input.slice(0, 2) + '/' + input.slice(2);
              setFieldValue(`ordersForms.${order.id}.${position.id}.manufacturingDate`, input);
              getSuggestedStorages({
                orderId: order.id,
                positionId: position.id,
                manufacturingDate: input,
              });
            }}
          />
        </FormControl>
      </TableCell>
      <TableCell component="div" className={classes.cell}>
        <ActionButton
          className={storageMap[position.id] && position.checked && !storageNumber ? '' : classes.hidden}
          tooltipPlacement="bottom"
          aria-label={t.translate('Storage details')}
          tooltip={t.translate('Storage details')}
          icon={<InfoIcon/>}
          onClick={() => storageMap[position.id] && onStorageInfoClick(storageMap[position.id])}
        />
      </TableCell>
    </TableRow>
  );
},
areEqual);

export const OrdersTable = ({
  values,
  orders,
  setFieldValue,
  isLoading,
  productEan,
  manufacturerNumber,
  orderNumber,
  setSelectedPositions,
  selectedPositions,
  suggestedPurchaseStorages,
  setSuggestedPurchaseStorages,
  deliveryDataFilter,
  setDeliveryDataFilter,
  onStorageInfoClick,
  storageNumber,
}) => {
  const t = useTranslator();
  const { classes } = useStyles();
  const history = useHistory();

  const getSuggestedStorages = async ({ orderId, positionId, manufacturingDate } = {}) => {
    if (isNonTransitPositions(selectedPositions)) {
      const orderPositionsData = [];
      const purchasePositionsData = [];

      selectedPositions.forEach(position=> {
        const positionManufacturingDate = position.orderId === orderId && position.id === positionId
          ? simpleFormatDate(convertDotStringToDate(manufacturingDate))
          : simpleFormatDate(convertDotStringToDate(values.ordersForms[position.orderId][position.id].manufacturingDate));
        const payload = {
          id: position.id,
          ...(manufacturingDate && { manufacturingDate: positionManufacturingDate }),
        };

        if (position.isCancelledOrder || position.isReturnedOrder) orderPositionsData.push(payload);

        if (position.isPurchase) purchasePositionsData.push(payload);
      });

      try {
        const suggestions = await fetchSuggestedStorages({
          orderPositionsData,
          purchasePositionsData,
        });

        setSuggestedPurchaseStorages(suggestions);
      } catch (err) {
        setSuggestedPurchaseStorages({
          orderPositionStorageMap: {},
          purchasePositionStorageMap: {},
        });
      }
    }
  };

  const openOrder = order => history.push(`/${order.isPurchase ? 'purchases' : 'orders'}/${order.id}`);

  const filteredOrders = useMemo(() => {
    if (!productEan && !manufacturerNumber && !orderNumber && !deliveryDataFilter) return orders;

    return orders.filter(order => {
      if (deliveryDataFilter
        && (
          order.customer.id !== deliveryDataFilter.customerId
          || order.deliveryAddress.id !== deliveryDataFilter.deliveryAddressId
          || order.shippingNeutral !== deliveryDataFilter.shippingNeutral
        )
      ) {
        return false;
      }

      if (!productEan && !manufacturerNumber && !orderNumber) {
        return true;
      }

      if (order.isPurchase) {
        order.filteredPositions = order.positions.filter(position => {
          return (productEan && position?.product?.ean?.includes(productEan))
            || (manufacturerNumber && position?.product?.manufacturerNumber?.includes(manufacturerNumber));
        });
      }

      if (orderNumber) return order.number.toLowerCase().includes(orderNumber.toLowerCase());

      if (order.isPurchase && order.filteredPositions.length) return true;

      return order.positions.some(position => {
        return (productEan && position?.product?.ean?.includes(productEan))
          || (manufacturerNumber && position?.product?.manufacturerNumber?.includes(manufacturerNumber));
      });
    });
  }, [orders, productEan, manufacturerNumber, orderNumber, deliveryDataFilter]);

  useEffect(() => {
    getSuggestedStorages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPositions]);

  const setIsCanMakePickupSheet = (order, position, value) => {
    const isCanMakePickupSheet = order.positions.every(({ id, quantity }) => {
      const stockValue = id === position.id ? value : values.ordersForms[order.id][id].stock;
      return quantity === stockValue;
    });

    order.isCanMakePickupSheet = isCanMakePickupSheet;
    setFieldValue(`ordersForms.${order.id}.isCanMakePickupSheet`, isCanMakePickupSheet);
  };

  const onToggle = (checked, order, position) => {
    setFieldValue(`ordersForms.${order.id}.${position.id}.selected`, checked);
    position.checked = checked;

    if (checked) {
      setSelectedPositions([
        ...selectedPositions,
        {
          ...position,
          orderId: order.id,
          orderCode: order.number,
          supplier: order.supplier,
          customer: order.customer,
          deliveryAddress: order.deliveryAddress,
          shippingNeutral: order.shippingNeutral,
          isPurchase: order.isPurchase,
          isCancelledOrder: order.isCancelledOrder,
          isReturnedOrder: order.isReturnedOrder,
        },
      ]);

      setDeliveryDataFilter({
        customerId: order.customer.id,
        deliveryAddressId: order.deliveryAddress.id,
        shippingNeutral: order.shippingNeutral,
      });

      setIsCanMakePickupSheet(order, position, values.ordersForms[order.id][position.id].stock);
    } else {
      order.isCanMakePickupSheet = false;
      setFieldValue(`ordersForms.${order.id}.isCanMakePickupSheet`, false);
      const newSelectedPositions = selectedPositions.filter(selectedPosition => selectedPosition.id !== position.id);
      setSelectedPositions(newSelectedPositions);

      if (!newSelectedPositions.length) {
        setDeliveryDataFilter(null);
      }
    }
  };

  if (isLoading) {
    return <CircularLoader />;
  } else if (!orders.length) {
    return <Typography variant="body2">{t.translate('No positions found')}</Typography>;
  } else {
    return (
      <TableContainer className={classes.root}>
        <Table className={classes.table} component="div">
          <TableHead component="div" className={classes.head}>
            <TableRow component="div" className={classes.row}>
              <TableCell component="div" className={classes.cell}>{t.translate('Select')}</TableCell>
              <TableCell component="div" className={classes.cell}>{t.translate('Ordered')}</TableCell>
              <TableCell component="div" align="center" className={classes.cell}>EAN</TableCell>
              <TableCell component="div" className={classes.cell}>{t.translate('Manufacturer no.')}</TableCell>
              <TableCell component="div" className={classes.cell}>{t.translate('Volume')}</TableCell>
              <TableCell component="div" align="center" className={classes.cell}>{t.translate('Stock')}</TableCell>
              <TableCell component="div" align="center" className={classes.cell}>{t.translate('DOT')}</TableCell>
              <TableCell component="div" align="center" className={classes.cell}>
                <ActionButton
                  tooltipPlacement="bottom"
                  aria-label={t.translate('Storage details')}
                  tooltip={t.translate('Storage details')}
                  icon={<InfoIcon />}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          {filteredOrders.map((order, index) => {
            const positions = (productEan || manufacturerNumber) && order.filteredPositions?.length ? order.filteredPositions : order.positions;

            return (
              <TableBody className={classes.tableBody} component="div" key={index}>
                <TableRow
                  component="div"
                  className={classes.numberRow}
                >
                  <TableCell component="div" colSpan={6} align="center" className={classes.orderHeader}>
                    <div className={classes.orderNumber}>
                      <Link onClick={() => openOrder(order)}>{order.number}</Link>
                    </div>
                    <div>{order.supplier.number} — {order.supplier.name}</div>
                  </TableCell>
                  {(order.isCancelledOrder || order.isReturnedOrder) && (
                    <TableCell component="div">
                      {order.isCancelledOrder && (<OrderStatusBadge className={classes.orderStatusBadge} order={order}/>)}
                      {order.isReturnedOrder && (<Chip className={clsx(classes.returnBadge, classes.orderStatusBadge)} label={t.translate('Returned')} />)}
                    </TableCell>
                  )
                  }
                </TableRow>
                <FixedSizeList
                  style={{ scrollbarGutter: 'stable' }}
                  // 59 - height of one row
                  height={Math.min(59 * positions.length, 600)}
                  itemSize={59}
                  itemCount={positions.length}
                  overscanCount={5}
                  itemData={{
                    order,
                    positions,
                    classes,
                    setFieldValue,
                    setIsCanMakePickupSheet,
                    values,
                    suggestedPurchaseStorages,
                    onToggle,
                    onStorageInfoClick,
                    storageNumber,
                    getSuggestedStorages,
                    t,
                  }}
                >
                  {Row}
                </FixedSizeList>
              </TableBody>
            );
          })}
        </Table>
      </TableContainer>
    );
  }
};

OrdersTable.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.string.isRequired,
      supplier: PropTypes.shape({
        number: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      positions: PropTypes.arrayOf(
        PropTypes.shape({
          quantity: PropTypes.number.isRequired,
          product: PropTypes.shape({
            ean: PropTypes.string,
            manufacturerNumber: PropTypes.string,
          }),
        })
      ),
    })
  ),
  isLoading: PropTypes.bool.isRequired,
};
