import React, { useEffect, useState } from 'react';
import { useTranslator } from 'i18n';
import { makeStyles } from 'tss-react/mui';
import {
  CircularLoader,
  PriceControl,
  Table,
  TableCell,
  TableRow,
} from 'components';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import { Button, FormControl } from '@mui/material';
import { fetchShippingPartnersSurcharges, modifyShippingPartnersSurcharges } from '../../../api/shipping-partners';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toaster from 'services/toaster';
import { modifyCountries } from 'api/country';

const useStyles = makeStyles()({
  actionsItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  textarea: {
    border: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: 2,
    fontSize: 16,
    '&:hover': {
      outline: '1px solid black',
      border: '1px solid black',
    },
    '&:focus': {
      outline: '1px solid #7592d6',
      border: '1px solid #7592d6',
    },
  },
  cell: {
    verticalAlign: 'top',
  },
  partnersCell: {
    width: 250,
  },
  countryCell: {
    width: 120,
  },
});

export const ShippingPartners = () => {
  const [initialValues, setInitialValues] = useState({
    shippingPartners: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  const t = useTranslator();
  const { classes } = useStyles();

  const validationSchema = Yup
    .object()
    .shape({
      shippingPartners: Yup.array().of(
        Yup.object().shape({
          zipCodes: Yup.string(),
          surcharges: Yup.array().of(
            Yup.object().shape({
              surcharge: Yup
                .number()
                .transform((_value, originalValue) => Number(originalValue.replace(/,/, '.')))
                .positive()
                .label(t.translate('Surcharge'))
                .typeError(t.translate('Surcharge must be a number'))
                .required(),
            })
          ),
        })
      ),
    })
  ;

  const onSubmit = async () => {
    try {
      const surcharges = [];
      const countries = [];
      values.shippingPartners.forEach(country => {
        countries.push({
          id: country.id,
          zipCodes: country.zipCodes,
        });
        country.surcharges.forEach(surcharge => {
          surcharges.push({
            shippingPartnerId: surcharge.shippingPartner.id,
            countryId: country.id,
            surcharge: Number(surcharge.surcharge.replace(/,/, '.')),
          });
        });
      });

      await modifyShippingPartnersSurcharges({ surcharges });
      await modifyCountries({ countries });
      toaster.success(t.translate('Shipping surcharge has been updated successfully'));
    } catch (err) {
      toaster.error(Object.values(err.collection)[0][0]);
    }
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    enableReinitialize: true,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    fetchShippingPartnersSurcharges()
      .then(res => {
        setInitialValues({ shippingPartners: res });
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <CircularLoader/>;

  return <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell name="country" className={classes.countryCell}>
            {t.translate('Country')}
          </TableCell>
          <TableCell name="zip">
            {t.translate('Zip codes')}
          </TableCell>
          <TableCell name="shipping_partners" className={classes.partnersCell}>
            {t.translate('Shipping partners')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {values.shippingPartners.map((item, index) => (
          <TableRow key={index}>
            <TableCell className={classes.cell}>
              <Typography>
                {t.translate(`country:${item.countryCode}`)}
              </Typography>
            </TableCell>
            <TableCell className={classes.cell}>
              <FormControl fullWidth error={!!errors.shippingPartners}>
                  <textarea
                    className={classes.textarea}
                    name={`shippingPartners[${index}].zipCodes`}
                    value={item.zipCodes}
                    onChange={handleChange}
                    rows={7}
                  />
              </FormControl>
            </TableCell>
            <TableCell className={classes.cell}>
              {
                item.surcharges.map((elem, i) => (
                    <Grid item key={i}>
                      <PriceControl
                        id={`shipping-partners${index}${i}`}
                        name={`shippingPartners[${index}].surcharges[${i}].surcharge`}
                        error={errors?.shippingPartners && errors.shippingPartners[index]?.surcharges[i]?.surcharge}
                        value={elem.surcharge}
                        onChange={handleChange}
                        label={elem.shippingPartner.name}
                        style={{ marginBottom: 10 }}
                        placeholder={t.translate('Surcharge')}
                      />
                    </Grid>
                  )
                )
              }
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <Grid item className={classes.actionsItem}>
      <Button
        type="submit"
        color="secondary"
        variant="contained"
        onClick={handleSubmit}
      >
        {t.translate('Save')}
      </Button>
    </Grid>
  </TableContainer>;
};
