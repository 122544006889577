import React from 'react';
import PropTypes from 'prop-types';

import { CircularLoader } from 'components';
import { Column } from 'layouts';

import { CustomersTable } from './CustomersTable/CustomersTable';

export const DesktopView = ({ loading, ...props }) => (
  <Column p={3}>
    {loading
      ? <CircularLoader />
      : <CustomersTable {...props} />}
  </Column>
);

DesktopView.propTypes = {
  loading: PropTypes.bool.isRequired,
};
