import React, { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslator } from '../../i18n';
import { DialogContent } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ActionButton, BusyButton, InputControl } from '../../components';
import { useFormik } from 'formik';
import toaster from '../../services/toaster';
import * as Yup from 'yup';
import { makeStyles } from 'tss-react/mui';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { fetchAlzuraSupplier, modifySupplier } from '../../api/suppliers';
import PropTypes from 'prop-types';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const makeId = postfix => `contact-info-dialog--${postfix}`;

const useStyles = makeStyles()(theme =>  ({
  priceSection: {
    marginBottom: theme.spacing(1),
  },
  containerLabel: {
    fontWeight: 500,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  actionsGrid: {
    marginBottom: theme.spacing(3),
  },
  checkboxContainer: {
    '& .MuiFormControlLabel-root': {
      display: 'block',
      marginLeft: 0,
      marginRight: 0,
    },
    '& .MuiFormControl-root': {
      marginTop: 0,
    },
  },
  noMargin: {
    marginTop: '0 !important',
  },
  cellVerticalAlign: {
    verticalAlign: 'top',
  },
  dialogActions: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  floatingActionButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: '8px 24px',
  },
}));

export const ContactInfoDialog = ({
  onClose,
  onSave,
  supplier,
  activeMarketplaces,
  isMobileView,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const [initialValues, setInitialValues] = useState({
    contactInfos: activeMarketplaces?.map(marketplace => ({
      marketplaceCountryId: marketplace.id,
      countryCode: marketplace.country.countryCode,
      email: '',
      invoiceEmail: '',
      contactPerson: '',
      phone: '',
      fax: '',
      ustId: '',
      taxId: '',
      taxArea: '',
      bankName: '',
      bankHolder: '',
      bankIBAN: '',
      bankBIC: '',
    })) ?? [],
  });

  const onSupplierSave = () => {
    toaster.success(t.translate('Supplier\'s contact info has been saved successfully.'));
  };

  const onSubmit = async () => {
    try {
      const payload = {
        ...supplier,
        ...supplier.address,
        contactInfos: values.contactInfos.map(value => ({
          ...value,
        })),
      };

      if (supplier) {
        await modifySupplier(supplier.id, payload);
        onSupplierSave();
      }

      onSave();
      onClose();
    } catch (err) {
      toaster.error(Object.values(err.collection)[0][0]);
    }
  };

  const validationSchema = Yup
    .object()
    .shape({
      contactInfos: Yup.array().of(
        Yup.object().shape({
          email: Yup.string().email(),
          invoiceEmail: Yup.string().email(),
        })
      ),
    });

  const {
    setValues,
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    enableReinitialize: true,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  const handleAlzuraSupplierFetch = async () => {
    const alzuraSupplier = await fetchAlzuraSupplier(supplier.number);

    if (alzuraSupplier) {
      setValues({
        contactInfos: activeMarketplaces?.map(marketplace => {
          const countryData = alzuraSupplier?.countriesMap[marketplace.country.countryCode.toUpperCase()];

          return ({
            marketplaceCountryId: marketplace.id,
            countryCode: marketplace.country.countryCode,
            email: countryData.email,
            invoiceEmail: countryData.invoiceEmail,
            contactPerson: countryData.contactPerson,
            phone: countryData.phone,
            fax: countryData.fax,
            ustId: countryData.ustId,
            taxId: countryData.taxId,
            taxArea: countryData.taxArea,
            bankName: countryData.bank?.name,
            bankHolder: countryData.bank?.holder,
            bankIBAN: countryData.bank?.IBAN,
            bankBIC: countryData.bank?.BIC,
          });
        }),
      });
    }
    else {
      toaster.warning(t.translate('Unable to fetch supplier data.'));
    }
  };

  useEffect(() => {
    if (supplier){
      const contactInfos = supplier.contactInfos
        .filter(item => item.marketplaceCountry.isActive)
        .map((item) => ({
          ...item,
          countryCode: item.marketplaceCountry.countryCode,
        }));
      setInitialValues({ contactInfos });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier]);

  return (
    <Dialog
      {...props}
      scroll="body"
      fullWidth
      maxWidth="xl"
      fullScreen={isMobileView}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">
        <Grid container>
          {t.translate('Contact info')}
          <ActionButton
            className={classes.floatingActionButton}
            tooltipPlacement="bottom"
            aria-label={t.translate('Update from Alzura API')}
            tooltip={t.translate('Update from Alzura API')}
            icon={<CloudDownloadIcon/>}
            onClick={async () => handleAlzuraSupplierFetch()}
          />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <TableContainer className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Country')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Email')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Invoice email')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Contact person')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Phone')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Fax')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('UST Id')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Tax Id')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Tax area')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Bank name')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Bank holder')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Bank IBAN')}</TableCell>
                  <TableCell className={classes.cellVerticalAlign} align="center">{t.translate('Bank BIC')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  values.contactInfos.map((contactInfo, index) => (
                    <TableRow key={`position-${index}`}>
                      <TableCell align="center">
                        {t.translate(`country:${contactInfo?.countryCode}`)}
                      </TableCell>
                      <TableCell align="center">
                        <InputControl
                          inputProps={{ style: { textAlign: 'left' } }}
                          className={classes.noMargin}
                          id={makeId('email')}
                          name={`contactInfos[${index}].email`}
                          error={!!errors.contactInfos && !!errors.contactInfos[index]?.email}
                          value={contactInfo.email}
                          onChange={handleChange}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <InputControl
                          inputProps={{ style: { textAlign: 'left' } }}
                          className={classes.noMargin}
                          id={makeId('invoice-email')}
                          name={`contactInfos[${index}].invoiceEmail`}
                          error={!!errors.contactInfos && !!errors.contactInfos[index]?.invoiceEmail}
                          value={contactInfo.invoiceEmail}
                          onChange={handleChange}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <InputControl
                          inputProps={{ style: { textAlign: 'left' } }}
                          className={classes.noMargin}
                          id={makeId('contact-person')}
                          name={`contactInfos[${index}].contactPerson`}
                          error={!!errors.contactInfos && !!errors.contactInfos[index]?.contactPerson}
                          value={contactInfo.contactPerson}
                          onChange={handleChange}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <InputControl
                          inputProps={{ style: { textAlign: 'left' } }}
                          className={classes.noMargin}
                          id={makeId('phone')}
                          name={`contactInfos[${index}].phone`}
                          error={!!errors.contactInfos && !!errors.contactInfos[index]?.phone}
                          value={contactInfo.phone}
                          onChange={handleChange}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <InputControl
                          inputProps={{ style: { textAlign: 'left' } }}
                          className={classes.noMargin}
                          id={makeId('fax')}
                          name={`contactInfos[${index}].fax`}
                          error={!!errors.contactInfos && !!errors.contactInfos[index]?.fax}
                          value={contactInfo.fax}
                          onChange={handleChange}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <InputControl
                          inputProps={{ style: { textAlign: 'left' } }}
                          className={classes.noMargin}
                          id={makeId('ust-id')}
                          name={`contactInfos[${index}].ustId`}
                          error={!!errors.contactInfos && !!errors.contactInfos[index]?.ustId}
                          value={contactInfo.ustId}
                          onChange={handleChange}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <InputControl
                          inputProps={{ style: { textAlign: 'left' } }}
                          className={classes.noMargin}
                          id={makeId('tax-id')}
                          name={`contactInfos[${index}].taxId`}
                          error={!!errors.contactInfos && !!errors.contactInfos[index]?.taxId}
                          value={contactInfo.taxId}
                          onChange={handleChange}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <InputControl
                          inputProps={{ style: { textAlign: 'left' } }}
                          className={classes.noMargin}
                          id={makeId('tax-area')}
                          name={`contactInfos[${index}].tax-area`}
                          error={!!errors.contactInfos && !!errors.contactInfos[index]?.taxArea}
                          value={contactInfo.taxArea}
                          onChange={handleChange}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <InputControl
                          inputProps={{ style: { textAlign: 'left' } }}
                          className={classes.noMargin}
                          id={makeId('bank-name')}
                          name={`contactInfos[${index}].bankName`}
                          error={!!errors.contactInfos && !!errors.contactInfos[index]?.bankName}
                          value={contactInfo.bankName}
                          onChange={handleChange}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <InputControl
                          inputProps={{ style: { textAlign: 'left' } }}
                          className={classes.noMargin}
                          id={makeId('bank-holder')}
                          name={`contactInfos[${index}].bankHolder`}
                          error={!!errors.contactInfos && !!errors.contactInfos[index]?.bankHolder}
                          value={contactInfo.bankHolder}
                          onChange={handleChange}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <InputControl
                          inputProps={{ style: { textAlign: 'left' } }}
                          className={classes.noMargin}
                          id={makeId('bank-iban')}
                          name={`contactInfos[${index}].bankIban`}
                          error={!!errors.contactInfos && !!errors.contactInfos[index]?.bankIBAN}
                          value={contactInfo.bankIBAN}
                          onChange={handleChange}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <InputControl
                          inputProps={{ style: { textAlign: 'left' } }}
                          className={classes.noMargin}
                          id={makeId('bank-bic')}
                          name={`contactInfos[${index}].bankBIC`}
                          error={!!errors.contactInfos && !!errors.contactInfos[index]?.bankBIC}
                          value={contactInfo.bankBIC}
                          onChange={handleChange}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
        <BusyButton color="primary" submit onClick={handleSubmit}>{t.translate('Save')}</BusyButton>
      </DialogActions>
    </Dialog>
  );
};

ContactInfoDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  supplier: PropTypes.shape({
    contactInfos: PropTypes.array,
  }).isRequired,
  activeMarketplaces: PropTypes.array,
};
