import React from 'react';

import { useTranslator } from 'i18n';
import { useSelector } from 'react-redux';
import { useStyles } from './style';
import { useDialog } from 'hooks';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { SuppliersDetails } from './SuppliersDetails/SuppliersDetails';
import { OperationsTable } from './OperationsTable/OperationsTable';
import { DetailingStorageProductOperationDialog } from 'dialogs';
import { StorageDetails } from './StorageDetails/StorageDetails';
import { ProductDetails } from './ProductDetails/ProductDetails';

import { Column } from 'layouts';
import { ItemHeadline } from 'components';

export const DesktopView = () => {
  const { classes } = useStyles();
  const t = useTranslator();

  const detailingDialog = useDialog({ operation: null });

  const storageProduct = useSelector(({ products }) => products.history.storageProduct);
  const suppliers = useSelector(({ products }) => products.history.suppliers);
  if (storageProduct) {
    return (
      <>
        {detailingDialog.data.operation && <DetailingStorageProductOperationDialog {...detailingDialog.props} />}

        <Column p={3}>
          <Grid container spacing={3} style={{ flexWrap: 'wrap' }}>
            <Grid item xs={12}>
              <ItemHeadline text={t.translate('Storage details')} />
              <StorageDetails
                storage={storageProduct.storage}
                stock={storageProduct.totalStock}
              />
            </Grid>
            <Grid item xs={4} className={classes.cardItem}>
              <ItemHeadline text={t.translate('Product details')} />
              <ProductDetails product={storageProduct.product} manufacturingDate={storageProduct.manufacturingDate} />
            </Grid>
            <Grid item xs={8} className={classes.cardItem}>
              <ItemHeadline text={t.translate('Suppliers')} />
              <SuppliersDetails
                suppliers={suppliers}
              />
            </Grid>
            <Grid item xs={12}>
              <ItemHeadline text={t.translate('Storage history')} />
              <Paper>
                <OperationsTable onOperationDetail={operation => detailingDialog.open({ operation })} />
              </Paper>
            </Grid>
          </Grid>
        </Column>
      </>
    );
  } else {
    return <Column pb={8} />;
  }
};
