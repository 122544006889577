import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './PickupSheetItem.jsx';
import { useTranslator } from 'i18n';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ActionButton } from 'components';
import Box from '@mui/material/Box';

export const PickupSheetItem = ({
  onPickupSheetDelete,
  onPickupSheetPrint,
  onPickupSheetOpen,
  canDeletePickupSheet,
  canPrintPickupSheet,
  pickupSheet,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <Paper>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item className={classes.contentItem}>
            <Box>
              <Typography
                variant="body2"
                component="span"
                className={classes.bold}
              >{t.translate('Status')}:&nbsp;</Typography>
              <Typography
                variant="body2"
                component="span"
              >{pickupSheet.status || '—'}</Typography>
            </Box>
            <Box>
              <Typography
                variant="body2"
                component="span"
                className={classes.bold}
              >ID:&nbsp;</Typography>
              <Typography
                variant="body2"
                component="span"
              >{pickupSheet.id || '—'}</Typography>
            </Box>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item className={classes.contentItem}>
            <Grid container justifyContent="space-between">
              <Typography
                variant="body2"
                className={classes.bold}>{t.translate('Created')}</Typography>
              <Typography variant="body2">{t.formatDate(pickupSheet.createdAt)}</Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider className={classes.divider}/>
      </Accordion>
      <Grid container direction="column" className={classes.paper}>
        <Grid item className={classes.contentItem}>
          <Grid container justifyContent="space-between">
            <Typography variant="body2" className={classes.bold}>{t.translate('Customer no')}</Typography>
            <Typography variant="body2">{pickupSheet.customer.number || '—'}</Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.contentItem}>
          <Grid container justifyContent="space-between">
            <Typography variant="body2" className={classes.bold}>{t.translate('Customer company')}</Typography>
            <Typography variant="body2">{pickupSheet.customer.company || '—'}</Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.contentItem}>
          <Grid container justifyContent="space-between">
            <Typography variant="body2" className={classes.bold}>{t.translate('Customer name')}</Typography>
            <Typography variant="body2">{pickupSheet.customer.name || '—'}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="space-between" alignItems="center" className={classes.buttonContainer}>
          <Grid item>
            <Button
              aria-label={t.translate('Open customer')}
              className={classes.button}
              startIcon={<InfoIcon />}
              onClick={() => onPickupSheetOpen(pickupSheet)}
            >
              {t.translate('Details')}
            </Button>
          </Grid>
          <Grid item>
            {canDeletePickupSheet && pickupSheet.status === 'open' && (
              <ActionButton
                className={classes.actionButton}
                aria-label={t.translate('Delete the pickup sheet')}
                tooltip={t.translate('Delete')}
                icon={<DeleteIcon />}
                onClick={() => onPickupSheetDelete(pickupSheet)}
              />
            )}
            {canPrintPickupSheet && (
              <ActionButton
                className={classes.actionButton}
                aria-label={t.translate('Print the pickup sheet')}
                tooltip={t.translate('Print')}
                icon={<PrintIcon />}
                onClick={() => onPickupSheetPrint(pickupSheet)}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

PickupSheetItem.propTypes = {
  onPickupSheetDelete: PropTypes.func.isRequired,
  onPickupSheetPrint: PropTypes.func.isRequired,
  onPickupSheetOpen: PropTypes.func.isRequired,
  canDeletePickupSheet: PropTypes.bool.isRequired,
  canPrintPickupSheet: PropTypes.bool.isRequired,
  pickupSheet: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    customer: PropTypes.shape({
      company: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    printed: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
};
