import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslator } from 'i18n';
import {
  AppHeaderPrimaryToolbar,
  AppHeaderMenuButton,
  AppHeaderBackButton,
  AppHeaderTitle,
  AppHeader,
  AppHeaderRightBox,
  QuerySearchInput,
} from 'components';

export const Header = ({
  isMobileView,
  routeTitle,
  hasMenu,
  activeRouteHome,
  userDetailsOpened,
}) => {
  const t = useTranslator();
  const history = useHistory();

  return (
    <AppHeader>
      <AppHeaderPrimaryToolbar>
        {isMobileView ? (
          (hasMenu && !userDetailsOpened)
            ? <AppHeaderMenuButton edge="start" />
            : <AppHeaderBackButton edge="start" onClick={() => userDetailsOpened ? history.goBack() : history.replace(activeRouteHome)} />
        ) : (
          userDetailsOpened && <AppHeaderBackButton edge="start" onClick={() => history.goBack()} />
        )}
        <AppHeaderTitle>
          {t.translate(routeTitle || 'Settings')}
        </AppHeaderTitle>
        {activeRouteHome === '/settings/users' && (
          <AppHeaderRightBox>
            <QuerySearchInput placeholder={t.translate('Search by user name')} />
          </AppHeaderRightBox>
        )}
        {activeRouteHome === '/settings/retailers' && (
          <AppHeaderRightBox>
            <QuerySearchInput placeholder={t.translate('Search by retailer number or name')} />
          </AppHeaderRightBox>
        )}
      </AppHeaderPrimaryToolbar>
    </AppHeader>
  );
};

Header.propTypes = {
  isMobileView: PropTypes.bool,
  routeTitle: PropTypes.string,
  hasMenu: PropTypes.bool,
  activeRouteHome: PropTypes.string,
  userDetailsOpened: PropTypes.object,
};
