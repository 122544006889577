import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ShoppingBasketRoundedIcon from '@mui/icons-material/ShoppingBasketRounded';

import { AlzuraSpriteIcon } from '../..';

export const MenuItem = React.forwardRef(({
  classes,
  text,
  iconId,
  isThumbnail,
  ...props
}, ref) => (
  <ListItem
    {...props}
    className={classes.root}
    ref={ref}
    button
  >
    <ListItemIcon className={classes.icon}>
      {iconId === 'shoppingBasketRoundedIcon' ? (
        <ShoppingBasketRoundedIcon />
      ) : (
        <AlzuraSpriteIcon id={iconId} />
      )}
    </ListItemIcon>
    {!isThumbnail && (
      <ListItemText
        className={classes.text}
        primary={text}
        primaryTypographyProps={{
          variant: 'body2',
          color: 'textSecondary',
        }}
      />
    )}
  </ListItem>
));

MenuItem.propTypes = {
  classes: PropTypes.shape({
    text: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }),
  text: PropTypes.string.isRequired,
  iconId: PropTypes.string.isRequired,
};
