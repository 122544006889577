import React from 'react';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import { useLoading, useList } from 'hooks';
import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import {
  CheckableMenuItem,
  ExpansionMenu,
  TuneBox,
} from 'components';

import { selectOverviewWarehouses } from 'store/selectors/storages';
import { fetchWarehouses } from 'services/storages';
import without from 'lodash/without';
import tables from 'config/tables';
import uniq from 'lodash/uniq';

const useStyles = makeStyles()(theme => ({
  mobilePopover: {
    '& .MuiPopover-paper': {
      left: 'auto !important',
      right: theme.spacing(4),
      '& > .MuiSvgIcon-root': {
        right: theme.spacing(6.75),
        [theme.breakpoints.down('sm')]: {
          right: theme.spacing(5.75),
        },
      },
    },
  },
}));

export const Filter = ({
  isMobileView,
  ...props
}) => {
  const dispatch = useDispatch();
  const loading = useLoading();
  const { classes } = useStyles();
  const list = useList(tables.storages.storages);
  const t = useTranslator();

  const warehouses = useSelector(selectOverviewWarehouses);

  const filters = {
    warehouseIds: Array.isArray(list.filters.warehouseIds) ? list.filters.warehouseIds : [],
  };
  const changeFilter = (property, value) => ({ target: { checked } }) => list.changeFilter(
    property,
    checked
      ? uniq([...filters[property], value])
      : without(filters[property], value)
  );

  return (
    <TuneBox
      width={280}
      PopoverProps={{ className: isMobileView && classes.mobilePopover }}
      isMobile={isMobileView}
      {...props}
      onOpen={loading.lock(() => dispatch(fetchWarehouses()))}
    >
      <ExpansionMenu title={t.translate('Filter by warehouse')}>
        {warehouses.map(warehouse => (
          <CheckableMenuItem
            key={warehouse.id}
            label={warehouse.name}
            checked={filters.warehouseIds.includes(warehouse.id.toString())}
            onChange={changeFilter('warehouseIds', warehouse.id.toString())}
          />
        ))}
      </ExpansionMenu>
    </TuneBox>
  );
};

Filter.propTypes = {
  isMobileView: PropTypes.bool,
};
