import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { FormControlError } from 'components';

export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const monthByNumber = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
};

const monthOptions = Object.keys(monthByNumber).map((monthNumber) =>
  <MenuItem key={monthNumber} value={monthNumber}>{monthByNumber[monthNumber]}</MenuItem>
);

export const MonthPicker = ({
  disabled = false,
  value,
  defaultValue,
  onChange,
  label,
  error,
  ...props
}) => (
  <FormControl fullWidth error={!!error}>
    <InputLabel shrink htmlFor={props.id}>{label}</InputLabel>
    <Select
      id="month-select"
      value={value}
      onChange={onChange}
      disabled={disabled}
      defaultValue={defaultValue || '01'}
      {...props}
    >
      {monthOptions}
    </Select>
    {!!error && <FormControlError error={error} />}
  </FormControl>
);

MonthPicker.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  error: PropTypes.any,
};
