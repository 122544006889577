import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';

const useStyles = makeStyles()(theme => ({
  controlGrid: {
    marginTop: theme.spacing(2),
  },
}));

export const FlagFilterFormControl = ({
  onToggle,
  onChange,
  enabled,
  value,
  label,
  ...props
}) => {
  const { classes } = useStyles();

  return (
    <FormControl fullWidth>
      <InputLabel shrink htmlFor={props.id}>{label}</InputLabel>
      <Grid container className={classes.controlGrid}>
        <Grid item>
          <Checkbox
            color="primary"
            checked={enabled}
            onChange={({ target: { checked } }) => onToggle(checked)}
          />
        </Grid>
        <Grid item>
          <Checkbox
            {...props}
            color="primary"
            checked={value}
            onChange={({ target: { checked } }) => onChange(checked)}
          />
        </Grid>
      </Grid>
    </FormControl>
  );
};

FlagFilterFormControl.propTypes = {
  onToggle: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
};
