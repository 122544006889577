import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { useTranslator } from 'i18n';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { storageBoxColors } from 'config/colors';
import { fetchInboundStockPositions, fetchOrders } from 'api/orders';
import { fetchProduct, fetchProductTypes } from 'api/products';
import { fetchStorages, printStorageTicket, fetchSuggestedTransitStorages } from 'api/storages';
import { createStorageProduct, fetchStorageProducts } from 'api/storage-products';
import { createPickupSheetTransit, createTicketForTransitStorage } from 'api/pickup-sheets';
import { fetchPurchases } from 'api/purchases';

import { ConfirmingDialog, QrCodeScanDialog, StorageInfoDialog } from 'dialogs';
import { QrCodeIcon } from 'icons';

import { BusyButton, EanCodeControl, InputControl, StorageQrCodeControl } from 'components';

import { useStyles } from './style.js';
import { StorageItem } from './StorageItem/StorageItem';
import { OrdersTable } from './OrdersTable/OrdersTable';
import { StoragesTable } from './StoragesTable/StoragesTable';

import { extractApiErrors } from 'utils/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useDialog } from 'hooks';
import { printPickupSheetDocument } from 'services/pickup-sheets';
import { download } from 'services/files';
import {
  CancelledOrderStatusCodes,
  StockOperationReasons,
  StorageTypes,
  SupplierHandlingTypes,
  TyreTypes,
} from 'consts';
import { convertDotStringToDate, simpleFormatDate } from 'utils/dates';
import toaster from 'services/toaster';

const makeId = postfix => `booking-stock-dialog--${postfix}`;
const isNonTransitPositions = selectedPositions => {
  return selectedPositions[0]?.isPurchase
    || selectedPositions[0]?.isCancelledOrder
    || selectedPositions[0]?.isReturnedOrder;
};

export const StockBooking = () => {
  const t = useTranslator();
  const { classes } = useStyles();
  const confirmingDialog = useDialog({ orders: [] });
  const storageInfoDialog = useDialog({ storage: null });

  const [deliveryDataFilter, setDeliveryDataFilter] = useState(null);
  const [scannersVisibility, setScannersVisibility] = useState({
    ean: false,
    qr: false,
  });

  const [sourceStorageLocation, setSourceStorageLocation] = useState(null);
  const [suggestedPurchaseStorages, setSuggestedPurchaseStorages] = useState({ purchasePositionStorageMap: {}, orderPositionStorageMap: {} });

  const closeScanner = type => () => setScannersVisibility({ ...scannersVisibility, [type]: false });
  const openScanner = type => () => setScannersVisibility({ ...scannersVisibility, [type]: true });

  const [, setTypes] = useState([]);
  const [orders, setOrders] = useState([]);
  const [isLoadingRequest, setIsLoadingRequest] = useState({ orders: false, storages: false });
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [storages, setStorages] = useState([]);
  const [storage, setStorage] = useState(null);
  const [productEan, setProductEan] = useState('');
  const [manufacturerNumber, setManufacturerNumber] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [isCanMakePickupSheet, setIsCanMakePickupSheet] = useState(false);
  const [ordersForPickupSheet, setOrdersForPickupSheet] = useState([]);
  const [isTransitStorage, setIsTransitStorage] = useState(false);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [storageFilter, setStorageFilter] = useState(null);
  const [storageNumber, setStorageNumber] = useState('');
  const [ordersForms, setOrdersForms] = useState([]);

  const onStorageInfoClick = storage => storageInfoDialog.open({ storage });

  const getManufacturingDate = (position, isTyreProduct) => {
    if (!isTyreProduct) return null;

    const date = convertDotStringToDate(values.ordersForms[position.orderId][position.id].manufacturingDate);
    const isDateValid = Boolean(date);

    setFieldValue(`ordersForms.${position.orderId}.${position.id}.error`, !isDateValid);

    return isDateValid ? date : false;
  };

  const onSubmit = async (values, { setErrors }) => {
    try {
      const isNotValidDOT = selectedPositions.some(position => {
        const isTyreProduct = TyreTypes.includes(position.product?.type?.code);

        return getManufacturingDate(position, isTyreProduct) === false;
      });

      if (isNotValidDOT) throw new Error(t.translate('Manufacturing date is empty or incorrect.'));

      if (isNonTransitPositions(selectedPositions)) {
        let isCancelledOrReturnedPositions = selectedPositions
            .some(({ isCancelledOrder, isReturnedOrder }) => isCancelledOrder || isReturnedOrder);

        const payload = selectedPositions.reduce((acc, position) => {
          const stock = values.ordersForms[position.orderId][position.id].stock;
          const isTyreProduct = TyreTypes.includes(values.ordersForms[position.orderId][position.id].productType);
          const manufacturingDate = simpleFormatDate(getManufacturingDate(position, isTyreProduct));

          const supplierId = values.ordersForms[position.orderId].supplierId;
          const storageId = storageNumber
            ? Number(storageNumber)
            : position.isCancelledOrder || position.isReturnedOrder
              ? suggestedPurchaseStorages.orderPositionStorageMap[position.id].id
              : suggestedPurchaseStorages.purchasePositionStorageMap[position.id].id;
          const orderPositionReturns = values.ordersForms[position.orderId][position.id].orderPositionReturns;

          if (!stock) return acc;

          if (orderPositionReturns.length) {
            let unallocatedStock = stock;

            orderPositionReturns.forEach(orderPositionReturn => {
              const quantity = Math.min(
                orderPositionReturn.quantity - orderPositionReturn.returnedQuantity,
                unallocatedStock
              );

              if (quantity <= 0) return;

              acc.push({
                manual: true,
                reason: position.isReturnedOrder ? StockOperationReasons.RETURN : StockOperationReasons.STOCK_RECEIPT,
                comment: position.product?.stockOperations?.[0]?.comment ?? null,
                ...(position.isPurchase && {
                  isPurchasePosition: true,
                  purchasePositionId: position.id,
                  purchaseId: position.orderId,
                }),
                isCancelledOrReturnedPosition: position.isCancelledOrder || position.isReturnedOrder,
                orderCode: position.orderCode,
                ...(!position.isPurchase && { orderId: position.orderId }),
                storageId,
                supplierId: supplierId,
                productEan: position.product.ean,
                productAlzuraId: position.product.alzuraId,
                productId: position.product.id,
                manufacturingDate,
                isTyreProduct,
                productPrice: position.purchasePrice,
                orderPositionReturnId: orderPositionReturn.id,
                stock: quantity,
                id: position.id,
              });

              unallocatedStock -= quantity;
            });
          } else {
            acc.push({
              manual: true,
              reason: position.isReturnedOrder
                ? StockOperationReasons.RETURN
                : position.isCancelledOrder
                  ? StockOperationReasons.CANCEL
                  : StockOperationReasons.STOCK_RECEIPT,
              comment: position.product?.stockOperations?.[0]?.comment ?? null,
              ...(position.isPurchase && {
                isPurchasePosition: true,
                purchasePositionId: position.id,
                purchaseId: position.orderId,
              }),
              isCancelledOrReturnedPosition: position.isCancelledOrder || position.isReturnedOrder,
              orderCode: position.orderCode,
              ...(!position.isPurchase && { orderId: position.orderId }),
              storageId,
              supplierId: supplierId,
              productEan: position.product.ean,
              productAlzuraId: position.product.alzuraId,
              productId: position.product.id,
              productPrice: position.purchasePrice,
              manufacturingDate,
              isTyreProduct,
              orderPositionReturnId: null,
              stock,
              id: position.id,
            });
          }

          return acc;
        }, []);

        setIsLoading(true);
        await createStorageProduct(payload);

        if (isCancelledOrReturnedPositions) {
          const payloadStorageTicket = selectedPositions.reduce((acc, position) => {
            const stock = values.ordersForms[position.orderId][position.id].stock;
            const storageId = storageNumber
              ? Number(storageNumber)
              : position.isCancelledOrder || position.isReturnedOrder
                ? suggestedPurchaseStorages.orderPositionStorageMap[position.id].id
                : suggestedPurchaseStorages.purchasePositionStorageMap[position.id].id;

            if (!stock) return acc;

            acc.push({
              id: position.orderId,
              isPurchase: !isCancelledOrReturnedPositions,
              positions: [{
                id: position.id,
                storageId,
                stock,
              }],
            });

            return acc;
          }, []);

          const storageTicketDocument = await printStorageTicket({ orders: payloadStorageTicket });

          download(storageTicketDocument);
        }
      } else {
        const payload = selectedPositions.reduce((acc, position) => {
          const stock = values.ordersForms[position.orderId][position.id].stock;
          const isTyreProduct = TyreTypes.includes(values.ordersForms[position.orderId][position.id].productType);
          const manufacturingDate = getManufacturingDate(position, isTyreProduct);

          if (!stock) return acc;

          acc.push({
            supplierNumber: position.supplier.number,
            productPrice: 0,
            supplierId: position.supplier.id,
            storageId: storageNumber,
            isTransitPosition: true,
            manufacturerNumber: '',
            reason: StockOperationReasons.STOCK_RECEIPT,
            packingUnit: '',
            productEan: position.product.ean,
            productAlzuraId: position.product.alzuraId,
            productId: position.product.id,
            manufacturingDate,
            isTyreProduct,
            orderPositionId: position.id,
            manual: true,
            color: null,
            comment: '',
            length: '',
            height: '',
            width: '',
            customerId: position.customer.id,
            shippingAddressId: position.deliveryAddress.id,
            orderId: position.orderId,
            stock,
            id: position.id,
          });

          return acc;
        }, []);

        const ordersMap = payload.reduce((acc, { id: positionId, orderId, stock }) => {
          if (!acc.has(orderId)) {
            acc.set(orderId, {
              id: orderId,
              positions: [],
            });
          }

          const { positions } = acc.get(orderId);

          positions.push({
            id: positionId,
            stock,
          });

          return acc;
        }, new Map());

        setIsLoading(true);
        await createStorageProduct(payload);
        const ticketDocument = await createTicketForTransitStorage({ orders: [...ordersMap.values()] });
        download(ticketDocument);
      }

      setSelectedPositions([]);
      resetForm();
      await resetFilters();
      await getOrders({ status: 'open', type: 'transit', pickupSheet: 'false', orderedAtSupplier: 'true' }, true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      toaster.error(err.message ?? Object.values(err.collection)[0][0]);
      setErrors(extractApiErrors(err));
    } finally {
      setIsLoading(false);
    }
  };

  const initialValues = {
    manual: true,
    manufacturerNumber,
    color: null,
    productPrice: 0,
    productEan,
    length: '',
    height: '',
    width: '',
    productAlzuraId: '',
    productId: null,
    packingUnit: '',
    comment: '',
    reason: StockOperationReasons.STOCK_RECEIPT,
    weight: '',
    stock: 0,
    ordersForms,
  };

  const validationSchema = Yup
    .object();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  const {
    setFieldValue,
    handleSubmit,
    resetForm,
    values,
    errors,
  } = formik;

  useEffect(() => {
    setSelectedSupplier(null);
  }, []);

  useEffect(() => {
    setOrdersForms(values.ordersForms);
  }, [values.ordersForms]);

  useEffect(() => {
    setOrdersForms(orders.reduce((accParent, order) => {
      accParent[order.id] = order.positions.reduce((acc, {
        id,
        quantity,
        stock,
        filledPosition,
        orderPositionReturns,
        checked,
        product,
      }) => {
        acc.supplierType = order.supplier?.handlingType;
        acc.supplierId = order.supplier.id;
        acc.isCanMakePickupSheet = order.isCanMakePickupSheet ?? false;
        acc[id] = {
          selected: checked ?? false,
          filledPosition: filledPosition ?? null,
          quantity,
          stock: stock ?? 0,
          orderPositionReturns: order.isReturnedOrder ? orderPositionReturns : [],
          manufacturingDate: '',
          productType: product?.type?.code ?? null,
        };

        return acc;
      }, {});

      return accParent;
    }, {}));
  }, [orders]);

  useEffect(() => {
    const ordersForPUS = [];
    orders.forEach(order => {
      if (
        values.ordersForms[order.id]?.isCanMakePickupSheet
        && values.ordersForms[order.id]?.supplierType === SupplierHandlingTypes.TRANSIT
        && !order.isCancelledOrder
        && !order.isReturnedOrder
      ) {
        ordersForPUS.push(order);
      }
    });

    if (!ordersForPUS.length) {
      return setIsCanMakePickupSheet(false);
    }

    setOrdersForPickupSheet(ordersForPUS);
    setIsCanMakePickupSheet(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    const getStorageProductColor = async (params) => {
      const storageProduct = await fetchStorageProducts(params);
      if (storageProduct.data[0]) {
        setFieldValue('color', storageProduct.data[0]?.color);
      } else {
        setFieldValue('color', 'black');
      }
    };
    if ((storage && !storage.boxColor) && values.productId && selectedSupplier?.id) {
      getStorageProductColor({ productId: values.productId, storageId: storage.id, supplierId: selectedSupplier?.id });
    } else {
      setFieldValue('color', 'black');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storage?.id, values.productId, selectedSupplier?.id]);

  const getOrders = async (params, isSubmit) => {
    try {
      setIsLoadingRequest(state => ({ ...state, orders: true }));
      const updateOrders = await fetchOrders({ order: 'desc', orderBy: 'date', limit: 150, offset: 0, ...params });
      const purchases = await fetchPurchases({ status: 'open', isSkipFulfilled: true, isIncludePositions: true });
      const inboundStockPositions = await fetchInboundStockPositions();

      const data = [...updateOrders.data];
      for (const purchase of purchases.data) {
        purchase.customer = { id: null };
        purchase.deliveryAddress = { id: null };
        purchase.isPurchase = true;

        data.push(purchase);
      }

      inboundStockPositions.forEach(inboundStockPosition => {
        inboundStockPosition.isCancelledOrder = CancelledOrderStatusCodes.includes(inboundStockPosition.statusCode);
        inboundStockPosition.isReturnedOrder = !inboundStockPosition.isCancelledOrder;

        data.push(inboundStockPosition);
      });
      if (!isSubmit) {
        Object.keys(data).forEach(order => {
          if (orders[order]?.positions.length) data[order].positions = [...orders[order].positions];
        });
      }

      setOrders(data || []);

      setIsLoadingRequest(state => ({ ...state, orders: false }));
    } catch (err) {
      setIsLoadingRequest(state => ({ ...state, orders: false }));
    }
  };

  const getProduct = async (id) => {
    if (id) {
      try {
        const source = axios.CancelToken.source();
        setIsLoadingRequest(state => ({ ...state, products: true }));
        const product = await fetchProduct(id, { cancelToken: source.token });
        if (product) {
          setFieldValue('productId', product.id);
          setFieldValue('productEan', product.ean);
          setFieldValue('manufacturerNumber', product.manufacturerNumber);
          setFieldValue('productAlzuraId', product.alzuraId);
          setFieldValue('productPrice', 0);
        }
        setIsLoadingRequest(state => ({ ...state, products: false }));
      } catch (err) {
        setIsLoadingRequest(state => ({ ...state, products: false }));
      }
    }
  };

  useEffect(() => {
    const getProductTypes = async () => {
      try {
        const productTypes = await fetchProductTypes();
        setTypes(productTypes.data);
      } catch (err) { }
    };
    getProductTypes();
    getOrders({
      status: 'open',
      type: 'transit',
      pickupSheet: 'false',
      orderedAtSupplier: true,
      omitCancelledPositions: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedSupplier?.handlingType === 'transit') {
      const getStorages = async () => {
        try {
          setIsLoadingRequest(state => ({ ...state, storages: true }));
          const storages = await fetchStorages({ limit: 150, offset: 0, type: StorageTypes.TRANSIT });
          setStorages(storages.data || []);
          setStorage(null);
          setIsLoadingRequest(state => ({ ...state, storages: false }));
        } catch (err) {
          setIsLoadingRequest(state => ({ ...state, storages: false }));
        }
      };
      getStorages();
    } else {
      setStorages([]);
    }
  }, [selectedSupplier]);

  const resetFilters = async () => {
    if (!selectedPositions.length) {
      setDeliveryDataFilter(null);
    }

    setProductEan('');
    setManufacturerNumber('');
    setOrderNumber('');
    setStorages([]);
    setStorage(null);
    setIsTransitStorage(false);
    setSourceStorageLocation(null);
  };

  const createTransitPickupSheet = async () => {
    const payload = ordersForPickupSheet.reduce((acc, { id, positions }) => {
      return ([
        ...acc,
        {
          id,
          positions: positions.reduce((acc, { id: positionId }) => ([
            ...acc,
            {
              id: positionId,
              stock: values.ordersForms[id][positionId].stock,
            },
          ]), []),
        },
      ]);
    }, []);

    const { pickupSheetId } = await createPickupSheetTransit({ orders: payload });
    await printPickupSheetDocument({ id: pickupSheetId });
    await resetFilters();
    setDeliveryDataFilter(null);
    await getOrders({ status: 'open', type: 'transit', pickupSheet: 'false', orderedAtSupplier: 'true' }, true);
  };

  useEffect(() => {
    const handleTransitStorage = async () => {
      const { data } = await fetchSuggestedTransitStorages({ orderIds: selectedPositions.map(({ orderId }) => orderId), limit: 5 });
      setSourceStorageLocation(data[0]);
      setStorageNumber(data[0]?.id);
      setIsTransitStorage(true);
    };

    if (selectedPositions.length === 1) {
      if (!isNonTransitPositions(selectedPositions)) {
        handleTransitStorage();
      }
    } else if (!selectedPositions.length) {
      setSourceStorageLocation(null);
      setIsTransitStorage(false);
      setStorageNumber('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPositions.length]);

  useEffect(() => {
    if (selectedPositions.length) {
      if (selectedPositions[0].isPurchase || selectedPositions[0].isCancelledOrder) {
        setStorageFilter({ type: StorageTypes.DEFAULT });
        return;
      }
      if (selectedPositions[0].isReturnedOrder) {
        setStorageFilter({ type: StorageTypes.EVALUATION });
        return;
      }
      setStorageFilter({ type: StorageTypes.TRANSIT });
    } else setStorageFilter(null);
  }, [selectedPositions, setStorageFilter]);

  return (
    <>
      {scannersVisibility.qr && (
        <QrCodeScanDialog
          open={scannersVisibility.qr}
          onClose={closeScanner('qr')}
          onDecode={id => getProduct(id)}
        />
      )}

      <ConfirmingDialog
        {...confirmingDialog.props}
        title={t.translate('Create transit pickup sheet')}
        acceptButtonText={t.translate(`Yes, I'm sure`)}
        declineButtonText={t.translate('No, cancel')}
        onAccept={createTransitPickupSheet}
      >
        {t.translate('Are you sure you want to create a pickup sheet for these orders?')}
        {confirmingDialog.data.orders.map(order =>
          <Typography key={order.id} component={'span'} className={classes.fontBold}>{order.number}</Typography>
        )}
      </ConfirmingDialog>

      <StorageInfoDialog
        {...storageInfoDialog.props}
      >
      </StorageInfoDialog>

      <Paper className={classes.paper}>
        <Grid className={classes.root}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-end"
            className={classes.container}
          >
            <EanCodeControl
              id={makeId('product-ean')}
              name="productEan"
              error={errors.productEan}
              label={t.translate('Product EAN code')}
              value={productEan}
              onChange={productEan => setProductEan(productEan)}
            />
            <Grid item md={6}>
              <Button
                className={classes.qrButton}
                variant="outlined"
                endIcon={<QrCodeIcon />}
                onClick={openScanner('qr')}
              />
            </Grid>
          </Grid>
          <Grid container direction="column" className={classes.container}>
            <InputControl
              id={makeId('manufacturer-number')}
              error={errors.manufacturerNumber}
              label={t.translate('Manufacturer number')}
              value={manufacturerNumber}
              onChange={({ target: { value } }) => setManufacturerNumber(value)}
            />
          </Grid>
          <Grid container direction="column" className={classes.container}>
            <InputControl
              id={makeId('order-number')}
              error={errors.orderNumber}
              label={t.translate('Order number')}
              value={orderNumber}
              onChange={({ target: { value } }) => setOrderNumber(value)}
            />
          </Grid>

          <Grid container justifyContent="flex-end" className={classes.stockContainer}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.resetFiltersButton}
              onClick={resetFilters}
            >{t.translate('Reset filters')}</Button>
          </Grid>

          <Grid container direction="column" className={classes.container}>
            <Typography variant="body1">{t.translate('Awaited order-positions')}:</Typography>
            <OrdersTable
              values={values}
              orders={orders}
              setFieldValue={setFieldValue}
              isLoading={isLoadingRequest.orders}
              productEan={productEan}
              manufacturerNumber={manufacturerNumber}
              orderNumber={orderNumber}
              setSourceStorageLocation={setSourceStorageLocation}
              setIsTransitStorage={setIsTransitStorage}
              setSelectedPositions={setSelectedPositions}
              selectedPositions={selectedPositions}
              setStorage={setStorage}
              suggestedPurchaseStorages={suggestedPurchaseStorages}
              setSuggestedPurchaseStorages={setSuggestedPurchaseStorages}
              deliveryDataFilter={deliveryDataFilter}
              setDeliveryDataFilter={setDeliveryDataFilter}
              onStorageInfoClick={onStorageInfoClick}
            />
          </Grid>

          {!isCanMakePickupSheet && (
            <Grid container direction="column" className={classes.container}>
              {selectedSupplier?.handlingType === 'transit'
                ? (
                  <>
                    <Typography variant="body2">{t.translate('Storage locations')}</Typography>
                    <StoragesTable storages={storages} isLoading={isLoadingRequest.storages} onStorageSelect={setStorage} selectedStorageId={storage?.id} />
                  </>
                ) : (
                  <Grid container direction="column">
                    <StorageQrCodeControl
                      id={makeId('source-storage-number')}
                      error={''}
                      label={t.translate('Storage location')}
                      value={storageNumber}
                      name="storageNumber"
                      criteria={storageFilter}
                      onChange={value => setStorageNumber(value)}
                      onLoad={(storageNumber) => {
                        setStorage(storageNumber);
                        setSourceStorageLocation(storageNumber);
                      }}
                      disabled={isTransitStorage}
                    />
                    { sourceStorageLocation
                      && <StorageItem storage={sourceStorageLocation} />}
                  </Grid>
                )
              }
            </Grid>
          )}

          { !isCanMakePickupSheet
            && !isNonTransitPositions(selectedPositions)
            && sourceStorageLocation
            && !sourceStorageLocation?.boxColor
            && (
            <>
              <Typography variant="body2">{t.translate('Color')}</Typography>

              <Grid item container
                className={classes.storageItem}
              >
                <ToggleButtonGroup
                  value={values.color}
                  onChange={(event, color) => setFieldValue('color', color)}
                  exclusive
                  className={classes.toggleButton}
                >
                  {Object.entries(storageBoxColors).map(([color, hex]) => (
                    <ToggleButton
                      key={color}
                      variant="outlined"
                      style={color !== 'black' ? { color: hex, borderColor: hex } : { borderStyle: 'dashed' }}
                      className={classes.button}
                      value={color}
                    >
                      {values.color === color && <CheckCircleIcon />}
                      {color !== 'black' ? t.translate(color) : t.translate('no color')}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
            </>
          )}

          <Grid container justifyContent="flex-end" className={classes.actionButton}>
            {isCanMakePickupSheet
              ? (
                <BusyButton color="primary" submit onClick={() => confirmingDialog.open({ orders: ordersForPickupSheet })}>{
                  t.translate('Create pick list')
                }</BusyButton>
              ) : (
                <BusyButton color="primary" submit onClick={handleSubmit} disabled={isLoading}>{
                  t.translate('Book stock')
                }</BusyButton>
              )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

StockBooking.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};
