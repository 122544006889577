import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Chip from '@mui/material/Chip';
import { BooleanIcon } from '..';

const useStyles = makeStyles()(theme => ({
  chip: {
    paddingLeft: theme.spacing(.5),
    '& .MuiSvgIcon-root': {
      color: theme.palette.common.white,
    },
    '& .MuiChip-label': {
      color: theme.palette.common.white,
      paddingLeft: theme.spacing(.75),
    },
  },
  redChip: {
    '&, &:hover, &:focus': {
      backgroundColor: theme.palette.red.coral,
    },
  },
  greenChip: {
    '&, &:hover, &:focus': {
      backgroundColor: theme.palette.green.main,
    },
  },
}));

export const BooleanChip = ({ value, ...props }) => {
  const { classes, cx } = useStyles();

  return (
    <Chip
      {...props}
      avatar={<BooleanIcon truthy={value} />}
      className={cx(classes.chip, {
        [classes.greenChip]: value,
        [classes.redChip]: !value,
      })}
    />
  );
};

BooleanChip.propTypes = {
  value: PropTypes.bool.isRequired,
};
