import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { useTranslator } from 'i18n';
import {
  useTableSortableHeadCell,
  useList, useDialog,
} from 'hooks';

import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import {
  ActionButton,
  BooleanIcon,
} from 'components';

import tables from 'config/tables';
import { styleTableRow } from 'theme/mixins/table';
import { fetchProductPrices } from 'services/products';
import { clearPrices } from 'store/actions/products';
import { ProductPricesDialog } from 'dialogs';

import { MarketplaceCountriesTabs } from '../MarketplaceCountriesTabs/MarketplaceCountriesTabs';
import { selectActionPermission } from '../../../../../store/selectors/account';
import { Hint } from '../../../../suppliers/settings/Hint/Hint';
import { fetchProductReplenishmentSuggestionsOptions } from 'api/products';

const useStyles = makeStyles()(theme => styleTableRow(theme, {
  tableGridContainer: {
    width: '100%',
  },
  tableContainer: {
    maxHeight: theme.spacing(50),
  },
  cardLabel: {
    color: theme.palette.text.itemHeadline,
    padding: theme.spacing(1),
  },
  cardContainer: {
    flexGrow: 1,
  },
  cardItem: {
    padding: theme.spacing(2),
    '& > div:first-of-type > p': {
      fontWeight: 500,
    },
  },
  button: {
    color: theme.palette.primary.main,
    backgroundColor: '#f1f5ff',
    textTransform: 'none',
  },
  productTotals: {
    width: 'auto',
  },
  bold: {
    fontWeight: 500,
  },
  marketPriceContainer: {
    padding: theme.spacing(1, 2, .5, 2),
  },
  marketPriceValues: {
    minWidth: 100,
  },
  tableBody: {
    '& tr:last-child td': {
      borderBottom: 'none',
    },
  },
}));

const getPurchasePrice = (prices, item) => {
  let medianPrice = 0;
  prices.purchasePrices.forEach((purchasePriceObj) => {
    if (purchasePriceObj.supplierId === item.supplierId) {
      medianPrice = purchasePriceObj.currentMedianPurchasePrice;
    }
  });
  return medianPrice;
};

const getMarketplaceId = ({ marketplaces, currentCountryCode }) => {
  return marketplaces[marketplaces.findIndex((marketplace) => marketplace.country.countryCode === currentCountryCode)].id;
};

const getMarketPrices = ({ marketplaces, prices, currentCountryCode }) => {
  let currentMPrice = 0;
  const currentCountryId = getMarketplaceId({ marketplaces, currentCountryCode });
  prices.marketPrices.forEach((marketPriceObj) => {
    if (marketPriceObj.marketplaceCountryId === currentCountryId) {
      currentMPrice = marketPriceObj;
    }
  });
  return currentMPrice;
};

const getLatestPurchasesInfo = ({ supplierId, latestSupplierProductPurchases, t }) => {
  if (!latestSupplierProductPurchases?.[supplierId]) return t.translate('No data');

  const purchasePositions = latestSupplierProductPurchases[supplierId];

  const averageDeliveryTime = purchasePositions.reduce((acc, pos) => {
    return acc + dayjs(pos.fulfilledAt).diff(pos.purchase.openedAt, 'd', true);
  }, 0) / purchasePositions.length;

  return t.translate(
    'Last order was on {lastOrderDate} {lastOrderTime} with {lastOrderQuantity} pieces and it took {lastOrderDeliveryTime} days to complete. Average delivery time is {averageDeliveryTime} days based on {basedOn} last deliveries from supplier: {supplierName} {supplierCity}, {supplierStreet}, {supplierCountry}.', {
      lastOrderDate: t.formatDate(purchasePositions[0].purchase.openedAt),
      lastOrderTime: t.formatTime(purchasePositions[0].purchase.openedAt),
      lastOrderDeliveryTime: dayjs(purchasePositions[0].fulfilledAt).diff(purchasePositions[0].purchase.openedAt, 'd'),
      lastOrderQuantity: purchasePositions[0].quantity,
      averageDeliveryTime: Math.floor(averageDeliveryTime),
      basedOn: purchasePositions.length,
      supplierName: purchasePositions[0].purchase.supplier.name,
      supplierCity: purchasePositions[0].purchase.supplier.address.city,
      supplierStreet: purchasePositions[0].purchase.supplier.address.street,
      supplierCountry: purchasePositions[0].purchase.supplier.address.country,
    }
  );
};

export const MarketplaceSuppliersTable = ({
  canEditProduct,
  marketplaces,
  product,
  prices,
}) => {
  const dispatch = useDispatch();
  const list = useList('productPrices', tables.products.prices);
  const t = useTranslator();
  const { classes } = useStyles();

  const productPricesDialog = useDialog();

  const canEditAutoSalesPrice = useSelector(selectActionPermission('products', 'calculateSalesPrice'));
  const canUpdateFromMarketplace = useSelector(selectActionPermission('products', 'updateFromMarketplace'));

  const [currentCountryCode, setCurrentCountryCode] = useState('de');
  const [salesPrices, setSalesPrices] = useState(null);
  const [marketPrice, setMarketPrice] = useState(null);
  const [superdealInfos, setSuperdealInfos] = useState(null);
  const [replenishmentSuggestionsOptions, setReplenishmentSuggestionsOptions] = useState([]);

  const fetchData = () => {
    dispatch(fetchProductPrices(product.id, list.sorting));
    return () => dispatch(clearPrices());
  };

  const fetchReplenishmentOptions = async (productId) => {
    const replenishmentSuggestionsOptions = await fetchProductReplenishmentSuggestionsOptions(productId);

    setReplenishmentSuggestionsOptions(replenishmentSuggestionsOptions);
  };

  useEffect(() => {
    fetchReplenishmentOptions(product.id);
  }, [product.id]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchData, [product.id, list.sorting]);

  useEffect(() => {
    if (prices) {
      const currentCountryId = getMarketplaceId({ marketplaces, currentCountryCode });
      setSalesPrices(prices.salesPrices.filter((salesPrice) => salesPrice.marketplaceCountryId === currentCountryId));
      setSuperdealInfos(prices.superdealInfos);
      setMarketPrice(getMarketPrices({ prices, marketplaces, currentCountryCode }));
    }
  }, [prices, marketplaces, currentCountryCode]);

  const SortableCell = useTableSortableHeadCell({
    changeSorting: list.changeSorting,
    sorting: list.sorting,
  });

  const getMarketPrice = (name) => marketPrice && !isNaN(marketPrice[name]) ? <>{t.formatPrice(marketPrice[name])}&nbsp;&euro;</> : '—';

  return (
    <>
      {canEditProduct && marketplaces && productPricesDialog.visible && (
        <ProductPricesDialog
          {...productPricesDialog.props}
          marketplaces={marketplaces}
          product={product}
          onSave={async () => {
            await fetchReplenishmentOptions(product.id);

            return dispatch(fetchProductPrices(product.id, list.sorting));
          }}
          canEditAutoSalesPrice={canEditAutoSalesPrice}
          canUpdateFromMarketplace={canUpdateFromMarketplace}
        />
      )}

      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Typography className={classes.cardLabel} variant="body2">{t.translate('Marketplace suppliers')}</Typography>
        </Grid>
        <Paper>
            <MarketplaceCountriesTabs
              initialValue={currentCountryCode}
              marketplaces={marketplaces}
              onChange={setCurrentCountryCode}
            />
            <Grid container direction="row" justifyContent="flex-start" className={classes.marketPriceContainer}>
              <Typography variant="span">{t.translate('Cheapest marketprices')}:&nbsp;</Typography>
              <Typography variant="span">
                <Typography variant="span">{t.translate('1st')}:</Typography>
                <Typography variant="span" className={classes.bold}>&nbsp;{getMarketPrice('cheapestMarketPrice')} &nbsp;</Typography>
              </Typography>
              <Typography variant="span">
                <Typography variant="span">{t.translate('2nd')}:</Typography>
                <Typography variant="span" className={classes.bold}>&nbsp;{getMarketPrice('cheapestMarketPrice2')} &nbsp;</Typography>
              </Typography>
              <Typography variant="span">
                <Typography variant="span">{t.translate('3rd')}:</Typography>
                <Typography variant="span" className={classes.bold}>&nbsp;{getMarketPrice('cheapestMarketPrice3')}</Typography>
              </Typography>
            </Grid>
            <Grid container className={classes.tableGridContainer}>
              <TableContainer className={classes.tableContainer}>
                <Table className={classes.table} stickyHeader>
                  <TableHead>
                    <TableRow style={{ textAlign: 'center' }}>
                      <SortableCell name="supplierId">{t.translate('Supplier')}</SortableCell>
                      <SortableCell name="isProductActiveInMarketplace">{t.translate('Active')}</SortableCell>
                      <SortableCell name="isReplenishmentSuggestionsEnabled">{t.translate('Replenishment suggestions')}</SortableCell>
                      <SortableCell name="stock">{t.translate('Stock')}</SortableCell>
                      <SortableCell name="medianPurchasePrice">{t.translate('Median purchase price')}</SortableCell>
                      <SortableCell name="isAutoCalculateSalesPrice">{t.translate('Calculate sales price')}</SortableCell>
                      <SortableCell name="salesPrice">{t.translate('Sales price')}</SortableCell>
                      <SortableCell name="superdealPrice">{t.translate('Superdeal price')}</SortableCell>
                      <SortableCell name="superdealPromotionPrice">{t.translate('Superdeal promotion price')}</SortableCell>
                      <SortableCell name="superdealMaxOrderAmount">{t.translate('Superdeal max order amount')}</SortableCell>
                      <SortableCell name="superdealStartDate">{t.translate('Superdeal start')}</SortableCell>
                      <SortableCell name="superdealEndDate">{t.translate('Superdeal end')}</SortableCell>
                      <TableCell>{t.translate('Last purchase')}</TableCell>
                      {canEditProduct && (<TableCell>{t.translate('Edit')}</TableCell>)}
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {salesPrices && salesPrices.map((item, index) => {
                      const replenishmentSuggestionsOption = replenishmentSuggestionsOptions.find(option => option.supplierId === item.supplierId);
                      const superdealInfo = superdealInfos.find(info => info.supplierId === item.supplierId);

                      return (
                        <TableRow key={index}>
                          <TableCell>{item.supplier ? `${item.supplier.number} — ${item.supplier.name}` : item.supplierId}</TableCell>
                          <TableCell>{<BooleanIcon truthy={Boolean(item.isProductActiveInMarketplace)}/>}</TableCell>
                          <TableCell>{<BooleanIcon truthy={Boolean(replenishmentSuggestionsOption?.isReplenishmentSuggestionsEnabled)}/>}</TableCell>
                          <TableCell>{!isNaN(item.stock) ? item.stock : '—'}</TableCell>
                          <TableCell>{t.formatPrice(getPurchasePrice(prices, item))}&nbsp;&euro;</TableCell>
                          <TableCell>{<BooleanIcon truthy={Boolean(item.isAutoCalculateSalesPrice)}/>}</TableCell>
                          <TableCell>{t.formatPrice(item.salesPrice)}&nbsp;&euro;</TableCell>
                          <TableCell>{t.formatPrice(item.superdealPrice)}&nbsp;&euro;</TableCell>
                          <TableCell>{t.formatPrice(item.superdealPromotionPrice)}&nbsp;&euro;</TableCell>
                          <TableCell>{superdealInfo.superdealMaxOrderAmount}</TableCell>
                          <TableCell>{item.superdealStartDate ? t.formatDate(item.superdealStartDate) : '—'}</TableCell>
                          <TableCell>{item.superdealEndDate ? t.formatDate(item.superdealEndDate) : '—'}</TableCell>
                          <TableCell>
                            <Hint
                              text={getLatestPurchasesInfo({
                                supplierId: item.supplierId,
                                latestSupplierProductPurchases: product.latestSupplierProductPurchases,
                                t,
                              })}
                            />
                          </TableCell>
                              {canEditProduct && (
                                <TableCell>
                                  <ActionButton
                                    tooltipPlacement="bottom"
                                    aria-label={t.translate('Edit prices')}
                                    tooltip={t.translate('Edit prices')}
                                    icon={<EditIcon/>}
                                    onClick={() => productPricesDialog.open({
                                      prices,
                                      replenishmentSuggestionsOption,
                                      supplierItem: item,
                                    })}
                                  />
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
            </Grid>
        </Paper>
      </Grid>
    </>
  );
};

MarketplaceSuppliersTable.propTypes = {
  onStorageReassignStock: PropTypes.func.isRequired,
  onStorageProductEdit: PropTypes.func.isRequired,
  product: PropTypes.shape({
  }),
};
