import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: theme.spacing(90),
    },
    '& .MuiTableContainer-root': {
      overflowX: 'visible',
    },
  },
  emptyRow: {
    height: theme.spacing(1),
  },
  blockLabel: {
    height: '1.5rem',
    fontWeight: 500,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  dialogActions: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
}));
