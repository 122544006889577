import React, { useMemo } from 'react';
import {
  Grid,
  Typography,
  TableContainer,
  TableHead,
  TableBody,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import {
  CheckboxControl,
  DayPicker,
  InputControl,
  MonthPicker,
  PriceControl,
  Table,
  TableCell,
  TableRow,
} from 'components';
import { useTranslator } from 'i18n';
import { CostpriceProductPriceTypes, SupplierHandlingTypes } from 'consts';
import { Hint } from '../Hint/Hint';
import { PageWrapper } from '../PageWrapper/PageWrapper';
import { useStyles } from '../styles';

const makeId = postfix => `supplier-settings-fees--${postfix}`;

export const FeesSettings = ({ isEditMode, errors, values, handleChange, setFieldValue }) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const costpriceProductPriceTypeOptions = useMemo(
    () => new Map(['generalPurchasePrice', 'netPrice'].map(key => [key, t.translate(CostpriceProductPriceTypes[key])])),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t.intl.locale]
  );

  return (
    <PageWrapper>
      <Typography variant="body1" style={{ paddingBottom: '20px' }}>
        {t.translate('Selling fees & seasonal costs')}
      </Typography>
      <Grid
        container
        item
        lg={9}
        xs={12}
        direction="row"
        className={classes.containerWrap}
        style={{ justifyContent: 'space-between' }}
      >
        <Grid className={classes.containerItem}>
          <Typography variant="body2" className={classes.label}>
            {t.translate('Paid options')}
          </Typography>
          <Grid item container>
            <CheckboxControl
              disabled={!isEditMode}
              id={makeId('isAnonymousSelling')}
              name="isAnonymousSelling"
              error={errors.isAnonymousSelling}
              label={t.translate('Anonymous selling')}
              checked={!!values.isAnonymousSelling}
              onChange={handleChange}
            />
            <Hint text={'suppliers:isAnonymousSelling-hint'}/>
          </Grid>
          {values.handlingType === SupplierHandlingTypes.DEFAULT ? (
            <Grid item container>
              <CheckboxControl
                disabled={!isEditMode}
                id={makeId('isBillUsedStorage')}
                name="isBillUsedStorage"
                error={errors.isBillUsedStorage}
                label={t.translate('Bill used storage')}
                checked={!!values.isBillUsedStorage}
                onChange={handleChange}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid className={classes.containerItem}>
        <Grid item container alignItems="center">
          <Typography variant="body2" className={classes.label}>
            {t.translate('Forwarding of order-related earnings')}
          </Typography>
          <Hint text={'suppliers:order-related-earnings-hint'}/>
        </Grid>
        <Grid item container>
          <CheckboxControl
            disabled={!isEditMode}
            id={makeId('isHandlingCosts')}
            name="isHandlingCosts"
            error={errors.isHandlingCosts}
            label={t.translate('Handling costs')}
            checked={!!values.isHandlingCosts}
            onChange={handleChange}
          />
        </Grid>
        <Grid item container>
          <CheckboxControl
            disabled={!isEditMode}
            id={makeId('isShippingCosts')}
            name="isShippingCosts"
            error={errors.isShippingCosts}
            label={t.translate('Shipping costs')}
            checked={!!values.isShippingCosts}
            onChange={handleChange}
          />
        </Grid>
        <Grid item container>
          <CheckboxControl
            disabled={!isEditMode}
            id={makeId('isDifferingShippingAddressCosts')}
            name="isDifferingShippingAddressCosts"
            error={errors.isDifferingShippingAddressCosts}
            label={t.translate('Costs for differing shipping addresses')}
            checked={!!values.isDifferingShippingAddressCosts}
            onChange={handleChange}
          />
        </Grid>
        <Grid item container>
          <CheckboxControl
            disabled={!isEditMode}
            id={makeId('isRegionalSurcharge')}
            name="isRegionalSurchargeCosts"
            error={errors.isRegionalSurchargeCosts}
            label={t.translate('Regional surcharge')}
            checked={!!values.isRegionalSurchargeCosts}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid>
        <TableContainer style={{ marginTop: 20 }}>
          <Table style={{ width: 'auto' }}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.label}>{t.translate('Season')}</TableCell>
                <TableCell className={classes.label}>{t.translate('Start')}</TableCell>
                <TableCell className={classes.label}>{t.translate('End')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{t.translate('Summer')}</TableCell>
                <TableCell className={classes.cell}>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <DayPicker
                        disabled={!isEditMode}
                        id={makeId('summer-start-date-day')}
                        name="summerStartDate"
                        value={values.summerStartDate[1]}
                        onChange={(e) => setFieldValue('summerStartDate', [values.summerStartDate[0], e.target.value])}
                        month={values.summerStartDate[0]}
                        error={errors.summerStartDate}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <MonthPicker
                        disabled={!isEditMode}
                        id={makeId('summer-start-date-month')}
                        name="summerStartDate"
                        value={values.summerStartDate[0]}
                        onChange={(e) => setFieldValue('summerStartDate', [e.target.value, values.summerStartDate[1]])}
                        error={errors.summerStartDate}
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell className={classes.cell}>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <DayPicker
                        disabled={!isEditMode}
                        id={makeId('summer-end-date-day')}
                        name="summerEndDate"
                        value={values.summerEndDate[1]}
                        month={values.summerEndDate[0]}
                        onChange={(e) => setFieldValue('summerEndDate', [values.summerEndDate[0], e.target.value])}
                        error={errors.summerEndDate}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <MonthPicker
                        disabled={!isEditMode}
                        id={makeId('summer-end-date-month')}
                        name="summerEndDate"
                        value={values.summerEndDate[0]}
                        onChange={(e) => setFieldValue('summerEndDate', [e.target.value, values.summerEndDate[1]])}
                        error={errors.summerEndDate}
                      />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t.translate('Winter')}</TableCell>
                <TableCell className={classes.cell}>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <DayPicker
                        disabled={!isEditMode}
                        id={makeId('winter-start-date-day')}
                        name="winterStartDate"
                        value={values.winterStartDate[1]}
                        month={values.winterStartDate[0]}
                        onChange={(e) => setFieldValue('winterStartDate', [values.winterStartDate[0], e.target.value])}
                        error={errors.winterStartDate}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <MonthPicker
                        disabled={!isEditMode}
                        id={makeId('winter-start-date-month')}
                        name="winterStartDate"
                        value={values.winterStartDate[0]}
                        onChange={(e) => setFieldValue('winterStartDate', [e.target.value, values.winterStartDate[1]])}
                        error={errors.winterStartDate}
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell className={classes.cell}>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <DayPicker
                        disabled={!isEditMode}
                        id={makeId('winter-end-date-day')}
                        name="winterEndDate"
                        value={values.winterEndDate[1]}
                        month={values.winterEndDate[0]}
                        onChange={(e) => setFieldValue('winterEndDate', [values.winterEndDate[0], e.target.value])}
                        error={errors.winterEndDate}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <MonthPicker
                        disabled={!isEditMode}
                        id={makeId('winter-end-date-month')}
                        name="winterEndDate"
                        value={values.winterEndDate[0]}
                        onChange={(e) => setFieldValue('winterEndDate', [e.target.value, values.winterEndDate[1]])}
                        error={errors.winterEndDate}
                      />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid>
        <TableContainer style={{ marginTop: 40 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.label} align="center">{t.translate('Country')}</TableCell>
                <TableCell className={classes.label} align="center">{t.translate('Bill fees to partner')}</TableCell>
                <TableCell className={classes.label} align="center" style={{ width: 150 }}>{t.translate('Calculate fees based on')}</TableCell>
                <TableCell className={classes.label} align="center">{t.translate('Invoicing & support fee (%)')}</TableCell>
                <TableCell className={classes.label} align="center">{t.translate('Marketplace fee (%)')}</TableCell>
                <TableCell className={classes.label} align="center">{t.translate('Premium fee (%)')}</TableCell>
                <TableCell className={classes.label} align="center">{t.translate('Price calculation fee (%)')}</TableCell>
                <TableCell className={classes.label} align="center">{t.translate('Base shipping costs')}</TableCell>
                <TableCell className={classes.label} align="center">{t.translate('Off season')}</TableCell>
                <TableCell className={classes.label} align="center">{t.translate('Summer season')}</TableCell>
                <TableCell className={classes.label} align="center">{t.translate('Winter season')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                values.pricesCalculations.map((position, index) => (
                  <TableRow key={`position-${index}`}>
                    <TableCell align="center">
                      {t.translate(`country:${position.countryCode}`)}
                    </TableCell>
                    <TableCell className={classes.checkboxContainer} align="center">
                      <CheckboxControl
                        disabled={!isEditMode}
                        className={classes.checkboxContainer}
                        name={`pricesCalculations[${index}].billFeesToPartner`}
                        checked={!!position.billFeesToPartner}
                        onChange={(...args) => {
                          handleChange(...args);
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <FormControl
                        fullWidth
                        disabled={!position.billFeesToPartner}
                      >
                        <Select
                          disabled={!isEditMode}
                          id="calculate-fees"
                          name={`pricesCalculations[${index}].costpriceProductPriceType`}
                          value={position.costpriceProductPriceType}
                          displayEmpty
                          onChange={handleChange}
                          style={{ backgroundColor: 'white' }}
                        >
                          {Array.from(costpriceProductPriceTypeOptions).map(([value, text]) => (
                            <MenuItem key={value} value={value}>
                              {t.translate(text)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell align="center">
                      <InputControl
                        inputProps={{ style: { textAlign: 'right' } }}
                        className={classes.noMargin}
                        style={{ backgroundColor: 'white' }}
                        id={makeId('invoicing-fee')}
                        name={`pricesCalculations[${index}].invoicingFee`}
                        error={!!errors.pricesCalculations && !!errors.pricesCalculations[index]?.invoicingFee}
                        value={position.invoicingFee}
                        onChange={handleChange}
                        disabled={!isEditMode || !position.billFeesToPartner}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <InputControl
                        inputProps={{ style: { textAlign: 'right' } }}
                        className={classes.noMargin}
                        style={{ backgroundColor: 'white' }}
                        id={makeId('marketplace-fee')}
                        name={`pricesCalculations[${index}].marketplaceFee`}
                        error={!!errors.pricesCalculations && !!errors.pricesCalculations[index]?.marketplaceFee}
                        value={position.marketplaceFee}
                        onChange={handleChange}
                        disabled={!isEditMode || !position.billFeesToPartner}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <InputControl
                        inputProps={{ style: { textAlign: 'right' } }}
                        className={classes.noMargin}
                        style={{ backgroundColor: 'white' }}
                        id={makeId('premium-fee')}
                        name={`pricesCalculations[${index}].premiumFee`}
                        error={!!errors.pricesCalculations && !!errors.pricesCalculations[index]?.premiumFee}
                        value={position.premiumFee}
                        onChange={handleChange}
                        disabled={!isEditMode || !position.billFeesToPartner}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <InputControl
                        inputProps={{ style: { textAlign: 'right' } }}
                        className={classes.noMargin}
                        style={{ backgroundColor: 'white' }}
                        id={makeId('price-calculation-fee')}
                        name={`pricesCalculations[${index}].priceCalculationFee`}
                        error={!!errors.pricesCalculations && !!errors.pricesCalculations[index]?.priceCalculationFee}
                        value={position.priceCalculationFee}
                        onChange={handleChange}
                        disabled={!isEditMode || !position.billFeesToPartner}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <PriceControl
                        className={classes.noMargin}
                        style={{ backgroundColor: 'white' }}
                        id={makeId('base-shipping-costs')}
                        name={`pricesCalculations[${index}].baseShippingCosts`}
                        error={!!errors.pricesCalculations && !!errors.pricesCalculations[index]?.baseShippingCosts}
                        value={position.baseShippingCosts}
                        onChange={handleChange}
                        disabled={!isEditMode || !position.billFeesToPartner}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <PriceControl
                        className={classes.noMargin}
                        style={{ backgroundColor: 'white' }}
                        id={makeId('off-season')}
                        name={`pricesCalculations[${index}].offSeason`}
                        error={!!errors.pricesCalculations && !!errors.pricesCalculations[index]?.offSeason}
                        value={position.offSeason}
                        onChange={handleChange}
                        disabled={!isEditMode || !position.billFeesToPartner}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <PriceControl
                        className={classes.noMargin}
                        style={{ backgroundColor: 'white' }}
                        id={makeId('summer-season')}
                        name={`pricesCalculations[${index}].summerSeason`}
                        error={!!errors.pricesCalculations && !!errors.pricesCalculations[index]?.summerSeason}
                        value={position.summerSeason}
                        onChange={handleChange}
                        disabled={!isEditMode || !position.billFeesToPartner}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <PriceControl
                        className={classes.noMargin}
                        style={{ backgroundColor: 'white' }}
                        id={makeId('winter-season')}
                        name={`pricesCalculations[${index}].winterSeason`}
                        error={!!errors.pricesCalculations && !!errors.pricesCalculations[index]?.winterSeason}
                        value={position.winterSeason}
                        onChange={handleChange}
                        disabled={!isEditMode || !position.billFeesToPartner}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </PageWrapper>
  );
};
