import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import HelpIcon from '@mui/icons-material/Help';
import AddIcon from '@mui/icons-material/Add';
import {
  StoredCircleIcon,
  PlusMinusIcon,
} from './icons';

export const screenWidthThreshold = 1024;

export const StorageProductOrSpecialCaseActionReasons = {
  'stock-receipt': {
    Icon: AddIcon,
    text: 'Stock receipt',
  },
  'special-case': {
    Icon: WarningIcon,
    text: 'Special case',
  },
  'correction': {
    Icon: PlusMinusIcon,
    text: 'Correction',
  },
  'return': {
    Icon: AddIcon,
    text: 'Return',
  },
  'order': {
    Icon: ShoppingBasketIcon,
    text: 'Order',
  },
  'reassign-stock': {
    Icon: AssignmentReturnIcon,
    text: 'Reassign stock',
  },
  'partially-cancel': {
    Icon: AddIcon,
    text: 'Partially cancelled.',
  },
  'pickup-sheet': {
    Icon: AddIcon,
    text: 'Pickup sheet',
  },
  'reserve': {
    con: AddIcon,
    text: 'Reservation',
  },
  'repatriate-stock': {
    Icon: AddIcon,
    text: 'Repatriate stock',
  },
  'trash': {
    Icon: AddIcon,
    text: 'Trash',
  },
};

export const ProductConditions = {
  'used': 'Used',
  'new': 'New',
};

export const SpecialCaseStatuses = {
  'open': {
    Icon: InfoIcon,
    text: 'Open',
  },
  'in-progress': {
    Icon: HelpIcon,
    text: 'In progress',
  },
  'done': {
    Icon: CheckCircleIcon,
    text: 'Done',
  },
  'stored': {
    Icon: StoredCircleIcon,
    text: 'Stored',
  },
};

export const SpecialCaseStatusesForFilter = {
  'open': 'Open',
  'in-progress': 'In progress',
  'done': 'Done',
  'stored': 'Stored',
};

export const PickupSheetStatuses = {
  'open': 'Open',
  'packed': 'Packed',
  'closed': 'Closed',
  'labelled': 'Labelled',
  'label-fetched': 'Label fetched',
};

export const OrderStatuses = {
  QUEUE: 0,
  PROCESSING: 1,
  COMPLETED: 2,
  CLOSED: 3,
  DENIED: 4,
  SOLD_OUT: 5,
  PARTIALLY_CANCELLED: 6,
  CANCELLED_BY_SELLER: 7,
  CANCELLED_BY_BUYER: 8,
  DAMAGED_OR_LOST: 70,
};

export const cancelReason = [
  { value: 4, text: 'The order was rejected.' },
  { value: 5, text: 'Unfortunately the item is out of stock.' },
  { value: 7, text: 'Customer cancellation by supplier.' },
  { value: 70, text: 'Damaged or lost.' },
];

export const shipAgainReasons = [
  'RETURNED',
  'LOST',
  'DAMAGED',
  'INCOMPLETE',
  'COULD_NOT_BE_DELIVERED',
];

export const CancelledOrderStatusCodes = [
  OrderStatuses.DENIED,
  OrderStatuses.SOLD_OUT,
  OrderStatuses.CANCELLED_BY_SELLER,
  OrderStatuses.DAMAGED_OR_LOST,
];

export const returnReason = [
  { value: 1, text: 'Broken goods' },
  { value: 2, text: 'Incomplete shipment' },
  { value: 3, text: 'Wrong product' },
  { value: 4, text: 'Rejected by customer' },
  { value: 5, text: 'Address not found' },
];

export const returnShipment = [
  { value: 'HANDLED_BY_ALZURA', text: 'Handled by ALZURA' },
  { value: 'HANDLED_BY_CUSTOMER', text: 'Handled by customer' },
];

export const ReturnShipment = {
  HANDLED_BY_ALZURA: 'HANDLED_BY_ALZURA',
  HANDLED_BY_CUSTOMER: 'HANDLED_BY_CUSTOMER',
};

export const returnTypes = [
  { value: 'RESELL' },
  { value: 'RETURN_TO_ORIGINAL_SUPPLIER' },
];

export const correctionReason = {
  'inventoryCheck': 'Inventory check',
  'damagedGoods': 'Damaged goods',
};


export const ReturnStatuses = {
  'shipped': 'shipped',
  'partly-returned': 'partly-returned',
  'returned': 'returned',
};

export const SupplierReturnFeeTypes = {
  BOTH: 'BOTH',
  PERCENT: 'PERCENT',
  FIXED: 'FIXED',
};

export const PurchaseStatuses = {
  draft: 'draft',
  approvalNeeded: 'approval-needed',
  declined: 'declined',
  open: 'open',
  received: 'received',
};

export const CostpriceProductPriceTypes = {
  'generalPurchasePrice': 'Purchase price',
  'netPrice': 'Sales price',
};

export const SupplierHandlingTypes = {
  DEFAULT: 'default',
  DROPSHIPPING_DEFAULT: 'dropshippingDefault',
  DROPSHIPPING_AUTO_ORDER: 'dropshippingAutoOrder',
  TRANSIT: 'transit',
};

export const SupplierPaymentsMethods = [
  { id: 1, text: 'SEPA direct debit' },
  { id: 2, text: 'Advance payment' },
  { id: 3, text: 'Cash on delivery' },
  { id: 4, text: 'PayPal / Credit card' },
  { id: 5, text: 'Open payment' },
  { id: 7, text: 'Invoice (payable within 8 days)' },
  { id: 8, text: 'Open payment (SEPA)' },
  { id: 9, text: 'PayPal' },
  { id: 10, text: 'Credit invoice' },
  { id: 11, text: 'Invoice (payable within 30 days)' },
  { id: 12, text: 'ALZURA Pay SEPA-direct debit' },
  { id: 13, text: 'ALZURA Pay SEPA-debit 7 days' },
];

export const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passwordRegEx = /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[#?!@$%^&*\-_]).{8,}$/;

export const defaultRowsPerPage = [10, 25, 50, 100];

export const ProductImportTypes = {
  products: 'Products',
  priceImport: 'Price import',
};

export const StorageTypes = {
  DEFAULT: 'DEFAULT',
  TEMPORARY: 'TEMPORARY',
  TRANSIT: 'TRANSIT',
  EVALUATION: 'EVALUATION',
};

export const PurchaseTypes = {
  DEFAULT: 'DEFAULT',
  SUGGESTION: 'SUGGESTION',
};

export const StockOperationReasons = {
  RETURN: 'return',
  CORRECTION: 'correction',
  STOCK_RECEIPT: 'stock-receipt',
  SPECIAL_CASE: 'special-case',
  ORDER: 'order',
  REASSIGN_STOCK: 'reassign-stock',
  PARTIALLY_CANCEL: 'partially-cancel',
  CANCEL: 'cancel',
  RESERVE: 'reserve',
  PICKUP_SHEET: 'pickup-sheet',
};

export const OrderStatusFilter = {
  OPEN: 'open',
  CLOSED: 'closed',
  RETURNED: 'returned',
};

export const STUCK_ORDERS = 'stuck';

export const EvaluationTypes = {
  RESELL: 'RESELL',
  REPATRIATE: 'REPATRIATE',
  TRASH: 'TRASH',
};

export const ExternalPaymentMethods = ['sepa', 'invoice_direct', 'invoice_30_days'];

export const Languages = ['de', 'at', 'fr', 'it', 'pl', 'es', 'be', 'nl', 'lu', 'ee'];

export const QRNextStep = 'next step';

export const ReplenishmentSuggestionsStatuses = {
  OPEN: 'OPEN',
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  CLOSED: 'CLOSED',
};

export const B2BPriceTypes = [
  { value: 0, text: 'Fetch only normal prices' },
  { value: 1, text: 'Fetch both prices' },
  { value: 2, text: 'Fetch only FreeShop prices' },
];

export const StockReservationBehaviorOptions = [
  { value: 'TRY_TO_CLEAR_STORAGES', text: 'Try to clear storages' },
  { value: 'FIRST_IN_FIRST_OUT', text: 'First in first out' },
];

export const TyreTypes = ['PTY', 'R'];
