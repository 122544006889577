import reportsApi from 'api/reports';
import {
  setOverviewWarehouseInventoryReports,
  setOverviewStatisticsReports,
} from 'store/actions/reports';

export const fetchStatisticsReports = params => async dispatch => {
  try {
    const statisticsReports = await reportsApi.fetchStatisticsReports(params);
    dispatch(setOverviewStatisticsReports(statisticsReports));
  } catch (err) {}
};

export const fetchWarehouseInventoryReports = params => async dispatch => {
  try {
    const warehouseInventoryReports = await reportsApi.fetchManyWarehouseInventoryReports(params);
    dispatch(setOverviewWarehouseInventoryReports(warehouseInventoryReports));
  } catch (err) {}
};
