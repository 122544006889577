import { makeStyles } from 'tss-react/mui';
import { styleFormDialog } from 'theme/mixins/dialog';

export const useStyles = makeStyles()(theme => styleFormDialog(theme, {
  root: {
    zIndex: `${theme.zIndex.modal + 1} !important`,
  },
  content: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  reader: {
    '& input': {
      bottom: 0,
      display: 'block !important',
      left: 0,
      opacity: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: 1,
    },
  },
  actions: {
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
    },
  },
}));
