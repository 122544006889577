import React from 'react';
import { Grid, MenuItem, TableContainer, TableHead, TableBody, Select } from '@mui/material';
import { useStyles } from '../styles';
import {
  CheckboxControl,
  InputControl,
  Table,
  TableCell,
  TableRow,
} from 'components';
import { useTranslator } from 'i18n';
import { Hint } from '../Hint/Hint';
import { PageWrapper } from '../PageWrapper/PageWrapper';

const makeId = postfix => `supplier-settings-prices--${postfix}`;

export const PricesSettings = ({ activeMarketplaces, isEditMode, values, handleChange, errors }) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <PageWrapper>
      <Grid
        container
        item
        xl={3}
        lg={4}
        md={6}
        xs={12}
        direction="row"
        className={classes.containerWrap}
      >
        <Grid item container className={classes.containerItem}>
          <CheckboxControl
            disabled={!isEditMode}
            label={t.translate('Automatic sales price calculation')}
            id={makeId('isAutoCalculateSalesPrices')}
            name={'isAutoCalculateSalesPrices'}
            checked={values.isAutoCalculateSalesPrices}
            onChange={handleChange}
          />
          <Hint text={'suppliers:automaticSalesPrice-hint'}/>
        </Grid>
        <Grid item container className={classes.containerItem}>
          <InputControl
            id={makeId('isUndercutAutomatically')}
            error={errors.isUndercutAutomatically}
            label={t.translate('Calculate method')}
            InputComponent={() => (<Select
              disabled={!isEditMode}
              value={values.isUndercutAutomatically}
              name="isUndercutAutomatically"
              onChange={handleChange}
            >
              <MenuItem value={false}>{t.translate('Calculate prices based on the values below')}</MenuItem>
              <MenuItem value={true}>{t.translate('Automatically undercut 1-10 cents')}</MenuItem>
            </Select>)}
          />
          {values.isUndercutAutomatically && <Hint text={'suppliers:isUndercutAutomatically-hint'}/>}
        </Grid>
        <CheckboxControl
          disabled={!isEditMode}
          label={t.translate('Re-calculate prices on stock export')}
          id={makeId('isCalculatePricesOnStockExport')}
          name={'isCalculatePricesOnStockExport'}
          checked={values.isCalculatePricesOnStockExport}
          onChange={handleChange}
        />
      </Grid>
      <Grid
        container
        item
        xl={4}
        lg={6}
        md={8}
        xs={12}
        direction="row"
        className={classes.containerWrap}
      >
        {activeMarketplaces && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.label}>{t.translate('Country')}</TableCell>
                  <TableCell className={classes.label} align="center">{t.translate('Market price amount')}&nbsp;(%)</TableCell>
                  <TableCell className={classes.label} align="center">{t.translate('General price amount')}&nbsp;(%)</TableCell>
                  <TableCell className={classes.label} align="center">{t.translate('Fixed Amount')}&nbsp;(&euro;)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.pricesCalculations.map((position, index) => (
                  <TableRow key={`position-${index}`}>
                    <TableCell>{t.translate(`country:${position.countryCode}`)}</TableCell>
                    <TableCell align="center">
                      <InputControl
                        inputProps={{ style: { textAlign: 'right' } }}
                        id={makeId(`pricesCalculations[${index}].salesPriceMarketPriceRate`)}
                        name={`pricesCalculations[${index}].salesPriceMarketPriceRate`}
                        error={values.isAutoCalculateSalesPrices && !!errors.pricesCalculations && errors.pricesCalculations[index]?.salesPriceMarketPriceRate}
                        value={values.pricesCalculations[index].salesPriceMarketPriceRate}
                        onChange={handleChange}
                        disabled={!isEditMode || !values.isAutoCalculateSalesPrices || values.isUndercutAutomatically}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <InputControl
                        inputProps={{ style: { textAlign: 'right' } }}
                        id={makeId(`pricesCalculations[${index}].salesPriceGeneralPriceRate`)}
                        name={`pricesCalculations[${index}].salesPriceGeneralPriceRate`}
                        error={values.isAutoCalculateSalesPrices && !!errors.pricesCalculations && errors.pricesCalculations[index]?.salesPriceGeneralPriceRate}
                        value={values.pricesCalculations[index].salesPriceGeneralPriceRate}
                        onChange={handleChange}
                        disabled={!isEditMode || !values.isAutoCalculateSalesPrices}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <InputControl
                        inputProps={{ style: { textAlign: 'right' } }}
                        id={makeId(`pricesCalculations[${index}].salesPriceFixedRate`)}
                        name={`pricesCalculations[${index}].salesPriceFixedRate`}
                        error={values.isAutoCalculateSalesPrices && !!errors.pricesCalculations && errors.pricesCalculations[index]?.salesPriceFixedRate}
                        value={values.pricesCalculations[index].salesPriceFixedRate}
                        onChange={handleChange}
                        disabled={!isEditMode || !values.isAutoCalculateSalesPrices}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </PageWrapper>
  );
};
