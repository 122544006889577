import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import { useStyles } from './SelectingScanTypeDialog.jsx';
import { useTranslator } from '../../i18n';

export const SelectingScanTypeDialog = ({
  onScanSelect,
  onClose,
  title,
  ...props
}) => {
  const t = useTranslator();
  const { classes } = useStyles();

  const select = type => () => {
    onScanSelect(type);
    onClose();
  };

  return (
    <>
      <Dialog scroll="body" {...props} className={classes.root} onClose={onClose}>
        <DialogTitle className={classes.title}>{title || t.translate('Select scan mode')}</DialogTitle>
        <DialogContent className={clsx(classes.content, classes.actions)}>
          <Button color="primary" variant="contained" onClick={select('qr')}>{t.translate('Scan QR code')}</Button>
          <Button color="primary" variant="contained" onClick={select('ean')}>{t.translate('Scan EAN code')}</Button>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button color="primary" onClick={onClose}>{t.translate('dialog-action:Cancel')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

SelectingScanTypeDialog.propTypes = {
  onScanSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};
