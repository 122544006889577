import React from 'react';
import PropTypes from 'prop-types';

import { useTranslator } from 'i18n';

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles()(theme => ({
  root: {
    margin: theme.spacing(.5),
    padding: theme.spacing(1),
  },
  label: {
    fontWeight: 500,
  },
}));

export const ProductDetails = ({ product }) => {
  const t = useTranslator();
  const { classes } = useStyles();

  return (
    <Paper className={classes.root}>
    <Grid container direction="column" >
      <Grid item className={classes.label}>{product.details[0]?.name}</Grid>
      <Grid item><Typography variant="body2">{product.manufacturerName}</Typography></Grid>
      <Grid item container justifyContent="space-between">
        <Grid item><Typography variant="body2" className={classes.label}>{t.translate('Product-ID')}</Typography></Grid>
        <Grid item><Typography variant="body2">{product.id}</Typography></Grid>
      </Grid>
      <Grid item container justifyContent="space-between">
        <Grid item><Typography variant="body2" className={classes.label}>EAN</Typography></Grid>
        <Grid item><Typography variant="body2">{product.ean}</Typography></Grid>
      </Grid>
    </Grid>
  </Paper>
  );
};

ProductDetails.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    manufacturerName: PropTypes.string,
    id: PropTypes.number,
    ean: PropTypes.string,
  }),
};
